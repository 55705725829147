import { AppCard } from '@/common/components/app-card/AppCard';
import { OrganizationInfoCopyable } from '@/modules/organization-profile/components/organization-info-copyable/OrganizationInfoCopyable';
import { OrganizationInfo } from '@/modules/organization-profile/models/organization-info';
import React, { FC, memo } from 'react';

interface OrganizationInfoCardProps {
  model: OrganizationInfo;
}

const OrganizationInfoCardInner: FC<OrganizationInfoCardProps> = ({ model }) => (
  <AppCard>
    <OrganizationInfoCopyable
      title="Название"
      value={model.name}
    />
    <OrganizationInfoCopyable
      title="ИНН"
      value={model.inn}
    />
    <OrganizationInfoCopyable
      title="ОГРН/ОГРНИП"
      value={model.ogrn}
    />
    <OrganizationInfoCopyable
      title="Юридический адрес"
      value={model.legalAddress}
    />
    <OrganizationInfoCopyable
      title="Регистрационный номер"
      value={model.registrationNumber}
    />
  </AppCard>
);

export const OrganizationInfoCard = memo(OrganizationInfoCardInner);
