import { ExtractPropertyType } from '@/common/utils/common/types-utils';
import React, { FC, MutableRefObject, ReactNode, memo } from 'react';
import './app-tab-group.scss';

export interface AppTab {
  label: string;
  content: ReactNode;
  id: string | number;
}

export interface AppTabGroupProps {
  tabs: AppTab[];
  activeTabId: ExtractPropertyType<AppTab, 'id'>;
  tabContentRef: MutableRefObject<HTMLDivElement> | undefined;
  onChangeTab: (activeTab: ExtractPropertyType<AppTab, 'id'>) => void;
  onActiveTabClick: (activeTab: ExtractPropertyType<AppTab, 'id'>) => void;
}

const AppTabGroupInner: FC<AppTabGroupProps> = ({ tabs, activeTabId, tabContentRef, onChangeTab, onActiveTabClick }) => {

  const isActiveTab = (id: ExtractPropertyType<AppTab, 'id'>) => activeTabId === id;

  const getActiveTabContent = () =>
    tabs.find((tab) => tab.id === activeTabId).content;

  const onTabClick = (activeTab: ExtractPropertyType<AppTab, 'id'>) => {
    if (isActiveTab(activeTab)) {
      onActiveTabClick(activeTab);
    } else {
      onChangeTab(activeTab);
    }
  };

  return (
    <div className="app-tab-group">
      <div className="tab-labels">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-label ${isActiveTab(tab.id) ? 'active' : ''}`}
            onClick={() => onTabClick(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div
        className="active-tab-content"
        ref={tabContentRef}
      >
        {getActiveTabContent()}
      </div>
    </div>
  );
};

export const AppTabGroup = memo(AppTabGroupInner);
