import React, { FC, useMemo } from 'react';
import './stepper.scss';

interface StepperProps {
  children: React.ReactNode[];
  activeStep: number;
  className?: string;
}

export const Stepper: FC<StepperProps> = ({ children, activeStep = 0, className }) => {
  const stepsCount = children.length;
  const progressBarWidth = useMemo(() => Math.round((activeStep + 1) / stepsCount * 100), [activeStep]);
  const activeStepContent = activeStep >= 0 && activeStep < children.length ? children[activeStep] : undefined;

  return (
    <div className={`stepper-container ${className ?? ''}`}>
      <div className="progress-bar-container">
        <div className="step-title">
          Шаг {activeStep + 1}
        </div>
        <div className="progress-bar"
          style={{ width: `${progressBarWidth}%` }}
        />
      </div>
      <div className="content">
        {activeStepContent}
      </div>
    </div>
  );
};
