import { AppTableRowSelectionType, Updater } from '@/common/models/app-table/app-table-types';
import { VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { getValueFromUpdater } from '@/common/utils/common/types-utils';
import { DocumentType } from '@/shared/document/models/document-type';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { DocumentsApi } from '@/shared/document/store/documents/types';
import { CaseReducerActions, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface DocumentsTableState extends VisibleColumns<DocumentsTableColumns> {
  docType: DocumentType;
  updatedRowId: string;
  isUpdatingInProcess: boolean;
  isExportInProcess: boolean;
  isUploadingInProcess: boolean;
  rowsSelection: AppTableRowSelectionType;
}

const getInitialState = (docType: DocumentType, visibleColumns?: DocumentsTableColumns[]) => ({
  docType,
  updatedRowId: '',
  isUpdatingInProcess: false,
  isExportInProcess: false,
  isUploadingInProcess: false,
  rowsSelection: {},
  visibleColumns,
});

interface DocumentsTableCaseReducers extends SliceCaseReducers<DocumentsTableState> {
  setUpdatedRowId: (state: Draft<DocumentsTableState>, action: PayloadAction<string>) => void;
  setIsUpdatingInProgress: (state: Draft<DocumentsTableState>, action: PayloadAction<boolean>) => void;
  setRowSelection: (state: Draft<DocumentsTableState>, action: PayloadAction<Updater<AppTableRowSelectionType>>) => void;
}

export type DocumentsTableActions = CaseReducerActions<DocumentsTableCaseReducers, string>;

interface CreateDocumentsTableSliceParams extends VisibleColumns<DocumentsTableColumns> {
  moduleName: string;
  docType: DocumentType;
  api: DocumentsApi;
}

export const createDocumentsTableSlice = ({ moduleName, docType, api, visibleColumns }: CreateDocumentsTableSliceParams) => {
  const documentsTableSlice = createSlice<DocumentsTableState, DocumentsTableCaseReducers>({
    name: moduleName,
    initialState: getInitialState(docType, visibleColumns),
    reducers: {
      setUpdatedRowId(state: DocumentsTableState, action: PayloadAction<string>) {
        state.updatedRowId = action.payload;
      },
      setIsUpdatingInProgress(state: DocumentsTableState, action: PayloadAction<boolean>) {
        state.isUpdatingInProcess = action.payload;
      },
      setRowSelection(state: DocumentsTableState, action: PayloadAction<Updater<AppTableRowSelectionType>>) {
        state.rowsSelection = getValueFromUpdater<AppTableRowSelectionType>(action.payload, state.rowsSelection);
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.exportDocumentFile.pending, (state) => {
          state.isExportInProcess = true;
        })
        .addCase(api.exportDocumentFile.fulfilled, (state) => {
          state.isExportInProcess = false;

          const { setRowSelection } = documentsTableSlice.caseReducers;
          setRowSelection(state, getReducerAction(setRowSelection.name, {}));
        })
        .addCase(api.exportDocumentFile.rejected, (state) => {
          state.isExportInProcess = false;
        })
        .addCase(api.uploadDocumentFile.pending, (state) => {
          state.isUploadingInProcess = true;
        })
        .addCase(api.uploadDocumentFile.fulfilled, (state) => {
          state.isUploadingInProcess = false;
        })
        .addCase(api.uploadDocumentFile.rejected, (state) => {
          state.isUploadingInProcess = false;
        });
    }
  });

  return documentsTableSlice;
};
