import { saleCardHistoryModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardHistoryApi } from '@/modules/sale/store/sale-card/history/async-thunks';
import { createDocumentCardHistorySlice } from '@/shared/document/store/document-card/history';

export const saleCardHistorySlice = createDocumentCardHistorySlice({
  moduleName: saleCardHistoryModuleName,
  savedTableConfigName: 'sale-card-history-table',
  api: saleCardHistoryApi,
});

export const saleCardHistoryRootActions = saleCardHistorySlice.rootSlice.actions;
export const saleCardHistoryTableConfigActions = saleCardHistorySlice.documentCardHistoryTableConfigSlice.actions;

export default saleCardHistorySlice.rootSlice.reducer;
