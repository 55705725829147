export function getFileSizeText(value: number) {
  const stepSize = 1024;

  if (value < stepSize) {
    return `${value} байт`;
  }

  const kB = Math.ceil(value / stepSize);
  if (kB < stepSize) {
    return `${kB} Кб.`;
  }

  const mB = Math.ceil(kB / stepSize);
  if (mB < stepSize) {
    return `${mB} Мб.`;
  }

  const gB = Math.ceil(mB / stepSize);
  return `${gB} Гб.`;
}
