import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { phoneMask } from '@/common/constants/masks';
import { fioRegExp } from '@/common/constants/reg-exps';
import { emailMaxLength, fioMaxLength, positionMaxLength } from '@/common/utils/client-validation/string-lengths';
import { FieldErrors } from '@/common/utils/server-validation/field-errors';
import { useRegistrationFormDataValidation } from '@/modules/auth/hooks/use-registration-form-data-validation';
import { RegistrationFormDataFields } from '@/modules/auth/models/registration-form-data';
import { SignupValidateContactsErrorFields } from '@/modules/auth/models/signup-validate-contacts/signup-validate-contacts-body';
import { updateFormDataValue } from '@/modules/auth/store/registration';
import { RegistrationSelectors } from '@/modules/auth/store/registration/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './registration-forms.scss';

interface RegistrationStep1FormProps {
  fieldErrors: FieldErrors<SignupValidateContactsErrorFields>;
  onNextClick: () => void;
  onCancelClick: () => void;
}

const formFields = new Set<RegistrationFormDataFields>(['firstName', 'secondName', 'lastName', 'phoneNumber', 'email', 'position']);

export const RegistrationStep1Form: FC<RegistrationStep1FormProps> = ({ fieldErrors, onNextClick, onCancelClick }) => {
  const dispatch = useAppDispatch();
  const formData = useSelector(RegistrationSelectors.selectFormData);

  const {
    validationResult,
    validateForm,
  } = useRegistrationFormDataValidation(formData, formFields);

  const onFieldChange = (fieldName: RegistrationFormDataFields) => ((value: string) => dispatch(updateFormDataValue({ [fieldName]: value })));

  const onSecondNameChange = useCallback(onFieldChange('secondName'), []);
  const onFirstNameChange = useCallback(onFieldChange('firstName'), []);
  const onLastNameChange = useCallback(onFieldChange('lastName'), []);
  const onPhoneNumberChange = useCallback(onFieldChange('phoneNumber'), []);
  const onEmailChange = useCallback(onFieldChange('email'), []);
  const onPositionChange = useCallback(onFieldChange('position'), []);

  const onNextClickInner = () => {
    if (validateForm()) {
      onNextClick();
    }
  };

  return (
    <div className="registration-form-container">
      <h1 className="title">Регистрация</h1>
      <h2 className="sub-title">Для регистрации администратора организации заполните, пожалуйста, все поля</h2>
      <AppInput
        label="Фамилия"
        className="registration-form-container-field"
        value={formData.lastName}
        maxLength={fioMaxLength}
        error={validationResult.lastName?.error}
        helperText={validationResult.lastName?.messages?.[0]}
        regExp={fioRegExp}
        onValueChange={onLastNameChange}
      />
      <AppInput
        label="Имя"
        className="registration-form-container-field"
        value={formData.firstName}
        maxLength={fioMaxLength}
        error={validationResult.firstName?.error}
        helperText={validationResult.firstName?.messages?.[0]}
        regExp={fioRegExp}
        onValueChange={onFirstNameChange}
      />
      <AppInput
        label="Отчество"
        className="registration-form-container-field"
        value={formData.secondName}
        maxLength={fioMaxLength}
        error={validationResult.secondName?.error}
        helperText={validationResult.secondName?.messages?.[0]}
        regExp={fioRegExp}
        onValueChange={onSecondNameChange}
      />
      <AppInput
        label="Номер телефона"
        className="registration-form-container-field"
        value={formData.phoneNumber}
        mask={phoneMask}
        error={validationResult.phoneNumber?.error || fieldErrors?.phoneNumber?.error}
        helperText={validationResult.phoneNumber?.messages?.[0] || fieldErrors?.phoneNumber?.message}
        onValueChange={onPhoneNumberChange}
      />
      <AppInput
        label="Email"
        className="registration-form-container-field"
        value={formData.email}
        maxLength={emailMaxLength}
        error={validationResult.email?.error || fieldErrors?.email?.error}
        helperText={validationResult.email?.messages?.[0] || fieldErrors?.email?.message}
        onValueChange={onEmailChange}
      />
      <AppInput
        label="Должность в компании"
        className="registration-form-container-field"
        value={formData.position}
        maxLength={positionMaxLength}
        error={validationResult.position?.error}
        helperText={validationResult.position?.messages?.[0]}
        onValueChange={onPositionChange}
      />
      <AppButton
        label="Далее"
        className="next-button"
        onClick={onNextClickInner}
      />
      <AppLink
        text="Отмена"
        onClick={onCancelClick}
      />
    </div>
  );
};
