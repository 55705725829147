import { buildModelValueGetter } from '@/common/utils/app-table/app-table-utils';
import { convertFavoritesDtoFieldToColumn } from '@/modules/favorites/models/favorites-table-column-converter';
import { FavoritesTableColumns } from '@/modules/favorites/models/favorites-table-columns';
import { UpdateDocumentBody } from '@/shared/document/models/documents/update-document-body';

export function convertFromColumnModelToUpdateFavoriteDocumentBody(model: Record<FavoritesTableColumns, any>): UpdateDocumentBody {
  const getData = buildModelValueGetter<FavoritesTableColumns, UpdateDocumentBody>(convertFavoritesDtoFieldToColumn, model);

  return {
    comment: getData('comment')?.trim(),
  };
}
