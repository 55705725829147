import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { Certificate } from '@/common/models/certificate';
import { LoadingStatus } from '@/common/models/loading-status';
import { removeSelectedCertificate, setLoadingStatus } from '@/modules/certificates/store';
import { MdlpTokenBody } from '@/modules/portal/models/mdlp-token/mdlp-token-body';
import { setIsOpenCertificatesListDialog } from '@/modules/portal/store/header';
import { getAuthCode, getHeaderInfo, setToken } from '@/modules/portal/store/header/async-thunks';
import { PortalHeaderSelectors } from '@/modules/portal/store/header/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

interface ExtendSessionHookParams {
  initTimer: () => void;
}

export const useExtendSession = ({ initTimer }: ExtendSessionHookParams) => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const isDialogOpened = useSelector(PortalHeaderSelectors.selectIsOpenCertificatesListDialog);

  const openDialog = useCallback(() => dispatch(setIsOpenCertificatesListDialog(true)), []);
  const closeDialog = useCallback(() => dispatch(setIsOpenCertificatesListDialog(false)), []);

  // TODO: combine calling getAuthCode & setToken in one async-thunk. tokenThenCb pass into asyncDispatch with new async-thunk
  const extendSession = useCallback(({ thumbprint }: Certificate) => {
    dispatch(setLoadingStatus(LoadingStatus.Pending));

    const catchCb = () => {
      dispatch(setLoadingStatus(LoadingStatus.Rejected));
      dispatch(removeSelectedCertificate());
    };

    const tokenThenCb = () => {
      asyncDispatch(getHeaderInfo, initTimer);
      dispatch(setLoadingStatus(LoadingStatus.Fulfilled));
      closeDialog();
      dispatch(removeSelectedCertificate());
    };

    const authCodeThenCb = (res: MdlpTokenBody) => {
      // second request if the first was successfull
      asyncDispatch(
        () => setToken(res),
        tokenThenCb,
        catchCb
      );
    };

    // first request
    asyncDispatch(
      () => getAuthCode({ thumbprint }),
      authCodeThenCb,
      catchCb
    );
  }, []);

  return {
    isExtendSessionDialogOpened: isDialogOpened,
    openExtendSessionDialog: openDialog,
    closeExtendSessionDialog: closeDialog,
    extendSession
  };
};