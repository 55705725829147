import { ErrorModel } from '@/common/models/error-model';
import { PayloadActionMeta } from '@/common/models/payload-action-meta';
import { useRequestError } from '@/common/utils/server-validation/use-request-error';
import { AsyncAction, useAppDispatch } from '@/store';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';

export type AsyncDispatchResult = ReturnType<ReturnType<typeof useAsyncDispatch>['asyncDispatch']>;

export const useAsyncDispatch = <ErrorFields extends string = string>() => {

  const dispatch = useAppDispatch();
  const { fieldErrors, hasFieldError, resetFieldErrors, setFieldErrors } = useRequestError<ErrorFields>();

  const asyncDispatch = <Response = unknown>(
    action: AsyncAction,
    thenCb?: (res: Response) => void,
    catchCb?: (err: ErrorModel<ErrorFields>) => void,
    finallyCb?: () => void
  ) => {
    const dispatchResult = dispatch(action());

    dispatchResult.then((res: PayloadAction<Response, string, PayloadActionMeta, SerializedError>) => {
      dispatchResult.unwrap()
        .then((res: Response) => {
          resetFieldErrors();
          thenCb?.(res);
        })
        .catch((err: ErrorModel<ErrorFields>) => {
          // in case of aborting or condition redux throw own errors, we need to skip this.
          if (res.meta.aborted || res.meta.condition) {
            return;
          }
          catchCb?.(err);
          setFieldErrors(err);
        })
        .finally(() => {
          finallyCb?.();
        });
    });

    return dispatchResult;
  };

  return {
    fieldErrors,
    hasFieldError,
    asyncDispatch,
    resetFieldErrors
  };
};
