import { AppTableColumnOrderType, AppTableColumnSizingType, AppTableColumnVisibilityType, AppTableSortingType, Updater } from '@/common/models/app-table/app-table-types';
import { SavedTableConfigCaseReducers } from '@/common/models/saved-table-config/saved-table-config-case-reducers';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { getSavedTableConfigStateOrDefault, saveTableConfigState } from '@/common/utils/app-table/app-table-storage-utils';
import { getValueFromUpdater } from '@/common/utils/common/types-utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const createSavedTableConfigSlice = (name: string, initialState: SavedTableConfigState) => {
  return createSlice<SavedTableConfigState, SavedTableConfigCaseReducers>({
    name: `${name}/tableConfig`,
    initialState: {
      ...initialState,
      config: getSavedTableConfigStateOrDefault(initialState.tableConfigName, initialState.config),
    },
    reducers: {
      updateTableColumnVisibilityState: (state: SavedTableConfigState, action: PayloadAction<AppTableColumnVisibilityType>) => {
        state.config = {
          ...state.config,
          columnVisibility: action.payload,
        };
        saveTableConfigState(state.tableConfigName, state.config);
      },
      updateTableSortingState: (state: SavedTableConfigState, action: PayloadAction<Updater<AppTableSortingType>>) => {
        const newSorting = getValueFromUpdater<AppTableSortingType>(action.payload, state.config.sorting);
        state.config = {
          ...state.config,
          sorting: newSorting,
        };
        saveTableConfigState(state.tableConfigName, state.config);
      },
      updateTableColumnOrderState: (state: SavedTableConfigState, action: PayloadAction<Updater<AppTableColumnOrderType>>) => {
        const newColumnOrder = getValueFromUpdater<AppTableColumnOrderType>(action.payload, state.config.columnOrder);
        state.config = {
          ...state.config,
          columnOrder: newColumnOrder,
        };
        saveTableConfigState(state.tableConfigName, state.config);
      },
      updateTableColumnSizingState: (state: SavedTableConfigState, action: PayloadAction<Updater<AppTableColumnSizingType>>) => {
        const newColumnSizing = getValueFromUpdater<AppTableColumnSizingType>(action.payload, state.config.columnSizing);
        state.config = {
          ...state.config,
          columnSizing: newColumnSizing,
        };
        saveTableConfigState(state.tableConfigName, state.config);
      },
      setTableConfig: (state: SavedTableConfigState) => {
        state.config = getSavedTableConfigStateOrDefault(initialState.tableConfigName, initialState.config);
      }
    },
  });
};
