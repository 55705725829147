import { createTheme } from '@mui/material';
import { useMemo } from 'react';

export function useAppThemePalette() {

  const rootStyle = useMemo(() => getComputedStyle(document.documentElement), []);
  const primaryColor = useMemo(() => rootStyle.getPropertyValue('--primary-color').trim(), [rootStyle]);

  const themePalette = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: primaryColor,
          },
        },
      }),
    [],
  );

  return {
    themePalette
  };
}
