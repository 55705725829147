import { AppTableProgressBar } from '@/common/components/app-table/components/app-table-progress-bar/AppTableProgressBar';
import { useAppTableCustomIcons } from '@/common/components/app-table/hooks/use-app-table-custom-icons';
import { useAppTableLocalization } from '@/common/components/app-table/hooks/use-app-table-localization';
import { useAppTableMuiDefOptions } from '@/common/components/app-table/hooks/use-app-table-mui-def-options';
import { useAppTableScrolling } from '@/common/components/app-table/hooks/use-app-table-scrolling';
import { useAppTableSettings } from '@/common/components/app-table/hooks/use-app-table-settings';
import { useAppTableStyles } from '@/common/components/app-table/hooks/use-app-table-styles';
import {
  AppTableDefaultColumnSize,
  AppTableMaxColumnSize,
  AppTableMinColumnSize
} from '@/common/constants/app-table-constants';
import { useAppThemePalette } from '@/common/hooks/use-app-theme-palette';
import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { AppTableRowActionMenuItemModel } from '@/common/models/app-table/app-table-row-action-menu-item-model';
import {
  AppTableCellType,
  AppTableColumnDefType,
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableEditingRowSaveParamsType,
  AppTableRowSelectionType,
  AppTableRowType,
  AppTableSortingType,
  AppTableStateType,
  AppTableVirtualizerType,
  OnChangeFn
} from '@/common/models/app-table/app-table-types';
import { ThemeProvider } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { FC, memo, useRef } from 'react';
import './app-table-input.scss';
import './app-table.scss';

export type AppTablePropsGetRowsActions = (row: AppTableRowType) => AppTableRowActionMenuItemModel[];

export interface AppTableProps<TData extends Record<string, any> = Record<string, any>, ColumnId extends string = string> {
  data: TData[];
  columns: AppTableColumnDefType[];
  classes?: { cell: (data: { row?: AppTableRowType; cell?: AppTableCellType; }) => string; };
  isLoading?: boolean;
  isUpdatingInProcess?: boolean;
  updatedRowId?: string;
  maxHeight?: string;
  configState?: Partial<AppTableStateType<TData>>;
  enableSorting?: boolean;
  manualSorting?: boolean;
  enableEditing?: boolean;
  editingMode?: 'table' | 'modal' | 'row' | 'cell';
  enableRowSelection?: boolean | ((row: AppTableRowType<TData>) => boolean);
  enableRowVirtualization?: boolean;
  showDeleteRowButton?: boolean;
  renderEmptyRowsFallback?: () => React.ReactNode;
  getRowActions?: AppTablePropsGetRowsActions;
  getRowId?: (originalRow: TData, index: number, parent?: AppTableRowType<TData>) => string;
  onColumnVisibilityChange?: (value: AppTableColumnVisibilityType) => void,
  onSortingChange?: OnChangeFn<AppTableSortingType>,
  onColumnOrderChange?: OnChangeFn<AppTableColumnOrderType>,
  onColumnSizingChange?: OnChangeFn<AppTableColumnSizingType>,
  onRowSelectionChange?: OnChangeFn<AppTableRowSelectionType>;
  onRowDoubleClick?: (row: AppTableRowType) => void;
  onEditingRowSave?: (props: AppTableEditingRowSaveParamsType<TData, ColumnId>) => Promise<void> | void;
  onDeleteRow?: (row: AppTableRowType<TData>) => AsyncDispatchResult;
  onScrollAtTheEnd?: () => void;
}

/**
 * Base table component.
 */
const AppTableInner: FC<AppTableProps> = ({
  data,
  columns,
  classes,
  isLoading = false,
  isUpdatingInProcess = false,
  updatedRowId,
  maxHeight,
  configState,
  enableSorting = true,
  manualSorting = false,
  enableEditing = false,
  editingMode = 'row',
  enableRowSelection = false,
  enableRowVirtualization = false,
  showDeleteRowButton,
  renderEmptyRowsFallback,
  getRowActions,
  getRowId,
  onColumnVisibilityChange,
  onSortingChange,
  onColumnOrderChange,
  onColumnSizingChange,
  onRowDoubleClick,
  onEditingRowSave,
  onRowSelectionChange,
  onDeleteRow,
  onScrollAtTheEnd,
}) => {
  const rowVirtualizerInstanceRef = useRef<AppTableVirtualizerType>(null);

  const {
    renderRowActionMenuItems,
    renderRowActions,
    onRowDoubleClickInner,
    renderEmptyRowsFallbackInner
  } = useAppTableSettings({ isLoading, showDeleteRowButton, onDeleteRow, getRowActions, onRowDoubleClick, renderEmptyRowsFallback });

  const {
    actionColumnSize,
    fixedCellWidthClassName,
    disabledCellClassName,
    hideRowActionsClassName,
  } = useAppTableStyles({ enableEditing, editingMode, isUpdatingInProcess, updatedRowId, renderRowActionMenuItems });

  const {
    muiTableBodyCellEditTextFieldProps,
    actionColumnDefOptions,
    selectColumnDefOptions,
  } = useAppTableMuiDefOptions({
    columns, configState, classes, onColumnVisibilityChange,
    actionColumnSize, hideRowActionsClassName, disabledCellClassName
  });

  const { themePalette } = useAppThemePalette();
  const { customIcons } = useAppTableCustomIcons({ isUpdatingInProcess });
  const { localization } = useAppTableLocalization();
  const { onScroll } = useAppTableScrolling({ data, rowVirtualizerInstanceRef, onScrollAtTheEnd });

  return (
    <ThemeProvider theme={themePalette}>
      <div className="app-table-container">
        <AppTableProgressBar
          showProgressBar={isLoading}
          className="app-table-progress-bar"
        />
        <MaterialReactTable
          data={data}
          columns={columns}
          state={configState}
          muiTableContainerProps={{
            sx: { maxHeight },
            onScroll
          }}
          enableSorting={enableSorting}
          manualSorting={manualSorting}
          enableEditing={enableEditing}
          editingMode={editingMode}
          enableRowSelection={enableRowSelection}
          enableRowVirtualization={enableRowVirtualization}
          renderRowActionMenuItems={renderRowActionMenuItems}
          renderRowActions={renderRowActions}
          enableColumnResizing
          enableColumnOrdering
          enableRowActions
          positionActionsColumn="last"
          layoutMode="grid"
          displayColumnDefOptions={{
            ...actionColumnDefOptions,
            ...selectColumnDefOptions,
          }}
          muiSelectCheckboxProps={{
            className: 'app-checkbox-icon',
            disableRipple: true,
          }}
          muiTablePaperProps={{ className: 'app-table-paper' }}
          muiTableHeadCellProps={({ column }) => ({
            className: `${fixedCellWidthClassName(column)}`
          })}
          muiTableBodyCellProps={({ row, cell, column }) => ({
            className: `${classes?.cell({ row, cell }) ?? ''} ${fixedCellWidthClassName(column)} ${disabledCellClassName(row)}`,
            onDoubleClick: (event) => onRowDoubleClickInner(event, row),
          })}
          muiTableBodyCellEditTextFieldProps={muiTableBodyCellEditTextFieldProps}
          rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
          enableSelectAll={false}
          enableColumnActions={false}
          enablePagination={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          sortDescFirst={false}
          columnResizeMode="onEnd"
          enableStickyHeader
          localization={localization}
          defaultColumn={{
            maxSize: AppTableMaxColumnSize,
            minSize: AppTableMinColumnSize,
            size: AppTableDefaultColumnSize,
          }}
          renderEmptyRowsFallback={renderEmptyRowsFallbackInner}
          icons={customIcons}
          getRowId={getRowId}
          onSortingChange={onSortingChange}
          onColumnOrderChange={onColumnOrderChange}
          onColumnSizingChange={onColumnSizingChange}
          onRowSelectionChange={onRowSelectionChange}
          onEditingRowSave={onEditingRowSave}
        />
      </div>
    </ThemeProvider>
  );
};

export const AppTable = memo(AppTableInner);
