import { UIEvent, useCallback } from 'react';

interface ScrollingHookParams {
  scrollBottomPadding: number;
  onScrollAtTheEnd?: () => void;
}

export function useScrolling({ scrollBottomPadding, onScrollAtTheEnd }: ScrollingHookParams) {
  const onScroll = useCallback((containerElement: UIEvent<HTMLDivElement>) => {
    if (containerElement.currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = containerElement.currentTarget;
      if (scrollHeight - scrollTop - clientHeight < scrollBottomPadding) {
        onScrollAtTheEnd?.();
      }
    }
  }, [onScrollAtTheEnd]);

  return {
    onScroll,
  };
}
