export enum DocumentType {
  Receipt = 'RECEIPT',
  Sale = 'SALE',
  OtherDisposals = 'OTHER_DISPOSALS'
}

export const documentTypeLabels = {
  [DocumentType.Receipt]: 'Поступление',
  [DocumentType.Sale]: 'Реализация',
  [DocumentType.OtherDisposals]: 'Прочие выбытия',
};

export const getDocumentTypeLabel = (type: DocumentType) => documentTypeLabels[type];
