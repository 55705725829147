import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { defaultDocumentCardPositionsTableConfig } from '@/shared/document/constants/document-card-positions-table-config';
import { DocumentCardPosition } from '@/shared/document/models/document-card/positions/document-card-position';
import { DocumentCardPositionsTableColumns } from '@/shared/document/models/document-card/positions/document-card-positions-table-columns';
import { DocumentsCardPositionsApi } from '@/shared/document/store/document-card/positions/types';
import { CaseReducerActions, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface DocumentCardPositionsState extends VisibleColumns<DocumentCardPositionsTableColumns> {
  tableConfig: SavedTableConfigState;
  positions: DocumentCardPosition[];
  isLoadingPositions: boolean;
}

interface DocumentCardPositionsCaseReducers extends SliceCaseReducers<DocumentCardPositionsState> {
  deletePosition: (state: Draft<DocumentCardPositionsState>, action: PayloadAction<number>) => void;
  resetState: (state: Draft<DocumentCardPositionsState>) => void;
}

export type DocumentCardPositionsActions = CaseReducerActions<DocumentCardPositionsCaseReducers, string>;

const notClearedFields: Set<keyof DocumentCardPositionsState> = new Set(['tableConfig']);

interface CreateDocumentCardPositionsSliceParams extends VisibleColumns<DocumentCardPositionsTableColumns> {
  moduleName: string;
  savedTableConfigName: string;
  api: DocumentsCardPositionsApi;
}

export const createDocumentCardPositionsSlice = ({ moduleName, savedTableConfigName, api, visibleColumns }: CreateDocumentCardPositionsSliceParams) => {
  const documentCardPositionsTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: defaultDocumentCardPositionsTableConfig,
  };

  const documentCardPositionsTableConfigSlice = createSavedTableConfigSlice(moduleName, documentCardPositionsTableConfigState);

  const initialState: DocumentCardPositionsState = {
    tableConfig: documentCardPositionsTableConfigSlice.getInitialState(),
    positions: [],
    isLoadingPositions: false,
    visibleColumns
  };

  const rootSlice = createSlice<DocumentCardPositionsState, DocumentCardPositionsCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      deletePosition: (state, action: PayloadAction<number>) => {
        state.positions.splice(action.payload, 1);
      },
      resetState: (state) => {
        Object.keys(state)
          .forEach((key: keyof DocumentCardPositionsState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getAllPositions.pending, (state) => {
          state.isLoadingPositions = true;
        })
        .addCase(api.getAllPositions.fulfilled, (state, action) => {
          state.isLoadingPositions = false;
          state.positions = action.payload;
        })
        .addCase(api.getAllPositions.rejected, (state) => {
          state.isLoadingPositions = false;
        })
        .addMatcher(
          (action) => action.type.startsWith(documentCardPositionsTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = documentCardPositionsTableConfigSlice.reducer(state.tableConfig, action);
          }
        );
    }
  });

  return {
    rootSlice,
    documentCardPositionsTableConfigSlice,
  };
};
