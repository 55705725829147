import { AppTableOpenButton } from '@/common/components/app-table/components/app-table-open-button/AppTableOpenButton';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getLogicalValueLabel } from '@/common/utils/common/types-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';
import { NomenclatureTableColumns } from '@/modules/references/models/nomenclature/nomenclature-table-columns';
import { convertNomenclatureColumnFieldToDto } from '@/modules/references/models/nomenclature/nomenclature-table-columns-converters';
import { setIsDialogOpened, updateCurrentNomenclature } from '@/modules/references/store/nomenclature-mdlp-comparison';
import { useAppDispatch } from '@/store';
import React, { useCallback, useMemo } from 'react';

export function useNomenclatureTableSettings() {
  const dispatch = useAppDispatch();

  const searchPlaceholder = getSearchString([Labels.nameHeader]);

  const onOpenNomenclatureMdlpComparisonClick = useCallback((entity: NomenclatureItem) => {
    dispatch(updateCurrentNomenclature(entity));
    dispatch(setIsDialogOpened(true));
  }, []);

  const columns = useMemo<AppTableColumnDefType<NomenclatureItem>[]>(
    () => [
      {
        id: NomenclatureTableColumns.Code,
        header: Labels.code,
        accessorKey: convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Code),
      },
      {
        id: NomenclatureTableColumns.Name,
        header: Labels.nameHeader,
        accessorKey: convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Name),
      },
      {
        id: NomenclatureTableColumns.Producer,
        header: Labels.producer,
        accessorKey: convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Producer),
      },
      {
        id: NomenclatureTableColumns.Country,
        header: Labels.producerCountry,
        accessorKey: convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Country),
      },
      {
        id: NomenclatureTableColumns.Barcode,
        header: Labels.barCode,
        accessorKey: convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Barcode),
      },
      {
        id: NomenclatureTableColumns.Vital,
        header: Labels.vital,
        accessorFn: (row) => {
          const modelField = convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Vital);
          const isVital = row[modelField] as boolean;
          return getLogicalValueLabel(isVital);
        },
      },
      {
        id: NomenclatureTableColumns.Gtin,
        header: Labels.gtin,
        accessorKey: convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.Gtin),
      },
      {
        id: NomenclatureTableColumns.IsGtinFound,
        header: Labels.isGtinFound,
        accessorFn: (row) => {
          const modelField = convertNomenclatureColumnFieldToDto(NomenclatureTableColumns.IsGtinFound);
          const isGtinFound = row[modelField] as boolean;
          return getLogicalValueLabel(isGtinFound);
        },
      },
      {
        id: NomenclatureTableColumns.MdlpComparison,
        header: Labels.mdlpComparison,
        accessorFn: (row) => <AppTableOpenButton
          text="Открыть"
          onClick={() => onOpenNomenclatureMdlpComparisonClick(row)}
        />,
        enableSorting: false,
      },
    ], []);

  return {
    columns,
    searchPlaceholder,
  };
}
