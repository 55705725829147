export enum DialogType {
  Adding,
  Editing,
}

export interface AppDialogConfig {
  type: DialogType;
}

export interface HasDialogConfig {
  dialogConfig: AppDialogConfig;
}
