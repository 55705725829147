import { disposalCardScanningModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardScanningApi } from '@/modules/disposals/store/disposal-card/scanning/async-thunks';
import { createDocumentCardScanningSlice } from '@/shared/document/store/document-card/scanning';

export const disposalCardScanningSlice = createDocumentCardScanningSlice({
  moduleName: disposalCardScanningModuleName,
  savedTableConfigName: 'disposal-card-scanning-table',
  api: disposalCardScanningApi,
});

export const disposalCardScanningRootActions = disposalCardScanningSlice.rootSlice.actions;
export const disposalCardScanningPageableDataActions = disposalCardScanningSlice.documentCardScanningPageableDataSlice.actions;
export const disposalCardScanningSearchingActions = disposalCardScanningSlice.documentCardScanningSearchingSlice.actions;
export const disposalCardScanningTableConfigActions = disposalCardScanningSlice.documentCardScanningTableConfigSlice.actions;
export const disposalCardScanningFilterConfigSlice = disposalCardScanningSlice.documentCardScanningFilterConfigSlice.actions;

export default disposalCardScanningSlice.rootSlice.reducer;
