import { AppTableRowActionMenuItemModel } from '@/common/models/app-table/app-table-row-action-menu-item-model';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { RemainsTableActions } from '@/modules/remains/hooks/use-remains-table-actions';
import { Remain } from '@/shared/remains/models/remain';
import { useCallback, useMemo } from 'react';

type RemainsTableRowActionsHookParams = RemainsTableActions;

export function useRemainsTableRowActions({ unboxRemain }: RemainsTableRowActionsHookParams) {
  const unboxRemainActionItem: AppTableRowActionMenuItemModel<Remain> = useMemo(() => ({
    key: 'unbox-remain',
    title: 'Разагрегировать третичную упаковку',
    action: (row) => unboxRemain(row.original?.sscc),
  }), []);

  const getRowActions = useCallback((row: AppTableRowType<Remain>) => {
    const items: AppTableRowActionMenuItemModel<Remain>[] = [];

    if (row.original?.sscc) {
      items.push(unboxRemainActionItem);
    }

    return items;
  }, [unboxRemainActionItem]);

  return {
    getRowActions,
  };
}
