import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { DocumentCardHistoryTableColumns } from '@/shared/document/models/document-card/history/document-card-history-table-columns';

const columnVisibility = {
  [DocumentCardHistoryTableColumns.OperationDate]: true,
  [DocumentCardHistoryTableColumns.OperationName]: true,
  [DocumentCardHistoryTableColumns.Editor]: true,
};

const sorting = [
  {
    id: DocumentCardHistoryTableColumns.OperationDate,
    desc: true,
  }
];

const columnOrder = [
  DocumentCardHistoryTableColumns.OperationDate,
  DocumentCardHistoryTableColumns.OperationName,
  DocumentCardHistoryTableColumns.Editor,
  AppTableActionColumnName,
];

const columnSizing = {
  [DocumentCardHistoryTableColumns.OperationDate]: 40,
  [DocumentCardHistoryTableColumns.OperationName]: 150,
  [DocumentCardHistoryTableColumns.Editor]: 150,
};

export const defaultDocumentCardHistoryTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
