import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DialogType } from '@/common/models/app-dialog/app-dialog-config';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { differenceInSecondsWithCurrentTime } from '@/common/utils/common/date-utils';
import { InviteUserResponse } from '@/modules/users-management/models/invite-user/invite-user-response';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { convertUserDtoModelToFormModel } from '@/modules/users-management/models/user-info/user-info-form-data';
import { UserState } from '@/modules/users-management/models/user-state';
import {
  patchUser,
  updateEditUser,
  updateEditUserDialogTypeConfig,
  updateIndexEditUser,
  updateOpenEditUserDialog
} from '@/modules/users-management/store';
import {
  activateUser as activateUserAction,
  blockUser as blockUserAction,
  inviteUser as inviteUserAction
} from '@/modules/users-management/store/async-thunks';
import { useAppDispatch } from '@/store';
import { useState } from 'react';

export interface UsersManagementTableActions {
  isActivateUserLoading: boolean;
  isBlockUserLoading: boolean;
  isInviteUserLoading: boolean;
  addUser: () => void;
  editUser: (row: AppTableRowType<UserInfo>) => void;
  activateUser: (row: AppTableRowType<UserInfo>) => void;
  blockUser: (row: AppTableRowType<UserInfo>) => void;
  inviteUser: (row: AppTableRowType<UserInfo>) => void;
}

export function useUsersManagementTableActions(): UsersManagementTableActions {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const toast = useAppToast();

  const [isActivateUserLoading, setIsActivateUserLoading] = useState(false);
  const [isBlockUserLoading, setIsBlockUserLoading] = useState(false);
  const [isInviteUserLoading, setIsInviteUserLoading] = useState(false);

  const addUser = () => {
    dispatch(updateEditUserDialogTypeConfig(DialogType.Adding));
    dispatch(updateOpenEditUserDialog(true));
  };

  const editUser = (row: AppTableRowType<UserInfo>) => {
    dispatch(updateEditUserDialogTypeConfig(DialogType.Editing));
    dispatch(updateEditUser(convertUserDtoModelToFormModel(row.original)));
    dispatch(updateIndexEditUser(row.index));
    dispatch(updateOpenEditUserDialog(true));
  };

  const activateUser = (row: AppTableRowType<UserInfo>) => {
    setIsActivateUserLoading(true);

    return asyncDispatch(
      () => activateUserAction(row.original.userUuid),
      () => {
        const updatedUser = { ...row.original, userState: UserState.Active };
        dispatch(patchUser({ item: updatedUser, index: row.index }));
        setIsActivateUserLoading(false);
        toast('Пользователь успешно активирован');
      },
      () => { setIsActivateUserLoading(false); }
    );
  };

  const blockUser = (row: AppTableRowType<UserInfo>) => {
    setIsBlockUserLoading(true);

    return asyncDispatch(
      () => blockUserAction(row.original.userUuid),
      () => {
        const updatedUser = { ...row.original, userState: UserState.Blocked };
        dispatch(patchUser({ item: updatedUser, index: row.index }));
        setIsBlockUserLoading(false);
        toast('Пользователь успешно заблокирован');
      },
      () => { setIsBlockUserLoading(false); }
    );
  };

  const inviteUser = (row: AppTableRowType<UserInfo>) => {
    setIsInviteUserLoading(true);

    return asyncDispatch<InviteUserResponse>(
      () => (inviteUserAction(row.original.userUuid)),
      (response) => {
        setIsInviteUserLoading(false);
        if (response.isSuccess) {
          toast('Указанному пользователю отправлены сообщения с приглашением завершить регистрацию в системе');
        } else {
          const timeout = differenceInSecondsWithCurrentTime(response.nextInviteResend);
          toast(`Не удалось отправить сообщения с приглашением указанному пользователю. Повторная отправка возможна через ${timeout} секунд(ы).`, 'error');
        }
      },
      () => { setIsInviteUserLoading(false); }
    );
  };

  return {
    isActivateUserLoading,
    isBlockUserLoading,
    isInviteUserLoading,
    addUser,
    editUser,
    activateUser,
    blockUser,
    inviteUser,
  };
}
