import { LoadingStatus } from '@/common/models/loading-status';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { portalSyncModuleName } from '@/modules/portal/constants/portal-module-names';
import { SyncData, SyncDataInProccess, SyncDataIsAbleToLoading } from '@/modules/portal/models/sync/sync-data';
import { getSyncInfo, startSync } from '@/modules/portal/store/sync/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SyncState {
  openUpdateSyncDialog: boolean;
  syncData: SyncData;
  updateSyncDialogLoadingStatus: LoadingStatus;
  syncDataInProgress: SyncDataInProccess;
}

const initialState: SyncState = {
  openUpdateSyncDialog: false,
  syncData: undefined,
  updateSyncDialogLoadingStatus: undefined,
  syncDataInProgress: {}
};

export const syncSlice = createSlice({
  name: portalSyncModuleName,
  initialState,
  reducers: {
    setOpenUpdateSyncDialog(state, action: PayloadAction<boolean>) {
      state.openUpdateSyncDialog = action.payload;
    },
    setSyncDataInProgress(state, action: PayloadAction<SyncDataIsAbleToLoading>) {
      state.syncDataInProgress[action.payload] = true;
    },
    removeSyncDataInProgress(state, action: PayloadAction<SyncDataIsAbleToLoading>) {
      delete state.syncDataInProgress[action.payload];
    },
    setMdlpSyncInfo(state, action: PayloadAction<SyncData['mdlpSyncInfo']>) {
      state.syncData.mdlpSyncInfo = {
        ...state.syncData.mdlpSyncInfo,
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSyncInfo.pending, (state, action) => {
        const isFirstRequest = action.meta.arg;
        if (isFirstRequest) {
          state.updateSyncDialogLoadingStatus = LoadingStatus.Pending;
        }
      })
      .addCase(getSyncInfo.fulfilled, (state, action) => {
        const isFirstRequest = action.meta.arg;
        if (isFirstRequest) {
          state.updateSyncDialogLoadingStatus = LoadingStatus.Fulfilled;
        }
        state.syncData = action.payload;
      })
      .addCase(getSyncInfo.rejected, (state) => {
        state.updateSyncDialogLoadingStatus = LoadingStatus.Rejected;
      })
      .addCase(startSync.pending, (state, action) => {
        const { setSyncDataInProgress } = syncSlice.caseReducers;
        setSyncDataInProgress(state, getReducerAction(setSyncDataInProgress.name, action.meta.arg));
      })
      .addCase(startSync.fulfilled, (state, action) => {
        const { removeSyncDataInProgress } = syncSlice.caseReducers;

        state.syncData.mdlpSyncInfo = {
          ...state.syncData.mdlpSyncInfo,
          ...action.payload.mdlpSyncInfo
        };

        removeSyncDataInProgress(state, getReducerAction(removeSyncDataInProgress.name, action.meta.arg));
      })
      .addCase(startSync.rejected, (state, action) => {
        const { removeSyncDataInProgress } = syncSlice.caseReducers;
        removeSyncDataInProgress(state, getReducerAction(removeSyncDataInProgress.name, action.meta.arg));
      });
  }
});

export const {
  setOpenUpdateSyncDialog,
  setSyncDataInProgress,
  removeSyncDataInProgress,
  setMdlpSyncInfo
} = syncSlice.actions;

export default syncSlice.reducer;
