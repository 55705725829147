import { fullDateTimeFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getShortFioFromFullName } from '@/common/utils/common/common-user-data-utils';
import { formatDate } from '@/common/utils/common/date-utils';
import { sortDates } from '@/common/utils/sorting/sorting-functions';
import { DocumentCardHistory } from '@/shared/document/models/document-card/history/document-card-history';
import { DocumentCardHistoryTableColumns } from '@/shared/document/models/document-card/history/document-card-history-table-columns';
import { getDocumentStatusTitle } from '@/shared/document/models/document-status';
import { useMemo } from 'react';

export function useDocumentCardHistoryTableSettings() {

  const columns = useMemo<AppTableColumnDefType<DocumentCardHistory>[]>(
    () => [
      {
        id: DocumentCardHistoryTableColumns.OperationDate,
        header: Labels.operationDate,
        accessorFn: (row) => formatDate(row.operationDate, fullDateTimeFormat),
        sortingFn: (row1, row2) => sortDates(row1.original.operationDate, row2.original.operationDate),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: DocumentCardHistoryTableColumns.OperationName,
        header: Labels.operationName,
        accessorFn: (row) => getDocumentStatusTitle(row.docState),
      },
      {
        id: DocumentCardHistoryTableColumns.Editor,
        header: Labels.editor,
        accessorFn: (row) => getShortFioFromFullName(row.userFullName),
      }
    ], []);

  return {
    columns,
  };
}
