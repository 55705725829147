import { getISOString } from '@/common/utils/common/date-utils';
import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { InteractionLogFilter } from '@/modules/interaction-log/models/interaction-log-filter';
import { getTargetOppositeSource } from '@/modules/interaction-log/models/interaction-origin';

const getSearchFields = (canSearchByOrganizationName: boolean) => {
  const userSearchFields: (keyof InteractionItem)[] = ['userFullName', 'docNumber'];
  if (canSearchByOrganizationName) {
    userSearchFields.push('ownOrganizationName');
  }
  return userSearchFields;
};

function getInteractionLogRsqlFilter(filter: Partial<InteractionLogFilter>): RsqlFilterModel<InteractionItem>[] {
  return [
    {
      selector: 'docCreationDateInMdlp',
      operation: RsqlFilterOperations.GreaterEq,
      value: getISOString(filter.startDate),
    },
    {
      selector: 'docCreationDateInMdlp',
      operation: RsqlFilterOperations.LessEq,
      value: getISOString(filter?.endDate),
    },
    {
      selector: 'eventState',
      operation: RsqlFilterOperations.In,
      value: filter?.statuses,
    },
    {
      selector: 'docSchema',
      operation: RsqlFilterOperations.In,
      value: filter?.events,
    },
    {
      selector: 'target',
      operation: RsqlFilterOperations.Equal,
      value: filter.source && getTargetOppositeSource(filter.source)
    },
    {
      selector: 'source',
      operation: RsqlFilterOperations.Equal,
      value: filter.source
    },
  ];
}

function getInteractionLogSearchFilter(searchString: string, canSearchByOrganizationName: boolean): RsqlFilterModel<InteractionItem>[] {
  const searchFields = getSearchFields(canSearchByOrganizationName);
  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: searchString
  }));
}

export function buildInteractionLogFilterExpression(
  filter: Partial<InteractionLogFilter>, searchString: string, canSearchByOrganizationName: boolean
): string {
  const filterModel = getInteractionLogRsqlFilter(filter);
  const searchModel = getInteractionLogSearchFilter(searchString, canSearchByOrganizationName);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}