import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { required } from '@/common/utils/client-validation/validation-rules';
import { RegistrationFormCodes, RegistrationFormCodesFields, RegistrationFormCodesValidation } from '@/modules/auth/models/registration-form-codes';

export function useRegistrationFormCodesValidation(data: Partial<RegistrationFormCodes>) {
  const codesMaxLength = 5;

  const validationRules: FormValidationRules<RegistrationFormCodesValidation, RegistrationFormCodesFields> = {
    phoneNumber: [required()],
    email: [required()],
  };

  const dataInner: RegistrationFormCodesValidation = {
    email: data.email?.value ?? '',
    phoneNumber: data.phoneNumber?.value ?? ''
  };

  return {
    codesMaxLength,
    ...useFormValidation({ validationRules, data: dataInner }),
  };
}
