import { organizationInfoModuleName } from '@/modules/organization-profile/constants/organization-profile-module-names';
import { OrganizationInfoDto } from '@/modules/organization-profile/models/dto/organization-info-dto';
import { OrganizationProfileRequests } from '@/modules/organization-profile/requests/organization-profile-requests';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getOrganizationInfo = createAsyncThunkWrapper<
  OrganizationInfoDto,
  undefined
>(
  `${organizationInfoModuleName}/getOrganizationInfo`,
  () => OrganizationProfileRequests.getOrganizationInfo()
    .then((response) => response.data),
);
