import ClearIcon from '@/assets/icons/controls/cross.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { InputAdornment } from '@mui/material';
import React, { FC, memo } from 'react';
import './app-end-adornment.scss';

interface AppEndAdornmentProps {
  showClearButton: boolean;
  iconEnd?: React.ReactNode;
  className?: string;
  onClearButtonClick?: () => void;
}

const AppEndAdornmentInner: FC<AppEndAdornmentProps> = ({
  showClearButton,
  iconEnd,
  className,
  onClearButtonClick,
}) => {
  const clearIconComponent =
    <AppIconButton
      icon={<ClearIcon className="app-end-adornment-icon" />}
      initialSize
      edge="end"
      onClick={onClearButtonClick}
    />;

  return (
    <InputAdornment
      position="end"
      className={className}
    >
      {iconEnd}
      {showClearButton && clearIconComponent}
    </InputAdornment>
  );
};

export const AppEndAdornment = memo(AppEndAdornmentInner);
