import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { DocumentCardScanningPageProps } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import { useSelector } from 'react-redux';

type DocumentCardScanningTableDataSourceHookParams = Pick<DocumentCardScanningPageProps,
  'documentCardSelectors' | 'documentCardScanningSelectors' | 'documentCardScanningPageableDataActions' | 'documentCardScanningApi'
>;

export function useDocumentCardScanningTableDataSource({
  documentCardSelectors,
  documentCardScanningSelectors,
  documentCardScanningPageableDataActions,
  documentCardScanningApi
}: DocumentCardScanningTableDataSourceHookParams) {

  const documentId = useSelector(documentCardSelectors.selectDocumentId);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: documentCardScanningPageableDataActions,
    selectPageableDataState: documentCardScanningSelectors.selectPageableDataState,
    getData: documentCardScanningApi.getAllScannedItems,
    resetCondition: documentId
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
