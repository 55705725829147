import { axios } from '@/common/api/axios-config';
import { activateUserUrl, allUsersUrl, blockUserUrl, inviteManagerUrl, manangerUrl, userUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { InviteUserResponse } from '@/modules/users-management/models/invite-user/invite-user-response';
import { SaveNewManagerResponse } from '@/modules/users-management/models/save-new-user/save-new-user-response';
import { AddManagerBody } from '@/modules/users-management/models/user-info/add-user-body';
import { EditUserBody } from '@/modules/users-management/models/user-info/edit-user-body';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { AxiosResponse } from 'axios';

export class UsersManagementRequests {
  static getAllUsers(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<UserInfo>>> {
    return axios.get(allUsersUrl, { params });
  }

  static addManager(body: Partial<AddManagerBody>): Promise<AxiosResponse<SaveNewManagerResponse>> {
    return axios.post(manangerUrl, body);
  }

  static editUser(body: Partial<EditUserBody>): Promise<AxiosResponse<UserInfo>> {
    return axios.put(userUrl, body);
  }

  static activateUser(userUuid: string): Promise<AxiosResponse<void>> {
    return axios.patch(activateUserUrl, { userUuid });
  }

  static blockUser(userUuid: string): Promise<AxiosResponse<void>> {
    return axios.patch(blockUserUrl, { userUuid });
  }

  static inviteUser(userUuid: string): Promise<AxiosResponse<InviteUserResponse>> {
    return axios.post(inviteManagerUrl, { userUuid });
  }
}

