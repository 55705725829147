import { useCopyToClipboard } from '@/common/hooks/use-copy-to-clipboard';
import { useCallback, useState } from 'react';

export function useOrganizationInfoCopyToClipboard(value: string) {
  const { copy } = useCopyToClipboard();

  const [isCopied, setIsCopied] = useState(false);

  const copyTooltip = isCopied ? 'Текст скопирован в буфер обмена' : 'Скопировать';

  const copyValue = useCallback(() => {
    copy(value)
      .then((isCopied) => {
        setIsCopied(isCopied);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(() => setIsCopied(false));
  }, []);

  return {
    copyTooltip,
    copyValue,
  };
}
