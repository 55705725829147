import { AppTableRowActionMenuItem } from '@/common/components/app-table/components/app-table-row-action-menu-item/AppTableRowActionMenuItem';
import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { DeleteButton } from '@/common/components/app-table/components/delete-button/DeleteButton';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import React, { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import '@/common/components/app-table/styles/app-table-styles.scss';

type AppTableSettingsParams = Pick<AppTableProps, 'getRowActions' | 'onRowDoubleClick' | 'showDeleteRowButton' | 'onDeleteRow' | 'renderEmptyRowsFallback' | 'isLoading'>;

export interface AppTableSettings {
  renderRowActionMenuItems: ((data: { row: AppTableRowType; closeMenu: () => void; }) => ReactNode[]) | undefined;
  onRowDoubleClickInner: (event: MouseEvent<HTMLElement>, row: AppTableRowType) => void;
  renderRowActions: (data: { row: AppTableRowType; }) => ReactNode | undefined;
  renderEmptyRowsFallbackInner?: () => React.ReactNode;
}

export function useAppTableSettings({ isLoading, showDeleteRowButton, onDeleteRow, getRowActions, onRowDoubleClick, renderEmptyRowsFallback }: AppTableSettingsParams): AppTableSettings {
  const renderRowActionMenuItemsCallback = useCallback(({ closeMenu, row }: { closeMenu: () => void; row: AppTableRowType; }) => {
    return getRowActions(row).map((item) =>
      <AppTableRowActionMenuItem
        key={item.key}
        item={item}
        row={row}
        closeMenu={closeMenu}
      />
    );
  }, [getRowActions]);

  const renderRowActionMenuItems = useMemo(() => getRowActions ? renderRowActionMenuItemsCallback : undefined, [getRowActions]);

  const onRowDoubleClickInner = (event: MouseEvent<HTMLElement>, row: AppTableRowType) => {
    // preventing double click on other elements (different from a table cell) - checkboxes, custom buttons, action menu.
    if (event?.target?.tagName === 'TD') {
      onRowDoubleClick?.(row);
    }
  };
  const renderRowActions = useMemo(() => {
    return onDeleteRow && showDeleteRowButton
      ? ({ row }: { row: AppTableRowType; }) => <DeleteButton onDelete={() => onDeleteRow(row)} />
      : undefined;
  }, [onDeleteRow, showDeleteRowButton]);

  const renderEmptyRowsFallbackInner = useMemo(() =>
    isLoading
      ? () => <div className="app-table-empty-rows-placeholder">Загрузка данных...</div>
      : renderEmptyRowsFallback
    , [isLoading, renderEmptyRowsFallback]);

  return {
    renderRowActionMenuItems,
    onRowDoubleClickInner,
    renderRowActions,
    renderEmptyRowsFallbackInner
  };
}
