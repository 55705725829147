import { ThunkApi } from '@/store';
import { RootState } from '.';

export type AsyncThunkConditionOptionParam<Args> = (state: RootState, args?: Args) => boolean;

type AsyncThunkConditionOptionReturnValue<Args> = (args: Args, config: ThunkApi) => boolean | undefined;

export const asyncThunkConditionOption = <Args>(condition: AsyncThunkConditionOptionParam<Args>): AsyncThunkConditionOptionReturnValue<Args> =>
  (args, { getState }) => {
    const isAborted = condition(getState(), args);
    if (isAborted) {
      return false;
    }
  };
