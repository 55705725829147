import { AppInput } from '@/common/components/app-input/AppInput';
import { AppInputCommonProps } from '@/common/models/app-input/app-input-common-props';
import { removeNonDigits } from '@/common/utils/common/string-utils';
import React, { ClipboardEvent, FC, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';

const INPUT_POSITIVE_REGEX = /^\d*$/;

const allowedKeyboardKeys = new Set([
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'Backspace',
  'Delete'
]);

const copyPasteKeyboardKeys = new Set([
  // latin letters
  'c',
  'v',
  'a',
  'x',
  // cyrillic letters
  'c',
  'м',
  'ф',
  'ч',
]);

type AppNumberInputProps = AppInputCommonProps<string>;

const AppNumberInputInner: FC<AppNumberInputProps> = (props) => {
  const [innerValue, setInnerValue] = useState(props.value);

  useEffect(() => { setInnerValue(props.value); }, [props.value]);

  const onInput = useCallback((value: string) => {
    setInnerValue(value);
    props.onInput?.(value);
  }, []);

  const onValueChange = useCallback((value: string) => {
    setInnerValue(value);
  }, []);

  const onBlur = useCallback(() => {
    if (innerValue !== props.value) {
      props.onValueChange?.(innerValue);
    }
  }, [props.value, innerValue]);

  const onPaste = useCallback((event: ClipboardEvent) => {
    event.preventDefault();

    if (!!props.maxLength && props.maxLength === innerValue?.length) {
      return;
    }

    const pastedData = event.clipboardData.getData('Text');
    let processedData = removeNonDigits(pastedData);

    if (props.maxLength) {
      const pastedDataLength = props.maxLength - (innerValue?.length ?? 0);
      processedData = processedData.substring(0, pastedDataLength);
    }

    const target = event.target as HTMLInputElement;
    const cursorPosition = target?.selectionStart ?? innerValue?.length ?? 0;
    const newValue = (innerValue?.substring(0, cursorPosition) ?? '') + processedData + (innerValue?.substring(cursorPosition) ?? '');
    const newCursorPosition = cursorPosition + processedData.length;

    setInnerValue(newValue);
    // It is needed to put cursor after pasted string.
    // Without this cursor will go to the end of the all received string.
    setTimeout(() => target?.setSelectionRange(newCursorPosition, newCursorPosition), 10);

    props.onInput?.(newValue);
  }, [innerValue]);

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    const { key, ctrlKey } = event;

    if (allowedKeyboardKeys.has(key) || ctrlKey && (copyPasteKeyboardKeys.has(key))) {
      return;
    }

    if (!INPUT_POSITIVE_REGEX.test(key)) {
      event.preventDefault();
    }
  }, []);

  return (
    <AppInput
      {...props}
      value={innerValue}
      onInput={onInput}
      onValueChange={onValueChange}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      onBlur={onBlur}
    />
  );
};

export const AppNumberInput = memo(AppNumberInputInner);
