import { AppButton } from '@/common/components/app-button/AppButton';
import { AuthLayout } from '@/modules/auth/components/base-auth-layout/AuthLayout';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import './app-error-boundary.scss';

export const AppErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    console.error(error);
  }, [error]);

  const navigateToMainPage = useCallback(() => {
    navigate('/');
  }, []);

  return (
    <AuthLayout padding >
      <div className="app-error-boundary-container">
        <div className="app-error-boundary-message">
          <h2>Произошла ошибка сервиса, попробуйте повторить операцию позже. <br /> Приносим извинения за неудобства</h2>
        </div>
        <AppButton
          label="Вернуться на главную"
          onClick={navigateToMainPage}
        />
      </div>
    </AuthLayout>
  );
};