import React, { FC, memo, useCallback, useMemo } from 'react';
import { AppCard } from '@/common/components/app-card/AppCard';
import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { OrganizationInfoBlock } from '@/modules/organization-profile/components/organization-info-block/OrganizationInfoBlock';
import { OrganizationInfoCopyable } from '@/modules/organization-profile/components/organization-info-copyable/OrganizationInfoCopyable';
import { OrganizationPlaceCardType } from '@/modules/organization-profile/components/organization-place-card-type/OrganizationPlaceCardType';
import { OrganizationPlace } from '@/modules/organization-profile/models/organization-place';
import { OrganizationPlaceType } from '@/modules/organization-profile/models/organization-place-type';
import { getOrganizationStateTitle } from '@/modules/organization-profile/models/organization-state';
import { updateOrganizationPlaceType as updateOrganizationPlaceTypeAsync } from '@/modules/organization-profile/store/organization-places/async-thunks';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import './organization-place-card.scss';

interface OrganizationPlaceCardProps {
  model: OrganizationPlace;
}

const OrganizationPlaceCardInner: FC<OrganizationPlaceCardProps> = ({ model }) => {
  const { asyncDispatch } = useAsyncDispatch();
  const toast = useAppToast();
  const { hasAccessByPermission } = useUserPermissionsAccess();

  const onPlaceTypeChange = useCallback((value: OrganizationPlaceType) => {
    const body = {
      activityPlaceUuid: model.activityPlaceUuid,
      type: value,
    };

    asyncDispatch(
      () => updateOrganizationPlaceTypeAsync(body),
      () => toast('Тип места деятельности успешно изменен'),
      () => toast('Не удалось изменить тип места деятельности', 'error')
    );
  }, [model.activityPlaceUuid]);

  const isOrganizationPlaceTypeEditable = useMemo(() => hasAccessByPermission(UserPermission.EditOrganizationProfile), []);

  return (
    <AppCard>
      <div className="organization-place-card-row">
        <OrganizationInfoCopyable
          title="Идентификатор"
          value={model.mdlpId}
        />
        <OrganizationPlaceCardType
          editable={isOrganizationPlaceTypeEditable}
          value={model.type}
          selectTypeClassName="organization-place-card-type"
          onChange={onPlaceTypeChange}
        />
      </div>
      <div className="organization-place-card-row">
        <OrganizationInfoCopyable
          title="Название"
          value={model.name}
        />
      </div>
      <div className="organization-place-card-row">
        <OrganizationInfoCopyable
          title="Адрес полный"
          value={model.address}
        />
        <OrganizationInfoBlock
          title="Статус"
          value={getOrganizationStateTitle(model.state)}
          alignment="end"
          textAlignValue="right"
        />
      </div>
    </AppCard>
  );
};

export const OrganizationPlaceCard = memo(OrganizationPlaceCardInner);
