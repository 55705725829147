import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getLogicalValueLabel } from '@/common/utils/common/types-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { convertMdlpProductColumnFieldToDto } from '@/modules/references/models/mdlp-products/mdlp-products-table-columns-converters';
import { MdlpTableColumns } from '@/modules/references/models/mdlp-products/mdlp-table-columns';
import { useMemo } from 'react';

export function useMdlpProductsTableSettings() {
  const searchPlaceholder = getSearchString([Labels.nameHeader]);

  const columns = useMemo<AppTableColumnDefType<MdlpProduct>[]>(
    () => [
      {
        id: MdlpTableColumns.Name,
        header: Labels.nameHeader,
        accessorKey: convertMdlpProductColumnFieldToDto(MdlpTableColumns.Name),
      },
      {
        id: MdlpTableColumns.Producer,
        header: Labels.producer,
        accessorKey: convertMdlpProductColumnFieldToDto(MdlpTableColumns.Producer),
      },
      {
        id: MdlpTableColumns.Country,
        header: Labels.producerCountry,
        accessorKey: convertMdlpProductColumnFieldToDto(MdlpTableColumns.Country),
      },
      {
        id: MdlpTableColumns.NonProprietaryName,
        header: Labels.nonProprietaryName,
        accessorKey: convertMdlpProductColumnFieldToDto(MdlpTableColumns.NonProprietaryName),
      },
      {
        id: MdlpTableColumns.Vital,
        header: Labels.vital,
        accessorFn: (row) => {
          const modelField = convertMdlpProductColumnFieldToDto(MdlpTableColumns.Vital);
          const isVital = row[modelField] as boolean;
          return getLogicalValueLabel(isVital);
        },
      },
      {
        id: MdlpTableColumns.Gtin,
        header: Labels.gtin,
        accessorKey: convertMdlpProductColumnFieldToDto(MdlpTableColumns.Gtin),
      },
    ], []);

  return {
    columns,
    searchPlaceholder,
  };
}
