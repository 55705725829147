import { useScrolling } from '@/common/hooks/use-scrolling';
import React, { forwardRef, memo, useImperativeHandle, useRef } from 'react';
import './scrollable-content.scss';

export interface ScrollableContentComponent {
  scrollToTop: () => void;
}

const defaultScrollBottomPadding = 500;

interface ScrollableContentProps {
  children: React.ReactNode;
  scrollBottomPadding?: number;
  onScrollAtTheEnd?: () => void;
}

const ScrollableContentInner = forwardRef<ScrollableContentComponent, ScrollableContentProps>(({
  children,
  scrollBottomPadding = defaultScrollBottomPadding,
  onScrollAtTheEnd
}, ref) => {
  const scrollableContentRef = useRef<HTMLDivElement>();

  const scrollToTop = () => {
    scrollableContentRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  useImperativeHandle(ref, () => ({ scrollToTop }), []);

  const { onScroll } = useScrolling({ onScrollAtTheEnd, scrollBottomPadding });

  return (
    <div
      className="scrollable-content"
      ref={scrollableContentRef}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
});

ScrollableContentInner.displayName = 'ScrollableContent';

export const ScrollableContent = memo(ScrollableContentInner);
