import { DocumentCardPosition } from '@/shared/document/models/document-card/positions/document-card-position';
import { DocumentCardPositionType } from '@/shared/document/models/document-card/positions/document-card-position-type';
import { DocumentCardPositionsRequests } from '@/shared/document/requests/document-card-positions-requests';
import { DocumentCardGeneralPositionsSelectors } from '@/shared/document/store/document-card/general-positions/types';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardGenerallPositionsThunksCreationParams {
  moduleName: string;
  documentCardGeneralPositionsSelectors: DocumentCardGeneralPositionsSelectors;
}

export const createDocumentCardGeneralPositionsThunks = ({ moduleName, documentCardGeneralPositionsSelectors }: DocumentCardGenerallPositionsThunksCreationParams) => {
  const getGeneralPositions = createAsyncThunkWrapper<
    DocumentCardPosition[],
    string
  >(`${moduleName}/getGeneralPositions`,
    (documentId, { signal }) => DocumentCardPositionsRequests.getAllPositions(documentId, DocumentCardPositionType.General, signal)
      .then((res) => res.data.positions),
    {
      condition: asyncThunkConditionOption((state, documentId) => {
        const isLoading = documentCardGeneralPositionsSelectors.selectIsLoadingPositions(state);
        return isLoading || !documentId;
      })
    }
  );

  return {
    getGeneralPositions
  };
};
