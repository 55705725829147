import { AppEndAdornment } from '@/common/components/app-end-adornment/AppEndAdornment';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { SelectOption } from '@/common/models/app-select/select-option';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';
import './app-select.scss';

interface AppSelectProps<T = string | boolean | number> {
  options: SelectOption<T>[];
  className?: string;
  value?: T;
  label?: string;
  disabled?: boolean;
  maxWidth?: number;
  error?: boolean;
  helperText?: string;
  showClearButton?: boolean;
  onChange?: (value: T | undefined) => void;
}

const AppSelectInner: FC<AppSelectProps> = ({
  options,
  className,
  value = '',
  label,
  disabled = false,
  maxWidth,
  error = false,
  helperText,
  showClearButton,
  onChange,
}) => {
  const onChangeInner = useCallback((event: SelectChangeEvent) => onChange?.(event.target.value), []);
  const onClear = useCallback(() => onChange(undefined), []);

  return (
    <FormControl className={className}>
      <InputLabel
        id="app-select-label"
        className="app-select-label"
        disabled={disabled}
        error={error}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        className="app-select"
        label={label}
        labelId="app-select-label"
        disabled={disabled}
        error={error}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
        endAdornment={
          showClearButton && value != null && value !== '' && (
            <AppEndAdornment
              showClearButton
              className="app-select-end-icon"
              onClearButtonClick={onClear}
            />
          )}
        onChange={onChangeInner}
      >
        {options.map((option) => <MenuItem
          className="app-select-menu-item"
          key={option.value?.toString()}
          value={option.value as any}
          style={{
            maxWidth
          }}
        >
          <AppTooltip
            text={option.label}
            open="onlyOnOverflow"
          >
            <span className="ellipsis-text">{option.label}</span>
          </AppTooltip>
        </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText
          className="app-select-form-helper-text"
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const AppSelect = memo(AppSelectInner);
