import EyeClosed from '@/assets/icons/controls/eye_closed.svg?react';
import EyeOpened from '@/assets/icons/controls/eye_opened.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import React, { useMemo, useState } from 'react';

export function usePasswordInput() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordIcon = useMemo(() => passwordVisible ? <EyeOpened /> : <EyeClosed />, [passwordVisible]);
  const passwordInputType = useMemo(() => passwordVisible ? AppInputType.Text : AppInputType.Password, [passwordVisible]);
  const handleClickShowPassword = () => setPasswordVisible((visible) => !visible);

  const passwordIconComponent =
    <AppIconButton
      initialSize
      icon={passwordIcon}
      edge="end"
      onClick={handleClickShowPassword}
    />;

  return {
    passwordInputType,
    passwordIconComponent,
  };
}
