import { phoneMask } from '@/common/constants/masks';

export const isPhone = (phoneString: string) => {
  if (phoneString.length > phoneMask.length) {
    return false;
  }

  for (let i = 0; i < phoneString.length; i++) {
    const maskSymbol = phoneMask[i];
    const phoneSymbol = phoneString[i];

    if (typeof maskSymbol === 'string') {
      if (phoneSymbol !== maskSymbol) {
        return false;
      }
    } else if (!phoneSymbol.match(maskSymbol)) {
      return false;
    }
  }

  return true;
};
