import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { DocumentCardPositionsTableColumns } from '@/shared/document/models/document-card/positions/document-card-positions-table-columns';

const columnVisibility = {
  [DocumentCardPositionsTableColumns.Code]: true,
  [DocumentCardPositionsTableColumns.Name]: true,
  [DocumentCardPositionsTableColumns.Producer]: true,
  [DocumentCardPositionsTableColumns.Vital]: true,
  [DocumentCardPositionsTableColumns.Gtin]: true,
  [DocumentCardPositionsTableColumns.Series]: true,
  [DocumentCardPositionsTableColumns.ExpirationDate]: true,
  [DocumentCardPositionsTableColumns.PriceWithVat]: true,
  [DocumentCardPositionsTableColumns.Vat]: true,
  [DocumentCardPositionsTableColumns.TotalPriceWithVat]: true,
  [DocumentCardPositionsTableColumns.VatRate]: true,
  [DocumentCardPositionsTableColumns.IsProductInOrder]: true,
  [DocumentCardPositionsTableColumns.CountOfScanned]: true,
  [DocumentCardPositionsTableColumns.Amount]: true,
};

const sorting = [
  {
    id: DocumentCardPositionsTableColumns.Name,
    desc: false,
  }
];

const columnOrder = [
  DocumentCardPositionsTableColumns.Code,
  DocumentCardPositionsTableColumns.Name,
  DocumentCardPositionsTableColumns.Producer,
  DocumentCardPositionsTableColumns.Vital,
  DocumentCardPositionsTableColumns.Gtin,
  DocumentCardPositionsTableColumns.Series,
  DocumentCardPositionsTableColumns.ExpirationDate,
  DocumentCardPositionsTableColumns.PriceWithVat,
  DocumentCardPositionsTableColumns.Vat,
  DocumentCardPositionsTableColumns.TotalPriceWithVat,
  DocumentCardPositionsTableColumns.VatRate,
  DocumentCardPositionsTableColumns.IsProductInOrder,
  DocumentCardPositionsTableColumns.CountOfScanned,
  DocumentCardPositionsTableColumns.Amount,
  AppTableActionColumnName,
];

const columnSizing = {
  [DocumentCardPositionsTableColumns.Code]: 20,
  [DocumentCardPositionsTableColumns.Name]: 55,
  [DocumentCardPositionsTableColumns.Producer]: 55,
  [DocumentCardPositionsTableColumns.Vital]: 20,
  [DocumentCardPositionsTableColumns.Gtin]: 40,
  [DocumentCardPositionsTableColumns.Series]: 40,
  [DocumentCardPositionsTableColumns.ExpirationDate]: 40,
  [DocumentCardPositionsTableColumns.PriceWithVat]: 50,
  [DocumentCardPositionsTableColumns.Vat]: 40,
  [DocumentCardPositionsTableColumns.TotalPriceWithVat]: 60,
  [DocumentCardPositionsTableColumns.VatRate]: 50,
  [DocumentCardPositionsTableColumns.IsProductInOrder]: 30,
  [DocumentCardPositionsTableColumns.CountOfScanned]: 60,
  [DocumentCardPositionsTableColumns.Amount]: 55,
};

export const defaultDocumentCardPositionsTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
