import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { fileOperationsPageableDataActions } from '@/modules/file-operations/store';
import { getAllFileOperations } from '@/modules/file-operations/store/async-thunks';
import { FileOperationsSelectors } from '@/modules/file-operations/store/selectors';
import { useSelector } from 'react-redux';

export function useFileOperationsTableDataSource() {
  const sorting = useSelector(FileOperationsSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: fileOperationsPageableDataActions,
    selectPageableDataState: FileOperationsSelectors.selectPageableDataState,
    getData: getAllFileOperations,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage,
  };
}
