import React, { FC, ReactNode, useEffect } from 'react';
import { useAliveController } from 'react-activation';

interface AppDropAliveComponentProps {
  name: string;
  children: ReactNode;
}

export const AppDropAliveComponent: FC<AppDropAliveComponentProps> = ({ name, children }) => {
  const { drop } = useAliveController();

  useEffect(() => {
    return () => {
      drop(name);
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
};
