import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { HeaderWithLoader } from '@/common/components/custom/header-with-loader/HeaderWithLoader';
import { useOrganizationPlacesDataSource } from '@/common/hooks/common-data-sources/use-organization-places-data-source';
import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DialogType } from '@/common/models/app-dialog/app-dialog-config';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { EditUserForm } from '@/modules/users-management/components/edit-user-dialog/components/edit-user-form/EditUserForm';
import { useEditUserValidation } from '@/modules/users-management/hooks/use-edit-user-validation';
import { DialogClosedParams } from '@/modules/users-management/models/dialog-closed-params';
import { SaveNewUserErrorFields } from '@/modules/users-management/models/save-new-user/save-new-user-error-fields';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { UserInfoFormData, UserInfoFormDataFields } from '@/modules/users-management/models/user-info/user-info-form-data';
import { resetEditUserStates, updateEditUser, updateOpenEditUserDialog } from '@/modules/users-management/store';
import { editUser, saveNewUser } from '@/modules/users-management/store/async-thunks';
import { UsersManagementSelectors } from '@/modules/users-management/store/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './edit-user-dialog.scss';

interface EditUserDialogProps {
  dialogClosed: (params: DialogClosedParams) => void;
}

const EditUserDialogInner: FC<EditUserDialogProps> = ({ dialogClosed }) => {
  const dispatch = useAppDispatch();
  const { fieldErrors, resetFieldErrors, asyncDispatch } = useAsyncDispatch<SaveNewUserErrorFields>();
  const toast = useAppToast();

  const { hasAccessByPermission } = useUserPermissionsAccess();
  const showActivityPlaces = useMemo(() => hasAccessByPermission(UserPermission.ViewActivityPlaces), []);

  const { organizationPlacesOptions, loadOrganizationPlacesOptions } = useOrganizationPlacesDataSource();

  useEffect(() => {
    showActivityPlaces && loadOrganizationPlacesOptions();
  }, [showActivityPlaces]);

  const editingUser = useSelector(UsersManagementSelectors.selectEditingUser);
  const dialogConfig = useSelector(UsersManagementSelectors.selectDialogConfig);
  const isLoadingEditUser = useSelector(UsersManagementSelectors.selectIsLoadingEditUser);
  const indexEditUser = useSelector(UsersManagementSelectors.selectIndexEditUser);
  const isOpenEditUserDialog = useSelector(UsersManagementSelectors.selectIsOpenEditUserDialog);

  const isNewUser = dialogConfig.type == DialogType.Adding;
  const header = isNewUser ? 'Новый пользователь' : 'Редактирование пользователя';

  const onFieldChange = (fieldName: UserInfoFormDataFields) => (value: UserInfoFormData[UserInfoFormDataFields]) => dispatch(updateEditUser({ [fieldName]: value }));

  const handleDialogClose = (successfulSave: boolean, updatedEditUser?: UserInfo) => {
    dispatch(updateOpenEditUserDialog(false));
    dispatch(resetEditUserStates());
    resetValidationResult();
    resetFieldErrors();
    dialogClosed({ successfulSave, updatedEditUser, indexEditUser });
  };

  const closeDialogOnCancel = useCallback(() => handleDialogClose(false), []);

  const onSaveInner = useCallback(() => {
    if (validateForm()) {
      const toastMessage = isNewUser
        ? 'На указанные пользовательские контакты отправлены сообщения с приглашением завершить регистрацию в системе'
        : 'Пользователь успешно изменен';
      const actionCb = isNewUser ? saveNewUser : editUser;
      const thenCb = (res: UserInfo) => {
        handleDialogClose(true, !isNewUser ? res : undefined);
        toast(toastMessage);
      };
      asyncDispatch(
        actionCb,
        thenCb,
      );
    }
  }, [editingUser]);

  const {
    validationResult,
    validateForm,
    resetValidationResult
  } = useEditUserValidation({ data: editingUser, validateActivityPlaces: showActivityPlaces });

  return (
    <AppDialog
      open={isOpenEditUserDialog}
      classes={{ dialog: 'edit-user-dialog' }}
      onClose={closeDialogOnCancel}
      header={
        <HeaderWithLoader
          title={header}
          showLoader={isLoadingEditUser}
          classes={{ title: 'edit-user-dialog-title' }}
        />
      }
      footer={
        <AppButton
          label="Сохранить"
          disabled={isLoadingEditUser}
          onClick={onSaveInner}
        />
      }
    >
      <EditUserForm
        readOnly={isLoadingEditUser}
        userData={editingUser}
        validationResult={validationResult}
        activityPlacesOptions={organizationPlacesOptions}
        fieldErrors={fieldErrors}
        showActivityPlaces={showActivityPlaces}
        onFieldChange={onFieldChange}
      />
    </AppDialog>
  );
};

export const EditUserDialog = memo(EditUserDialogInner);
