import React, { FC, memo } from 'react';
import './loading-icon.scss';

interface LoadingIconProps {
  width?: number;
  height?: number;
}

const LoadingIconInner: FC<LoadingIconProps> = ({ width, height }) => (
  <div
    className="loading-icon"
    style={{
      width: width ?? 16,
      height: height ?? 16
    }}
  >
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </div>
);

export const LoadingIcon = memo(LoadingIconInner);
