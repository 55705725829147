import {
  receiptCardBoxesRootActions,
  receiptCardBoxesTableConfigActions
} from '@/modules/receipts/store/receipt-card/boxes';
import { receiptCardBoxesApi } from '@/modules/receipts/store/receipt-card/boxes/async-thunks';
import { receiptCardBoxesSelectors } from '@/modules/receipts/store/receipt-card/boxes/selectors';
import { receiptCardActions } from '@/modules/receipts/store/receipt-card/card';
import { receiptCardApi } from '@/modules/receipts/store/receipt-card/card/async-thunks';
import { receiptCardSelectors } from '@/modules/receipts/store/receipt-card/card/selectors';
import { receiptCardGeneralPositionsRootActions, receiptCardGeneralPositionsTableConfigActions } from '@/modules/receipts/store/receipt-card/general-positions';
import { receiptCardGeneralPositionsApi } from '@/modules/receipts/store/receipt-card/general-positions/async-thunks';
import { receiptCardGeneralPositionsSelectors } from '@/modules/receipts/store/receipt-card/general-positions/selectors';
import { receiptCardHistoryRootActions, receiptCardHistoryTableConfigActions } from '@/modules/receipts/store/receipt-card/history';
import { receiptCardHistoryApi } from '@/modules/receipts/store/receipt-card/history/async-thunks';
import { receiptCardHistorySelectors } from '@/modules/receipts/store/receipt-card/history/selectors';
import {
  receiptCardNotesPageableDataActions,
  receiptCardNotesRootActions
} from '@/modules/receipts/store/receipt-card/notes';
import { receiptCardNotesApi } from '@/modules/receipts/store/receipt-card/notes/async-thunks';
import { receiptCardNotesSelectors } from '@/modules/receipts/store/receipt-card/notes/selectors';
import {
  receiptCardPositionsRootActions,
  receiptCardPositionsTableConfigActions
} from '@/modules/receipts/store/receipt-card/positions';
import { receiptCardPositionsApi } from '@/modules/receipts/store/receipt-card/positions/async-thunks';
import { receiptCardPositionsSelectors } from '@/modules/receipts/store/receipt-card/positions/selectors';
import { DocumentCardBoxesTable } from '@/shared/document/components/document-card-boxes-table/DocumentCardBoxesTable';
import { DocumentCardGeneralPositionsTable } from '@/shared/document/components/document-card-general-positions-table/DocumentCardGeneralPositionsTable';
import { DocumentCardHistoryTable } from '@/shared/document/components/document-card-history-table/DocumentCardHistoryTable';
import { DocumentCardNotes } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { DocumentCardPositionsTable } from '@/shared/document/components/document-card-positions-table/DocumentCardPositionsTable';
import { DocumentCardTab, getDocumentCardTabsTitle } from '@/shared/document/models/document-card/document-card-tab';
import { DocumentCardPage } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const ReceiptCardPage = () => {
  const documentInfo = useSelector(receiptCardSelectors.selectDocumentInfo);
  const maxTableHeight = useSelector(receiptCardSelectors.selectTabContentHeight);

  const receiptCardTabs = useMemo(() => [
    // REM-837: hide unbox remains
    // {
    //   id: DocumentCardTab.All,
    //   label: getDocumentCardTabsTitle(DocumentCardTab.All),
    //   content: <DocumentCardGeneralPositionsTable
    //     documentInfo={documentInfo}
    //     selectors={receiptCardGeneralPositionsSelectors}
    //     tableConfigActions={receiptCardGeneralPositionsTableConfigActions}
    //     api={receiptCardGeneralPositionsApi}
    //     maxTableHeight={maxTableHeight}
    //   />
    // },
    {
      id: DocumentCardTab.Boxes,
      label: getDocumentCardTabsTitle(DocumentCardTab.Boxes),
      content: <DocumentCardBoxesTable
        documentInfo={documentInfo}
        selectors={receiptCardBoxesSelectors}
        rootActions={receiptCardBoxesRootActions}
        tableConfigActions={receiptCardBoxesTableConfigActions}
        api={receiptCardBoxesApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.Positions,
      label: getDocumentCardTabsTitle(DocumentCardTab.Positions),
      content: <DocumentCardPositionsTable
        documentInfo={documentInfo}
        selectors={receiptCardPositionsSelectors}
        rootActions={receiptCardPositionsRootActions}
        tableConfigActions={receiptCardPositionsTableConfigActions}
        api={receiptCardPositionsApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.History,
      label: getDocumentCardTabsTitle(DocumentCardTab.History),
      content: <DocumentCardHistoryTable
        selectors={receiptCardHistorySelectors}
        tableConfigActions={receiptCardHistoryTableConfigActions}
        api={receiptCardHistoryApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.Notes,
      label: getDocumentCardTabsTitle(DocumentCardTab.Notes),
      content: <DocumentCardNotes
        documentCardSelectors={receiptCardSelectors}
        documentCardNotesSelectors={receiptCardNotesSelectors}
        documentCardNotesPageableDataActions={receiptCardNotesPageableDataActions}
        documentCardNotesApi={receiptCardNotesApi}
      />
    },
  ], [maxTableHeight, documentInfo]);

  return (
    <DocumentCardPage
      tabs={receiptCardTabs}
      documentCardSelectors={receiptCardSelectors}
      documentCardApi={receiptCardApi}
      documentCardActions={receiptCardActions}
      documentCardGeneralPositionsActions={receiptCardGeneralPositionsRootActions}
      documentCardBoxesActions={receiptCardBoxesRootActions}
      documentCardPositionsActions={receiptCardPositionsRootActions}
      documentCardHistoryActions={receiptCardHistoryRootActions}
      documentCardNotesActions={receiptCardNotesRootActions}
      getGeneralPositions={receiptCardGeneralPositionsApi.getGeneralPositions}
      getAllPositions={receiptCardPositionsApi.getAllPositions}
      getAllBoxes={receiptCardBoxesApi.getAllBoxes}
      getAllNotes={receiptCardNotesApi.getAllNotes}
      getAllHistory={receiptCardHistoryApi.getAllHistory}
    />
  );
};
