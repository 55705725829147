import { signupManagerComplete } from '@/modules/auth/store/invite-manager/async-thunks';
import { passwordRecoveryComplete } from '@/modules/auth/store/password-recovery/async-thunks';
import { signupComplete } from '@/modules/auth/store/registration/async-thunks';
import { UserAuthService } from '@/modules/auth/utils/user-auth-service';
import { logout } from '@/modules/portal/store/user-profile/async-thunks';
import { createSlice } from '@reduxjs/toolkit';

interface UserAuthState {
  isLoggedIn: boolean;
}

const initialState: UserAuthState = {
  isLoggedIn: UserAuthService.isLoggedIn(),
};

const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setIsUserLoggedIn: (state: UserAuthState) => {
      state.isLoggedIn = true;
      UserAuthService.setUserLoggedIn();
    },
    removeUserLoggedIn: (state: UserAuthState) => {
      state.isLoggedIn = false;
      UserAuthService.removeUserLoggedIn();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(passwordRecoveryComplete.fulfilled, (state: UserAuthState) => {
        userAuthSlice.caseReducers.setIsUserLoggedIn(state);
      })
      .addCase(signupComplete.fulfilled, (state: UserAuthState) => {
        userAuthSlice.caseReducers.setIsUserLoggedIn(state);
      })
      .addCase(signupManagerComplete.fulfilled, (state: UserAuthState) => {
        userAuthSlice.caseReducers.setIsUserLoggedIn(state);
      })
      .addCase(logout.fulfilled, (state: UserAuthState) => {
        userAuthSlice.caseReducers.removeUserLoggedIn(state);
      });
  },
});

export const {
  setIsUserLoggedIn,
  removeUserLoggedIn,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;
