import { AsyncDispatchResult, useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { DocumentCardTab } from '@/shared/document/models/document-card/document-card-tab';
import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardTabsHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions' | 'documentCardGeneralPositionsActions' | 'documentCardBoxesActions' | 'documentCardPositionsActions' | 'documentCardHistoryActions' | 'documentCardNotesActions' | 'getGeneralPositions' | 'getAllPositions' | 'getAllBoxes' | 'getAllNotes' | 'getAllHistory'>;

export function useDocumentCardTabs({ documentCardSelectors, documentCardActions, documentCardGeneralPositionsActions, documentCardBoxesActions, documentCardPositionsActions, documentCardHistoryActions, documentCardNotesActions, getGeneralPositions, getAllPositions, getAllBoxes, getAllNotes, getAllHistory }: DocumentCardTabsHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { setDispatchResult, abortRequest } = useAsyncDispatchAborting();

  const activeTab = useSelector(documentCardSelectors.selectActiveTab);
  const docUuid = useSelector(documentCardSelectors.selectDocumentId);

  useEffect(() => {
    return () => { resetPrevActiveTabState(activeTab); };
  }, [activeTab]);

  const resetPrevActiveTabState = (prevActiveTab: DocumentCardTab) => {
    if (prevActiveTab === DocumentCardTab.All) {
      dispatch(documentCardGeneralPositionsActions.resetState());
    } else if (prevActiveTab === DocumentCardTab.Boxes) {
      dispatch(documentCardBoxesActions.resetState());
    } else if (prevActiveTab === DocumentCardTab.Positions) {
      dispatch(documentCardPositionsActions.resetState());
    } else if (prevActiveTab === DocumentCardTab.History) {
      dispatch(documentCardHistoryActions.resetState());
    } else {
      dispatch(documentCardNotesActions.resetState());
    }
  };

  const changeActiveTab = useCallback((newActiveTab: DocumentCardTab) => {
    dispatch(documentCardActions.setActiveTab(newActiveTab));
    abortRequest();
  }, [activeTab, abortRequest]);

  const onActiveTabClick = useCallback((activeTab: DocumentCardTab) => {
    let dispatchResult: AsyncDispatchResult;
    if (activeTab === DocumentCardTab.All) {
      dispatchResult = asyncDispatch(() => getGeneralPositions(docUuid));
    } else if (activeTab === DocumentCardTab.Boxes) {
      dispatchResult = asyncDispatch(() => getAllBoxes(docUuid));
    } else if (activeTab === DocumentCardTab.Positions) {
      dispatchResult = asyncDispatch(() => getAllPositions(docUuid));
    } else if (activeTab === DocumentCardTab.History) {
      dispatchResult = asyncDispatch(() => getAllHistory(docUuid));
    } else {
      dispatchResult = asyncDispatch(() => getAllNotes(docUuid));
    }
    setDispatchResult(dispatchResult);
  }, [activeTab]);

  return {
    activeTab,
    changeActiveTab,
    onActiveTabClick
  };
}
