export enum FavoritesTableColumns {
  Favorite = 'favorite',
  Partner = 'partner',
  ActivityPlace = 'activityPlace',
  Type = 'type',
  DocNumber = 'docNumber',
  Date = 'date',
  PriceWithVat = 'priceWithVat',
  Status = 'status',
  Comment = 'comment',
  CreatedDate = 'createdDate',
}