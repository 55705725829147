import { passwordMaxLength } from '@/common/utils/client-validation/string-lengths';
import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { passwordsEquals, passwordsNotEquals, required } from '@/common/utils/client-validation/validation-rules';
import { passwordFieldRules } from '@/common/utils/client-validation/validation-rules-for-fields';
import { FormPasswordsChange, FormPasswordsChangeFields } from '@/modules/portal/models/form-passwords-change';
import { useEffect } from 'react';

export function useChangePasswordsValidation(data: Partial<FormPasswordsChange>) {

  const validationRules: FormValidationRules<FormPasswordsChange, FormPasswordsChangeFields> = {
    oldPassword: [required()],
    password: [...passwordFieldRules, passwordsNotEquals(data.oldPassword)],
    passwordConfirmation: [...passwordFieldRules, passwordsEquals(data.password)],
  };

  const validator = useFormValidation({ validationRules, data });

  useEffect(() => {
    if (data.passwordConfirmation) {
      validator.validateField('passwordConfirmation');
    }
  }, [data.password]);

  useEffect(() => {
    if (data.password) {
      validator.validateField('password');
    }
  }, [data.oldPassword]);

  return {
    passwordMaxLength,
    ...validator,
  };
}
