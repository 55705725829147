import React, { FC, memo, useCallback, useRef } from 'react';

interface AppSingleFileInputProps {
  multiple?: false;
  onFilesUploaded?: (file: File) => void;
}

interface AppMultipleFileInputProps {
  multiple?: true;
  onFilesUploaded?: (file: File[]) => void;
}

interface AppFileInputCommonProps {
  renderer: (open: () => void) => React.ReactNode;
  className?: string;
  accept?: string;
  maxSize?: number;
  onMaxSizeExceed?: () => void;
}

type AppFileInputProps = AppSingleFileInputProps & AppMultipleFileInputProps & AppFileInputCommonProps;

const AppFileInputInner: FC<AppFileInputProps> = ({ renderer, className, accept = '*/*', maxSize, multiple = false, onFilesUploaded, onMaxSizeExceed }) => {
  const fileInputRef = useRef<HTMLInputElement>();

  const open = useCallback(() => {
    fileInputRef.current.click();
  }, [fileInputRef]);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);

    if (files?.length === 0) {
      return;
    }

    if (maxSize) {
      const size = files.reduce((sum, file) => sum + file.size, 0);
      if (size > maxSize) {
        onMaxSizeExceed?.();
        return;
      }
    }

    if (multiple) {
      onFilesUploaded?.(files);
    } else {
      onFilesUploaded?.(files[0])
    }

    if (fileInputRef.current.value) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <label
      htmlFor="app-file-input"
      className={className}
    >
      <input
        ref={fileInputRef}
        hidden
        id="app-file-input"
        name="app-file-input"
        type="file"
        multiple={multiple}
        accept={accept}
        onChange={onFileInputChange}
      />
      { renderer(open) }
    </label>
  )
}

export const AppFileInput = memo(AppFileInputInner);
