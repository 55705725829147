import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { interactionLogModuleName } from '@/modules/interaction-log/constants/interaction-log-module-names';
import { defaultInteractionLogTableConfig } from '@/modules/interaction-log/constants/interaction-log-table-config';
import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { InteractionLogFilter } from '@/modules/interaction-log/models/interaction-log-filter';
import { getAllInteractionLogs } from '@/modules/interaction-log/store/interaction-log/async-thunks';
import { setUserUuidToSessionStorage } from '@/modules/portal/store/user-profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InteractionLogState {
  tableConfig: SavedTableConfigState;
  search: SearchingState;
  filterConfig: FilterConfigState<InteractionLogFilter>;
  pageableData: PageableDataState<InteractionItem>;
  isOpenCertificatesListDialog: boolean;
  retryOperationEventUuid: string;
}
const notClearedFields: Set<keyof InteractionLogState> = new Set(['tableConfig']);

const interactionLogTableConfigState: SavedTableConfigState = {
  tableConfigName: 'interaction-log-table',
  config: defaultInteractionLogTableConfig,
};

const interactionLogSearchingSlice = createSearchingSlice(interactionLogModuleName);
const interactionLogTableConfigSlice = createSavedTableConfigSlice(interactionLogModuleName, interactionLogTableConfigState);
const interactionLogPageableDataSlice = createPageableDataSlice<InteractionItem>(interactionLogModuleName);
const interactionLogFilterConfigSlice = createFilterConfigSlice(interactionLogModuleName);

const initialState: InteractionLogState = {
  search: interactionLogSearchingSlice.getInitialState(),
  tableConfig: interactionLogTableConfigSlice.getInitialState(),
  filterConfig: interactionLogFilterConfigSlice.getInitialState(),
  pageableData: interactionLogPageableDataSlice.getInitialState(),
  isOpenCertificatesListDialog: false,
  retryOperationEventUuid: undefined
};

export const interactionLogSlice = createSlice({
  name: interactionLogModuleName,
  initialState,
  reducers: {
    setIsOpenCertificatesListDialog(state, action: PayloadAction<boolean>) {
      state.isOpenCertificatesListDialog = action.payload;
    },
    setRetryOperationEventUuid(state, action: PayloadAction<string>) {
      state.retryOperationEventUuid = action.payload;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof InteractionLogState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInteractionLogs.pending, (state) => {
        const { updateLoadingStatus } = interactionLogPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllInteractionLogs.fulfilled, (state) => {
        const { updateLoadingStatus } = interactionLogPageableDataSlice.caseReducers;
        const { updateStateAfterGettingData } = interactionLogFilterConfigSlice.caseReducers;
        const { saveAppliedSearchString } = interactionLogSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        updateStateAfterGettingData(state.filterConfig);
        saveAppliedSearchString(state.search);
      })
      .addCase(getAllInteractionLogs.rejected, (state) => {
        const { updateLoadingStatus } = interactionLogPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addCase(setUserUuidToSessionStorage, (state) => {
        interactionLogTableConfigSlice.caseReducers.setTableConfig(state.tableConfig);
      })
      .addMatcher(
        (action) => action.type.startsWith(interactionLogTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = interactionLogTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(interactionLogPageableDataSlice.name),
        (state, action) => {
          state.pageableData = interactionLogPageableDataSlice.reducer(state.pageableData, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(interactionLogFilterConfigSlice.name),
        (state, action) => {
          state.filterConfig = interactionLogFilterConfigSlice.reducer(state.filterConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(interactionLogSearchingSlice.name),
        (state, action) => {
          state.search = interactionLogSearchingSlice.reducer(state.search, action);
        }
      );
  }
});

export const {
  updateFilter,
  clearFilter,
  resetIsFiltersChanged,
  changeFilterOpenState
} = interactionLogFilterConfigSlice.actions;

export const {
  setIsOpenCertificatesListDialog,
  setRetryOperationEventUuid,
  resetState,
} = interactionLogSlice.actions;

export const {
  updateSearchString,
} = interactionLogSearchingSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = interactionLogTableConfigSlice.actions;

export const interactionLogPageableDataActions = interactionLogPageableDataSlice.actions;
export const interactionLogFilterConfigActions = interactionLogFilterConfigSlice.actions;

export default interactionLogSlice.reducer;
