export enum NomenclatureTableColumns {
  Code = 'code',
  Name = 'name',
  Producer = 'producer',
  Country = 'country',
  Barcode = 'barcode',
  Vital = 'vital',
  Gtin = 'gtin',
  IsGtinFound = 'isGtinFound',
  MdlpComparison = 'mdlpComparison',
}
