import { toast } from 'react-toastify';

type AppToastHookParams = (message: string, type?: 'info' | 'error') => void;

export const useAppToast = (): AppToastHookParams =>
  (message, type = 'info') => {
    toast(message, {
      className: `app-toast ${type}`
    });
  };
