import { setIsDialogOpenedByRowActions, setSscc } from '@/modules/remains/store/page';
import { useAppDispatch } from '@/store';

export interface RemainsTableActions {
  unboxRemain: (sscc: string) => void;
}

export function useRemainsTableActions(openDialog: () => void): RemainsTableActions {
  const dispatch = useAppDispatch();

  const unboxRemain = (sscc: string) => {
    dispatch(setSscc(sscc));
    dispatch(setIsDialogOpenedByRowActions(true));
    openDialog();
  };

  return {
    unboxRemain,
  };
}
