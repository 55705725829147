import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { legalEntitiesModuleName } from '@/modules/references/constants/references-module-names';
import { convertLegalEntityColumnFieldToDto } from '@/modules/references/models/legal-entities/legal-entities-table-columns-converters';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';
import { UpdateLegalEntityBody } from '@/modules/references/models/legal-entities/update-legal-entity-body';
import { ReferencesRequests } from '@/modules/references/requests/references-requests';
import { LegalEntitiesSelectors } from '@/modules/references/store/legal-entities/selectors';
import { buildLegalEntitiesFilterExpression } from '@/modules/references/utils/filters/legal-entities-filter-utils';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllLegalEntities = createAsyncThunkWrapper<
  PageableDataResponse<LegalEntity>,
  PageableDataBody
>(
  `${legalEntitiesModuleName}/getAllLegalEntities`,
  ({ pageNumber }, { getState }) => {
    const sorting = LegalEntitiesSelectors.selectTableSorting(getState());
    const filters = LegalEntitiesSelectors.selectFilters(getState());
    const searchString = LegalEntitiesSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: AppTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertLegalEntityColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const filterParams = buildLegalEntitiesFilterExpression(filters, searchString);
    if (filterParams) {
      body.filter = filterParams;
    }

    return ReferencesRequests.getAllLegalEntities(body)
      .then(res => res.data);
  },
  { condition: asyncThunkConditionOption(LegalEntitiesSelectors.selectIsLoadingLegalEntities) },
);

export const updateLegalEntity = createAsyncThunkWrapper<
  void,
  { partnerUuid: string; body: UpdateLegalEntityBody; }
>(
  `${legalEntitiesModuleName}/updateLegalEntity`,
  async ({ partnerUuid, body }) => { await ReferencesRequests.updateLegalEntity(partnerUuid, body); },
  { condition: asyncThunkConditionOption(LegalEntitiesSelectors.selectIsUpdatingInProcess) },
);
