import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { LoadingStatus } from '@/common/models/loading-status';
import { removeSelectedCertificate, setLoadingStatus } from '@/modules/certificates/store';
import { DocumentCardProcessedDialogProps } from '@/shared/document/components/document-card-processed-dialog/DocumentCardProcessedDialog';
import { useDocumentCardProcessedDialogValidation } from '@/shared/document/hooks/document-card/use-document-card-processed-dialog-validation';
import { DocumentCardSendType } from '@/shared/document/models/document-card/document-card-send-type';
import { DocumentStatus } from '@/shared/document/models/document-status';
import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardProcessedHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions' | 'documentCardApi'>;

export function useDocumentCardProcessed({ documentCardSelectors, documentCardActions, documentCardApi }: DocumentCardProcessedHookParams): DocumentCardProcessedDialogProps {
  const toast = useAppToast();
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const isDialogOpened = useSelector(documentCardSelectors.selectIsProcessedDialogOpened);
  const sendType = useSelector(documentCardSelectors.selectProcessedDialogSendType);
  const rejectReason = useSelector(documentCardSelectors.selectProcessedRejectReason);

  const isAcceptingDocument = sendType === DocumentCardSendType.Accept;

  const {
    validationResult,
    validateForm,
    resetValidationResult
    //TODO: fix type
  } = useDocumentCardProcessedDialogValidation({ rejectReason }, !isAcceptingDocument);

  const onSendDocumentToMdlp = useCallback(() => {
    if (validateForm()) {

      dispatch(setLoadingStatus(LoadingStatus.Pending));

      const catchCb = () => {
        dispatch(setLoadingStatus(LoadingStatus.Rejected));
        dispatch(removeSelectedCertificate());
      };

      const sendDocumentSignatureThenCb = () => {
        dispatch(setLoadingStatus(LoadingStatus.Fulfilled));
        dispatch(documentCardActions.updateDocumentStatus(DocumentStatus.SentToMdlp));
        closeDialog();
        toast('Документ отправлен в МДЛП');
      };

      const getDocumentSignatureThenCb = (signature: string) => {
        // second request if the first one completed
        asyncDispatch(
          () => documentCardApi.sendDocumentToMdlp(signature),
          sendDocumentSignatureThenCb,
          catchCb
        );
      };

      // first request
      asyncDispatch(
        documentCardApi.getDocumentSignature,
        getDocumentSignatureThenCb,
        catchCb
      );
    }
  }, [rejectReason, isAcceptingDocument]);

  const closeDialog = useCallback(() => {
    dispatch(documentCardActions.updateProcessedDialog({ isDialogOpened: false }));
    resetValidationResult();
    dispatch(removeSelectedCertificate());
    dispatch(setLoadingStatus(undefined));
    setTimeout(() =>
      dispatch(documentCardActions.updateProcessedDialog({ sendType: undefined, rejectReason: undefined })), AppDialogTransitionDuration
    );
  }, []);

  const onRejectReasonInput = useCallback((value: string) =>
    dispatch(documentCardActions.updateProcessedDialog({ rejectReason: value })), []);

  const title = isAcceptingDocument ? 'Прием документа' : 'Отказ в приеме документа';
  const subtitle = <>{isAcceptingDocument ? 'Для приема Документа' : 'Для отказа в приеме Документа'} вставьте УКЭП в USB-разъем и нажмите &quot;Обновить&quot; или выберите из списка установленных в системе сертификатов</>;

  return {
    isDialogOpened,
    rejectReason,
    validationResult,
    title,
    subtitle,
    isAcceptingDocument,
    onSendDocumentToMdlp,
    onRejectReasonInput,
    closeDialog
  };
}
