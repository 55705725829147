import { AppSelectList } from '@/common/components/app-select-list/AppSelectList';
import { SelectOption } from '@/common/models/app-select/select-option';
import { AppTableColumnVisibilityType } from '@/common/models/app-table/app-table-types';
import React, { FC, memo, useCallback } from 'react';

interface ColumnSettingsFormProps {
  columns: SelectOption[];
  columnVisibility: AppTableColumnVisibilityType;
  columnVisibilityChanged: (value: AppTableColumnVisibilityType) => void;
}

const ColumnSettingsFormInner: FC<ColumnSettingsFormProps> = ({ columns, columnVisibility, columnVisibilityChanged }) => {
  const [checked, setChecked] = React.useState(columnVisibility);

  const handleClick = useCallback((value: string) => {
    const newChecked = {
      ...checked,
      [value]: !checked[value],
    };
    setChecked(newChecked);
    columnVisibilityChanged?.(newChecked);
  }, [checked]);

  const isSelected = useCallback((value: string) => !!checked[value], [checked]);

  return (
    <AppSelectList
      items={columns}
      isSelected={isSelected}
      onItemClick={handleClick}
    />
  );
};

export const ColumnSettingsForm = memo(ColumnSettingsFormInner);
