export enum FileState {
  InPreparation = 'IN_PREPARATION',
  InProcessing = 'IN_PROCESSING',
  Imported = 'IMPORTED',
  PartiallyImported = 'PARTIALLY_IMPORTED',
  ReadyForExport = 'READY_FOR_EXPORT',
  Error = 'ERROR',
  Expired = 'EXPIRED'
}

const fileStatesTitles = {
  [FileState.InPreparation]: 'Подготавливается',
  [FileState.InProcessing]: 'В обработке',
  [FileState.Imported]: 'Загружен',
  [FileState.PartiallyImported]: 'Частично загружен',
  [FileState.ReadyForExport]: 'Готов к скачиванию',
  [FileState.Error]: 'Ошибка',
  [FileState.Expired]: 'Файл просрочен',
};

export const getFileStateTitle = (key: FileState): string => fileStatesTitles[key];

export const getFileStatesOptions = () => Object.entries(fileStatesTitles).map(([key, value]) => ({
  label: value,
  value: key,
}));

const warnFileStates = new Set([FileState.Expired]);
export const isWarnFileState = (state: FileState) => warnFileStates.has(state);

const errorFileStates = new Set([FileState.Error]);
export const isErrorFileState = (state: FileState) => errorFileStates.has(state);