import { AppTableRowActionMenuItemModel } from '@/common/models/app-table/app-table-row-action-menu-item-model';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { UserProfileSelectors } from '@/modules/portal/store/user-profile/selectors';
import { hasRolesAccessByPermission } from '@/modules/portal/utils/has-roles-access-by-permission';
import { UsersManagementTableActions } from '@/modules/users-management/hooks/use-users-management-table-actions';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { UserState } from '@/modules/users-management/models/user-state';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type UsersManagementTableRowActionsHookParams = Pick<
  UsersManagementTableActions,
  'isBlockUserLoading' | 'isActivateUserLoading' | 'isInviteUserLoading' |
  'editUser' | 'blockUser' | 'activateUser' | 'inviteUser'
>;

export function useUsersManagementTableRowActions({
  isBlockUserLoading, isActivateUserLoading, isInviteUserLoading,
  editUser, blockUser, activateUser, inviteUser
}: UsersManagementTableRowActionsHookParams) {
  const userRoles = useSelector(UserProfileSelectors.selectProfileInfo).roles;

  const editActionItem: AppTableRowActionMenuItemModel<UserInfo> = {
    key: 'edit',
    title: 'Редактировать',
    action: editUser,
  };

  const blockActionItem: AppTableRowActionMenuItemModel<UserInfo> = useMemo(() => ({
    key: 'block',
    title: 'Блокировать',
    showLoader: isBlockUserLoading,
    disabled: isBlockUserLoading,
    action: blockUser,
  }), [isBlockUserLoading]);

  const activateActionItem: AppTableRowActionMenuItemModel<UserInfo> = useMemo(() => ({
    key: 'activate',
    title: 'Разблокировать',
    showLoader: isActivateUserLoading,
    disabled: isActivateUserLoading,
    action: activateUser,
  }), [isActivateUserLoading]);

  const inviteActionItem: AppTableRowActionMenuItemModel<UserInfo> = useMemo(() => ({
    key: 'invite',
    title: 'Отправить письмо с приглашением',
    showLoader: isInviteUserLoading,
    disabled: isInviteUserLoading,
    action: inviteUser,
  }), [isInviteUserLoading]);

  const getRowActions = useCallback((row: AppTableRowType<UserInfo>) => {
    const items: AppTableRowActionMenuItemModel<UserInfo>[] = [editActionItem];
    const canUserSendInviteLetter = hasRolesAccessByPermission(userRoles, UserPermission.SendInviteLetter);

    const { userState } = row.original;
    if (userState === UserState.Active) {
      items.push(blockActionItem);
    } else if (userState === UserState.Blocked) {
      items.push(activateActionItem);
    } else if (userState === UserState.Invited && canUserSendInviteLetter) {
      items.push(inviteActionItem);
    }

    return items;
  }, [blockActionItem, activateActionItem, inviteActionItem]);

  return {
    getRowActions,
  };
}
