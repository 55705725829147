import { InteractionLogNotificationHistoryDialog } from '@/modules/interaction-log/components/interaction-log-notification-history-dialog/InteractionLogNotificationHistoryDialog';
import { InteractionLogRetryOperationDialog } from '@/modules/interaction-log/components/interaction-log-retry-operation-dialog/InteractionLogRetryOperationDialog';
import { InteractionLogTable } from '@/modules/interaction-log/components/interaction-log-table/InteractionLogTable';
import { resetState } from '@/modules/interaction-log/store/interaction-log';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';
import './interaction-log-page.scss';

export const InteractionLogPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <>
    <InteractionLogTable />
    <InteractionLogNotificationHistoryDialog />
    <InteractionLogRetryOperationDialog />
  </>;
};
