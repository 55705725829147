import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableRowType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { DocumentCardBoxesTableProps } from '@/shared/document/components/document-card-boxes-table/DocumentCardBoxesTable';
import { DocumentCardBox } from '@/shared/document/models/document-card/boxes/document-card-box';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type DocumentCardBoxesTableEventHandlersHookParams = Pick<DocumentCardBoxesTableProps, 'api' | 'rootActions' | 'tableConfigActions'>;

export function useDocumentCardBoxesTableEventHandlers({ api, rootActions, tableConfigActions }: DocumentCardBoxesTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(tableConfigActions.updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(tableConfigActions.updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(tableConfigActions.updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(tableConfigActions.updateTableColumnSizingState(value));
  }, []);

  const onDeleteRow = useCallback(({ index, original }: AppTableRowType<DocumentCardBox>) => {
    return asyncDispatch(
      () => api.deleteBox(original.ssccBoxUuid),
      () => dispatch(rootActions.deleteBox(index))
    );
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onDeleteRow
  };
}
