import { axios } from '@/common/api/axios-config';
import { activityPlaceTypeUrl, activityPlaceUrl, organizationInfoUrl } from '@/common/api/urls';
import { OrganizationInfoDto } from '@/modules/organization-profile/models/dto/organization-info-dto';
import { OrganizationPlaceDto } from '@/modules/organization-profile/models/dto/organization-place-dto';
import { UpdateOrganizationPlaceTypeBody } from '@/modules/organization-profile/models/update-organization-place-type/update-organization-place-type-body';
import { AxiosResponse } from 'axios';

export class OrganizationProfileRequests {
  static getOrganizationInfo(): Promise<AxiosResponse<OrganizationInfoDto>> {
    return axios.get(organizationInfoUrl);
  }

  static getOrganizationPlaces(): Promise<AxiosResponse<OrganizationPlaceDto>> {
    return axios.get(activityPlaceUrl);
  }

  static updateOrganizationPlaceType(body: UpdateOrganizationPlaceTypeBody): Promise<AxiosResponse<void>> {
    return axios.patch(activityPlaceTypeUrl, body);
  }
}
