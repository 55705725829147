import { AppDropAliveComponent } from '@/common/components/app-keep-alive/AppDropAliveComponent';
import { AppKeepAliveComponent } from '@/common/components/app-keep-alive/AppKeepAliveComponent';
import { saleCardRoutePath, salesRoutePath } from '@/modules/sale/routes/routes';
import { SaleCardPage } from '@/modules/sale/views/sale-card-page/SaleCardPage';
import { SaleCardRootPage } from '@/modules/sale/views/sale-card-root-page/SaleCardRootPage';
import { SaleCardScanningPage } from '@/modules/sale/views/sale-card-scanning-page/SaleCardScanningPage';
import { SalesPage } from '@/modules/sale/views/sales-page/SalesPage';
import { documentCardScanningRoutePath } from '@/shared/document/routes/routes';
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const SalesPageComponentName = 'SalesPage';

/**
 * All sales routes.
 */
const routes: RouteObject[] = [
  {
    path: salesRoutePath,
    element:
      <AppDropAliveComponent name={SalesPageComponentName}>
        <Outlet />
      </AppDropAliveComponent>,
    children: [
      {
        path: '',
        element:
          <AppKeepAliveComponent name={SalesPageComponentName}>
            <SalesPage />
          </AppKeepAliveComponent>,
      },
      {
        path: saleCardRoutePath,
        element: <SaleCardRootPage />,
        children: [
          {
            path: '',
            element: <SaleCardPage />,
          },
          {
            path: documentCardScanningRoutePath,
            element: <SaleCardScanningPage />,
          }
        ]
      }
    ],
  },
];

export default routes;
