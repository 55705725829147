import { AppLink } from '@/common/components/app-link/AppLink';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { DoneIcon } from '@/common/components/icons/done-icon/DoneIcon';
import { PhoneCodeInput } from '@/modules/auth/components/phone-code-form/components/phone-code-input/PhoneCodeInput';
import { SendCodeButton } from '@/modules/auth/components/send-code-button/SendCodeButton';
import { CodeStatus } from '@/modules/auth/models/code-status';
import React, { FC, ReactNode, memo, useEffect, useMemo } from 'react';
import './phone-code-form.scss';

export interface PhoneCodeFormProps {
  phone: string;
  code: string;
  expireVerificationCode: Date;
  isLoading: boolean;
  codeStatus?: CodeStatus;
  successMessage?: ReactNode;
  deniedMessage?: ReactNode;
  phoneNumberMessage?: ReactNode;
  onInput: (code: string) => void;
  onCompleteInput: (code: string) => void;
  onGoBackClick?: () => void;
  onSendPhoneNumberCodeClick: () => void;
}

const PhoneCodeFormInner: FC<PhoneCodeFormProps> = ({
  phone,
  code,
  expireVerificationCode,
  isLoading,
  codeStatus,
  successMessage,
  deniedMessage = 'Код введен неверно, попробуйте ещё раз',
  phoneNumberMessage = <>Номер телефона <span className="phone-number">{phone}</span></>,
  onInput,
  onCompleteInput,
  onGoBackClick,
  onSendPhoneNumberCodeClick,
}) => {
  const isCodeVerified = useMemo(() => codeStatus === CodeStatus.Verified, [codeStatus]);
  const isCodeDenied = useMemo(() => codeStatus === CodeStatus.Denied, [codeStatus]);

  useEffect(() => {
    if (!isLoading && isCodeDenied) {
      setTimeout(() => onInput(''), 2000);
    }
  }, [isCodeDenied, isLoading]);

  return (
    <div className="phone-code-form">
      <h1>Введите код из смс</h1>
      <div className="label">
        {phoneNumberMessage}
      </div>
      <PhoneCodeInput
        value={code}
        status={codeStatus}
        onInput={onInput}
        onCompleteInput={onCompleteInput}
      />

      {isLoading
        ? <AppLoader
          className="phone-code-form-loader"
          size="small"
        />
        : <>
          {isCodeVerified && (
            <div className="verified-message">
              {successMessage}
            </div>
          )}
          {isCodeDenied && (
            <div className="denied-message">
              {deniedMessage}
            </div>
          )}
        </>}

      {isCodeVerified ? (
        <DoneIcon
          width={24}
          height={24}
        />
      ) : (
        <SendCodeButton
          expireTime={expireVerificationCode}
          onClick={onSendPhoneNumberCodeClick}
        />
      )}

      {!isCodeVerified && onGoBackClick && (
        <AppLink
          text="Начать заново"
          onClick={onGoBackClick}
        />
      )}
    </div>
  );
};

export const PhoneCodeForm = memo(PhoneCodeFormInner);
