import { LinearProgress } from '@mui/material';
import React, { FC, memo } from 'react';
import './app-table-progress-bar.scss';

interface AppTableProgressBarProps {
  showProgressBar: boolean;
  className?: string;
}

const AppTableProgressBarInner: FC<AppTableProgressBarProps> = ({ showProgressBar, className }) => (
  <div className={`app-table-progress-bar-container ${className ?? ''}`}>
    <div className={`progress-bar-content ${showProgressBar ? 'open' : ''}`}>
      <div className="inner-content">
        <LinearProgress className="progress-bar" />
      </div>
    </div>
  </div>
);

export const AppTableProgressBar = memo(AppTableProgressBarInner);
