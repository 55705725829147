export enum DocumentCardBoxProcessingStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Awaiting = 'AWAITING'
}

const documentCardBoxProcessingStatusLabels = {
  [DocumentCardBoxProcessingStatus.Success]: 'Успешно',
  [DocumentCardBoxProcessingStatus.Error]: 'Ошибка',
  [DocumentCardBoxProcessingStatus.Awaiting]: 'В ожидании',
};

export const getDocumentCardBoxProcessingStatusLabels = (processingStatus: DocumentCardBoxProcessingStatus) => documentCardBoxProcessingStatusLabels[processingStatus];
