import { AppInputType } from '@/common/models/app-input/app-input-type';
import { TextFieldCommonProps } from '@/common/models/app-input/text-field-common-props';
import { TextFieldProps } from '@/common/models/app-input/text-field-props';
import { TextField } from '@mui/material';
import React, { FC, memo } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';

interface AppInputContentProps {
  value: string;
  type: AppInputType;
  commonTextFieldProps: TextFieldCommonProps;
  textFieldProps: TextFieldProps;
  mask?: Mask;
  maskPlaceholderChar?: string;
  showMask?: boolean;
}

const AppInputContentInner: FC<AppInputContentProps> = ({ value, type, commonTextFieldProps, textFieldProps, mask, maskPlaceholderChar, showMask }) => {
  if (mask) {
    return (
      <MaskedInput
        {...commonTextFieldProps}
        value={value}
        placeholderChar={maskPlaceholderChar}
        mask={mask}
        showMask={showMask}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            {...textFieldProps}
          />
        )}
      />
    );
  }

  if (type === AppInputType.Password) {
    return (
      <TextField
        {...commonTextFieldProps}
        {...textFieldProps}
        defaultValue={value}
      />
    );
  }

  if (type === AppInputType.Textarea) {
    return (
      <TextField
        {...commonTextFieldProps}
        {...textFieldProps}
        value={value}
        multiline={type === AppInputType.Textarea}
      />
    );
  }

  return (
    <TextField
      {...commonTextFieldProps}
      {...textFieldProps}
      value={value}
    />
  );
};

export const AppInputContent = memo(AppInputContentInner);
