import { resetState as resetCommonState } from '@/common/store/common-data-sources';
import { LoaderWrapper } from '@/modules/portal/components/loader-wrappers/loader-wrapper/LoaderWrapper';
import { usePortalInitialization } from '@/modules/portal/hooks/use-portal-initialization';
import { LoaderWrapperCommonProps } from '@/modules/portal/models/loader-wrapper/loader-wrapper-common-props';
import { resetState as resetPortalState, setIsPortalInitialized } from '@/modules/portal/store/portal';
import { PortalSelectors } from '@/modules/portal/store/portal/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './portal-loader-wrapper.scss';

const PortalLoaderWrapperInner: FC<Omit<LoaderWrapperCommonProps, 'classNames'>> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { getPortalInfo } = usePortalInitialization();

  const isPortalInitialized = useSelector(PortalSelectors.selectIsPortalInitialized);
  const isGlobalLoading = !isPortalInitialized;

  useEffect(() => {
    getPortalInfo()
      .then(() => { dispatch(setIsPortalInitialized(true)); })
      .catch(() => { dispatch(setIsPortalInitialized(true)); });

    return () => {
      dispatch(resetCommonState());
      dispatch(resetPortalState());
    };
  }, []);

  return (
    <LoaderWrapper
      isLoading={isGlobalLoading}
      classNames={{ loaderWrapper: 'portal-loader-wrapper' }}
    >
      {children}
    </LoaderWrapper>
  );
};

export const PortalLoaderWrapper = memo(PortalLoaderWrapperInner);
