import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { HeaderWithLoader } from '@/common/components/custom/header-with-loader/HeaderWithLoader';
import React, { FC, memo } from 'react';
import './document-card-warn-info-dialog.scss';

export interface DocumentCardWarnInfoDialogProps {
  isDialogOpened: boolean;
  header: string;
  subHeader: string;
  isLoading: boolean;
  continueCompletingProcessing: () => void;
  closeDialog: () => void;
}

const DocumentCardWarnInfoDialogInner: FC<DocumentCardWarnInfoDialogProps> = ({ isDialogOpened, header, subHeader, isLoading, continueCompletingProcessing, closeDialog }) => (
  <AppDialog
    open={isDialogOpened}
    classes={{ dialog: 'document-card-warn-info-dialog' }}
    header={
      <HeaderWithLoader
        title={header}
        showLoader={isLoading}
      />}
    subHeader={subHeader}
    onClose={closeDialog}
    footer={
      <div className="document-card-warn-info-dialog-footer">
        <AppButton
          label="Продолжить"
          onClick={continueCompletingProcessing}
        />
        <AppButton
          label="Отмена"
          variant='outlined'
          onClick={closeDialog}
        />
      </div>
    }
  />
);

export const DocumentCardWarnInfoDialog = memo(DocumentCardWarnInfoDialogInner);
