import { axios } from '@/common/api/axios-config';
import { allInteractionLogUrl, interactionLogNotificationHistoryUrl, resendInteractionInit, resendInteractionSend } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { InteractionLogNotificationHistoryResponse } from '@/modules/interaction-log/models/interaction-log-notification-history/interaction-log-notification-history-response';
import { AxiosResponse } from 'axios';

export class InteractionLogRequests {

  static getAllInteractionLogs(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<InteractionItem>>> {
    return axios.get(allInteractionLogUrl, { params });
  }

  static initResendInteraction(eventUuid: string): Promise<AxiosResponse<XMLDocument>> {
    return axios.get(resendInteractionInit(eventUuid));
  }

  static sendResendInteraction(eventUuid: string, signature: string): Promise<AxiosResponse<void>> {
    return axios.post(resendInteractionSend(eventUuid), { signature });
  }

  static getInteractionLogNotificationHistory(eventUuid: string, signal?: AbortSignal): Promise<AxiosResponse<InteractionLogNotificationHistoryResponse>> {
    return axios.get(interactionLogNotificationHistoryUrl(eventUuid), { signal });
  }
}
