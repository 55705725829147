import { LoadingStatus } from '@/common/models/loading-status';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { organizationPlacesModuleName } from '@/modules/organization-profile/constants/organization-profile-module-names';
import { OrganizationPlace } from '@/modules/organization-profile/models/organization-place';
import { UpdateOrganizationPlaceTypeBody } from '@/modules/organization-profile/models/update-organization-place-type/update-organization-place-type-body';
import { getOrganizationPlaces, updateOrganizationPlaceType } from '@/modules/organization-profile/store/organization-places/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface OrganizationPlacesState {
  allPlaces: OrganizationPlace[],
  filteredPlaces: OrganizationPlace[],
  loadingStatus: LoadingStatus;
  search: SearchingState;
}

const organizationPlacesSearchingSlice = createSearchingSlice(organizationPlacesModuleName);

const initialState: OrganizationPlacesState = {
  allPlaces: [],
  filteredPlaces: [],
  loadingStatus: undefined,
  search: organizationPlacesSearchingSlice.getInitialState(),
};

const getFilteredOrganizationPlaces = (allPlaces: OrganizationPlace[], searchString: string) => {
  if (searchString.length === 0) {
    return allPlaces;
  }
  const search = searchString.toLowerCase();
  return allPlaces.filter((place) =>
    place.name?.toLowerCase()?.includes(search)
    || place.address?.toLowerCase()?.includes(search)
  );
};

const getUpdatedOrganizationPlaces = (
  places: OrganizationPlace[],
  activityPlaceUuid: OrganizationPlace['activityPlaceUuid'],
  newType: OrganizationPlace['type']
) => {
  return places.map((place) => place.activityPlaceUuid === activityPlaceUuid
    ? { ...place, type: newType }
    : place
  );
};

const organizationPlacesSlice = createSlice({
  name: organizationPlacesModuleName,
  initialState,
  reducers: {
    updateOrganizationPlaceType(state, action: PayloadAction<UpdateOrganizationPlaceTypeBody>) {
      state.allPlaces = getUpdatedOrganizationPlaces(state.allPlaces, action.payload.activityPlaceUuid, action.payload.type);
      state.filteredPlaces = getUpdatedOrganizationPlaces(state.filteredPlaces, action.payload.activityPlaceUuid, action.payload.type);
    },
    filterPlaces(state) {
      state.filteredPlaces = getFilteredOrganizationPlaces(state.allPlaces, state.search.currentSearchString);

      const { saveAppliedSearchString } = organizationPlacesSearchingSlice.caseReducers;
      saveAppliedSearchString(state.search);
    },
    resetState(state) {
      const { updateSearchString } = organizationPlacesSearchingSlice.caseReducers;

      state.loadingStatus = undefined;
      updateSearchString(state.search, getReducerAction(updateSearchString.name, ''));
      state.filteredPlaces = state.allPlaces;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationPlaces.pending, (state) => { state.loadingStatus = LoadingStatus.Pending; })
      .addCase(getOrganizationPlaces.rejected, (state) => { state.loadingStatus = LoadingStatus.Rejected; })
      .addCase(getOrganizationPlaces.fulfilled, (state, action) => {
        state.allPlaces = action.payload;
        state.filteredPlaces = action.payload;
        state.loadingStatus = LoadingStatus.Fulfilled;
      })
      .addCase(updateOrganizationPlaceType.fulfilled, (state, action) => {
        const { updateOrganizationPlaceType } = organizationPlacesSlice.caseReducers;
        updateOrganizationPlaceType(state, getReducerAction(updateOrganizationPlaceType.name, action.meta.arg));
      })
      .addMatcher(
        (action) => action.type.startsWith(organizationPlacesSearchingSlice.name),
        (state, action) => {
          state.search = organizationPlacesSearchingSlice.reducer(state.search, action);
        }
      );
  },
});

export const {
  filterPlaces,
  resetState,
} = organizationPlacesSlice.actions;

export const {
  updateSearchString,
} = organizationPlacesSearchingSlice.actions;

export default organizationPlacesSlice.reducer;
