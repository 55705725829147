import { AppTableActionColumnName, AppTableSelectColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';

const columnVisibility = {
  [DocumentsTableColumns.IsFavorite]: true,
  [DocumentsTableColumns.Partner]: true,
  [DocumentsTableColumns.ActivityPlace]: true,
  [DocumentsTableColumns.DocNumber]: true,
  [DocumentsTableColumns.DocDate]: true,
  [DocumentsTableColumns.Sum]: true,
  [DocumentsTableColumns.Order]: true,
  [DocumentsTableColumns.Status]: true,
  [DocumentsTableColumns.LastNotification]: true,
  [DocumentsTableColumns.Comment]: true,
};

const sorting = [
  {
    id: DocumentsTableColumns.CreatedDate,
    desc: true,
  }
];

const columnOrder = [
  DocumentsTableColumns.IsFavorite,
  AppTableSelectColumnName,
  DocumentsTableColumns.Partner,
  DocumentsTableColumns.ActivityPlace,
  DocumentsTableColumns.DocNumber,
  DocumentsTableColumns.DocDate,
  DocumentsTableColumns.Sum,
  DocumentsTableColumns.Order,
  DocumentsTableColumns.Status,
  DocumentsTableColumns.LastNotification,
  DocumentsTableColumns.Comment,
  AppTableActionColumnName,
];

const columnSizing = {
  [DocumentsTableColumns.IsFavorite]: 50,
  [DocumentsTableColumns.Partner]: 80,
  [DocumentsTableColumns.ActivityPlace]: 60,
  [DocumentsTableColumns.DocNumber]: 70,
  [DocumentsTableColumns.DocDate]: 70,
  [DocumentsTableColumns.Sum]: 70,
  [DocumentsTableColumns.Order]: 60,
  [DocumentsTableColumns.Status]: 70,
  [DocumentsTableColumns.LastNotification]: 100,
  [DocumentsTableColumns.Comment]: 70,
};

const defaultDocumentsTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};

export const buildDefaultDocumentsTableConfig = (visibleColumns: DocumentsTableColumns[] = []) => {
  if (visibleColumns.length === 0) {
    return defaultDocumentsTableConfig;
  }

  const allVisibleColumns = new Set([AppTableSelectColumnName, AppTableActionColumnName, ...visibleColumns]);

  const filterObject = (config: Record<DocumentsTableColumns, unknown>) => {
    const result = {};

    Object.keys(config).forEach((column: DocumentsTableColumns) => {
      if (allVisibleColumns.has(column)) {
        result[column] = config[column];
      }
    });

    return result;
  };

  const filterColumnOrder = (config: typeof columnOrder) => {
    return config.filter((column) => allVisibleColumns.has(column));
  };

  const filterSorting = (config: typeof sorting) => {
    return config.filter((column) => allVisibleColumns.has(column.id));
  };

  return {
    columnVisibility: filterObject(columnVisibility),
    sorting: filterSorting(sorting),
    columnOrder: filterColumnOrder(columnOrder),
    columnSizing: filterObject(columnSizing),
  };
};
