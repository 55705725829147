import { DocumentCardPositionsState } from '@/shared/document/store/document-card/positions/index';
import { RootState } from '@/store';

interface DocumentCardPositionsSelectorsParams {
  selectDocumentCardPositionsState: (state: RootState) => DocumentCardPositionsState;
}

export const createDocumentCardPositionsSelectors = ({ selectDocumentCardPositionsState }: DocumentCardPositionsSelectorsParams) => {
  const selectTableConfig = (state: RootState) => selectDocumentCardPositionsState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

  const selectPositions = (state: RootState) => selectDocumentCardPositionsState(state).positions;
  const selectIsLoadingPositions = (state: RootState) => selectDocumentCardPositionsState(state).isLoadingPositions;

  const selectVisibleColumns = (state: RootState) => selectDocumentCardPositionsState(state).visibleColumns;

  return {
    selectTableConfig,
    selectTableSorting,
    selectPositions,
    selectIsLoadingPositions,
    selectVisibleColumns
  };
};
