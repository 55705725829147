import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { AppDialogCancelButtonWidth, AppDialogConfirmButtonWidth } from '@/common/constants/app-dialog-constants';
import React, { FC, memo, useCallback } from 'react';
import './app-confirm-dialog.scss';

interface AppConfirmDialogProps {
  open: boolean;
  message: React.ReactNode;
  title?: string;
  subTitle?: string;
  acceptButtonText?: string;
  declineButtonText?: string;
  hideDeclineButton?: boolean;
  classes?: { dialog?: string; };
  onClose: (dialogResult: boolean) => void;
}

const AppConfirmDialogInner: FC<AppConfirmDialogProps> = ({
  open,
  message,
  title,
  subTitle,
  acceptButtonText = 'Да',
  declineButtonText = 'Нет',
  hideDeclineButton = false,
  classes,
  onClose,
}) => {

  const onAcceptButtonClick = useCallback(() => {
    onClose(true);
  }, []);

  const onCloseInner = useCallback(() => {
    onClose(false);
  }, []);

  return (
    <AppDialog
      open={open}
      header={title}
      subHeader={subTitle}
      classes={{ dialog: classes?.dialog ?? 'app-confirm-dialog', content: 'app-confirm-dialog-content' }}
      onClose={onCloseInner}
      footer={
        <div className="app-confirm-dialog-action-buttons">
          {!hideDeclineButton && <AppButton
            variant="outlined"
            label={declineButtonText}
            width={AppDialogCancelButtonWidth}
            onClick={onCloseInner}
          />}
          <AppButton
            label={acceptButtonText}
            width={AppDialogConfirmButtonWidth}
            onClick={onAcceptButtonClick}
          />
        </div>
      }
    >
      <div className="confirm-message">
        {message}
      </div>
    </AppDialog>
  );
};

export const AppConfirmDialog = memo(AppConfirmDialogInner);
