import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { useProcessesTableEventHandlers } from '@/modules/references/hooks/processes/use-processes-table-event-handlers';
import { useProcessesTableSettings } from '@/modules/references/hooks/processes/use-processes-table-settings';
import { ProcessesSelectors } from '@/modules/references/store/processes/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const ProcessesTable = () => {
  const processes = useSelector(ProcessesSelectors.selectFilteredProcesses);
  const isLoadingDataSource = useSelector(ProcessesSelectors.selectIsLoadingProcesses);
  const tableConfig = useSelector(ProcessesSelectors.selectTableConfig);

  const { columns, searchPlaceholder } = useProcessesTableSettings();
  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: ProcessesSelectors.selectSearchState
  });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
  } = useProcessesTableEventHandlers();

  return (
    <AppCustomTable
      data={processes}
      columns={columns}
      isLoading={isLoadingDataSource}
      configState={tableConfig}
      topToolbar={{
        search: {
          showSearchInput: true,
          searchPlaceholder,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput,
          onApplySearchClick,
          onClearSearchClick,
        }
      }}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
    />
  );
};
