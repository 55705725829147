import { AppButton } from '@/common/components/app-button/AppButton';
import { DocumentCardActionButtonsDisplayParams, documentCardActionButtonsDisplay } from '@/shared/document/constants/document-card-action-buttons-display';
import { DocumentCardActionButtonHandlersHookReturnType } from '@/shared/document/hooks/document-card/use-document-action-buttons-handlers';
import { DocumentCardActionButtonsType } from '@/shared/document/models/document-card/document-card-action-buttons-type';
import React, { ReactNode, useMemo } from 'react';

type DocumentCardActionButtonRenderHookParams =
  Pick<DocumentCardActionButtonHandlersHookReturnType, 'getActionButtonHandlerByType'> &
  { partialDocumentInfo: DocumentCardActionButtonsDisplayParams; };

export const useDocumentCardActionButtonsRender = ({ partialDocumentInfo, getActionButtonHandlerByType }: DocumentCardActionButtonRenderHookParams) => {

  const documentCardActionButtons = useMemo(() => partialDocumentInfo ? Object.keys(documentCardActionButtonsDisplay)
    .reduce((arr: ReactNode[], type: DocumentCardActionButtonsType) => {

      const { label, variant, isButtonDisabled, isButtonVisible } = documentCardActionButtonsDisplay[type];

      if (isButtonVisible(partialDocumentInfo)) {
        arr.push(
          <AppButton
            key={type}
            label={label}
            variant={variant}
            disabled={isButtonDisabled(partialDocumentInfo)}
            width={200}
            onClick={getActionButtonHandlerByType(type)}
          />
        );
      }

      return arr;
    }, []
    ) : [], [partialDocumentInfo]);

  return {
    documentCardActionButtons,
  };
};
