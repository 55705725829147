import { FavoriteButtonCommon } from '@/shared/favorites/components/favorite-button-common/FavoriteButtonCommon';
import { useFavoriteClick } from '@/shared/favorites/hooks/use-favorite-click';
import { SharedFavoritesSelectors } from '@/shared/favorites/store/selectors';
import { RootState, useAppDispatch } from '@/store';
import { AnyAction } from '@reduxjs/toolkit';
import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

interface DocumentCardFavoriteButtonProps {
  docUuid: string;
  isFavorite: boolean;
  setIsFavorite: (payload: boolean) => AnyAction;
}

const DocumentCardFavoriteButtonInner: FC<DocumentCardFavoriteButtonProps> = ({ docUuid, isFavorite, setIsFavorite }) => {

  const dispatch = useAppDispatch();

  const isChangingInProgress = useSelector((state: RootState) => SharedFavoritesSelectors.selectIsChangingInProgress(state, docUuid));

  const { onFavoriteClick } = useFavoriteClick();

  const updateFavoriteItem = useCallback(() => {
    dispatch(setIsFavorite(!isFavorite));
  }, [isFavorite]);

  const onClick = useCallback(() => {
    onFavoriteClick({ docUuid, isFavorite, updateFavoriteItem });
  }, [updateFavoriteItem]);

  const notClickable = isChangingInProgress;

  return (
    <FavoriteButtonCommon
      isFavorite={isFavorite}
      notClickable={notClickable}
      onClick={onClick}
    />
  );
};

export const DocumentCardFavoriteButton = memo(DocumentCardFavoriteButtonInner);
