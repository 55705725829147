import organizationInfoReducer from '@/modules/organization-profile/store/organization-info';
import organizationPlacesReducer from '@/modules/organization-profile/store/organization-places';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Organization profile reducer.
 */
const organizationProfileReducer = combineReducers({
  info: organizationInfoReducer,
  places: organizationPlacesReducer,
});

export default organizationProfileReducer;
