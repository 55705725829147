import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { DeleteDocumentScanningBody } from '@/shared/document/models/document-card/scanning/delete-document-scanning-body';
import { DocumentScanningRequests } from '@/shared/document/requests/document-scanning-requests';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { DocumentCardScanningSelectors } from '@/shared/document/store/document-card/scanning/types';
import { buildDocumentScanningFilterExpression } from '@/shared/document/utils/filters/document-scanning-filter-utils';
import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import { RootState } from '@/store';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardScanningThunksCreationParams {
  moduleName: string;
  documentCardSelectors: DocumentCardSelectors;
  documentCardScanningSelectors: DocumentCardScanningSelectors;
}

export const createDocumentCardScanningThunks = ({ moduleName, documentCardSelectors, documentCardScanningSelectors }: DocumentCardScanningThunksCreationParams) => {
  const getAllScannedItems = createAsyncThunkWrapper<
    PageableDataResponse<ScanningInfo>,
    PageableDataBody
  >(
    `${moduleName}/getAllScannedItems`,
    ({ pageNumber }, { getState }) => {
      const docUuid = documentCardSelectors.selectDocumentId(getState());
      const filters = documentCardScanningSelectors.selectFilters(getState());
      const searchString = documentCardScanningSelectors.selectSearchString(getState());

      const body: PageableDataQueryParams = {
        page: pageNumber,
        size: AppTableItemsPageSize,
      };

      const filterParams = buildDocumentScanningFilterExpression({ docUuid, ...filters }, searchString);
      if (filterParams) {
        body.filter = filterParams;
      }

      return DocumentScanningRequests.getScannedItems(body)
        .then((res) => res.data);
    },
    {
      condition: asyncThunkConditionOption((state: RootState) => {
        const isLoading = documentCardScanningSelectors.selectIsLoadingScannedItems(state);
        const documentId = documentCardSelectors.selectDocumentId(state);
        return isLoading || !documentId;
      })
    }
  );

  const addScannedItemInfo = createAsyncThunkWrapper<
    ScanningInfo,
    string
  >(
    `${moduleName}/addScannedItemInfo`,
    (scannedText, { getState }) => {
      const docUuid = documentCardSelectors.selectDocumentId(getState());
      return DocumentScanningRequests.addScannedItemInfo(docUuid, { scannedText })
        .then((res) => res.data);
    }
  );

  const deleteScannedItems = createAsyncThunkWrapper<
    void,
    undefined
  >(
    `${moduleName}/deleteScannedItem`,
    async (_, { getState }) => {
      const rowsSelection = documentCardScanningSelectors.selectRowsSelection(getState());
      const docUuid = documentCardSelectors.selectDocumentId(getState());
      const body: DeleteDocumentScanningBody = {
        docUuid,
        scanUuids: Object.keys(rowsSelection),
      };
      await DocumentScanningRequests.deleteScannedItems(body);
    },
  );

  return {
    addScannedItemInfo,
    getAllScannedItems,
    deleteScannedItems,
  };
};
