import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectMdlpProductsState = (state: RootState) => state.references.mdlpProducts;

const selectSearchState = (state: RootState) => selectMdlpProductsState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectFilterConfig = (state: RootState) => selectMdlpProductsState(state).filterConfig;
const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);
const selectFilterProducer = (state: RootState) => selectFilters(state).producer;
const selectFilterCountry = (state: RootState) => selectFilters(state).country;
const selectFilterNonProprietaryName = (state: RootState) => selectFilters(state).nonProprietaryName;
const selectFilterVital = (state: RootState) => selectFilters(state).vital;
const selectFilterGtin = (state: RootState) => selectFilters(state).gtin;

const selectTableConfig = (state: RootState) => selectMdlpProductsState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectPageableDataState = (state: RootState) => selectMdlpProductsState(state).pageableData;
const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectIsLoadingData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

export const MdlpProductsSelectors = {
  selectTableConfig,
  selectTableSorting,
  selectPageableDataState,
  selectData,
  selectIsLoadingData,
  selectFilterConfig,
  selectSearchState,
  selectSearchString,
  selectIsPanelOpen,
  selectFilters,
  selectSelectedFiltersCount,
  selectFilterProducer,
  selectFilterCountry,
  selectFilterNonProprietaryName,
  selectFilterVital,
  selectFilterGtin,
};
