import { DocumentCardGeneralPositionsState } from '@/shared/document/store/document-card/general-positions';
import { RootState } from '@/store';

interface DocumentCardGeneralPositionsSelectorsParams {
  selectDocumentCardGeneralPositionsState: (state: RootState) => DocumentCardGeneralPositionsState;
}

export const createDocumentCardGeneralPositionsSelectors = ({ selectDocumentCardGeneralPositionsState }: DocumentCardGeneralPositionsSelectorsParams) => {
  const selectTableConfig = (state: RootState) => selectDocumentCardGeneralPositionsState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

  const selectPositions = (state: RootState) => selectDocumentCardGeneralPositionsState(state).positions;
  const selectIsLoadingPositions = (state: RootState) => selectDocumentCardGeneralPositionsState(state).isLoadingGeneralPositions;

  const selectVisibleColumns = (state: RootState) => selectDocumentCardGeneralPositionsState(state).visibleColumns;
  const selectIsMedicineDataNotRecieved = (state: RootState) => selectDocumentCardGeneralPositionsState(state).isMedicineDataNotRecieved;

  return {
    selectTableConfig,
    selectTableSorting,
    selectPositions,
    selectIsLoadingPositions,
    selectVisibleColumns,
    selectIsMedicineDataNotRecieved
  };
};
