import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { convertDocumentColumnFieldToDto } from '@/shared/document/models/documents/documents-table-columns-converters';
import { UpdateDocumentBody } from '@/shared/document/models/documents/update-document-body';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { DocumentRequests } from '@/shared/document/requests/document-requests';
import { DocumentSelectors } from '@/shared/document/store/documents/types';
import { buildDocumentsFilterExpression } from '@/shared/document/utils/filters/documents-filter-utils';
import { FileExportBody } from '@/shared/files/models/file-export-body';
import { FileRequests } from '@/shared/files/requests/file-requests';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentThunksCreationParams {
  moduleName: string;
  selectors: DocumentSelectors;
}

export const createDocumentThunks = ({ moduleName, selectors }: DocumentThunksCreationParams) => {
  const getAllDocuments = createAsyncThunkWrapper<PageableDataResponse<ShortDocumentInfo>,
    PageableDataBody>(
      `${moduleName}/getAllDocuments`,
      ({ pageNumber }, { getState }) => {
        const sorting = selectors.selectTableSorting(getState());
        const filters = selectors.selectFilters(getState());
        const docType = selectors.selectDocType(getState());
        const searchString = selectors.selectSearchString(getState());

        const body: PageableDataQueryParams = {
          page: pageNumber,
          size: AppTableItemsPageSize,
        };

        const sortParams = getRequestSorting(sorting, convertDocumentColumnFieldToDto);
        if (sortParams) {
          body.sort = sortParams;
        }

        const filterParams = buildDocumentsFilterExpression({ ...filters, docType }, searchString);
        if (filterParams) {
          body.filter = filterParams;
        }

        return DocumentRequests.getAllDocuments(body)
          .then(res => res.data);
      },
      { condition: asyncThunkConditionOption(selectors.selectIsLoadingData) },
    );

  const updateDocument = createAsyncThunkWrapper<void,
    { docUuid: string; body: UpdateDocumentBody; }>(
      `${moduleName}/updateDocument`,
      async ({ docUuid, body }) => {
        await DocumentRequests.updateDocument(docUuid, body);
      },
      { condition: asyncThunkConditionOption(selectors.selectIsUpdatingInProcess) },
    );

  const uploadDocumentFile = createAsyncThunkWrapper<void,
    File>(
      `${moduleName}/uploadDocumentFile`,
      async (file, { getState }) => {
        const fileType = selectors.selectDocType(getState());
        await FileRequests.uploadFile({ fileType, file });
      },
    );

  const exportDocumentFile = createAsyncThunkWrapper<void,
    undefined>(
      `${moduleName}/exportDocumentFile`,
      async (_, { getState }) => {
        const docType = selectors.selectDocType(getState());
        const rowsSelection = selectors.selectRowsSelection(getState());

        const body: FileExportBody = {
          exportType: docType,
          exportUuids: Object.keys(rowsSelection),
        };

        await FileRequests.exportFile(body);
      },
    );

  return {
    getAllDocuments,
    updateDocument,
    uploadDocumentFile,
    exportDocumentFile,
  };
};
