import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { BlockedInfoItem } from '@/common/components/custom/blocked-info-item/BlockedInfoItem';
import { AppDialogConfirmButtonWidth } from '@/common/constants/app-dialog-constants';
import { DocumentCardUpdatingStatusBlockedInfo } from '@/shared/document/models/document-card-updating-status-blocked-info';
import { DocumentCardUpdatingStatusBlockedInfoErrorFields, getDocumentCardUpdatingStatusBlockedInfoHintText, getDocumentCardUpdatingStatusBlockedInfoTitle } from '@/shared/document/models/document-card-updating-status-blocked-info-error-fields';
import React, { FC, ReactNode, memo } from 'react';
import './document-card-blocked-info-dialog.scss';

export interface DocumentCardBlockedInfoDialogProps {
  isDialogOpened: boolean;
  blockedInfo: DocumentCardUpdatingStatusBlockedInfo;
  header: ReactNode;
  subHeader: ReactNode;
  closeDialog: () => void;
}

const DocumentCardBlockedInfoDialogInner: FC<DocumentCardBlockedInfoDialogProps> = ({ isDialogOpened, blockedInfo, header, subHeader, closeDialog }) => (
  <AppDialog
    open={isDialogOpened}
    classes={{ dialog: 'document-card-blocked-info-dialog' }}
    header={header}
    subHeader={subHeader}
    onClose={closeDialog}
    footer={
      <AppButton
        label="Продолжить обработку"
        width={AppDialogConfirmButtonWidth}
        onClick={closeDialog}
      />
    }
  >
    <div className="document-card-blocked-list-items">
      {blockedInfo && Object.keys(blockedInfo).map((field: DocumentCardUpdatingStatusBlockedInfoErrorFields) => (
        <BlockedInfoItem
          key={field}
          title={getDocumentCardUpdatingStatusBlockedInfoTitle(field)}
          hintText={getDocumentCardUpdatingStatusBlockedInfoHintText(field)}
          info={blockedInfo[field]}
        />
      ))}
    </div>
  </AppDialog>
);

export const DocumentCardBlockedInfoDialog = memo(DocumentCardBlockedInfoDialogInner);
