export enum DocumentStatus {
  Preparation = 'PREPARATION',
  New = 'NEW',
  InProcess = 'IN_PROCESS',
  Processed = 'PROCESSED',
  SentToMdlp = 'SENT_TO_MDLP',
  Error = 'ERROR',
}

const documentStatusTitles = {
  [DocumentStatus.Preparation]: 'Подготавливается',
  [DocumentStatus.New]: 'Новый',
  [DocumentStatus.InProcess]: 'В обработке',
  [DocumentStatus.Processed]: 'Обработан',
  [DocumentStatus.SentToMdlp]: 'Отправлен в МДЛП',
  [DocumentStatus.Error]: 'Ошибка',
};

export const getDocumentStatusTitle = (key: DocumentStatus): string => documentStatusTitles[key];

export const getDocumentStatusesOptions = () => Object.entries(documentStatusTitles).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const isDocumentInPreparationStatus = (status: DocumentStatus) => status === DocumentStatus.Preparation;

const documentErrorStatuses = new Set([DocumentStatus.Error]);
export const isDocumentInErrorStatus = (status: DocumentStatus) => documentErrorStatuses.has(status);