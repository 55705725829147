import GearIcon from '@/assets/icons/app-table/gear.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { ColumnSettingsDialog } from '@/common/components/app-table/components/column-settings-dialog/ColumnSettingsDialog';
import { SelectOption } from '@/common/models/app-select/select-option';
import { AppTableColumnVisibilityType } from '@/common/models/app-table/app-table-types';
import React, { FC, memo, useCallback, useState } from 'react';

interface ColumnSettingsButtonProps {
  columns: SelectOption[];
  columnVisibility: AppTableColumnVisibilityType;
  columnVisibilityChanged: (value: AppTableColumnVisibilityType) => void;
}

const ColumnSettingsButtonInner: FC<ColumnSettingsButtonProps> = ({ columns, columnVisibility, columnVisibilityChanged }) => {
  const [settingDialogOpened, setSettingDialogOpened] = useState(false);

  const openColumnSettingsDialog = useCallback(() => {
    setSettingDialogOpened(true);
  }, []);

  const closeColumnSettingsDialog = useCallback(() => {
    setSettingDialogOpened(false);
  }, []);

  const onSaveClick = useCallback((value: AppTableColumnVisibilityType) => {
    columnVisibilityChanged(value);
    closeColumnSettingsDialog();
  }, []);

  return (
    <>
      <AppIconButton
        tooltip="Настройка видимости столбцов таблицы"
        activeColor="primary"
        icon={<GearIcon />}
        onClick={openColumnSettingsDialog}
      />
      <ColumnSettingsDialog
        open={settingDialogOpened}
        columns={columns}
        columnVisibility={columnVisibility}
        onSave={onSaveClick}
        onClose={closeColumnSettingsDialog}
      />
    </>
  );
};

export const ColumnSettingsButton = memo(ColumnSettingsButtonInner);
