import React, { FC, memo } from 'react';
import './app-loader.scss';

interface AppLoaderProps {
  className?: string;
  size?: 'normal' | 'small';
}

const AppLoaderInner: FC<AppLoaderProps> = ({ className, size = 'normal' }) => (
  <div className={`app-loader ${className ?? ''} ${size}-size`} />
);

export const AppLoader = memo(AppLoaderInner);
