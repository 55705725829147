import { organizationPlacesModuleName } from '@/modules/organization-profile/constants/organization-profile-module-names';
import { OrganizationPlace } from '@/modules/organization-profile/models/organization-place';
import { UpdateOrganizationPlaceTypeBody } from '@/modules/organization-profile/models/update-organization-place-type/update-organization-place-type-body';
import { OrganizationProfileRequests } from '@/modules/organization-profile/requests/organization-profile-requests';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getOrganizationPlaces = createAsyncThunkWrapper<
  OrganizationPlace[],
  undefined
>(
  `${organizationPlacesModuleName}/getOrganizationPlaces`,
  () => OrganizationProfileRequests.getOrganizationPlaces()
    .then((res) => res.data.activityPlaces),
);

export const updateOrganizationPlaceType = createAsyncThunkWrapper<
  void,
  UpdateOrganizationPlaceTypeBody
>(
  `${organizationPlacesModuleName}/updateOrganizationPlaceType`,
  async (body) => { await OrganizationProfileRequests.updateOrganizationPlaceType(body); },
);
