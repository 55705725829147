import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { RemainsTableProps } from '@/shared/remains/components/remains-table/RemainsTable';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type RemainsTableFilteringHookParams =
  Pick<PageableLoadingActions, 'loadFirstPage'> &
  Pick<RemainsTableProps, 'selectors' | 'filterConfigActions'>;


export function useRemainsTableFiltering({ loadFirstPage, selectors, filterConfigActions }: RemainsTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(filterConfigActions.changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData({
    filterConfigActions,
    selectFilterConfig: selectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
