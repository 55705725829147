import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { ErrorCode } from '@/common/models/error-code';
import { setIsUserLoggedIn } from '@/modules/auth/store/auth-state';
import { updateVerificationCode } from '@/modules/auth/store/login';
import { sendSignInVerificationCode, signInComplete } from '@/modules/auth/store/login/async-thunks';
import { LoginSelectors } from '@/modules/auth/store/login/selectors';
import { useAppDispatch } from '@/store';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export function usePhoneCodeFormForLogin() {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const phone = useSelector(LoginSelectors.selectLogin);
  const verificationCode = useSelector(LoginSelectors.selectVerificationCode);
  const expireVerificationCode = useSelector(LoginSelectors.selectExpireVerificationCodeDate);
  const codeStatus = useSelector(LoginSelectors.selectCodeStatus);
  const isLoading = useSelector(LoginSelectors.selectIsLoading);

  const [errorCode, setErrorCode] = useState('');

  const deniedMessage = useMemo(() => {
    if (errorCode === ErrorCode.VERIFICATION_CODE_ATTEMPTS_EXCEEDED) {
      return 'Превышено количество попыток ввода';
    }
    return 'Код введен неверно, попробуйте ещё раз';
  }, [errorCode]);

  const onInput = useCallback((value: string) => {
    dispatch(updateVerificationCode(value));
  }, []);

  const onCompleteInput = useCallback((value: string) => {
    dispatch(updateVerificationCode(value));

    const thenCb = () => {
      setTimeout(
        () => dispatch(setIsUserLoggedIn()),
        700);
    };

    asyncDispatch(
      signInComplete,
      thenCb,
      (err) => setErrorCode(err.code)
    );
  }, []);

  const onSendPhoneNumberCodeClick = useCallback(() => asyncDispatch(sendSignInVerificationCode), []);

  return {
    phone,
    verificationCode,
    codeStatus,
    expireVerificationCode,
    isLoading,
    deniedMessage,
    onInput,
    onCompleteInput,
    onSendPhoneNumberCodeClick,
  };
}
