import { nomenclatureMdlpComparisonModuleName } from '@/modules/references/constants/references-module-names';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { ReferencesRequests } from '@/modules/references/requests/references-requests';
import { NomenclatureMdlpComparisonSelectors } from '@/modules/references/store/nomenclature-mdlp-comparison/selectors';
import { RootState } from '@/store';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getMdlpProduct = createAsyncThunkWrapper<
  MdlpProduct,
  void
>(
  `${nomenclatureMdlpComparisonModuleName}/getMdlpProduct`,
  (_, { getState, signal }) => {
    const gtin = NomenclatureMdlpComparisonSelectors.selectNomenclatureGtin(getState());
    return ReferencesRequests.getMdlpProductByGtin(gtin, signal)
      .then((res) => res.data);
  },
  {
    condition: asyncThunkConditionOption((state: RootState) => {
      const isLoading = NomenclatureMdlpComparisonSelectors.selectIsLoading(state);
      const gtin = NomenclatureMdlpComparisonSelectors.selectNomenclatureGtin(state);
      return isLoading || !gtin;
    })
  }
);

export const syncNomenclatureWithMdlpProduct = createAsyncThunkWrapper<
  MdlpProduct,
  void
>(
  `${nomenclatureMdlpComparisonModuleName}/syncNomenclatureWithMdlpProduct`,
  (_, { getState }) => {
    const gtin = NomenclatureMdlpComparisonSelectors.selectNomenclatureGtin(getState());
    return ReferencesRequests.syncNomenclatureWithMdlpByGtin(gtin)
      .then((res) => res.data);
  },
  {
    condition: asyncThunkConditionOption((state: RootState) => {
      const isLoading = NomenclatureMdlpComparisonSelectors.selectIsLoading(state);
      const gtin = NomenclatureMdlpComparisonSelectors.selectNomenclatureGtin(state);
      return isLoading || !gtin;
    })
  }
);

