import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';

const selectData = <T extends object>(state: PageableDataState<T>) => state.data;
const selectPageNumber = <T extends object>(state: PageableDataState<T>) => state.pageNumber;
const selectTotalItems = <T extends object>(state: PageableDataState<T>) => state.totalItems;
const selectLoadingStatus = <T extends object>(state: PageableDataState<T>) => state.loadingStatus;
const selectIsLoading = <T extends object>(state: PageableDataState<T>) => state.loadingStatus === LoadingStatus.Pending;

export const PageableDataSelectors = {
  selectData,
  selectPageNumber,
  selectTotalItems,
  selectLoadingStatus,
  selectIsLoading,
};
