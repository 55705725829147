import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { InteractionLogTableColumns } from '@/modules/interaction-log/models/interaction-log-table/interaction-log-table-columns';

const columnVisibility = {
  [InteractionLogTableColumns.Organization]: true,
  [InteractionLogTableColumns.CreatedDate]: true,
  [InteractionLogTableColumns.DocNumber]: true,
  [InteractionLogTableColumns.Event]: true,
  [InteractionLogTableColumns.Source]: true,
  [InteractionLogTableColumns.Target]: true,
  [InteractionLogTableColumns.User]: true,
  [InteractionLogTableColumns.Status]: true,
  [InteractionLogTableColumns.CreatedDateMdlp]: true,
  [InteractionLogTableColumns.NotificationHistory]: true,
};

const sorting = [
  {
    id: InteractionLogTableColumns.CreatedDate,
    desc: true,
  }
];

const columnOrder = [
  InteractionLogTableColumns.Organization,
  InteractionLogTableColumns.CreatedDate,
  InteractionLogTableColumns.DocNumber,
  InteractionLogTableColumns.Event,
  InteractionLogTableColumns.Source,
  InteractionLogTableColumns.Target,
  InteractionLogTableColumns.User,
  InteractionLogTableColumns.Status,
  InteractionLogTableColumns.CreatedDateMdlp,
  InteractionLogTableColumns.NotificationHistory,
  AppTableActionColumnName,
];

const columnSizing = {
  [InteractionLogTableColumns.Organization]: 130,
  [InteractionLogTableColumns.CreatedDate]: 60,
  [InteractionLogTableColumns.DocNumber]: 60,
  [InteractionLogTableColumns.Event]: 130,
  [InteractionLogTableColumns.Source]: 50,
  [InteractionLogTableColumns.Target]: 50,
  [InteractionLogTableColumns.User]: 50,
  [InteractionLogTableColumns.Status]: 70,
  [InteractionLogTableColumns.CreatedDateMdlp]: 60,
  [InteractionLogTableColumns.NotificationHistory]: 50,
};

export const defaultInteractionLogTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
