import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import React, { FC, memo } from 'react';
import './activity-places-cell.scss';

interface ActivityPlacesCellProps {
  places: string[];
}

const ActivityPlacesCellInner: FC<ActivityPlacesCellProps> = ({ places }) => {
  const visibleItemsCount = 2;

  const visibleItems = places.slice(0, visibleItemsCount);
  const visibleItemsRow = visibleItems.join(', ');

  const hiddenItemsCount = places.length - visibleItemsCount;

  const allItemsRow = places.join(', ');

  const showMoreOptions = hiddenItemsCount > 0;

  return (
    <div>
      {visibleItemsRow}
      {showMoreOptions &&
        <>
          <span>, </span>
          <AppTooltip text={allItemsRow}>
            <span className="more-items-label">ещё {hiddenItemsCount}</span>
          </AppTooltip>
        </>
      }
    </div>
  );
};

export const ActivityPlacesCell = memo(ActivityPlacesCellInner);
