import { AuthLayout } from '@/modules/auth/components/base-auth-layout/AuthLayout';
import { LicenceAgreement } from '@/modules/auth/components/licence-agreement/LicenceAgreement';
import { RegistrationForm } from '@/modules/auth/components/registration-forms/RegistrationForm';
import { resetState, setIsOpenLicenceAgreement } from '@/modules/auth/store/registration';
import { RegistrationSelectors } from '@/modules/auth/store/registration/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './registration-page.scss';

export const RegistrationPage = () => {
  const dispatch = useAppDispatch();
  const isOpenLicenceAgreement = useSelector(RegistrationSelectors.selectIsOpenLicenceAgreement);

  const onLicenceAgreementGoBack = useCallback(() => {
    dispatch(setIsOpenLicenceAgreement(false));
  }, []);

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return (
    <AuthLayout licenceAgreement={isOpenLicenceAgreement}>
      {isOpenLicenceAgreement
        ? <LicenceAgreement onGoBack={onLicenceAgreementGoBack} />
        : <RegistrationForm />
      }
    </AuthLayout>
  );
};
