import { SignupManagerCompleteBody } from '@/modules/auth/models/signup-manager-complete/signup-manager-complete-body';
import { VerificationCodeSendResponse } from '@/modules/auth/models/verification-code-send/verification-code-send-response';
import { AuthRequests } from '@/modules/auth/requests/auth-requests';
import { InviteManagerSelectors } from '@/modules/auth/store/invite-manager/selectors';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const sendVerificationCodeByCipcher = createAsyncThunkWrapper<
  VerificationCodeSendResponse,
  string
>(
  'invite-manager/sendVerificationCodeByCipcher',
  (managerUuidCipher) =>
    AuthRequests.verificationCodeSendByCipher({ managerUuidCipher })
      .then(res => res.data)
);

export const signupManagerVerifyPhoneNumber = createAsyncThunkWrapper<
  void,
  string
>(
  'inviteManager/verifyPhoneNumber',
  (managerUuidCipher, { getState }) => {
    const verificationCode = InviteManagerSelectors.selectCode(getState());
    return AuthRequests.signupManagerVerifyPhoneNumber({ managerUuidCipher, verificationCode })
      .then((res) => res.data);
  });

export const signupManagerComplete = createAsyncThunkWrapper<
  void,
  SignupManagerCompleteBody
>(
  'inviteManager/complete',
  async (body) => { await AuthRequests.signupManagerComplete(body); },
);
