import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { ProcessesTable } from '@/modules/references/components/processes-table/ProcessesTable';
import { resetState } from '@/modules/references/store/processes';
import { getAllProcesses } from '@/modules/references/store/processes/async-thunks';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const ProcessesPage = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  useEffect(() => {
    asyncDispatch(getAllProcesses);

    return () => { dispatch(resetState()); };
  }, []);

  return <ProcessesTable />;
};
