import { useAsyncDispatch } from "@/common/hooks/use-async-dispatch";
import { LoadingStatus } from "@/common/models/loading-status";
import { PageableDataBody } from "@/common/models/pageable-data/pageable-data-body";
import { favoritesPageableDataActions, resetState } from "@/modules/favorites/store";
import { getAllFavoriteDocuments } from "@/modules/favorites/store/async-thunks";
import { FavoritesSelectors } from "@/modules/favorites/store/selectors";
import { useAppDispatch } from "@/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useFavoritesEmptyData = () => {
  const { asyncDispatch } = useAsyncDispatch();
  const dispatch = useAppDispatch();

  const totalItems = useSelector(FavoritesSelectors.selectTotalItems);
  const loadingStatus = useSelector(FavoritesSelectors.selectLoadingStatus);

  const hasFavorites = totalItems > 0;
  const isDataLoading = !loadingStatus || loadingStatus === LoadingStatus.Pending;

  const getFavorites = () => {
    const body: PageableDataBody = {
      pageNumber: 0,
      size: 1
    };

    asyncDispatch(
      () => getAllFavoriteDocuments(body),
      (res) => dispatch(favoritesPageableDataActions.rewriteData(res))
    );
  };

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return {
    hasFavorites,
    isDataLoading,
    getFavorites
  };
};