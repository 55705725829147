import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { LegalEntitiesActivityPlacesTableColumns } from '@/modules/references/models/legal-entities-activity-places/legal-entities-activity-places-table-columns';

const columnVisibility = {
  [LegalEntitiesActivityPlacesTableColumns.RegNumber]: true,
  [LegalEntitiesActivityPlacesTableColumns.Address]: true,
  [LegalEntitiesActivityPlacesTableColumns.State]: true,
  [LegalEntitiesActivityPlacesTableColumns.Type]: true,
};

const sorting = [
  {
    id: LegalEntitiesActivityPlacesTableColumns.RegNumber,
    desc: false,
  }
];

const columnOrder = [
  LegalEntitiesActivityPlacesTableColumns.RegNumber,
  LegalEntitiesActivityPlacesTableColumns.Address,
  LegalEntitiesActivityPlacesTableColumns.State,
  LegalEntitiesActivityPlacesTableColumns.Type,
  AppTableActionColumnName,
];

const columnSizing = {
  [LegalEntitiesActivityPlacesTableColumns.RegNumber]: 80,
  [LegalEntitiesActivityPlacesTableColumns.Address]: 100,
  [LegalEntitiesActivityPlacesTableColumns.State]: 40,
  [LegalEntitiesActivityPlacesTableColumns.Type]: 70,
};

export const defaultLegalEntitiesActivityPlacesTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
