import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { SetNewPasswordFormProps } from '@/modules/auth/components/set-new-password-form/SetNewPasswordForm';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import { loginRoutePath } from '@/modules/auth/routes/routes';
import { setPasswords } from '@/modules/auth/store/password-recovery';
import { passwordRecoveryComplete } from '@/modules/auth/store/password-recovery/async-thunks';
import { PasswordRecoverySelectors } from '@/modules/auth/store/password-recovery/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export const useSetNewPasswordPageForm = (): SetNewPasswordFormProps => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userUuidCipher } = useParams();
  const { asyncDispatch } = useAsyncDispatch();

  const title = 'Придумайте новый пароль';
  const passwords = useSelector(PasswordRecoverySelectors.selectPasswords);

  const onGoBackClick = useCallback(() => {
    navigate(loginRoutePath);
  }, []);

  const onSavePasswordClick = useCallback((password: string) => {
    asyncDispatch(() => passwordRecoveryComplete({ userUuidCipher, password }));
  }, [userUuidCipher]);

  const onChangePasswords = useCallback((passwords: PasswordsConfirmation) => {
    dispatch(setPasswords(passwords));
  }, []);

  return {
    title,
    passwords,
    onSavePasswordClick,
    onChangePasswords,
    onGoBackClick
  };
};