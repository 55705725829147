import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { FileOperationsTableColumns } from '@/modules/file-operations/models/file-operations-table-columns';

const fileOperationsTableColumnsToDtoMap: Record<FileOperationsTableColumns, keyof FileOperation> = {
  [FileOperationsTableColumns.FileName]: 'fileName',
  [FileOperationsTableColumns.FileType]: 'fileType',
  [FileOperationsTableColumns.FileState]: 'fileState',
  [FileOperationsTableColumns.OperationType]: 'operationType',
  [FileOperationsTableColumns.OperationDate]: 'operationDate',
};

type FileOperationsDtoToColumnsMap = Record<keyof FileOperation, FileOperationsTableColumns>;

const fileOperationsDtoToColumnsMap: FileOperationsDtoToColumnsMap =
  Object.entries(fileOperationsTableColumnsToDtoMap).reduce((obj, [key, value]) => ({
    ...obj,
    [value]: key,
  }), {} as FileOperationsDtoToColumnsMap);

export const convertFileOperationColumnFieldToDto = (columnField: FileOperationsTableColumns) => fileOperationsTableColumnsToDtoMap[columnField];
