import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableRowSelectionType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { RemainsTableProps } from '@/shared/remains/components/remains-table/RemainsTable';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type RemainsTableEventHandlersHookParams = PageableLoadingActions &
  Pick<RemainsTableProps, 'tableConfigActions' | 'searchingActions' | 'rootActions'>;

export function useRemainsTableEventHandlers({
  loadFirstPage,
  loadNextPage,
  tableConfigActions,
  searchingActions,
  rootActions
}: RemainsTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(tableConfigActions.updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(tableConfigActions.updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(tableConfigActions.updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(tableConfigActions.updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(searchingActions.updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onScrollChange = useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  const onRefreshClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onRowSelectionChange = useCallback((value: Updater<AppTableRowSelectionType>) => {
    dispatch(rootActions.setRowSelection(value));
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRefreshClick,
    onRowSelectionChange
  };
}
