import { AppCheckbox } from '@/common/components/app-checkbox/AppCheckbox';
import { FilterConfigActions } from '@/common/models/filter-config/filter-config-actions';
import { DisposalRemainsFilter } from '@/modules/disposals/models/remains/disposal-remains-filter';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { RemainsFilters } from '@/shared/remains/components/remains-filters/RemainsFilters';
import { RemainsSelectors } from '@/shared/remains/store/types';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './disposal-remains-filters.scss';

interface DisposalRemainsFiltersProps {
  cardSelectors: DocumentCardSelectors;
  remainsSelectors: RemainsSelectors;
  filterConfigActions: FilterConfigActions<DisposalRemainsFilter>;
}

const DisposalRemainsFiltersInner: FC<DisposalRemainsFiltersProps> = ({ cardSelectors, remainsSelectors, filterConfigActions }) => {
  const dispatch = useAppDispatch();

  const documentId = useSelector(cardSelectors.selectDocumentId);
  const filter = useSelector(remainsSelectors.selectFilters);

  const onFilterByDocumentChange = useCallback((checked: boolean) => {
    const filterValue = checked ? documentId : '';
    dispatch(filterConfigActions.updateFilter({ docUuid: filterValue }));
  }, [documentId]);

  return (
    <div className="disposal-remains-filters">
      <RemainsFilters
        selectors={remainsSelectors}
        filterConfigActions={filterConfigActions}
      />
      <AppCheckbox
        label="Отобразить товары только в составе текущего документа"
        checked={!!filter.docUuid}
        onChange={onFilterByDocumentChange}
      />
    </div>
  );
};

export const DisposalRemainsFilters = memo(DisposalRemainsFiltersInner);
