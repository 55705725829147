import './cadesplugin-api';

export function getSignatureByCertificateOwner(owner, code) {
  let CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;

  return new Promise((resolve, reject) => {
    cadesplugin.then(() => {
      // работа с плагином
      cadesplugin.async_spawn(function* () {
        try {
          // Создание объекта для работы с сертификатами
          const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
          if (!oStore) {
            reject('Не удалось создать объект Store');
            return;
          }

          // Открытие хранилище сертификатов (в скобках указано, что со всех носителей)
          yield oStore.Open(
            cadesplugin.CADESCOM_CONTAINER_STORE,
            cadesplugin.CAPICOM_MY_STORE,
            cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

          // Получение имени владельца

          let oCertificates = yield oStore.Certificates;
          let selectedCertificates = yield oCertificates.Find(
            CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, owner);

          let count = yield selectedCertificates.Count;
          // Проверка, есть ли доступные сертификаты
          if (count === 0) {
            reject('Нет доступных сертификатов');
            return;
          }
          // Найденный сертификат по CN владельца
          let oCertificate = yield selectedCertificates.Item(1);
          yield oStore.Close();

          // Создаем подписанное сообщение
          // Такая подпись должна проверяться в КриптоАРМ и cryptcp.exe
          // Создаем объект CAdESCOM.CPSigner
          let oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
          yield oSigner.propset_Certificate(oCertificate);
          yield oSigner.propset_CheckCertificate(true);

          // Создаем объект CAdESCOM.CadesSignedData
          let oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');

          // Переменная, где будет храниться подпись
          let sSignedMessage = '';

          const utfCode = String.fromCodePoint(...(new TextEncoder().encode(code)));
          const dataToSign = window.btoa(utfCode, 'base64');
          yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
          yield oSignedData.propset_Content(dataToSign);
          yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);

          const errorLabel = 'Не удалось создать подпись из-за ошибки: ';
          // Вычисляем значение подписи
          try {
            sSignedMessage = yield oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_BES);
          } catch (err) {
            console.error(errorLabel + cadesplugin.getLastError(err));
            reject(err);
            return;
          }

          // Проверяем подпись
          try {
            resolve(sSignedMessage);
          } catch (err) {
            console.error(errorLabel + cadesplugin.getLastError(err));
            reject(err);
            return;
          }

        } catch (err) {
          reject(err);
        }
      });
    });
  });
}