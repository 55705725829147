import ArrowBackIcon from '@/assets/icons/arrows/arrow_back.svg?react';
import { AppLink } from '@/common/components/app-link/AppLink';
import React, { FC, memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface BackButtonProps {
  defaultRoute: string;
  className?: string;
}

const BackButtonInner: FC<BackButtonProps> = ({ defaultRoute, className }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const canGoBack = location.key !== 'default';

  const goBack = useCallback(() => {
    if (canGoBack) {
      navigate(-1);
      return;
    }
    navigate(defaultRoute);
  }, [defaultRoute, canGoBack]);

  return (
    <AppLink
      classes={{ container: className }}
      text="Назад"
      startIcon={<ArrowBackIcon />}
      onClick={goBack}
    />
  );
};

export const BackButton = memo(BackButtonInner);
