import { AppLink } from '@/common/components/app-link/AppLink';
import React, { FC, memo } from 'react';
import './app-table-open-button.scss';

interface AppTableOpenButtonProps {
  text: string;
  onClick: () => void;
}

const AppTableOpenButtonInner: FC<AppTableOpenButtonProps> = ({ text, onClick }) => (
  <AppLink
    classes={{ container: 'app-table-open-button-container', font: 'app-table-open-button-font' }}
    text={text}
    onClick={onClick}
  />
);

export const AppTableOpenButton = memo(AppTableOpenButtonInner);
