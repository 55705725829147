import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { userProfileStorageKeys } from '@/modules/portal/constants/user-profile-storage-keys';

const getDataFromStorage = () => {
  const userUuid = sessionStorage.getItem(userProfileStorageKeys.USER_UUID);
  const savedTableConfigByUserUuid = localStorage.getItem(userUuid);

  return {
    userUuid,
    savedTableConfigByUserUuid
  };
};

export const getSavedTableConfigStateOrDefault = (tableId: string, defaultConfig: SavedTableConfig) => {
  const { savedTableConfigByUserUuid } = getDataFromStorage();
  if (!savedTableConfigByUserUuid) {
    return defaultConfig;
  }

  const savedTableConfig = JSON.parse(savedTableConfigByUserUuid)[tableId];
  if (!savedTableConfig) {
    return defaultConfig;
  }

  return { ...defaultConfig, ...savedTableConfig };
};

export const saveTableConfigState = (tableId: string, config: SavedTableConfig) => {
  const { userUuid, savedTableConfigByUserUuid } = getDataFromStorage();
  const parsedTableConfigByUserUuid = JSON.parse(savedTableConfigByUserUuid);

  const savedTableConfig = JSON.stringify(
    parsedTableConfigByUserUuid
      ? { ...parsedTableConfigByUserUuid, [tableId]: config }
      : { [tableId]: config }
  );
  localStorage.setItem(userUuid, savedTableConfig);
};
