import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { ScanningViewHeader } from '@/shared/scanning-view/components/scanning-view-header/ScanningViewHeader';
import { ScanningViewInfo } from '@/shared/scanning-view/components/scanning-view-info/ScanningViewInfo';
import { ScanningViewTable, ScanningViewTableProps } from '@/shared/scanning-view/components/scanning-view-table/ScanningViewTable';
import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import React, { FC, memo } from 'react';
import './scanning-view.scss';

export interface ScanningItemProps {
  lastScannedItem: ScanningInfo;
  isGettingLastScannedItemInProcess: boolean;
  onScanningItem: (scannedText: string) => AsyncDispatchResult;
}

export interface DeleteButtonParams {
  deleteButtonDisabled: boolean;
  deleteButtonLabel: string;
  onDeleteClick: () => void;
}

export interface ScanningViewProps {
  backNavigationRoute: string;
  scanningItemParams: ScanningItemProps;
  tableParams: Pick<ScanningViewTableProps, 'tableParams'>;
  toolbarParams: Pick<ScanningViewTableProps, 'toolbarParams'>;
  deleteButtonParams: DeleteButtonParams;
}

const ScanningViewInner: FC<ScanningViewProps> = ({
  backNavigationRoute,
  scanningItemParams,
  tableParams,
  toolbarParams,
  deleteButtonParams,
}) => {
  return (
    <div className="scanning-view">
      <ScanningViewHeader
        backNavigationRoute={backNavigationRoute}
        isGettingLastScannedItemInProcess={scanningItemParams.isGettingLastScannedItemInProcess}
        onScanningItem={scanningItemParams.onScanningItem}
      />
      <ScanningViewInfo lastScannedItem={scanningItemParams.lastScannedItem} />
      <ScanningViewTable
        tableParams={tableParams}
        toolbarParams={toolbarParams}
        deleteButtonParams={deleteButtonParams}
      />
    </div >
  );
};

export const ScanningView = memo(ScanningViewInner);
