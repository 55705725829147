import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { email, notEmptyArray, phone, required } from '@/common/utils/client-validation/validation-rules';
import { UserInfoFormData, UserInfoFormDataFields } from '@/modules/users-management/models/user-info/user-info-form-data';
import { useMemo } from 'react';

interface EditUserValidationHookParams {
  data: Partial<UserInfoFormData>;
  validateActivityPlaces: boolean;
}

export function useEditUserValidation({ data, validateActivityPlaces }: EditUserValidationHookParams) {

  const validationRules = useMemo(() => {

    const userValidationRules: FormValidationRules<UserInfoFormData, UserInfoFormDataFields> = {
      secondName: [required()],
      firstName: [required()],
      lastName: [required()],
      email: [required(), email()],
      phoneNumber: [required(), phone()],
      position: [required()],
    };

    if (validateActivityPlaces) {
      userValidationRules.activityPlaces = [notEmptyArray()];
    }

    return userValidationRules;
  }, [validateActivityPlaces]);

  return {
    ...useFormValidation({ validationRules, data }),
  };
}
