import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { useMemo } from 'react';

export function useAppTableLocalization() {
  const localizationOverrides: Partial<typeof MRT_Localization_RU> = useMemo(() => ({
    move: 'Переместить колонку',
    noRecordsToDisplay: 'Нет данных',
    unsorted: 'Не отсортировано',
    sortedByColumnAsc: 'Отсортировано по возрастанию',
    sortedByColumnDesc: 'Отсортировано по убыванию',
  }), []);

  const localization = useMemo(() => ({
    ...MRT_Localization_RU,
    ...localizationOverrides,
  }), []);

  return {
    localization,
  };
}
