import Done from '@/assets/icons/done.svg?react';
import React, { FC, memo } from 'react';
import './done-icon.scss';

interface DoneIconProps {
  width?: number;
  height?: number;
}

const DoneIconInner: FC<DoneIconProps> = ({ width, height }) => (
  <div
    className="done-icon"
    style={{
      width: width ?? 16,
      height: height ?? 16
    }}
  >
    <Done className="done" />
  </div>
);

export const DoneIcon = memo(DoneIconInner);
