import { PageableDataPatchItemByCallbackParams } from '@/common/models/pageable-data/pageable-data-patch-item-by-callback-params';
import { CommonFavoriteDocumentItem } from '@/shared/favorites/models/common-favorite-document-item';
import { useAppDispatch } from '@/store';
import { AnyAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';

interface PatchFavoriteDocumentHookParams<T extends CommonFavoriteDocumentItem> {
  patchItemByFindIndex: (payload: PageableDataPatchItemByCallbackParams<T>) => AnyAction;
}

export const usePatchFavoriteItem = <T extends CommonFavoriteDocumentItem>({ patchItemByFindIndex }: PatchFavoriteDocumentHookParams<T>) => {
  const dispatch = useAppDispatch();

  const patchFavoriteItem = useCallback((entity: Partial<T>, isFavorite: boolean) => {
    const updatedItem = { ...entity, isFavorite };
    const findIndexCallback = (document: CommonFavoriteDocumentItem) => document.docUuid === updatedItem.docUuid;
    dispatch(patchItemByFindIndex({ item: updatedItem, findIndexCallback }));
  }, []);

  return { patchFavoriteItem };
};
