import { saleCardBoxesModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardBoxesApi } from '@/modules/sale/store/sale-card/boxes/async-thunks';
import { createDocumentCardBoxesSlice } from '@/shared/document/store/document-card/boxes';

export const saleCardBoxesSlice = createDocumentCardBoxesSlice({
  moduleName: saleCardBoxesModuleName,
  savedTableConfigName: 'sale-card-boxes-table',
  api: saleCardBoxesApi,
});

export const saleCardBoxesRootActions = saleCardBoxesSlice.rootSlice.actions;
export const saleCardBoxesTableConfigActions = saleCardBoxesSlice.documentCardBoxesTableConfigSlice.actions;

export default saleCardBoxesSlice.rootSlice.reducer;
