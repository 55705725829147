import { axios } from '@/common/api/axios-config';
import { documentBoxUrl, documentBoxesUrl } from '@/common/api/urls';
import { GetDocumentCardBoxesResponse } from '@/shared/document/models/document-card/boxes/get-document-card-boxes-response';
import { AxiosResponse } from 'axios';

export class DocumentCardBoxesRequests {

  static getAllBoxes(docUuid: string, signal?: AbortSignal): Promise<AxiosResponse<GetDocumentCardBoxesResponse>> {
    return axios.get(documentBoxesUrl(docUuid), { signal });
  }

  static deleteBox(docUuid: string, boxUuid: string): Promise<AxiosResponse<void>> {
    return axios.delete(documentBoxUrl(boxUuid), { data: { docUuid } });
  }

}
