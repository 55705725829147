import { disposalModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalsApi } from '@/modules/disposals/store/disposals/async-thunks';
import { DocumentType } from '@/shared/document/models/document-type';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { createDocumentsSlice } from '@/shared/document/store/documents';

const disposalsSlice = createDocumentsSlice({
  moduleName: disposalModuleName,
  savedTableConfigName: 'disposals-table',
  docType: DocumentType.OtherDisposals,
  api: disposalsApi,
  visibleColumns: [
    DocumentsTableColumns.IsFavorite, DocumentsTableColumns.ActivityPlace, DocumentsTableColumns.DocNumber,
    DocumentsTableColumns.DocDate, DocumentsTableColumns.Sum, DocumentsTableColumns.Status,
    DocumentsTableColumns.LastNotification, DocumentsTableColumns.Comment, DocumentsTableColumns.CreatedDate
  ]
});

export const disposalsRootActions = disposalsSlice.rootSlice.actions;
export const disposalsTableActions = disposalsSlice.documentsTableSlice.actions;
export const disposalsSearchingActions = disposalsSlice.documentsSearchingSlice.actions;
export const disposalsTableConfigActions = disposalsSlice.documentsTableConfigSlice.actions;
export const disposalsFilterConfigActions = disposalsSlice.documentsFilterConfigSlice.actions;
export const disposalsPageableDataActions = disposalsSlice.documentsPageableDataSlice.actions;

export default disposalsSlice.rootSlice.reducer;
