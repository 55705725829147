import { AuthLayout } from '@/modules/auth/components/base-auth-layout/AuthLayout';
import { SetNewPasswordForm } from '@/modules/auth/components/set-new-password-form/SetNewPasswordForm';
import { useSetNewPasswordPageForm } from '@/modules/auth/hooks/use-set-new-password-page-form';
import { resetState } from '@/modules/auth/store/password-recovery';
import { useAppDispatch } from '@/store';
import React, { FC, useEffect } from 'react';

export const SetNewPasswordPage: FC = () => {
  const dispatch = useAppDispatch();

  const setNewPasswordContext = useSetNewPasswordPageForm();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return (
    <AuthLayout padding>
      <SetNewPasswordForm {...setNewPasswordContext} />
    </AuthLayout>
  );
};
