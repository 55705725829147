import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { CommonDocument } from '@/shared/document/models/common-document';
import { isDocumentInErrorStatus, isDocumentInPreparationStatus } from '@/shared/document/models/document-status';

export const getDocumentCellRowClassName = (data: { row: AppTableRowType<CommonDocument>; }) => {
  const { docState } = data.row.original;
  if (isDocumentInPreparationStatus(docState)) {
    return 'preparation-document';
  }
  if (isDocumentInErrorStatus(docState)) {
    return 'error-document';
  }
};
