import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { DocumentUuidFilter } from '@/shared/document/models/document-card/document-uuid-filter';
import { DocumentCardScanningFilter } from '@/shared/document/models/document-card/scanning/document-card-scanning-filter';
import { DocumentCardScanningFilterRsqlModel } from '@/shared/document/models/document-card/scanning/document-card-scanning-filter-rsql-model';

const searchFields: (keyof DocumentCardScanningFilterRsqlModel)[] = ['identifierSgtinBox', 'identifierSsccBox'];

function getDocumentUuidRsqlFilter(filter: Partial<DocumentUuidFilter>): RsqlFilterModel<DocumentCardScanningFilterRsqlModel>[] {
  return [
    {
      selector: 'docUuid',
      operation: RsqlFilterOperations.Equal,
      value: filter.docUuid,
    }
  ];
}

function getDocumentCardScanningRsqlFilter(filter: Partial<DocumentCardScanningFilter>): RsqlFilterModel<DocumentCardScanningFilterRsqlModel>[] {
  return [
    {
      selector: 'productAdditionalInfoStatusSgtinBox',
      operation: RsqlFilterOperations.Equal,
      value: filter.productAdditionalInfoStatus,
    },
    {
      selector: 'productAdditionalInfoStatusSsccBox',
      operation: RsqlFilterOperations.Equal,
      value: filter.productAdditionalInfoStatus,
    }
  ];
}

function getDocumentScanningSearchFilter(searchString: string): RsqlFilterModel<DocumentCardScanningFilterRsqlModel>[] {
  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: searchString,
  }));
}

export function buildDocumentScanningFilterExpression(filter: Partial<DocumentUuidFilter & DocumentCardScanningFilter>, searchString: string): string {
  const documentUuidFilterModel = getDocumentUuidRsqlFilter(filter);
  const documentScanningCardFilterModel = getDocumentCardScanningRsqlFilter(filter);
  const searchModel = getDocumentScanningSearchFilter(searchString);

  const documentUuidFilterExpressions = convertToFilterExpressions(documentUuidFilterModel);
  const documentScanningCardFilterExpressions = convertToFilterExpressions(documentScanningCardFilterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(documentUuidFilterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(documentScanningCardFilterExpressions, RsqlLogicalOperators.Or),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
