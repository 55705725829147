import { AppTableRowActionMenuItemModel } from '@/common/models/app-table/app-table-row-action-menu-item-model';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { FileOperationsTableActions } from '@/modules/file-operations/hooks/use-file-operations-table-actions';
import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { useCallback, useMemo } from 'react';

type FileOperationsTableRowActionsHookParams = FileOperationsTableActions;

export function useFileOperationsTableRowActions({ isDocumentLoading, isReportLoading, downloadDocument, downloadReport }: FileOperationsTableRowActionsHookParams) {
  const downloadFileActionItem: AppTableRowActionMenuItemModel<FileOperation> = useMemo(() => ({
    key: 'download-file',
    title: 'Скачать документ',
    showLoader: isDocumentLoading,
    disabled: isDocumentLoading,
    action: (row) => downloadDocument(row.original.fileUuid),
  }), [isDocumentLoading]);

  const downloadReportActionItem: AppTableRowActionMenuItemModel<FileOperation> = useMemo(() => ({
    key: 'download-report',
    title: 'Скачать отчет',
    showLoader: isReportLoading,
    disabled: isReportLoading,
    action: (row) => downloadReport(row.original.bugReportUuid),
  }), [isReportLoading]);

  const getRowActions = useCallback((row: AppTableRowType<FileOperation>) => {
    const items: AppTableRowActionMenuItemModel<FileOperation>[] = [];

    if (row.original.fileUuid) {
      items.push(downloadFileActionItem);
    }
    if (row.original.bugReportUuid) {
      items.push(downloadReportActionItem);
    }

    return items;
  }, [downloadFileActionItem, downloadReportActionItem]);

  return {
    getRowActions,
  };
}
