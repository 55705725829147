import { LoadingStatus } from '@/common/models/loading-status';
import { getHeaderInfo } from '@/modules/portal/store/header/async-thunks';
import { setPortalLoadingStatus } from '@/modules/portal/store/portal';
import { getUserProfileInfo } from '@/modules/portal/store/user-profile/async-thunks';
import { useAppDispatch } from '@/store';

export function usePortalInitialization() {
  const dispatch = useAppDispatch();

  const getPortalInfo = () => {
    dispatch(setPortalLoadingStatus(LoadingStatus.Pending));

    return Promise.all([
      dispatch(getUserProfileInfo()),
      dispatch(getHeaderInfo())
    ])
      .then(() => dispatch(setPortalLoadingStatus(LoadingStatus.Fulfilled)))
      .catch(() => dispatch(setPortalLoadingStatus(LoadingStatus.Rejected)));
  };

  return { getPortalInfo };
}
