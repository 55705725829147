import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DocumentCardSharedData } from '@/shared/document/models/document-card/document-card-shared-data';
import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Location, useLocation, useParams } from 'react-router-dom';

type DocumentCardInfoHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions' | 'documentCardApi'>;

export function useDocumentCardInfo({ documentCardSelectors, documentCardActions, documentCardApi }: DocumentCardInfoHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const { id } = useParams();
  const location: Location<DocumentCardSharedData> = useLocation();

  const dataFromTable = location.state;

  // TODO: add type for documentCardSelectors.selectDocumentInfo
  const documentInfo = useSelector(documentCardSelectors.selectDocumentInfo);
  const loadingStatusDocumentInfo = useSelector(documentCardSelectors.selectLoadingStatusDocumentInfo);
  const docType = useSelector(documentCardSelectors.selectDocType);

  const getDocumentInfo = useCallback(
    () => asyncDispatch(() => documentCardApi.getDocumentInfo(id)),
    [id]);

  useEffect(() => {
    asyncDispatch(() => documentCardApi.getDocumentInfo(id));

    return () => { dispatch(documentCardActions.resetState()); };
  }, []);

  return {
    id,
    dataFromTable,
    documentInfo,
    loadingStatusDocumentInfo,
    docType,
    getDocumentInfo,
  };
}
