import { RootState } from '@/store';

const selectRemainsPageState = (state: RootState) => state.remains.page;

const selectIsExportSelectedInProcess = (state: RootState) => selectRemainsPageState(state).isExportSelectedInProcess;
const selectIsExportAllInProcess = (state: RootState) => selectRemainsPageState(state).isExportAllInProcess;
const selectSscc = (state: RootState) => selectRemainsPageState(state).unboxRemain.sscc;
const selectIsRecursive = (state: RootState) => selectRemainsPageState(state).unboxRemain.isRecursive;
const selectIsDialogOpenedByRowActions = (state: RootState) => selectRemainsPageState(state).unboxRemain.isDialogOpenedByRowActions;

export const RemainsPageSelectors = {
  selectIsExportSelectedInProcess,
  selectIsExportAllInProcess,
  selectSscc,
  selectIsRecursive,
  selectIsDialogOpenedByRowActions
};
