import { DisposalCardInfo } from '@/modules/disposals/components/disposal-card-info/DisposalCardInfo';
import {
  disposalCardBoxesRootActions,
  disposalCardBoxesTableConfigActions
} from '@/modules/disposals/store/disposal-card/boxes';
import { disposalCardBoxesApi } from '@/modules/disposals/store/disposal-card/boxes/async-thunks';
import { disposalCardBoxesSelectors } from '@/modules/disposals/store/disposal-card/boxes/selectors';
import { disposalCardActions } from '@/modules/disposals/store/disposal-card/card';
import { disposalCardApi } from '@/modules/disposals/store/disposal-card/card/async-thunks';
import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import { disposalCardGeneralPositionsRootActions } from '@/modules/disposals/store/disposal-card/general-positions';
import { disposalCardGeneralPositionsApi } from '@/modules/disposals/store/disposal-card/general-positions/async-thunks';
import {
  disposalCardHistoryRootActions,
  disposalCardHistoryTableConfigActions
} from '@/modules/disposals/store/disposal-card/history';
import { disposalCardHistoryApi } from '@/modules/disposals/store/disposal-card/history/async-thunks';
import { disposalCardHistorySelectors } from '@/modules/disposals/store/disposal-card/history/selectors';
import {
  disposalCardNotesPageableDataActions,
  disposalCardNotesRootActions
} from '@/modules/disposals/store/disposal-card/notes';
import { disposalCardNotesApi } from '@/modules/disposals/store/disposal-card/notes/async-thunks';
import { disposalCardNotesSelectors } from '@/modules/disposals/store/disposal-card/notes/selectors';
import {
  disposalCardPositionsRootActions,
  disposalCardPositionsTableConfigActions
} from '@/modules/disposals/store/disposal-card/positions';
import { disposalCardPositionsApi } from '@/modules/disposals/store/disposal-card/positions/async-thunks';
import { disposalCardPositionsSelectors } from '@/modules/disposals/store/disposal-card/positions/selectors';
import { DocumentCardBoxesTable } from '@/shared/document/components/document-card-boxes-table/DocumentCardBoxesTable';
import { DocumentCardHistoryTable } from '@/shared/document/components/document-card-history-table/DocumentCardHistoryTable';
import { DocumentCardNotes } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { DocumentCardPositionsTable } from '@/shared/document/components/document-card-positions-table/DocumentCardPositionsTable';
import { DocumentCardTab, getDocumentCardTabsTitle } from '@/shared/document/models/document-card/document-card-tab';
import { DocumentCardPage } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const DisposalCardPage = () => {
  const documentInfo = useSelector(disposalCardSelectors.selectDocumentInfo);
  const maxTableHeight = useSelector(disposalCardSelectors.selectTabContentHeight);

  const disposalCardTabs = useMemo(() => [
    // REM-837: hide unbox remains
    // {
    //   id: DocumentCardTab.All,
    //   label: getDocumentCardTabsTitle(DocumentCardTab.All),
    //   content: <DocumentCardGeneralPositionsTable
    //     documentInfo={documentInfo}
    //     selectors={disposalCardGeneralPositionsSelectors}
    //     tableConfigActions={disposalCardGeneralPositionsTableConfigActions}
    //     api={disposalCardGeneralPositionsApi}
    //     maxTableHeight={maxTableHeight}
    //   />
    // },
    {
      id: DocumentCardTab.Boxes,
      label: getDocumentCardTabsTitle(DocumentCardTab.Boxes),
      content: <DocumentCardBoxesTable
        documentInfo={documentInfo}
        selectors={disposalCardBoxesSelectors}
        rootActions={disposalCardBoxesRootActions}
        tableConfigActions={disposalCardBoxesTableConfigActions}
        api={disposalCardBoxesApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.Positions,
      label: getDocumentCardTabsTitle(DocumentCardTab.Positions),
      content: <DocumentCardPositionsTable
        documentInfo={documentInfo}
        selectors={disposalCardPositionsSelectors}
        rootActions={disposalCardPositionsRootActions}
        tableConfigActions={disposalCardPositionsTableConfigActions}
        api={disposalCardPositionsApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.History,
      label: getDocumentCardTabsTitle(DocumentCardTab.History),
      content: <DocumentCardHistoryTable
        selectors={disposalCardHistorySelectors}
        tableConfigActions={disposalCardHistoryTableConfigActions}
        api={disposalCardHistoryApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.Notes,
      label: getDocumentCardTabsTitle(DocumentCardTab.Notes),
      content: <DocumentCardNotes
        documentCardSelectors={disposalCardSelectors}
        documentCardNotesSelectors={disposalCardNotesSelectors}
        documentCardNotesPageableDataActions={disposalCardNotesPageableDataActions}
        documentCardNotesApi={disposalCardNotesApi}
      />
    },
  ], [maxTableHeight, documentInfo]);

  return (
    <DocumentCardPage
      tabs={disposalCardTabs}
      documentCardSelectors={disposalCardSelectors}
      documentCardApi={disposalCardApi}
      documentCardActions={disposalCardActions}
      documentCardGeneralPositionsActions={disposalCardGeneralPositionsRootActions}
      documentCardBoxesActions={disposalCardBoxesRootActions}
      documentCardPositionsActions={disposalCardPositionsRootActions}
      documentCardHistoryActions={disposalCardHistoryRootActions}
      documentCardNotesActions={disposalCardNotesRootActions}
      getGeneralPositions={disposalCardGeneralPositionsApi.getGeneralPositions}
      getAllPositions={disposalCardPositionsApi.getAllPositions}
      getAllBoxes={disposalCardBoxesApi.getAllBoxes}
      getAllNotes={disposalCardNotesApi.getAllNotes}
      getAllHistory={disposalCardHistoryApi.getAllHistory}
      CustomDocumentCardInfo={DisposalCardInfo}
    />
  );
};
