import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { setCode } from '@/modules/auth/store/password-recovery';
import { passwordRecoveryInit, sendVerificationCode } from '@/modules/auth/store/password-recovery/async-thunks';
import { PasswordRecoverySelectors } from '@/modules/auth/store/password-recovery/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

export function usePhoneCodeFormForPasswordRecovery() {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const code = useSelector(PasswordRecoverySelectors.selectCode);
  const codeStatus = useSelector(PasswordRecoverySelectors.selectCodeStatus);
  const login = useSelector(PasswordRecoverySelectors.selectLogin);
  const isLoading = useSelector(PasswordRecoverySelectors.selectIsLoading);
  const email = useSelector(PasswordRecoverySelectors.selectEmail);
  const expireTimePhone = useSelector(PasswordRecoverySelectors.selectExpireTimePhoneDate);

  const onCodeInput = useCallback((value: string) => {
    dispatch(setCode(value));
  }, []);

  const onCodeValueChange = useCallback(() => {
    asyncDispatch(passwordRecoveryInit);
  }, []);

  const onSendPhoneNumberCodeClick = useCallback(() => dispatch(sendVerificationCode()), []);

  const successMessage: React.ReactNode = <>На ваш email <span className="email">{email}</span> была отправлена ссылка для смены пароля. <br />Не забудьте проверить раздел “спам”.</>;

  return {
    code,
    codeStatus,
    login,
    isLoading,
    expireTimePhone,
    successMessage,
    onCodeInput,
    onCodeValueChange,
    onSendPhoneNumberCodeClick,
  };
}
