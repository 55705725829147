import { AppLink } from '@/common/components/app-link/AppLink';
import { fileOperationsRoutePath } from '@/modules/file-operations/routes/routes';
import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './import-export-link.scss';

interface ImportExportLinkProps {
  onClick?: () => void;
}

const ImportExportLinkInner: FC<ImportExportLinkProps> = ({ onClick }) => {
  const navigate = useNavigate();

  const onClickInner = useCallback(() => {
    onClick?.();
    navigate(fileOperationsRoutePath);
  }, []);

  return (
    <AppLink
      text="«Импорт/Экспорт»"
      classes={{ container: 'import-export-link-container', font: 'import-export-link-font' }}
      href={fileOperationsRoutePath}
      onClick={onClickInner}
    />
  );
};

export const ImportExportLink = memo(ImportExportLinkInner);
