import { RootState } from '@/store';

const selectCommonDataSourcesState = (state: RootState) => state.commonDataSources;

const selectOrganizationPlacesData = (state: RootState) => selectCommonDataSourcesState(state).organizationPlaces.data;
const selectIsLoadingOrganizationPlaces = (state: RootState) => selectCommonDataSourcesState(state).organizationPlaces.isLoading;

export const CommonDataSourcesSelectors = {
  selectOrganizationPlacesData,
  selectIsLoadingOrganizationPlaces
};
