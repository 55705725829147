import { BackButton } from '@/modules/portal/components/back-button/BackButton';
import { ScanningViewScanInput } from '@/shared/scanning-view/components/scanning-view-scan-input/ScanningViewScanInput';
import { ScanningItemProps, ScanningViewProps } from '@/shared/scanning-view/ScanningView';
import React, { FC, memo } from 'react';
import './scanning-view-header.scss';

type ScanningViewHeaderProps = Pick<ScanningItemProps, 'isGettingLastScannedItemInProcess' | 'onScanningItem'> &
  Pick<ScanningViewProps, 'backNavigationRoute'>;

const ScanningViewHeaderInner: FC<ScanningViewHeaderProps> = ({ backNavigationRoute, isGettingLastScannedItemInProcess, onScanningItem }) => {
  return (
    <div className="scanning-view-header">
      <BackButton
        defaultRoute={backNavigationRoute}
        className="scanning-view-header-back-button"
      />
      <ScanningViewScanInput
        readOnly={isGettingLastScannedItemInProcess}
        onScan={onScanningItem}
      />
    </div>
  );
};

export const ScanningViewHeader = memo(ScanningViewHeaderInner);
