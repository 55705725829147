import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { UserProfileSelectors } from '@/modules/portal/store/user-profile/selectors';
import { InviteUserResponse } from '@/modules/users-management/models/invite-user/invite-user-response';
import { SaveNewManagerResponse } from '@/modules/users-management/models/save-new-user/save-new-user-response';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { UsersManagementRequests } from '@/modules/users-management/requests/users-management-requests';
import { UsersManagementSelectors } from '@/modules/users-management/store/selectors';
import { buildUsersManagementFilterExpression, getUsersManagementSearchFields } from '@/modules/users-management/utils/users-management-filter-utils';
import { convertUsersManagementColumnFieldToDto } from '@/modules/users-management/utils/users-management-sort-converter';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllUsers = createAsyncThunkWrapper<
  PageableDataResponse<UserInfo>,
  PageableDataBody
>(
  'usersManagement/getAllUsers',
  ({ pageNumber }, { getState }) => {
    const sorting = UsersManagementSelectors.selectTableSorting(getState());
    const showBlockedUsers = UsersManagementSelectors.selectShowBlockedUsers(getState());
    const searchString = UsersManagementSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: AppTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertUsersManagementColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const searchFields = getUsersManagementSearchFields(UserProfileSelectors.selectProfileInfo(getState()).roles);
    const filterParams = buildUsersManagementFilterExpression({ showBlockedUsers }, searchString, searchFields);
    if (filterParams) {
      body.filter = filterParams;
    }

    return UsersManagementRequests.getAllUsers(body)
      .then((res) => res.data);

  },
  { condition: asyncThunkConditionOption(UsersManagementSelectors.selectIsLoadingUsers) },
);

export const saveNewUser = createAsyncThunkWrapper<
  SaveNewManagerResponse,
  undefined
>(
  'usersManagement/saveUser',
  (_, { getState }) => {
    const state = getState();
    const editUser = UsersManagementSelectors.selectEditingUser(state);
    const phoneNumber = UsersManagementSelectors.selectPreprocessedPhoneNumber(state);

    return UsersManagementRequests.addManager({ ...editUser, phoneNumber })
      .then((res) => res.data);
  },
  { condition: asyncThunkConditionOption(UsersManagementSelectors.selectIsLoadingEditUser) },
);

export const editUser = createAsyncThunkWrapper<
  UserInfo,
  undefined
>(
  'usersManagement/editUser',
  async (_, { getState }) => {
    const state = getState();
    const editUser = UsersManagementSelectors.selectEditingUser(state);
    const phoneNumber = UsersManagementSelectors.selectPreprocessedPhoneNumber(state);

    return UsersManagementRequests.editUser({ ...editUser, phoneNumber })
      .then((res) => res.data);
  },
  { condition: asyncThunkConditionOption(UsersManagementSelectors.selectIsLoadingEditUser) },
);

export const activateUser = createAsyncThunkWrapper<
  void,
  string
>(
  'usersManagement/activateUser',
  async (userUuid: string) => { await UsersManagementRequests.activateUser(userUuid); },
);

export const blockUser = createAsyncThunkWrapper<
  void,
  string
>(
  'usersManagement/blockUser',
  async (userUuid: string) => { await UsersManagementRequests.blockUser(userUuid); },
);

export const inviteUser = createAsyncThunkWrapper<
  InviteUserResponse,
  string
>(
  'usersManagement/inviteUser',
  (userUuid: string) => {
    return UsersManagementRequests.inviteUser(userUuid)
      .then((response) => response.data);
  },
);
