import { saleCardBoxesRootActions, saleCardBoxesTableConfigActions } from '@/modules/sale/store/sale-card/boxes';
import { saleCardBoxesApi } from '@/modules/sale/store/sale-card/boxes/async-thunks';
import { saleCardBoxesSelectors } from '@/modules/sale/store/sale-card/boxes/selectors';
import { saleCardActions } from '@/modules/sale/store/sale-card/card';
import { saleCardApi } from '@/modules/sale/store/sale-card/card/async-thunks';
import { saleCardSelectors } from '@/modules/sale/store/sale-card/card/selectors';
import { saleCardGeneralPositionsRootActions } from '@/modules/sale/store/sale-card/general-positions';
import { saleCardGeneralPositionsApi } from '@/modules/sale/store/sale-card/general-positions/async-thunks';
import { saleCardHistoryRootActions, saleCardHistoryTableConfigActions } from '@/modules/sale/store/sale-card/history';
import { saleCardHistoryApi } from '@/modules/sale/store/sale-card/history/async-thunks';
import { saleCardHistorySelectors } from '@/modules/sale/store/sale-card/history/selectors';
import { saleCardNotesPageableDataActions, saleCardNotesRootActions } from '@/modules/sale/store/sale-card/notes';
import { saleCardNotesApi } from '@/modules/sale/store/sale-card/notes/async-thunks';
import { saleCardNotesSelectors } from '@/modules/sale/store/sale-card/notes/selectors';
import { saleCardPositionsRootActions, saleCardPositionsTableConfigActions } from '@/modules/sale/store/sale-card/positions';
import { saleCardPositionsApi } from '@/modules/sale/store/sale-card/positions/async-thunks';
import { saleCardPositionsSelectors } from '@/modules/sale/store/sale-card/positions/selectors';
import { DocumentCardBoxesTable } from '@/shared/document/components/document-card-boxes-table/DocumentCardBoxesTable';
import { DocumentCardHistoryTable } from '@/shared/document/components/document-card-history-table/DocumentCardHistoryTable';
import { DocumentCardNotes } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { DocumentCardPositionsTable } from '@/shared/document/components/document-card-positions-table/DocumentCardPositionsTable';
import { DocumentCardTab, getDocumentCardTabsTitle } from '@/shared/document/models/document-card/document-card-tab';
import { DocumentCardPage } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const SaleCardPage = () => {
  const documentInfo = useSelector(saleCardSelectors.selectDocumentInfo);
  const maxTableHeight = useSelector(saleCardSelectors.selectTabContentHeight);

  const saleCardTabs = useMemo(() => [
    // REM-837: hide unbox remains
    // {
    //   id: DocumentCardTab.All,
    //   label: getDocumentCardTabsTitle(DocumentCardTab.All),
    //   content: <DocumentCardGeneralPositionsTable
    //     documentInfo={documentInfo}
    //     selectors={saleCardGeneralPositionsSelectors}
    //     tableConfigActions={saleCardGeneralPositionsTableConfigActions}
    //     api={saleCardGeneralPositionsApi}
    //     maxTableHeight={maxTableHeight}
    //   />
    // },
    {
      id: DocumentCardTab.Boxes,
      label: getDocumentCardTabsTitle(DocumentCardTab.Boxes),
      content: <DocumentCardBoxesTable
        documentInfo={documentInfo}
        selectors={saleCardBoxesSelectors}
        rootActions={saleCardBoxesRootActions}
        tableConfigActions={saleCardBoxesTableConfigActions}
        api={saleCardBoxesApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.Positions,
      label: getDocumentCardTabsTitle(DocumentCardTab.Positions),
      content: <DocumentCardPositionsTable
        documentInfo={documentInfo}
        selectors={saleCardPositionsSelectors}
        rootActions={saleCardPositionsRootActions}
        tableConfigActions={saleCardPositionsTableConfigActions}
        api={saleCardPositionsApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.History,
      label: getDocumentCardTabsTitle(DocumentCardTab.History),
      content: <DocumentCardHistoryTable
        selectors={saleCardHistorySelectors}
        tableConfigActions={saleCardHistoryTableConfigActions}
        api={saleCardHistoryApi}
        maxTableHeight={maxTableHeight}
      />
    },
    {
      id: DocumentCardTab.Notes,
      label: getDocumentCardTabsTitle(DocumentCardTab.Notes),
      content: <DocumentCardNotes
        documentCardSelectors={saleCardSelectors}
        documentCardNotesSelectors={saleCardNotesSelectors}
        documentCardNotesPageableDataActions={saleCardNotesPageableDataActions}
        documentCardNotesApi={saleCardNotesApi}
      />
    },
  ], [maxTableHeight, documentInfo]);

  return (
    <DocumentCardPage
      tabs={saleCardTabs}
      documentCardSelectors={saleCardSelectors}
      documentCardApi={saleCardApi}
      documentCardActions={saleCardActions}
      documentCardGeneralPositionsActions={saleCardGeneralPositionsRootActions}
      documentCardBoxesActions={saleCardBoxesRootActions}
      documentCardPositionsActions={saleCardPositionsRootActions}
      documentCardHistoryActions={saleCardHistoryRootActions}
      documentCardNotesActions={saleCardNotesRootActions}
      getGeneralPositions={saleCardGeneralPositionsApi.getGeneralPositions}
      getAllPositions={saleCardPositionsApi.getAllPositions}
      getAllHistory={saleCardHistoryApi.getAllHistory}
      getAllBoxes={saleCardBoxesApi.getAllBoxes}
      getAllNotes={saleCardNotesApi.getAllNotes}
    />
  );
};
