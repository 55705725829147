import { AppToast } from '@/common/components/app-toast/AppToast';
import { disableReactDevTools } from '@/common/utils/react/disable-dev-tools';
import router from '@/router';
import { store } from '@/store';
import React, { useEffect } from 'react';
import { AliveScope } from 'react-activation';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './styles.scss';

const container = document.getElementById('root');
const root = createRoot(container);

const App = () => {
  useEffect(() => {
    disableReactDevTools();
  }, []);

  return (
    <AliveScope>
      <Provider store={store} >
        <RouterProvider router={router} />
        <AppToast />
      </Provider>
    </AliveScope>
  );
};

root.render(<App />);
