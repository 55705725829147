import { AppList } from '@/common/components/app-list/AppList';
import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import React, { FC, ReactNode, memo } from 'react';
import './blocked-info-item.scss';

type BlockedInfoItemColorVariant = 'error' | 'info';

interface BlockedInfoItemProps {
  title: string;
  info: string[];
  hintText?: ReactNode;
  variant?: BlockedInfoItemColorVariant;
}

const BlockedInfoItemInner: FC<BlockedInfoItemProps> = ({ title, info, hintText, variant = 'error' }) => (
  <div className={`blocked-info-item variant-${variant}`}>
    <div className="blocked-info-item-title">
      {title} {hintText && <HintIcon text={hintText} />}
    </div>
    <AppList
      items={info}
      classNames={{ list: 'blocked-info-item-list' }}
    />
  </div>
);

export const BlockedInfoItem = memo(BlockedInfoItemInner);