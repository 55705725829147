import React, { FC, memo, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import './app-link.scss';

interface AppLinkProps {
  classes?: { container?: string; font?: string; };
  href?: string;
  text?: string;
  width?: number;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  onClick?: () => void;
}

const AppLinkInner: FC<AppLinkProps> = ({ classes, href, text, width, disabled, startIcon, onClick }) => {
  const navigate = useNavigate();

  const onClickInner = (event: MouseEvent) => {
    event.preventDefault();

    onClick?.();

    if (href) {
      navigate(href);
    }
  };

  return (
    <a
      href={href}
      style={{ width }}
      className={`app-link link ${classes?.container ?? ''} ${disabled ? 'app-link-disabled' : ''}`}
    >
      <span
        className={`app-link-inner-container ${startIcon ? 'app-link-inner-container-flex' : ''}`}
        onClick={onClickInner}
      >
        {startIcon &&
          <div className="app-link-start-icon">
            {startIcon}
          </div>
        }
        <span className={`${classes?.font ?? ''}`}>
          {text}
        </span>
      </span>
    </a>
  );
};

export const AppLink = memo(AppLinkInner);
