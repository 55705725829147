export enum OrganizationState {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  InProcessOfSuspending = 'IN_PROCESS_OF_SUSPENDING',
  Unknown = 'UNKNOWN',
}

const organizationStatesTitles = {
  [OrganizationState.Active]: 'Действует',
  [OrganizationState.Deactivated]: 'Неактивно',
  [OrganizationState.InProcessOfSuspending]: 'В процессе приостановления',
  [OrganizationState.Unknown]: 'Неизвестно',
};

export const getOrganizationStateTitle = (key: OrganizationState): string => organizationStatesTitles[key];
