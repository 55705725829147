import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';

const documentsTableColumnsToDtoMap: Partial<Record<DocumentsTableColumns, keyof ShortDocumentInfo>> = {
  [DocumentsTableColumns.IsFavorite]: 'isFavorite',
  [DocumentsTableColumns.Partner]: 'partnerOrganizationName',
  [DocumentsTableColumns.ActivityPlace]: 'activityPlaceName',
  [DocumentsTableColumns.DocNumber]: 'docNumber',
  [DocumentsTableColumns.DocDate]: 'docDate',
  [DocumentsTableColumns.Sum]: 'totalPriceWithVat',
  [DocumentsTableColumns.Order]: 'docOrder',
  [DocumentsTableColumns.Status]: 'docState',
  [DocumentsTableColumns.LastNotification]: 'lastInteractionSchema',
  [DocumentsTableColumns.Comment]: 'comment',
  [DocumentsTableColumns.CreatedDate]: 'createdDate',
};

type DocumentsDtoToColumnsMap = Record<keyof ShortDocumentInfo, DocumentsTableColumns>;

const documentsDtoToColumnsMap: DocumentsDtoToColumnsMap =
  Object.entries(documentsTableColumnsToDtoMap).reduce((obj, [key, value]) => ({
    ...obj,
    [value]: key,
  }), {} as DocumentsDtoToColumnsMap);

export const convertDocumentColumnFieldToDto = (columnField: DocumentsTableColumns) => documentsTableColumnsToDtoMap[columnField];
export const convertDocumentDtoFieldToColumn = (dtoField: keyof ShortDocumentInfo) => documentsDtoToColumnsMap[dtoField];
