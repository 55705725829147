import { disposalCardNotesModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import { disposalCardNotesSelectors } from '@/modules/disposals/store/disposal-card/notes/selectors';
import { createDocumentCardNotesThunks } from '@/shared/document/store/document-card/notes/async-thunks';

export const disposalCardNotesApi = createDocumentCardNotesThunks({
  moduleName: disposalCardNotesModuleName,
  documentCardSelectors: disposalCardSelectors,
  documentCardNotesSelectors: disposalCardNotesSelectors,
});
