import { isDateValid } from '@/common/utils/common/date-utils';
import { isDate } from 'date-fns';

export const isEmptyObject = <T extends Record<string, unknown>>(obj: T) => {
  return !obj || Object.values(obj).every((value) => !value);
};

export const isValidObject = <T extends Record<string, unknown>>(obj: T) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }

  return Object.values(obj).every((value) => {
    if (isDate(value) && !isDateValid(value)) {
      return false;
    }

    if (value instanceof Object) {
      return isValidObject(value);
    }

    if (value instanceof Array) {
      return value.every((item) => isValidObject(item));
    }

    if (typeof value === 'number') {
      return isNaN(value);
    }

    return true;
  });
};

export const isNonEmptyValue = (value: unknown) => {
  if (isDate(value) && isDateValid(value as Date)) {
    return true;
  }
  if (value instanceof Object && !isEmptyObject(value as Record<string, unknown>)) {
    return true;
  }
  if (value instanceof Array && !!value.length) {
    return true;
  }
  if ((typeof value === 'number' || typeof value === 'string') && !!value) {
    return true;
  }
  if (typeof value === 'boolean') {
    return true;
  }
  return false;
};

export const getCountOfNonEmptyFields = <T extends Record<string, unknown>>(obj: T) =>
  Object.keys(obj).reduce((count, key) => {
    isNonEmptyValue(obj[key]) && count++;
    return count;
  }, 0);
