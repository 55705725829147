export enum InteractionOrigin {
  Mdlp = 'MDLP',
  Application = 'APPLICATION'
}

export const interactionOriginLabels = {
  [InteractionOrigin.Mdlp]: 'МДЛП',
  [InteractionOrigin.Application]: 'ООО "Ремедиум"',
};

export const getInteractionOriginLabel = (interactionOrigin: InteractionOrigin) => interactionOriginLabels[interactionOrigin];

export const getTargetOppositeSource = (source: InteractionOrigin) =>
  source === InteractionOrigin.Application
    ? InteractionOrigin.Mdlp
    : InteractionOrigin.Application;


export const getSourceTargetLabel = (source: InteractionOrigin) => {
  const target = getTargetOppositeSource(source);
  return `${interactionOriginLabels[source]} - ${interactionOriginLabels[target]}`;
};
