import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { UsersManagementTableActions } from '@/modules/users-management/hooks/use-users-management-table-actions';
import { DialogClosedParams } from '@/modules/users-management/models/dialog-closed-params';
import {
  patchUser,
  updateSearchString,
  updateShowBlockedUsersValue,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
  updateTableColumnVisibilityState,
  updateTableSortingState
} from '@/modules/users-management/store';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type UsersManagementTableEventHandlersHookParams = PageableLoadingActions & Pick<UsersManagementTableActions, 'addUser'>;

export function useUsersManagementTableEventHandlers({ loadNextPage, loadFirstPage, addUser }: UsersManagementTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();

  const onAddClick = useCallback(addUser, []);

  const onDialogClosed = useCallback(
    ({ successfulSave, updatedEditUser, indexEditUser }: DialogClosedParams) => {
      if (successfulSave) {
        updatedEditUser
          ? dispatch(patchUser({ item: updatedEditUser, index: indexEditUser }))
          : loadFirstPage();
      }
    }, []);

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(updateSearchString(search));
  }, []);

  const onShowBlockedUsersChange = useCallback((value: boolean) => {
    dispatch(updateShowBlockedUsersValue(value));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onScrollChange = useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  return {
    onAddClick,
    onDialogClosed,
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onShowBlockedUsersChange,
    onScrollChange
  };
}
