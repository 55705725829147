import headerReducer from '@/modules/portal/store/header';
import portalInnerReducer from '@/modules/portal/store/portal';
import syncReducer from '@/modules/portal/store/sync';
import userProfileReducer from '@/modules/portal/store/user-profile';
import { combineReducers } from '@reduxjs/toolkit';

const portalReducer = combineReducers({
  portal: portalInnerReducer,
  userProfile: userProfileReducer,
  header: headerReducer,
  sync: syncReducer
});

export default portalReducer;
