export enum InteractionEvent {
  SCHEMA_251 = 'SCHEMA_251',
  SCHEMA_252 = 'SCHEMA_252',
  SCHEMA_415 = 'SCHEMA_415',
  SCHEMA_416 = 'SCHEMA_416',
  SCHEMA_552 = 'SCHEMA_552',
  SCHEMA_601 = 'SCHEMA_601',
  SCHEMA_602 = 'SCHEMA_602',
  SCHEMA_606 = 'SCHEMA_606',
  SCHEMA_607 = 'SCHEMA_607',
  SCHEMA_701 = 'SCHEMA_701',
  SCHEMA_912 = 'SCHEMA_912',
}

export const interactionEventLabels = {
  [InteractionEvent.SCHEMA_251]: 'Регистрация в ИС МДЛП сведений об отзыве отправителем переданных получателю лекарственных препаратов (251)',
  [InteractionEvent.SCHEMA_252]: 'Направление сведений для отказа в получении товара (252)',
  [InteractionEvent.SCHEMA_415]: 'Передача сведений об отгрузке ЛП со склада отправителя (415)',
  [InteractionEvent.SCHEMA_416]: 'Передача получателем сведений о приемке ЛП на склад (416)',
  [InteractionEvent.SCHEMA_552]: 'Передача сведений о выводе из оборота ЛП (552)',
  [InteractionEvent.SCHEMA_601]: 'Уведомление получателя об отгрузке лекарственных препаратов со склада отправителя (601)',
  [InteractionEvent.SCHEMA_602]: 'Уведомление отправителя о приемке лекарственных препаратов на склад получателя (602)',
  [InteractionEvent.SCHEMA_606]: 'Уведомление отправителя об отказе получателя от приемки лекарственного препарата (606)',
  [InteractionEvent.SCHEMA_607]: 'Уведомление о подтверждении (акцептовании) сведений (607)',
  [InteractionEvent.SCHEMA_701]: 'Подтверждение (акцептование сведений) (701)',
  [InteractionEvent.SCHEMA_912]: 'Разагрегирование третичной упаковки (912)',
};

export const getInteractionEventLabel = (event: InteractionEvent) => {
  return interactionEventLabels[event] ?? '';
};
