import { disposalsRoutePath } from '@/modules/disposals/routes/routes';
import { favoritesRoutePath } from '@/modules/favorites/routes/routes';
import { fileOperationsRoutePath } from '@/modules/file-operations/routes/routes';
import { interactionLogRoutePath } from '@/modules/interaction-log/routes/routes';
import { organizationInformationPath, organizationPlacesPath } from '@/modules/organization-profile/routes/routes';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { portalNotFoundRoutePath } from '@/modules/portal/routes/routes';
import { receiptsRoutePath } from '@/modules/receipts/routes/routes';
import { legalEntitiesRoutePath, mdlpProductsRoutePath, nomenclatureRoutePath, processesRoutePath } from '@/modules/references/routes/routes';
import { remainsRoutePath } from '@/modules/remains/routes/routes';
import { salesRoutePath } from '@/modules/sale/routes/routes';
import { usersManagementRoutePath } from '@/modules/users-management/routes/routes';

const usersPermissionsByRoutes: Record<string, UserPermission> = {
  [portalNotFoundRoutePath]: UserPermission.ViewPortalNotFound,
  [favoritesRoutePath]: UserPermission.ViewFavorites,
  [receiptsRoutePath]: UserPermission.ViewReceipts,
  [salesRoutePath]: UserPermission.ViewSales,
  [disposalsRoutePath]: UserPermission.ViewDisposals,
  [remainsRoutePath]: UserPermission.ViewRemains,
  [interactionLogRoutePath]: UserPermission.ViewInteractionLog,
  [fileOperationsRoutePath]: UserPermission.ViewFileOperations,
  [legalEntitiesRoutePath]: UserPermission.ViewLegalEntities,
  [mdlpProductsRoutePath]: UserPermission.ViewMdlpProducts,
  [nomenclatureRoutePath]: UserPermission.ViewNomenclature,
  [processesRoutePath]: UserPermission.ViewProcesses,
  [organizationInformationPath]: UserPermission.ViewOrganizationProfile,
  [organizationPlacesPath]: UserPermission.ViewOrganizationProfile,
  [usersManagementRoutePath]: UserPermission.ViewUsersManagement,
};

export const getPermissionByRoute = (route: string) => {
  const parentOrCurrentRoute = Object.keys(usersPermissionsByRoutes)
    .find((value) => route.startsWith(value));
  return usersPermissionsByRoutes?.[parentOrCurrentRoute] ?? undefined;
};
