import { UsersManagementSortFields } from '@/modules/users-management/models/users-management-table/users-management-sort-fields';
import { UsersManagementTableColumns } from '@/modules/users-management/models/users-management-table/users-management-table-columns';

const legalEntitiesColumnsToSortFields: Partial<Record<UsersManagementTableColumns, UsersManagementSortFields>> = {
  [UsersManagementTableColumns.ActivityPlaces]: 'activityPlacesName',
  [UsersManagementTableColumns.CreatedDate]: 'createdDate',
  [UsersManagementTableColumns.Email]: 'email',
  [UsersManagementTableColumns.Fio]: 'lastName',
  [UsersManagementTableColumns.LastSigninDate]: 'lastSigninDate',
  [UsersManagementTableColumns.Organization]: 'organizationName',
  [UsersManagementTableColumns.PhoneNumber]: 'phoneNumber',
  [UsersManagementTableColumns.Position]: 'position',
};

export const convertUsersManagementColumnFieldToDto = (columnField: UsersManagementTableColumns) => legalEntitiesColumnsToSortFields[columnField];