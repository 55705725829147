import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { ProcessesTableColumns } from '@/modules/references/models/processes/processes-table-columns';

const columnVisibility = {
  [ProcessesTableColumns.Scheme]: true,
  [ProcessesTableColumns.Description]: true,
  [ProcessesTableColumns.Application]: true,
};

const sorting = [
  {
    id: ProcessesTableColumns.Scheme,
    desc: false,
  }
];

const columnOrder = [
  ProcessesTableColumns.Scheme,
  ProcessesTableColumns.Description,
  ProcessesTableColumns.Application,
  AppTableActionColumnName,
];

const columnSizing = {
  [ProcessesTableColumns.Scheme]: 60,
  [ProcessesTableColumns.Description]: 150,
  [ProcessesTableColumns.Application]: 150,
};

export const defaultProcessesTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
