import { AppButton } from '@/common/components/app-button/AppButton';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { RejectContentClassNames } from '@/modules/portal/models/loader-wrapper/reject-content-class-names';
import { AsyncAction } from '@/store';
import React, { FC, ReactNode, memo, useCallback } from 'react';
import './rejected-content.scss';

interface RejectedContentProps {
  classNames?: RejectContentClassNames;
  bottomContent?: ReactNode;
  retryAction: AsyncAction;
}
const RejectedContentInner: FC<RejectedContentProps> = ({ classNames, bottomContent, retryAction }) => {
  const { asyncDispatch } = useAsyncDispatch();

  const onRetryAction = useCallback(() => {
    asyncDispatch(retryAction);
  }, []);

  return (
    <div className={`rejected-content ${classNames?.wrapper ?? ''}`}>
      <h1 className={classNames?.title ?? ''}>Ошибка</h1>
      <h2>Не удалось загрузить данные. Проверьте ваше интернет-соединение и повторите попытку</h2>
      <AppButton
        label="Повторить"
        className={classNames?.button ?? ''}
        onClick={onRetryAction}
      />
      {bottomContent}
    </div>
  );
};

export const RejectedContent = memo(RejectedContentInner);

