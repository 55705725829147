import { buildModelValueGetter } from '@/common/utils/app-table/app-table-utils';
import { Document } from '@/shared/document/models/document';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { convertDocumentDtoFieldToColumn } from '@/shared/document/models/documents/documents-table-columns-converters';

export type UpdateDocumentBody = Pick<Document, 'comment'>;

export function convertFromColumnModelToUpdateDocumentBody(model: Record<DocumentsTableColumns, any>): UpdateDocumentBody {
  const getData = buildModelValueGetter<DocumentsTableColumns, UpdateDocumentBody>(convertDocumentDtoFieldToColumn, model);

  return {
    comment: getData('comment')?.trim(),
  };
}
