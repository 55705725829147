import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectOrganizationProfilePlacesState = (state: RootState) => state.organizationProfile.places;

const selectOrganizationPlaces = (state: RootState) => selectOrganizationProfilePlacesState(state).filteredPlaces;
const selectLoadingStatus = (state: RootState) => selectOrganizationProfilePlacesState(state).loadingStatus;

const selectSearchState = (state: RootState) => selectOrganizationProfilePlacesState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

export const OrganizationProfilePlacesSelectors = {
  selectLoadingStatus,
  selectSearchState,
  selectSearchString,
  selectOrganizationPlaces,
};
