import { remainsTableModuleName } from '@/modules/remains/constants/remains-module-names';
import { remainsTableApi } from '@/modules/remains/store/table/async-thunks';
import { createRemainsSlice } from '@/shared/remains/store';

const remainsTableSlice = createRemainsSlice({
  moduleName: remainsTableModuleName,
  savedTableConfigName: 'remains-table',
  api: remainsTableApi,
});

export const remainsTableRootActions = remainsTableSlice.rootSlice.actions;
export const remainsSearchingActions = remainsTableSlice.remainsSearchingSlice.actions;
export const remainsTableConfigActions = remainsTableSlice.remainsTableConfigSlice.actions;
export const remainsFilterConfigActions = remainsTableSlice.remainsFilterConfigSlice.actions;
export const remainsPageableDataActions = remainsTableSlice.remainsPageableDataSlice.actions;

export default remainsTableSlice.rootSlice.reducer;
