import { Remain } from '@/shared/remains/models/remain';
import { RemainsTableColumns } from '@/shared/remains/models/remains-table-columns';

const remainsTableColumnsToDtoMap: Record<Partial<RemainsTableColumns>, keyof Remain> = {
  [RemainsTableColumns.Sgtin]: 'sgtin',
  [RemainsTableColumns.Gtin]: 'gtin',
  [RemainsTableColumns.ActivityPlace]: 'placeName',
  [RemainsTableColumns.Name]: 'name',
  [RemainsTableColumns.Producer]: 'producer',
  [RemainsTableColumns.ProducerCountry]: 'producerCountry',
  [RemainsTableColumns.SeriesNumber]: 'seriesNumber',
  [RemainsTableColumns.ExpirationDate]: 'expirationDate',
  [RemainsTableColumns.Sscc]: 'sscc',
  [RemainsTableColumns.Document]: 'relatedDocNumber',
};

export const convertRemainColumnFieldToDto = (columnField: RemainsTableColumns) => remainsTableColumnsToDtoMap[columnField];
