import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { favoritesModuleName } from '@/modules/favorites/constants/favorites-module-name';
import { FavoritesTableItemsPageSize } from '@/modules/favorites/constants/favorites-table-items-page-size';
import { FavoriteDocument } from '@/modules/favorites/models/favorite-document';
import { convertFavoriteDocumentsToFavoriteDocumentsWithIndicator } from '@/modules/favorites/models/favorite-document-with-indicator';
import { convertFavoritesColumnFieldToDto } from '@/modules/favorites/models/favorites-table-column-converter';
import { FavoritesRequests } from '@/modules/favorites/requests/favorites-requests';
import { FavoritesSelectors } from '@/modules/favorites/store/selectors';
import { buildFavoritesFilterExpression } from '@/modules/favorites/utils/favorites-filter-utils';
import { UpdateDocumentBody } from '@/shared/document/models/documents/update-document-body';
import { DocumentRequests } from '@/shared/document/requests/document-requests';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllFavoriteDocuments = createAsyncThunkWrapper<
  PageableDataResponse<FavoriteDocument>,
  PageableDataBody
>(
  `${favoritesModuleName}/getAllFavoriteDocuments`,
  ({ pageNumber, size }, { getState }) => {

    const sorting = FavoritesSelectors.selectTableSorting(getState());
    const searchString = FavoritesSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: size ?? FavoritesTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertFavoritesColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const filterParams = buildFavoritesFilterExpression(searchString);
    if (filterParams) {
      body.filter = filterParams;
    }

    return FavoritesRequests.getAllFavoriteDocuments(body)
      .then((res) => {
        const favoritesWithIndicator = convertFavoriteDocumentsToFavoriteDocumentsWithIndicator(res.data.data);
        return { ...res.data, data: favoritesWithIndicator };
      });
  },
  { condition: asyncThunkConditionOption(FavoritesSelectors.selectIsLoading) }
);

export const updateFavoriteDocument = createAsyncThunkWrapper<
  void,
  { docUuid: string; body: UpdateDocumentBody; }
>(
  `${favoritesModuleName}/updateFavoriteDocument`,
  async ({ docUuid, body }) => { await DocumentRequests.updateDocument(docUuid, body); },
  { condition: asyncThunkConditionOption(FavoritesSelectors.selectIsUpdatingInProcess) },
);
