import {
  AppTableBottomToolbar,
  AppTableBottomToolbarProps
} from '@/common/components/app-table/components/app-table-bottom-toolbar/AppTableBottomToolbar';
import {
  AppTableTopToolbar,
  AppTableTopToolbarProps
} from '@/common/components/app-table/components/app-table-top-toolbar/AppTableTopToolbar';
import { AppTable, AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { useTableHeight } from '@/common/hooks/use-table-height';
import React, { FC, useRef } from 'react';

interface AppCustomTableProps extends AppTableProps {
  topToolbar?: AppTableTopToolbarProps;
  bottomToolbar?: AppTableBottomToolbarProps;
}

export const AppCustomTable: FC<AppCustomTableProps> = ({ topToolbar, bottomToolbar, ...table }: AppCustomTableProps) => {
  const topToolbarRef = useRef<HTMLDivElement>(null);
  const bottomToolbarRef = useRef<HTMLDivElement>(null);
  const { tableMaxHeight } = useTableHeight({ topToolbarRef, bottomToolbarRef });

  return (
    <>
      {topToolbar &&
        <AppTableTopToolbar
          ref={topToolbarRef}
          {...topToolbar}
        />
      }
      <AppTable
        {...table}
        maxHeight={tableMaxHeight}
      />
      {bottomToolbar &&
        <AppTableBottomToolbar
          ref={bottomToolbarRef}
          {...bottomToolbar}
        />
      }
    </>
  );
};
