import { AppInput } from '@/common/components/app-input/AppInput';
import { FormValidationResult } from '@/common/utils/client-validation/validation-result';
import { PasswordValidationHint } from '@/modules/auth/components/password-validation-hint/PasswordValidationHint';
import { usePasswordInput } from '@/modules/auth/hooks/use-password-input';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import React, { FC, memo, useCallback } from 'react';
import './password-confirmation.scss';

interface PasswordConfirmationProps {
  passwords: PasswordsConfirmation;
  isNewPassword?: boolean;
  passwordMaxLength: number;
  validationResult: FormValidationResult<string>;
  className?: string;
  onChangePasswords: (passwords: PasswordsConfirmation) => void;
}

const PasswordConfirmationInner: FC<PasswordConfirmationProps> = ({ passwords, isNewPassword = false, passwordMaxLength, validationResult, className, onChangePasswords }) => {

  const onChangePasswordsInner = useCallback((field: keyof PasswordsConfirmation) =>
    (value: string) => onChangePasswords({ ...passwords, [field]: value })
    , [passwords]);

  const onPasswordChange = useCallback(onChangePasswordsInner('password'), [onChangePasswordsInner]);
  const onConfirmationPasswordChange = useCallback(onChangePasswordsInner('passwordConfirmation'), [onChangePasswordsInner]);

  const passwordInputContext = usePasswordInput();
  const passwordConfirmationInputContext = usePasswordInput();

  return (
    <div className={`password-confirmation ${className ?? ''}`}>
      <AppInput
        autoComplete="new-password"
        label={isNewPassword ? 'Новый пароль' : 'Пароль'}
        type={passwordInputContext.passwordInputType}
        value={passwords.password}
        maxLength={passwordMaxLength}
        error={validationResult.password?.error}
        helperText={validationResult.password?.messages?.[0]}
        hint={<PasswordValidationHint />}
        iconEnd={passwordInputContext.passwordIconComponent}
        onValueChange={onPasswordChange}
      />
      <AppInput
        autoComplete="new-password"
        label="Подтверждение пароля"
        type={passwordConfirmationInputContext.passwordInputType}
        value={passwords.passwordConfirmation}
        maxLength={passwordMaxLength}
        error={validationResult.passwordConfirmation?.error}
        helperText={validationResult.passwordConfirmation?.messages?.[0]}
        iconEnd={passwordConfirmationInputContext.passwordIconComponent}
        onValueChange={onConfirmationPasswordChange}
      />
    </div>
  );
};

export const PasswordConfirmation = memo(PasswordConfirmationInner);
