import { axios } from '@/common/api/axios-config';
import { allFileOperationsUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { AxiosResponse } from 'axios';

export class FileOperationsRequests {
  static getAllFileOperations(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<FileOperation>>> {
    return axios.get(allFileOperationsUrl, { params });
  }
}
