import { AppTable } from '@/common/components/app-table/components/app-table/AppTable';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { SavedTableConfigActions } from '@/common/models/saved-table-config/saved-table-config-actions';
import { useDocumentCardBoxesTableEventHandlers } from '@/shared/document/hooks/document-card-boxes/use-document-card-boxes-table-event-handlers';
import { useDocumentCardBoxesTableSettings } from '@/shared/document/hooks/document-card-boxes/use-document-card-boxes-table-settings';
import { useDocumentCardTableData } from '@/shared/document/hooks/document-card/use-document-card-table-data';
import { Document } from '@/shared/document/models/document';
import { DocumentCardBoxesActions } from '@/shared/document/store/document-card/boxes';
import { DocumentCardBoxesSelectors, DocumentsCardBoxesApi } from '@/shared/document/store/document-card/boxes/types';
import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './document-card-boxes-table.scss';

export interface DocumentCardBoxesTableProps {
  documentInfo: Document;
  selectors: DocumentCardBoxesSelectors;
  api: DocumentsCardBoxesApi;
  rootActions: DocumentCardBoxesActions;
  tableConfigActions: SavedTableConfigActions;
  maxTableHeight: number;
}

const DocumentCardBoxesTableInner: FC<DocumentCardBoxesTableProps> = ({ documentInfo, selectors, api, rootActions, tableConfigActions, maxTableHeight }) => {
  const { asyncDispatch } = useAsyncDispatch();
  const { id: documentId } = useParams();
  const { abortRequestByParam } = useAsyncDispatchAborting();

  const boxes = useSelector(selectors.selectBoxes);
  const isLoading = useSelector(selectors.selectIsLoadingBoxes);
  const tableConfig = useSelector(selectors.selectTableConfig);

  const { processingWay, showDeleteRowButton } = useDocumentCardTableData({ documentInfo });
  const { columns, tableClasses } = useDocumentCardBoxesTableSettings(processingWay);

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onDeleteRow
  } = useDocumentCardBoxesTableEventHandlers({ api, rootActions, tableConfigActions });

  useEffect(() => {
    const dispatchResult = asyncDispatch(() => api.getAllBoxes(documentId));
    return () => { abortRequestByParam(dispatchResult); };
  }, [documentId]);

  return (
    <AppTable
      data={boxes}
      columns={columns}
      isLoading={isLoading}
      configState={tableConfig}
      classes={tableClasses}
      enableRowVirtualization
      showDeleteRowButton={showDeleteRowButton}
      maxHeight={`${maxTableHeight}px`}
      onDeleteRow={onDeleteRow}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
    />
  );
};

export const DocumentCardBoxesTable = memo(DocumentCardBoxesTableInner);