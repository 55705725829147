import { dateTimeFormat } from '@/common/constants/date-formats';
import { getShortFioFromParts } from '@/common/utils/common/common-user-data-utils';
import { formatDate } from '@/common/utils/common/date-utils';
import { SyncItem } from '@/modules/portal/models/sync/sync-item';
import { getSyncStatusLabel } from '@/modules/portal/models/sync/sync-status';
import React, { FC, memo, useMemo } from 'react';
import './sync-item-tooltip.scss';

interface SyncItemTooltipProps {
  syncItem: SyncItem;
}

const SyncItemTooltipInner: FC<SyncItemTooltipProps> = ({ syncItem }) => {

  const status = useMemo(() => getSyncStatusLabel(syncItem.state), [syncItem.state]);

  const userName = useMemo(() =>
    getShortFioFromParts({
      firstName: syncItem.userFirstName,
      secondName: syncItem.userSecondName,
      lastName: syncItem.userLastName,
    })
    , [syncItem.userFirstName, syncItem.userSecondName, syncItem.userLastName]);

  const endDate = useMemo(() =>
    formatDate(syncItem.endDate, dateTimeFormat)
    , [syncItem.endDate]);

  const nextPossibleAfter = useMemo(() =>
    formatDate(syncItem.nextPossibleAfter, dateTimeFormat)
    , [syncItem.nextPossibleAfter]);

  return (
    <div className="sync-item-tooltip">
      {status && <span>Статус последней синхронизации: {status}</span>}
      {endDate && <span>Последняя успешная синхронизация: {endDate}</span>}
      {userName && <span>Пользователь: {userName}</span>}
      {nextPossibleAfter && <span>Следующий запуск возможен после: {nextPossibleAfter}</span>}
    </div>
  );
};

export const SyncItemTooltip = memo(SyncItemTooltipInner);
