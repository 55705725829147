import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { greaterOrEquals, lessOrEquals } from '@/common/utils/client-validation/validation-rules';
import { DocumentsFilter, DocumentsFilterFields } from '@/shared/document/models/documents/documents-filter';
import { useEffect, useMemo } from 'react';

const sumFromErrorMessage = 'Значение должно быть меньше или равно, чем Сумма по';
const sumToErrorMessage = 'Значение должно быть больше или равно, чем Сумма от';

export function useDocumentsFiltersValidation(data: Partial<DocumentsFilter>) {
  const validationRules = useMemo(() => ({
    sumFrom: [lessOrEquals(data.sumTo, sumFromErrorMessage)],
    sumTo: [greaterOrEquals(data.sumFrom, sumToErrorMessage)],
  }), [data]);

  const validator = useFormValidation<DocumentsFilter, DocumentsFilterFields>({ validationRules, data });

  useEffect(() => {
    validator.validateField('sumTo');
  }, [data.sumFrom]);

  useEffect(() => {
    validator.validateField('sumFrom');
  }, [data.sumTo]);

  return {
    ...validator,
  };
}
