import { AppConfirmDialog } from '@/common/components/app-confirm-dialog/AppConfirmDialog';
import { useDialog } from '@/common/hooks/use-dialog';
import { SuccessfulImportNotificationForm } from '@/shared/files/components/successful-import-notification-form/SuccessfulImportNotificationForm';
import { ExtraFileType, FileType } from '@/shared/files/models/file-type';
import React, { useCallback } from 'react';

interface SuccessfulImportNotificationHookParams {
  fileType: FileType;
}

export function useSuccessfulImportNotification({ fileType }: SuccessfulImportNotificationHookParams) {
  const { isDialogOpened, openDialog, closeDialog } = useDialog();

  const getImportNotificationMessage = () => {
    if (fileType === ExtraFileType.NomenclatureDict) {
      return 'После завершения обработки файла данные справочника появятся в системе.';
    }

    return 'После завершения обработки документ появится в списке документов.';
  };

  const mainMessage = getImportNotificationMessage();
  const onImportExportClick = useCallback(() => { closeDialog(); }, []);

  const successfulImportNotificationDialog =
    <AppConfirmDialog
      open={isDialogOpened}
      title="Файл успешно загружен"
      message={<SuccessfulImportNotificationForm
        mainMessage={mainMessage}
        onImportExportClick={onImportExportClick}
      />}
      hideDeclineButton
      acceptButtonText="ОК"
      onClose={closeDialog}
    />;

  return {
    openImportNotificationDialog: openDialog,
    successfulImportNotificationDialog,
  };
}
