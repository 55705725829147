import { AppLink } from '@/common/components/app-link/AppLink';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { loginRoutePath } from '@/modules/auth/routes/routes';
import { logout } from '@/modules/portal/store/user-profile/async-thunks';
import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface UserProfileLogoutButtonProps {
  className?: string;
}
const UserProfileLogoutButtonInner: FC<UserProfileLogoutButtonProps> = ({ className }) => {
  const navigate = useNavigate();
  const { asyncDispatch } = useAsyncDispatch();

  const onLogout = useCallback(() => {
    asyncDispatch(logout, () => navigate(loginRoutePath));
  }, []);

  return (
    <AppLink
      text="Выйти из профиля"
      classes={{ container: className }}
      onClick={onLogout}
    />
  );
};

export const UserProfileLogoutButton = memo(UserProfileLogoutButtonInner);