import { Header } from '@/modules/portal/components/header/Header';
import { MenuPanel } from '@/modules/portal/components/menu-panel/MenuPanel';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import './main-layout.scss';

export const MainLayout: FC = () => (
  <div className="main-layout">
    <Header />
    <div className="main-layout-body">
      <MenuPanel />
      <div className="content">
        <Outlet />
      </div>
    </div>
  </div>
);
