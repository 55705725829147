import { AuthStateSelectors } from '@/modules/auth/store/auth-state/selectors';
import { getPermissionByRoute } from '@/modules/portal/constants/users-permissions-by-routes';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  accessBy: 'authenticated' | 'non-authenticated';
  redirectPath: string;
  children?: React.ReactNode;
}

/**
 * Component for protecting routes from navigation by it.
 * @param accessBy - way of protection (require authentication or require non authentication).
 * @param redirectPath - path for redirect in case of not allowed route.
 * @param children - component that is protected by route.
 */
const ProtectedRouteInner: FC<ProtectedRouteProps> = ({ accessBy, redirectPath, children }) => {
  const { pathname } = useLocation();
  const { hasAccessByPermission } = useUserPermissionsAccess();

  const isLoggedIn = useSelector(AuthStateSelectors.selectIsLoggedIn);

  const isAllowedRoute = useMemo(() =>
    accessBy === 'authenticated' && isLoggedIn || accessBy === 'non-authenticated' && !isLoggedIn, [isLoggedIn, accessBy]);

  const hasAllowedPermission = useMemo(() => {
    if (pathname === '/') {
      return true;
    }

    const accessPermission = getPermissionByRoute(pathname);
    if (accessPermission === undefined) {
      return false;
    }

    return hasAccessByPermission(accessPermission);
  }, [pathname]);

  if (isAllowedRoute) {
    if (accessBy === 'authenticated' && !hasAllowedPermission) {
      return <Navigate to={'/'} replace />;
    }

    return children ? <>{children}</> : <Outlet />;
  }

  return <Navigate to={redirectPath} replace />;
};

export const ProtectedRoute = memo(ProtectedRouteInner);
