import { AppInput } from '@/common/components/app-input/AppInput';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import { DocumentCardInfoContainer } from '@/shared/document/components/document-card-info-container/DocumentCardInfoContainer';
import { Document } from '@/shared/document/models/document';
import { convertDocumentInfoToCardModel } from '@/shared/document/utils/convert-document-info';
import React, { forwardRef } from 'react';
import './document-card-info.scss';

export interface DocumentCardInfoContentProps {
  info: Document;
}

export const DocumentCardInfo = forwardRef<HTMLDivElement, DocumentCardInfoContentProps>(
  ({ info }, ref) => {

    const documentInfo = convertDocumentInfoToCardModel(info);

    return (
      <DocumentCardInfoContainer
        className="document-card-info"
        ref={ref}
      >
        <AppInput
          label="Отправитель"
          value={documentInfo?.sender}
          className="sender"
          readOnly
        />
        <AppInput
          label="Номер документа"
          value={documentInfo?.documentNumber}
          className="document-number"
          readOnly
        />
        <AppInput
          label="Дата документа"
          value={documentInfo?.documentDate}
          className="document-date"
          readOnly
        />
        <AppInput
          label="Место отправления"
          value={documentInfo?.departurePoint}
          className="departure-point"
          readOnly
        />
        <AppInput
          label="Тип операции"
          value={documentInfo?.operationType}
          className="operation-type"
          readOnly
        />
        <AppInput
          label="Получатель"
          value={documentInfo?.receiver}
          className="receiver"
          readOnly
        />
        <AppInput
          label="Тип договора"
          value={documentInfo?.contractType}
          className="contract-type"
          readOnly
        />
        <AppInput
          label="Место приемки"
          value={documentInfo?.acceptancePlace}
          className="acceptance-place"
          readOnly
        />
        <AppInput
          label="Источник финансирования"
          value={documentInfo?.financingSource}
          className="financing-source"
          readOnly
        />
        <AppInput
          label="Дата операции"
          value={documentInfo?.operationDate}
          className="operaton-date"
          readOnly
        />
        <AppInput
          label="Статус"
          value={documentInfo?.status}
          className="status"
          readOnly
        />
        <AppInput
          label="Сумма (с НДС), руб"
          value={documentInfo?.amountWithVat}
          className="amount-with-vat"
          readOnly
        />
        <AppInput
          label="Сумма НДС, руб"
          value={documentInfo?.vatAmount}
          className="vat-amount"
          readOnly
        />
        <AppInput
          label="Комментарий"
          value={documentInfo?.comment}
          type={AppInputType.Textarea}
          className="comment"
          readOnly
        />
      </DocumentCardInfoContainer>
    );
  });

DocumentCardInfo.displayName = 'DocumentCardInfo';
