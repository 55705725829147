import { AppTable } from '@/common/components/app-table/components/app-table/AppTable';
import { AppTableMaxHeightForTableInDialog } from '@/common/constants/app-table-constants';
import { useInteractionLogNotificationHistoryTableEventHandlers } from '@/modules/interaction-log/hooks/interaction-log-notification-history/use-interaction-log-notification-history-table-event-handlers';
import { useInteractionLogNotificationHistoryTableSettings } from '@/modules/interaction-log/hooks/interaction-log-notification-history/use-interaction-log-notification-history-table-settings';
import { InteractionLogNotificationHistorySelectors } from '@/modules/interaction-log/store/interaction-log-notification-history/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const InteractionLogNotificationHistoryTable = () => {

  const notificationHistory = useSelector(InteractionLogNotificationHistorySelectors.selectNotificationHistory);
  const isLoading = useSelector(InteractionLogNotificationHistorySelectors.selectIsLoadingNotificationHistory);
  const tableConfig = useSelector(InteractionLogNotificationHistorySelectors.selectTableConfig);

  const { columns } = useInteractionLogNotificationHistoryTableSettings();

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
  } = useInteractionLogNotificationHistoryTableEventHandlers();

  return (
    <AppTable
      data={notificationHistory}
      columns={columns}
      isLoading={isLoading}
      configState={tableConfig}
      maxHeight={`${AppTableMaxHeightForTableInDialog}px`}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
    />
  );
};