import React, { FC, memo } from 'react';
import './delete-button-tooltip.scss';

interface DeleteButtonTooltipProps {
  onDeny: () => void;
  onAccess: () => void;
}

const DeleteButtonTooltipInner: FC<DeleteButtonTooltipProps> = ({ onDeny, onAccess }) => (
  <div className="delete-button-tooltip">
    <div className="delete-button-tooltip-label">Вы уверены?</div>
    <span
      className="delete-button-tooltip-deny-button"
      onClick={onDeny}
    >Нет</span>
    <span
      className="delete-button-tooltip-accept-button"
      onClick={onAccess}
    >Да</span>
  </div>
);

export const DeleteButtonTooltip = memo(DeleteButtonTooltipInner);