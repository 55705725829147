import { OrganizationPlaceShortDto } from '@/common/models/common-data-sources/organization-places/organization-place-short-dto';
import { CommonDataSourcesRequests } from '@/common/requests/common-data-sources-requests';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const commonDataSourceModuleName = 'commonDataSource';

export const getOrganizationPlacesDataSource = createAsyncThunkWrapper<
  OrganizationPlaceShortDto[],
  undefined
>(
  `${commonDataSourceModuleName}/getShortOrganizationPlaces`,
  () => CommonDataSourcesRequests.getOrganizationPlaces()
    .then((res) => res.data.slimActivityPlaces),
);
