/**
 * Converter type from model field to its corresponding column field.
 */
type ModelToColumnConverter<ColumnType, Model> = (field: keyof Model) => ColumnType;

/**
 * Returns function that extracts value from table model by dto-field.
 * @param converter - converter from model field to column field.
 * @param model - model from AppTable.
 */
export const buildModelValueGetter =
  <ColumnType, Model>(converter: ModelToColumnConverter<ColumnType, Model>, model: Record<ColumnType, unknown>) =>
    (field: keyof Model) => model[converter(field)];
