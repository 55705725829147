import { AppSelect } from '@/common/components/app-select/AppSelect';
import { DocumentCardScanningFilter } from '@/shared/document/models/document-card/scanning/document-card-scanning-filter';
import { getProductAdditionalInfoStatusOptions, ProductAdditionalInfoStatus } from '@/shared/scanning-view/models/product-additional-info-status';
import React, { FC, memo, useCallback, useMemo } from 'react';
import './scanning-filters.scss';

interface ScanningFiltersProps {
  filter: DocumentCardScanningFilter;
  onFilterChanged: (value: Partial<DocumentCardScanningFilter>) => void;
}

const ScanningFiltersInner: FC<ScanningFiltersProps> = ({ filter, onFilterChanged }) => {
  const onStatusChanged = useCallback((value: ProductAdditionalInfoStatus) => { onFilterChanged({ productAdditionalInfoStatus: value }); }, []);

  const productAdditionalInfoStatusOptions = useMemo(getProductAdditionalInfoStatusOptions, []);

  return (
    <div className="scanning-filters">
      <AppSelect
        label="Статус"
        value={filter.productAdditionalInfoStatus}
        showClearButton
        options={productAdditionalInfoStatusOptions}
        onChange={onStatusChanged}
      />
    </div>
  );
};

export const ScanningFilters = memo(ScanningFiltersInner);
