import { FileOperationsTable } from '@/modules/file-operations/components/file-operations-table/FileOperationsTable';
import { resetState } from '@/modules/file-operations/store';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const FileOperationsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <FileOperationsTable />;
};
