export enum DocumentOperationType {
  Sell = 'SELL',
  Return = 'RETURN',
  Receipt = 'RECEIPT',
  ReturnFromSeller = 'RETURN_FROM_SELLER',
  Resort = 'RESORT',
  WithdrawalType6 = 'WITHDRAWAL_TYPE_6',
  WithdrawalType7 = 'WITHDRAWAL_TYPE_7',
  WithdrawalType8 = 'WITHDRAWAL_TYPE_8',
  WithdrawalType9 = 'WITHDRAWAL_TYPE_9',
  WithdrawalType10 = 'WITHDRAWAL_TYPE_10',
  WithdrawalType11 = 'WITHDRAWAL_TYPE_11',
  WithdrawalType12 = 'WITHDRAWAL_TYPE_12',
  WithdrawalType13 = 'WITHDRAWAL_TYPE_13',
  WithdrawalType14 = 'WITHDRAWAL_TYPE_14',
  WithdrawalType15 = 'WITHDRAWAL_TYPE_15',
  WithdrawalType16 = 'WITHDRAWAL_TYPE_16',
  WithdrawalType17 = 'WITHDRAWAL_TYPE_17',
  WithdrawalType18 = 'WITHDRAWAL_TYPE_18',
  WithdrawalType19 = 'WITHDRAWAL_TYPE_19',
  WithdrawalType20 = 'WITHDRAWAL_TYPE_20',
  WithdrawalType21 = 'WITHDRAWAL_TYPE_21',
  WithdrawalType22 = 'WITHDRAWAL_TYPE_22',
  WithdrawalType23 = 'WITHDRAWAL_TYPE_23',
  WithdrawalType24 = 'WITHDRAWAL_TYPE_24',
  WithdrawalType26 = 'WITHDRAWAL_TYPE_26',
}

const documentOperationTypeLabels = {
  [DocumentOperationType.Sell]: 'Продажа',
  [DocumentOperationType.Return]: 'Возврат',
  [DocumentOperationType.Receipt]: 'Поступление',
  [DocumentOperationType.ReturnFromSeller]: 'Возврат от покупателя',
  [DocumentOperationType.Resort]: 'Пересорт',
  [DocumentOperationType.WithdrawalType6]: 'Выборочный контроль',
  [DocumentOperationType.WithdrawalType7]: 'Таможенный контроль',
  [DocumentOperationType.WithdrawalType8]: 'Федеральный надзор',
  [DocumentOperationType.WithdrawalType9]: 'В целях клинических исследований',
  [DocumentOperationType.WithdrawalType10]: 'В целях фармацевтической экспертизы',
  [DocumentOperationType.WithdrawalType11]: 'Недостача',
  [DocumentOperationType.WithdrawalType12]: 'Отбор демонстрационных образцов',
  [DocumentOperationType.WithdrawalType13]: 'Списание без передачи на уничтожение',
  [DocumentOperationType.WithdrawalType14]: 'Вывод из оборота КИЗ, накопленных в рамках эксперимента',
  [DocumentOperationType.WithdrawalType15]: 'Производственный брак',
  [DocumentOperationType.WithdrawalType16]: 'Списание разукомплектованной потребительской упаковки',
  [DocumentOperationType.WithdrawalType17]: 'Производство медицинских изделий',
  [DocumentOperationType.WithdrawalType18]: 'Производство медицинских препаратов',
  [DocumentOperationType.WithdrawalType19]: 'Отбор контрольных образцов в рамках процесса контроля качества',
  [DocumentOperationType.WithdrawalType20]: 'Отбор архивных образцов',
  [DocumentOperationType.WithdrawalType21]: 'Хищение',
  [DocumentOperationType.WithdrawalType22]: 'Автоматическое списание по истечению срока годности',
  [DocumentOperationType.WithdrawalType23]: 'Списание лекарственных препаратов при отсутствии информации согласно БП',
  [DocumentOperationType.WithdrawalType24]: 'Экспорт вне РФ',
  [DocumentOperationType.WithdrawalType26]: 'Предоставление гуманитарной помощи',
};

export const getDocumentOperationTypeLabel = (operationType: DocumentOperationType) => documentOperationTypeLabels[operationType];