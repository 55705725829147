import { CommonUserData } from '@/common/models/common-user-data';

type GetFioParams = Pick<CommonUserData, 'firstName' | 'lastName' | 'secondName'>;

export const getFio = (userName: GetFioParams) =>
  `${userName.lastName} ${userName.firstName} ${userName.secondName}`;

export const getShortFioFromParts = ({ firstName, secondName, lastName }: GetFioParams) => {
  if (!firstName || !lastName) {
    return firstName ?? lastName ?? '';
  }
  const getInitial = (name: string) => name ? `${name?.[0]?.toUpperCase()}.` : '';

  return `${lastName} ${getInitial(firstName)} ${getInitial(secondName)}`;
};

export const getShortFioFromFullName = (fullName: string) => {
  if (!fullName) {
    return '';
  }
  const [lastName, firstName, secondName] = fullName.split(' ');
  return getShortFioFromParts({ firstName, secondName, lastName });
};
