import { AppDatePicker } from '@/common/components/app-date-picker/AppDatePicker';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppMultiSelect } from '@/common/components/app-multi-select/AppMultiSelect';
import { AppNumberInput } from '@/common/components/app-number-input/AppNumberInput';
import { Labels } from '@/common/constants/labels';
import { useOrganizationPlacesDataSource } from '@/common/hooks/common-data-sources/use-organization-places-data-source';
import { FilterConfigActions } from '@/common/models/filter-config/filter-config-actions';
import {
  gtinMaxLength,
  productProducerMaxLength,
  seriesMaxLength,
  ssccMaxLength
} from '@/common/utils/client-validation/string-lengths';
import { getEndOfDay } from '@/common/utils/common/date-utils';
import { RemainsFilter, RemainsFilterFields } from '@/shared/remains/models/remains-filter';
import { RemainsSelectors } from '@/shared/remains/store/types';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './remains-filters.scss';

export interface RemainsFilterProps {
  selectors: RemainsSelectors;
  filterConfigActions: FilterConfigActions<RemainsFilter>;
}

const RemainsFiltersInner: FC<RemainsFilterProps> = ({ selectors, filterConfigActions }) => {
  const dispatch = useAppDispatch();

  const filter = useSelector(selectors.selectFilters);

  const { organizationPlacesOptions, loadOrganizationPlacesOptions } = useOrganizationPlacesDataSource();

  useEffect(() => {
    loadOrganizationPlacesOptions();
  }, []);

  const updateFilterField = (fieldName: RemainsFilterFields) =>
    (value: RemainsFilter[RemainsFilterFields]) =>
      dispatch(filterConfigActions.updateFilter({ [fieldName]: value }));

  const updateActivityPlaces = useCallback(updateFilterField('activityPlaces'), []);
  const updateProducer = useCallback(updateFilterField('producer'), []);
  const updateSeriesNumber = useCallback(updateFilterField('seriesNumber'), []);
  const updateGtin = useCallback(updateFilterField('gtin'), []);
  const updateSscc = useCallback(updateFilterField('sscc'), []);
  const updateExpirationDateFrom = useCallback(updateFilterField('expirationDateFrom'), []);
  const updateExpirationDateTo = useCallback((value: RemainsFilter['expirationDateTo']) =>
    dispatch(filterConfigActions.updateFilter({ expirationDateTo: getEndOfDay(value) }))
    , []);

  return (
    <div className="remains-filters">
      <AppDatePicker
        label={`${Labels.expirationDate} ${Labels.dateFrom}`}
        value={filter.expirationDateFrom}
        maxDate={filter.expirationDateTo}
        onChange={updateExpirationDateFrom}
      />
      <AppNumberInput
        value={filter.gtin}
        label={Labels.gtin}
        maxLength={gtinMaxLength}
        showClearButton
        onInput={updateGtin}
      />
      <AppNumberInput
        value={filter.sscc}
        label={Labels.sscc}
        maxLength={ssccMaxLength}
        showClearButton
        onInput={updateSscc}
      />
      <AppDatePicker
        label={`${Labels.expirationDate} ${Labels.dateTo}`}
        value={filter.expirationDateTo}
        minDate={filter.expirationDateFrom}
        onChange={updateExpirationDateTo}
      />
      <AppInput
        value={filter.seriesNumber}
        label={Labels.series}
        maxLength={seriesMaxLength}
        showClearButton
        onInput={updateSeriesNumber}
      />
      <AppInput
        value={filter.producer}
        label={Labels.producer}
        maxLength={productProducerMaxLength}
        showClearButton
        onInput={updateProducer}
      />
      <AppMultiSelect
        className="activity-places-filter"
        label={Labels.activityPlacesFull}
        value={filter.activityPlaces}
        options={organizationPlacesOptions}
        onChange={updateActivityPlaces}
      />
    </div>
  );
};

export const RemainsFilters = memo(RemainsFiltersInner);
