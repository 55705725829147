import { DialogType, HasDialogConfig } from '@/common/models/app-dialog/app-dialog-config';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { setUserUuidToSessionStorage } from '@/modules/portal/store/user-profile';
import { defaultUsersManagementTableConfig } from '@/modules/users-management/constants/users-management-table-config';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { UserInfoFormData } from '@/modules/users-management/models/user-info/user-info-form-data';
import { editUser, getAllUsers, saveNewUser } from '@/modules/users-management/store/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UsersManagementState extends HasDialogConfig {
  editUser: Partial<UserInfoFormData>;
  isLoadingEditUser: boolean;
  indexEditUser: number;
  isOpenEditUserDialog: boolean;
  showBlockedUsers: boolean;
  search: SearchingState;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<UserInfo>;
}

const notClearedFields: Set<keyof UsersManagementState> = new Set(['tableConfig']);

const usersManagementSliceName = 'usersManagement';

const usersManagementTableConfigState: SavedTableConfigState = {
  tableConfigName: 'users-management-table',
  config: defaultUsersManagementTableConfig,
};

const usersManagementSearchingSlice = createSearchingSlice(usersManagementSliceName);
const usersManagementTableConfigSlice = createSavedTableConfigSlice(usersManagementSliceName, usersManagementTableConfigState);
const usersManagementPageableDataSlice = createPageableDataSlice<UserInfo>(usersManagementSliceName);

const initialState: UsersManagementState = {
  editUser: {},
  isLoadingEditUser: false,
  indexEditUser: undefined,
  isOpenEditUserDialog: false,
  showBlockedUsers: false,
  dialogConfig: { type: DialogType.Adding },
  search: usersManagementSearchingSlice.getInitialState(),
  tableConfig: usersManagementTableConfigSlice.getInitialState(),
  pageableData: usersManagementPageableDataSlice.getInitialState()
};

const usersManagementSlice = createSlice({
  name: usersManagementSliceName,
  initialState,
  reducers: {
    /** Table reducers. */
    updateShowBlockedUsersValue: (state: UsersManagementState, action: PayloadAction<boolean>) => {
      state.showBlockedUsers = action.payload;
    },
    /** Dialog reducers. */
    updateEditUser: (state: UsersManagementState, action: PayloadAction<Partial<UserInfoFormData>>) => {
      state.editUser = {
        ...state.editUser,
        ...action.payload
      };
    },
    resetEditUserStates: (state: UsersManagementState) => {
      state.editUser = {};
      state.indexEditUser = undefined;
    },
    updateOpenEditUserDialog: (state: UsersManagementState, action: PayloadAction<boolean>) => {
      state.isOpenEditUserDialog = action.payload;
    },
    updateIndexEditUser: (state: UsersManagementState, action: PayloadAction<number>) => {
      state.indexEditUser = action.payload;
    },
    updateEditUserDialogTypeConfig: (state: UsersManagementState, action: PayloadAction<DialogType>) => {
      state.dialogConfig = {
        ...state.dialogConfig,
        type: action.payload,
      };
    },
    /** Common page reducers. */
    resetState: (state: UsersManagementState) => {
      Object.keys(state)
        .forEach((key: keyof UsersManagementState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        const { updateLoadingStatus } = usersManagementPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllUsers.rejected, (state) => {
        const { updateLoadingStatus } = usersManagementPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addCase(getAllUsers.fulfilled, (state) => {
        const { updateLoadingStatus } = usersManagementPageableDataSlice.caseReducers;
        const { saveAppliedSearchString } = usersManagementSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        saveAppliedSearchString(state.search);
      })
      .addCase(saveNewUser.pending, (state) => { state.isLoadingEditUser = true; })
      .addCase(saveNewUser.fulfilled, (state) => { state.isLoadingEditUser = false; })
      .addCase(saveNewUser.rejected, (state) => { state.isLoadingEditUser = false; })
      .addCase(editUser.pending, (state) => { state.isLoadingEditUser = true; })
      .addCase(editUser.fulfilled, (state) => { state.isLoadingEditUser = false; })
      .addCase(editUser.rejected, (state) => { state.isLoadingEditUser = false; })
      .addCase(setUserUuidToSessionStorage, (state) => {
        usersManagementTableConfigSlice.caseReducers.setTableConfig(state.tableConfig);
      })
      .addMatcher(
        (action) => action.type.startsWith(usersManagementTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = usersManagementTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(usersManagementSearchingSlice.name),
        (state, action) => {
          state.search = usersManagementSearchingSlice.reducer(state.search, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(usersManagementPageableDataSlice.name),
        (state, action) => {
          state.pageableData = usersManagementPageableDataSlice.reducer(state.pageableData, action);
        }
      );
  }
});

export const {
  updateShowBlockedUsersValue,
  updateEditUser,
  resetEditUserStates,
  updateOpenEditUserDialog,
  updateIndexEditUser,
  updateEditUserDialogTypeConfig,
  resetState,
} = usersManagementSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = usersManagementTableConfigSlice.actions;

export const {
  updateSearchString,
} = usersManagementSearchingSlice.actions;

export const {
  patchItemByIndex: patchUser,
} = usersManagementPageableDataSlice.actions;

export const usersManagementPageableDataActions = usersManagementPageableDataSlice.actions;

export default usersManagementSlice.reducer;
