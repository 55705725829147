import { getDocumentCardModuleNames } from '@/shared/document/utils/document-card-modules-names';

export const saleModuleName = 'sale';

export const saleCardModuleName = 'saleCard';

export const {
  generalPositionsModuleName: saleCardGeneralPositionsModuleName,
  boxesModuleName: saleCardBoxesModuleName,
  positionsModuleName: saleCardPositionsModuleName,
  historyModuleName: saleCardHistoryModuleName,
  notesModuleName: saleCardNotesModuleName,
  scanningModuleName: saleCardScanningModuleName,
} = getDocumentCardModuleNames(saleCardModuleName);
