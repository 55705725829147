import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { interactionLogPageableDataActions } from '@/modules/interaction-log/store/interaction-log';
import { getAllInteractionLogs } from '@/modules/interaction-log/store/interaction-log/async-thunks';
import { InteractionLogSelectors } from '@/modules/interaction-log/store/interaction-log/selectors';
import { useSelector } from 'react-redux';

export function useInteractionLogTableDataSource() {
  const sorting = useSelector(InteractionLogSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: interactionLogPageableDataActions,
    selectPageableDataState: InteractionLogSelectors.selectPageableDataState,
    getData: getAllInteractionLogs,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
