import { disposalCardBoxesModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardBoxesApi } from '@/modules/disposals/store/disposal-card/boxes/async-thunks';
import { createDocumentCardBoxesSlice } from '@/shared/document/store/document-card/boxes';

export const disposalCardBoxesSlice = createDocumentCardBoxesSlice({
  moduleName: disposalCardBoxesModuleName,
  savedTableConfigName: 'disposal-card-boxes-table',
  api: disposalCardBoxesApi,
});

export const disposalCardBoxesRootActions = disposalCardBoxesSlice.rootSlice.actions;
export const disposalCardBoxesTableConfigActions = disposalCardBoxesSlice.documentCardBoxesTableConfigSlice.actions;

export default disposalCardBoxesSlice.rootSlice.reducer;
