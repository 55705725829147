import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import {
  updateSearchString,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
  updateTableColumnVisibilityState,
  updateTableSortingState
} from '@/modules/references/store/nomenclature';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type NomenclatureTableEventHandlersHookParams = PageableLoadingActions;

export function useNomenclatureTableEventHandlers({ loadFirstPage, loadNextPage }: NomenclatureTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onScrollChange = useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  const onRefreshClick = useCallback(() => {
    loadFirstPage();
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRefreshClick
  };
}
