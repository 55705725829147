import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { getHeaderInfo } from '@/modules/portal/store/header/async-thunks';
import { useEffect, useRef, useState } from 'react';

interface IntervalGettingHeaderInfo {
  isTimerExpired: boolean;
}

export const useIntervalGettingHeaderInfo = ({ isTimerExpired }: IntervalGettingHeaderInfo) => {
  const { asyncDispatch } = useAsyncDispatch();

  const [isTabActive, setIsTabActive] = useState(true);
  const intervalId = useRef<NodeJS.Timer>();
  const isAbleToStartInterval = !isTimerExpired && isTabActive;

  // При запуске синхронизации отправляется запрос в МДЛП на продление сессии и на фронте запускается таймер (useTimer()).
  // Позже МДЛП может прекратить сессию и в БД сессии не будет, но на фронте таймер будет продолжать работать, будто сессия все еще запущена.
  // Чтобы не возникало ошибок при синхронизации данных (т.к. на беке сессии не будет, а для юзера таймер идет, будто сессия активна),
  // каждые 30 секунд будет отправляться запрос на получение актуального значения запроса header/info,
  // чтобы если сессия на беке остановилась, таймер на фронте тоже сбросился
  const gettingHeaderInfoTimeout = 30000;

  const clearGetHeaderInfoInterval = () => {
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  };

  const startGetHeaderInfoInterval = () => {
    if (!intervalId.current) {
      intervalId.current = setInterval(() => asyncDispatch(getHeaderInfo), gettingHeaderInfoTimeout);
    }
  };

  const onTabVisibilityChange = () => setIsTabActive(!document.hidden);

  useEffect(() => {
    if (isAbleToStartInterval && !intervalId.current) {
      startGetHeaderInfoInterval();
    }
    if (isTimerExpired || !isTabActive) {
      clearGetHeaderInfoInterval();
    }
  }, [isTimerExpired, isTabActive, intervalId.current]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onTabVisibilityChange);

    if (isAbleToStartInterval) {
      startGetHeaderInfoInterval();
    }

    return () => {
      clearGetHeaderInfoInterval();
      document.removeEventListener('visibilitychange', onTabVisibilityChange);
    };
  }, []);

  return {
    startGetHeaderInfoInterval
  };
};
