import { UsersManagementTable } from '@/modules/users-management/components/users-management-table/UsersManagementTable';
import { resetState } from '@/modules/users-management/store';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';
import './users-management-page.scss';

export const UsersManagementPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <UsersManagementTable />;
};
