import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { defaultProcessesTableConfig } from '@/modules/references/constants/processes-table-config';
import { processesModuleName } from '@/modules/references/constants/references-module-names';
import { Process } from '@/modules/references/models/processes/process';
import { getAllProcesses } from '@/modules/references/store/processes/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProcessesState {
  processes: Process[];
  isLoadingProcesses: boolean;
  search: SearchingState;
  filteredProcesses: Process[];
  tableConfig: SavedTableConfigState;
}

const notClearedFields: Set<keyof ProcessesState> = new Set(['tableConfig']);

const processesTableConfigState: SavedTableConfigState = {
  tableConfigName: 'processes-table',
  config: defaultProcessesTableConfig,
};

const processesSearchingSlice = createSearchingSlice(processesModuleName);
const processesTableConfigSlice = createSavedTableConfigSlice(processesModuleName, processesTableConfigState);

const initialState: ProcessesState = {
  processes: [],
  isLoadingProcesses: false,
  filteredProcesses: [],
  search: processesSearchingSlice.getInitialState(),
  tableConfig: processesTableConfigSlice.getInitialState(),
};

const getFilteredProcesses = (searchString: string, processes: Process[]) => {
  if (searchString.length === 0) {
    return processes;
  }
  const search = searchString.toLowerCase();

  return processes.filter((process) =>
    process.schemaName?.toLowerCase()?.includes(search)
    || process.description?.toLowerCase()?.includes(search)
    || process.schemaType?.toLowerCase()?.includes(search)
  );
};

const processesSlice = createSlice({
  name: processesModuleName,
  initialState,
  reducers: {
    filterProcesses(state: ProcessesState) {
      state.filteredProcesses = getFilteredProcesses(state.search.currentSearchString, state.processes);

      const { saveAppliedSearchString } = processesSearchingSlice.caseReducers;
      saveAppliedSearchString(state.search);
    },
    resetState(state: ProcessesState) {
      Object.keys(state)
        .forEach((key: keyof ProcessesState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProcesses.pending, (state: ProcessesState) => {
        state.isLoadingProcesses = true;
      })
      .addCase(getAllProcesses.fulfilled, (state: ProcessesState, action: PayloadAction<Process[]>) => {
        state.processes = action.payload;
        state.filteredProcesses = action.payload;
        state.isLoadingProcesses = false;
      })
      .addCase(getAllProcesses.rejected, (state: ProcessesState) => {
        state.isLoadingProcesses = false;
      })
      .addMatcher(
        (action) => action.type.startsWith(processesTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = processesTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(processesSearchingSlice.name),
        (state, action) => {
          state.search = processesSearchingSlice.reducer(state.search, action);
        }
      );
  }
});

export const {
  filterProcesses,
  resetState,
} = processesSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = processesTableConfigSlice.actions;

export const {
  updateSearchString,
} = processesSearchingSlice.actions;

export default processesSlice.reducer;
