import { saleCardScanningModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardScanningApi } from '@/modules/sale/store/sale-card/scanning/async-thunks';
import { createDocumentCardScanningSlice } from '@/shared/document/store/document-card/scanning';

export const saleCardScanningSlice = createDocumentCardScanningSlice({
  moduleName: saleCardScanningModuleName,
  savedTableConfigName: 'sale-card-scanning-table',
  api: saleCardScanningApi,
});

export const saleCardScanningRootActions = saleCardScanningSlice.rootSlice.actions;
export const saleCardScanningPageableDataActions = saleCardScanningSlice.documentCardScanningPageableDataSlice.actions;
export const saleCardScanningSearchingActions = saleCardScanningSlice.documentCardScanningSearchingSlice.actions;
export const saleCardScanningTableConfigActions = saleCardScanningSlice.documentCardScanningTableConfigSlice.actions;
export const saleCardScanningFilterConfigSlice = saleCardScanningSlice.documentCardScanningFilterConfigSlice.actions;

export default saleCardScanningSlice.rootSlice.reducer;
