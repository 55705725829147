import { axios } from '@/common/api/axios-config';
import { fileDownloadUrl, fileExportUrl, fileUploadUrl } from '@/common/api/urls';
import { AllFilesExportBody, FileExportBody } from '@/shared/files/models/file-export-body';
import { FileUploadingParams } from '@/shared/files/models/file-uploading-params';
import { AxiosResponse } from 'axios';

export class FileRequests {
  static downloadFile(fileUuid: string): Promise<AxiosResponse<string>> {
    return axios.get(fileDownloadUrl(fileUuid));
  }

  static uploadFile({ fileType, file }: FileUploadingParams): Promise<AxiosResponse<void>> {
    const formData = new FormData();
    formData.append('uploadFile', file);

    const params = { fileType };
    const headers = { 'Content-Type': 'multipart/form-data' };

    return axios.post(fileUploadUrl, formData, { params, headers });
  }

  static exportFile(body: FileExportBody | AllFilesExportBody): Promise<AxiosResponse<void>> {
    return axios.post(fileExportUrl, body);
  }
}
