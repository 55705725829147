import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import React, { FC, ReactNode, memo } from 'react';
import './header-with-loader.scss';

interface HeaderWithLoaderProps {
  title: string;
  showLoader: boolean;
  tooltip?: ReactNode;
  align?: 'center' | 'start';
  classes?: { title?: string; };
}

const HeaderWithLoaderInner: FC<HeaderWithLoaderProps> = ({ title, showLoader, tooltip, align = 'center', classes }) => (
  <div className={`header-container align-${align}`}>
    <div className="title-container">
      <h1 className={classes?.title}>{title}</h1>
      {tooltip && <HintIcon text={tooltip} />}
      {showLoader && <AppLoader
        className="header-loader"
        size="small"
      />}
    </div>
  </div>
);

export const HeaderWithLoader = memo(HeaderWithLoaderInner);
