import { DialogCommonState } from '@/common/hooks/use-dialog';
import { LoadingStatus } from '@/common/models/loading-status';
import { getDocumentCardStorageKeys } from '@/shared/document/constants/document-card-storage-keys';
import { DocumentCardUpdatingStatusBlockedInfo } from '@/shared/document/models/document-card-updating-status-blocked-info';
import { DocumentCardSendType } from '@/shared/document/models/document-card/document-card-send-type';
import { DocumentCardTab } from '@/shared/document/models/document-card/document-card-tab';
import { DocumentStatus } from '@/shared/document/models/document-status';
import { DocumentType } from '@/shared/document/models/document-type';
import { DocumentDto } from '@/shared/document/models/dto/document-dto';
import { DocumentCardStorageKeys, DocumentsCardApi } from '@/shared/document/store/document-card/card/types';
import { CaseReducerActions, PayloadAction, SliceCaseReducers, createSlice } from '@reduxjs/toolkit';


export interface ProcessedDialogState extends DialogCommonState {
  sendType: DocumentCardSendType;
  rejectReason: string;
}

interface BlockedInfoState extends DialogCommonState {
  info: DocumentCardUpdatingStatusBlockedInfo;
  status: DocumentStatus;
}

interface WarnInfoState extends DialogCommonState {
  notScannedMedicineAmount: number;
}

export interface DocumentCardState {
  documentId: string;
  documentInfo: DocumentDto;
  docType: DocumentType;
  loadingStatusDocumentInfo: LoadingStatus;
  openDocumentInfo: boolean;
  activeTab: DocumentCardTab;
  blockedInfo: BlockedInfoState;
  warnInfo: WarnInfoState;
  processedDialog: ProcessedDialogState;
  isUpdatingStatusInProgress: boolean;
  tabContentHeight: number;
}

const defaultActiveTab: DocumentCardTab = DocumentCardTab.Boxes;
// REM-837: hide unbox remains
// const defaultActiveTab: DocumentCardTab = DocumentCardTab.All;
const notClearedFields: Set<keyof DocumentCardState> = new Set(['activeTab', 'documentId']);

const getInitialState = (documentCardStorageKeys: DocumentCardStorageKeys, docType: DocumentType): DocumentCardState => ({
  activeTab: sessionStorage.getItem(documentCardStorageKeys.LAST_ACTIVE_TAB) as DocumentCardTab ?? defaultActiveTab,
  documentId: '',
  docType,
  openDocumentInfo: false,
  documentInfo: undefined,
  loadingStatusDocumentInfo: undefined,
  isUpdatingStatusInProgress: false,
  tabContentHeight: undefined,
  blockedInfo: {
    isDialogOpened: false,
    info: undefined,
    status: undefined,
  },
  warnInfo: {
    isDialogOpened: false,
    notScannedMedicineAmount: undefined,
  },
  processedDialog: {
    sendType: undefined,
    isDialogOpened: false,
    rejectReason: ''
  }
});

export interface DocumentCardCaseReducers extends SliceCaseReducers<DocumentCardState> {
  setActiveTab: (state: DocumentCardState, action: PayloadAction<DocumentCardTab>) => void;
  setDocumentId: (state: DocumentCardState, action: PayloadAction<string>) => void;
  setIsFavorite: (state: DocumentCardState, action: PayloadAction<boolean>) => void;
  toggleOpenDocumentInfo: (state: DocumentCardState) => void;
  updateBlockedInfo: (state: DocumentCardState, action: PayloadAction<Partial<BlockedInfoState>>) => void;
  updateWarnInfo: (state: DocumentCardState, action: PayloadAction<Partial<WarnInfoState>>) => void;
  updateProcessedDialog: (state: DocumentCardState, action: PayloadAction<Partial<ProcessedDialogState>>) => void;
  updateDocumentStatus: (state: DocumentCardState, action: PayloadAction<DocumentStatus>) => void;
  setTabContentHeight: (state: DocumentCardState, action: PayloadAction<number>) => void;
  resetActiveTab: (state: DocumentCardState) => void;
  resetState: (state: DocumentCardState) => void;
}

export type DocumentCardActions = CaseReducerActions<DocumentCardCaseReducers, string>;

interface CreateDocumentCardSliceParams {
  moduleName: string,
  api: DocumentsCardApi;
  docType: DocumentType;
}
// TODO: fix type
export const createDocumentCardSlice = ({ moduleName, api, docType }: CreateDocumentCardSliceParams) => {
  const documentCardStorageKeys = getDocumentCardStorageKeys(moduleName);
  const initialState = getInitialState(documentCardStorageKeys, docType);

  const documentCardSlice = createSlice<DocumentCardState, DocumentCardCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      setActiveTab(state, action: PayloadAction<DocumentCardTab>) {
        state.activeTab = action.payload;
        sessionStorage.setItem(documentCardStorageKeys.LAST_ACTIVE_TAB, action.payload);
      },
      setDocumentId(state, action: PayloadAction<string>) {
        state.documentId = action.payload;
      },
      setIsFavorite(state, action: PayloadAction<boolean>) {
        state.documentInfo.isFavorite = action.payload;
      },
      toggleOpenDocumentInfo(state) {
        state.openDocumentInfo = !state.openDocumentInfo;
      },
      updateBlockedInfo(state, action: PayloadAction<Partial<BlockedInfoState>>) {
        state.blockedInfo = { ...state.blockedInfo, ...action.payload };
      },
      updateWarnInfo(state, action: PayloadAction<Partial<WarnInfoState>>) {
        state.warnInfo = { ...state.warnInfo, ...action.payload };
      },
      updateProcessedDialog(state, action: PayloadAction<Partial<ProcessedDialogState>>) {
        state.processedDialog = { ...state.processedDialog, ...action.payload };
      },
      updateDocumentStatus(state, action: PayloadAction<DocumentStatus>) {
        state.documentInfo.docState = action.payload;
      },
      setTabContentHeight(state, action: PayloadAction<number>) {
        state.tabContentHeight = action.payload;
      },
      resetActiveTab(state) {
        sessionStorage.removeItem(documentCardStorageKeys.LAST_ACTIVE_TAB);
        state.activeTab = defaultActiveTab;
      },
      resetState: (state: DocumentCardState) => {
        documentCardSlice.caseReducers.resetActiveTab(state);
        Object.keys(state)
          .forEach((key: keyof DocumentCardState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getDocumentInfo.pending, (state) => {
          state.loadingStatusDocumentInfo = LoadingStatus.Pending;
        })
        .addCase(api.getDocumentInfo.fulfilled, (state, action) => {
          state.loadingStatusDocumentInfo = LoadingStatus.Fulfilled;
          state.documentInfo = action.payload;
        })
        .addCase(api.getDocumentInfo.rejected, (state) => {
          state.loadingStatusDocumentInfo = LoadingStatus.Rejected;
        })
        .addCase(api.updateDocumentCardStatus.pending, (state) => {
          state.isUpdatingStatusInProgress = true;
        })
        .addCase(api.updateDocumentCardStatus.fulfilled, (state, action) => {
          state.isUpdatingStatusInProgress = false;
          state.documentInfo = action.payload;
        })
        .addCase(api.updateDocumentCardStatus.rejected, (state) => {
          state.isUpdatingStatusInProgress = false;
        });
    }
  });

  return documentCardSlice;
};
