import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { InteractionLogNotificationHistoryTableColumns } from '@/modules/interaction-log/models/interaction-log-notification-history/interaction-log-notification-history-table-columns';

const columnVisibility = {
  [InteractionLogNotificationHistoryTableColumns.Date]: true,
  [InteractionLogNotificationHistoryTableColumns.Status]: true,
  [InteractionLogNotificationHistoryTableColumns.User]: true,
};

const sorting = [
  {
    id: InteractionLogNotificationHistoryTableColumns.Date,
    desc: false,
  }
];

const columnOrder = [
  InteractionLogNotificationHistoryTableColumns.Date,
  InteractionLogNotificationHistoryTableColumns.Status,
  InteractionLogNotificationHistoryTableColumns.User,
  AppTableActionColumnName,
];

const columnSizing = {
  [InteractionLogNotificationHistoryTableColumns.Date]: 100,
  [InteractionLogNotificationHistoryTableColumns.Status]: 160,
  [InteractionLogNotificationHistoryTableColumns.User]: 140,
};

export const defaultInteractionLogNotificationHistoryTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
