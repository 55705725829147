import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { InteractionLogTableColumns } from '@/modules/interaction-log/models/interaction-log-table/interaction-log-table-columns';

const interactionLogColumnsToDtoMap: Partial<Record<InteractionLogTableColumns, keyof InteractionItem>> = {
  [InteractionLogTableColumns.CreatedDate]: 'createdDate',
  [InteractionLogTableColumns.DocNumber]: 'docNumber',
  [InteractionLogTableColumns.Organization]: 'ownOrganizationName',
  [InteractionLogTableColumns.Event]: 'docSchema',
  [InteractionLogTableColumns.Source]: 'source',
  [InteractionLogTableColumns.Target]: 'target',
  [InteractionLogTableColumns.User]: 'userFullName',
  [InteractionLogTableColumns.Status]: 'eventState',
  [InteractionLogTableColumns.CreatedDateMdlp]: 'docCreationDateInMdlp',
};

export const convertInteractionItemColumnFieldToDto = (columnField: InteractionLogTableColumns) => interactionLogColumnsToDtoMap[columnField];