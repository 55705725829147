import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataCaseReducers } from '@/common/models/pageable-data/pageable-data-case-reducers';
import { PageableDataPatchItemByCallbackParams as PatchItemByCallbackParams } from '@/common/models/pageable-data/pageable-data-patch-item-by-callback-params';
import { PageableDataPatchItemByIndexParams as PatchItemByIndexParams } from '@/common/models/pageable-data/pageable-data-patch-item-by-index-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

const getInitialState = <T>(): PageableDataState<T> => ({
  pageNumber: 0,
  totalItems: undefined,
  loadingStatus: undefined,
  data: []
});

export const createPageableDataSlice = <T>(name: string) => {
  return createSlice<PageableDataState<T>, PageableDataCaseReducers<T>>({
    name: `${name}/pageableData`,
    initialState: getInitialState<T>(),
    reducers: {
      moveToNextPage(state) {
        state.pageNumber += 1;
      },
      resetPagination(state) {
        state.pageNumber = 0;
      },
      addData: (state, action: PayloadAction<PageableDataResponse<T>>) => {
        const { data, total } = action.payload;
        state.data.push(...data as Draft<T>[]);
        state.totalItems = total;
      },
      rewriteData: (state, action: PayloadAction<PageableDataResponse<T>>) => {
        const { data, total } = action.payload;
        state.data = data as Draft<T>[];
        state.totalItems = total;
      },
      patchItemByIndex: (state, action: PayloadAction<PatchItemByIndexParams<T>>) => {
        const { item, index } = action.payload;
        state.data[index] = { ...state.data[index], ...item };
      },
      patchItemByFindIndex: (state, action: PayloadAction<PatchItemByCallbackParams<T>>) => {
        const { item, findIndexCallback } = action.payload;
        const index = state.data.findIndex(findIndexCallback);
        if (index >= 0) {
          state.data[index] = { ...state.data[index], ...item };
        }
      },
      deleteItem: (state, action: PayloadAction<(item: Draft<T>) => boolean>) => {
        state.data = state.data.filter(action.payload);
      },
      addItemToStart: (state, action: PayloadAction<Draft<T>>) => {
        state.data.unshift(action.payload);
      },
      updateLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
        state.loadingStatus = action.payload;
      },
    }
  });
};
