import { RootState } from '@/store';

const selectLegalEntitiesActivityPlacesState = (state: RootState) => state.references.legalEntitiesActivityPlaces;

const selectActivityPlaces = (state: RootState) => selectLegalEntitiesActivityPlacesState(state).activityPlaces;
const selectIsLoading = (state: RootState) => selectLegalEntitiesActivityPlacesState(state).isLoadingData;
const selectIsDialogOpened = (state: RootState) => selectLegalEntitiesActivityPlacesState(state).isDialogOpened;
const selectLegalEntityPartnerUuid = (state: RootState) => selectLegalEntitiesActivityPlacesState(state).currentLegalEntity?.partnerUuid;
const selectLegalEntityFullName = (state: RootState) => selectLegalEntitiesActivityPlacesState(state).currentLegalEntity?.fullOrganizationName;
const selectTableConfig = (state: RootState) => selectLegalEntitiesActivityPlacesState(state).tableConfig.config;

export const LegalEntitiesActivityPlacesSelectors = {
  selectActivityPlaces,
  selectIsLoading,
  selectIsDialogOpened,
  selectLegalEntityPartnerUuid,
  selectLegalEntityFullName,
  selectTableConfig,
};
