import React, { FC, ReactNode } from 'react';
import KeepAlive from 'react-activation';

interface AppKeepAliveComponentProps {
  name: string;
  children: ReactNode;
}

export const AppKeepAliveComponent: FC<AppKeepAliveComponentProps> = ({ name, children }) => (
  <KeepAlive name={name}>
    {children}
  </KeepAlive>
);
