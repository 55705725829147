import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { AuthLayout } from '@/modules/auth/components/base-auth-layout/AuthLayout';
import { InviteManagerContent } from '@/modules/auth/components/invite-manager-content/InviteManagerContent';
import { LicenceAgreementProps } from '@/modules/auth/components/licence-agreement/LicenceAgreement';
import { useInviteManagerPhoneCodeForm } from '@/modules/auth/hooks/use-invite-manager-phone-code-form';
import { useInviteManagerSetNewPasswordForm } from '@/modules/auth/hooks/use-invite-manager-set-new-password-form';
import { setIsOpenLicenceAgreement } from '@/modules/auth/store/invite-manager';
import { sendVerificationCodeByCipcher } from '@/modules/auth/store/invite-manager/async-thunks';
import { InviteManagerSelectors } from '@/modules/auth/store/invite-manager/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

export const InviteManagerPage = () => {
  const { asyncDispatch } = useAsyncDispatch();
  const dispatch = useAppDispatch();

  const { managerUuidCipher } = useParams();

  const showSetNewPasswordForm = useSelector(InviteManagerSelectors.selectShowSetNewPasswordForm);
  const isOpenLicenceAgreement = useSelector(InviteManagerSelectors.selectIsOpenLicenceAgreement);

  useEffect(() => {
    asyncDispatch(() => sendVerificationCodeByCipcher(managerUuidCipher));
  }, []);

  const closeLicenceAgreement = useCallback(() => {
    dispatch(setIsOpenLicenceAgreement(false));
  }, []);

  const phoneCodeFormContext = useInviteManagerPhoneCodeForm(managerUuidCipher);
  const setNewPasswordFormContext = useInviteManagerSetNewPasswordForm(managerUuidCipher);
  const licenceAgreementContext: LicenceAgreementProps = useMemo(() => ({
    onGoBack: closeLicenceAgreement
  }), [closeLicenceAgreement]);

  return (
    <AuthLayout padding licenceAgreement={isOpenLicenceAgreement}>
      <InviteManagerContent
        isOpenLicenceAgreement={isOpenLicenceAgreement}
        showSetNewPasswordForm={showSetNewPasswordForm}
        phoneCodeFormContext={phoneCodeFormContext}
        licenceAgreementContext={licenceAgreementContext}
        setNewPasswordFormContext={setNewPasswordFormContext}
      />
    </AuthLayout>
  );
};
