import { formatPhoneOutput } from '@/common/utils/common/formatting-utils';
import { userProfileLabels } from '@/modules/portal/constants/user-profile-labels';
import { UserProfileData, UserProfileFields } from '@/modules/portal/models/user-profile-info';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectUserProfileState = (state: RootState) => state.portal.userProfile;

const selectProfileInfo = (state: RootState) => selectUserProfileState(state).profileInfo;
const selectProfileData = createSelector([selectProfileInfo], (profileInfo) =>
  profileInfo && Object.keys(userProfileLabels).reduce((acc, key: UserProfileFields) => {
    acc[key] = {
      label: userProfileLabels[key],
      value: key === 'phoneNumber' ? formatPhoneOutput(profileInfo[key]) : profileInfo[key]
    };
    return acc;
  }, {} as UserProfileData)
);
const selectUserProfileLoadingStatus = (state: RootState) => selectUserProfileState(state).profileInfoLoadingStatus;
const selectChangePasswordDialogOpen = (state: RootState) => selectUserProfileState(state).changePasswordDialogOpen;
const selectChangePasswordDialogIsLoading = (state: RootState) => selectUserProfileState(state).changePasswordDialogIsLoading;

export const UserProfileSelectors = {
  selectProfileInfo,
  selectProfileData,
  selectUserProfileLoadingStatus,
  selectChangePasswordDialogOpen,
  selectChangePasswordDialogIsLoading
};
