import CopyIcon from '@/assets/icons/copy.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { OrganizationInfoBlock } from '@/modules/organization-profile/components/organization-info-block/OrganizationInfoBlock';
import { useOrganizationInfoCopyToClipboard } from '@/modules/organization-profile/hooks/use-organization-info-copy-to-clipboard';
import React, { FC, memo } from 'react';
import './organization-info-copyable.scss';

interface OrganizationInfoCopyableProps {
  title: string;
  value: string;
}

const OrganizationInfoCopyableInner: FC<OrganizationInfoCopyableProps> = ({ title, value }) => {
  const { copyTooltip, copyValue } = useOrganizationInfoCopyToClipboard(value);

  return (
    <div className="organization-info-copyable">
      <AppIconButton
        icon={<CopyIcon />}
        activeColor="primary"
        tooltip={copyTooltip}
        onClick={copyValue}
      />
      <OrganizationInfoBlock
        title={title}
        value={value}
      />
    </div>
  );
};

export const OrganizationInfoCopyable = memo(OrganizationInfoCopyableInner);
