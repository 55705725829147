import { AppButtonVariant } from '@/common/components/app-button/AppButton';
import { Document } from '@/shared/document/models/document';
import { DocumentCardActionButtonsType, getDocumentCardActionButtonsLabel, getDocumentCardActionButtonsVariant } from '@/shared/document/models/document-card/document-card-action-buttons-type';
import { DocumentOrder } from '@/shared/document/models/document-order';
import { DocumentStatus } from '@/shared/document/models/document-status';
import { DocumentType } from '@/shared/document/models/document-type';

export type DocumentCardActionButtonsDisplayParams = Pick<Document, 'status' | 'order'> & { docType: DocumentType; };

interface DocumentCardActionButtonsDisplaySettings {
  label: string;
  variant: AppButtonVariant;
  isButtonDisabled: (params: DocumentCardActionButtonsDisplayParams) => boolean;
  isButtonVisible: (params: DocumentCardActionButtonsDisplayParams) => boolean;
}

type DocumentCardActionButtonsDisplayReturnValue = Record<DocumentCardActionButtonsType, DocumentCardActionButtonsDisplaySettings>;

export const documentCardActionButtonsDisplay: DocumentCardActionButtonsDisplayReturnValue = {
  [DocumentCardActionButtonsType.StartProcesing]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.StartProcesing),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.StartProcesing),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, docType, order }) => status === DocumentStatus.New && (docType === DocumentType.Receipt || docType === DocumentType.OtherDisposals || (docType === DocumentType.Sale && order === DocumentOrder.Direct))
  },
  [DocumentCardActionButtonsType.Reject]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.Reject),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.Reject),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, order, docType }) => status === DocumentStatus.Processed && (docType === DocumentType.Receipt && order === DocumentOrder.Direct)
  },
  [DocumentCardActionButtonsType.Recall]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.Recall),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.Recall),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, order, docType }) => (status === DocumentStatus.New || status === DocumentStatus.Processed) && (docType === DocumentType.Sale && order === DocumentOrder.Reverse)
  },
  [DocumentCardActionButtonsType.Confirm]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.Confirm),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.Confirm),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, docType, order }) => (status === DocumentStatus.New || status === DocumentStatus.Processed) && (docType === DocumentType.Sale && order === DocumentOrder.Reverse)
  },
  [DocumentCardActionButtonsType.Scan]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.Scan),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.Scan),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status }) => status === DocumentStatus.InProcess
  },
  [DocumentCardActionButtonsType.AddRemains]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.AddRemains),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.AddRemains),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, docType }) => status === DocumentStatus.InProcess && docType === DocumentType.OtherDisposals
  },
  [DocumentCardActionButtonsType.CompleteProcesing]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.CompleteProcesing),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.CompleteProcesing),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status }) => status === DocumentStatus.InProcess
  },
  [DocumentCardActionButtonsType.Accept]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.Accept),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.Accept),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, docType }) => status === DocumentStatus.Processed && docType === DocumentType.Receipt
  },
  [DocumentCardActionButtonsType.Send]: {
    label: getDocumentCardActionButtonsLabel(DocumentCardActionButtonsType.Send),
    variant: getDocumentCardActionButtonsVariant(DocumentCardActionButtonsType.Send),
    isButtonDisabled: () => false,
    isButtonVisible: ({ status, docType, order }) => status === DocumentStatus.Processed && ((docType === DocumentType.Sale && order === DocumentOrder.Direct) || docType === DocumentType.OtherDisposals)
  },
};
