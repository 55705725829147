import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { AppTableScrollBottomPadding } from '@/common/constants/app-table-constants';
import { useScrolling } from '@/common/hooks/use-scrolling';
import { AppTableVirtualizerType } from '@/common/models/app-table/app-table-types';
import { MutableRefObject, useEffect, useState } from 'react';

type AppTableScrollingHookParams = Pick<AppTableProps, 'data' | 'onScrollAtTheEnd'> & {
  rowVirtualizerInstanceRef: MutableRefObject<AppTableVirtualizerType | null>;
};

export function useAppTableScrolling({ data, rowVirtualizerInstanceRef, onScrollAtTheEnd }: AppTableScrollingHookParams) {
  const [prevData, setPrevData] = useState([]);

  useEffect(() => {
    if (!!data.length && data.length < prevData.length) {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    }
    setPrevData(data);
  }, [data]);

  const { onScroll } = useScrolling({
    scrollBottomPadding: AppTableScrollBottomPadding,
    onScrollAtTheEnd,
  });

  return {
    onScroll,
  };
}
