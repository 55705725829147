import { DoneIcon } from '@/common/components/icons/done-icon/DoneIcon';
import { Certificate } from '@/common/models/certificate';
import React, { FC, memo } from 'react';

interface CertificateListTableRowProps {
  certificateInfo?: Certificate;
  isSelected?: boolean;
  onSelectCertificate?: () => void;
}

const CertificateListTableRowInner: FC<CertificateListTableRowProps> = ({ certificateInfo, isSelected, onSelectCertificate }) => {
  const { INN, issuedFor, issuedFrom, organization, owner, position } = certificateInfo ?? {};
  return certificateInfo
    ? (
      <tr onClick={onSelectCertificate}>
        <td>{isSelected && <DoneIcon />}</td>
        <td>{organization}</td>
        <td>{owner}</td>
        <td>{INN}</td>
        <td>{position}</td>
        <td>{issuedFor}</td>
        <td>{issuedFrom}</td>
      </tr>
    ) : (
      <tr>
        <td></td>
        <th>Организация</th>
        <th>Владелец</th>
        <th>ИНН</th>
        <th>Должность</th>
        <th>Выписан на</th>
        <th>Выдан</th>
      </tr>
    );
};

export const CertificateListTableRow = memo(CertificateListTableRowInner);