import { useRef } from 'react';

export const useDebouncedInput = () => {
  const minPeriodBetweenInput = 300;

  const timeoutRef = useRef<NodeJS.Timeout>();

  const debouncedInput = (onInput: () => void) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(onInput, minPeriodBetweenInput);
  };

  return {
    debouncedInput
  };
};