import { ProtectedRoute } from '@/common/components/protected-route/ProtectedRoute';
import { inviteManagerRoutePath, loginRoutePath, passwordRecoveryRoutePath, registrationRoutePath, setNewPasswordRoutePath } from '@/modules/auth/routes/routes';
import { InviteManagerPage } from '@/modules/auth/views/invite-manager-page/InviteManagerPage';
import { LoginPage } from '@/modules/auth/views/login-page/LoginPage';
import { PasswordRecoveryPage } from '@/modules/auth/views/password-recovery-page/PasswordRecoveryPage';
import { RegistrationPage } from '@/modules/auth/views/registration-page/RegistrationPage';
import { SetNewPasswordPage } from '@/modules/auth/views/set-new-password-page/SetNewPasswordPage';
import { notFoundRoutePath } from '@/modules/portal/routes/routes';
import { NotFoundPage } from '@/modules/portal/views/not-found-page/NotFoundPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All auth routes
 */
const routes: RouteObject[] = [
  {
    element:
      <ProtectedRoute
        accessBy="non-authenticated"
        redirectPath="/"
      />,
    children: [
      {
        path: loginRoutePath,
        element: <LoginPage />,
      },
      {
        path: registrationRoutePath,
        element: <RegistrationPage />,
      },
      {
        path: passwordRecoveryRoutePath,
        element: <PasswordRecoveryPage />,
      },
      {
        path: setNewPasswordRoutePath,
        element: <SetNewPasswordPage />,
      },
      {
        path: inviteManagerRoutePath,
        element: <InviteManagerPage />,
      },
      {
        path: notFoundRoutePath,
        element:
          <NotFoundPage
            showBackButton={false}
            mainPageRoutePath={loginRoutePath}
          />
      },
    ],
  }
];

export default routes;
