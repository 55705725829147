export enum DocumentCardGeneralPositionsTableColumns {
  Code = 'code',
  Name = 'name',
  Producer = 'producer',
  Vital = 'vital',
  Gtin = 'gtin',
  Series = 'series',
  ExpirationDate = 'expirationDate',
  PriceWithVat = 'priceWithVat',
  Vat = 'vat',
  TotalPriceWithVat = 'totalPriceWithVat',
  VatRate = 'vatRate',
  IsProductInOrder = 'isProductInOrder',
  TotalTertiaryPackages = 'totalTertiaryPackages',
  TotalSecondaryPackages = 'totalSecondaryPackages',
  Amount = 'amount',
}
