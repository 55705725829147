import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { UsersManagementTableColumns } from '@/modules/users-management/models/users-management-table/users-management-table-columns';

const columnVisibility = {
  [UsersManagementTableColumns.Role]: true,
  [UsersManagementTableColumns.Fio]: true,
  [UsersManagementTableColumns.Position]: true,
  [UsersManagementTableColumns.Organization]: true,
  [UsersManagementTableColumns.ActivityPlaces]: true,
  [UsersManagementTableColumns.CreatedDate]: true,
  [UsersManagementTableColumns.LastSigninDate]: true,
  [UsersManagementTableColumns.Email]: true,
  [UsersManagementTableColumns.PhoneNumber]: true,
};

const sorting = [
  {
    id: UsersManagementTableColumns.Fio,
    desc: false,
  }
];

const columnOrder = [
  UsersManagementTableColumns.Role,
  UsersManagementTableColumns.Fio,
  UsersManagementTableColumns.Position,
  UsersManagementTableColumns.Organization,
  UsersManagementTableColumns.ActivityPlaces,
  UsersManagementTableColumns.CreatedDate,
  UsersManagementTableColumns.LastSigninDate,
  UsersManagementTableColumns.Email,
  UsersManagementTableColumns.PhoneNumber,
  AppTableActionColumnName,
];

const columnSizing = {
  [UsersManagementTableColumns.Role]: 70,
  [UsersManagementTableColumns.Fio]: 150,
  [UsersManagementTableColumns.Position]: 120,
  [UsersManagementTableColumns.Organization]: 120,
  [UsersManagementTableColumns.ActivityPlaces]: 150,
  [UsersManagementTableColumns.CreatedDate]: 120,
  [UsersManagementTableColumns.LastSigninDate]: 105,
  [UsersManagementTableColumns.Email]: 130,
  [UsersManagementTableColumns.PhoneNumber]: 80,
};

export const defaultUsersManagementTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
