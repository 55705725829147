import authRoutes from '@/modules/auth/routes';
import { NotFoundPagesNavigation } from '@/modules/portal/components/not-found-pages-navigation/NotFoundPagesNavigation';
import portalRoutes from '@/modules/portal/routes';
import React from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

/**
 * App route list.
 */
const routes: RouteObject[] = [
  ...authRoutes,
  ...portalRoutes,
  {
    path: '*',
    element: <NotFoundPagesNavigation />,
  }
];

/**
 * App router.
 */
const router = createBrowserRouter(routes);

export default router;
