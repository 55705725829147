import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { CertificatesSelectors } from '@/modules/certificates/store/selectors';
import { getSignatureByCertificateOwner } from '@/modules/certificates/utils/get-signature-by-certificate-owner';
import { interactionLogModuleName } from '@/modules/interaction-log/constants/interaction-log-module-names';
import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { convertInteractionItemColumnFieldToDto } from '@/modules/interaction-log/models/interaction-log-table/interaction-log-table-column-converter';
import { InteractionLogRequests } from '@/modules/interaction-log/requests/interaction-log-requests';
import { InteractionLogSelectors } from '@/modules/interaction-log/store/interaction-log/selectors';
import { buildInteractionLogFilterExpression } from '@/modules/interaction-log/utils/interaction-log-filter-utils';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { UserProfileSelectors } from '@/modules/portal/store/user-profile/selectors';
import { hasRolesAccessByPermission } from '@/modules/portal/utils/has-roles-access-by-permission';
import { FileRequests } from '@/shared/files/requests/file-requests';
import { downloadFileFromResponse } from '@/shared/files/utils/file-import-utils';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllInteractionLogs = createAsyncThunkWrapper<
  PageableDataResponse<InteractionItem>,
  PageableDataBody
>(
  `${interactionLogModuleName}/getAllInteractionLogs`,
  ({ pageNumber }, { getState }) => {
    const sorting = InteractionLogSelectors.selectTableSorting(getState());
    const filters = InteractionLogSelectors.selectFilters(getState());
    const searchString = InteractionLogSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: AppTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertInteractionItemColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const userRoles = UserProfileSelectors.selectProfileInfo(getState()).roles;
    const isOrganizationVisible = hasRolesAccessByPermission(userRoles, UserPermission.ViewOrganizationInInteractionLog);

    const filterParams = buildInteractionLogFilterExpression(filters, searchString, isOrganizationVisible);
    if (filterParams) {
      body.filter = filterParams;
    }

    return InteractionLogRequests.getAllInteractionLogs(body)
      .then(res => res.data);
  },
  { condition: asyncThunkConditionOption(InteractionLogSelectors.selectIsLoadingInteractionData) }
);

export const downloadInteractionLogFile = createAsyncThunkWrapper<
  void,
  string
>(
  `${interactionLogModuleName}/downloadInteractionLogFile`,
  (fileUuid) => FileRequests.downloadFile(fileUuid)
    .then((res) => downloadFileFromResponse(res)),
);

export const retryOperationInit = createAsyncThunkWrapper<
  string,
  undefined
>(
  `${interactionLogModuleName}/retryOperationInit`,
  (_, { getState }) => {
    const selectedCertificate = CertificatesSelectors.selectSelectedCertificate(getState());
    const eventUuid = InteractionLogSelectors.selectRetryOperationEventUuid(getState());
    return InteractionLogRequests.initResendInteraction(eventUuid)
      .then((res) => getSignatureByCertificateOwner(selectedCertificate.owner, res.data));
  }
);

export const retryOperationSend = createAsyncThunkWrapper<
  void,
  string
>(
  `${interactionLogModuleName}/retryOperationSend`,
  async (signature, { getState }) => {
    const eventUuid = InteractionLogSelectors.selectRetryOperationEventUuid(getState());
    await InteractionLogRequests.sendResendInteraction(eventUuid, signature);
  },
);