import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectProcessesState = (state: RootState) => state.references.processes;

const selectTableConfig = (state: RootState) => selectProcessesState(state).tableConfig.config;
const selectIsLoadingProcesses = (state: RootState) => selectProcessesState(state).isLoadingProcesses;
const selectFilteredProcesses = (state: RootState) => selectProcessesState(state).filteredProcesses;

const selectSearchState = (state: RootState) => selectProcessesState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

export const ProcessesSelectors = {
  selectTableConfig,
  selectIsLoadingProcesses,
  selectSearchState,
  selectSearchString,
  selectFilteredProcesses,
};
