import { NomenclatureTable } from '@/modules/references/components/nomenclature-table/NomenclatureTable';
import { resetState } from '@/modules/references/store/nomenclature';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const NomenclaturePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <NomenclatureTable />;
};
