import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { FavoriteDocument } from '@/modules/favorites/models/favorite-document';

const searchFields: (keyof FavoriteDocument)[] = ['activityPlaceName', 'partnerOrganizationName', 'docNumber', 'comment'];

function getFavoritesSearchFilter(searchString: string): RsqlFilterModel<FavoriteDocument>[] {
  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: searchString,
  }));
}

export function buildFavoritesFilterExpression(searchString: string): string {
  const searchModel = getFavoritesSearchFilter(searchString);

  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or);
}
