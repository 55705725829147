import { axios } from '@/common/api/axios-config';
import {
  allLegalEntitiesUrl,
  allLegalEntityActivityPlacesUrl,
  allMdlpProductsUrl,
  allNomenclatureUrl,
  allProcessesUrl,
  legalEntityUrl,
  mdlpProductByGtintUrl,
  syncNomenclatureUrl
} from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { LegalEntityActivityPlacesResponse } from '@/modules/references/models/legal-entities-activity-places/legal-entity-activity-places-response';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';
import { UpdateLegalEntityBody } from '@/modules/references/models/legal-entities/update-legal-entity-body';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';
import { ProcessesResponse } from '@/modules/references/models/processes/processes-response';
import { AxiosResponse } from 'axios';

export class ReferencesRequests {
  static getAllLegalEntities(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<LegalEntity>>> {
    return axios.get(allLegalEntitiesUrl, { params });
  }

  static updateLegalEntity(partnerUuid: string, body: UpdateLegalEntityBody): Promise<AxiosResponse<void>> {
    return axios.patch(legalEntityUrl(partnerUuid), body);
  }

  static getAllLegalEntityActivityPlaces(partnerUuid: string, signal?: AbortSignal): Promise<AxiosResponse<LegalEntityActivityPlacesResponse>> {
    return axios.get(allLegalEntityActivityPlacesUrl(partnerUuid), { signal });
  }

  static getAllMdlpProducts(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<MdlpProduct>>> {
    return axios.get(allMdlpProductsUrl, { params });
  }

  static getMdlpProductByGtin(gtin: string, signal?: AbortSignal): Promise<AxiosResponse<MdlpProduct>> {
    return axios.get(mdlpProductByGtintUrl(gtin), { signal });
  }

  static syncNomenclatureWithMdlpByGtin(gtin: string): Promise<AxiosResponse<MdlpProduct>> {
    return axios.post(syncNomenclatureUrl, { gtin });
  }

  static getAllNomenclature(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<NomenclatureItem>>> {
    return axios.get(allNomenclatureUrl, { params });
  }

  static getAllProcesses(): Promise<AxiosResponse<ProcessesResponse>> {
    return axios.get(allProcessesUrl);
  }
}
