import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableRowSelectionType,
  AppTableRowType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { getValueFromUpdater } from '@/common/utils/common/types-utils';
import { DocumentsTableProps } from '@/shared/document/components/documents-table/DocumentsTable';
import { buildDefaultDocumentsTableConfig } from '@/shared/document/constants/documents-table-config';
import { DocumentCardSharedData } from '@/shared/document/models/document-card/document-card-shared-data';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { convertFromColumnModelToUpdateDocumentBody } from '@/shared/document/models/documents/update-document-body';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { getDocumentCardRelativeRoute } from '@/shared/document/routes/routes';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type DocumentsTableEventHandlersHookParams =
  PageableLoadingActions &
  Pick<DocumentsTableProps, 'documentsTableConfigActions' | 'documentsSearchingActions' | 'documentsTableActions' | 'documentsPageableDataActions' | 'documentsApi' | 'documentsSelectors'> & VisibleColumns<DocumentsTableColumns>;

export function useDocumentsTableEventHandlers({ loadFirstPage, loadNextPage, documentsTableConfigActions, documentsSearchingActions, documentsTableActions, documentsPageableDataActions, documentsApi, documentsSelectors, visibleColumns }: DocumentsTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const navigate = useNavigate();

  const tableSorting = useSelector(documentsSelectors.selectTableSorting);

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(documentsTableConfigActions.updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    const newSorting = getValueFromUpdater<AppTableSortingType>(value, tableSorting);
    const defaultDocumentsTableConfig = buildDefaultDocumentsTableConfig(visibleColumns);
    dispatch(documentsTableConfigActions.updateTableSortingState(!newSorting.length ? defaultDocumentsTableConfig.sorting : newSorting));
  }, [tableSorting]);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(documentsTableConfigActions.updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(documentsTableConfigActions.updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(documentsSearchingActions.updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onScrollChange = useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  const onRowSelectionChange = useCallback((value: Updater<AppTableRowSelectionType>) => {
    dispatch(documentsTableActions.setRowSelection(value));
  }, []);

  const onRowDoubleClick = useCallback((row: AppTableRowType<ShortDocumentInfo>) => {
    const { docState, isFavorite, docProcessingWay, docOrder } = row.original;
    const dataForDocumentCard: DocumentCardSharedData = { docState, isFavorite, docProcessingWay, docOrder };
    navigate(getDocumentCardRelativeRoute(row.original.docUuid), { state: dataForDocumentCard });
  }, []);

  const onEditingRowSave: AppTableProps<ShortDocumentInfo, DocumentsTableColumns>['onEditingRowSave'] = ({ exitEditingMode, row, values }) => {
    dispatch(documentsTableActions.setUpdatedRowId(row.id));

    const { docUuid } = row.original;
    const updateEntity = convertFromColumnModelToUpdateDocumentBody(values);

    asyncDispatch(
      () => documentsApi.updateDocument({ docUuid, body: updateEntity }),
      () => {
        dispatch(documentsPageableDataActions.patchItemByIndex({ item: updateEntity, index: row.index }));
        exitEditingMode();
      },
      undefined,
      () => {
        // needs for delay for exitEditingMode, in order to stop loader only after edit mode will be closed.
        setTimeout(() => {
          dispatch(documentsTableActions.setUpdatedRowId(''));
          dispatch(documentsTableActions.setIsUpdatingInProgress(false));
        }, 200);
      }
    );
  };

  const onRefreshClick = useCallback(() => {
    loadFirstPage();
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRowSelectionChange,
    onRowDoubleClick,
    onEditingRowSave,
    onRefreshClick
  };
}
