import { CertificatesSelectors } from '@/modules/certificates/store/selectors';
import { getSignatureByCertificateOwner } from '@/modules/certificates/utils/get-signature-by-certificate-owner';
import { DocumentDto } from '@/shared/document/models/dto/document-dto';
import { UpdateDocumentStatusBody } from '@/shared/document/models/update-document-status-body';
import { DocumentRequests } from '@/shared/document/requests/document-requests';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardThunksCreationParams {
  moduleName: string;
  selectors: DocumentCardSelectors;
}

// TODO: fix type
export const createDocumentCardThunks = ({ moduleName, selectors }: DocumentCardThunksCreationParams) => {
  const getDocumentInfo = createAsyncThunkWrapper<
    DocumentDto,
    string
  >(
    `${moduleName}/getDocumentInfo`,
    (docUuid) =>
      DocumentRequests.getDocumentInfo(docUuid)
        .then((res) => res.data)
  );

  const updateDocumentCardStatus = createAsyncThunkWrapper<
    DocumentDto,
    Pick<UpdateDocumentStatusBody, 'state'>
  >(
    `${moduleName}/updateDocumentCardStatus`,
    ({ state }, { getState }) => {
      const docUuid = selectors.selectDocumentId(getState());
      return DocumentRequests.updateDocumentStatus({ docUuid, state })
        .then((res) => res.data);
    },
    { condition: asyncThunkConditionOption(selectors.selectIsUpdatingStatusInProgress) }
  );

  const getDocumentSignature = createAsyncThunkWrapper<
    string,
    undefined
  >(
    `${moduleName}/getDocumentSignature`,
    (_, { getState }) => {
      const docUuid = selectors.selectDocumentId(getState());
      const rejectReason = selectors.selectProcessedRejectReason(getState());
      const sendType = selectors.selectProcessedDialogSendType(getState());

      return DocumentRequests.initDocument(docUuid, { rejectReason, sendType })
        .then((res) => {
          const selectedSertificate = CertificatesSelectors.selectSelectedCertificate(getState());
          return getSignatureByCertificateOwner(selectedSertificate.owner, res.data);
        });
    }
  );

  const sendDocumentToMdlp = createAsyncThunkWrapper<
    void,
    string
  >(
    `${moduleName}/sendDocumentToMdlp`,
    async (signature, { getState }) => {
      const docUuid = selectors.selectDocumentId(getState());
      await DocumentRequests.sendDocument(docUuid, signature);
    },
  );

  const checkForAllScannedMedicine = createAsyncThunkWrapper<
    void,
    undefined
  >(
    `${moduleName}/checkForAllScannedMedicine`,
    async (_, { getState }) => {
      const docUuid = selectors.selectDocumentId(getState());
      await DocumentRequests.checkDocumentForAllScannedMedicine(docUuid);
    },
  );

  return {
    getDocumentInfo,
    updateDocumentCardStatus,
    getDocumentSignature,
    sendDocumentToMdlp,
    checkForAllScannedMedicine
  };
};
