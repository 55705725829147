import { ErrorCode } from '@/common/models/error-code';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { defaultDocumentCardGeneralPositionsTableConfig } from '@/shared/document/constants/document-card-general-positions-table-config';
import { DocumentCardGeneralPosition } from '@/shared/document/models/document-card/general-positions/document-card-general-position';
import { DocumentCardGeneralPositionsTableColumns } from '@/shared/document/models/document-card/general-positions/document-card-general-positions-table-columns';
import { DocumentsCardGeneralPositionsApi } from '@/shared/document/store/document-card/general-positions/types';
import { CaseReducerActions, Draft, SliceCaseReducers, createSlice } from '@reduxjs/toolkit';

export interface DocumentCardGeneralPositionsState extends VisibleColumns<DocumentCardGeneralPositionsTableColumns> {
  tableConfig: SavedTableConfigState;
  positions: DocumentCardGeneralPosition[];
  isLoadingGeneralPositions: boolean;
  isMedicineDataNotRecieved?: boolean;
}

interface DocumentCardGeneralPositionsCaseReducers extends SliceCaseReducers<DocumentCardGeneralPositionsState> {
  resetState: (state: Draft<DocumentCardGeneralPositionsState>) => void;
}

export type DocumentCardGeneralPositionsActions = CaseReducerActions<DocumentCardGeneralPositionsCaseReducers, string>;

const notClearedFields: Set<keyof DocumentCardGeneralPositionsState> = new Set(['tableConfig']);

interface CreateDocumentCardGeneralPositionsSliceParams extends VisibleColumns<DocumentCardGeneralPositionsTableColumns> {
  moduleName: string;
  savedTableConfigName: string;
  api: DocumentsCardGeneralPositionsApi;
}

export const createDocumentCardGeneralPositionsSlice = ({ moduleName, savedTableConfigName, api, visibleColumns }: CreateDocumentCardGeneralPositionsSliceParams) => {
  const documentCardGeneralPositionsTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: defaultDocumentCardGeneralPositionsTableConfig,
  };

  const documentCardGeneralPositionsTableConfigSlice = createSavedTableConfigSlice(moduleName, documentCardGeneralPositionsTableConfigState);

  const initialState: DocumentCardGeneralPositionsState = {
    tableConfig: documentCardGeneralPositionsTableConfigSlice.getInitialState(),
    positions: [],
    isLoadingGeneralPositions: false,
    isMedicineDataNotRecieved: false,
    visibleColumns
  };

  const rootSlice = createSlice<DocumentCardGeneralPositionsState, DocumentCardGeneralPositionsCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      resetState: (state) => {
        Object.keys(state)
          .forEach((key: keyof DocumentCardGeneralPositionsState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getGeneralPositions.pending, (state) => {
          state.isMedicineDataNotRecieved = false;
          state.isLoadingGeneralPositions = true;
        })
        .addCase(api.getGeneralPositions.fulfilled, (state, action) => {
          state.isLoadingGeneralPositions = false;
          state.positions = action.payload;
        })
        .addCase(api.getGeneralPositions.rejected, (state, action) => {
          if (action.payload.code === ErrorCode.NOT_ALL_MEDICINES_ARE_READY) {
            state.isMedicineDataNotRecieved = true;
          }
          state.isLoadingGeneralPositions = false;
        })
        .addMatcher(
          (action) => action.type.startsWith(documentCardGeneralPositionsTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = documentCardGeneralPositionsTableConfigSlice.reducer(state.tableConfig, action);
          }
        );
    }
  });

  return {
    rootSlice,
    documentCardPositionsTableConfigSlice: documentCardGeneralPositionsTableConfigSlice,
  };
};
