import { AppDatePicker } from '@/common/components/app-date-picker/AppDatePicker';
import { AppSelect } from '@/common/components/app-select/AppSelect';
import { Labels } from '@/common/constants/labels';
import { getEndOfDay } from '@/common/utils/common/date-utils';
import { getFileOperationTypesOptions } from '@/modules/file-operations/models/file-operation-type';
import { FileOperationsFiltersFields, FileOperationsFilters as FileOperationsFiltersModel } from '@/modules/file-operations/models/file-operations-filters';
import { getFileStatesOptions } from '@/modules/file-operations/models/file-state';
import { updateFilter } from '@/modules/file-operations/store';
import { FileOperationsSelectors } from '@/modules/file-operations/store/selectors';
import { getFileTypesOptions } from '@/shared/files/models/file-type';
import { useAppDispatch } from '@/store';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './file-operations-filters.scss';

const FileOperationsFiltersInner = () => {
  const dispatch = useAppDispatch();

  const fileType = useSelector(FileOperationsSelectors.selectFilterFileType);
  const operationType = useSelector(FileOperationsSelectors.selectFilterOperationType);
  const fileState = useSelector(FileOperationsSelectors.selectFilterFileState);
  const operationDateFrom = useSelector(FileOperationsSelectors.selectFilterOperationDateFrom);
  const operationDateTo = useSelector(FileOperationsSelectors.selectFilterOperationDateTo);

  const updateFilterField = (fieldName: FileOperationsFiltersFields) =>
    (value: FileOperationsFiltersModel[FileOperationsFiltersFields]) =>
      dispatch(updateFilter({ [fieldName]: value }));

  const updateFileType = useCallback(updateFilterField('fileType'), []);
  const updateOperationType = useCallback(updateFilterField('operationType'), []);
  const updateFileState = useCallback(updateFilterField('fileState'), []);
  const updateOperationDateFrom = useCallback(updateFilterField('operationDateFrom'), []);
  const updateOperationDateTo = useCallback((value: FileOperationsFiltersModel['operationDateTo']) =>
    dispatch(updateFilter({ operationDateTo: getEndOfDay(value) }))
    , []);

  const fileTypesOptions = useMemo(getFileTypesOptions, []);
  const operationTypesOptions = useMemo(getFileOperationTypesOptions, []);
  const fileStatesOptions = useMemo(getFileStatesOptions, []);

  return (
    <div className="file-operations-filters">
      <AppSelect
        label={Labels.fileType}
        value={fileType}
        showClearButton
        options={fileTypesOptions}
        onChange={updateFileType}
      />
      <AppSelect
        label={Labels.status}
        value={fileState}
        showClearButton
        options={fileStatesOptions}
        onChange={updateFileState}
      />
      <AppSelect
        label={Labels.operationType}
        value={operationType}
        showClearButton
        options={operationTypesOptions}
        onChange={updateOperationType}
      />
      <AppDatePicker
        label={`${Labels.operationDate} ${Labels.dateFrom}`}
        value={operationDateFrom}
        maxDate={operationDateTo}
        onChange={updateOperationDateFrom}
      />
      <AppDatePicker
        label={`${Labels.operationDate} ${Labels.dateTo}`}
        value={operationDateTo}
        minDate={operationDateFrom}
        onChange={updateOperationDateTo}
      />
    </div>
  );
};

export const FileOperationsFilters = memo(FileOperationsFiltersInner);
