import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import { ScanningTableColumns } from '@/shared/scanning-view/models/scanning-table-columns';

const receiptCardPositionsTableColumnsToDtoMap: Record<ScanningTableColumns, keyof ScanningInfo> = {
  [ScanningTableColumns.Sgtin]: 'identifier',
  [ScanningTableColumns.Name]: 'medicineName',
  [ScanningTableColumns.Producer]: 'producer',
  [ScanningTableColumns.Gtin]: 'gtin',
  [ScanningTableColumns.Series]: 'seriesNumber',
  [ScanningTableColumns.Status]: 'productAdditionalInfoStatus',
};

export const convertScanningColumnFieldToDto = (columnField: ScanningTableColumns) => receiptCardPositionsTableColumnsToDtoMap[columnField];
