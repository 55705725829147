import { ErrorCode } from '@/common/models/error-code';
import { loginRoutePath } from '@/modules/auth/routes/routes';
import { removeUserLoggedIn } from '@/modules/auth/store/auth-state';
import { setIsOpenCertificatesListDialog } from '@/modules/portal/store/header';
import router from '@/router';
import { store } from '@/store';
import { AxiosError, AxiosResponse } from 'axios';

export const errorResponseHandler = (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (error.code === ErrorCode.MDLP_TOKEN_EXPIRED) {
      store.dispatch(setIsOpenCertificatesListDialog(true));
      return;
    }

    store.dispatch(removeUserLoggedIn());
    return router.navigate(loginRoutePath);
  }
  return Promise.reject(error);
};

export const successResponseHandler = (response: AxiosResponse) => {
  return response;
};
