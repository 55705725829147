import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { NomenclatureMdlpComparisonForm } from '@/modules/references/components/nomenclature-mdlp-comparison-form/NomenclatureMdlpComparisonForm';
import { resetState, setIsDialogOpened } from '@/modules/references/store/nomenclature-mdlp-comparison';
import { getMdlpProduct } from '@/modules/references/store/nomenclature-mdlp-comparison/async-thunks';
import { NomenclatureMdlpComparisonSelectors } from '@/modules/references/store/nomenclature-mdlp-comparison/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './nomenclature-mdlp-comparison-dialog.scss';

export const NomenclatureMdlpComparisonDialog = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { setDispatchResult, abortRequest } = useAsyncDispatchAborting();

  const isDialogOpened = useSelector(NomenclatureMdlpComparisonSelectors.selectIsDialogOpened);
  const isGtinFound = useSelector(NomenclatureMdlpComparisonSelectors.selectNomenclatureIsGtinFound);

  const closeDialog = useCallback(() => {
    dispatch(setIsDialogOpened(false));
  }, []);

  const resetInnerState = () => {
    // timeout delays resetting state until the closing dialog animation ends
    setTimeout(() => dispatch(resetState()), AppDialogTransitionDuration);
    abortRequest();
  };

  useEffect(() => {
    if (isDialogOpened) {
      if (isGtinFound) {
        setDispatchResult(asyncDispatch(getMdlpProduct));
      }
    } else {
      resetInnerState();
    }
  }, [isDialogOpened]);

  return (
    <AppDialog
      classes={{ dialog: 'nomenclature-mdlp-comparison-dialog' }}
      open={isDialogOpened}
      onClose={closeDialog}
    >
      <NomenclatureMdlpComparisonForm />
    </AppDialog>
  );
};
