import { AppSelect } from '@/common/components/app-select/AppSelect';
import { OrganizationInfoBlock } from '@/modules/organization-profile/components/organization-info-block/OrganizationInfoBlock';
import { OrganizationPlaceType, getOrganizationPlaceTitle, getOrganizationPlacesTypesOptions } from '@/modules/organization-profile/models/organization-place-type';
import React, { FC, memo, useMemo } from 'react';

interface OrganizationPlaceCardTypeProps {
  editable: boolean;
  value: OrganizationPlaceType | null;
  selectTypeClassName: string;
  onChange?: (value: OrganizationPlaceType) => void;
}

const OrganizationPlaceCardTypeInner: FC<OrganizationPlaceCardTypeProps> = ({ editable = false, value, selectTypeClassName, onChange }) => {
  const organizationPlacesTypesOptions = useMemo(getOrganizationPlacesTypesOptions, []);
  const organizationPlaceTitle = useMemo(() => getOrganizationPlaceTitle(value), []);

  return (
    editable ?
      <AppSelect
        value={value}
        options={organizationPlacesTypesOptions}
        className={selectTypeClassName}
        label="Тип"
        onChange={onChange}
      /> :
      <OrganizationInfoBlock
        title="Тип"
        value={organizationPlaceTitle}
        alignment="end"
      />
  );
};

export const OrganizationPlaceCardType = memo(OrganizationPlaceCardTypeInner);
