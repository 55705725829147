import { dateTimeFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getShortFioFromFullName } from '@/common/utils/common/common-user-data-utils';
import { formatDate } from '@/common/utils/common/date-utils';
import { sortDates } from '@/common/utils/sorting/sorting-functions';
import { InteractionLogNotificationHistoryTableColumns } from '@/modules/interaction-log/models/interaction-log-notification-history/interaction-log-notification-history-table-columns';
import { NotificationHistory } from '@/modules/interaction-log/models/interaction-log-notification-history/notification-history';
import { getInteractionStatusLabel } from '@/modules/interaction-log/models/interaction-status';
import { useMemo } from 'react';

export function useInteractionLogNotificationHistoryTableSettings() {

  const columns = useMemo<AppTableColumnDefType<NotificationHistory>[]>(
    () => [
      {
        id: InteractionLogNotificationHistoryTableColumns.Date,
        header: Labels.operationDate,
        accessorFn: (row) => formatDate(row.eventDate, dateTimeFormat),
        sortingFn: (row1, row2) => sortDates(row1.original.eventDate, row2.original.eventDate),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: InteractionLogNotificationHistoryTableColumns.Status,
        header: Labels.status,
        accessorFn: (row) => getInteractionStatusLabel(row.eventState),
      },
      {
        id: InteractionLogNotificationHistoryTableColumns.User,
        header: Labels.user,
        accessorFn: (row) => getShortFioFromFullName(row.userFullName),
      }
    ], []);

  return {
    columns
  };
}
