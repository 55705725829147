import { MutableRefObject, useEffect, useState } from 'react';

interface ContentOverflowCheckHookParams {
  contentRef: MutableRefObject<HTMLElement | undefined>;
}

export function useContentOverflowCheck({ contentRef }: ContentOverflowCheckHookParams) {
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollWidth, clientWidth, scrollHeight, clientHeight } = contentRef.current;
      const hasOverflow = scrollWidth > clientWidth || scrollHeight > clientHeight;
      setHasOverflow(hasOverflow);
    }
  }, [contentRef]);

  return {
    hasOverflow,
  }
}
