import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { defaultDocumentCardHistoryTableConfig } from '@/shared/document/constants/document-card-history-table-config';
import { DocumentCardHistory } from '@/shared/document/models/document-card/history/document-card-history';
import { DocumentsCardHistoryApi } from '@/shared/document/store/document-card/history/types';
import { CaseReducerActions, Draft, SliceCaseReducers, createSlice } from '@reduxjs/toolkit';

export interface DocumentCardHistoryState {
  tableConfig: SavedTableConfigState;
  history: DocumentCardHistory[];
  isLoadingHistory: boolean;
}

interface DocumentCardHistoryCaseReducers extends SliceCaseReducers<DocumentCardHistoryState> {
  resetState: (state: Draft<DocumentCardHistoryState>) => void;
}

export type DocumentCardHistoryActions = CaseReducerActions<DocumentCardHistoryCaseReducers, string>;

const notClearedFields: Set<keyof DocumentCardHistoryState> = new Set(['tableConfig']);

interface CreateDocumentCardHistorySliceParams {
  moduleName: string;
  savedTableConfigName: string;
  api: DocumentsCardHistoryApi;
}

export const createDocumentCardHistorySlice = ({ moduleName, savedTableConfigName, api }: CreateDocumentCardHistorySliceParams) => {
  const documentCardHistoryTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: defaultDocumentCardHistoryTableConfig,
  };

  const documentCardHistoryTableConfigSlice = createSavedTableConfigSlice(moduleName, documentCardHistoryTableConfigState);

  const initialState: DocumentCardHistoryState = {
    tableConfig: documentCardHistoryTableConfigSlice.getInitialState(),
    history: [],
    isLoadingHistory: false
  };

  const rootSlice = createSlice<DocumentCardHistoryState, DocumentCardHistoryCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      resetState: (state) => {
        Object.keys(state)
          .forEach((key: keyof DocumentCardHistoryState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getAllHistory.pending, (state) => {
          state.isLoadingHistory = true;
        })
        .addCase(api.getAllHistory.fulfilled, (state, action) => {
          state.isLoadingHistory = false;
          state.history = action.payload;
        })
        .addCase(api.getAllHistory.rejected, (state) => {
          state.isLoadingHistory = false;
        })
        .addMatcher(
          (action) => action.type.startsWith(documentCardHistoryTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = documentCardHistoryTableConfigSlice.reducer(state.tableConfig, action);
          }
        );
    }
  });

  return {
    rootSlice,
    documentCardHistoryTableConfigSlice,
  };
};
