import { favoritesModuleName } from '@/modules/favorites/constants/favorites-module-name';
import { SharedFavoritesRequests } from '@/shared/favorites/requests/shared-favorites-requests';
import { SharedFavoritesSelectors } from '@/shared/favorites/store/selectors';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const addToFavorite = createAsyncThunkWrapper<
  void,
  string
>(
  `${favoritesModuleName}/addToFavorite`,
  async (docUuid) => { await SharedFavoritesRequests.addToFavorite(docUuid); },
  { condition: asyncThunkConditionOption((state, docUuid) => SharedFavoritesSelectors.selectIsChangingInProgress(state, docUuid)) },
);

export const deleteFromFavorite = createAsyncThunkWrapper<
  void,
  string
>(
  `${favoritesModuleName}/deleteFromFavorite`,
  async (docUuid) => { await SharedFavoritesRequests.deleteFromFavorite(docUuid); },
  { condition: asyncThunkConditionOption((state, docUuid) => SharedFavoritesSelectors.selectIsChangingInProgress(state, docUuid)) },
);
