import { FavoriteDocumentWithIndicator } from '@/modules/favorites/models/favorite-document-with-indicator';
import { patchItemByFindIndex } from '@/modules/favorites/store';
import { FavoriteButtonCommon } from '@/shared/favorites/components/favorite-button-common/FavoriteButtonCommon';
import { useFavoriteClick } from '@/shared/favorites/hooks/use-favorite-click';
import { usePatchFavoriteItem } from '@/shared/favorites/hooks/use-patch-favorite-item';
import { SharedFavoritesSelectors } from '@/shared/favorites/store/selectors';
import { RootState } from '@/store';
import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

interface FavoriteButtonProps {
  favoriteDocument: FavoriteDocumentWithIndicator;
}

const FavoriteButtonInner: FC<FavoriteButtonProps> = ({ favoriteDocument }) => {
  const { docUuid, isFavorite } = favoriteDocument;

  const isChangingInProgress = useSelector((state: RootState) => SharedFavoritesSelectors.selectIsChangingInProgress(state, docUuid));

  const { patchFavoriteItem } = usePatchFavoriteItem({ patchItemByFindIndex });

  const { onFavoriteClick } = useFavoriteClick();

  const onClick = useCallback(() => {
    const updateFavoriteItem = () => patchFavoriteItem(favoriteDocument, !isFavorite);
    onFavoriteClick({ docUuid, isFavorite, updateFavoriteItem });
  }, [favoriteDocument]);

  const notClickable = isChangingInProgress;

  return (
    <FavoriteButtonCommon
      isFavorite={isFavorite}
      notClickable={notClickable}
      onClick={onClick}
    />
  );
};

export const FavoriteButton = memo(FavoriteButtonInner);
