import { AuthStateSelectors } from '@/modules/auth/store/auth-state/selectors';
import { notFoundRoutePath, portalNotFoundRoutePath } from '@/modules/portal/routes/routes';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const NotFoundPagesNavigation = () => {
  const isLoggedIn = useSelector(AuthStateSelectors.selectIsLoggedIn);
  const replaceUrl = useMemo(() => (isLoggedIn ? portalNotFoundRoutePath : notFoundRoutePath), [isLoggedIn]);

  return <Navigate to={replaceUrl} replace />;
};
