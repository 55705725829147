import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { defaultNomenclatureTableConfig } from '@/modules/references/constants/nomenclature-table-config';
import { nomenclatureModuleName } from '@/modules/references/constants/references-module-names';
import { NomenclatureFilter } from '@/modules/references/models/nomenclature/nomenclature-filter';
import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';
import { getAllNomenclature, uploadNomenclatureFile } from '@/modules/references/store/nomenclature/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NomenclatureState {
  isUploadingInProcess: boolean;
  search: SearchingState;
  filterConfig: FilterConfigState<NomenclatureFilter>;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<NomenclatureItem>;
}

const notClearedFields: Set<keyof NomenclatureState> = new Set(['tableConfig']);

const nomenclatureTableConfigState: SavedTableConfigState = {
  tableConfigName: 'nomenclature-table',
  config: defaultNomenclatureTableConfig,
};

const nomenclatureSearchingSlice = createSearchingSlice(nomenclatureModuleName);
const nomenclatureTableConfigSlice = createSavedTableConfigSlice(nomenclatureModuleName, nomenclatureTableConfigState);
const nomenclaturePageableDataSlice = createPageableDataSlice<NomenclatureItem>(nomenclatureModuleName);
const nomenclatureFilterConfigSlice = createFilterConfigSlice(nomenclatureModuleName);

const initialState: NomenclatureState = {
  isUploadingInProcess: false,
  search: nomenclatureSearchingSlice.getInitialState(),
  filterConfig: nomenclatureFilterConfigSlice.getInitialState(),
  tableConfig: nomenclatureTableConfigSlice.getInitialState(),
  pageableData: nomenclaturePageableDataSlice.getInitialState(),
};

const nomenclatureSlice = createSlice({
  name: nomenclatureModuleName,
  initialState,
  reducers: {
    updateNomenclature(state: NomenclatureState, action: PayloadAction<NomenclatureItem>) {
      const updatedNomenclature = action.payload;
      const { patchItemByFindIndex } = nomenclaturePageableDataSlice.caseReducers;
      const findIndexCallback = (item: NomenclatureItem) => item.gtin === updatedNomenclature.gtin;
      patchItemByFindIndex(state.pageableData, getReducerAction(patchItemByFindIndex.name, { item: updatedNomenclature, findIndexCallback }));
    },
    resetState(state: NomenclatureState) {
      Object.keys(state)
        .forEach((key: keyof NomenclatureState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNomenclature.pending, (state) => {
        const { updateLoadingStatus } = nomenclaturePageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllNomenclature.fulfilled, (state) => {
        const { updateLoadingStatus } = nomenclaturePageableDataSlice.caseReducers;
        const { updateStateAfterGettingData } = nomenclatureFilterConfigSlice.caseReducers;
        const { saveAppliedSearchString } = nomenclatureSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        updateStateAfterGettingData(state.filterConfig);
        saveAppliedSearchString(state.search);
      })
      .addCase(getAllNomenclature.rejected, (state) => {
        const { updateLoadingStatus } = nomenclaturePageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addCase(uploadNomenclatureFile.pending, (state) => {
        state.isUploadingInProcess = true;
      })
      .addCase(uploadNomenclatureFile.fulfilled, (state) => {
        state.isUploadingInProcess = false;
      })
      .addCase(uploadNomenclatureFile.rejected, (state) => {
        state.isUploadingInProcess = false;
      })
      .addMatcher(
        (action) => action.type.startsWith(nomenclatureSearchingSlice.name),
        (state, action) => {
          state.search = nomenclatureSearchingSlice.reducer(state.search, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(nomenclatureTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = nomenclatureTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(nomenclaturePageableDataSlice.name),
        (state, action) => {
          state.pageableData = nomenclaturePageableDataSlice.reducer(state.pageableData, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(nomenclatureFilterConfigSlice.name),
        (state, action) => {
          state.filterConfig = nomenclatureFilterConfigSlice.reducer(state.filterConfig, action);
        }
      );
  },
});

export const {
  updateNomenclature,
  resetState,
} = nomenclatureSlice.actions;

export const {
  updateSearchString,
} = nomenclatureSearchingSlice.actions;

export const {
  updateFilter,
  changeFilterOpenState
} = nomenclatureFilterConfigSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = nomenclatureTableConfigSlice.actions;

export const nomenclaturePageableDataActions = nomenclaturePageableDataSlice.actions;
export const nomenclatureFilterConfigActions = nomenclatureFilterConfigSlice.actions;

export default nomenclatureSlice.reducer;
