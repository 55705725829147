import ExportIcon from '@/assets/icons/controls/export.svg?react';
import FilterIcon from '@/assets/icons/controls/filter.svg?react';
import ImportIcon from '@/assets/icons/controls/import.svg?react';
import PlusIcon from '@/assets/icons/controls/rect_plus.svg?react';
import { AppButton } from '@/common/components/app-button/AppButton';
import { AppFileInput } from '@/common/components/app-file-input/AppFileInput';
import { AppSearchBar } from '@/common/components/app-search-bar/AppSearchBar';
import { AppFilterPanel, AppFilterPanelClasses } from '@/common/components/app-table/components/app-filter-panel/AppFilterPanel';
import { SyncIcon } from '@/common/components/icons/sync-icon/SyncIcon';
import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import './app-table-top-toolbar.scss';

interface SearchInputProps {
  searchClassName?: string;
  searchPlaceholder?: string;
  showSearchInput?: boolean;
  disableApplySearchButton?: boolean;
  onSearchValueInput?: (search: string) => void;
  onApplySearchClick?: () => void;
  onClearSearchClick?: () => void;
}

interface FilterPanelProps {
  filterClasses?: AppFilterPanelClasses;
  showFilterButton?: boolean;
  filterPanel?: React.ReactNode;
  customFilterActions?: React.ReactNode;
  isPanelOpen?: boolean;
  selectedFiltersCount?: number;
  disableApplyFilterButton?: boolean;
  disableClearFilterButton?: boolean;
  onFilterClick?: () => void;
  onApplyFiltersClick?: () => void;
  onClearFiltersClick?: () => void;
}

interface AddButtonProps {
  showAddButton?: boolean;
  onAddClick?: () => void;
}

interface ImportButtonProps {
  showImportButton?: boolean;
  importButtonDisabled?: boolean;
  acceptedImportTypes?: string;
  importFileMaxSize?: number;
  onImportFileUploaded?: (file: File) => void;
  onImportFileMaxSizeExceed?: () => void;
}

interface ExportButtonProps {
  showExportButton?: boolean;
  exportButtonDisabled?: boolean;
  exportedItemsCount?: number;
  onExportClick?: () => void;
}

interface RefreshButtonProps {
  showRefreshButton: boolean;
  notClickableRefreshButton: boolean;
  onRefreshClick?: () => void;
}

export interface AppTableTopToolbarProps {
  search?: SearchInputProps;
  filter?: FilterPanelProps;
  addButton?: AddButtonProps;
  importButton?: ImportButtonProps;
  exportButton?: ExportButtonProps;
  refreshButton?: RefreshButtonProps;
}

const AppTableTopToolbarInner = forwardRef<HTMLDivElement, AppTableTopToolbarProps>(({
  search: {
    searchClassName,
    showSearchInput,
    searchPlaceholder,
    disableApplySearchButton,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick
  } = {},
  filter: {
    filterClasses,
    showFilterButton,
    filterPanel,
    customFilterActions,
    isPanelOpen,
    selectedFiltersCount,
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick
  } = {},
  addButton: {
    showAddButton,
    onAddClick
  } = {},
  importButton: {
    showImportButton,
    importButtonDisabled,
    acceptedImportTypes,
    importFileMaxSize,
    onImportFileUploaded,
    onImportFileMaxSizeExceed
  } = {},
  exportButton: {
    showExportButton,
    exportButtonDisabled,
    exportedItemsCount,
    onExportClick
  } = {},
  refreshButton: {
    showRefreshButton,
    notClickableRefreshButton,
    onRefreshClick
  } = {},
}, ref) => {
  const [searchString, setSearchString] = useState('');

  const onSearchValueInputInner = useCallback((value: string) => {
    setSearchString(value);
    onSearchValueInput(value);
  }, []);

  const filterPanelLabel = useMemo(() =>
    `Фильтр${selectedFiltersCount ? ` +${selectedFiltersCount}` : ''}`
    , [selectedFiltersCount]);

  const exportButtonLabel = useMemo(() =>
    `Экспорт${exportedItemsCount ? ` (${exportedItemsCount})` : ''}`
    , [exportedItemsCount]);

  return (
    <div
      className="app-table-top-toolbar-container"
      ref={ref}
    >
      <div className="app-table-top-toolbar-controls-row">
        <div className="buttons-wrapper left-part">
          {showFilterButton && <AppButton
            className={showAddButton || showRefreshButton ? '' : 'align-left'}
            label={filterPanelLabel}
            startIcon={<FilterIcon />}
            variant="outlined"
            active={isPanelOpen || !!selectedFiltersCount}
            onClick={onFilterClick}
          />}
          {showRefreshButton && <AppButton
            className={showFilterButton || showAddButton ? '' : 'align-right'}
            label="Обновить"
            startIcon={
              <SyncIcon
                notClickable={notClickableRefreshButton}
                noShadow
              />
            }
            variant="outlined"
            notClickable={notClickableRefreshButton}
            onClick={onRefreshClick}
          />}
          {showAddButton && <AppButton
            className={showFilterButton || showRefreshButton ? '' : 'align-right'}
            label="Добавить"
            startIcon={<PlusIcon />}
            variant="outlined"
            onClick={onAddClick}
          />}
        </div>
        {showSearchInput && <AppSearchBar
          className={`search-field ${searchClassName ?? ''}`}
          value={searchString}
          placeholder={searchPlaceholder}
          searchButtonDisabled={disableApplySearchButton}
          onSearchClick={onApplySearchClick}
          onClearClick={onClearSearchClick}
          onInput={onSearchValueInputInner}
        />}
        <div className="buttons-wrapper right-part">
          {showImportButton &&
            <AppFileInput
              renderer={(open) => <AppButton
                label="Импорт"
                startIcon={<ImportIcon />}
                variant="outlined"
                disabled={importButtonDisabled}
                onClick={open}
              />}
              className={showExportButton ? '' : 'align-left'}
              accept={acceptedImportTypes}
              maxSize={importFileMaxSize}
              onFilesUploaded={onImportFileUploaded}
              onMaxSizeExceed={onImportFileMaxSizeExceed}
            />
          }
          {showExportButton && <AppButton
            className={showImportButton ? '' : 'align-right'}
            label={exportButtonLabel}
            startIcon={<ExportIcon />}
            variant="outlined"
            disabled={exportButtonDisabled}
            onClick={onExportClick}
          />}
          {customFilterActions}
        </div>
      </div>
      {showFilterButton &&
        <AppFilterPanel
          classes={filterClasses}
          isOpen={isPanelOpen}
          disableApplyButton={disableApplyFilterButton}
          disableClearButton={disableClearFilterButton}
          filterPanel={filterPanel}
          onClearClick={onClearFiltersClick}
          onExpandClick={onFilterClick}
          onApplyClick={onApplyFiltersClick}
        />
      }
    </div>
  );
});

AppTableTopToolbarInner.displayName = 'AppTableTopToolbar';

export const AppTableTopToolbar = memo(AppTableTopToolbarInner);
