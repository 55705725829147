import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { LegalEntitiesActivityPlacesTable } from '@/modules/references/components/legal-entities-activity-places-table/LegalEntitiesActivityPlacesTable';
import { resetState, setIsDialogOpened } from '@/modules/references/store/legal-entities-activity-places';
import { getAllLegalEntitiesActivityPlaces } from '@/modules/references/store/legal-entities-activity-places/async-thunks';
import { LegalEntitiesActivityPlacesSelectors } from '@/modules/references/store/legal-entities-activity-places/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './legal-entities-activity-places-dialog.scss';

export const LegalEntitiesActivityPlacesDialog = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { setDispatchResult, abortRequest } = useAsyncDispatchAborting();

  const isDialogOpened = useSelector(LegalEntitiesActivityPlacesSelectors.selectIsDialogOpened);
  const legalEntityFullName = useSelector(LegalEntitiesActivityPlacesSelectors.selectLegalEntityFullName);

  const closeDialog = useCallback(() => {
    dispatch(setIsDialogOpened(false));
  }, []);

  const resetInnerState = () => {
    // timeout delays resetting state until the closing dialog animation ends
    setTimeout(() => dispatch(resetState()), AppDialogTransitionDuration);
    abortRequest();
  };

  useEffect(() => {
    if (isDialogOpened) {
      setDispatchResult(asyncDispatch(getAllLegalEntitiesActivityPlaces));
    } else {
      resetInnerState();
    }
  }, [isDialogOpened]);

  return (
    <AppDialog
      header="Места деятельности"
      subHeader={legalEntityFullName}
      classes={{ dialog: 'legal-entities-activity-places-dialog' }}
      open={isDialogOpened}
      onClose={closeDialog}
    >
      <LegalEntitiesActivityPlacesTable />
    </AppDialog>
  );
};
