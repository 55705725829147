import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { HeaderWithLoader } from '@/common/components/custom/header-with-loader/HeaderWithLoader';
import { PasswordConfirmation } from '@/modules/auth/components/password-confirmation/PasswordConfirmation';
import { initialPasswordsState } from '@/modules/auth/constants/initial-passwords-state';
import { usePasswordInput } from '@/modules/auth/hooks/use-password-input';
import { FormPasswords } from '@/modules/auth/models/form-passwords';
import { useChangePasswordsValidation } from '@/modules/portal/hooks/use-change-passwords-validation';
import { PasswordChangeBody } from '@/modules/portal/models/password-change/password-change-body';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import './change-password-dialog.scss';

interface ChangePasswordDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onPasswordChange: (body: PasswordChangeBody) => void;
}

const ChangePasswordDialogInner: FC<ChangePasswordDialogProps> = ({ open, isLoading, onClose, onPasswordChange }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [passwords, setPasswords] = useState<FormPasswords>(initialPasswordsState);

  const onChangeOldPassword = useCallback((value: string) => setOldPassword(value), []);
  const onPasswordsChange = useCallback((passwords: FormPasswords) => setPasswords(passwords), []);

  useEffect(() => {
    if (!open) {
      setOldPassword('');
      resetValidationResult();
    }
  }, [open]);

  const {
    passwordMaxLength,
    validationResult,
    validateForm,
    resetValidationResult,
  } = useChangePasswordsValidation({ ...passwords, oldPassword });

  const onSavePassword = useCallback(() => {
    if (validateForm()) {
      onPasswordChange({ oldPassword, newPassword: passwords.password });
    }
  }, [oldPassword, passwords]);

  const oldPasswordInputContext = usePasswordInput();

  return (
    <AppDialog
      open={open}
      classes={{ dialog: 'change-password-dialog' }}
      onClose={onClose}
      header={<HeaderWithLoader
        title="Смена пароля"
        showLoader={isLoading}
      />}
      footer={
        <div className="change-password-footer">
          <AppButton
            label="Сохранить"
            onClick={onSavePassword}
          />
          <AppLink
            text="Отмена"
            onClick={onClose}
          />
        </div>
      }
    >
      <AppInput
        label="Старый пароль"
        autoComplete="new-password"
        value={oldPassword}
        maxLength={passwordMaxLength}
        type={oldPasswordInputContext.passwordInputType}
        iconEnd={oldPasswordInputContext.passwordIconComponent}
        error={validationResult.oldPassword?.error}
        helperText={validationResult.oldPassword?.messages?.[0]}
        onValueChange={onChangeOldPassword}
      />

      <PasswordConfirmation
        isNewPassword
        passwords={passwords}
        passwordMaxLength={passwordMaxLength}
        validationResult={validationResult}
        onChangePasswords={onPasswordsChange}
      />
    </AppDialog>
  );
};

export const ChangePasswordDialog = memo(ChangePasswordDialogInner);
