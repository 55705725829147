import React, { FC, memo, ReactNode } from 'react';
import './document-card-info-button.scss';

interface DocumentCardInfoButtonProps {
  label: string;
  icon: ReactNode;
  onClick: () => void;
}

const DocumentCardInfoButtonInner: FC<DocumentCardInfoButtonProps> = ({ label, icon, onClick }) => (
  <div
    className="document-card-info-button-wrapper"
    onClick={onClick}
  >
    <div className="document-card-info-button">
      <div className="card-button-label">
        {label}
      </div>
      {icon}
    </div>
  </div>
);

export const DocumentCardInfoButton = memo(DocumentCardInfoButtonInner);