import React from 'react';
import './actions-icon.scss';

export const ActionsIcon = () => (
  <div className="actions-icon-circle">
    <div className="actions-icon-dot" />
    <div className="actions-icon-dot" />
    <div className="actions-icon-dot" />
  </div>
);
