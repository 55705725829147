import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { DocumentCardScanningPageProps } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardScanningActionsToolbarHookParams = Pick<PageableLoadingActions, 'loadFirstPage'> &
  Pick<DocumentCardScanningPageProps, 'documentCardScanningSelectors' | 'documentCardScanningApi'>;

export function useDocumentCardScanningDeleteButton({
  loadFirstPage,
  documentCardScanningSelectors,
  documentCardScanningApi
}: DocumentCardScanningActionsToolbarHookParams) {
  const { asyncDispatch } = useAsyncDispatch();

  const isDeletingInProcess = useSelector(documentCardScanningSelectors.selectIsDeletingInProcess);
  const rowsSelections = useSelector(documentCardScanningSelectors.selectRowsSelection);

  const selectedRowsCount = useMemo(() => (Object.keys(rowsSelections).length), [rowsSelections]);
  const deleteButtonDisabled = selectedRowsCount === 0 || isDeletingInProcess;

  const deleteButtonLabel = useMemo(() => (
    `Удалить${selectedRowsCount ? ` (${selectedRowsCount})` : ''}`
  ), [selectedRowsCount]);

  const onDeleteClick = useCallback(() => {
    asyncDispatch(
      () => documentCardScanningApi.deleteScannedItems(),
      loadFirstPage,
    );
  }, []);

  return {
    deleteButtonLabel,
    deleteButtonDisabled,
    onDeleteClick,
  };
}
