import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { favoritesModuleName } from '@/modules/favorites/constants/favorites-module-name';
import { defaultFavoritesTableConfig } from '@/modules/favorites/constants/favorites-table-config';
import { FavoriteDocumentWithIndicator } from '@/modules/favorites/models/favorite-document-with-indicator';
import { getAllFavoriteDocuments, updateFavoriteDocument } from '@/modules/favorites/store/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FavoritesState {
  tableConfig: SavedTableConfigState;
  search: SearchingState;
  pageableData: PageableDataState<FavoriteDocumentWithIndicator>;
  updatedRowId: string;
  isUpdatingInProcess: boolean;
}

const notClearedFields: Set<keyof FavoritesState> = new Set(['tableConfig']);

const favoritesTableConfigState: SavedTableConfigState = {
  tableConfigName: 'favorites-table',
  config: defaultFavoritesTableConfig,
};

const favoritesSearchingSlice = createSearchingSlice(favoritesModuleName);
const favoritesTableConfigSlice = createSavedTableConfigSlice(favoritesModuleName, favoritesTableConfigState);
const favoritesPageableDataSlice = createPageableDataSlice<FavoriteDocumentWithIndicator>(favoritesModuleName);

const initialState: FavoritesState = {
  search: favoritesSearchingSlice.getInitialState(),
  tableConfig: favoritesTableConfigSlice.getInitialState(),
  pageableData: favoritesPageableDataSlice.getInitialState(),
  updatedRowId: '',
  isUpdatingInProcess: false
};

const favoritesSlice = createSlice({
  name: favoritesModuleName,
  initialState,
  reducers: {
    setUpdatedRowId(state, action: PayloadAction<string>) {
      state.updatedRowId = action.payload;
    },
    setIsUpdatingInProgress(state, action: PayloadAction<boolean>) {
      state.isUpdatingInProcess = action.payload;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof FavoritesState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFavoriteDocuments.pending, (state) => {
        const { updateLoadingStatus } = favoritesPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllFavoriteDocuments.fulfilled, (state) => {
        const { updateLoadingStatus } = favoritesPageableDataSlice.caseReducers;
        const { saveAppliedSearchString } = favoritesSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        saveAppliedSearchString(state.search);
      })
      .addCase(getAllFavoriteDocuments.rejected, (state) => {
        const { updateLoadingStatus } = favoritesPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addCase(updateFavoriteDocument.pending, (state) => {
        state.isUpdatingInProcess = true;
      })
      .addMatcher(
        (action) => action.type.startsWith(favoritesTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = favoritesTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(favoritesPageableDataSlice.name),
        (state, action) => {
          state.pageableData = favoritesPageableDataSlice.reducer(state.pageableData, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(favoritesSearchingSlice.name),
        (state, action) => {
          state.search = favoritesSearchingSlice.reducer(state.search, action);
        }
      );
  }
});

export const {
  setUpdatedRowId,
  setIsUpdatingInProgress,
  resetState,
} = favoritesSlice.actions;

export const {
  updateSearchString,
} = favoritesSearchingSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = favoritesTableConfigSlice.actions;

export const {
  patchItemByFindIndex,
  patchItemByIndex
} = favoritesPageableDataSlice.actions;

export const favoritesPageableDataActions = favoritesPageableDataSlice.actions;

export default favoritesSlice.reducer;
