import { AppTableColumnHeaderWithHint } from '@/common/components/app-table/components/app-table-column-header-with-hint/AppTableColumnHeaderWithHint';
import { dateFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType, AppTableRowType } from '@/common/models/app-table/app-table-types';
import { formatDate } from '@/common/utils/common/date-utils';
import { formatCurrency } from '@/common/utils/common/formatting-utils';
import { getLogicalValueLabel } from '@/common/utils/common/types-utils';
import { sortDates } from '@/common/utils/sorting/sorting-functions';
import { DocumentCardBox } from '@/shared/document/models/document-card/boxes/document-card-box';
import { DocumentCardBoxProcessingStatus, getDocumentCardBoxProcessingStatusLabels } from '@/shared/document/models/document-card/boxes/document-card-box-processing-status';
import { DocumentCardBoxesTableColumns } from '@/shared/document/models/document-card/boxes/document-card-boxes-table-columns';
import { convertDocumentCardBoxColumnFieldToDto } from '@/shared/document/models/document-card/boxes/document-card-boxes-table-columns-converters';
import { DocumentProcessingWay } from '@/shared/document/models/document-processing-way';
import React, { useMemo } from 'react';

export function useDocumentCardBoxesTableSettings(processingWay: DocumentProcessingWay) {

  const columns = useMemo(
    () => {
      let userColumns: AppTableColumnDefType<DocumentCardBox>[] = [
        {
          id: DocumentCardBoxesTableColumns.Sscc,
          header: Labels.sscc,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Sscc),
        },
        {
          id: DocumentCardBoxesTableColumns.Code,
          header: Labels.code,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Code),
        },
        {
          id: DocumentCardBoxesTableColumns.Name,
          header: Labels.nameHeader,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Name),
        },
        {
          id: DocumentCardBoxesTableColumns.Producer,
          header: Labels.producer,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Producer),
        },
        {
          id: DocumentCardBoxesTableColumns.Vital,
          header: Labels.vital,
          accessorFn: (row) => {
            const modelField = convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Vital);
            const isVital = row[modelField] as boolean;
            return getLogicalValueLabel(isVital);
          },
        },
        {
          id: DocumentCardBoxesTableColumns.Gtin,
          header: Labels.gtin,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Gtin),
        },
        {
          id: DocumentCardBoxesTableColumns.Series,
          header: Labels.series,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Series),
        },
        {
          id: DocumentCardBoxesTableColumns.ExpirationDate,
          header: Labels.expirationDate,
          accessorFn: (row) => formatDate(row.expirationDate, dateFormat),
          sortingFn: (row1, row2) => sortDates(row1.original.expirationDate, row2.original.expirationDate),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: DocumentCardBoxesTableColumns.PriceWithVat,
          header: Labels.priceWithVat,
          accessorFn: (row) => formatCurrency(row.priceWithVat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: DocumentCardBoxesTableColumns.Vat,
          header: Labels.vat,
          accessorFn: (row) => formatCurrency(row.vat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: DocumentCardBoxesTableColumns.TotalPriceWithVat,
          header: Labels.totalPriceWithVat,
          accessorFn: (row) => formatCurrency(row.totalPriceWithVat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: DocumentCardBoxesTableColumns.VatRate,
          header: Labels.vatRate,
          accessorFn: (row) => formatCurrency(row.vatRate)
        },
        {
          id: DocumentCardBoxesTableColumns.Amount,
          header: Labels.amount,
          accessorKey: convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.Amount),
        },
        {
          id: DocumentCardBoxesTableColumns.ProcessingStatus,
          header: '',
          headerSettingsName: Labels.processingStatus,
          Header: () =>
            <AppTableColumnHeaderWithHint
              header={Labels.processingStatus}
              hint={Labels.processingStatusHint}
            />,
          accessorFn: (row) => getDocumentCardBoxProcessingStatusLabels(row.processingStatus),
        }
      ];

      if (processingWay === DocumentProcessingWay.Fill) {
        userColumns = userColumns.concat([
          {
            id: DocumentCardBoxesTableColumns.IsProductInOrder,
            header: '',
            headerSettingsName: Labels.isProductInOrder,
            Header: () =>
              <AppTableColumnHeaderWithHint
                header={Labels.isProductInOrder}
                hint={Labels.isProductInOrderHint}
              />,
            accessorFn: (row) => {
              const modelField = convertDocumentCardBoxColumnFieldToDto(DocumentCardBoxesTableColumns.IsProductInOrder);
              const isProductInOrder = row[modelField] as boolean;
              return getLogicalValueLabel(isProductInOrder);
            },
          }
        ]);
      }

      return userColumns;

    }, [processingWay]);

  const getCellRowClassName = (data: { row: AppTableRowType<DocumentCardBox>; }) => {
    const documentBox = data.row.original;
    if (documentBox.processingStatus === DocumentCardBoxProcessingStatus.Awaiting) {
      return 'document-box-in-awaiting-status';
    }
    if (documentBox.processingStatus === DocumentCardBoxProcessingStatus.Error || !documentBox.isProductPresentInOrder) {
      return 'document-box-in-error-status';
    }
  };

  const tableClasses = useMemo(() => ({
    cell: getCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses
  };
}
