import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { AppTableRowSelectionType } from '@/common/models/app-table/app-table-types';
import { AsyncThunkConfig } from '@/store';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useCallback } from 'react';

interface FileExportHookParams {
  rowsSelection: AppTableRowSelectionType;
  isExportInProcess: boolean;
  exportAction: AsyncThunk<void, undefined, AsyncThunkConfig>;
  openExportNotificationDialog: () => void;
}

export function useFileExport({ rowsSelection, isExportInProcess, exportAction, openExportNotificationDialog }: FileExportHookParams) {
  const { asyncDispatch } = useAsyncDispatch();

  const exportedItemsCount = Object.keys(rowsSelection).length;
  const exportButtonDisabled = exportedItemsCount === 0 || isExportInProcess;

  const onExportClick = useCallback(() => {
    return asyncDispatch(
      exportAction,
      openExportNotificationDialog,
    );
  }, []);

  return {
    exportedItemsCount,
    exportButtonDisabled,
    onExportClick,
  };
}
