import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import { documentNoteMaxLength } from '@/common/utils/client-validation/string-lengths';
import React, { FC, memo } from 'react';
import './document-card-note-adding.scss';

interface DocumentCardNoteAddingProps {
  noteText: string;
  isAddingNoteDisabled: boolean;
  onNoteInput: (value: string) => void;
  onAddNewNoteClick: () => void;
}

const DocumentCardNoteAddingInner: FC<DocumentCardNoteAddingProps> = ({ noteText, isAddingNoteDisabled, onNoteInput, onAddNewNoteClick }) => {
  return (
    <div className="adding-new-note-container">
      <AppInput
        type={AppInputType.Textarea}
        value={noteText}
        rows={5}
        maxLength={documentNoteMaxLength}
        onInput={onNoteInput}
      />
      <AppButton
        label="Добавить комментарий"
        className="add-note-button"
        disabled={isAddingNoteDisabled}
        onClick={onAddNewNoteClick}
      />
    </div>
  );
};

export const DocumentCardNoteAdding = memo(DocumentCardNoteAddingInner);
