import { interactionLogRoutePath } from '@/modules/interaction-log/routes/routes';
import { InteractionLogPage } from '@/modules/interaction-log/views/interaction-log-page/InteractionLogPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All interaсtion log routes.
 */
const routes: RouteObject[] = [
  {
    path: interactionLogRoutePath,
    element: <InteractionLogPage />,
  }
];

export default routes;
