import { DocumentCardHistory } from '@/shared/document/models/document-card/history/document-card-history';
import { DocumentCardHistoryRequests } from '@/shared/document/requests/document-card-history-requests';
import { DocumentCardHistorySelectors } from '@/shared/document/store/document-card/history/types';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardHistoryThunksCreationParams {
  moduleName: string;
  documentCardHistorySelectors: DocumentCardHistorySelectors;
}

export const createDocumentCardHistoryThunks = ({ moduleName, documentCardHistorySelectors }: DocumentCardHistoryThunksCreationParams) => {
  const getAllHistory = createAsyncThunkWrapper<
    DocumentCardHistory[],
    string
  >(`${moduleName}/getAllHistory`,
    (docUuid, { signal }) => {
      return DocumentCardHistoryRequests.getAllHistory(docUuid, signal)
        .then((res) => res.data.documentHistory);
    },
    {
      condition: asyncThunkConditionOption((state, documentId) => {
        const isLoading = documentCardHistorySelectors.selectIsLoadingHistory(state);
        return isLoading || !documentId;
      })
    },
  );

  return {
    getAllHistory,
  };
};
