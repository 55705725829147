import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { LegalEntitiesTableColumns } from '@/modules/references/models/legal-entities/legal-entities-table-columns';

const columnVisibility = {
  [LegalEntitiesTableColumns.Code]: true,
  [LegalEntitiesTableColumns.FullName]: true,
  [LegalEntitiesTableColumns.ShortName]: true,
  [LegalEntitiesTableColumns.Inn]: true,
  [LegalEntitiesTableColumns.Kpp]: true,
  [LegalEntitiesTableColumns.RegNumber]: true,
  [LegalEntitiesTableColumns.ActivityPlaces]: true,
  [LegalEntitiesTableColumns.Comment]: true,
};

const sorting = [
  {
    id: LegalEntitiesTableColumns.FullName,
    desc: false,
  }
];

const columnOrder = [
  LegalEntitiesTableColumns.Code,
  LegalEntitiesTableColumns.FullName,
  LegalEntitiesTableColumns.ShortName,
  LegalEntitiesTableColumns.Inn,
  LegalEntitiesTableColumns.Kpp,
  LegalEntitiesTableColumns.RegNumber,
  LegalEntitiesTableColumns.ActivityPlaces,
  LegalEntitiesTableColumns.Comment,
  AppTableActionColumnName,
];

const columnSizing = {
  [LegalEntitiesTableColumns.Code]: 60,
  [LegalEntitiesTableColumns.FullName]: 130,
  [LegalEntitiesTableColumns.ShortName]: 100,
  [LegalEntitiesTableColumns.Inn]: 60,
  [LegalEntitiesTableColumns.Kpp]: 60,
  [LegalEntitiesTableColumns.RegNumber]: 110,
  [LegalEntitiesTableColumns.ActivityPlaces]: 40,
  [LegalEntitiesTableColumns.Comment]: 120,
};

export const defaultLegalEntitiesTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
