import { Certificate } from '@/common/models/certificate';
import { certificatesModuleName } from '@/modules/certificates/constants/certificates-module-name';
import { getCertificates as getCertificatesFromCryptoPro } from '@/modules/certificates/utils/get-certificates';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getCertificates = createAsyncThunkWrapper<
  Certificate[],
  undefined
>(
  `${certificatesModuleName}/getCertificates`,
  () => getCertificatesFromCryptoPro(),
);
