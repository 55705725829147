import { Certificate } from '@/common/models/certificate';
import { CertificateListTableRow } from '@/modules/certificates/components/certificates-list-dialog/components/сertificate-list-table-row/CertificateListTableRow';
import React, { FC, memo } from 'react';
import './certificate-list-table.scss';

interface CertificateListTableProps {
  certificates: Certificate[];
  selectedCertificateThumbprint: string;
  onSelectCertificate: (certificate: Certificate) => void;
}

const CertificateListTableInner: FC<CertificateListTableProps> = (
  { certificates, selectedCertificateThumbprint, onSelectCertificate: onSelectCertificateProp }
) => (
  <div className="certificates-list-table">
    <table>
      <thead>
        <CertificateListTableRow />
      </thead>
      <tbody>
        {certificates.map((certificate) => (
          <CertificateListTableRow
            key={certificate.thumbprint}
            certificateInfo={certificate}
            isSelected={selectedCertificateThumbprint === certificate.thumbprint}
            onSelectCertificate={() => onSelectCertificateProp(certificate)}
          />
        )
        )}
      </tbody>
    </table>
  </div>
);

export const CertificateListTable = memo(CertificateListTableInner);