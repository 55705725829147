import { AppTableColumnHeaderWithHint } from '@/common/components/app-table/components/app-table-column-header-with-hint/AppTableColumnHeaderWithHint';
import { dateFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getDocumentVisibleColumns, VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { commentMaxLength } from '@/common/utils/client-validation/string-lengths';
import { formatDate } from '@/common/utils/common/date-utils';
import { formatCurrency } from '@/common/utils/common/formatting-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { getInteractionEventLabel } from '@/modules/interaction-log/models/interaction-event';
import { DocumentFavoriteButton } from '@/shared/document/components/document-favorite-button/DocumentFavoriteButton';
import { DocumentsTableProps } from '@/shared/document/components/documents-table/DocumentsTable';
import { getDocumentOrderTitle } from '@/shared/document/models/document-order';
import { getDocumentStatusTitle } from '@/shared/document/models/document-status';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { convertDocumentColumnFieldToDto } from '@/shared/document/models/documents/documents-table-columns-converters';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { getDocumentCellRowClassName } from '@/shared/document/utils/get-document-cell-row-class-name';
import React, { useCallback, useMemo } from 'react';

type DocumentsTableSettingsHookParams = Pick<DocumentsTableProps, 'documentsPageableDataActions'> & VisibleColumns<DocumentsTableColumns>;

export function useDocumentsTableSettings({ documentsPageableDataActions, visibleColumns }: DocumentsTableSettingsHookParams) {
  const searchPlaceholder = getSearchString([Labels.docNumber, Labels.comment]);

  const getFavoriteColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.IsFavorite,
    header: '',
    accessorFn: (row) => <DocumentFavoriteButton
      document={row}
      patchItemByFindIndex={documentsPageableDataActions.patchItemByFindIndex}
    />,
    enableColumnDragging: false,
    enableEditing: false,
    enableSorting: false,
    enableResizing: false,
    showInSettings: false,
    fixedWidth: true,
    muiTableBodyCellProps: {
      align: 'center',
    },
  });

  const getPartnerColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.Partner,
    header: Labels.partner,
    accessorKey: convertDocumentColumnFieldToDto(DocumentsTableColumns.Partner),
    enableEditing: false,
    enableSorting: true,
  });

  const getActivityPlaceColumns = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.ActivityPlace,
    header: Labels.activityPlaceShort,
    accessorKey: convertDocumentColumnFieldToDto(DocumentsTableColumns.ActivityPlace),
    enableEditing: false,
    enableSorting: true,
  });

  const getDocNumberColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.DocNumber,
    header: Labels.docNumber,
    accessorKey: convertDocumentColumnFieldToDto(DocumentsTableColumns.DocNumber),
    enableEditing: false,
    enableSorting: true,
  });

  const getDocDateColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.DocDate,
    header: Labels.docDate,
    accessorFn: (row) => formatDate(row.docDate, dateFormat),
    enableEditing: false,
    enableSorting: true,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  });

  const getSumColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.Sum,
    header: Labels.totalPriceWithVat,
    accessorFn: (row) => formatCurrency(row.totalPriceWithVat),
    enableEditing: false,
    enableSorting: true,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  });

  const getOrderColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.Order,
    header: Labels.order,
    accessorFn: (row) => getDocumentOrderTitle(row.docOrder),
    enableEditing: false,
    enableSorting: true,
  });

  const getStatusColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.Status,
    header: Labels.status,
    accessorFn: (row) => getDocumentStatusTitle(row.docState),
    enableEditing: false,
    enableSorting: true,
  });

  const getLastNotificationColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.LastNotification,
    header: '',
    headerSettingsName: Labels.lastNotification,
    Header: () =>
      <AppTableColumnHeaderWithHint
        header={Labels.lastNotification}
        hint={Labels.lastNotificationHint}
      />,
    accessorFn: (row) => `${getInteractionEventLabel(row?.lastInteractionSchema)}`,
    enableEditing: false,
    enableSorting: true,
  });

  const getCommentColumn = (): AppTableColumnDefType<ShortDocumentInfo> => ({
    id: DocumentsTableColumns.Comment,
    header: Labels.comment,
    accessorKey: convertDocumentColumnFieldToDto(DocumentsTableColumns.Comment),
    enableEditing: true,
    enableSorting: false,
    muiTableBodyCellEditTextFieldProps: {
      inputProps: {
        maxLength: commentMaxLength,
      },
    },
  });

  const columnsMap = useMemo(() => ({
    [DocumentsTableColumns.IsFavorite]: getFavoriteColumn(),
    [DocumentsTableColumns.Partner]: getPartnerColumn(),
    [DocumentsTableColumns.ActivityPlace]: getActivityPlaceColumns(),
    [DocumentsTableColumns.DocNumber]: getDocNumberColumn(),
    [DocumentsTableColumns.DocDate]: getDocDateColumn(),
    [DocumentsTableColumns.Sum]: getSumColumn(),
    [DocumentsTableColumns.Order]: getOrderColumn(),
    [DocumentsTableColumns.Status]: getStatusColumn(),
    [DocumentsTableColumns.LastNotification]: getLastNotificationColumn(),
    [DocumentsTableColumns.Comment]: getCommentColumn(),
  }), []);

  const columns = useMemo<AppTableColumnDefType<ShortDocumentInfo>[]>(
    () => getDocumentVisibleColumns<DocumentsTableColumns, ShortDocumentInfo>({ columnsMap, visibleColumns }),
    [visibleColumns, columnsMap]
  );

  const getRowId = useCallback((row: ShortDocumentInfo) => row.docUuid, []);

  const tableClasses = useMemo(() => ({
    cell: getDocumentCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses,
    getRowId,
    searchPlaceholder,
  };
}
