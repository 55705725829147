export enum LegalEntitiesTableColumns {
  Code = 'code',
  FullName = 'fullName',
  ShortName = 'shortName',
  Inn = 'inn',
  Kpp = 'kpp',
  RegNumber = 'regNumber',
  ActivityPlaces = 'activityPlaces',
  Comment = 'comment',
}
