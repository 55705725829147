import { RootState } from '@/store';

const selectInteractionLogNotificationHistoryState = (state: RootState) => state.interactionLog.interactionLogNotificationHistory;

const selectTableConfig = (state: RootState) => selectInteractionLogNotificationHistoryState(state).tableConfig.config;

const selectIsOpenNotificationHistoryDialog = (state: RootState) => selectInteractionLogNotificationHistoryState(state).isOpenNotificationHistoryDialog;
const selectNotificationHistory = (state: RootState) => selectInteractionLogNotificationHistoryState(state).notificationHistory;
const selectCurrentEventUuid = (state: RootState) => selectInteractionLogNotificationHistoryState(state).currentEventUuid;
const selectIsLoadingNotificationHistory = (state: RootState) => selectInteractionLogNotificationHistoryState(state).isLoadingNotificationHistory;

export const InteractionLogNotificationHistorySelectors = {
  selectTableConfig,
  selectIsOpenNotificationHistoryDialog,
  selectNotificationHistory,
  selectIsLoadingNotificationHistory,
  selectCurrentEventUuid
};
