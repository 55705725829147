import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { legalEntitiesPageableDataActions } from '@/modules/references/store/legal-entities';
import { getAllLegalEntities } from '@/modules/references/store/legal-entities/async-thunks';
import { LegalEntitiesSelectors } from '@/modules/references/store/legal-entities/selectors';
import { useSelector } from 'react-redux';

export function useLegalEntitiesTableDataSource() {
  const sorting = useSelector(LegalEntitiesSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: legalEntitiesPageableDataActions,
    selectPageableDataState: LegalEntitiesSelectors.selectPageableDataState,
    getData: getAllLegalEntities,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
