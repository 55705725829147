import {
  legalEntitiesRoutePath,
  mdlpProductsRoutePath,
  nomenclatureRoutePath,
  processesRoutePath
} from '@/modules/references/routes/routes';
import { LegalEntitiesPage } from '@/modules/references/views/legal-entities-page/LegalEntitiesPage';
import { MdlpProductsPage } from '@/modules/references/views/mdlp-products/MdlpProductsPage';
import { NomenclaturePage } from '@/modules/references/views/nomenclature-page/NomenclaturePage';
import { ProcessesPage } from '@/modules/references/views/processes-page/ProcessesPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All references routes.
 */
const routes: RouteObject[] = [
  {
    path: legalEntitiesRoutePath,
    element: <LegalEntitiesPage />,
  },
  {
    path: processesRoutePath,
    element: <ProcessesPage />,
  },
  {
    path: mdlpProductsRoutePath,
    element: <MdlpProductsPage />,
  },
  {
    path: nomenclatureRoutePath,
    element: <NomenclaturePage />,
  }
];

export default routes;
