import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { AuthLayout } from '@/modules/auth/components/base-auth-layout/AuthLayout';
import { LoginForm } from '@/modules/auth/components/login-form/LoginForm';
import { PhoneCodeForm } from '@/modules/auth/components/phone-code-form/PhoneCodeForm';
import { usePhoneCodeFormForLogin } from '@/modules/auth/hooks/use-phone-code-form-for-login';
import { passwordRecoveryRoutePath, registrationRoutePath } from '@/modules/auth/routes/routes';
import { resetState as resetLoginState } from '@/modules/auth/store/login';
import { signInInit } from '@/modules/auth/store/login/async-thunks';
import { LoginSelectors } from '@/modules/auth/store/login/selectors';
import { resetState as resetPasswordRecoveryState } from '@/modules/auth/store/password-recovery';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const login = useSelector(LoginSelectors.selectLogin);

  const [showLoginForm, setShowLoginForm] = useState(!login);

  const phoneCodeFormContext = usePhoneCodeFormForLogin();

  const onSignInClick = () => {
    asyncDispatch(signInInit, () => setShowLoginForm(false));
  };

  const onRegisterClick = () => {
    navigate(registrationRoutePath);
  };

  const onPasswordRecoveryClick = () => {
    dispatch(resetPasswordRecoveryState());
    navigate(passwordRecoveryRoutePath);
  };

  const navigateToLogin = useCallback(() => {
    setShowLoginForm(true);
    dispatch(resetLoginState());
  }, []);

  useEffect(() => {
    return () => { dispatch(resetLoginState()); };
  }, []);

  return (
    <AuthLayout padding>
      {showLoginForm ?
        <LoginForm
          onSignInClick={onSignInClick}
          onRegisterClick={onRegisterClick}
          onPasswordRecoveryClick={onPasswordRecoveryClick}
        /> :
        <PhoneCodeForm
          phone={phoneCodeFormContext.phone}
          code={phoneCodeFormContext.verificationCode}
          codeStatus={phoneCodeFormContext.codeStatus}
          expireVerificationCode={phoneCodeFormContext.expireVerificationCode}
          isLoading={phoneCodeFormContext.isLoading}
          deniedMessage={phoneCodeFormContext.deniedMessage}
          onInput={phoneCodeFormContext.onInput}
          onCompleteInput={phoneCodeFormContext.onCompleteInput}
          onSendPhoneNumberCodeClick={phoneCodeFormContext.onSendPhoneNumberCodeClick}
          onGoBackClick={navigateToLogin}
        />
      }
    </AuthLayout>
  );
};
