export enum SyncStatus {
  Running = 'RUNNING',
  CompleteSuccesslully = 'COMPLETE_SUCCESSFULLY',
  CompleteExceptionally = 'COMPLETE_EXCEPTIONALLY'
}

const syncStatusLabels = {
  [SyncStatus.CompleteExceptionally]: 'Ошибка',
  [SyncStatus.CompleteSuccesslully]: 'Успешно',
  [SyncStatus.Running]: 'В процессе',
};

export const getSyncStatusLabel = (syncStatus: SyncStatus) => syncStatusLabels[syncStatus];