import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DocumentCardNotesProps } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { EditNoteParams } from '@/shared/document/models/document-card/notes/edit-note-params';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardNotesEditingHookParams = Pick<DocumentCardNotesProps, 'documentCardNotesSelectors' | 'documentCardNotesApi'>;

export function useDocumentCardNotesEditing({ documentCardNotesSelectors, documentCardNotesApi }: DocumentCardNotesEditingHookParams) {
  const { asyncDispatch } = useAsyncDispatch();

  const isEditingInProgress = useSelector(documentCardNotesSelectors.selectIsEditingNoteInProgress);

  const [editedNoteUuid, setEditedNoteUuid] = useState<string>();
  const [editedNoteText, setEditedNoteText] = useState('');
  const [initialNoteText, setInitialNoteText] = useState('');

  const onNoteValueChange = useCallback((value: string) => {
    setEditedNoteText(value);
  }, []);

  const onEditClick = useCallback(({ documentNoteUuid, noteText }: EditNoteParams) => {
    setInitialNoteText(noteText);
    setEditedNoteText(noteText);
    setEditedNoteUuid(documentNoteUuid);
  }, []);

  const exitEditing = () => {
    setEditedNoteUuid(undefined);
    setEditedNoteText('');
    setInitialNoteText('');
  };

  const onCancelClick = useCallback(exitEditing, []);

  const onSaveClick = useCallback(() => {
    if (!editedNoteText) {
      return;
    }

    if (editedNoteText === initialNoteText) {
      exitEditing();
      return;
    }

    const editNoteParams: EditNoteParams = {
      documentNoteUuid: editedNoteUuid,
      noteText: editedNoteText,
    };

    asyncDispatch(
      () => documentCardNotesApi.editNote(editNoteParams),
      () => exitEditing(),
    );
  }, [initialNoteText, editedNoteText, editedNoteUuid]);

  return {
    editedNoteUuid,
    isEditingInProgress,
    onNoteValueChange,
    onEditClick,
    onCancelClick,
    onSaveClick,
  };
}
