import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { email, phone, required } from '@/common/utils/client-validation/validation-rules';
import { errorMessageValidationAgreementWithRules } from '@/modules/auth/constants/error-messages-validation';
import { RegistrationFormData, RegistrationFormDataFields } from '@/modules/auth/models/registration-form-data';

export function useRegistrationFormDataValidation(data: Partial<RegistrationFormData>, fields: Set<RegistrationFormDataFields>) {

  const validationRules: FormValidationRules<RegistrationFormData, RegistrationFormDataFields> = {
    secondName: [required()],
    firstName: [required()],
    lastName: [required()],
    email: [required(), email()],
    phoneNumber: [required(), phone()],
    position: [required()],
    clientId: [required()],
    clientSecret: [required()],
    thumbprint: [required()],
    agreementWithRules: [required(errorMessageValidationAgreementWithRules)],
    selectedCertificate: [required()]
  };

  return {
    ...useFormValidation({ validationRules, data, fields }),
  };
}
