import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { IconButton } from '@mui/material';
import React, { FC, MouseEvent, memo, useMemo } from 'react';
import './app-icon-button.scss';

interface AppIconButtonProps {
  icon: React.ReactNode;
  className?: string;
  activeColor?: 'primary' | 'text';
  hoverColor?: 'primary' | 'text';
  tooltip?: string;
  edge?: 'start' | 'end' | false;
  initialSize?: boolean;
  disabled?: boolean;
  notClickable?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<Element>) => void;
}

const AppIconButtonInner: FC<AppIconButtonProps> = ({
  icon,
  className,
  activeColor = 'text',
  hoverColor = 'primary',
  tooltip,
  edge,
  initialSize,
  disabled,
  notClickable,
  isLoading,
  onClick
}) => {
  const iconButtonClassName = useMemo(() =>
    `app-icon-button
    ${activeColor}
    hover-${hoverColor}
    ${className ?? ''}
    ${initialSize ? 'initial-icon-size' : ''}
    ${disabled ? 'disabled' : ''}
    ${notClickable ? 'not-clickable' : ''}
    `, [activeColor, hoverColor, className, initialSize, disabled, notClickable]);

  return (
    <AppTooltip text={tooltip}>
      <IconButton
        edge={edge}
        className={iconButtonClassName}
        disableRipple
        onClick={onClick}
      >
        {isLoading ? <AppLoader className="app-icon-button-loader" /> : icon}
      </IconButton>
    </AppTooltip>
  );
};

export const AppIconButton = memo(AppIconButtonInner);
