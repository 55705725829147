import { RootState } from '@/store';

const selectCertificatesState = (state: RootState) => state.certificates;

const selectSelectedCertificate = (state: RootState) => selectCertificatesState(state).selectedCertificate;
const selectPreSelectedCertificate = (state: RootState) => selectCertificatesState(state).preSelectedCertificate;
const selectCertificates = (state: RootState) => selectCertificatesState(state).certificates;
const selectLoadingStatus = (state: RootState) => selectCertificatesState(state).loadingStatus;

export const CertificatesSelectors = {
  selectSelectedCertificate,
  selectPreSelectedCertificate,
  selectCertificates,
  selectLoadingStatus,
};
