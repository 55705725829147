import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';

const selectLegalEntitiesState = (state: RootState) => state.references.legalEntities;

const selectIsUpdatingInProcess = (state: RootState) => selectLegalEntitiesState(state).isUpdatingInProcess;
const selectUpdatedRowId = (state: RootState) => selectLegalEntitiesState(state).updatedRowId;

const selectSearchState = (state: RootState) => selectLegalEntitiesState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectFilterConfig = (state: RootState) => selectLegalEntitiesState(state).filterConfig;
const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
const selectFilterInn = (state: RootState) => selectFilters(state).inn;
const selectFilterKpp = (state: RootState) => selectFilters(state).kpp;
const selectFilterRegNumber = (state: RootState) => selectFilters(state).regNumber;
const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);

const selectTableConfig = (state: RootState) => selectLegalEntitiesState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectPageableDataState = (state: RootState) => selectLegalEntitiesState(state).pageableData;
const selectLegalEntities = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectIsLoadingLegalEntities = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

export const LegalEntitiesSelectors = {
  selectFilterConfig,
  selectIsPanelOpen,
  selectFilters,
  selectFilterInn,
  selectFilterKpp,
  selectFilterRegNumber,
  selectSelectedFiltersCount,
  selectSearchState,
  selectSearchString,
  selectLegalEntities,
  selectTableConfig,
  selectTableSorting,
  selectIsLoadingLegalEntities,
  selectIsUpdatingInProcess,
  selectUpdatedRowId,
  selectPageableDataState,
};
