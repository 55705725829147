import { receiptsApi } from '@/modules/receipts/store/receipts/async-thunks';
import { receiptModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { DocumentType } from '@/shared/document/models/document-type';
import { createDocumentsSlice } from '@/shared/document/store/documents';

const receiptsSlice = createDocumentsSlice({
  moduleName: receiptModuleName,
  savedTableConfigName: 'receipts-table',
  docType: DocumentType.Receipt,
  api: receiptsApi,
});

export const receiptsRootActions = receiptsSlice.rootSlice.actions;
export const receiptsTableActions = receiptsSlice.documentsTableSlice.actions;
export const receiptsSearchingActions = receiptsSlice.documentsSearchingSlice.actions;
export const receiptsTableConfigActions = receiptsSlice.documentsTableConfigSlice.actions;
export const receiptsFilterConfigActions = receiptsSlice.documentsFilterConfigSlice.actions;
export const receiptsPageableDataActions = receiptsSlice.documentsPageableDataSlice.actions;

export default receiptsSlice.rootSlice.reducer;
