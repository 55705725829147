import { AppTableSortingType } from '@/common/models/app-table/app-table-types';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import { NoteState } from '@/shared/document/models/document-card/notes/note-state';
import { DocumentsCardNotesApi } from '@/shared/document/store/document-card/notes/types';
import { CaseReducerActions, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface DocumentCardNotesState {
  pageableData: PageableDataState<DocumentCardNote>;
  sorting: AppTableSortingType;
  isAddingNoteInProgress: boolean;
  isEditingNoteInProgress: boolean;
  isDeletingNoteInProgress: boolean;
  isRevertingNoteInProgress: boolean;
}

interface DocumentCardNotesCaseReducers extends SliceCaseReducers<DocumentCardNotesState> {
  updateNote: (state: Draft<DocumentCardNotesState>, action: PayloadAction<Partial<DocumentCardNote>>) => void;
  resetState: (state: Draft<DocumentCardNotesState>) => void;
}

export type DocumentCardNotesActions = CaseReducerActions<DocumentCardNotesCaseReducers, string>;

interface CreateDocumentCardNotesSliceParams {
  moduleName: string;
  api: DocumentsCardNotesApi;
}

export const createDocumentCardNotesSlice = ({ moduleName, api }: CreateDocumentCardNotesSliceParams) => {

  const documentNotesPageableDataSlice = createPageableDataSlice<DocumentCardNote>(moduleName);

  const initialState: DocumentCardNotesState = {
    pageableData: documentNotesPageableDataSlice.getInitialState(),
    sorting: [{
      id: 'createdDate',
      desc: false,
    }],
    isAddingNoteInProgress: false,
    isEditingNoteInProgress: false,
    isDeletingNoteInProgress: false,
    isRevertingNoteInProgress: false,
  };

  const rootSlice = createSlice<DocumentCardNotesState, DocumentCardNotesCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      updateNote(state: DocumentCardNotesState, action: PayloadAction<Partial<DocumentCardNote>>) {
        const updatedNote = action.payload;
        const { patchItemByFindIndex } = documentNotesPageableDataSlice.caseReducers;
        const findIndexCallback = (item: DocumentCardNote) => item.documentNoteUuid === updatedNote.documentNoteUuid;
        patchItemByFindIndex(state.pageableData, getReducerAction(patchItemByFindIndex.name, {
          item: updatedNote,
          findIndexCallback
        }));
      },
      resetState: (state: DocumentCardNotesState) => {
        Object.keys(state)
          .forEach((key: keyof DocumentCardNotesState) => {
            // @ts-ignore
            state[key] = initialState[key];
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getAllNotes.pending, (state) => {
          const { updateLoadingStatus } = documentNotesPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
        })
        .addCase(api.getAllNotes.rejected, (state) => {
          const { updateLoadingStatus } = documentNotesPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
        })
        .addCase(api.getAllNotes.fulfilled, (state) => {
          const { updateLoadingStatus } = documentNotesPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        })
        .addCase(api.addNote.pending, (state) => {
          state.isAddingNoteInProgress = true;
        })
        .addCase(api.addNote.rejected, (state) => {
          state.isAddingNoteInProgress = false;
        })
        .addCase(api.addNote.fulfilled, (state, action) => {
          state.isAddingNoteInProgress = false;
          const { addItemToStart } = documentNotesPageableDataSlice.caseReducers;
          addItemToStart(state.pageableData, getReducerAction(addItemToStart.name, action.payload));
        })
        .addCase(api.editNote.pending, (state) => {
          state.isEditingNoteInProgress = true;
        })
        .addCase(api.editNote.rejected, (state) => {
          state.isEditingNoteInProgress = false;
        })
        .addCase(api.editNote.fulfilled, (state, action) => {
          state.isEditingNoteInProgress = false;
          rootSlice.caseReducers.updateNote(state, action);
        })
        .addCase(api.deleteNote.pending, (state) => {
          state.isDeletingNoteInProgress = true;
        })
        .addCase(api.deleteNote.rejected, (state) => {
          state.isDeletingNoteInProgress = false;
        })
        .addCase(api.deleteNote.fulfilled, (state, action) => {
          state.isDeletingNoteInProgress = false;

          const data: Partial<DocumentCardNote> = {
            documentNoteUuid: action.meta.arg,
            noteState: NoteState.Deleted
          };

          const { updateNote } = rootSlice.caseReducers;
          updateNote(state, getReducerAction(updateNote.name, data));
        })
        .addCase(api.revertNote.pending, (state) => {
          state.isRevertingNoteInProgress = true;
        })
        .addCase(api.revertNote.rejected, (state) => {
          state.isRevertingNoteInProgress = false;
        })
        .addCase(api.revertNote.fulfilled, (state, action) => {
          state.isRevertingNoteInProgress = false;

          const data: Partial<DocumentCardNote> = {
            documentNoteUuid: action.meta.arg,
            noteState: NoteState.Active
          };

          const { updateNote } = rootSlice.caseReducers;
          updateNote(state, getReducerAction(updateNote.name, data));
        })
        .addMatcher(
          (action) => action.type.startsWith(documentNotesPageableDataSlice.name),
          (state, action) => {
            state.pageableData = documentNotesPageableDataSlice.reducer(state.pageableData, action);
          }
        );
    }
  });

  return {
    rootSlice,
    documentNotesPageableDataSlice,
  };
};
