import { AppMenu } from '@/common/components/app-menu/AppMenu';
import { AppMenuItemProps } from '@/common/components/app-menu/components/AppMenuItem';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { exportAllRemainsFiles, exportSelectedRemainsFiles } from '@/modules/remains/store/page/async-thunks';
import { RemainsPageSelectors } from '@/modules/remains/store/page/selectors';
import { remainsTableSelectors } from '@/modules/remains/store/table/selectors';
import { useFileExport } from '@/shared/files/hooks/use-file-export';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export interface RemainsExportMenuProps {
  openDialog: () => void;
}

const RemainsExportMenuInner: FC<RemainsExportMenuProps> = ({ openDialog }) => {
  const { asyncDispatch } = useAsyncDispatch();

  const totalItems = useSelector(remainsTableSelectors.selectTotalItems);
  const rowsSelection = useSelector(remainsTableSelectors.selectRowsSelection);
  const isExportSelectedInProcess = useSelector(RemainsPageSelectors.selectIsExportSelectedInProcess);
  const isExportAllInProcess = useSelector(RemainsPageSelectors.selectIsExportAllInProcess);
  const exportAllDisabled = totalItems === 0 || isExportAllInProcess;

  const { exportButtonDisabled, exportedItemsCount, onExportClick: exportSelected } = useFileExport({
    rowsSelection,
    isExportInProcess: isExportSelectedInProcess,
    exportAction: exportSelectedRemainsFiles,
    openExportNotificationDialog: openDialog,
  });

  const exportAll = useCallback(() => {
    return asyncDispatch(
      exportAllRemainsFiles,
      openDialog
    );
  }, []);

  const menuItems = useMemo<AppMenuItemProps[]>(() => [
    {
      title: 'Экспорт всех',
      disabled: exportAllDisabled,
      showLoader: isExportAllInProcess,
      action: exportAll,
    },
    {
      title: `Экспорт выбранных${exportedItemsCount ? ` (${exportedItemsCount})` : ''}`,
      disabled: exportButtonDisabled,
      showLoader: isExportSelectedInProcess,
      action: exportSelected
    }
  ], [exportedItemsCount, isExportSelectedInProcess, exportButtonDisabled, isExportAllInProcess, exportAllDisabled]);

  return (
    <AppMenu
      label="Экспорт"
      items={menuItems}
    />
  );
};

export const RemainsExportMenu = memo(RemainsExportMenuInner);
