import { LoadingStatus } from '@/common/models/loading-status';
import { portalModuleName } from '@/modules/portal/constants/portal-module-names';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PortalInitialState {
  isPortalInitialized: boolean;
  loadingStatus?: LoadingStatus;
}

const initialState: PortalInitialState = {
  isPortalInitialized: false,
  loadingStatus: undefined,
};

export const portalSlice = createSlice({
  name: portalModuleName,
  initialState,
  reducers: {
    setIsPortalInitialized(state, action: PayloadAction<LoadingStatus>) {
      state.isPortalInitialized = action.payload;
    },
    setPortalLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatus = action.payload;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof PortalInitialState) => {
          state[key] = initialState[key];
        });
    }
  }
});

export const {
  resetState,
  setIsPortalInitialized,
  setPortalLoadingStatus,
} = portalSlice.actions;

export default portalSlice.reducer;
