import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { DocumentsTableProps } from '@/shared/document/components/documents-table/DocumentsTable';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type DocumentsTableFilteringHookParams =
  Pick<PageableLoadingActions, 'loadFirstPage'> &
  Pick<DocumentsTableProps, 'documentsSelectors' | 'documentsFilterConfigActions'>;

export function useDocumentsTableFiltering({ loadFirstPage, documentsSelectors, documentsFilterConfigActions }: DocumentsTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(documentsFilterConfigActions.changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData({
    filterConfigActions: documentsFilterConfigActions,
    selectFilterConfig: documentsSelectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
