import { AppDatePicker } from '@/common/components/app-date-picker/AppDatePicker';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppMultiSelect } from '@/common/components/app-multi-select/AppMultiSelect';
import { AppNumberInput } from '@/common/components/app-number-input/AppNumberInput';
import { AppSelect } from '@/common/components/app-select/AppSelect';
import { Labels } from '@/common/constants/labels';
import { useOrganizationPlacesDataSource } from '@/common/hooks/common-data-sources/use-organization-places-data-source';
import { VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { legalEntityNameMaxLength, sumMaxLength } from '@/common/utils/client-validation/string-lengths';
import { FormValidationResult } from '@/common/utils/client-validation/validation-result';
import { getEndOfDay } from '@/common/utils/common/date-utils';
import { getDocumentOrdersOptions } from '@/shared/document/models/document-order';
import { getDocumentStatusesOptions } from '@/shared/document/models/document-status';
import { DocumentsFilterFields, DocumentsFilter as DocumentsFilterModel } from '@/shared/document/models/documents/documents-filter';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { useAppDispatch } from '@/store';
import { AnyAction } from '@reduxjs/toolkit';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import './documents-filters.scss';

interface DocumentsFilterProps extends VisibleColumns<DocumentsTableColumns> {
  filter: DocumentsFilterModel;
  updateFilter: (payload: Partial<DocumentsFilterModel>) => AnyAction;
  validationResult?: FormValidationResult<DocumentsFilterFields>;
}

const DocumentsFilterInner: FC<DocumentsFilterProps> = ({ filter, updateFilter, validationResult, visibleColumns }) => {
  const dispatch = useAppDispatch();

  const { organizationPlacesOptions, loadOrganizationPlacesOptions } = useOrganizationPlacesDataSource();

  useEffect(() => {
    loadOrganizationPlacesOptions();
  }, []);

  const showPartner = useMemo(() => visibleColumns?.some((column) => column === DocumentsTableColumns.Partner) ?? true, []);

  const updateFilterField = (fieldName: DocumentsFilterFields) =>
    (value: DocumentsFilterModel[DocumentsFilterFields]) =>
      dispatch(updateFilter({ [fieldName]: value }));

  const onActivityPlacesChange = useCallback(updateFilterField('activityPlaces'), []);
  const updateLegalEntity = useCallback(updateFilterField('legalEntity'), []);
  const updateCreationDateFrom = useCallback(updateFilterField('creationDateFrom'), []);
  const updateCreationDateTo = useCallback((value: DocumentsFilterModel['creationDateTo']) =>
    dispatch(updateFilter({ creationDateTo: getEndOfDay(value) }))
    , []);
  const updateSumFrom = useCallback(updateFilterField('sumFrom'), []);
  const updateSumTo = useCallback(updateFilterField('sumTo'), []);
  const updateOrder = useCallback(updateFilterField('order'), []);
  const updateStatuses = useCallback(updateFilterField('statuses'), []);

  const documentOrdersOptions = useMemo(getDocumentOrdersOptions, []);
  const documentStatusesOptions = useMemo(getDocumentStatusesOptions, []);

  return (
    <div className="documents-filters">
      <AppDatePicker
        label={`${Labels.createdDate} ${Labels.dateFrom}`}
        value={filter.creationDateFrom}
        maxDate={filter.creationDateTo}
        onChange={updateCreationDateFrom}
      />
      <AppDatePicker
        label={`${Labels.createdDate} ${Labels.dateTo}`}
        value={filter.creationDateTo}
        minDate={filter.creationDateFrom}
        onChange={updateCreationDateTo}
      />
      <AppNumberInput
        label={`${Labels.sum} ${Labels.amountFrom}`}
        value={filter.sumFrom}
        showClearButton
        maxLength={sumMaxLength}
        error={validationResult?.sumFrom?.error}
        helperText={validationResult?.sumFrom?.messages?.[0]}
        onValueChange={updateSumFrom}
      />
      <AppNumberInput
        label={`${Labels.sum} ${Labels.amountTo}`}
        value={filter.sumTo}
        showClearButton
        maxLength={sumMaxLength}
        error={validationResult?.sumTo?.error}
        helperText={validationResult?.sumTo?.messages?.[0]}
        onValueChange={updateSumTo}
      />
      {showPartner && <AppInput
        label={Labels.partner}
        value={filter.legalEntity}
        className="legal-entity-filter"
        showClearButton
        maxLength={legalEntityNameMaxLength}
        onValueChange={updateLegalEntity}
      />}
      <AppSelect
        label={Labels.order}
        value={filter.order}
        className={showPartner ? 'order-filter-short' : 'order-filter-long'}
        showClearButton
        options={documentOrdersOptions}
        onChange={updateOrder}
      />
      <AppMultiSelect
        label={Labels.activityPlacesFull}
        value={filter.activityPlaces}
        className="activity-places-filter"
        options={organizationPlacesOptions}
        onChange={onActivityPlacesChange}
      />
      <AppMultiSelect
        label={Labels.status}
        value={filter.statuses}
        className="status-filter"
        options={documentStatusesOptions}
        onChange={updateStatuses}
      />
    </div>
  );
};

export const DocumentsFilter = memo(DocumentsFilterInner);
