import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { InteractionLogNotificationHistoryTable } from '@/modules/interaction-log/components/interaction-log-notification-history-dialog/interaction-log-notification-history-table/InteractionLogNotificationHistoryTable';
import { resetState, setIsOpenNotificationHistoryDialog } from '@/modules/interaction-log/store/interaction-log-notification-history';
import { getNotificationHistory } from '@/modules/interaction-log/store/interaction-log-notification-history/async-thunks';
import { InteractionLogNotificationHistorySelectors } from '@/modules/interaction-log/store/interaction-log-notification-history/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './interaction-log-notification-history-dialog.scss';

export const InteractionLogNotificationHistoryDialog = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { setDispatchResult, abortRequest } = useAsyncDispatchAborting();

  const isOpen = useSelector(InteractionLogNotificationHistorySelectors.selectIsOpenNotificationHistoryDialog);
  const currentEventUuid = useSelector(InteractionLogNotificationHistorySelectors.selectCurrentEventUuid);

  const resetInnerState = () => {
    // timeout delays resetting state until the closing dialog animation ends
    setTimeout(() => dispatch(resetState()), AppDialogTransitionDuration);
    abortRequest();
  };

  useEffect(() => {
    if (isOpen) {
      const resultDispatch = asyncDispatch(() => getNotificationHistory(currentEventUuid));
      setDispatchResult(resultDispatch);
    } else {
      resetInnerState();
    }
  }, [isOpen]);

  const onCloseDialog = useCallback(() => {
    dispatch(setIsOpenNotificationHistoryDialog(false));
  }, []);

  return (
    <AppDialog
      open={isOpen}
      classes={{ dialog: 'interaction-log-notification-history-dialog' }}
      header="История уведомления"
      onClose={onCloseDialog}
    >
      <InteractionLogNotificationHistoryTable />
    </AppDialog >
  );
};
