import { PageableDataPatchItemByCallbackParams } from '@/common/models/pageable-data/pageable-data-patch-item-by-callback-params';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { FavoriteButtonCommon } from '@/shared/favorites/components/favorite-button-common/FavoriteButtonCommon';
import { useFavoriteClick } from '@/shared/favorites/hooks/use-favorite-click';
import { usePatchFavoriteItem } from '@/shared/favorites/hooks/use-patch-favorite-item';
import { SharedFavoritesSelectors } from '@/shared/favorites/store/selectors';
import { RootState } from '@/store';
import { AnyAction } from '@reduxjs/toolkit';
import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

interface DocumentFavoriteButtonProps {
  document: ShortDocumentInfo;
  patchItemByFindIndex: (payload: PageableDataPatchItemByCallbackParams<ShortDocumentInfo>) => AnyAction;
}

const DocumentFavoriteButtonInner: FC<DocumentFavoriteButtonProps> = ({ document, patchItemByFindIndex }) => {
  const { docUuid, isFavorite } = document;

  const isChangingInProgress = useSelector((state: RootState) => SharedFavoritesSelectors.selectIsChangingInProgress(state, docUuid));

  const { patchFavoriteItem } = usePatchFavoriteItem({ patchItemByFindIndex });

  const { onFavoriteClick } = useFavoriteClick();

  const onClick = useCallback(() => {
    const updateFavoriteItem = () => patchFavoriteItem(document, !isFavorite);
    onFavoriteClick({ docUuid, isFavorite, updateFavoriteItem });
  }, [document]);

  const notClickable = isChangingInProgress;

  return (
    <FavoriteButtonCommon
      isFavorite={isFavorite}
      notClickable={notClickable}
      onClick={onClick}
    />
  );
};

export const DocumentFavoriteButton = memo(DocumentFavoriteButtonInner);
