import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { DocumentCardNotesProps } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { useSelector } from 'react-redux';

type DocumentCardNotesDataSourceHookParams = Pick<DocumentCardNotesProps,
  'documentCardSelectors' | 'documentCardNotesSelectors' | 'documentCardNotesPageableDataActions' | 'documentCardNotesApi'
>;

export function useDocumentCardNotesDataSource({
  documentCardSelectors,
  documentCardNotesSelectors,
  documentCardNotesPageableDataActions,
  documentCardNotesApi
}: DocumentCardNotesDataSourceHookParams) {
  const documentId = useSelector(documentCardSelectors.selectDocumentId);

  const { dispatchResult, loadFirstPage, loadNextPage } = usePageableLoadingData({
    dataActions: documentCardNotesPageableDataActions,
    selectPageableDataState: documentCardNotesSelectors.selectPageableDataState,
    getData: documentCardNotesApi.getAllNotes,
    resetCondition: documentId,
  });

  return {
    dispatchResult,
    loadNextPage,
    loadFirstPage
  };
}
