import { LoadingStatus } from '@/common/models/loading-status';
import { RootState } from '@/store';

const selectPortalState = (state: RootState) => state.portal.portal;

const selectIsLoading = (state: RootState) => selectPortalState(state).loadingStatus === LoadingStatus.Pending;
const selectIsPortalInitialized = (state: RootState) => selectPortalState(state).isPortalInitialized;

export const PortalSelectors = {
  selectIsLoading,
  selectIsPortalInitialized,
};
