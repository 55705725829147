import { AppButton } from '@/common/components/app-button/AppButton';
import { HeaderWithLoader } from '@/common/components/custom/header-with-loader/HeaderWithLoader';
import { Labels } from '@/common/constants/labels';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { getLogicalValueLabel } from '@/common/utils/common/types-utils';
import { FormInfoItem } from '@/modules/references/components/nomenclature-mdlp-comparison-form/components/FormInfoItem';
import { updateNomenclature } from '@/modules/references/store/nomenclature';
import { syncNomenclatureWithMdlpProduct } from '@/modules/references/store/nomenclature-mdlp-comparison/async-thunks';
import { NomenclatureMdlpComparisonSelectors } from '@/modules/references/store/nomenclature-mdlp-comparison/selectors';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './nomenclature-mdlp-comparison-form.scss';

const NomenclatureMdlpComparisonFormInner = () => {
  const dispatch = useDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const nomenclature = useSelector(NomenclatureMdlpComparisonSelectors.selectNomenclature);
  const mdlpProduct = useSelector(NomenclatureMdlpComparisonSelectors.selectMdlpProduct);
  const isMdlpInfoLoading = useSelector(NomenclatureMdlpComparisonSelectors.selectIsLoading);
  const isGtinFound = useSelector(NomenclatureMdlpComparisonSelectors.selectNomenclatureIsGtinFound);

  useEffect(() => { isGtinFound && dispatch(updateNomenclature(nomenclature)); }, [isGtinFound]);

  const mdlpGtinLabel = isGtinFound ? 'GTIN найден' : 'GTIN не найден';
  const showMdlpGtinLabel = !isMdlpInfoLoading;
  const hideMdlpInfo = !isGtinFound;
  const showLoadMdlpProductButton = !isGtinFound;

  const onLoadMdlpProductClick = useCallback(() => {
    asyncDispatch(syncNomenclatureWithMdlpProduct);
  }, []);

  return (
    <>
      <div className="nomenclature-mdlp-comparison-form-container">
        <h1>КУС</h1>
        <HeaderWithLoader
          title="МДЛП"
          showLoader={isMdlpInfoLoading}
          align="start"
        />

        <span>Код товара: {nomenclature?.code}</span>
        <br />

        <span>GTIN: <strong>{nomenclature?.gtin}</strong></span>
        <span>{showMdlpGtinLabel && mdlpGtinLabel}</span>

        <FormInfoItem
          title={Labels.nameHeader}
          value={nomenclature?.name}
        />
        <FormInfoItem
          title={Labels.nameHeader}
          value={mdlpProduct?.name}
          hideElement={hideMdlpInfo}
          hideValue={isMdlpInfoLoading}
        />

        <FormInfoItem
          title={Labels.producer}
          value={nomenclature?.producerName}
        />
        <FormInfoItem
          title={Labels.producer}
          value={mdlpProduct?.producerName}
          hideElement={hideMdlpInfo}
          hideValue={isMdlpInfoLoading}
        />

        <FormInfoItem
          title={Labels.producerCountry}
          value={nomenclature?.producerCountry}
        />
        <FormInfoItem
          title={Labels.producerCountry}
          value={mdlpProduct?.producerCountry}
          hideElement={hideMdlpInfo}
          hideValue={isMdlpInfoLoading}
        />

        <FormInfoItem
          title={Labels.barCode}
          value={nomenclature?.barcode}
        />
        <FormInfoItem
          title={Labels.barCode}
          hideElement={hideMdlpInfo}
          hideValue={isMdlpInfoLoading}
        />

        <FormInfoItem
          title={Labels.vital}
          value={getLogicalValueLabel(nomenclature?.gnvlp)}
        />
        <FormInfoItem
          title={Labels.vital}
          value={getLogicalValueLabel(mdlpProduct?.gnvlp)}
          hideElement={hideMdlpInfo}
          hideValue={isMdlpInfoLoading}
        />

        <FormInfoItem
          title={Labels.nonProprietaryName}
        />
        <FormInfoItem
          title={Labels.nonProprietaryName}
          value={mdlpProduct?.mnn}
          hideElement={hideMdlpInfo}
          hideValue={isMdlpInfoLoading}
        />
      </div>
      {showLoadMdlpProductButton &&
        <div className="nomenclature-mdlp-comparison-bottom-toolbar">
          <AppButton
            label="Загрузить данные из МДЛП"
            disabled={isMdlpInfoLoading}
            onClick={onLoadMdlpProductClick}
          />
        </div>
      }
    </>
  );
};

export const NomenclatureMdlpComparisonForm = memo(NomenclatureMdlpComparisonFormInner);
