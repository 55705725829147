import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { CommonFavoriteDocumentItem } from '@/shared/favorites/models/common-favorite-document-item';
import { addToFavorite, deleteFromFavorite } from '@/shared/favorites/store/async-thunks';
import { useCallback } from 'react';

interface FavoriteClickParams extends CommonFavoriteDocumentItem {
  updateFavoriteItem: () => void;
}

export const useFavoriteClick = () => {

  const { asyncDispatch } = useAsyncDispatch();

  const onFavoriteClick = useCallback(({ docUuid, isFavorite, updateFavoriteItem }: FavoriteClickParams) => {
    const changeFavorite = isFavorite ? deleteFromFavorite : addToFavorite;
    asyncDispatch(() => changeFavorite(docUuid), updateFavoriteItem);
  }, []);

  return { onFavoriteClick };
};