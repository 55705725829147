import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectInteractionLogState = (state: RootState) => state.interactionLog.interactionLog;

const selectTableConfig = (state: RootState) => selectInteractionLogState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectSearchState = (state: RootState) => selectInteractionLogState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectFilterConfig = (state: RootState) => selectInteractionLogState(state).filterConfig;
const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
const selectFilterStartDate = (state: RootState) => selectFilters(state).startDate;
const selectFilterEndDate = (state: RootState) => selectFilters(state).endDate;
const selectFilterStatuses = (state: RootState) => selectFilters(state).statuses;
const selectFilterEvents = (state: RootState) => selectFilters(state).events;
const selectFilterSource = (state: RootState) => selectFilters(state).source;
const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);

const selectPageableDataState = (state: RootState) => selectInteractionLogState(state).pageableData;
const selectInteractionData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectPageNumber = createSelector([selectPageableDataState], PageableDataSelectors.selectPageNumber);
const selectIsLoadingInteractionData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

const selectIsOpenCertificatesListDialog = (state: RootState) => selectInteractionLogState(state).isOpenCertificatesListDialog;
const selectRetryOperationEventUuid = (state: RootState) => selectInteractionLogState(state).retryOperationEventUuid;

export const InteractionLogSelectors = {
  selectTableConfig,
  selectSearchState,
  selectSearchString,
  selectTableSorting,
  selectFilterConfig,
  selectFilters,
  selectIsPanelOpen,
  selectFilterStartDate,
  selectFilterEndDate,
  selectFilterStatuses,
  selectFilterEvents,
  selectFilterSource,
  selectSelectedFiltersCount,
  selectPageableDataState,
  selectInteractionData,
  selectPageNumber,
  selectIsLoadingInteractionData,
  selectIsOpenCertificatesListDialog,
  selectRetryOperationEventUuid
};
