import ArrowBackIcon from '@/assets/icons/arrows/arrow_back.svg?react';
import { AppLink } from '@/common/components/app-link/AppLink';
import { AppList } from '@/common/components/app-list/AppList';
import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import './licence-agreement.scss';

export interface LicenceAgreementProps {
  onGoBack: () => void;
}

const LicenceAgreementInner: FC<LicenceAgreementProps> = ({ onGoBack }) => (
  <>
    <AppLink
      text="Назад"
      startIcon={<ArrowBackIcon />}
      onClick={onGoBack}
    />

    <div className="licence-agreement">
      <h2>Пользовательское соглашение (Публичная оферта)</h2>

      <p>Общество с ограниченной ответственностью «Ремедиум» (ООО «Ремедиум»), именуемое в дальнейшем <strong>«Владелец»</strong>, ИНН 4632197133, адрес: 107078, город Москва, пер Орликов, д. 5 стр. 1а, этаж 1 ком. 190.</p>

      <p>Настоящий документ в соответствии со ст. 437 Гражданского кодекса Российской Федерации является предложением, согласно которому ООО «Ремедиум» намерено оказать любому лицу, которое совершает акцепт Оферты, услуги, указанные в Оферте, на условиях, указанных ниже.</p>

      <h3>1. Термины и определения</h3>

      <p><strong>1.1. Пользователь</strong> — субъект обращения лекарственных средств, в частности дистрибьютор, акцептовавший настоящую Оферту.</p>

      <p><strong>1.2. Сервис «REMEDIUM» (далее - Сервис)</strong> — программный комплекс, доступный посредством веб-браузера, предназначенный для расширения возможностей по управлению данными и процедурами, предоставляемыми сервисом «Честный знак» (МДЛП – Мониторинг Движения Лекарственных Препаратов).</p>

      <p><strong>1.3. Акцепт</strong> — полное и безоговорочное согласие Пользователя принять условия Оферты. Любое использование Сервиса означает акцепт Оферты Пользователем.</p>

      <p><strong>1.4. Аутентификационные данные</strong> — уникальные средства идентификации Пользователя (адрес электронной почты, номер мобильного телефона и пароль), используемые для его авторизации в Сервисе.</p>

      <p><strong>1.5. УКЭП</strong> — усиленная квалифицированная электронная подпись, обладающая дополнительными признаками защищённости: ключом проверки и подтверждёнными средствами электронной подписи.</p>

      <p><strong> 1.6.</strong> Термины и определения, не указанные в настоящем разделе, но используемые по тексту Соглашения, имеют значение, определенное для них действующим законодательством.
      </p>

      <h3>2. Общие положения о Сервисе</h3>

      <p><strong>2.1.</strong> Настоящий Сервис призван обеспечить эффективное взаимодействие с сервисом «Честный знак» (МДЛП – Мониторинг Движения Лекарственных Препаратов) с учётом соблюдения законодательства РФ и нормативно-распорядительных документов:</p>

      <AppList
        classNames={{ list: 'licence-agreement-content-list' }}
        items={[
          'Федеральный закон от 12.04.2010 N 61-ФЗ "Об обращении лекарственных средств";',
          'Постановление Правительства РФ от 14.12.2018 № 1556 «Об утверждении Положения о системе мониторинга движения лекарственных препаратов для медицинского применения»;',
          'Постановление Правительства РФ от 2 ноября 2020 г. № 1779 “О внесении изменений в Положение о системе мониторинга движения лекарственных препаратов для медицинского применения”.',
          <span key="2.2"><strong>2.2.</strong> Сервис предоставляет возможность импортировать / экспортировать данные из / в МДЛП, а также предоставляет Пользователю возможность получения актуальной информации о лекарственных препаратах.</span>,
          <span key="2.3"><strong>2.3.</strong> Адрес Сервиса в сети Интернет:
            &nbsp;
            <Link
              to="https://mdlp.remedium-opt.ru"
              className="link"
            >
              https://mdlp.remedium-opt.ru
            </Link>
          </span>
        ]}
      />

      <h3>3. Права и обязанности сторон</h3>

      <p><strong>3.1. Права и обязанности Владельца:</strong></p>

      <p>3.1.1. Владелец обязуется предоставлять Пользователям доступ к Сервису.</p>

      <p>3.1.2. Владелец обязуется не копировать и не хранить экземпляр УКЭП Пользователя в Сервисе.</p>

      <p>3.1.3. Владелец вправе по своему усмотрению вносить изменения в настоящую Оферту, которые распространяются на все оферты, акцептованные Пользователями, без уведомления об этом Пользователя. Использование Пользователем Приложения на измененных условиях является согласием Пользователями с такими изменениями.</p>

      <p>3.1.4. Владелец вправе ограничить доступ Пользователя к любой из функций Сервиса, удалить, заблокировать Аккаунт Пользователя и предпринимать иные действия, направленные на защиту Сервиса от воздействия факторов, представляющих реально или потенциально угрозу для Пользователей и нормальной работы Сервиса. Владелец реализует указанные меры по собственному усмотрению и не несет ответственности за возможные негативные последствия таких мер для Пользователя или третьих лиц. Указанные выше действия Владелец вправе применить при нарушении Пользователем условий настоящей Оферты.</p>

      <p><strong>3.2. Права и обязанности Пользователя:</strong></p>

      <p>3.2.1 Пользователь обязуется соблюдать условия настоящей Оферты, в том числе:</p>

      <AppList
        classNames={{ list: 'licence-agreement-content-list' }}
        items={[
          'не передавать Аккаунт или доступ к нему другим лицам;',
          'не передавать УКЭП другим лицам;',
          'не осуществлять действия, направленные на нарушение нормального функционирования Сервиса;',
          'не совершать иные действия, нарушающие законодательство Российской Федерации (далее также — «РФ») и/или иное применимое законодательство, включая законодательство места пребывания Пользователя, а также условия настоящей Оферты.'
        ]}
      />

      <p>3.2.2 Пользователь осознает и принимает риски, связанные с использованием УКЭП (под рисками, связанными с использованием УКЭП, понимается возможность наступления события, влекущего за собой финансовые потери для Клиента), в частности, риски неисправности технических устройств, иные риски технического характера, в результате реализации которых подписание УКЭП будет невозможно либо будет осуществляться некорректно, риск утери/кражи устройства, и как следствие – несанкционированного использования УКЭП, риск получения доступа неуполномоченных лиц к данным, связанным с использованием УКЭП.</p>

      <p><strong>3.3.</strong> Все объекты, доступные при помощи Сервиса, в том числе элементы дизайна, тексты, графические изображения, иллюстрации, программы для ЭВМ, базы данных, и другие объекты, а также любой контент, размещённый Владельцем или третьими лицами, имеющими на это права, являются объектами исключительных прав Владельца и других правообладателей.</p>
      <p><strong>3.4.</strong> Использование каких-либо элементов Сервиса возможно только в рамках функционала, предлагаемого Сервисом. Никакие элементы Сервиса, а также контент, не могут быть использованы иным образом без предварительного разрешения правообладателя.</p>

      <h3>4. Ответственность сторон</h3>

      <p><strong>4.1.</strong> Стороны несут ответственность в соответствии с законодательством РФ и настоящей Офертой.</p>

      <p><strong>4.2.</strong> Пользователь несёт ответственность за нарушение условий Оферты, а также за допущенное Пользователем нарушение действующего законодательства РФ.</p>

      <p><strong>4.3.</strong> Пользователь соглашается с тем, что никакое программное обеспечение и сервис не свободно от ошибок.</p>

      <p><strong>4.4.</strong> Владелец не несёт ответственность за временные сбои и перерывы в работе Сервиса, вызванные ими недоступность и/или потерю информации. Владелец не несёт ответственности за любой ущерб любому оборудованию, программам для ЭВМ или информации, вызванный или связанный с использованием Сервиса, включая ущерб, причинённый изменением Сервиса.</p>

      <h3>5. Заключительные положения</h3>

      <p><strong>5.1.</strong> Технические данные, передаваемые Сервису программным обеспечением Пользователя, а также иные данные, передаваемые Пользователем Сервису, могут использоваться Владельцем по своему усмотрению любыми не запрещёнными законом способами, в том числе для таргетированной доставки Пользователю информации от Владельца.</p>

      <p><strong>5.2.</strong> Обработка персональных данных (Федеральный закон от 27.07.2006 № 152-ФЗ «О персональных данных»).</p>

      <p><strong>5.3.</strong> Все претензии, связанные с использованием Сервиса, необходимо направлять Владельцу посредством электронной почты
        &nbsp;
        <Link
          to="mailto:support@remedium-opt.ru"
          className="link"
        >
          support@remedium-opt.ru
        </Link>
        .</p>

      <p><strong>5.4.</strong> Владелец вправе отправлять Пользователю на связанный с Аккаунтом номер телефона или на указанный им при регистрации адрес электронной почты информацию, касающуюся Сервиса.</p>
    </div>
  </>
);

export const LicenceAgreement = memo(LicenceAgreementInner);