import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { DocumentCardBoxesTableColumns } from '@/shared/document/models/document-card/boxes/document-card-boxes-table-columns';

const columnVisibility = {
  [DocumentCardBoxesTableColumns.Sscc]: true,
  [DocumentCardBoxesTableColumns.Code]: true,
  [DocumentCardBoxesTableColumns.Name]: true,
  [DocumentCardBoxesTableColumns.Producer]: true,
  [DocumentCardBoxesTableColumns.Vital]: true,
  [DocumentCardBoxesTableColumns.Gtin]: true,
  [DocumentCardBoxesTableColumns.Series]: true,
  [DocumentCardBoxesTableColumns.ExpirationDate]: true,
  [DocumentCardBoxesTableColumns.PriceWithVat]: true,
  [DocumentCardBoxesTableColumns.Vat]: true,
  [DocumentCardBoxesTableColumns.TotalPriceWithVat]: true,
  [DocumentCardBoxesTableColumns.VatRate]: true,
  [DocumentCardBoxesTableColumns.Amount]: true,
  [DocumentCardBoxesTableColumns.IsProductInOrder]: true,
  [DocumentCardBoxesTableColumns.ProcessingStatus]: true,
};

const sorting = [
  {
    id: DocumentCardBoxesTableColumns.Name,
    desc: false,
  }
];

const columnOrder = [
  DocumentCardBoxesTableColumns.Sscc,
  DocumentCardBoxesTableColumns.Code,
  DocumentCardBoxesTableColumns.Name,
  DocumentCardBoxesTableColumns.Producer,
  DocumentCardBoxesTableColumns.Vital,
  DocumentCardBoxesTableColumns.Gtin,
  DocumentCardBoxesTableColumns.Series,
  DocumentCardBoxesTableColumns.ExpirationDate,
  DocumentCardBoxesTableColumns.PriceWithVat,
  DocumentCardBoxesTableColumns.Vat,
  DocumentCardBoxesTableColumns.TotalPriceWithVat,
  DocumentCardBoxesTableColumns.VatRate,
  DocumentCardBoxesTableColumns.Amount,
  DocumentCardBoxesTableColumns.IsProductInOrder,
  DocumentCardBoxesTableColumns.ProcessingStatus,
  AppTableActionColumnName,
];

const columnSizing = {
  [DocumentCardBoxesTableColumns.Sscc]: 50,
  [DocumentCardBoxesTableColumns.Code]: 20,
  [DocumentCardBoxesTableColumns.Name]: 55,
  [DocumentCardBoxesTableColumns.Producer]: 55,
  [DocumentCardBoxesTableColumns.Vital]: 20,
  [DocumentCardBoxesTableColumns.Gtin]: 40,
  [DocumentCardBoxesTableColumns.Series]: 50,
  [DocumentCardBoxesTableColumns.ExpirationDate]: 50,
  [DocumentCardBoxesTableColumns.PriceWithVat]: 30,
  [DocumentCardBoxesTableColumns.Vat]: 30,
  [DocumentCardBoxesTableColumns.TotalPriceWithVat]: 30,
  [DocumentCardBoxesTableColumns.VatRate]: 30,
  [DocumentCardBoxesTableColumns.Amount]: 40,
  [DocumentCardBoxesTableColumns.IsProductInOrder]: 30,
  [DocumentCardBoxesTableColumns.ProcessingStatus]: 30,
};

export const defaultDocumentCardBoxesTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
