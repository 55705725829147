import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';
import { NomenclatureTableColumns } from '@/modules/references/models/nomenclature/nomenclature-table-columns';

const nomenclatureTableColumnsToDtoMap: Partial<Record<NomenclatureTableColumns, keyof NomenclatureItem>> = {
  [NomenclatureTableColumns.Code]: 'code',
  [NomenclatureTableColumns.Name]: 'name',
  [NomenclatureTableColumns.Producer]: 'producerName',
  [NomenclatureTableColumns.Country]: 'producerCountry',
  [NomenclatureTableColumns.Barcode]: 'barcode',
  [NomenclatureTableColumns.Vital]: 'gnvlp',
  [NomenclatureTableColumns.Gtin]: 'gtin',
  [NomenclatureTableColumns.IsGtinFound]: 'isGtinFound',
};

export const convertNomenclatureColumnFieldToDto = (columnField: NomenclatureTableColumns) => nomenclatureTableColumnsToDtoMap[columnField];
