import { useCallback, useState } from 'react';

export interface DialogCommonState {
  isDialogOpened: boolean;
}
interface DialogHookReturnValue extends DialogCommonState {
  openDialog: () => void;
  closeDialog: () => void;
}

export function useDialog(): DialogHookReturnValue {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const openDialog = () => {
    setIsDialogOpened(true);
  };

  const closeDialog = useCallback(() => {
    setIsDialogOpened(false);
  }, []);

  return {
    isDialogOpened,
    openDialog,
    closeDialog,
  };
}
