import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { removeNonDigits } from '@/common/utils/common/string-utils';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectUsersManagementState = (state: RootState) => state.usersManagement;

const selectTableConfig = (state: RootState) => selectUsersManagementState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectDialogConfig = (state: RootState) => selectUsersManagementState(state).dialogConfig;
const selectEditingUser = (state: RootState) => selectUsersManagementState(state).editUser;
const selectIsLoadingEditUser = (state: RootState) => selectUsersManagementState(state).isLoadingEditUser;
const selectIndexEditUser = (state: RootState) => selectUsersManagementState(state).indexEditUser;
const selectIsOpenEditUserDialog = (state: RootState) => selectUsersManagementState(state).isOpenEditUserDialog;
const selectShowBlockedUsers = (state: RootState) => selectUsersManagementState(state).showBlockedUsers;

const selectPreprocessedPhoneNumber = createSelector([selectEditingUser], (user) => removeNonDigits(user.phoneNumber));

const selectSearchState = (state: RootState) => selectUsersManagementState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectPageableDataState = (state: RootState) => selectUsersManagementState(state).pageableData;
const selectUsers = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectIsLoadingUsers = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

export const UsersManagementSelectors = {
  selectPreprocessedPhoneNumber,
  selectShowBlockedUsers,
  selectEditingUser,
  selectIsLoadingEditUser,
  selectIsOpenEditUserDialog,
  selectIndexEditUser,
  selectTableConfig,
  selectTableSorting,
  selectDialogConfig,
  selectSearchState,
  selectSearchString,
  selectPageableDataState,
  selectUsers,
  selectIsLoadingUsers
};
