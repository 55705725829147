import { Updater } from '@/common/models/app-table/app-table-types';

export type ExtractPropertyType<T, K extends keyof any> = K extends keyof T ? T[K] : never;

export function getValueFromUpdater<T extends number | string | boolean | object>(action: Updater<T>, oldValue: T) {
  if (typeof action === 'function') {
    return action(oldValue);
  }

  return action;
}

export function isFunction(obj: unknown) {
  return typeof obj == 'function';
}

export function isObject(obj: unknown) {
  return typeof obj === 'object' && !!obj;
}

export function hasWindowObject() {
  return typeof window !== 'undefined' && window.document;
}

export function getLogicalValueLabel(value?: boolean) {
  if (value === undefined) {
    return '';
  }
  return value ? 'Да' : 'Нет';
}

export function isValueExist(value: unknown) {
  return value !== undefined && value !== null;
}
