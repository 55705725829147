import { axios } from '@/common/api/axios-config';
import { documentPositionUrl, documentPositionsUrl } from '@/common/api/urls';
import { DocumentCardDeletePositionBody } from '@/shared/document/models/document-card/positions/document-card-delete-position-body';
import { DocumentCardPositionType } from '@/shared/document/models/document-card/positions/document-card-position-type';
import { GetDocumentCardPositionsResponse } from '@/shared/document/models/document-card/positions/get-document-card-positions-response';
import { AxiosResponse } from 'axios';

export class DocumentCardPositionsRequests {

  static getAllPositions(docUuid: string, positionType: DocumentCardPositionType, signal?: AbortSignal): Promise<AxiosResponse<GetDocumentCardPositionsResponse>> {
    return axios.get(documentPositionsUrl(docUuid), {
      params: { positionType },
      signal
    });
  }

  static deletePosition(data: DocumentCardDeletePositionBody): Promise<AxiosResponse<void>> {
    return axios.delete(documentPositionUrl, { data });
  }

}
