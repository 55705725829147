import { axios } from '@/common/api/axios-config';
import { baseRemainUrl } from '@/common/api/urls';
import { AddRemainsBody } from '@/modules/disposals/models/remains/add-remains-body';
import { AxiosResponse } from 'axios';

export class DisposalsRequests {

  static addRemainsToDocument(body: AddRemainsBody): Promise<AxiosResponse<void>> {
    return axios.post(baseRemainUrl, body);
  }

}