import Sync from '@/assets/icons/sync.svg?react';
import React, { FC, memo } from 'react';
import './sync-icon.scss';

interface SyncIconProps {
  isLoading?: boolean;
  noShadow?: boolean;
  notClickable?: boolean;
}

const SyncIconInner: FC<SyncIconProps> = ({ isLoading, noShadow = false, notClickable }) => (
  <div className={`sync-icon ${isLoading ? 'loading' : ''} ${noShadow ? 'no-shadow' : ''} ${notClickable ? 'not-clickable' : ''}`}>
    <Sync className="sync" />
  </div>
);

export const SyncIcon = memo(SyncIconInner);
