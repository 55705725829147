import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { DocumentCardHistoryTableProps } from '@/shared/document/components/document-card-history-table/DocumentCardHistoryTable';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type DocumentCardHistoryTableEventHandlersHookParams = Pick<DocumentCardHistoryTableProps, 'tableConfigActions'>;

export function useDocumentCardHistoryTableEventHandlers({ tableConfigActions }: DocumentCardHistoryTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(tableConfigActions.updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(tableConfigActions.updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(tableConfigActions.updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(tableConfigActions.updateTableColumnSizingState(value));
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
  };
}
