import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { FavoritesTableColumns } from '@/modules/favorites/models/favorites-table-columns';

const columnVisibility = {
  [FavoritesTableColumns.Favorite]: true,
  [FavoritesTableColumns.ActivityPlace]: true,
  [FavoritesTableColumns.Partner]: true,
  [FavoritesTableColumns.Type]: true,
  [FavoritesTableColumns.DocNumber]: true,
  [FavoritesTableColumns.Date]: true,
  [FavoritesTableColumns.PriceWithVat]: true,
  [FavoritesTableColumns.Status]: true,
  [FavoritesTableColumns.Comment]: true,
};

const sorting = [
  {
    id: FavoritesTableColumns.CreatedDate,
    desc: true,
  }
];

const columnOrder = [
  FavoritesTableColumns.Favorite,
  FavoritesTableColumns.ActivityPlace,
  FavoritesTableColumns.Partner,
  FavoritesTableColumns.Type,
  FavoritesTableColumns.DocNumber,
  FavoritesTableColumns.Date,
  FavoritesTableColumns.PriceWithVat,
  FavoritesTableColumns.Status,
  FavoritesTableColumns.Comment,
  AppTableActionColumnName,
];

const columnSizing = {
  [FavoritesTableColumns.Favorite]: 50,
  [FavoritesTableColumns.ActivityPlace]: 70,
  [FavoritesTableColumns.Partner]: 100,
  [FavoritesTableColumns.Type]: 60,
  [FavoritesTableColumns.DocNumber]: 60,
  [FavoritesTableColumns.Date]: 50,
  [FavoritesTableColumns.PriceWithVat]: 50,
  [FavoritesTableColumns.Status]: 60,
  [FavoritesTableColumns.Comment]: 70,
};

export const defaultFavoritesTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
