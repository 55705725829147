import { useAppToast } from '@/common/hooks/use-app-toast';
import { isErrorCodeForValidation, isErrorCodeWithoutNotification } from '@/common/models/error-code';
import { ErrorModel } from '@/common/models/error-model';
import { FieldErrors } from '@/common/utils/server-validation/field-errors';
import { useMemo, useState } from 'react';

export function useRequestError<T extends string>() {

  const toast = useAppToast();

  const [fieldErrors, setFieldErrorsInner] = useState<FieldErrors<T>>({});

  const resetFieldErrors = () => {
    setFieldErrorsInner({});
  };

  const showErrorNotification = (message: string) => {
    message && toast(message, 'error');
  };

  const hasFieldError = useMemo(() => !!Object.keys(fieldErrors).length, [fieldErrors]);

  const setFieldErrors = (error: ErrorModel<T>) => {
    if (isErrorCodeForValidation(error?.code)) {
      const validationResult = Object.keys(error?.args).length
        ? Object.keys(error?.args)
          .reduce((acc, current: T) => ({
            ...acc,
            [current]: {
              error: true,
              message: error?.args[current]
            }
          }), {})
        : [];
      setFieldErrorsInner(validationResult);
      return;
    }

    if (isErrorCodeWithoutNotification(error?.code)) {
      return;
    }

    if (error?.userMessage) {
      showErrorNotification(error.userMessage);
      return;
    }

    showErrorNotification('Произошла внутренняя ошибка');
    console.error(error);
  };

  return {
    fieldErrors,
    hasFieldError,
    setFieldErrors,
    resetFieldErrors
  };
}
