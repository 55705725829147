import { AppTableActionColumnName, AppTableSelectColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { ScanningTableColumns } from '@/shared/scanning-view/models/scanning-table-columns';

const columnVisibility = {
  [ScanningTableColumns.Sgtin]: true,
  [ScanningTableColumns.Name]: true,
  [ScanningTableColumns.Producer]: true,
  [ScanningTableColumns.Gtin]: true,
  [ScanningTableColumns.Series]: true,
  [ScanningTableColumns.Status]: true,
};

const columnOrder = [
  AppTableSelectColumnName,
  ScanningTableColumns.Sgtin,
  ScanningTableColumns.Name,
  ScanningTableColumns.Producer,
  ScanningTableColumns.Gtin,
  ScanningTableColumns.Series,
  ScanningTableColumns.Status,
  AppTableActionColumnName,
];

const columnSizing = {
  [ScanningTableColumns.Sgtin]: 170,
  [ScanningTableColumns.Name]: 170,
  [ScanningTableColumns.Producer]: 170,
  [ScanningTableColumns.Gtin]: 170,
  [ScanningTableColumns.Series]: 170,
  [ScanningTableColumns.Status]: 120,
};

export const defaultScanningTableConfig: SavedTableConfig = {
  columnVisibility,
  columnOrder,
  columnSizing,
};
