import { FavoriteDocument } from '@/modules/favorites/models/favorite-document';
import { FavoritesTableColumns } from '@/modules/favorites/models/favorites-table-columns';

const favoritesColumnsToDtoMap: Partial<Record<FavoritesTableColumns, keyof FavoriteDocument>> = {
  [FavoritesTableColumns.ActivityPlace]: 'activityPlaceName',
  [FavoritesTableColumns.Partner]: 'partnerOrganizationName',
  [FavoritesTableColumns.Type]: 'docType',
  [FavoritesTableColumns.DocNumber]: 'docNumber',
  [FavoritesTableColumns.Date]: 'docDate',
  [FavoritesTableColumns.CreatedDate]: 'createdDate',
  [FavoritesTableColumns.PriceWithVat]: 'totalPriceWithVat',
  [FavoritesTableColumns.Status]: 'docState',
  [FavoritesTableColumns.Comment]: 'comment',
};

type ReceiptsDtoToColumnsMap = Record<keyof FavoriteDocument, FavoritesTableColumns>;

const favoritesDtoToColumnsMap: ReceiptsDtoToColumnsMap =
  Object.entries(favoritesColumnsToDtoMap).reduce((obj, [key, value]) => ({
    ...obj,
    [value]: key,
  }), {} as ReceiptsDtoToColumnsMap);

export const convertFavoritesColumnFieldToDto = (columnField: FavoritesTableColumns) => favoritesColumnsToDtoMap[columnField];
export const convertFavoritesDtoFieldToColumn = (dtoField: keyof FavoriteDocument) => favoritesDtoToColumnsMap[dtoField];