import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RemainsState } from '@/shared/remains/store/index';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

interface RemainsSelectorsParams {
  selectRemainsState: (state: RootState) => RemainsState;
}

export const createRemainsSelectors = ({ selectRemainsState }: RemainsSelectorsParams) => {
  const selectSearchState = (state: RootState) => selectRemainsState(state).search;
  const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

  const selectFilterConfig = (state: RootState) => selectRemainsState(state).filterConfig;
  const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
  const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
  const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);

  const selectRowsSelection = (state: RootState) => selectRemainsState(state).rowsSelection;
  const selectRowsSelectionIds = createSelector([selectRowsSelection], (rowsSelection) => Object.keys(rowsSelection));
  const selectSavedTableConfig = (state: RootState) => selectRemainsState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;
  const selectIsExportSelectedInProcess = (state: RootState) => selectRemainsState(state).isExportSelectedInProcess;
  const selectIsExportAllInProcess = (state: RootState) => selectRemainsState(state).isExportAllInProcess;

  const selectTableConfig = createSelector(
    [selectRowsSelection, selectSavedTableConfig],
    (rowSelection, savedTableConfig) => ({ rowSelection, ...savedTableConfig })
  );

  const selectPageableDataState = (state: RootState) => selectRemainsState(state).pageableData;
  const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
  const selectTotalItems = createSelector([selectPageableDataState], PageableDataSelectors.selectTotalItems);
  const selectIsLoadingData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

  return {
    selectSearchState,
    selectSearchString,
    selectFilterConfig,
    selectIsPanelOpen,
    selectFilters,
    selectSelectedFiltersCount,
    selectRowsSelection,
    selectRowsSelectionIds,
    selectTableConfig,
    selectTableSorting,
    selectIsExportSelectedInProcess,
    selectIsExportAllInProcess,
    selectPageableDataState,
    selectData,
    selectTotalItems,
    selectIsLoadingData,
  };
};
