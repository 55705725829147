import { LoadingStatus } from '@/common/models/loading-status';
import { portalHeaderModuleName } from '@/modules/portal/constants/portal-module-names';
import { HeaderInfo } from '@/modules/portal/models/header-info';
import { getHeaderInfo } from '@/modules/portal/store/header/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface HeaderState extends HeaderInfo {
  loadingStatus: LoadingStatus;
  isOpenCertificatesListDialog: boolean;
}

const initialState: HeaderState = {
  firstName: '',
  lastName: '',
  organizationName: '',
  inn: '',
  isTokenExpired: false,
  tokenExpiresAt: '',
  loadingStatus: undefined,
  isOpenCertificatesListDialog: false
};

export const headerSlice = createSlice({
  name: portalHeaderModuleName,
  initialState,
  reducers: {
    setIsOpenCertificatesListDialog(state, action: PayloadAction<boolean>) {
      state.isOpenCertificatesListDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHeaderInfo.pending, (state) => {
        state.loadingStatus = LoadingStatus.Pending;
      })
      .addCase(getHeaderInfo.fulfilled, (state, action: PayloadAction<HeaderInfo>) => {
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.organizationName = action.payload.organizationName;
        state.inn = action.payload.inn;
        state.isTokenExpired = action.payload.isTokenExpired;
        state.tokenExpiresAt = action.payload.tokenExpiresAt;
        state.loadingStatus = LoadingStatus.Fulfilled;
      })
      .addCase(getHeaderInfo.rejected, (state) => {
        state.loadingStatus = LoadingStatus.Rejected;
      });
  }
});

export const {
  setIsOpenCertificatesListDialog
} = headerSlice.actions;

export default headerSlice.reducer;
