import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DocumentCardScanningPageProps } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardScanningItemHookParams = Pick<DocumentCardScanningPageProps, 'documentCardScanningSelectors' | 'documentCardScanningApi'>;

export function useDocumentCardScanningItem({ documentCardScanningSelectors, documentCardScanningApi }: DocumentCardScanningItemHookParams) {
  const { asyncDispatch } = useAsyncDispatch();

  const lastScannedItem = useSelector(documentCardScanningSelectors.selectLastScannedItem);
  const isGettingLastScannedItemInProcess = useSelector(documentCardScanningSelectors.selectIsGettingLastScannedItemInProcess);

  const onScanningItem = useCallback((scannedText: string) => asyncDispatch(() => documentCardScanningApi.addScannedItemInfo(scannedText)), []);

  return {
    lastScannedItem,
    isGettingLastScannedItemInProcess,
    onScanningItem,
  };
}
