import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { FileOperationsTableColumns } from '@/modules/file-operations/models/file-operations-table-columns';

const columnVisibility = {
  [FileOperationsTableColumns.OperationDate]: true,
  [FileOperationsTableColumns.FileName]: true,
  [FileOperationsTableColumns.FileType]: true,
  [FileOperationsTableColumns.FileState]: true,
  [FileOperationsTableColumns.OperationType]: true,
};

const sorting = [
  {
    id: FileOperationsTableColumns.OperationDate,
    desc: true,
  }
];

const columnOrder = [
  FileOperationsTableColumns.OperationDate,
  FileOperationsTableColumns.FileName,
  FileOperationsTableColumns.FileType,
  FileOperationsTableColumns.OperationType,
  FileOperationsTableColumns.FileState,
  AppTableActionColumnName,
];

const columnSizing = {
  [FileOperationsTableColumns.OperationDate]: 60,
  [FileOperationsTableColumns.FileName]: 100,
  [FileOperationsTableColumns.FileType]: 60,
  [FileOperationsTableColumns.FileState]: 60,
  [FileOperationsTableColumns.OperationType]: 60,
};

export const defaultFileOperationsTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
