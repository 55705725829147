import { AppButton } from '@/common/components/app-button/AppButton';
import { UserProfileContent } from '@/modules/portal/components/user-profile/components/user-profile-content/UserProfileContent';
import { UserProfileLogoutButton } from '@/modules/portal/components/user-profile/components/user-profile-logout-button/UserProfileLogoutButton';
import { UserProfileSelectors } from '@/modules/portal/store/user-profile/selectors';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import './user-profile-body.scss';

interface UserProfileBodyProps {
  onChangePasswordDialogOpen: () => void;
}

const UserProfileBodyInner: FC<UserProfileBodyProps> = ({ onChangePasswordDialogOpen }) => {

  const profileData = useSelector(UserProfileSelectors.selectProfileData);

  return (
    <div className="user-profile-body">
      <UserProfileContent userProfileInfo={profileData} />
      <div className="user-profile-action-buttons">
        <AppButton
          label="Сменить пароль"
          variant="outlined"
          onClick={onChangePasswordDialogOpen}
        />
        <UserProfileLogoutButton />
      </div>
    </div >
  );
};

export const UserProfileBody = memo(UserProfileBodyInner);
