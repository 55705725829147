import { receiptCardBoxesModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardBoxesApi } from '@/modules/receipts/store/receipt-card/boxes/async-thunks';
import { createDocumentCardBoxesSlice } from '@/shared/document/store/document-card/boxes';

export const receiptCardBoxesSlice = createDocumentCardBoxesSlice({
  moduleName: receiptCardBoxesModuleName,
  savedTableConfigName: 'receipt-card-boxes-table',
  api: receiptCardBoxesApi,
});

export const receiptCardBoxesRootActions = receiptCardBoxesSlice.rootSlice.actions;
export const receiptCardBoxesTableConfigActions = receiptCardBoxesSlice.documentCardBoxesTableConfigSlice.actions;

export default receiptCardBoxesSlice.rootSlice.reducer;
