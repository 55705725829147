import React, { FC } from 'react';
import './organization-info-page-layout.scss';

interface OrganizationInfoPageLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export const OrganizationInfoPageLayout: FC<OrganizationInfoPageLayoutProps> = ({ children, className }) => {
  return (
    <div className={`organization-info-page-layout ${className ?? ''}`}>
      { children }
    </div>
  );
};
