import { legalEntitiesActivityPlacesModuleName } from '@/modules/references/constants/references-module-names';
import { LegalEntityActivityPlace } from '@/modules/references/models/legal-entities-activity-places/legal-entity-activity-place';
import { ReferencesRequests } from '@/modules/references/requests/references-requests';
import { LegalEntitiesActivityPlacesSelectors } from '@/modules/references/store/legal-entities-activity-places/selectors';
import { RootState } from '@/store';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllLegalEntitiesActivityPlaces = createAsyncThunkWrapper<
  LegalEntityActivityPlace[],
  void
>(
  `${legalEntitiesActivityPlacesModuleName}/getAllLegalEntitiesActivityPlaces`,
  (_, { getState, signal }) => {
    const partnerUuid = LegalEntitiesActivityPlacesSelectors.selectLegalEntityPartnerUuid(getState());
    return ReferencesRequests.getAllLegalEntityActivityPlaces(partnerUuid, signal)
      .then((res) => res.data.places);
  },
  {
    condition: asyncThunkConditionOption((state: RootState) => {
      const isLoading = LegalEntitiesActivityPlacesSelectors.selectIsLoading(state);
      const partnerUuid = LegalEntitiesActivityPlacesSelectors.selectLegalEntityPartnerUuid(state);
      return isLoading || !partnerUuid;
    })
  }
);
