import { LegalEntitiesTableColumns } from '@/modules/references/models/legal-entities/legal-entities-table-columns';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';

const legalEntitiesTableColumnsToDtoMap: Partial<Record<LegalEntitiesTableColumns, keyof LegalEntity>> = {
  [LegalEntitiesTableColumns.Code]: 'clientIdCRM',
  [LegalEntitiesTableColumns.FullName]: 'fullOrganizationName',
  [LegalEntitiesTableColumns.ShortName]: 'shortOrganizationName',
  [LegalEntitiesTableColumns.Inn]: 'inn',
  [LegalEntitiesTableColumns.Kpp]: 'kpp',
  [LegalEntitiesTableColumns.RegNumber]: 'registrationNumber',
  [LegalEntitiesTableColumns.Comment]: 'description',
};

type LegalEntitiesDtoToColumnsMap = Record<keyof LegalEntity, LegalEntitiesTableColumns>;

const legalEntitiesDtoToColumnsMap: LegalEntitiesDtoToColumnsMap =
  Object.entries(legalEntitiesTableColumnsToDtoMap).reduce((obj, [key, value]) => ({
    ...obj,
    [value]: key,
  }), {} as LegalEntitiesDtoToColumnsMap);

export const convertLegalEntityColumnFieldToDto = (columnField: LegalEntitiesTableColumns) => legalEntitiesTableColumnsToDtoMap[columnField];
export const convertLegalEntityDtoFieldToColumn = (dtoField: keyof LegalEntity) => legalEntitiesDtoToColumnsMap[dtoField];
