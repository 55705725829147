import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { Process } from '@/modules/references/models/processes/process';
import { ProcessesTableColumns } from '@/modules/references/models/processes/processes-table-columns';
import { useMemo } from 'react';

export function useProcessesTableSettings() {
  const searchPlaceholder = getSearchString([Labels.scheme, Labels.description, Labels.schemaType]);

  const columns = useMemo<AppTableColumnDefType<Process>[]>(
    () => [
      {
        id: ProcessesTableColumns.Scheme,
        header: Labels.scheme,
        accessorKey: 'schemaName',
      },
      {
        id: ProcessesTableColumns.Description,
        header: Labels.description,
        accessorKey: 'description',
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        id: ProcessesTableColumns.Application,
        header: Labels.schemaType,
        accessorKey: 'schemaType',
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
    ], []);

  return {
    columns,
    searchPlaceholder,
  };
}
