import { hasWindowObject, isFunction, isObject } from '@/common/utils/common/types-utils';

export const disableReactDevTools = () => {
  // @ts-ignore
  const disable = import.meta.env.VITE_DISABLE_DEV_TOOLS === 'true';
  if (disable) {
    disableReactDevToolsInner();
  }
};

function disableReactDevToolsInner() {
  if (hasWindowObject()) {
    const devTools = (window as { [key: string]: any; }).__REACT_DEVTOOLS_GLOBAL_HOOK__;
    // Ensure the React Developer Tools global hook exists
    if (!isObject(devTools)) {
      return;
    }

    // Replace all global hook properties with a no-op function or a null value
    for (const prop in devTools) {
      if (prop === 'renderers') {
        devTools[prop] = new Map();
      } else {
        devTools[prop] = isFunction(devTools[prop]) ? Function.prototype : null;
      }
    }
  }
}
