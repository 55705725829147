import { OrganizationInfoDto } from '@/modules/organization-profile/models/dto/organization-info-dto';

export interface OrganizationInfo {
  name: string;
  inn: string;
  kpp: string;
  ogrn: string;
  legalAddress: string;
  registrationNumber: string;
  activityPlacesCount: number;
}

export function convertOrganizationInfoFromDtoToModel({ organizationName, inn, kpp, ogrn, legalAddress, registrationNumber, countOfActivityPlaces }: OrganizationInfoDto) {
  return {
    name: organizationName,
    inn,
    kpp,
    ogrn,
    legalAddress,
    registrationNumber,
    activityPlacesCount: countOfActivityPlaces,
  };
}
