import { DocumentCardActions } from '@/shared/document/store/document-card/card';
import { useAppDispatch } from '@/store';
import React, { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

interface DocumentCardRootPageProps {
  documentCardActions: DocumentCardActions;
}

export const DocumentCardRootPage: FC<DocumentCardRootPageProps> = ({ documentCardActions }) => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(documentCardActions.setDocumentId(id));

    return () => { dispatch(documentCardActions.setDocumentId('')); };
  }, []);

  return <Outlet />;
};
