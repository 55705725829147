import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DocumentCardNotesProps } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardNotesAddingHookParams = Pick<DocumentCardNotesProps, 'documentCardNotesSelectors' | 'documentCardNotesApi'> & {
  onNoteAdded?: () => void;
};

export function useDocumentCardNotesAdding({ documentCardNotesSelectors, documentCardNotesApi, onNoteAdded }: DocumentCardNotesAddingHookParams) {
  const { asyncDispatch } = useAsyncDispatch();

  const isAddingNoteInProgress = useSelector(documentCardNotesSelectors.selectIsAddingNoteInProgress);

  const [newNote, setNewNote] = useState('');

  const onAddNoteInput = useCallback((value: string) => {
    setNewNote(value);
  }, []);

  const addNewNote = useCallback(() => {
    if (!newNote) {
      return;
    }

    asyncDispatch(
      () => documentCardNotesApi.addNote(newNote),
      () => {
        setNewNote('');
        onNoteAdded?.();
      },
    );
  }, [newNote]);

  return {
    newNote,
    isAddingNoteInProgress,
    onAddNoteInput,
    addNewNote,
  };
}
