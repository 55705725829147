import authStateReducer from '@/modules/auth/store/auth-state';
import inviteManagerReducer from '@/modules/auth/store/invite-manager';
import loginReducer from '@/modules/auth/store/login/index';
import passwordRecoveryReducer from '@/modules/auth/store/password-recovery';
import registrationReducer from '@/modules/auth/store/registration/index';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base auth reducer.
 */
const authReducer = combineReducers({
  authState: authStateReducer,
  registration: registrationReducer,
  login: loginReducer,
  passwordRecovery: passwordRecoveryReducer,
  inviteManager: inviteManagerReducer
});

export default authReducer;
