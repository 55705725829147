import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { DocumentCardNotesState } from '@/shared/document/store/document-card/notes/index';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

interface DocumentCardNotesSelectorsParams {
  selectDocumentCardNotesState: (state: RootState) => DocumentCardNotesState;
}

export const createDocumentCardNotesSelectors = ({ selectDocumentCardNotesState }: DocumentCardNotesSelectorsParams) => {
  const selectPageableDataState = (state: RootState) => selectDocumentCardNotesState(state).pageableData;
  const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
  const selectIsLoadingData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);
  const selectDataSorting = (state: RootState) => selectDocumentCardNotesState(state).sorting;
  const selectIsAddingNoteInProgress = (state: RootState) => selectDocumentCardNotesState(state).isAddingNoteInProgress;
  const selectIsEditingNoteInProgress = (state: RootState) => selectDocumentCardNotesState(state).isEditingNoteInProgress;
  const selectIsDeletingNoteInProgress = (state: RootState) => selectDocumentCardNotesState(state).isDeletingNoteInProgress;
  const selectIsRevertingNoteInProgress = (state: RootState) => selectDocumentCardNotesState(state).isRevertingNoteInProgress;

  return {
    selectPageableDataState,
    selectData,
    selectIsLoadingData,
    selectDataSorting,
    selectIsAddingNoteInProgress,
    selectIsEditingNoteInProgress,
    selectIsDeletingNoteInProgress,
    selectIsRevertingNoteInProgress,
  };
};
