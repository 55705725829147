import { saleCardNotesModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardSelectors } from '@/modules/sale/store/sale-card/card/selectors';
import { saleCardNotesSelectors } from '@/modules/sale/store/sale-card/notes/selectors';
import { createDocumentCardNotesThunks } from '@/shared/document/store/document-card/notes/async-thunks';

export const saleCardNotesApi = createDocumentCardNotesThunks({
  moduleName: saleCardNotesModuleName,
  documentCardSelectors: saleCardSelectors,
  documentCardNotesSelectors: saleCardNotesSelectors,
});
