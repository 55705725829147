export enum DocumentCardTab {
  All = 'All',
  Boxes = 'Boxes',
  Positions = 'Positions',
  History = 'History',
  Notes = 'Notes',
}

const documentCardTabsTitles = {
  [DocumentCardTab.All]: 'Всего',
  [DocumentCardTab.Boxes]: 'Коробы',
  [DocumentCardTab.Positions]: 'Позиции',
  [DocumentCardTab.History]: 'История',
  [DocumentCardTab.Notes]: 'Заметки',
};

export const getDocumentCardTabsTitle = (key: DocumentCardTab): string => documentCardTabsTitles[key];
