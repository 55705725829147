import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectSharedFavoritesState = (state: RootState) => state.shared.favorites;

const selectFavoriteDocumentsInProgress = (state: RootState) => selectSharedFavoritesState(state).favoriteDocumentsInProgress;

const selectFavoriteDocUuid = (state: RootState, docUuid: string) => docUuid;

const selectIsChangingInProgress = createSelector(
  [selectFavoriteDocumentsInProgress, selectFavoriteDocUuid],
  (favoriteDocumentsInProgress, docUuid) => !!favoriteDocumentsInProgress[docUuid]
);

export const SharedFavoritesSelectors = {
  selectFavoriteDocumentsInProgress,
  selectIsChangingInProgress
};
