import disposalCard from '@/modules/disposals/store/disposal-card/disposal-card-reducer';
import disposals from '@/modules/disposals/store/disposals';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base disposals reducer.
 */
const disposalsReducer = combineReducers({
  disposals,
  disposalCard,
});

export default disposalsReducer;
