import { DialogCommonState } from '@/common/hooks/use-dialog';
import { nomenclatureMdlpComparisonModuleName } from '@/modules/references/constants/references-module-names';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';
import { getMdlpProduct, syncNomenclatureWithMdlpProduct } from '@/modules/references/store/nomenclature-mdlp-comparison/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NomenclatureMdlpComparisonState extends DialogCommonState {
  currentNomenclature?: NomenclatureItem;
  mdlpProduct?: MdlpProduct,
  isLoadingData: boolean;
}

const initialState: NomenclatureMdlpComparisonState = {
  isDialogOpened: false,
  currentNomenclature: undefined,
  mdlpProduct: undefined,
  isLoadingData: false,
};

const nomenclatureMdlpComparisonSlice = createSlice({
  name: nomenclatureMdlpComparisonModuleName,
  initialState,
  reducers: {
    setIsDialogOpened(state, action: PayloadAction<boolean>) {
      state.isDialogOpened = action.payload;
    },
    updateCurrentNomenclature(state, action: PayloadAction<NomenclatureItem>) {
      state.currentNomenclature = action.payload;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof NomenclatureMdlpComparisonState) => {
          state[key] = initialState[key];
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMdlpProduct.pending, (state: NomenclatureMdlpComparisonState) => {
        state.isLoadingData = true;
      })
      .addCase(getMdlpProduct.fulfilled, (state: NomenclatureMdlpComparisonState, action: PayloadAction<MdlpProduct>) => {
        state.mdlpProduct = action.payload;
        state.isLoadingData = false;
      })
      .addCase(getMdlpProduct.rejected, (state: NomenclatureMdlpComparisonState) => {
        state.isLoadingData = false;
      })
      .addCase(syncNomenclatureWithMdlpProduct.pending, (state: NomenclatureMdlpComparisonState) => {
        state.isLoadingData = true;
      })
      .addCase(syncNomenclatureWithMdlpProduct.fulfilled, (state: NomenclatureMdlpComparisonState, action: PayloadAction<MdlpProduct>) => {
        state.mdlpProduct = action.payload;
        state.currentNomenclature.isGtinFound = !!action.payload;
        state.isLoadingData = false;
      })
      .addCase(syncNomenclatureWithMdlpProduct.rejected, (state: NomenclatureMdlpComparisonState) => {
        state.isLoadingData = false;
      });
  },
});

export const {
  setIsDialogOpened,
  updateCurrentNomenclature,
  resetState,
} = nomenclatureMdlpComparisonSlice.actions;

export default nomenclatureMdlpComparisonSlice.reducer;
