import React, { FC, ReactNode, memo } from 'react';
import './app-drop-down-container.scss';

interface AppDropDownContainerProps {
  isOpen: boolean;
  children: ReactNode;
  maxHeight: number;
  className?: string;
}

const AppDropDownContainerInner: FC<AppDropDownContainerProps> = ({ isOpen, children, maxHeight, className }) => (
  <div
    className={`app-drop-down-container ${className ?? ''}`}
    style={{
      maxHeight: isOpen ? maxHeight : 0
    }}
  >
    {children}
  </div>
);

export const AppDropDownContainer = memo(AppDropDownContainerInner);