import { AppTable } from '@/common/components/app-table/components/app-table/AppTable';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { SavedTableConfigActions } from '@/common/models/saved-table-config/saved-table-config-actions';
import { useDocumentCardPositionsTableEventHandlers } from '@/shared/document/hooks/document-card-positions/use-document-card-positions-table-event-handlers';
import { useDocumentCardPositionsTableSettings } from '@/shared/document/hooks/document-card-positions/use-document-card-positions-table-settings';
import { useDocumentCardTableData } from '@/shared/document/hooks/document-card/use-document-card-table-data';
import { Document } from '@/shared/document/models/document';
import { DocumentCardPositionsActions } from '@/shared/document/store/document-card/positions';
import { DocumentCardPositionsSelectors, DocumentsCardPositionsApi } from '@/shared/document/store/document-card/positions/types';
import '@/shared/document/styles/document-card-positions-table-styles.scss';
import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export interface DocumentCardPositionsTableProps {
  documentInfo: Document;
  selectors: DocumentCardPositionsSelectors;
  api: DocumentsCardPositionsApi;
  rootActions: DocumentCardPositionsActions;
  tableConfigActions: SavedTableConfigActions;
  maxTableHeight: number;
}

const DocumentCardPositionsTableInner: FC<DocumentCardPositionsTableProps> = ({ documentInfo, selectors, api, rootActions, tableConfigActions, maxTableHeight }) => {
  const { asyncDispatch } = useAsyncDispatch();
  const { id: documentId } = useParams();
  const { abortRequestByParam } = useAsyncDispatchAborting();

  const positions = useSelector(selectors.selectPositions);
  const isLoading = useSelector(selectors.selectIsLoadingPositions);
  const tableConfig = useSelector(selectors.selectTableConfig);
  const visibleColumns = useSelector(selectors.selectVisibleColumns);

  const { processingWay, showDeleteRowButton } = useDocumentCardTableData({ documentInfo });
  const { columns, tableClasses } = useDocumentCardPositionsTableSettings({ processingWay, visibleColumns });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onDeleteRow
  } = useDocumentCardPositionsTableEventHandlers({ api, rootActions, tableConfigActions });

  useEffect(() => {
    const dispatchResult = asyncDispatch(() => api.getAllPositions(documentId));

    return () => { abortRequestByParam(dispatchResult); };
  }, [documentId]);

  return (
    <AppTable
      data={positions}
      columns={columns}
      classes={tableClasses}
      isLoading={isLoading}
      configState={tableConfig}
      enableRowVirtualization
      showDeleteRowButton={showDeleteRowButton}
      maxHeight={`${maxTableHeight}px`}
      onDeleteRow={onDeleteRow}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
    />
  );
};

export const DocumentCardPositionsTable = memo(DocumentCardPositionsTableInner);