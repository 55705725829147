import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { disposalCardRemainsModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardRemainsSelectors } from '@/modules/disposals/store/disposal-card/remains/table/selectors';
import { buildDisposalCardRemainsFilterExpression } from '@/modules/disposals/utils/filters/disposal-remains-filter-utils';
import { Remain } from '@/shared/remains/models/remain';
import { convertRemainColumnFieldToDto } from '@/shared/remains/models/remains-table-columns-converters';
import { RemainsRequests } from '@/shared/remains/requests/remains-requests';
import { RemainsSelectors } from '@/shared/remains/store/types';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DisposalCardRemainsThunksCreationParams {
  moduleName: string;
  remainsSelectors: RemainsSelectors;
}

export const createDisposalCardRemainsThunks = ({ moduleName, remainsSelectors }: DisposalCardRemainsThunksCreationParams) => {
  const getAllRemains = createAsyncThunkWrapper<
    PageableDataResponse<Remain>,
    PageableDataBody
  >(
    `${moduleName}/getAllRemains`,
    ({ pageNumber }, { getState }) => {
      const sorting = remainsSelectors.selectTableSorting(getState());
      const filters = remainsSelectors.selectFilters(getState());
      const searchString = remainsSelectors.selectSearchString(getState());

      const body: PageableDataQueryParams = {
        page: pageNumber,
        size: AppTableItemsPageSize,
      };

      const sortParams = getRequestSorting(sorting, convertRemainColumnFieldToDto);
      if (sortParams) {
        body.sort = sortParams;
      }

      const filterParams = buildDisposalCardRemainsFilterExpression(filters, searchString);
      if (filterParams) {
        body.filter = filterParams;
      }

      return RemainsRequests.getAllRemains(body)
        .then(res => res.data);
    },
    { condition: asyncThunkConditionOption(remainsSelectors.selectIsLoadingData) },
  );

  return {
    getAllRemains,
  };
};

export const disposalCardRemainsApi = createDisposalCardRemainsThunks({
  moduleName: disposalCardRemainsModuleName,
  remainsSelectors: disposalCardRemainsSelectors,
});
