import { FilterConfigActionType } from '@/common/models/filter-config/filter-config-action-type';
import { FilterConfigActions } from '@/common/models/filter-config/filter-config-actions';
import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { isValidObject } from '@/common/utils/common/object-utils';
import { RootState, useAppDispatch } from '@/store';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * @param slice slice of filterConfigSlice
 * @param selectFilterConfig filterConfig's selector
 * @param getData func to get data
 * @param closePanel close filter panel
 */
interface FilteringDataHookParams<T> {
  filterConfigActions: FilterConfigActions<T>;
  selectFilterConfig: (state: RootState) => FilterConfigState<T>;
  getData: () => void;
  closePanel: () => void;
}

export const useFilteringData = <T>({ filterConfigActions, selectFilterConfig, getData, closePanel }: FilteringDataHookParams<T>) => {
  const dispatch = useAppDispatch();

  const { isDataFiltered, isFiltersChanged, filters } = useSelector(selectFilterConfig);

  const isValidValue = useMemo(() => isValidObject(filters), [filters]);

  const disableApplyButton = useMemo(() => !isFiltersChanged || !isValidValue, [isFiltersChanged, isValidValue]);

  const disableClearButton = useMemo(() => !isFiltersChanged && !isDataFiltered, [isFiltersChanged, isDataFiltered]);

  const clearFilter = useCallback(() => {
    dispatch(filterConfigActions.setActionType(FilterConfigActionType.Clear));

    if (isDataFiltered) {
      dispatch(filterConfigActions.clearFilter());
      getData();
    } else {
      dispatch(filterConfigActions.clearFilter());
      dispatch(filterConfigActions.resetIsFiltersChanged());
    }

    setTimeout(closePanel, 200);
  }, [isDataFiltered]);

  const applyFilter = useCallback(() => {
    dispatch(filterConfigActions.setActionType(FilterConfigActionType.Apply));
    getData();
    closePanel();
  }, []);

  return {
    disableApplyButton,
    disableClearButton,
    clearFilter,
    applyFilter
  };
};
