import { axios } from "@/common/api/axios-config";
import { initDocumentUnboxSsccUrl } from "@/common/api/urls";
import { UnboxRemainInitBody } from "@/modules/remains/models/unbox-remain-init/unbox-remain-init-body";
import { UnboxRemainInitResponse } from "@/modules/remains/models/unbox-remain-init/unbox-remain-init-response";
import { AxiosResponse } from "axios";

export class RemainsPageRequests {

  static unboxRemainInit(body: UnboxRemainInitBody): Promise<AxiosResponse<UnboxRemainInitResponse>> {
    return axios.post(initDocumentUnboxSsccUrl, body);
  }

}