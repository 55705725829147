import { DocumentCardBox } from '@/shared/document/models/document-card/boxes/document-card-box';
import { DocumentCardBoxesRequests } from '@/shared/document/requests/document-card-boxes-requests';
import { DocumentCardBoxesSelectors } from '@/shared/document/store/document-card/boxes/types';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardBoxesThunksCreationParams {
  moduleName: string;
  documentCardSelectors: DocumentCardSelectors;
  documentCardBoxesSelectors: DocumentCardBoxesSelectors;
}

export const createDocumentCardBoxesThunks = ({ moduleName, documentCardSelectors, documentCardBoxesSelectors }: DocumentCardBoxesThunksCreationParams) => {
  const getAllBoxes = createAsyncThunkWrapper<
    DocumentCardBox[],
    string
  >(`${moduleName}/getAllBoxes`,
    (docUuid, { signal }) => {
      return DocumentCardBoxesRequests.getAllBoxes(docUuid, signal)
        .then((res) => res.data.ssccBoxes);
    },
    {
      condition: asyncThunkConditionOption((state, documentId) => {
        const isLoading = documentCardBoxesSelectors.selectIsLoadingBoxes(state);
        return isLoading || !documentId;
      })
    },
  );

  const deleteBox = createAsyncThunkWrapper<
    void,
    string
  >(
    `${moduleName}/deleteBox`,
    async (boxUuid, { getState }) => {
      const documentId = documentCardSelectors.selectDocumentId(getState());
      await DocumentCardBoxesRequests.deleteBox(documentId, boxUuid);
    },
  );

  return {
    getAllBoxes,
    deleteBox,
  };
};
