import { organizationInformationPath, organizationPlacesPath } from '@/modules/organization-profile/routes/routes';
import { OrganizationInfoPage } from '@/modules/organization-profile/views/organization-info-page/OrganizationInfoPage';
import { OrganizationPlacesPage } from '@/modules/organization-profile/views/organization-places-page/OrganizationPlacesPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: organizationInformationPath,
    element: <OrganizationInfoPage />,
  },
  {
    path: organizationPlacesPath,
    element: <OrganizationPlacesPage />,
  },
];

export default routes;
