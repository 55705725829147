import { AppCard } from '@/common/components/app-card/AppCard';
import { OrganizationInfoBlock } from '@/modules/organization-profile/components/organization-info-block/OrganizationInfoBlock';
import { OrganizationOwner } from '@/modules/organization-profile/models/organization-owner';
import React, { FC, memo } from 'react';
import './organization-owner-card.scss';

interface OrganizationOwnerCardProps {
  model: OrganizationOwner;
}

const OrganizationOwnerCardInner: FC<OrganizationOwnerCardProps> = ({ model }) => (
  <AppCard>
    <div className="organization-owner-card">
      <div className="inner-row">
        <OrganizationInfoBlock
          title="Фамилия"
          value={model.lastName}
          alignment="start"
        />
        <OrganizationInfoBlock
          title="Должность"
          value={model.position}
          alignment="end"
        />
      </div>
      <div className="inner-row">
        <OrganizationInfoBlock
          title="Имя"
          value={model.firstName}
          alignment="start"
        />
        <OrganizationInfoBlock
          title="Контактный телефон"
          value={model.phoneNumber}
          alignment="end"
        />
      </div>
      <div className="inner-row">
        <OrganizationInfoBlock
          title="Отчество"
          value={model.secondName}
          alignment="start"
        />
        <OrganizationInfoBlock
          title="Email"
          value={model.email}
          alignment="end"
        />
      </div>
    </div>
  </AppCard>
);

export const OrganizationOwnerCard = memo(OrganizationOwnerCardInner);
