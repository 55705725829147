import favorites from '@/shared/favorites/store';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base shared reducer.
 */
const sharedReducer = combineReducers({
  favorites
});

export default sharedReducer;
