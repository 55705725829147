import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { LegalEntitiesFilter } from '@/modules/references/models/legal-entities/legal-entities-filter';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';

const searchFields: (keyof LegalEntity)[] = ['clientIdCRM', 'fullOrganizationName', 'shortOrganizationName', 'description'];

function getLegalEntitiesRsqlFilter(filter: Partial<LegalEntitiesFilter>): RsqlFilterModel<LegalEntity>[] {
  return [
    {
      selector: 'inn',
      operation: RsqlFilterOperations.Equal,
      value: filter.inn,
    },
    {
      selector: 'kpp',
      operation: RsqlFilterOperations.Equal,
      value: filter.kpp,
    },
    {
      selector: 'registrationNumber',
      operation: RsqlFilterOperations.Equal,
      value: filter.regNumber
    }
  ];
}

function getLegalEntitiesSearchFilter(searchString: string): RsqlFilterModel<LegalEntity>[] {
  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: searchString,
  }));
}

export function buildLegalEntitiesFilterExpression(filter: Partial<LegalEntitiesFilter>, searchString: string): string {
  const filterModel = getLegalEntitiesRsqlFilter(filter);
  const searchModel = getLegalEntitiesSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
