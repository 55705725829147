export enum DocumentsTableColumns {
  IsFavorite = 'isFavorite',
  Partner = 'partner',
  ActivityPlace = 'activityPlace',
  DocNumber = 'docNumber',
  DocDate = 'docDate',
  Sum = 'sum',
  Order = 'order',
  Status = 'status',
  LastNotification = 'lastNotification',
  Comment = 'comment',
  CreatedDate = 'createdDate'
}
