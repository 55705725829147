import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import React, { FC, ReactNode, memo } from 'react';
import './hint-icon.scss';

interface HintIconProps {
  className?: string;
  text?: string | ReactNode;
}

const HintIconInner: FC<HintIconProps> = ({ className, text }) => (
  <AppTooltip text={text}>
    <div className={`circle-icon ${className ?? ''}`}>
      <span className="question">?</span>
    </div>
  </AppTooltip>
);

export const HintIcon = memo(HintIconInner);