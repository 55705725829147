import { SyncIcon } from '@/common/components/icons/sync-icon/SyncIcon';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { LoadingStatus } from '@/common/models/loading-status';
import { ExtendSession } from '@/modules/portal/components/extend-session/ExtendSession';
import { UpdateSync } from '@/modules/portal/components/update-sync/UpdateSync';
import { UserProfile } from '@/modules/portal/components/user-profile/UserProfile';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { getHeaderInfo } from '@/modules/portal/store/header/async-thunks';
import { PortalHeaderSelectors } from '@/modules/portal/store/header/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import './header.scss';

export const Header = () => {
  const organizationName = useSelector(PortalHeaderSelectors.selectOrganizationName);
  const inn = useSelector(PortalHeaderSelectors.selectInn);
  const loadingStatus = useSelector(PortalHeaderSelectors.selectLoadingStatus);

  const { hasAccessByPermission } = useUserPermissionsAccess();
  const { asyncDispatch } = useAsyncDispatch();

  const onRetryGettingInfo = () => asyncDispatch(getHeaderInfo);

  const canUserExtendSession = useMemo(() => hasAccessByPermission(UserPermission.ExtendUserSession), []);
  const isDataWasRejected = useMemo(() => loadingStatus === LoadingStatus.Rejected && !organizationName && !inn, [loadingStatus, organizationName, inn]);

  const headerClassName = useMemo(() =>
    `header-component ${!canUserExtendSession && !isDataWasRejected ? 'flex-end' : ''}`
    , [canUserExtendSession, isDataWasRejected]);

  return (
    <div className={headerClassName}>
      {isDataWasRejected ?
        <>
          <div className="header-block">
            Не удалось загрузить данные
          </div>
          <div className="header-block">
            <div
              className="header-block retry-getting-info"
              onClick={onRetryGettingInfo}
            >
              Повторить загрузку
              <SyncIcon isLoading={loadingStatus === LoadingStatus.Pending} />
            </div>
            <UserProfile />
          </div>
        </>
        : <>
          {canUserExtendSession && (
            <div className="header-block">
              {organizationName} (ИНН {inn})
            </div>
          )}
          <div className="header-block">
            {canUserExtendSession && <ExtendSession />}
            <UpdateSync />
            <UserProfile />
          </div>
        </>
      }
    </div>
  );
};
