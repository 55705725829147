import { getDocumentCardModuleNames } from '@/shared/document/utils/document-card-modules-names';

export const receiptModuleName = 'receipts';

export const receiptCardModuleName = 'receiptCard';

export const {
  generalPositionsModuleName: receiptCardGeneralPositionsModuleName,
  boxesModuleName: receiptCardBoxesModuleName,
  positionsModuleName: receiptCardPositionsModuleName,
  historyModuleName: receiptCardHistoryModuleName,
  notesModuleName: receiptCardNotesModuleName,
  scanningModuleName: receiptCardScanningModuleName,
} = getDocumentCardModuleNames(receiptCardModuleName);
