import { AppButton } from '@/common/components/app-button/AppButton';
import { UpdateSyncItem } from '@/modules/portal/components/update-sync/components/update-sync-item/UpdateSyncItem';
import { SyncData, SyncDataInProccess } from '@/modules/portal/models/sync/sync-data';
import { SyncType, getSyncTypeLabel } from '@/modules/portal/models/sync/sync-type';
import React, { FC, memo, useCallback } from 'react';
import './update-sync-body.scss';

interface UpdateSyncBodyProps {
  syncData: SyncData;
  showStartAllSyncs: boolean;
  syncDataInProgress: SyncDataInProccess;
  onStartSync: (type: SyncType) => void;
}

const UpdateSyncBodyInner: FC<UpdateSyncBodyProps> = ({
  syncData, showStartAllSyncs, syncDataInProgress, onStartSync
}) => {

  const onStartAllSync = useCallback(() => onStartSync(SyncType.All), []);

  return (
    <div className="update-sync-body">
      {syncData?.mdlpGlobalSyncInfo && (
        <div className="update-sync-body-block">
          <div className="update-sync-body-title">Системная синхронизация</div>
          <UpdateSyncItem syncItem={syncData.mdlpGlobalSyncInfo[SyncType.Partners]} />
          <UpdateSyncItem syncItem={syncData.mdlpGlobalSyncInfo[SyncType.Products]} />
        </div>
      )}
      {syncData?.mdlpSyncInfo && (
        <div className="update-sync-body-block">
          <div className="update-sync-body-title">Синхронизация данных организации</div>
          <UpdateSyncItem
            syncItem={syncData.mdlpSyncInfo[SyncType.ReceiptMed]}
            isLoading={syncDataInProgress?.[SyncType.ReceiptMed]}
            onStartSync={onStartSync}
          />
          <UpdateSyncItem
            syncItem={syncData.mdlpSyncInfo[SyncType.SaleMed]}
            isLoading={syncDataInProgress?.[SyncType.SaleMed]}
            onStartSync={onStartSync}
          />
          <UpdateSyncItem
            syncItem={syncData.mdlpSyncInfo[SyncType.DisposalMed]}
            isLoading={syncDataInProgress?.[SyncType.DisposalMed]}
            onStartSync={onStartSync}
          />
          <UpdateSyncItem
            syncItem={syncData.mdlpSyncInfo[SyncType.RemainMed]}
            isLoading={syncDataInProgress?.[SyncType.RemainMed]}
            onStartSync={onStartSync}
          />
          <UpdateSyncItem
            syncItem={syncData.mdlpSyncInfo[SyncType.Interactions]}
            isLoading={syncDataInProgress?.[SyncType.Interactions]}
            hintText="Данные из Журнала взаимодействий с МДЛП"
            onStartSync={onStartSync}
          />
          <UpdateSyncItem
            syncItem={syncData.mdlpSyncInfo[SyncType.OtherSyncs]}
            isLoading={syncDataInProgress?.[SyncType.OtherSyncs]}
            hintText="Синхронизация: Мест деятельности, Информации об организации"
            onStartSync={onStartSync}
          />
        </div>
      )}
      {showStartAllSyncs && (
        <AppButton
          label={getSyncTypeLabel(SyncType.All)}
          variant="outlined"
          hint={<div className="update-sync-body-hint-text">Между запуском синхронизаций каждого типа должно пройти некоторое время.<br /> Доступными для запуска считаются синхронизации, у которых время следующего запуска уже возможно (доступность запуска можно просмотреть наведя курсор на иконку статуса нужной синхронизации)</div>}
          onClick={onStartAllSync}
        />
      )}
    </div>
  );
};

export const UpdateSyncBody = memo(UpdateSyncBodyInner);
