import saleCard from '@/modules/sale/store/sale-card/sale-card-reducer';
import sales from '@/modules/sale/store/sales';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base sales reducer.
 */
const salesReducer = combineReducers({
  sales,
  saleCard,
});

export default salesReducer;
