import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { MdlpTableColumns } from '@/modules/references/models/mdlp-products/mdlp-table-columns';

const columnVisibility = {
  [MdlpTableColumns.Name]: true,
  [MdlpTableColumns.Producer]: true,
  [MdlpTableColumns.Country]: true,
  [MdlpTableColumns.NonProprietaryName]: true,
  [MdlpTableColumns.Vital]: true,
  [MdlpTableColumns.Gtin]: true,
};

const sorting = [];

const columnOrder = [
  MdlpTableColumns.Name,
  MdlpTableColumns.Producer,
  MdlpTableColumns.Country,
  MdlpTableColumns.NonProprietaryName,
  MdlpTableColumns.Vital,
  MdlpTableColumns.Gtin,
  AppTableActionColumnName,
];

const columnSizing = {
  [MdlpTableColumns.Name]: 60,
  [MdlpTableColumns.Producer]: 60,
  [MdlpTableColumns.Country]: 60,
  [MdlpTableColumns.NonProprietaryName]: 60,
  [MdlpTableColumns.Vital]: 20,
  [MdlpTableColumns.Gtin]: 40,
};

export const defaultMdlpProductsTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
