import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { HeaderWithLoader } from '@/common/components/custom/header-with-loader/HeaderWithLoader';
import { Certificate } from '@/common/models/certificate';
import { LoadingStatus } from '@/common/models/loading-status';
import { CertificateListTable } from '@/modules/certificates/components/certificates-list-dialog/components/certificate-list-table/CertificateListTable';
import { setPreSelectedCertificate, setSelectedCertificate, setSelectedCertificatesFromStorage } from '@/modules/certificates/store';
import { getCertificates } from '@/modules/certificates/store/async-thunks';
import { CertificatesSelectors } from '@/modules/certificates/store/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, ReactNode, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './certificates-list-dialog.scss';

interface CertificateListDialogProps {
  open: boolean;
  title: string;
  subtitle: string | ReactNode;
  selectCertificateTitleButton: string;
  tooltipText?: ReactNode;
  bottomContent?: ReactNode;
  closeDialog: () => void;
  onSelectCertificate: (preSelectedCertificate: Certificate) => void;
}

const CertificateListDialogInner: FC<CertificateListDialogProps> = ({ open, title, subtitle, selectCertificateTitleButton, bottomContent, closeDialog, tooltipText, onSelectCertificate }) => {
  const dispatch = useAppDispatch();

  const selectedCertificate = useSelector(CertificatesSelectors.selectSelectedCertificate);
  const preSelectedCertificate = useSelector(CertificatesSelectors.selectPreSelectedCertificate);
  const certificates = useSelector(CertificatesSelectors.selectCertificates);
  const loadingStatus = useSelector(CertificatesSelectors.selectLoadingStatus);
  /**
   * @preSelectedCertificate user clicked on the certificate? but didn't click button "Выбрать"
   * @selectedCertificate user clicked on button "Выбрать" and the certificate from `preSelectedCertificate` was setted in `selectedCertificate`, that using for make a signature for backend
   */

  const onSelectCertificateInner = useCallback(() => {
    dispatch(setSelectedCertificate());
    onSelectCertificate(preSelectedCertificate);
  }, [preSelectedCertificate, onSelectCertificate]);

  const onSelectPreSelectedCertificate = useCallback((certificate: Certificate) => {
    dispatch(setPreSelectedCertificate(certificate));
  }, []);

  useEffect(() => {
    dispatch(setSelectedCertificatesFromStorage());
    return () => { dispatch(setPreSelectedCertificate(undefined)); };
  }, []);

  const updateCertificateList = useCallback(() => dispatch(getCertificates()), []);

  const closeDialogInner = useCallback(() => {
    closeDialog();
    dispatch(setPreSelectedCertificate(undefined));
  }, []);

  return (
    <AppDialog
      open={open}
      classes={{ dialog: 'certificates-list' }}
      header={
        <HeaderWithLoader
          title={title}
          showLoader={loadingStatus === LoadingStatus.Pending}
          tooltip={tooltipText}
        />
      }
      subHeader={subtitle}
      onClose={closeDialogInner}
      footer={
        <div className="certificates-list-buttons">
          <AppButton
            label={selectCertificateTitleButton}
            disabled={!(preSelectedCertificate ?? selectedCertificate)}
            onClick={onSelectCertificateInner}
          />
          <AppButton
            label="Обновить"
            variant="outlined"
            onClick={updateCertificateList}
          />
        </div>
      }
    >
      {certificates.length
        ? (
          <CertificateListTable
            certificates={certificates}
            selectedCertificateThumbprint={preSelectedCertificate?.thumbprint ?? selectedCertificate?.thumbprint}
            onSelectCertificate={onSelectPreSelectedCertificate}
          />
        )
        : loadingStatus === LoadingStatus.Rejected && <h2 className="no-certificates">Сетификатов не найдено</h2>
      }
      {bottomContent}
    </AppDialog>
  );
};

export const CertificateListDialog = memo(CertificateListDialogInner);
