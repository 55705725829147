import { AppDatePicker } from '@/common/components/app-date-picker/AppDatePicker';
import { AppMultiSelect } from '@/common/components/app-multi-select/AppMultiSelect';
import { AppSelect } from '@/common/components/app-select/AppSelect';
import { Labels } from '@/common/constants/labels';
import { getEndOfDay } from '@/common/utils/common/date-utils';
import { interactionEventLabels } from '@/modules/interaction-log/models/interaction-event';
import { InteractionLogFilter } from '@/modules/interaction-log/models/interaction-log-filter';
import { InteractionOrigin, getSourceTargetLabel } from '@/modules/interaction-log/models/interaction-origin';
import { interactionStatusLabels } from '@/modules/interaction-log/models/interaction-status';
import { updateFilter } from '@/modules/interaction-log/store/interaction-log';
import { InteractionLogSelectors } from '@/modules/interaction-log/store/interaction-log/selectors';
import { useAppDispatch } from '@/store';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './interaction-log-filters.scss';

const InteractionLogFiltersInner = () => {
  const dispatch = useAppDispatch();

  const startDate = useSelector(InteractionLogSelectors.selectFilterStartDate);
  const endDate = useSelector(InteractionLogSelectors.selectFilterEndDate);
  const statuses = useSelector(InteractionLogSelectors.selectFilterStatuses);
  const events = useSelector(InteractionLogSelectors.selectFilterEvents);
  const source = useSelector(InteractionLogSelectors.selectFilterSource);

  const updateFilterStartDate = useCallback((startDate: InteractionLogFilter['startDate']) => dispatch(updateFilter({ startDate })), []);
  const updateFilterEndDate = useCallback((endDate: InteractionLogFilter['endDate']) => dispatch(updateFilter({ endDate: getEndOfDay(endDate) })), []);
  const updateFilterStatuses = useCallback((statuses: InteractionLogFilter['statuses']) => dispatch(updateFilter({ statuses })), []);
  const updateFilterEvents = useCallback((events: InteractionLogFilter['events']) => dispatch(updateFilter({ events })), []);
  const updateFilterSource = useCallback((source: InteractionLogFilter['source']) => dispatch(updateFilter({ source })), []);

  const eventsOptions = useMemo(() =>
    Object.entries(interactionEventLabels).map(([key, value]) => ({
      label: value,
      value: key,
    })), []);

  const statusesOptions = useMemo(() =>
    Object.entries(interactionStatusLabels).map(([key, value]) => ({
      label: value,
      value: key,
    })), []);

  const sourceTargetOptions = useMemo(() => {
    return [
      {
        label: getSourceTargetLabel(InteractionOrigin.Application),
        value: InteractionOrigin.Application
      },
      {
        label: getSourceTargetLabel(InteractionOrigin.Mdlp),
        value: InteractionOrigin.Mdlp
      }
    ];
  }, []);

  return (
    <div className="interaction-log-filters">
      <AppMultiSelect
        options={eventsOptions}
        value={events}
        className="interaction-log-filters-events"
        label={Labels.event}
        onChange={updateFilterEvents}
      />
      <AppDatePicker
        label={`${Labels.period} ${Labels.dateFrom}`}
        value={startDate}
        maxDate={endDate}
        onChange={updateFilterStartDate}
      />
      <AppMultiSelect
        options={statusesOptions}
        value={statuses}
        label={Labels.status}
        onChange={updateFilterStatuses}
      />
      <AppDatePicker
        label={`${Labels.period} ${Labels.dateTo}`}
        value={endDate}
        minDate={startDate}
        onChange={updateFilterEndDate}
      />
      <AppSelect
        value={source}
        showClearButton
        options={sourceTargetOptions}
        label={`${Labels.source} - ${Labels.target}`}
        onChange={updateFilterSource}
      />
    </div>
  );
};
export const InteractionLogFilters = memo(InteractionLogFiltersInner);
