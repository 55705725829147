import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import { Button } from '@mui/material';
import React, { FC, ReactNode, memo, useCallback, useMemo } from 'react';
import './app-button.scss';

export type AppButtonVariant = 'contained' | 'outlined';

interface AppButtonProps {
  className?: string;
  label?: React.ReactNode;
  variant?: AppButtonVariant;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  width?: number;
  hint?: ReactNode;
  notClickable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AppButtonInner: FC<AppButtonProps> = ({
  className,
  label,
  variant = 'contained',
  active,
  startIcon,
  endIcon,
  disabled,
  width,
  hint,
  notClickable,
  onClick,
}) => {
  const onClickInner = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (notClickable) {
      return;
    }
    onClick(event);
  }, [notClickable, onClick]);

  const classNames = useMemo(() => (
    `app-button ${className ?? ''} ${active ? 'active' : ''} ${width ? 'fixed-width' : ''} ${notClickable ? 'not-clickable' : ''}`
  ), [className, active, width, notClickable]);

  return (
    <Button
      variant={variant}
      className={classNames}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      style={{ width }}
      disableElevation={notClickable}
      disableRipple={notClickable}
      onClick={onClickInner}
    >
      <span className="app-button-label">
        {label}
      </span>
      {hint && (
        <HintIcon
          text={hint}
          className="app-button-hint"
        />
      )}
    </Button>
  );
};

export const AppButton = memo(AppButtonInner);
