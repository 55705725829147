import { getDocumentCardModuleNames } from '@/shared/document/utils/document-card-modules-names';

export const disposalModuleName = 'disposals';

export const disposalCardModuleName = 'disposalCard';

export const {
  generalPositionsModuleName: disposalCardGeneralPositionsModuleName,
  boxesModuleName: disposalCardBoxesModuleName,
  positionsModuleName: disposalCardPositionsModuleName,
  historyModuleName: disposalCardHistoryModuleName,
  notesModuleName: disposalCardNotesModuleName,
  scanningModuleName: disposalCardScanningModuleName,
  remainsModuleName: disposalCardRemainsModuleName,
} = getDocumentCardModuleNames(disposalCardModuleName);
