export enum InteractionStatus {
  Preparation = 'PREPARATION',
  SentToMdlp = 'SENT_TO_MDLP',
  SendingToMdlp = 'SENDING_TO_MDLP',
  FailedToSendToMdlp = 'FAILED_TO_SEND_TO_MDLP',
  SyncMdlp = 'SYNC_MDLP',
  AcceptedByMdlp = 'ACCEPTED_BY_MDLP',
  PartialAcceptedByMdlp = 'PARTIAL_ACCEPTED_BY_MDLP',
  NotAcceptedByMdlp = 'NOT_ACCEPTED_BY_MDLP',
  TechnicalError = 'TECHNICAL_ERROR'
}

export const interactionStatusLabels = {
  [InteractionStatus.Preparation]: 'Отправлен запрос на формирование документа',
  [InteractionStatus.SentToMdlp]: 'Отправлен в МДЛП',
  [InteractionStatus.SendingToMdlp]: 'Отправка документа',
  [InteractionStatus.FailedToSendToMdlp]: 'Ошибка отправки в МДЛП',
  [InteractionStatus.SyncMdlp]: 'Синхронизация с МДЛП',
  [InteractionStatus.AcceptedByMdlp]: 'Подтвержден МДЛП',
  [InteractionStatus.PartialAcceptedByMdlp]: 'Частично подтвержден в МДЛП',
  [InteractionStatus.NotAcceptedByMdlp]: 'Не принят МДЛП',
  [InteractionStatus.TechnicalError]: 'Техническая ошибка',
};

export const getInteractionStatusLabel = (status: InteractionStatus) => interactionStatusLabels[status];

const warningInteractionStatuses = new Set([InteractionStatus.SendingToMdlp, InteractionStatus.SentToMdlp]);
export const isWarningInteractionStatus = (status: InteractionStatus) => warningInteractionStatuses.has(status);

const errorInteractionStatuses = new Set([InteractionStatus.FailedToSendToMdlp, InteractionStatus.NotAcceptedByMdlp, InteractionStatus.TechnicalError]);
export const isErrorInteractionStatus = (status: InteractionStatus) => errorInteractionStatuses.has(status);

