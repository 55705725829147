import { DoneIcon } from '@/common/components/icons/done-icon/DoneIcon';
import { ErrorScanningStatusIcon } from '@/common/components/icons/error-scanning-status-icon/ErrorScanningStatusIcon';
import { WarningScanningStatusIcon } from '@/common/components/icons/warning-scanning-status-icon/WarningScanningStatusIcon';
import { ScanningStatus, isErrorScanningStatus, isWarningScanningStatus } from '@/shared/scanning-view/models/scanning-status';
import React, { FC, memo } from 'react';

interface ScanningViewInfoStatusIconProps {
  status: ScanningStatus;
}

const ScanningViewInfoStatusIconInner: FC<ScanningViewInfoStatusIconProps> = ({ status }) => {
  let StatusIcon;
  if (isErrorScanningStatus(status)) {
    StatusIcon = ErrorScanningStatusIcon;
  } else if (isWarningScanningStatus(status)) {
    StatusIcon = WarningScanningStatusIcon;
  } else {
    StatusIcon = DoneIcon;
  }

  return <StatusIcon width={24} height={24} />;
};

export const ScanningViewInfoStatusIcon = memo(ScanningViewInfoStatusIconInner);
