// Search
export const searchMaxLength = 150;

// Passwords
export const passwordMinLength = 8;
export const passwordMaxLength = 16;

// Users
export const fioMaxLength = 100;
export const emailMaxLength = 100;
export const positionMaxLength = 200;

// Common
export const codeMaxLength = 50;
export const innMaxLength = 10;
export const kppMaxLength = 9;
export const productProducerMaxLength = 50;
export const countryMaxLength = 50;
export const gtinMaxLength = 20;
export const ssccMaxLength = 20;
export const seriesMaxLength = 20;
export const commentMaxLength = 500;

// Notes
export const documentNoteMaxLength = 500;

// Legal entities
export const legalEntityShortNameMaxLength = 300;

//Document card
export const documentCardRejectReasonMaxLength = 200;

// Legal entity filters
export const registrationNumberMaxLength = 50;

// Mdlp product filters
export const nonProprietaryNameMaxLength = 50;

// Nomenclature filters
export const barcodeMaxLength = 48;

// Receipts filters
export const legalEntityNameMaxLength = 100;
export const sumMaxLength = 20;
