import { RootState } from '@/store';

const selectSyncState = (state: RootState) => state.portal.sync;

const selectOpenUpdateSyncDialog = (state: RootState) => selectSyncState(state).openUpdateSyncDialog;
const selectSyncData = (state: RootState) => selectSyncState(state).syncData;
const selectSyncDataInProgress = (state: RootState) => selectSyncState(state).syncDataInProgress;
const selectUpdateSyncDialogLoadingStatus = (state: RootState) => selectSyncState(state).updateSyncDialogLoadingStatus;

export const SyncSelectors = {
  selectOpenUpdateSyncDialog,
  selectSyncData,
  selectSyncDataInProgress,
  selectUpdateSyncDialogLoadingStatus
};
