import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { MdlpTableColumns } from '@/modules/references/models/mdlp-products/mdlp-table-columns';

const mdlpProductsTableColumnsToDtoMap: Record<MdlpTableColumns, keyof MdlpProduct> = {
  [MdlpTableColumns.Name]: 'name',
  [MdlpTableColumns.Producer]: 'producerName',
  [MdlpTableColumns.Country]: 'producerCountry',
  [MdlpTableColumns.NonProprietaryName]: 'mnn',
  [MdlpTableColumns.Vital]: 'gnvlp',
  [MdlpTableColumns.Gtin]: 'gtin',
};

export const convertMdlpProductColumnFieldToDto = (columnField: MdlpTableColumns) => mdlpProductsTableColumnsToDtoMap[columnField];
