import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { mdlpProductsPageableDataActions } from '@/modules/references/store/mdlp-products';
import { getAllMdlpProducts } from '@/modules/references/store/mdlp-products/async-thunks';
import { MdlpProductsSelectors } from '@/modules/references/store/mdlp-products/selectors';
import { useSelector } from 'react-redux';

export function useMdlpProductsTableDataSource() {
  const sorting = useSelector(MdlpProductsSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: mdlpProductsPageableDataActions,
    selectPageableDataState: MdlpProductsSelectors.selectPageableDataState,
    getData: getAllMdlpProducts,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
