import { AppEndAdornment } from '@/common/components/app-end-adornment/AppEndAdornment';
import { AppInputContent } from '@/common/components/app-input/components/app-input-content/AppInputContent';
import { AppInputStartAdornment } from '@/common/components/app-input/components/app-input-start-adornment/AppInputStartAdornment';
import { useAutofillCheck } from '@/common/components/app-input/hooks/use-autofill-check';
import { useMaskedInputCursor } from '@/common/components/app-input/hooks/use-masked-input-cursor';
import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import { AppInputCommonProps } from '@/common/models/app-input/app-input-common-props';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import { TextFieldCommonProps } from '@/common/models/app-input/text-field-common-props';
import { TextFieldProps } from '@/common/models/app-input/text-field-props';
import React, {
  ChangeEvent,
  ClipboardEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './app-input.scss';

interface AppInputProps extends AppInputCommonProps<string | number> {
  type?: AppInputType;
  autoComplete?: string;
  rows?: string | number;
  maxRows?: string | number;
  minRows?: string | number;
  regExp?: RegExp;
  onKeyDown?: (event: KeyboardEvent) => void;
  onPaste?: (event: ClipboardEvent) => void;
  onBlur?: () => void;
}

const AppInputInner: FC<AppInputProps> = ({
  className,
  value = '',
  label,
  placeholder,
  hint,
  type = AppInputType.Text,
  disabled = false,
  readOnly = false,
  autoFocus,
  maxLength,
  autoComplete,
  rows,
  minRows,
  maxRows,
  error,
  success,
  helperText,
  showClearButton = false,
  iconEnd,
  iconStart,
  mask,
  regExp,
  onValueChange,
  onInput,
  onKeyDown,
  onPaste,
  onBlur,
  onClearClick,
}) => {
  const inputContainerRef = useRef<HTMLDivElement | undefined>();
  const inputElementRef = useRef<HTMLInputElement | undefined>();

  const [innerValue, setInnerValue] = useState<string>(value.toString());
  const [isFocused, setIsFocused] = useState(false);

  const { hasAutofilledValue, checkAutofill } = useAutofillCheck({ inputElement: inputElementRef });

  useEffect(
    () => { inputElementRef.current = inputContainerRef.current?.getElementsByTagName('input')?.item(0); },
    [inputContainerRef.current]
  );

  useEffect(() => { setInnerValue(value.toString()); }, [value]);

  useEffect(() => { checkAutofill(); }, [innerValue]);

  useEffect(() => {
    // Trigger value change when browser pastes autocomplete value into AppInput
    if (innerValue && hasAutofilledValue) {
      onValueChange?.(innerValue);
    }
  }, [innerValue, hasAutofilledValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match(regExp) || inputValue === '') {
      setInnerValue(inputValue);

      const newValue = inputValue.trim();
      if (newValue !== value) {
        onInput?.(newValue);
      }
    }
  };

  const onBlurInner = () => {
    const newValue = innerValue?.trim();
    if (newValue !== value) {
      onValueChange?.(newValue);
    }
    setIsFocused(false);
    onBlur?.();
  };

  const maskPlaceholderChar = '_';
  const { changeCursorPositionAfterInputCharacters } = useMaskedInputCursor({
    mask,
    maskPlaceholderChar,
    input: inputElementRef,
  });

  const onSelect = (event: SyntheticEvent<HTMLInputElement>) => {
    if (mask) {
      const target = event.target as { value?: string; };
      const maskedValue = target?.value;
      setTimeout(() => changeCursorPositionAfterInputCharacters(maskedValue), 0);
    }
  };

  const onFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(true);
    const inputLength = event.target.value.length;
    event.target.setSelectionRange(inputLength, inputLength);
  };

  const showPlaceholder = !isFocused;
  const placeholderValue = showPlaceholder ? placeholder : undefined;

  const showClearButtonInner = useMemo(() => showClearButton && !!innerValue, [innerValue, showClearButton]);
  const showEndAdornment = useMemo(() => iconEnd || showClearButton, [iconEnd, showClearButton]);

  const clearValue = useCallback(() => {
    setInnerValue('');
    onInput?.('');
    onValueChange?.('');
    onClearClick?.();
    inputElementRef?.current?.focus();
  }, [inputElementRef?.current]);

  const commonTextFieldProps: TextFieldCommonProps = {
    className: `app-input ${success ? 'input-success' : ''}`,
    autoFocus,
    onChange,
    onBlur: onBlurInner,
    onFocus,
    onKeyDown,
    onPaste,
  };

  const textFieldProps: TextFieldProps = {
    type,
    label,
    placeholder: placeholderValue,
    disabled,
    autoComplete,
    rows,
    minRows,
    maxRows,
    inputProps: {
      maxLength,
      readOnly,
      onSelect,
    },
    error,
    helperText,
    InputProps: {
      endAdornment: showEndAdornment &&
        <AppEndAdornment
          showClearButton={showClearButtonInner}
          iconEnd={iconEnd}
          onClearButtonClick={clearValue}
        />,
      startAdornment: iconStart && <AppInputStartAdornment iconStart={iconStart} />,
    },
    InputLabelProps: { shrink: !!innerValue || isFocused || !!iconStart || hasAutofilledValue },
    margin: 'normal',
  };

  return (
    <div
      className={`app-input-container ${className ?? ''}`}
      ref={inputContainerRef}
    >
      <AppInputContent
        value={innerValue}
        type={type}
        commonTextFieldProps={commonTextFieldProps}
        textFieldProps={textFieldProps}
        mask={mask}
        maskPlaceholderChar={maskPlaceholderChar}
        showMask={isFocused}
      />
      {hint && <HintIcon
        className="app-input-hint-icon"
        text={hint}
      />}
    </div>
  );
};

export const AppInput = memo(AppInputInner);
