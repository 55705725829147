import { axios } from '@/common/api/axios-config';
import { mdlpSyncInfoUrl, mdlpSyncStartUrl } from '@/common/api/urls';
import { SyncData } from '@/modules/portal/models/sync/sync-data';
import { SyncType } from '@/modules/portal/models/sync/sync-type';
import { AxiosResponse } from 'axios';

export class SyncRequests {

  static getSyncInfo(): Promise<AxiosResponse<SyncData>> {
    return axios.get(mdlpSyncInfoUrl);
  }

  static startSync(type: SyncType): Promise<AxiosResponse<SyncData>> {
    return axios.get(mdlpSyncStartUrl, { params: { type } });
  }
}