import { axios } from '@/common/api/axios-config';
import { allDocumentsUrl, areAllMedicinesScannedInDocumentUrl, documentUrl, initDocumentUrl, promoteDocumentUrl, sendDocumentUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { InitDocumentBody } from '@/shared/document/models/document-card/init-document-body';
import { UpdateDocumentBody } from '@/shared/document/models/documents/update-document-body';
import { DocumentDto } from '@/shared/document/models/dto/document-dto';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { UpdateDocumentStatusBody } from '@/shared/document/models/update-document-status-body';
import { AxiosResponse } from 'axios';

export class DocumentRequests {

  static getAllDocuments(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<ShortDocumentInfo>>> {
    return axios.get(allDocumentsUrl, { params });
  }

  static getDocumentInfo(docUuid: string): Promise<AxiosResponse<DocumentDto>> {
    return axios.get(documentUrl(docUuid));
  }

  static updateDocumentStatus({ docUuid, state }: UpdateDocumentStatusBody): Promise<AxiosResponse<DocumentDto>> {
    return axios.post(promoteDocumentUrl(docUuid, state));
  }

  static updateDocument(docUuid: string, body: UpdateDocumentBody): Promise<AxiosResponse<void>> {
    return axios.patch(documentUrl(docUuid), body);
  }

  static initDocument(docUuid: string, body: InitDocumentBody): Promise<AxiosResponse<XMLDocument>> {
    return axios.post(initDocumentUrl(docUuid), body);
  }

  static sendDocument(docUuid: string, signature: string): Promise<AxiosResponse<void>> {
    return axios.post(sendDocumentUrl(docUuid), { signature });
  }

  static checkDocumentForAllScannedMedicine(docUuid: string): Promise<AxiosResponse<void>> {
    return axios.get(areAllMedicinesScannedInDocumentUrl(docUuid));
  }

}
