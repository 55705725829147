import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { DocumentCardGeneralPositionsTableColumns } from '@/shared/document/models/document-card/general-positions/document-card-general-positions-table-columns';

const columnVisibility = {
  [DocumentCardGeneralPositionsTableColumns.Code]: true,
  [DocumentCardGeneralPositionsTableColumns.Name]: true,
  [DocumentCardGeneralPositionsTableColumns.Producer]: true,
  [DocumentCardGeneralPositionsTableColumns.Vital]: true,
  [DocumentCardGeneralPositionsTableColumns.Gtin]: true,
  [DocumentCardGeneralPositionsTableColumns.Series]: true,
  [DocumentCardGeneralPositionsTableColumns.ExpirationDate]: true,
  [DocumentCardGeneralPositionsTableColumns.PriceWithVat]: true,
  [DocumentCardGeneralPositionsTableColumns.Vat]: true,
  [DocumentCardGeneralPositionsTableColumns.TotalPriceWithVat]: true,
  [DocumentCardGeneralPositionsTableColumns.VatRate]: true,
  [DocumentCardGeneralPositionsTableColumns.IsProductInOrder]: true,
  [DocumentCardGeneralPositionsTableColumns.TotalTertiaryPackages]: true,
  [DocumentCardGeneralPositionsTableColumns.TotalSecondaryPackages]: true,
  [DocumentCardGeneralPositionsTableColumns.Amount]: true,
};

const sorting = [
  {
    id: DocumentCardGeneralPositionsTableColumns.Name,
    desc: false,
  }
];

const columnOrder = [
  DocumentCardGeneralPositionsTableColumns.Code,
  DocumentCardGeneralPositionsTableColumns.Name,
  DocumentCardGeneralPositionsTableColumns.Producer,
  DocumentCardGeneralPositionsTableColumns.Vital,
  DocumentCardGeneralPositionsTableColumns.Gtin,
  DocumentCardGeneralPositionsTableColumns.Series,
  DocumentCardGeneralPositionsTableColumns.ExpirationDate,
  DocumentCardGeneralPositionsTableColumns.PriceWithVat,
  DocumentCardGeneralPositionsTableColumns.Vat,
  DocumentCardGeneralPositionsTableColumns.TotalPriceWithVat,
  DocumentCardGeneralPositionsTableColumns.VatRate,
  DocumentCardGeneralPositionsTableColumns.IsProductInOrder,
  DocumentCardGeneralPositionsTableColumns.TotalTertiaryPackages,
  DocumentCardGeneralPositionsTableColumns.TotalSecondaryPackages,
  DocumentCardGeneralPositionsTableColumns.Amount,
  AppTableActionColumnName,
];

const columnSizing = {
  [DocumentCardGeneralPositionsTableColumns.Code]: 20,
  [DocumentCardGeneralPositionsTableColumns.Name]: 55,
  [DocumentCardGeneralPositionsTableColumns.Producer]: 55,
  [DocumentCardGeneralPositionsTableColumns.Vital]: 20,
  [DocumentCardGeneralPositionsTableColumns.Gtin]: 40,
  [DocumentCardGeneralPositionsTableColumns.Series]: 40,
  [DocumentCardGeneralPositionsTableColumns.ExpirationDate]: 40,
  [DocumentCardGeneralPositionsTableColumns.PriceWithVat]: 50,
  [DocumentCardGeneralPositionsTableColumns.Vat]: 40,
  [DocumentCardGeneralPositionsTableColumns.TotalPriceWithVat]: 60,
  [DocumentCardGeneralPositionsTableColumns.VatRate]: 50,
  [DocumentCardGeneralPositionsTableColumns.IsProductInOrder]: 30,
  [DocumentCardGeneralPositionsTableColumns.TotalTertiaryPackages]: 60,
  [DocumentCardGeneralPositionsTableColumns.TotalSecondaryPackages]: 60,
  [DocumentCardGeneralPositionsTableColumns.Amount]: 55,
};

export const defaultDocumentCardGeneralPositionsTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
