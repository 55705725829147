import { useFavoritesEmptyData } from '@/modules/favorites/hooks/use-favorites-empty-data';
import { favoritesRoutePath } from '@/modules/favorites/routes/routes';
import { interactionLogRoutePath } from '@/modules/interaction-log/routes/routes';
import { LoaderWrapper } from '@/modules/portal/components/loader-wrappers/loader-wrapper/LoaderWrapper';
import { UserWithoutRolesContent } from '@/modules/portal/components/navigation-after-sign-in/user-without-roles-content/UserWithoutRolesContent';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { receiptsRoutePath } from '@/modules/receipts/routes/routes';
import React, { memo, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import './navigation-after-sign-in.scss';

const NavigationAfterSignInInner = () => {

  const { isUserHasNoRoles, hasAccessByPermission } = useUserPermissionsAccess();
  const { hasFavorites, isDataLoading, getFavorites } = useFavoritesEmptyData();

  if (isUserHasNoRoles) {
    return <UserWithoutRolesContent />;
  }

  const hasUserAccessToFavoritesPath = hasAccessByPermission(UserPermission.ViewFavorites);

  if (!hasUserAccessToFavoritesPath) {
    return <Navigate to={interactionLogRoutePath} />;
  }

  const navigationPath = hasFavorites ? favoritesRoutePath : receiptsRoutePath;

  useEffect(() => {
    getFavorites();
  }, []);

  return (
    <LoaderWrapper
      isLoading={isDataLoading}
      classNames={{ loaderWrapper: 'navigation-loader-wrapper' }}
    >
      <Navigate to={navigationPath} />
    </LoaderWrapper>
  );
};

export const NavigationAfterSignIn = memo(NavigationAfterSignInInner);
