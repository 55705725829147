import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { AppTableSettings } from '@/common/components/app-table/hooks/use-app-table-settings';
import {
  AppTableActionColumnDefaultSize,
  AppTableActionColumnIncreasedSize,
  AppTableActionColumnName,
  AppTableDisabledCellClassName,
  AppTableFixedCellWidthClassName,
  AppTableHideRowActionsClassName
} from '@/common/constants/app-table-constants';
import {
  AppTableCellType,
  AppTableColumnDefType,
  AppTableColumnType,
  AppTableRowType
} from '@/common/models/app-table/app-table-types';
import { useCallback, useMemo } from 'react';

type AppTableStylesParams =
  Pick<AppTableProps, 'enableEditing' | 'editingMode' | 'updatedRowId' | 'isUpdatingInProcess'> &
  Pick<AppTableSettings, 'renderRowActionMenuItems'>;

export interface AppTableStyles {
  actionColumnSize: number;
  fixedCellWidthClassName: (column: AppTableColumnType) => string;
  disabledCellClassName: (row: AppTableRowType, cell?: AppTableCellType) => string;
  hideRowActionsClassName: (row: AppTableRowType) => string;
}

export function useAppTableStyles({
  enableEditing, editingMode, updatedRowId, isUpdatingInProcess, renderRowActionMenuItems
}: AppTableStylesParams): AppTableStyles {

  const actionColumnSize = useMemo(
    () => enableEditing && editingMode === 'row' ? AppTableActionColumnIncreasedSize : AppTableActionColumnDefaultSize,
    [enableEditing, editingMode]
  );

  const fixedCellWidthClassName = (column: AppTableColumnType) => {
    const isFixed = (column.columnDef as AppTableColumnDefType).fixedWidth;
    return isFixed ? AppTableFixedCellWidthClassName : '';
  };

  const disabledCellClassName = useCallback((row: AppTableRowType, cell?: AppTableCellType) => {
    if (!isUpdatingInProcess) {
      return '';
    }

    if (cell?.column?.id === AppTableActionColumnName || row.id === updatedRowId) {
      return AppTableDisabledCellClassName;
    }

    return '';
  }, [updatedRowId, isUpdatingInProcess]);

  const hideRowActionsClassName = (row: AppTableRowType) => {
    const isEmtyActionMenuItems = renderRowActionMenuItems && !renderRowActionMenuItems({ row, closeMenu: () => { } }).length;
    return isEmtyActionMenuItems ? AppTableHideRowActionsClassName : '';
  };

  return {
    actionColumnSize,
    fixedCellWidthClassName,
    disabledCellClassName,
    hideRowActionsClassName,
  };
}
