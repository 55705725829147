import { Document } from '@/shared/document/models/document';
import { DocumentCardSharedData } from '@/shared/document/models/document-card/document-card-shared-data';
import { DocumentProcessingWay } from '@/shared/document/models/document-processing-way';
import { DocumentStatus } from '@/shared/document/models/document-status';
import { Location, useLocation } from 'react-router-dom';

interface DocumentCardTableDataHookParams {
  documentInfo: Document;
}

export const useDocumentCardTableData = ({ documentInfo }: DocumentCardTableDataHookParams) => {
  const location: Location<DocumentCardSharedData> = useLocation();
  const dataFromTable = location.state;

  const processingWay = documentInfo.processingWay ?? dataFromTable?.docProcessingWay;

  const status = documentInfo.status ?? dataFromTable?.docState;

  const showDeleteRowButton = processingWay === DocumentProcessingWay.Fill && status === DocumentStatus.InProcess;

  return {
    processingWay,
    showDeleteRowButton
  };
};
