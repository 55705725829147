import { AppDropAliveComponent } from '@/common/components/app-keep-alive/AppDropAliveComponent';
import { AppKeepAliveComponent } from '@/common/components/app-keep-alive/AppKeepAliveComponent';
import { receiptCardRoutePath, receiptsRoutePath } from '@/modules/receipts/routes/routes';
import { ReceiptCardPage } from '@/modules/receipts/views/receipt-card-page/ReceiptCardPage';
import { ReceiptCardRootPage } from '@/modules/receipts/views/receipt-card-root-page/ReceiptCardRootPage';
import { ReceiptCardScanningPage } from '@/modules/receipts/views/receipt-card-scanning-page/ReceiptCardScanningPage';
import { ReceiptsPage } from '@/modules/receipts/views/receipts-page/ReceiptsPage';
import { documentCardScanningRoutePath } from '@/shared/document/routes/routes';
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const ReceiptsPageComponentName = 'ReceiptsPage';

/**
 * All receipts routes.
 */
const routes: RouteObject[] = [
  {
    path: receiptsRoutePath,
    element:
      <AppDropAliveComponent name={ReceiptsPageComponentName}>
        <Outlet />
      </AppDropAliveComponent>,
    children: [
      {
        path: '',
        element:
          <AppKeepAliveComponent name={ReceiptsPageComponentName}>
            <ReceiptsPage />
          </AppKeepAliveComponent>,
      },
      {
        path: receiptCardRoutePath,
        element: <ReceiptCardRootPage />,
        children: [
          {
            path: '',
            element: <ReceiptCardPage />,
          },
          {
            path: documentCardScanningRoutePath,
            element: <ReceiptCardScanningPage />,
          }
        ]
      }
    ],
  },
];

export default routes;
