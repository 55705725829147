import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { required } from '@/common/utils/client-validation/validation-rules';

interface UnboxRemainValidation {
  sscc: string;
}

export function useUnboxRemainDialogValidation(data: UnboxRemainValidation) {

  const validationRules: FormValidationRules<Partial<UnboxRemainValidation>, keyof UnboxRemainValidation> = {
    sscc: [required()]
  };

  return {
    ...useFormValidation({ validationRules, data }),
  };
}