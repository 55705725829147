import { disposalCardScanningModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import { disposalCardScanningSelectors } from '@/modules/disposals/store/disposal-card/scanning/selectors';
import { createDocumentCardScanningThunks } from '@/shared/document/store/document-card/scanning/async-thunks';

export const disposalCardScanningApi = createDocumentCardScanningThunks({
  moduleName: disposalCardScanningModuleName,
  documentCardSelectors: disposalCardSelectors,
  documentCardScanningSelectors: disposalCardScanningSelectors,
});
