import React, { FC, memo } from 'react';
import './organization-info-block.scss';

interface OrganizationOwnerInfoBlockProps {
  title: string;
  value: string;
  alignment?: 'start' | 'end';
  direction?: 'column' | 'row';
  textAlignValue?: 'left' | 'center' | 'right';
}

const OrganizationInfoBlockInner: FC<OrganizationOwnerInfoBlockProps> = ({ title, value, alignment = 'start', direction = 'column', textAlignValue = 'left' }) => (
  <div className={`organization-info-block align-${alignment} direction-${direction}`}>
    <div className="title">{title}</div>
    <div className={`value text-align-${textAlignValue}`}>{value ?? '-'}</div>
  </div>
);

export const OrganizationInfoBlock = memo(OrganizationInfoBlockInner);
