import { AppCard } from '@/common/components/app-card/AppCard';
import { OrganizationInfoBlock } from '@/modules/organization-profile/components/organization-info-block/OrganizationInfoBlock';
import React, { FC, memo } from 'react';

interface OrganizationPlacesInfoCardProps {
  placesCount: number;
}

const OrganizationPlacesInfoCardInner: FC<OrganizationPlacesInfoCardProps> = ({ placesCount }) => (
  <AppCard>
    <OrganizationInfoBlock
      title="Всего мест деятельности:"
      direction="row"
      value={placesCount}
    />
  </AppCard>
);

export const OrganizationPlacesInfoCard = memo(OrganizationPlacesInfoCardInner);
