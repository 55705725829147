import CloseIcon from '@/assets/icons/controls/cross.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { Dialog } from '@mui/material';
import React, { FC, memo } from 'react';
import './app-dialog.scss';

interface AppDialogProps {
  open: boolean;
  children?: React.ReactNode;
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  footer?: React.ReactNode;
  showCloseButton?: boolean;
  classes?: { dialog?: string; content?: string; };
  onClose: () => void;
}

const AppDialogInner: FC<AppDialogProps> = ({ open, children, header, subHeader, footer, showCloseButton = true, classes, onClose }) => (
  <Dialog
    className="app-dialog"
    onClose={onClose}
    open={open}
    maxWidth={false}
    transitionDuration={AppDialogTransitionDuration}
    classes={{
      paper: classes?.dialog
    }}
  >
    {showCloseButton && (
      <AppIconButton
        icon={<CloseIcon />}
        className="app-dialog-close-icon"
        edge="end"
        onClick={onClose}
      />
    )}
    {header && (
      <div className="app-dialog-header">
        {header}
      </div>
    )}
    {subHeader && (
      <div className="app-dialog-sub-header">
        {subHeader}
      </div>
    )}
    {children && (
      <div className={`app-dialog-content ${!header && !subHeader ? 'no-margin' : ''} ${classes?.content ?? ''}`}>
        {children}
      </div>
    )}
    {footer && (
      <div className="app-dialog-footer">
        {footer}
      </div>
    )}
  </Dialog>
);

export const AppDialog = memo(AppDialogInner);
