import { saleCardBoxesModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardBoxesSelectors } from '@/modules/sale/store/sale-card/boxes/selectors';
import { saleCardSelectors } from '@/modules/sale/store/sale-card/card/selectors';
import { createDocumentCardBoxesThunks } from '@/shared/document/store/document-card/boxes/async-thunks';

export const saleCardBoxesApi = createDocumentCardBoxesThunks({
  moduleName: saleCardBoxesModuleName,
  documentCardSelectors: saleCardSelectors,
  documentCardBoxesSelectors: saleCardBoxesSelectors,
});
