import { escapeStringFromQuotes, escapeStringFromSpecialCharacters } from '@/common/utils/common/string-utils';
import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';

function getFilterArrayString(value: string[]) {
  const notEmptyFilters = value.filter((filter) => !!filter);

  if (!notEmptyFilters.length) {
    return '';
  }

  const result = notEmptyFilters.join(RsqlLogicalOperators.Or);

  return `(${result})`;
}

function getFilterValueString<T extends object>(filter: RsqlFilterModel<T>): string {
  if (filter.value instanceof Array && (
    filter.operation === RsqlFilterOperations.In ||
    filter.operation === RsqlFilterOperations.Out
  )) {
    return getFilterArrayString(filter.value);
  }

  if (typeof filter.value === 'string') {
    const escapeString = (str: string) =>
      filter.operation === RsqlFilterOperations.EILike
        ? escapeStringFromSpecialCharacters(str)
        : escapeStringFromQuotes(str);

    return `"${encodeURIComponent(escapeString(filter.value))}"`;
  }

  return String(filter.value);
}

export function convertToFilterExpressions<T extends object>(filters: RsqlFilterModel<T>[]): string[] {
  return filters
    .filter((el) => el.value !== null && el.value !== undefined && el.value !== '')
    .map((filter) => `${String(filter.selector)}${filter.operation}${getFilterValueString(filter)}`);
}

export function joinFilterExpressions(operators: string[], operation: RsqlLogicalOperators) {
  if (!operators?.length) {
    return '';
  }

  const filteredOperators = operators
    .filter((operator) => !!operator);

  const result = filteredOperators.join(operation);

  if (operation === RsqlLogicalOperators.Or && filteredOperators.length > 1) {
    return `(${result})`;
  }

  return result;
}
