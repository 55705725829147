import { disposalCardBoxesModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardBoxesSelectors } from '@/modules/disposals/store/disposal-card/boxes/selectors';
import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import { createDocumentCardBoxesThunks } from '@/shared/document/store/document-card/boxes/async-thunks';

export const disposalCardBoxesApi = createDocumentCardBoxesThunks({
  moduleName: disposalCardBoxesModuleName,
  documentCardSelectors: disposalCardSelectors,
  documentCardBoxesSelectors: disposalCardBoxesSelectors,
});
