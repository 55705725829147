import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableRowType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { getValueFromUpdater } from '@/common/utils/common/types-utils';
import { defaultFavoritesTableConfig } from '@/modules/favorites/constants/favorites-table-config';
import { FavoriteDocument } from '@/modules/favorites/models/favorite-document';
import { convertFromColumnModelToUpdateFavoriteDocumentBody } from '@/modules/favorites/models/favorite-document-update-body';
import { FavoriteDocumentWithIndicator } from '@/modules/favorites/models/favorite-document-with-indicator';
import { FavoritesTableColumns } from '@/modules/favorites/models/favorites-table-columns';
import {
  patchItemByIndex,
  setIsUpdatingInProgress,
  setUpdatedRowId,
  updateSearchString,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
  updateTableColumnVisibilityState,
  updateTableSortingState
} from '@/modules/favorites/store';
import { updateFavoriteDocument } from '@/modules/favorites/store/async-thunks';
import { FavoritesSelectors } from '@/modules/favorites/store/selectors';
import { DocumentCardSharedData } from '@/shared/document/models/document-card/document-card-shared-data';
import { getDocumentCardRoutePathByDocType } from '@/shared/document/routes/routes';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type FavoritesTableEventHandlersHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useFavoritesTableEventHandlers({ loadFirstPage }: FavoritesTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const navigate = useNavigate();

  const tableSorting = useSelector(FavoritesSelectors.selectTableSorting);

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    const newSorting = getValueFromUpdater<AppTableSortingType>(value, tableSorting);
    dispatch(updateTableSortingState(!newSorting.length ? defaultFavoritesTableConfig.sorting : newSorting));
  }, [tableSorting]);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onRefreshClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onEditingRowSave: AppTableProps<FavoriteDocument, FavoritesTableColumns>['onEditingRowSave'] = ({ exitEditingMode, row, values }) => {
    dispatch(setUpdatedRowId(row.id));

    const { docUuid } = row.original;
    const updateEntity = convertFromColumnModelToUpdateFavoriteDocumentBody(values);

    asyncDispatch(
      () => updateFavoriteDocument({ docUuid, body: updateEntity }),
      () => {
        dispatch(patchItemByIndex({ item: updateEntity, index: row.index }));
        exitEditingMode();
      },
      undefined,
      () => {
        // needs for delay for exitEditingMode, in order to stop loader only after edit mode will be closed.
        setTimeout(() => {
          dispatch(setUpdatedRowId(''));
          dispatch(setIsUpdatingInProgress(false));
        }, 200);
      }
    );
  };

  const onRowDoubleClick = useCallback((row: AppTableRowType<FavoriteDocumentWithIndicator>) => {
    const { docState, isFavorite, docProcessingWay, docOrder, docType } = row.original;
    const dataForDocumentCard: DocumentCardSharedData = { docState, isFavorite, docProcessingWay, docOrder };
    navigate(
      getDocumentCardRoutePathByDocType({ docType, docUuid: row.original.docUuid }),
      { state: dataForDocumentCard }
    );
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onEditingRowSave,
    onRowDoubleClick,
    onRefreshClick
  };
}
