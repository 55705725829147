import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { buildDefaultDocumentsTableConfig } from '@/shared/document/constants/documents-table-config';
import { DocumentType } from '@/shared/document/models/document-type';
import { DocumentsFilter } from '@/shared/document/models/documents/documents-filter';
import { DocumentsTableColumns } from '@/shared/document/models/documents/documents-table-columns';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { createDocumentsTableSlice, DocumentsTableState } from '@/shared/document/store/documents/table-slice';
import { DocumentsApi } from '@/shared/document/store/documents/types';
import { createSlice, Draft, SliceCaseReducers } from '@reduxjs/toolkit';

export interface DocumentsState {
  table: DocumentsTableState;
  search: SearchingState;
  filterConfig: FilterConfigState<DocumentsFilter>;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<ShortDocumentInfo>;
}

interface RootDocumentsCaseReducers extends SliceCaseReducers<DocumentsState> {
  resetState: (state: Draft<DocumentsState>) => void;
}

const notClearedFields: Set<keyof DocumentsState> = new Set(['tableConfig']);

interface CreateDocumentsSliceParams extends VisibleColumns<DocumentsTableColumns> {
  moduleName: string;
  savedTableConfigName: string;
  docType: DocumentType;
  api: DocumentsApi;
}

export const createDocumentsSlice = ({ moduleName, savedTableConfigName, docType, api, visibleColumns }: CreateDocumentsSliceParams) => {
  const documentsTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: buildDefaultDocumentsTableConfig(visibleColumns),
  };

  const documentsTableSlice = createDocumentsTableSlice({ moduleName, docType, api, visibleColumns });
  const documentsSearchingSlice = createSearchingSlice(moduleName);
  const documentsTableConfigSlice = createSavedTableConfigSlice(moduleName, documentsTableConfigState);
  const documentsPageableDataSlice = createPageableDataSlice<ShortDocumentInfo>(moduleName);
  const documentsFilterConfigSlice = createFilterConfigSlice(moduleName);

  const initialState: DocumentsState = {
    table: documentsTableSlice.getInitialState(),
    filterConfig: documentsFilterConfigSlice.getInitialState(),
    search: documentsSearchingSlice.getInitialState(),
    tableConfig: documentsTableConfigSlice.getInitialState(),
    pageableData: documentsPageableDataSlice.getInitialState(),
  };

  const rootSlice = createSlice<DocumentsState, RootDocumentsCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      resetState(state: DocumentsState) {
        Object.keys(state)
          .forEach((key: keyof DocumentsState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getAllDocuments.pending, (state) => {
          const { updateLoadingStatus } = documentsPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
        })
        .addCase(api.getAllDocuments.fulfilled, (state) => {
          const { updateLoadingStatus } = documentsPageableDataSlice.caseReducers;
          const { updateStateAfterGettingData } = documentsFilterConfigSlice.caseReducers;
          const { saveAppliedSearchString } = documentsSearchingSlice.caseReducers;

          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
          updateStateAfterGettingData(state.filterConfig);
          saveAppliedSearchString(state.search);
        })
        .addCase(api.getAllDocuments.rejected, (state) => {
          const { updateLoadingStatus } = documentsPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
        })
        .addCase(api.updateDocument.pending, (state) => {
          const { setIsUpdatingInProgress } = documentsTableSlice.caseReducers;
          setIsUpdatingInProgress(state.table, getReducerAction(setIsUpdatingInProgress.name, true));
        })
        .addMatcher(
          (action) => action.type.startsWith(documentsTableSlice.name),
          (state, action) => {
            state.table = documentsTableSlice.reducer(state.table, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentsSearchingSlice.name),
          (state, action) => {
            state.search = documentsSearchingSlice.reducer(state.search, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentsTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = documentsTableConfigSlice.reducer(state.tableConfig, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentsPageableDataSlice.name),
          (state, action) => {
            state.pageableData = documentsPageableDataSlice.reducer(state.pageableData, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentsFilterConfigSlice.name),
          (state, action) => {
            state.filterConfig = documentsFilterConfigSlice.reducer(state.filterConfig, action);
          }
        );
    },
  });

  return {
    rootSlice,
    documentsTableSlice,
    documentsSearchingSlice,
    documentsTableConfigSlice,
    documentsPageableDataSlice,
    documentsFilterConfigSlice,
  };
};
