import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { InteractionLogFilters } from '@/modules/interaction-log/components/interaction-log-filters/InteractionLogFilters';
import { useInteractionLogTableActions } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-actions';
import { useInteractionLogTableDataSource } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-data-source';
import { useInteractionLogTableEventHandlers } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-event-handlers';
import { useInteractionLogTableFiltering } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-filtering';
import { useInteractionLogTableRowActions } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-row-actions';
import { useInteractionLogTableSettings } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-settings';
import { InteractionLogSelectors } from '@/modules/interaction-log/store/interaction-log/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const InteractionLogTable = () => {
  const interactionData = useSelector(InteractionLogSelectors.selectInteractionData);
  const isLoading = useSelector(InteractionLogSelectors.selectIsLoadingInteractionData);
  const tableConfig = useSelector(InteractionLogSelectors.selectTableConfig);
  const isPanelOpen = useSelector(InteractionLogSelectors.selectIsPanelOpen);

  const selectedFiltersCount = useSelector(InteractionLogSelectors.selectSelectedFiltersCount);

  const { loadFirstPage, loadNextPage } = useInteractionLogTableDataSource();
  const {
    isFileLoading,
    isTicketLoading,
    downloadFile,
    downloadTicket,
    retryOperation
  } = useInteractionLogTableActions();

  const { getRowActions } = useInteractionLogTableRowActions({ isFileLoading, isTicketLoading, downloadFile, downloadTicket, retryOperation });
  const { columns, tableClasses, searchPlaceholder } = useInteractionLogTableSettings();
  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: InteractionLogSelectors.selectSearchState
  });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRefreshClick
  } = useInteractionLogTableEventHandlers({ loadFirstPage, loadNextPage });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick
  } = useInteractionLogTableFiltering({ loadFirstPage });

  return (
    <AppCustomTable
      data={interactionData}
      classes={tableClasses}
      columns={columns}
      isLoading={isLoading}
      configState={tableConfig}
      enableRowVirtualization
      manualSorting
      topToolbar={{
        filter: {
          showFilterButton: true,
          filterPanel: <InteractionLogFilters />,
          isPanelOpen,
          selectedFiltersCount,
          disableApplyFilterButton,
          disableClearFilterButton,
          onFilterClick,
          onApplyFiltersClick,
          onClearFiltersClick,
        },
        search: {
          showSearchInput: true,
          searchPlaceholder,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput,
          onApplySearchClick,
          onClearSearchClick,
        },
        refreshButton: {
          showRefreshButton: true,
          notClickableRefreshButton: isLoading,
          onRefreshClick
        }
      }}
      getRowActions={getRowActions}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
      onScrollAtTheEnd={onScrollChange}
    />
  );
};
