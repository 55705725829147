import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { phoneMask } from '@/common/constants/masks';
import { FieldErrors } from '@/common/utils/server-validation/field-errors';
import { PasswordConfirmation } from '@/modules/auth/components/password-confirmation/PasswordConfirmation';
import { ContactConfirmationForm } from '@/modules/auth/components/registration-forms/ContactConfirmationForm';
import { initialPasswordsState } from '@/modules/auth/constants/initial-passwords-state';
import { useConfirmPasswordValidation } from '@/modules/auth/hooks/use-confirm-password-validation';
import { useRegistrationFormCodesValidation } from '@/modules/auth/hooks/use-registration-form-codes-validation';
import { ConfirmContactErrorFields } from '@/modules/auth/models/confirm-contact/confirm-contact-error-fields';
import { ContactType } from '@/modules/auth/models/contact-type';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import { setPassword } from '@/modules/auth/store/registration';
import { RegistrationSelectors } from '@/modules/auth/store/registration/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import './registration-forms.scss';

interface RegistrationStep3FormProps {
  fieldErrors: FieldErrors<ConfirmContactErrorFields>;
  onRegisterClick: () => void;
  onChangeDataClick: () => void;
  sendPhoneCode: () => void;
  sendEmailCode: () => void;
}

export const RegistrationStep3Form: FC<RegistrationStep3FormProps> = ({ fieldErrors, onRegisterClick, onChangeDataClick, sendEmailCode, sendPhoneCode }) => {
  const dispatch = useAppDispatch();

  const [passwords, setPasswords] = useState<PasswordsConfirmation>(initialPasswordsState);

  const formData = useSelector(RegistrationSelectors.selectFormData);
  const codes = useSelector(RegistrationSelectors.selectCodes);
  const expireTimeEmail = useSelector(RegistrationSelectors.selectExpireTimeEmailDate);
  const expireTimePhone = useSelector(RegistrationSelectors.selectExpireTimePhoneDate);
  const isEmailConfirmContactInProgress = useSelector(RegistrationSelectors.selectIsEmailConfirmContactInProgress);
  const isPhoneNumberConfirmContactInProgress = useSelector(RegistrationSelectors.selectIsPhoneNumberConfirmContactInProgress);
  const isLoading = useSelector(RegistrationSelectors.selectIsLoadingStep3);

  const {
    passwordMaxLength,
    validationResult: passwordValidationResult,
    validateForm: passwordValidateForm,
  } = useConfirmPasswordValidation(passwords);

  const {
    codesMaxLength,
    validationResult: codesValidationResult,
    validateForm: codesValidateForm,
  } = useRegistrationFormCodesValidation(codes);

  const onPasswordChange = useCallback((passwords: PasswordsConfirmation) => setPasswords(passwords), []);

  const onRegisterClickInner = useCallback(() => {
    if (codesValidateForm() && passwordValidateForm()) {
      dispatch(setPassword(passwords.password));
      onRegisterClick();
    }
  }, [passwords, codes]);

  return (
    <div className="registration-form-container">
      <h1 className="title">Подтверждение телефона и email</h1>
      <h2 className="sub-title">Введите код подтверждения из смс и почты</h2>
      <ContactConfirmationForm
        contactType={ContactType.PhoneNumber}
        code={codes.phoneNumber}
        contactValue={formData.phoneNumber}
        codesMaxLength={codesMaxLength}
        isConfirmContactInProgress={isPhoneNumberConfirmContactInProgress}
        clientValidationResult={codesValidationResult.phoneNumber}
        expireTime={expireTimePhone}
        serverValidationResult={fieldErrors.phoneNumber}
        sendCode={sendPhoneCode}
      />
      <ContactConfirmationForm
        contactType={ContactType.Email}
        code={codes.email}
        contactValue={formData.email}
        codesMaxLength={codesMaxLength}
        isConfirmContactInProgress={isEmailConfirmContactInProgress}
        clientValidationResult={codesValidationResult.email}
        expireTime={expireTimeEmail}
        serverValidationResult={fieldErrors.email}
        sendCode={sendEmailCode}
      />
      <AppInput
        className="hidden-phone"
        autoComplete="username"
        label="Номер телефона"
        value={formData.phoneNumber}
        mask={phoneMask}
        readOnly
      />
      <PasswordConfirmation
        passwords={passwords}
        passwordMaxLength={passwordMaxLength}
        validationResult={passwordValidationResult}
        className="registration-form-password-confirmation"
        onChangePasswords={onPasswordChange}
      />
      <AppButton
        label="Завершить регистрацию"
        className="next-button"
        disabled={isEmailConfirmContactInProgress || isPhoneNumberConfirmContactInProgress || isLoading}
        onClick={onRegisterClickInner}
      />
      <AppLink
        text="Изменить данные"
        onClick={onChangeDataClick}
      />
      {/* ПРи добавлении лоадера появляется скролл если 2 поля неверны */}
      {isLoading && <AppLoader
        size="small"
        className="registration-form-container-loader"
      />}
    </div>
  );
};
