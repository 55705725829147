import { MenuPanelItem as MenuItemType, MenuPanelCommonItem } from '@/modules/portal/models/menu-panel-item';
import React, { FC, memo } from 'react';
import './menu-panel-item.scss';

interface MenuPanelItemProps {
  item: MenuItemType;
  open: boolean;
  isActiveMenuItem: (link: string) => boolean;
  isItemVisible: (item: MenuPanelCommonItem) => boolean;
  onClick: (link: string) => void;
}

const MenuPanelItemInner: FC<MenuPanelItemProps> = ({ item, open, isActiveMenuItem, isItemVisible, onClick }) => {
  const { label, icon, children, link } = item;
  const classNameMainItem = `menu-panel-item ${isActiveMenuItem(link) || (!open && children?.some((child) => isActiveMenuItem(child.link))) ? 'active' : ''}  ${!link ? 'title' : ''}`;

  return (
    <div
      key={link}
      className={`menu-panel-item-wrapper ${open ? 'open-menu' : ''}`}
      onClick={() => link && onClick(link)}
    >
      <div className={classNameMainItem}>
        <div className="menu-panel-icon">{icon}</div>
        {open && <div className="menu-panel-label">{label}</div>}
      </div>

      {!!children?.length && open && (
        <div className="children-items">
          {children.map((child) => isItemVisible(child) && (
            <div
              key={child.link}
              className={`menu-panel-item ${isActiveMenuItem(child.link) ? 'active' : ''} child-item`}
              onClick={() => onClick(child.link)}
            >
              <div className="menu-panel-label">{child.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const MenuItem = memo(MenuPanelItemInner);
