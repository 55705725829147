import { receiptCardNotesModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardSelectors } from '@/modules/receipts/store/receipt-card/card/selectors';
import { receiptCardNotesSelectors } from '@/modules/receipts/store/receipt-card/notes/selectors';
import { createDocumentCardNotesThunks } from '@/shared/document/store/document-card/notes/async-thunks';

export const receiptCardNotesApi = createDocumentCardNotesThunks({
  moduleName: receiptCardNotesModuleName,
  documentCardSelectors: receiptCardSelectors,
  documentCardNotesSelectors: receiptCardNotesSelectors,
});
