import {
  disposalsFilterConfigActions,
  disposalsPageableDataActions,
  disposalsRootActions,
  disposalsSearchingActions,
  disposalsTableActions,
  disposalsTableConfigActions
} from '@/modules/disposals/store/disposals';
import { disposalsApi } from '@/modules/disposals/store/disposals/async-thunks';
import { disposalsSelectors } from '@/modules/disposals/store/disposals/selectors';
import { DocumentsTable } from '@/shared/document/components/documents-table/DocumentsTable';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const DisposalsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(disposalsRootActions.resetState()); };
  }, []);

  return <DocumentsTable
    documentsSelectors={disposalsSelectors}
    documentsApi={disposalsApi}
    documentsTableActions={disposalsTableActions}
    documentsPageableDataActions={disposalsPageableDataActions}
    documentsFilterConfigActions={disposalsFilterConfigActions}
    documentsSearchingActions={disposalsSearchingActions}
    documentsTableConfigActions={disposalsTableConfigActions}
  />;
};
