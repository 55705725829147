import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { BlockedInfoItem } from '@/common/components/custom/blocked-info-item/BlockedInfoItem';
import { DialogCommonState } from '@/common/hooks/use-dialog';
import React, { FC, ReactNode, memo } from 'react';
import './adding-remains-warn-dialog.scss';

export interface AddingRemainsWarnDialogProps extends DialogCommonState {
  warnInfo: string[];
  header: ReactNode;
  subHeader: ReactNode;
  closeDialog: () => void;
  continueAdding: () => void;
}

const AddingRemainsWarnDialogInner: FC<AddingRemainsWarnDialogProps> = ({ isDialogOpened, warnInfo, header, subHeader, closeDialog, continueAdding }) => (
  <AppDialog
    open={isDialogOpened}
    classes={{ dialog: 'adding-remains-blocked-info-dialog' }}
    header={header}
    subHeader={subHeader}
    onClose={closeDialog}
    footer={
      <div className="adding-remains-blocked-info-dialog-footer">
        <AppButton
          label="Отмена"
          variant="outlined"
          onClick={closeDialog}
        />
        <AppButton
          label="Продолжить"
          onClick={continueAdding}
        />
      </div>
    }
  >
    <BlockedInfoItem
      title="Третичные упаковки, которые будут добавлены:"
      info={warnInfo}
      variant="info"
    />
  </AppDialog>
);

export const AddingRemainsWarnDialog = memo(AddingRemainsWarnDialogInner);
