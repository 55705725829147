import { isDateValid } from '@/common/utils/common/date-utils';
import { format, set } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';

interface TimerHookParams {
  dateTimeFormat: string;
  expireTime: Date;
  onTimerExpired?: () => void;
}

export const useTimer = ({ expireTime, dateTimeFormat, onTimerExpired }: TimerHookParams) => {
  const expireTimeRef = useRef(expireTime);
  const updateTimeTimeoutRef = useRef<NodeJS.Timer>();
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [timer, setTimer] = useState(0);

  const isValidExpireTime = useMemo(() => isDateValid(expireTimeRef.current), [expireTimeRef.current]);

  const hours = useMemo(() => isValidExpireTime ? timer / 3600 : 0, [timer, expireTimeRef.current]);
  const minutes = useMemo(() => isValidExpireTime ? (timer % 3600) / 60 : 0, [timer, expireTimeRef.current]);
  const seconds = useMemo(() => isValidExpireTime ? timer % 60 : 0, [timer, expireTimeRef.current]);

  const getSecondsFromDate = (date: Date) => Math.floor(date?.getTime() / 1000);
  const getRemainingSeconds = (): number | null => {

    if (!isDateValid(expireTimeRef.current)) {
      return null;
    }
    const date = new Date();
    const difference = getSecondsFromDate(expireTimeRef.current) - getSecondsFromDate(date);

    return difference > 0 ? difference : null;
  };

  const updateTime = () => {
    const remainingSeconds = getRemainingSeconds();
    if (!remainingSeconds) {
      stopTimerWithCallback();
      return;
    }
    setTimer(remainingSeconds);
    if (remainingSeconds > 0) {
      updateTimeTimeoutRef.current = setTimeout(updateTime, 1000);
    }
  };

  const timerLabel = useMemo(() => {
    const newDate = set(isValidExpireTime ? expireTimeRef.current : new Date(), { hours, minutes, seconds });
    return format(newDate, dateTimeFormat);
  }, [expireTimeRef.current, hours, minutes, seconds, dateTimeFormat]);

  const startTimer = () => {
    if (expireTimeRef.current < new Date()) {
      stopTimerWithCallback();
      return;
    }
    setIsTimerExpired(false);
    clearTimeout(updateTimeTimeoutRef.current);
    updateTimeTimeoutRef.current = undefined;
    updateTime();
  };

  const stopTimer = () => {
    setIsTimerExpired(true);
    setTimer(0);
  };

  const stopTimerWithCallback = () => {
    stopTimer();
    onTimerExpired?.();
  };

  useEffect(() => {
    expireTimeRef.current = expireTime;
  }, [expireTime]);

  useEffect(() => {
    startTimer();
    return stopTimer;
  }, []);

  return {
    timerLabel,
    isTimerExpired,
    startTimer
  };
};
