import { LoadingStatus } from '@/common/models/loading-status';
import { RejectedContent } from '@/modules/portal/components/loader-wrappers/loader-wrapper-with-reject-content/components/rejected-content/RejectedContent';
import { LoaderWrapper } from '@/modules/portal/components/loader-wrappers/loader-wrapper/LoaderWrapper';
import { LoaderWrapperWithRejectContentClassNames } from '@/modules/portal/models/loader-wrapper/loader-wrapper-class-names';
import { LoaderWrapperCommonProps } from '@/modules/portal/models/loader-wrapper/loader-wrapper-common-props';
import { AsyncAction } from '@/store';
import React, { FC, ReactNode, memo, useMemo } from 'react';
import './loader-wrapper-with-reject-content.scss';

interface LoaderWrapperWithRejectContentProps extends LoaderWrapperCommonProps {
  loadingStatus: LoadingStatus;
  classNames?: LoaderWrapperWithRejectContentClassNames;
  mainWrapperHeight?: number;
  rejectBottomContent?: ReactNode;
  retryAction: AsyncAction;
}

const LoaderWrapperWithRejectContentInner: FC<LoaderWrapperWithRejectContentProps> = ({ children, loadingStatus, classNames, mainWrapperHeight, rejectBottomContent, retryAction }) => {
  const computedClassNames = useMemo(() => {
    const componentName = 'loader-wrapper-with-reject-content';
    return {
      loaderMainWrapper: `${componentName}-loader-main-wrapper ${classNames?.loaderMainWrapper ?? ''}`,
      loaderWrapper: `${componentName}-loader-wrapper ${classNames?.loaderWrapper ?? ''}`,
      loader: `${componentName}-loader ${classNames?.loader ?? ''}`,
      rejectContent: {
        wrapper: `${componentName}-reject-content-wrapper ${classNames?.rejectContent?.wrapper ?? ''}`,
        title: `${componentName}-reject-content-title ${classNames?.rejectContent?.title ?? ''}`,
        button: `${componentName}-reject-content-button ${classNames?.rejectContent?.button ?? ''}`
      }
    };
  }, [classNames]);

  return (
    <div
      className={computedClassNames.loaderMainWrapper}
      style={{ height: mainWrapperHeight ?? 'auto' }}
    >
      {loadingStatus === LoadingStatus.Rejected ? (
        <RejectedContent
          classNames={computedClassNames?.rejectContent}
          bottomContent={rejectBottomContent}
          retryAction={retryAction}
        />
      ) : (
        <LoaderWrapper
          isLoading={loadingStatus === LoadingStatus.Pending}
          classNames={computedClassNames}
        >
          {children}
        </LoaderWrapper>
      )}
    </div>
  );
};

export const LoaderWrapperWithRejectContent = memo(LoaderWrapperWithRejectContentInner);
