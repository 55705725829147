import './cadesplugin-api';

export function getCertificates() {
  return new Promise((resolve, reject) => {
    const certificates = [];
    cadesplugin.then(() => {
      // работа с плагином
      cadesplugin.async_spawn(function* () {
        try {
          // Создание объекта для работы с сертификатами
          const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
          if (!oStore) {
            reject('Не удалось создать объект Store');
            return;
          }

          // Открытие хранилище сертификатов (в скобках указано, что со всех носителей)
          yield oStore.Open(
            cadesplugin.CADESCOM_CONTAINER_STORE,
            cadesplugin.CAPICOM_MY_STORE,
            cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

          // Получение коллекции сертификатов
          let oCertificates = yield oStore.Certificates;
          let count = yield oCertificates.Count;
          // Проверка, есть ли доступные сертификаты
          if (count === 0) {
            reject('Нет доступных сертификатов');
            return;
          }
          for (let i = 1; i <= count; i++) {
            let thumbprint, subjectName, issuerName;
            let cert = yield oCertificates.Item(i);
            try {
              thumbprint = yield cert.Thumbprint;
              subjectName = yield cert.SubjectName;
              issuerName = yield cert.IssuerName;
              const organization = subjectName.match(/O=.+?,{1}/i)?.[0].replace(/[O=,"']/g, '') ?? '';
              const owner = subjectName.match(/CN=.+?,{1}/i)?.[0].replace(/[CN=,]/g, '') ?? '';
              const INN = subjectName.match(/ИНН=.+?,{1}/i)?.[0].replace(/[ИНН=,]/g, '') ?? '';
              const position = subjectName.match(/T=.+?,{1}/i)?.[0].replace(/[T=,]/g, '') ?? '';
              const issuedFor = `${subjectName.match(/SN=.+?,{1}/i)?.[0].replace(/[SN=,]/g, '') ?? ''} ${subjectName.match(/G=.+?,{1}/i)?.[0].replace(/[G=,]/g, '') ?? ''}`;
              const issuedFrom = issuerName.match(/CN=.+?,{1}/i)?.[0].replace(/[CN=,]/g, '') ?? '';

              certificates.push({
                organization,
                owner,
                INN,
                position,
                issuedFor,
                issuedFrom,
                thumbprint,
              });
            }
            catch (err) {
              console.error(err);
              continue;
            }
          }

          // Закрытие хранилище сертификатов
          yield oStore.Close();

          resolve(certificates);
        } catch (err) {
          reject(err);
        }
      });
    });
  });
}