import { DocumentContractType } from '@/shared/document/models/document-contract-type';
import { DocumentFinancingSource } from '@/shared/document/models/document-financing-source';
import { DocumentOperationType } from '@/shared/document/models/document-operation-type';
import { DocumentOrder } from '@/shared/document/models/document-order';
import { DocumentProcessingWay } from '@/shared/document/models/document-processing-way';
import { DocumentStatus } from '@/shared/document/models/document-status';
import { DocumentDto } from '@/shared/document/models/dto/document-dto';

export interface Document {
  sender?: string; // Отправитель
  documentNumber: string; // Номер документа 
  documentDate: string; //  Дата документа
  departurePoint?: string; // Место отправления
  operationType: DocumentOperationType; // Тип операции
  receiver?: string; // Получатель
  contractType?: DocumentContractType; // Тип договора
  acceptancePlace?: string; // Место приемки
  financingSource?: DocumentFinancingSource; // Источник финансирования
  operationDate: string; // Дата операции
  status: DocumentStatus; //Статус
  amountWithVat?: number;// Сумма (с НДС), руб
  vatAmount?: number; // Сумма НДС, руб
  comment: string; // Комментарий
  order?: DocumentOrder; // Порядок
  activityPlace?: string;  // Место деятельности (Выбытие)
  exportCountry?: string;  // Код страны экспорта (Выбытие)
  processingWay: DocumentProcessingWay;
  isFavorite: boolean;
}

export const convertDocumentFromDtoToModel = (documentDto: DocumentDto): Document => {
  return {
    sender: documentDto?.senderOrganizationName,
    documentNumber: documentDto?.docNumber,
    documentDate: documentDto?.docDate,
    departurePoint: documentDto?.senderPlaceInfo,
    operationType: documentDto?.operationType,
    receiver: documentDto?.receiverOrganizationName,
    contractType: documentDto?.contractType,
    acceptancePlace: documentDto?.receiverPlaceInfo,
    financingSource: documentDto?.financingSource,
    operationDate: documentDto?.operationDate,
    status: documentDto?.docState,
    amountWithVat: documentDto?.totalPriceWithVat,
    vatAmount: documentDto?.totalVat,
    comment: documentDto?.comment,
    order: documentDto?.docOrder,
    activityPlace: documentDto?.ownPlaceInfo,
    exportCountry: documentDto?.exportCountryCode,
    processingWay: documentDto?.docProcessingWay,
    isFavorite: documentDto?.isFavorite
  };
};