import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { phoneMask } from '@/common/constants/masks';
import { FieldErrors } from '@/common/utils/server-validation/field-errors';
import { Logo } from '@/modules/auth/components/logo/Logo';
import { usePasswordRecoveryFormValidation } from '@/modules/auth/hooks/use-password-recovery-validation';
import { VerificationCodeSendErrorFields } from '@/modules/auth/models/verification-code-send/verification-code-send-error-fields';
import { setLogin } from '@/modules/auth/store/password-recovery';
import { PasswordRecoverySelectors } from '@/modules/auth/store/password-recovery/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './password-recovery-form.scss';

interface PasswordRecoveryFormProps {
  fieldErrors: FieldErrors<VerificationCodeSendErrorFields>;
  onNextClick: () => void;
  onGoBackClick: () => void;
}

const PasswordRecoveryFormInner: FC<PasswordRecoveryFormProps> = ({ fieldErrors, onNextClick, onGoBackClick }) => {
  const dispatch = useAppDispatch();

  const login = useSelector(PasswordRecoverySelectors.selectLogin);

  const {
    validationResult,
    validateForm,
  } = usePasswordRecoveryFormValidation({ login });

  const onNextClickInner = useCallback(() => {
    if (validateForm()) {
      onNextClick();
    }
  }, [login]);

  const onValueChange = useCallback((value: string) => {
    dispatch(setLogin(value));
  }, []);

  return (
    <div className="password-recovery-form">
      <div className="form-header">
        <Logo />
        <h2>Введите данные аккаунта</h2>
        <AppInput
          label="Телефон"
          className="password-recovery-phone-input"
          showClearButton
          mask={phoneMask}
          value={login}
          error={validationResult.login?.error || fieldErrors?.contact?.error}
          helperText={validationResult.login?.messages?.[0] || fieldErrors?.contact?.message}
          onValueChange={onValueChange}
        />
      </div>

      <div className="form-actions">
        <AppButton
          label="Продолжить"
          className="sign-in-button"
          onClick={onNextClickInner}
        />
        <AppLink
          text="Назад"
          onClick={onGoBackClick}
        />
      </div>
    </div>
  );
};

export const PasswordRecoveryForm = memo(PasswordRecoveryFormInner);
