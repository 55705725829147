import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import { SyncIcon } from '@/common/components/icons/sync-icon/SyncIcon';
import { SyncItemStatusIcon } from '@/modules/portal/components/update-sync/components/sync-item-status-icon/SyncItemStatusIcon';
import { SyncItemTooltip } from '@/modules/portal/components/update-sync/components/sync-item-tooltip/SyncItemTooltip';
import { SyncItem } from '@/modules/portal/models/sync/sync-item';
import { SyncType, getSyncTypeLabel } from '@/modules/portal/models/sync/sync-type';
import React, { FC, memo, useCallback } from 'react';
import './update-sync-item.scss';

interface UpdateSyncItemProps {
  syncItem: SyncItem;
  hintText?: string;
  isLoading?: boolean;
  onStartSync?: (type: SyncType) => void;
}

const UpdateSyncItemInner: FC<UpdateSyncItemProps> = ({ syncItem, hintText, isLoading, onStartSync }) => {
  const onStartSyncInner = useCallback(() => onStartSync(syncItem.type), [syncItem.type]);

  return (
    <div className="update-sync-item">
      <div className="label">
        {getSyncTypeLabel(syncItem.type)} {hintText && <HintIcon text={hintText} />}
      </div>
      <div className="actions">
        {onStartSync && (
          <AppIconButton
            className="start-sync-icon"
            icon={<SyncIcon isLoading={isLoading} />}
            onClick={onStartSyncInner}
          />
        )}
        <AppTooltip text={<SyncItemTooltip syncItem={syncItem} />}>
          <div className="sync-item-status-icon-container">
            <SyncItemStatusIcon status={syncItem.state} />
          </div>
        </AppTooltip>
      </div>
    </div>
  );
};

export const UpdateSyncItem = memo(UpdateSyncItemInner);