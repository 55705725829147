export enum UsersManagementTableColumns {
  Role = 'role',
  Fio = 'fio',
  Position = 'position',
  Organization = 'organization',
  ActivityPlaces = 'activityPlaces',
  CreatedDate = 'createdDate',
  LastSigninDate = 'lastSigninDate',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
}
