export enum InteractionLogTableColumns {
  Organization = 'organization',
  CreatedDate = 'createdDate',
  DocNumber = 'docNumber',
  Event = 'event',
  Source = 'source',
  Target = 'target',
  User = 'user',
  Status = 'status',
  CreatedDateMdlp = 'createdDateMdlp',
  NotificationHistory = 'notificationHistory'
}