import { OrganizationInfoDto } from '@/modules/organization-profile/models/dto/organization-info-dto';

export interface OrganizationOwner {
  firstName: string;
  lastName: string;
  secondName: string;
  phoneNumber: string;
  email: string;
  position: string;
}

export function convertOrganizationInfoFromDtoToOwnerModel(dto: OrganizationInfoDto) {
  return {
    firstName: dto.ownerFirstName,
    lastName: dto.ownerLastName,
    secondName: dto.ownerSecondName,
    phoneNumber: dto.ownerPhoneNumber,
    email: dto.ownerEmail,
    position: dto.ownerPosition,
  };
}
