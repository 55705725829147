import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableRowType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { DocumentCardPositionsTableProps } from '@/shared/document/components/document-card-positions-table/DocumentCardPositionsTable';
import { DocumentCardPosition } from '@/shared/document/models/document-card/positions/document-card-position';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

type DocumentCardPositionsTableEventHandlersHookParams = Pick<DocumentCardPositionsTableProps, 'api' | 'rootActions' | 'tableConfigActions'>;

export function useDocumentCardPositionsTableEventHandlers({ api, rootActions, tableConfigActions }: DocumentCardPositionsTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { id: docUuid } = useParams();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(tableConfigActions.updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(tableConfigActions.updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(tableConfigActions.updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(tableConfigActions.updateTableColumnSizingState(value));
  }, []);

  const onDeleteRow = useCallback(({ index, original }: AppTableRowType<DocumentCardPosition>) => {
    const { gtin, seriesNumber } = original;

    return asyncDispatch(
      () => api.deletePosition({ docUuid, gtin, seriesNumber }),
      () => dispatch(rootActions.deletePosition(index))
    );
  }, [docUuid]);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onDeleteRow
  };
}
