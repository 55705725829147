import { disposalCardModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardApi } from '@/modules/disposals/store/disposal-card/card/async-thunks';
import { DocumentType } from '@/shared/document/models/document-type';
import { createDocumentCardSlice } from '@/shared/document/store/document-card/card';

export const disposalCardSlice = createDocumentCardSlice({
  moduleName: disposalCardModuleName,
  api: disposalCardApi,
  docType: DocumentType.OtherDisposals,
});

export const disposalCardActions = disposalCardSlice.actions;

export default disposalCardSlice.reducer;
