import { storageKeysRegistration } from '@/modules/auth/constants/storage-keys/storage-keys-registration';
import { CodeStatus } from '@/modules/auth/models/code-status';
import { RegistrationFormCodes } from '@/modules/auth/models/registration-form-codes';
import { RegistrationFormData } from '@/modules/auth/models/registration-form-data';
import { SignupConfirmContactReturnValue } from '@/modules/auth/models/signup-confirm-contact/signup-confirm-contact-return-value';
import { sendEmailCode, sendPhoneCode, signUpInit, signupAuthCode, signupComplete } from '@/modules/auth/store/registration/async-thunks';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

interface RegistrationFormState {
  formData: Partial<RegistrationFormData>,
  userUuid: string;
  codes: Partial<RegistrationFormCodes>,
  authCode: string;
  activeStep: number;
  isLoadingStep2: boolean;
  isLoadingStep3: boolean;
  expireTimePhone: string;
  expireTimeEmail: string;
  isEmailConfirmContactInProgress: boolean;
  isPhoneNumberConfirmContactInProgress: boolean;
  password: string;
  isOpenCertificatesListDialog: boolean;
  isOpenLicenceAgreement: boolean;
}

const initialState: RegistrationFormState = {
  formData: JSON.parse(sessionStorage.getItem(storageKeysRegistration.FORM_DATA)) ?? {},
  userUuid: sessionStorage.getItem(storageKeysRegistration.USER_UUID) ?? '',
  codes: {
    email: {
      value: sessionStorage.getItem(storageKeysRegistration.VERIFIED_EMAIL_CODE) ?? '',
      codeStatus: sessionStorage.getItem(storageKeysRegistration.VERIFIED_EMAIL_CODE) ? CodeStatus.Verified : undefined,
    },
    phoneNumber: {
      value: sessionStorage.getItem(storageKeysRegistration.VERIFIED_PHONE_CODE) ?? '',
      codeStatus: sessionStorage.getItem(storageKeysRegistration.VERIFIED_PHONE_CODE) ? CodeStatus.Verified : undefined,
    },
  },
  authCode: '',
  activeStep: +sessionStorage.getItem(storageKeysRegistration.STEP) ?? 0,
  isLoadingStep2: false,
  isLoadingStep3: false,
  expireTimePhone: sessionStorage.getItem(storageKeysRegistration.EXPIRE_TIME_PHONE) ?? '',
  expireTimeEmail: sessionStorage.getItem(storageKeysRegistration.EXPIRE_TIME_EMAIL) ?? '',
  isEmailConfirmContactInProgress: false,
  isPhoneNumberConfirmContactInProgress: false,
  password: '',
  isOpenCertificatesListDialog: false,
  isOpenLicenceAgreement: false
};

const registrationFormSlice = createSlice({
  name: 'registrationForm',
  initialState,
  reducers: {
    updateFormDataValue(state, action: PayloadAction<Partial<RegistrationFormData>>) {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };

      sessionStorage.setItem(storageKeysRegistration.FORM_DATA, JSON.stringify(state.formData));
    },
    updateFormCodesValue(state, action: PayloadAction<Partial<RegistrationFormCodes>>) {
      state.codes = {
        email: { ...state.codes.email, ...action.payload.email },
        phoneNumber: { ...state.codes.phoneNumber, ...action.payload.phoneNumber }
      };
    },
    resetFormCodesValue(state) {
      state.codes = {};
      sessionStorage.removeItem(storageKeysRegistration.VERIFIED_EMAIL_CODE);
      sessionStorage.removeItem(storageKeysRegistration.VERIFIED_PHONE_CODE);
    },
    resetStateInner(state) {
      state.formData = {};
      state.codes = {};
      state.userUuid = '';
      state.authCode = '';
      state.activeStep = 0;
      state.isLoadingStep2 = false;
      state.expireTimePhone = '';
      state.expireTimeEmail = '';
      state.isEmailConfirmContactInProgress = false;
      state.isPhoneNumberConfirmContactInProgress = false;
      state.password = '';
      Object.keys(storageKeysRegistration).forEach((key: keyof typeof storageKeysRegistration) =>
        sessionStorage.removeItem(storageKeysRegistration[key])
      );
    },
    setStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
      sessionStorage.setItem(storageKeysRegistration.STEP, action.payload + '');
    },
    setIsLoadingStep2(state, action: PayloadAction<boolean>) {
      state.isLoadingStep2 = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setCodeStatus(state, action: PayloadAction<SignupConfirmContactReturnValue>) {
      const { contactType, status } = action.payload;
      state.codes[contactType].codeStatus = status;

      const storageKey = contactType === 'email' ? storageKeysRegistration.VERIFIED_EMAIL_CODE : storageKeysRegistration.VERIFIED_PHONE_CODE;
      if (status === CodeStatus.Verified) {
        sessionStorage.setItem(storageKey, state.codes?.[contactType].value);
      }
    },
    setIsEmailConfirmContactInProgress(state, action: PayloadAction<boolean>) {
      state.isEmailConfirmContactInProgress = action.payload;
    },
    setIsPhoneNumberConfirmContactInProgress(state, action: PayloadAction<boolean>) {
      state.isPhoneNumberConfirmContactInProgress = action.payload;
    },
    setIsOpenCertificatesListDialog(state, action: PayloadAction<boolean>) {
      state.isOpenCertificatesListDialog = action.payload;
    },
    setIsOpenLicenceAgreement(state, action: PayloadAction<boolean>) {
      state.isOpenLicenceAgreement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (state) => {
        registrationFormSlice.caseReducers.resetStateInner(state);
      })
      .addCase(signUpInit.pending, (state) => {
        state.isLoadingStep2 = true;
      })
      .addCase(signUpInit.fulfilled, (state, action) => {
        state.userUuid = action.payload.userUuid;
        sessionStorage.setItem(storageKeysRegistration.USER_UUID, action.payload.userUuid);
      })
      .addCase(signUpInit.rejected, (state) => {
        state.isLoadingStep2 = false;
      })
      .addCase(signupAuthCode.fulfilled, (state, action) => {
        state.isLoadingStep2 = false;
        state.activeStep = 2;
        sessionStorage.setItem(storageKeysRegistration.STEP, '2');
        state.expireTimeEmail = action.payload.expireTimeEmail;
        state.expireTimePhone = action.payload.expireTimePhone;
        sessionStorage.setItem(storageKeysRegistration.EXPIRE_TIME_EMAIL, state.expireTimeEmail);
        sessionStorage.setItem(storageKeysRegistration.EXPIRE_TIME_PHONE, state.expireTimePhone);
      })
      .addCase(signupAuthCode.rejected, (state) => {
        state.isLoadingStep2 = false;
      })
      .addCase(sendEmailCode.fulfilled, (state, action) => {
        state.expireTimeEmail = action.payload;
        sessionStorage.setItem(storageKeysRegistration.EXPIRE_TIME_EMAIL, state.expireTimeEmail);
      })
      .addCase(sendPhoneCode.fulfilled, (state, action) => {
        state.expireTimePhone = action.payload;
        sessionStorage.setItem(storageKeysRegistration.EXPIRE_TIME_PHONE, state.expireTimePhone);
      })
      .addCase(signupComplete.pending, (state) => {
        state.isLoadingStep3 = true;
      })
      .addCase(signupComplete.fulfilled, (state) => {
        state.isLoadingStep3 = false;
      })
      .addCase(signupComplete.rejected, (state) => {
        state.isLoadingStep3 = false;
      });
  },
});

export const resetState = createAction('resetState');

export const {
  updateFormDataValue,
  updateFormCodesValue,
  resetFormCodesValue,
  setStep,
  setIsLoadingStep2,
  setPassword,
  setCodeStatus,
  setIsEmailConfirmContactInProgress,
  setIsPhoneNumberConfirmContactInProgress,
  setIsOpenCertificatesListDialog,
  setIsOpenLicenceAgreement
} = registrationFormSlice.actions;

export default registrationFormSlice.reducer;
