import { AppTable } from '@/common/components/app-table/components/app-table/AppTable';
import { AppTableMaxHeightForTableInDialog } from '@/common/constants/app-table-constants';
import { useLegalEntitiesActivityPlacesTableEventHandlers } from '@/modules/references/hooks/legal-entities-activity-places/use-legal-entities-activity-places-table-event-handlers';
import { useLegalEntitiesActivityPlacesTableSettings } from '@/modules/references/hooks/legal-entities-activity-places/use-legal-entities-activity-places-table-settings';
import { LegalEntitiesActivityPlacesSelectors } from '@/modules/references/store/legal-entities-activity-places/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const LegalEntitiesActivityPlacesTable = () => {
  const activityPlaces = useSelector(LegalEntitiesActivityPlacesSelectors.selectActivityPlaces);
  const isLoading = useSelector(LegalEntitiesActivityPlacesSelectors.selectIsLoading);
  const tableConfig = useSelector(LegalEntitiesActivityPlacesSelectors.selectTableConfig);

  const { columns } = useLegalEntitiesActivityPlacesTableSettings();

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
  } = useLegalEntitiesActivityPlacesTableEventHandlers();

  return (
    <AppTable
      data={activityPlaces}
      columns={columns}
      isLoading={isLoading}
      configState={tableConfig}
      maxHeight={`${AppTableMaxHeightForTableInDialog}px`}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
    />
  );
};
