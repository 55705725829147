import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { DocumentCardScanningState } from '@/shared/document/store/document-card/scanning/index';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

interface DocumentCardScanningSelectorsParams {
  selectDocumentCardScanningState: (state: RootState) => DocumentCardScanningState;
}

export const createDocumentCardScanningSelectors = ({ selectDocumentCardScanningState }: DocumentCardScanningSelectorsParams) => {
  const selectLastScannedItem = (state: RootState) => selectDocumentCardScanningState(state).lastScannedItem;
  const selectIsGettingLastScannedItemInProcess = (state: RootState) => selectDocumentCardScanningState(state).isGettingLastScannedItemInProcess;
  const selectIsDeletingInProcess = (state: RootState) => selectDocumentCardScanningState(state).isDeletingInProcess;

  const selectRowsSelection = (state: RootState) => selectDocumentCardScanningState(state).rowsSelection;
  const selectSavedTableConfig = (state: RootState) => selectDocumentCardScanningState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectSavedTableConfig(state).sorting;

  const selectPageableDataState = (state: RootState) => selectDocumentCardScanningState(state).pageableData;
  const selectScannedItems = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
  const selectIsLoadingScannedItems = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

  const selectFilterConfig = (state: RootState) => selectDocumentCardScanningState(state).filterConfig;
  const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
  const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
  const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);

  const selectSearchState = (state: RootState) => selectDocumentCardScanningState(state).search;
  const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

  const selectTableConfig = createSelector(
    [selectRowsSelection, selectSavedTableConfig],
    (rowSelection, savedTableConfig) => ({ rowSelection, ...savedTableConfig })
  );

  return {
    selectLastScannedItem,
    selectIsGettingLastScannedItemInProcess,
    selectIsDeletingInProcess,
    selectRowsSelection,
    selectTableConfig,
    selectTableSorting,
    selectPageableDataState,
    selectScannedItems,
    selectIsLoadingScannedItems,
    selectFilterConfig,
    selectIsPanelOpen,
    selectFilters,
    selectSelectedFiltersCount,
    selectSearchState,
    selectSearchString
  };
};
