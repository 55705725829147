import { numbersRegExp } from '@/common/constants/reg-exps';
import { CodeStatus } from '@/modules/auth/models/code-status';
import React, { ChangeEvent, FC, KeyboardEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import './phone-code-input.scss';

interface PhoneCodeInputProps {
  value: string;
  status?: CodeStatus;
  onInput: (value: string) => void;
  onCompleteInput: (value: string) => void;
}

const PhoneCodeInputInner: FC<PhoneCodeInputProps> = ({ value, status, onInput, onCompleteInput }) => {
  const emptyValue = ' ';

  const input1Ref = useRef<HTMLInputElement>();
  const input2Ref = useRef<HTMLInputElement>();
  const input3Ref = useRef<HTMLInputElement>();
  const input4Ref = useRef<HTMLInputElement>();
  const input5Ref = useRef<HTMLInputElement>();

  const inputRefs = [input1Ref, input2Ref, input3Ref, input4Ref, input5Ref];
  const [innerValues, setInnerValue] = useState([...value]);
  const maxValueLength = 5;

  useEffect(() => {
    setInnerValue([...value]);
    !value && input1Ref.current?.focus();
  }, [value]);

  const onChangeInner = (e: ChangeEvent<HTMLInputElement>, order: number) => {
    if (e.target?.value?.match(numbersRegExp)) {
      innerValues[order] = e.target.value;

      const newValue = innerValues.join('');
      onInput(newValue);

      const hasEmpty = newValue.includes(emptyValue);
      if (!hasEmpty && newValue.length === maxValueLength) {
        onCompleteInput(newValue);
      }

      const nextRefIndex = order === maxValueLength - 1 ? maxValueLength - 1 : order + 1;
      inputRefs[nextRefIndex].current.focus();
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>, order: number) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      if (value[order]?.match(numbersRegExp)) {
        innerValues[order] = emptyValue;

        const newValue = innerValues.join('');
        onInput(newValue);
      }
      order > 0 && inputRefs[order - 1].current.focus();
      return;
    }

    if (e.key === 'ArrowLeft') {
      const actualOrder = order === 0 ? 0 : order - 1;
      inputRefs[actualOrder].current.focus();
      return;
    }

    if (e.key === 'ArrowRight') {
      const actualOrder = order === maxValueLength - 1 ? maxValueLength - 1 : order + 1;
      inputRefs[actualOrder].current.focus();
    }
  };

  const className = useMemo(() =>
    `phone-code-input ${status === CodeStatus.Verified ? 'verified' : ''} ${status === CodeStatus.Denied ? 'denied' : ''}`, [status]);

  const getValue = (index: number) => {
    const value = innerValues[index];
    if (value === emptyValue || !value) {
      return '';
    }

    return value;
  }

  return (
    <div className={className}>
      {Array(maxValueLength).fill(undefined).map((_, index) =>
        <input
          type="text"
          key={index}
          value={getValue(index)}
          disabled={status === CodeStatus.Verified}
          maxLength={1}
          ref={inputRefs[index]}
          onChange={(e) => onChangeInner(e, index)}
          onKeyDown={(e) => onKeyDown(e, index)}
        />
      )}
    </div>
  );
};

export const PhoneCodeInput = memo(PhoneCodeInputInner);
