import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import { useEffect, useRef } from 'react';

type DocumentCardTabContentHeightHookParams = Pick<DocumentCardPageProps, 'documentCardActions'>;

export const useDocumentCardTabContentHeight = ({ documentCardActions }: DocumentCardTabContentHeightHookParams) => {
  const dispatch = useAppDispatch();
  const tabContentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    // TODO: make it responsive?
    const tabContentHeight = window.innerHeight - tabContentRef.current?.getBoundingClientRect().top;
    dispatch(documentCardActions.setTabContentHeight(tabContentHeight));
  }, [tabContentRef.current]);

  return {
    tabContentRef
  };
};
