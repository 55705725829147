import { saleCardScanningModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardSelectors } from '@/modules/sale/store/sale-card/card/selectors';
import { saleCardScanningSelectors } from '@/modules/sale/store/sale-card/scanning/selectors';
import { createDocumentCardScanningThunks } from '@/shared/document/store/document-card/scanning/async-thunks';

export const saleCardScanningApi = createDocumentCardScanningThunks({
  moduleName: saleCardScanningModuleName,
  documentCardSelectors: saleCardSelectors,
  documentCardScanningSelectors: saleCardScanningSelectors,
});
