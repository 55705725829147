import { OrganizationPlaceShortDto } from '@/common/models/common-data-sources/organization-places/organization-place-short-dto';
import { DataLoadingState } from '@/common/models/data-loading-state';
import { commonDataSourceModuleName, getOrganizationPlacesDataSource } from '@/common/store/common-data-sources/async-thunks';
import { createSlice } from '@reduxjs/toolkit';

interface CommonDataSourcesState {
  organizationPlaces: DataLoadingState<OrganizationPlaceShortDto[]>,
}

const initialState: CommonDataSourcesState = {
  organizationPlaces: { data: [], isLoading: false },
};

const commonDataSourcesSlice = createSlice({
  name: commonDataSourceModuleName,
  initialState,
  reducers: {
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof CommonDataSourcesState) => {
          state[key] = initialState[key];
        });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationPlacesDataSource.pending, (state) => {
        state.organizationPlaces.isLoading = true;
      })
      .addCase(getOrganizationPlacesDataSource.rejected, (state) => {
        state.organizationPlaces.isLoading = false;
      })
      .addCase(getOrganizationPlacesDataSource.fulfilled, (state, action) => {
        state.organizationPlaces = { data: action.payload, isLoading: false };
      });
  },
});

export const {
  resetState,
} = commonDataSourcesSlice.actions;

export default commonDataSourcesSlice.reducer;
