import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';

function getDocumentCardRsqlFilter(docUuid?: string): RsqlFilterModel<ShortDocumentInfo>[] {
  return [
    {
      selector: 'docUuid',
      operation: RsqlFilterOperations.Equal,
      value: docUuid,
    },
  ];
}

export function buildDocumentCardFilterExpression(documentId?: string): string {
  const filterModel = getDocumentCardRsqlFilter(documentId);
  const filterExpressions = convertToFilterExpressions(filterModel);

  return joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And);
}
