import { DialogCommonState } from '@/common/hooks/use-dialog';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { defaultLegalEntitiesActivityPlacesTableConfig } from '@/modules/references/constants/legal-entities-activity-places-table-config';
import { legalEntitiesActivityPlacesModuleName } from '@/modules/references/constants/references-module-names';
import { LegalEntityActivityPlace } from '@/modules/references/models/legal-entities-activity-places/legal-entity-activity-place';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';
import { getAllLegalEntitiesActivityPlaces } from '@/modules/references/store/legal-entities-activity-places/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LegalEntitiesActivityPlacesState extends DialogCommonState {
  currentLegalEntity?: LegalEntity;
  activityPlaces: LegalEntityActivityPlace[],
  isLoadingData: boolean;
  tableConfig: SavedTableConfigState;
}

const notClearedFields: Set<keyof LegalEntitiesActivityPlacesState> = new Set(['tableConfig']);

const tableConfigState: SavedTableConfigState = {
  tableConfigName: 'legal-entities-activity-places-table',
  config: defaultLegalEntitiesActivityPlacesTableConfig,
};

const tableConfigSlice = createSavedTableConfigSlice(legalEntitiesActivityPlacesModuleName, tableConfigState);

const initialState: LegalEntitiesActivityPlacesState = {
  isDialogOpened: false,
  currentLegalEntity: undefined,
  activityPlaces: [],
  isLoadingData: false,
  tableConfig: tableConfigSlice.getInitialState(),
};

const legalEntitiesActivityPlacesSlice = createSlice({
  name: legalEntitiesActivityPlacesModuleName,
  initialState,
  reducers: {
    setIsDialogOpened(state, action: PayloadAction<boolean>) {
      state.isDialogOpened = action.payload;
    },
    updateCurrentLegalEntity(state, action: PayloadAction<LegalEntity>) {
      state.currentLegalEntity = action.payload;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof LegalEntitiesActivityPlacesState) => {
          if (!notClearedFields.has(key)) {
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLegalEntitiesActivityPlaces.pending, (state: LegalEntitiesActivityPlacesState) => {
        state.isLoadingData = true;
      })
      .addCase(getAllLegalEntitiesActivityPlaces.fulfilled, (state: LegalEntitiesActivityPlacesState, action: PayloadAction<LegalEntityActivityPlace[]>) => {
        state.activityPlaces = action.payload;
        state.isLoadingData = false;
      })
      .addCase(getAllLegalEntitiesActivityPlaces.rejected, (state: LegalEntitiesActivityPlacesState) => {
        state.isLoadingData = false;
      })
      .addMatcher(
        (action) => action.type.startsWith(tableConfigSlice.name),
        (state, action) => {
          state.tableConfig = tableConfigSlice.reducer(state.tableConfig, action);
        }
      );
  },
});

export const {
  setIsDialogOpened,
  updateCurrentLegalEntity,
  resetState,
} = legalEntitiesActivityPlacesSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = tableConfigSlice.actions;

export default legalEntitiesActivityPlacesSlice.reducer;
