import { saleCardSelectors } from '@/modules/sale/store/sale-card/card/selectors';
import {
  saleCardScanningFilterConfigSlice,
  saleCardScanningPageableDataActions,
  saleCardScanningRootActions,
  saleCardScanningSearchingActions,
  saleCardScanningTableConfigActions
} from '@/modules/sale/store/sale-card/scanning';
import { saleCardScanningApi } from '@/modules/sale/store/sale-card/scanning/async-thunks';
import { saleCardScanningSelectors } from '@/modules/sale/store/sale-card/scanning/selectors';
import { DocumentCardScanningPage } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import React from 'react';

export const SaleCardScanningPage = () => (
  <DocumentCardScanningPage
    documentCardSelectors={saleCardSelectors}
    documentCardScanningSelectors={saleCardScanningSelectors}
    documentCardScanningActions={saleCardScanningRootActions}
    documentCardScanningPageableDataActions={saleCardScanningPageableDataActions}
    documentCardScanningSearchingActions={saleCardScanningSearchingActions}
    documentCardScanningTableConfigActions={saleCardScanningTableConfigActions}
    documentCardScanningFilterConfigActions={saleCardScanningFilterConfigSlice}
    documentCardScanningApi={saleCardScanningApi}
  />
);
