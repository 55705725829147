import { disposalCardRemainsModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { addRemainsToDocument } from '@/modules/disposals/store/disposal-card/remains/document-actions/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DisposalCardRemainsDocumentActionsState {
  isAddingInProgress: boolean;
  isAddingWarnDialogOpened: boolean;
  addingRemainsWarnInfo: string[];
}

const initialState: DisposalCardRemainsDocumentActionsState = {
  isAddingInProgress: false,
  isAddingWarnDialogOpened: false,
  addingRemainsWarnInfo: []
};

const disposalCardRemainsDocumentActionsSlice = createSlice({
  name: disposalCardRemainsModuleName,
  initialState,
  reducers: {
    setIsOpenedAddingRemainsSuccessfulDialog(state, action: PayloadAction<boolean>) {
      state.isAddingWarnDialogOpened = action.payload;
    },
    setAddingRemainsWarnInfo(state, action: PayloadAction<string[]>) {
      state.addingRemainsWarnInfo = action.payload;
    },
    resetState(state: DisposalCardRemainsDocumentActionsState) {
      Object.keys(state)
        .forEach((key: keyof DisposalCardRemainsDocumentActionsState) => {
          state[key] = initialState[key];
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRemainsToDocument.pending, (state) => {
        state.isAddingInProgress = true;
      })
      .addCase(addRemainsToDocument.fulfilled, (state) => {
        state.isAddingInProgress = false;
        state.isAddingWarnDialogOpened && (state.isAddingWarnDialogOpened = false);
      })
      .addCase(addRemainsToDocument.rejected, (state) => {
        state.isAddingInProgress = false;
      });
  },
});

export const disposalCardRemainsDocumentActions = disposalCardRemainsDocumentActionsSlice.actions;

export default disposalCardRemainsDocumentActionsSlice.reducer;
