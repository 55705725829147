import { DocumentStatus } from '@/shared/document/models/document-status';

/**
 * Sign in urls.
 */
export const signinInitUrl = '/signin/init';
export const signinCompleteUrl = '/signin/complete';

/**
 * Logout urls.
 */
export const logoutUrl = '/logout';

/**
 * Sign up urls.
 */
export const signupValidateContactsUrl = '/validate/contacts';
export const signupInitUrl = '/signup/admin/init';
export const signupAuthCodeUrl = '/signup/admin/auth-code';
export const signupConfirmContactUrl = '/signup/admin/confirm-contact';
export const signupCompleteUrl = '/signup/admin/complete';
export const signupManagerVerifyPhoneNumberUrl = '/signup/manager/verify-phone-number';
export const signupManagerCompleteUrl = '/signup/manager/complete';

/**
 * Password urls.
 */
export const passwordChangeUrl = '/password/change';
export const passwordRecoveryInitUrl = '/password/recovery/init';
export const passwordRecoveryCompleteUrl = '/password/recovery/complete';

/**
 * Verification code sending urls.
 */
export const verificationCodeSendUrl = '/verification-code/send';
export const verificationCodeSendByCipcherUrl = '/verification-code/send-by-cipher';

/**
 * Portal urls.
 */
export const headerInfoUrl = '/header/info';
export const mdlpAuthCodeUrl = '/mdlp/auth-code';
export const mdlpTokenUrl = '/mdlp/token';
export const mdlpSyncInfoUrl = '/mdlp/sync/info';
export const mdlpSyncStartUrl = '/mdlp/sync/start';

/**
 * Organization profile urls.
*/
export const organizationInfoUrl = '/organization/info';
export const activityPlaceUrl = '/activity-place/all';
export const activityPlaceSlimUrl = '/activity-place/all-slim';
export const activityPlaceTypeUrl = '/activity-place/type';

/**
 * Users urls.
*/
export const userInfoUrl = '/user/info';
export const allUsersUrl = '/user/all';
export const userUrl = '/user';
export const activateUserUrl = '/user/activate';
export const blockUserUrl = '/user/block';

/**
 * User management urls.
 */
export const inviteManagerUrl = '/manager/re-invite';
export const manangerUrl = '/manager';

/**
 * Interaction log urls.
 */
const baseInteractionUrl = 'interaction';
export const allInteractionLogUrl = `/${baseInteractionUrl}/all`;
export const resendInteractionInit = (eventUuid: string) => `${baseInteractionUrl}/resend/${eventUuid}/init`;
export const resendInteractionSend = (eventUuid: string) => `${baseInteractionUrl}/resend/${eventUuid}/send`;
export const interactionLogNotificationHistoryUrl = (eventUuid: string) => `/${baseInteractionUrl}/${eventUuid}/history`;

/**
 * Processes reference urls.
 */
export const allProcessesUrl = '/dict/processes';

/**
 * Legal entities reference urls.
 */
const baseLegalEntityUrl = '/dict/partner';
export const allLegalEntitiesUrl = `${baseLegalEntityUrl}/filter`;
export const legalEntityUrl = (partnerUuid: string) => `${baseLegalEntityUrl}/${partnerUuid}`;
export const allLegalEntityActivityPlacesUrl = (partnerUuid: string) => `${legalEntityUrl(partnerUuid)}/places`;

/**
 * Mdlp products reference urls.
 */
const baseMdlpProductUrl = '/dict/product';
export const allMdlpProductsUrl = `${baseMdlpProductUrl}/filter`;
export const mdlpProductByGtintUrl = (gtin: string) => `${baseMdlpProductUrl}/by-gtin/${gtin}`;

/**
 * Nomenclature reference urls.
 */
const baseNomenclatureUrl = '/dict/nomenclature';
export const allNomenclatureUrl = `${baseNomenclatureUrl}/filter`;
export const syncNomenclatureUrl = `${baseNomenclatureUrl}/sync`;

/**
 * Documents urls.
 */
const baseDocumentUrl = 'document';
export const allDocumentsUrl = `${baseDocumentUrl}/filter`;
export const documentUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}`;
export const initDocumentUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/init`;
export const initDocumentUnboxSsccUrl = initDocumentUrl('unbox-sscc');
export const sendDocumentUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/send`;
export const allFavoriteDocumentsUrl = `${baseDocumentUrl}/favorite/filter`;
export const favoriteDocumentUrl = (docUuid: string) => `${baseDocumentUrl}/favorite/${docUuid}`;
export const promoteDocumentUrl = (docUuid: string, state: DocumentStatus) => `${baseDocumentUrl}/${docUuid}/promote/${state}`;
export const areAllMedicinesScannedInDocumentUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/are-all-medicines-scanned`;

/**
 * Document boxes urls.
 */
export const documentBoxesUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/sscc-boxes`;
export const documentBoxUrl = (boxUuid: string) => `${baseDocumentUrl}/sscc-box/${boxUuid}`;

/**
 * Document positions urls.
 */
export const documentPositionsUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/positions`;
export const documentPositionUrl = `${baseDocumentUrl}/position`;

/**
 * Document history urls.
 */
export const documentHistoryUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/history`;

/**
 * Document notes urls.
 */
export const documentNoteUrl = `${baseDocumentUrl}/note`;
export const allDocumentNotesUrl = `${documentNoteUrl}/filter`;
export const documentNoteByUuidUrl = (documentNoteUuid: string) => `${documentNoteUrl}/${documentNoteUuid}`;
export const revertDocumentNoteUrl = (documentNoteUuid: string) => `${documentNoteUrl}/revert/${documentNoteUuid}`;

/**
 * Document scanning urls.
 */
export const documentScanningUrl = `${baseDocumentUrl}/scan`;
export const allDocumentScanningUrl = `${documentScanningUrl}/filter`;
export const resetDocumentScanningUrl = `${documentScanningUrl}/reset`;
export const createDocumentScanningUrl = (docUuid: string) => `${baseDocumentUrl}/${docUuid}/scan`;

/**
 * Remains urls.
 */
export const baseRemainUrl = '/remaining-med';
export const allRemainsUrl = `${baseRemainUrl}/filter`;

/**
 * File urls.
 */
export const allFileOperationsUrl = '/file-operation/all';
export const fileDownloadUrl = (fileUuid: string) => `/file/download/${fileUuid}`;
export const fileUploadUrl = '/file/upload';
export const fileExportUrl = '/file/export';
