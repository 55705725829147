import BarcodeIcon from '@/assets/icons/barcode.svg?react';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppList } from '@/common/components/app-list/AppList';
import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { useDebouncedInput } from '@/common/hooks/use-debounced-input';
import React, { FC, memo, useState } from 'react';
import './scanning-view-scan-input.scss';

interface ScanningViewScanInputProps {
  readOnly: boolean;
  onScan: (scannedText: string) => AsyncDispatchResult;
}

const ScanningViewScanInputInner: FC<ScanningViewScanInputProps> = ({ readOnly, onScan }) => {
  const { debouncedInput } = useDebouncedInput();

  const [value, setValue] = useState('');

  const onInput = (value: string) => {
    setValue(value);
    debouncedInput(() => {
      onScan(value)
        .unwrap()
        .then(() => setValue(''));
    });
  };

  return (
    <AppInput
      value={value}
      hint={<div className="scanning-view-scanning-input-tooltip">
        Для корректного сканирования - включите английскую раскладку клавиатуры и проверьте, выключен ли CapsLock. <br /><br />
        В Системе существует несколько статусов отсканированного товара:
        <AppList
          items={[
            'Товар найден (когда полная информация о товаре уже присутствует в документе);',
            'Товар не найден (в текущем документе или в справочнике «Товары МДЛП» отсутствует информация о товаре);',
            'Товар уже отсканирован (если товар с таким SGTIN/SSCC уже был отсканирован ранее для текущего документа);',
            'Ошибка сканирования (штрихкод плохо считывается, система не может его обработать или пользователь пытается отсканировать товар, которого нет в документе);',
            'Товар отсканирован, детальная информация появится в системе позже (система ждёт ответа от МДЛП по отсканированному товару).',
          ]}
        />
      </div>}
      iconStart={<BarcodeIcon className="scanning-input-icon" />}
      placeholder="Установите курсор и отсканируйте товар"
      className="scanning-view-scanning-input"
      readOnly={readOnly}
      onInput={onInput}
    />
  );
};

export const ScanningViewScanInput = memo(ScanningViewScanInputInner);
