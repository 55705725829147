import { DocumentCardBox } from '@/shared/document/models/document-card/boxes/document-card-box';
import { DocumentCardBoxesTableColumns } from '@/shared/document/models/document-card/boxes/document-card-boxes-table-columns';

const documentCardBoxesTableColumnsToDtoMap: Record<DocumentCardBoxesTableColumns, keyof DocumentCardBox> = {
  [DocumentCardBoxesTableColumns.Code]: 'nomenclatureCode',
  [DocumentCardBoxesTableColumns.Name]: 'medicineName',
  [DocumentCardBoxesTableColumns.Producer]: 'producer',
  [DocumentCardBoxesTableColumns.Vital]: 'gnvlp',
  [DocumentCardBoxesTableColumns.Sscc]: 'sscc',
  [DocumentCardBoxesTableColumns.Gtin]: 'gtin',
  [DocumentCardBoxesTableColumns.Series]: 'seriesNumber',
  [DocumentCardBoxesTableColumns.ExpirationDate]: 'expirationDate',
  [DocumentCardBoxesTableColumns.PriceWithVat]: 'priceWithVat',
  [DocumentCardBoxesTableColumns.Vat]: 'vat',
  [DocumentCardBoxesTableColumns.TotalPriceWithVat]: 'totalPriceWithVat',
  [DocumentCardBoxesTableColumns.VatRate]: 'vatRate',
  [DocumentCardBoxesTableColumns.IsProductInOrder]: 'isProductPresentInOrder',
  [DocumentCardBoxesTableColumns.Amount]: 'amount',
  [DocumentCardBoxesTableColumns.ProcessingStatus]: 'processingStatus',
};

export const convertDocumentCardBoxColumnFieldToDto = (columnField: DocumentCardBoxesTableColumns) => documentCardBoxesTableColumnsToDtoMap[columnField];
