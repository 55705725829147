import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { DoneIcon } from '@/common/components/icons/done-icon/DoneIcon';
import { Certificate } from '@/common/models/certificate';
import { AgreementWithRules } from '@/modules/auth/components/agreement-with-rules/AgreementWithRules';
import { useRegistrationFormDataValidation } from '@/modules/auth/hooks/use-registration-form-data-validation';
import { RegistrationFormDataFields } from '@/modules/auth/models/registration-form-data';
import { setIsOpenCertificatesListDialog, setIsOpenLicenceAgreement, updateFormDataValue } from '@/modules/auth/store/registration';
import { RegistrationSelectors } from '@/modules/auth/store/registration/selectors';
import { CertificateListDialog } from '@/modules/certificates/components/certificates-list-dialog/CertificatesListDialog';
import { getCertificates } from '@/modules/certificates/store/async-thunks';
import { CertificatesSelectors } from '@/modules/certificates/store/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './registration-forms.scss';

interface RegistrationStep2FormProps {
  onCheckDataClick: () => void;
  onPrevClick: () => void;
}

const formFields = new Set<RegistrationFormDataFields>(['clientId', 'clientSecret', 'agreementWithRules', 'selectedCertificate']);

export const RegistrationStep2Form: FC<RegistrationStep2FormProps> = ({ onCheckDataClick, onPrevClick }) => {
  const dispatch = useAppDispatch();

  const formData = useSelector(RegistrationSelectors.selectFormData);

  const isLoading = useSelector(RegistrationSelectors.selectIsLoadingStep2);
  const certificates = useSelector(CertificatesSelectors.selectCertificates);
  const open = useSelector(RegistrationSelectors.selectIsOpenCertificatesListDialog);

  const {
    validationResult,
    validateForm,
  } = useRegistrationFormDataValidation(formData, formFields);

  useEffect(() => {
    dispatch(getCertificates());
  }, []);

  const onFiledChange = (fieldName: RegistrationFormDataFields) =>
    ((value: string | boolean) => dispatch(updateFormDataValue({ [fieldName]: value })));

  const onClientIdChange = useCallback(onFiledChange('clientId'), []);
  const onClientSecretChange = useCallback(onFiledChange('clientSecret'), []);
  const onAgreementWithRulesChange = useCallback(onFiledChange('agreementWithRules'), []);

  const onCheckDataClickInner = useCallback(() => {
    validateForm() && onCheckDataClick();
  }, [formData]);

  const onOpenCertificateList = useCallback(() => dispatch(setIsOpenCertificatesListDialog(true)), []);

  const onSelectCertificate = useCallback((preSelectedCertificate: Certificate) => {
    dispatch(updateFormDataValue({ selectedCertificate: preSelectedCertificate }));
    dispatch(setIsOpenCertificatesListDialog(false));
  }, []);

  const closeDialog = useCallback(() => dispatch(setIsOpenCertificatesListDialog(false)), []);

  const onAgreementWithRulesLinkClick = useCallback(() => {
    dispatch(setIsOpenLicenceAgreement(true));
  }, []);

  const labelCertificateButton = useMemo(() =>
    formData.selectedCertificate ? 'Сертификат подключен' : 'Выбрать сертификат'
    , [formData.selectedCertificate]);

  const classNameCertificateButton = useMemo(() =>
    `select-certificate ${formData.selectedCertificate ? 'selected' : ''} ${validationResult.selectedCertificate?.error ? 'error' : ''}`
    , [formData.selectedCertificate, validationResult.selectedCertificate?.error]);

  return (
    <div className="registration-form-container">
      <h1 className="title">Регистрация</h1>
      <h2 className="sub-title">Для продолжения регистрации администратора организации заполните, пожалуйста, все поля</h2>
      <AppInput
        label="Идентификатор клиента МДЛП"
        hint={'Войдите в систему МДЛП, перейдите на вкладку  "Администрирование" -> "Учётные системы" и скопируйте значение "Идентификатор клиента" для выбранной учётной записи'}
        value={formData.clientId}
        error={validationResult.clientId?.error}
        helperText={validationResult.clientId?.messages?.[0]}
        onValueChange={onClientIdChange}
      />
      <AppInput
        label="Секретный код из МДЛП"
        hint='Войдите в систему МДЛП, перейдите на вкладку "Администрирование" -> "Учётные системы" и скопируйте значение "Секретный код" для выбранной учётной записи'
        value={formData.clientSecret}
        error={validationResult.clientSecret?.error}
        helperText={validationResult.clientSecret?.messages?.[0]}
        onValueChange={onClientSecretChange}
      />
      <AppButton
        label={labelCertificateButton}
        className={classNameCertificateButton}
        startIcon={formData.selectedCertificate && <DoneIcon />}
        onClick={onOpenCertificateList}
      />
      <CertificateListDialog
        open={open}
        title="Выберите сертификат"
        subtitle={'Вставьте УКЭП в USB-разъём и нажмите "Обновить"'}
        selectCertificateTitleButton="Выбрать"
        closeDialog={closeDialog}
        onSelectCertificate={onSelectCertificate}
      />
      <AgreementWithRules
        checked={formData.agreementWithRules}
        validationResult={validationResult?.agreementWithRules}
        className="registration-form-agreement-with-rules"
        onChange={onAgreementWithRulesChange}
        onLinkClick={onAgreementWithRulesLinkClick}
      />
      <AppButton
        label="Проверить данные и зарегистрировать"
        className="next-button"
        disabled={isLoading}
        onClick={onCheckDataClickInner}
      />
      <AppLink
        text="Назад"
        onClick={onPrevClick}
      />
      {isLoading && !!certificates.length && <AppLoader
        className="registration-form-container-loader"
        size="small"
      />}
    </div>
  );
};
