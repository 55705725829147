import { axios } from '@/common/api/axios-config';
import { documentHistoryUrl } from '@/common/api/urls';
import { GetDocumentCardHistoryResponse } from '@/shared/document/models/document-card/history/get-document-card-history-response';
import { AxiosResponse } from 'axios';

export class DocumentCardHistoryRequests {

  static getAllHistory(docUuid: string, signal?: AbortSignal): Promise<AxiosResponse<GetDocumentCardHistoryResponse>> {
    return axios.get(documentHistoryUrl(docUuid), { signal });
  }

}
