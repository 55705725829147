import React, { FC } from 'react';
import './app-card.scss';

interface AppCardProps {
  className?: string;
  children?: React.ReactNode;
}

export const AppCard: FC<AppCardProps> = ({ className, children }) => (
  <div className={`app-card ${className ?? ''}`}>
    { children }
  </div>
);
