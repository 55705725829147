export enum ProductAdditionalInfoStatus {
  Success = 'SUCCESS',
  Awaiting = 'AWAITING',
  Error = 'ERROR'
}

export const productAdditionalInfoStatusLabels = {
  [ProductAdditionalInfoStatus.Success]: 'Успешно',
  [ProductAdditionalInfoStatus.Awaiting]: 'Ожидание',
  [ProductAdditionalInfoStatus.Error]: 'Ошибка',
};

export const getProductAdditionalInfoStatusLabel = (status: ProductAdditionalInfoStatus) => productAdditionalInfoStatusLabels[status];

export const getProductAdditionalInfoStatusOptions = () => Object.entries(productAdditionalInfoStatusLabels).map(([key, value]) => ({
  label: value,
  value: key,
}));
