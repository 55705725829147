import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { fileOperationsModuleName } from '@/modules/file-operations/constants/file-operations-module-names';
import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { convertFileOperationColumnFieldToDto } from '@/modules/file-operations/models/file-operations-table-columns-converters';
import { FileOperationsRequests } from '@/modules/file-operations/requests/file-operations-requests';
import { FileOperationsSelectors } from '@/modules/file-operations/store/selectors';
import { buildFileOperationsFilterExpression } from '@/modules/file-operations/utils/filters/file-operations-filter-utils';
import { FileRequests } from '@/shared/files/requests/file-requests';
import { downloadFileFromResponse } from '@/shared/files/utils/file-import-utils';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllFileOperations = createAsyncThunkWrapper<
  PageableDataResponse<FileOperation>,
  PageableDataBody
>(
  `${fileOperationsModuleName}/getAllFileOperations`,
  ({ pageNumber }, { getState }) => {
    const sorting = FileOperationsSelectors.selectTableSorting(getState());
    const filters = FileOperationsSelectors.selectFilters(getState());
    const searchString = FileOperationsSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: AppTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertFileOperationColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const filterParams = buildFileOperationsFilterExpression(filters, searchString);
    if (filterParams) {
      body.filter = filterParams;
    }

    return FileOperationsRequests.getAllFileOperations(body)
      .then(res => res.data);
  },
  { condition: asyncThunkConditionOption(FileOperationsSelectors.selectIsLoadingData) },
);

export const downloadFile = createAsyncThunkWrapper<
  void,
  string
>(
  `${fileOperationsModuleName}/downloadFile`,
  (fileUuid) => FileRequests.downloadFile(fileUuid)
    .then((res) => downloadFileFromResponse(res)),
);
