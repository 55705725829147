import { passwordMaxLength } from '@/common/utils/client-validation/string-lengths';
import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { passwordsEquals, required } from '@/common/utils/client-validation/validation-rules';
import { passwordFieldRules } from '@/common/utils/client-validation/validation-rules-for-fields';
import { errorMessageValidationAgreementWithRules } from '@/modules/auth/constants/error-messages-validation';
import { FormPasswordWithAgreementWithRules, FormPasswordWithAgreementWithRulesFields } from '@/modules/auth/models/form-passwords';
import { useEffect, useMemo } from 'react';

export function useConfirmPasswordValidation(data: Partial<FormPasswordWithAgreementWithRules>) {

  const validationRules = useMemo(() => {
    const rules: FormValidationRules<FormPasswordWithAgreementWithRules, FormPasswordWithAgreementWithRulesFields> = {
      password: passwordFieldRules,
      passwordConfirmation: [...passwordFieldRules, passwordsEquals(data.password)],
    };

    if (data.agreementWithRules !== undefined) {
      rules.agreementWithRules = [required(errorMessageValidationAgreementWithRules)];
    }

    return rules;
  }, [data]);

  const validator = useFormValidation({ validationRules, data });

  useEffect(() => {
    if (data.passwordConfirmation) {
      validator.validateField('passwordConfirmation');
    }
  }, [data.password]);

  return {
    passwordMaxLength,
    ...validator,
  };
}
