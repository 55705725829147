import { AddingRemainsWarnDialog } from '@/modules/disposals/components/remains/adding-remains-warn-dialog/AddingRemainsWarnDialog';
import { DisposalRemainsActions } from '@/modules/disposals/components/remains/disposal-remains-actions/DisaposalRemainsActions';
import { DisposalRemainsFilters } from '@/modules/disposals/components/remains/disposal-remains-filters/DisposalRemainsFilters';
import { useAddingRemainsWarnDialog } from '@/modules/disposals/hooks/remains/use-adding-remains-warn-dialog';
import { useRemainsTableDocumentActions } from '@/modules/disposals/hooks/remains/use-remains-table-document-actions';
import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import { disposalCardRemainsDocumentActions } from '@/modules/disposals/store/disposal-card/remains/document-actions';
import {
  disposalCardRemainsFilterConfigActions,
  disposalCardRemainsPageableDataActions,
  disposalCardRemainsRootActions,
  disposalCardRemainsSearchingActions,
  disposalCardRemainsTableConfigActions
} from '@/modules/disposals/store/disposal-card/remains/table';
import { disposalCardRemainsApi } from '@/modules/disposals/store/disposal-card/remains/table/async-thunks';
import { disposalCardRemainsSelectors } from '@/modules/disposals/store/disposal-card/remains/table/selectors';
import { BackButton } from '@/modules/portal/components/back-button/BackButton';
import { getDocumentCardRoutePathByDocType } from '@/shared/document/routes/routes';
import { RemainsTable } from '@/shared/remains/components/remains-table/RemainsTable';
import { useRemainsTableDataSource } from '@/shared/remains/hooks/use-remains-table-data-source';
import { useAppDispatch } from '@/store';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './disposal-card-remains-page.scss';

export const DisposalCardRemainsPage = () => {
  const dispatch = useAppDispatch();

  const docType = useSelector(disposalCardSelectors.selectDocType);
  const docUuid = useSelector(disposalCardSelectors.selectDocumentId);

  const backNavigationRoute = useMemo(() => getDocumentCardRoutePathByDocType({ docType, docUuid }), [docUuid, docType]);

  useEffect(() => {
    return () => {
      dispatch(disposalCardRemainsDocumentActions.resetState());
      dispatch(disposalCardRemainsRootActions.resetState());
    };
  }, []);

  const { loadFirstPage, loadNextPage } = useRemainsTableDataSource({
    selectors: disposalCardRemainsSelectors,
    api: disposalCardRemainsApi,
    pageableDataActions: disposalCardRemainsPageableDataActions
  });

  const { addButton } = useRemainsTableDocumentActions({ loadFirstPage });

  const addingDialogRemainsWarnContext = useAddingRemainsWarnDialog({ loadFirstPage });

  return (
    <div className="disposal-card-remains-page-container">
      <BackButton
        defaultRoute={backNavigationRoute}
        className="disposal-card-remains-back-button"
      />
      <RemainsTable
        topToolbar={{
          filter: {
            filterPanel: <DisposalRemainsFilters
              cardSelectors={disposalCardSelectors}
              remainsSelectors={disposalCardRemainsSelectors}
              filterConfigActions={disposalCardRemainsFilterConfigActions}
            />,
            customFilterActions: <DisposalRemainsActions
              addButton={addButton}
            />
          }
        }}
        selectors={disposalCardRemainsSelectors}
        api={disposalCardRemainsApi}
        rootActions={disposalCardRemainsRootActions}
        pageableDataActions={disposalCardRemainsPageableDataActions}
        filterConfigActions={disposalCardRemainsFilterConfigActions}
        searchingActions={disposalCardRemainsSearchingActions}
        tableConfigActions={disposalCardRemainsTableConfigActions}
        loadFirstPage={loadFirstPage}
        loadNextPage={loadNextPage}
      />
      <AddingRemainsWarnDialog {...addingDialogRemainsWarnContext} />
    </div>
  );
};
