import EditIcon from '@/assets/icons/common/edit.svg?react';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { dateTimeFormat } from '@/common/constants/date-formats';
import { formatDate } from '@/common/utils/common/date-utils';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import React, { FC, memo } from 'react';
import './document-card-note-item-base.scss';

interface DocumentCardNoteItemBaseProps {
  note: DocumentCardNote;
  actions: React.ReactNode;
  content: React.ReactNode;
  className?: string;
}

const DocumentCardNoteItemBaseInner: FC<DocumentCardNoteItemBaseProps> = ({ note, actions, content, className }) => {
  return (
    <div className={`document-card-note-item-container ${className ?? ''}`}>
      <div className="note-dates">
        {formatDate(note.createdDate, dateTimeFormat)}
        {note.noteTextModifiedDate &&
          <AppTooltip text={`Изменено: ${formatDate(note.noteTextModifiedDate, dateTimeFormat)}`}>
            <div className="note-modify-date-icon">
              <EditIcon />
            </div>
          </AppTooltip>
        }
      </div>
      <div className="note-author">{note.userFullName}</div>
      <div className="note-actions">
        {actions}
      </div>
      <div className="note-content">
        {content}
      </div>
    </div>
  );
};

export const DocumentCardNoteItemBase = memo(DocumentCardNoteItemBaseInner);
