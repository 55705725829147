import { dateFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { formatDate } from '@/common/utils/common/date-utils';
import { formatCurrency } from '@/common/utils/common/formatting-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { FavoriteButton } from '@/modules/favorites/components/favorite-button/FavoriteButton';
import { FavoriteDocumentWithIndicator } from '@/modules/favorites/models/favorite-document-with-indicator';
import { convertFavoritesColumnFieldToDto } from '@/modules/favorites/models/favorites-table-column-converter';
import { FavoritesTableColumns } from '@/modules/favorites/models/favorites-table-columns';
import { getDocumentStatusTitle } from '@/shared/document/models/document-status';
import { getDocumentTypeLabel } from '@/shared/document/models/document-type';
import { getDocumentCellRowClassName } from '@/shared/document/utils/get-document-cell-row-class-name';
import React, { useMemo } from 'react';

export function useFavoritesTableSettings() {

  const searchPlaceholder = getSearchString([Labels.activityPlaceShort, Labels.partner, Labels.docNumber, Labels.comment]);

  const columns = useMemo<AppTableColumnDefType<FavoriteDocumentWithIndicator>[]>(
    () => [
      {
        id: FavoritesTableColumns.Favorite,
        header: '',
        accessorFn: (row) => <FavoriteButton favoriteDocument={row} />,
        enableColumnDragging: false,
        enableSorting: false,
        enableResizing: false,
        showInSettings: false,
        enableEditing: false,
        fixedWidth: true,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: FavoritesTableColumns.ActivityPlace,
        header: Labels.activityPlaceShort,
        accessorKey: convertFavoritesColumnFieldToDto(FavoritesTableColumns.ActivityPlace),
        enableEditing: false,
      },
      {
        id: FavoritesTableColumns.Partner,
        header: Labels.partner,
        accessorKey: convertFavoritesColumnFieldToDto(FavoritesTableColumns.Partner),
        enableEditing: false,
      },
      {
        id: FavoritesTableColumns.Type,
        header: Labels.docType,
        accessorFn: (row) => getDocumentTypeLabel(row.docType),
        enableEditing: false,
      },
      {
        id: FavoritesTableColumns.DocNumber,
        header: Labels.docNumber,
        accessorKey: convertFavoritesColumnFieldToDto(FavoritesTableColumns.DocNumber),
        enableEditing: false,
      },
      {
        id: FavoritesTableColumns.Date,
        header: Labels.docDate,
        accessorFn: (row) => formatDate(row.docDate, dateFormat),
        enableEditing: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: FavoritesTableColumns.PriceWithVat,
        header: Labels.totalPriceWithVat,
        accessorFn: (row) => formatCurrency(row.totalPriceWithVat),
        enableEditing: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: FavoritesTableColumns.Status,
        header: Labels.status,
        accessorFn: (row) => getDocumentStatusTitle(row.docState),
        enableEditing: false,
      },
      {
        id: FavoritesTableColumns.Comment,
        header: Labels.comment,
        accessorKey: convertFavoritesColumnFieldToDto(FavoritesTableColumns.Comment),
        enableSorting: false,
      },
    ]
    , []);

  const tableClasses = useMemo(() => ({
    cell: getDocumentCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses,
    searchPlaceholder
  };
}
