import { AppList } from '@/common/components/app-list/AppList';
import { specialCharactersStr } from '@/common/utils/client-validation/validation-rules';
import React from 'react';
import './password-validation-hint.scss';

export const PasswordValidationHint = () => (
  <div className="password-validation-hint">
    <div className="password-validation-hint-title">Пароль должен содержать:</div>
    <AppList
      items={['От 8 до 16 символов', 'Заглавные латинские буквы', 'Строчные латинские буквы', `Символы ${specialCharactersStr}`, 'Цифры']}
      classNames={{ list: 'password-validation-hint-list' }}
    />
    <div className="password-validation-hint-title">Пример пароля: <span className="text-bold">P@ssw0rd</span></div>
  </div>
);
