import { AppPopover } from '@/common/components/app-popover/AppPopover';
import { SyncIcon } from '@/common/components/icons/sync-icon/SyncIcon';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { LoaderWrapperWithRejectContent } from '@/modules/portal/components/loader-wrappers/loader-wrapper-with-reject-content/LoaderWrapperWithRejectContent';
import { UpdateSyncBody } from '@/modules/portal/components/update-sync/components/update-sync-body/UpdateSyncBody';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { SyncDataIsAbleToLoading } from '@/modules/portal/models/sync/sync-data';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { setOpenUpdateSyncDialog } from '@/modules/portal/store/sync';
import { getSyncInfo, startSync } from '@/modules/portal/store/sync/async-thunks';
import { SyncSelectors } from '@/modules/portal/store/sync/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import './update-sync.scss';

export const UpdateSync = () => {

  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { hasAccessByPermission } = useUserPermissionsAccess();

  const openUpdateSyncDialog = useSelector(SyncSelectors.selectOpenUpdateSyncDialog);
  const syncData = useSelector(SyncSelectors.selectSyncData);
  const syncDataInProgress = useSelector(SyncSelectors.selectSyncDataInProgress);
  const updateSyncDialogLoadingStatus = useSelector(SyncSelectors.selectUpdateSyncDialogLoadingStatus);

  const intervalIdRef = useRef<NodeJS.Timeout>();

  const onSetOpenUpdateSyncDialog = useCallback((value: boolean) => {
    if (value) {
      asyncDispatch(() => getSyncInfo(true));
      intervalIdRef.current = setInterval(() => asyncDispatch(getSyncInfo), 3000);
    } else {
      clearInterval(intervalIdRef.current);
    }
    dispatch(setOpenUpdateSyncDialog(value));
  }, [intervalIdRef.current]);

  const onStartSync = useCallback((type: SyncDataIsAbleToLoading) => {
    asyncDispatch(() => startSync(type));
  }, []);

  useEffect(() => {
    return () => { clearInterval(intervalIdRef.current); };
  }, []);

  const canUserStartsAllSyncs = useMemo(() => hasAccessByPermission(UserPermission.StartAllSyncs), []);

  return (
    <AppPopover
      open={openUpdateSyncDialog}
      className="update-sync"
      onChangeOpen={onSetOpenUpdateSyncDialog}
      buttonElement={
        <div className="update-sync-button">
          <SyncIcon /> Синхронизировать
        </div>
      }
      contentElement={
        <LoaderWrapperWithRejectContent
          loadingStatus={updateSyncDialogLoadingStatus}
          classNames={{
            loaderMainWrapper: 'update-sync-main-wrapper',
            rejectContent: {
              wrapper: 'update-sync-reject-content-wrapper',
              button: 'update-sync-reject-content-button'
            },
            loaderWrapper: 'update-sync-loader-wrapper'
          }}
          retryAction={getSyncInfo}
        >
          <UpdateSyncBody
            syncData={syncData}
            syncDataInProgress={syncDataInProgress}
            showStartAllSyncs={canUserStartsAllSyncs}
            onStartSync={onStartSync}
          />
        </LoaderWrapperWithRejectContent>
      }
    />
  );
};
