import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { setIsOpenCertificatesListDialog, setRetryOperationEventUuid } from '@/modules/interaction-log/store/interaction-log';
import { downloadInteractionLogFile } from '@/modules/interaction-log/store/interaction-log/async-thunks';
import { useAppDispatch } from '@/store';
import { useState } from 'react';

export interface InteractionLogTableActions {
  isFileLoading: boolean;
  isTicketLoading: boolean;
  downloadFile: (docFileUuid: string) => void;
  downloadTicket: (ticketFileUuid: string) => void;
  retryOperation: (eventUuid: string) => void;
}

export function useInteractionLogTableActions(): InteractionLogTableActions {
  const { asyncDispatch } = useAsyncDispatch();
  const dispatch = useAppDispatch();

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isTicketLoading, setIsTicketLoading] = useState(false);

  const downloadFile = (docFileUuid: string) => {
    setIsFileLoading(true);

    return asyncDispatch(
      () => downloadInteractionLogFile(docFileUuid),
      () => setIsFileLoading(false),
      () => setIsFileLoading(false),
    );
  };

  const downloadTicket = (ticketFileUuid: string) => {
    setIsTicketLoading(true);

    return asyncDispatch(
      () => downloadInteractionLogFile(ticketFileUuid),
      () => setIsTicketLoading(false),
      () => setIsTicketLoading(false),
    );
  };

  const retryOperation = (eventUuid: string) => {
    dispatch(setRetryOperationEventUuid(eventUuid));
    dispatch(setIsOpenCertificatesListDialog(true));
  };

  return {
    isFileLoading,
    isTicketLoading,
    downloadFile,
    downloadTicket,
    retryOperation
  };
}
