import { combineReducers } from '@reduxjs/toolkit';
import receipts from '@/modules/receipts/store/receipts';
import receiptCard from '@/modules/receipts/store/receipt-card/receipt-card-reducer';

/**
 * Base receipts reducer.
 */
const receiptsReducer = combineReducers({
  receipts,
  receiptCard,
});

export default receiptsReducer;
