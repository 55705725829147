import boxes from '@/modules/sale/store/sale-card/boxes';
import card from '@/modules/sale/store/sale-card/card';
import generalPositions from '@/modules/sale/store/sale-card/general-positions';
import history from '@/modules/sale/store/sale-card/history';
import notes from '@/modules/sale/store/sale-card/notes';
import positions from '@/modules/sale/store/sale-card/positions';
import scanning from '@/modules/sale/store/sale-card/scanning';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base sale card reducer.
 */
const saleCardReducer = combineReducers({
  card,
  generalPositions,
  boxes,
  positions,
  history,
  notes,
  scanning,
});

export default saleCardReducer;
