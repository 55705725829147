import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { useFavoritesTableDataSource } from '@/modules/favorites/hooks/use-favorites-table-data-source';
import { useFavoritesTableEventHandlers } from '@/modules/favorites/hooks/use-favorites-table-event-handlers';
import { useFavoritesTableSettings } from '@/modules/favorites/hooks/use-favorites-table-settings';
import { FavoritesSelectors } from '@/modules/favorites/store/selectors';
import '@/shared/document/styles/documents-table-styles.scss';
import React from 'react';
import { useSelector } from 'react-redux';

export const FavoritesTable = () => {

  const data = useSelector(FavoritesSelectors.selectData);
  const isLoading = useSelector(FavoritesSelectors.selectIsLoading);
  const tableConfig = useSelector(FavoritesSelectors.selectTableConfig);
  const updatedRowId = useSelector(FavoritesSelectors.selectUpdatedRowId);
  const isUpdatingInProcess = useSelector(FavoritesSelectors.selectIsUpdatingInProcess);

  const { loadFirstPage } = useFavoritesTableDataSource();

  const { columns, tableClasses, searchPlaceholder } = useFavoritesTableSettings();

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: FavoritesSelectors.selectSearchState
  });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onEditingRowSave,
    onRowDoubleClick,
    onRefreshClick
  } = useFavoritesTableEventHandlers({ loadFirstPage });

  return (
    <AppCustomTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      configState={tableConfig}
      isUpdatingInProcess={isUpdatingInProcess}
      updatedRowId={updatedRowId}
      classes={tableClasses}
      enableRowVirtualization
      manualSorting
      enableEditing
      editingMode="row"
      topToolbar={{
        search: {
          searchPlaceholder,
          showSearchInput: true,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput,
          onApplySearchClick,
          onClearSearchClick,
        },
        refreshButton: {
          showRefreshButton: true,
          notClickableRefreshButton: isLoading,
          onRefreshClick
        }
      }}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
      onEditingRowSave={onEditingRowSave}
      onRowDoubleClick={onRowDoubleClick}
    />
  );
};
