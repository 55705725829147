import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { downloadFile } from '@/modules/file-operations/store/async-thunks';
import { useState } from 'react';

export interface FileOperationsTableActions {
  isDocumentLoading: boolean;
  isReportLoading: boolean;
  downloadDocument: (fileUuid: string) => void;
  downloadReport: (bugReportUuid: string) => void;
}

export function useFileOperationsTableActions(): FileOperationsTableActions {
  const { asyncDispatch } = useAsyncDispatch();

  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);

  const downloadDocument = (fileUuid: string) => {
    setIsDocumentLoading(true);

    return asyncDispatch(
      () => downloadFile(fileUuid),
      () => setIsDocumentLoading(false),
      () => setIsDocumentLoading(false),
    );
  };

  const downloadReport = (bugReportUuid: string) => {
    setIsReportLoading(true);

    return asyncDispatch(
      () => downloadFile(bugReportUuid),
      () => setIsReportLoading(false),
      () => setIsReportLoading(false),
    );
  };

  return {
    isDocumentLoading,
    isReportLoading,
    downloadDocument,
    downloadReport,
  };
}
