import { AsyncThunkConfig, ThunkApi } from '@/store';
import { AsyncThunkOptions, createAsyncThunk } from '@reduxjs/toolkit';

type ActionCreator<TReturn, TArgs> = (
  args: TArgs,
  thunkAPI: ThunkApi
) => Promise<TReturn>;
/**
 * - createAsyncThunk's wrapper, that calls .catch() with rejectWithValue() for every request
 * @param actionType Request id
 * @param action Async func that returns a request. If you need to call `.catch()` in addition to `.then()`, then you must add `return rejectWithValue(err.response.data)` at the end and set the type `AxiosError<ErrorModel>` for `err`. And add TReturn `| RejectWithValue` in the end of type
 * @param options Async thunk options
 * @returns Return the result of createAsyncThunk, which you can processing  as _extraReducer_ at the store and call it at the component
 */
export const createAsyncThunkWrapper = <TReturn, TArgs = undefined>(
  actionType: string,
  action: ActionCreator<TReturn, TArgs>,
  options?: AsyncThunkOptions<TArgs>,
) => {

  return createAsyncThunk<
    TReturn,
    TArgs,
    AsyncThunkConfig
  >(
    actionType,
    (args, thunkApi) => {
      return action(args, thunkApi)
        .catch((err) => {
          if (!err?.response) {
            console.error(err);
          }

          return thunkApi.rejectWithValue(err.response.data);
        });
    },
    options,
  );
};
