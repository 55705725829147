import { DocumentCardHistoryState } from '@/shared/document/store/document-card/history/index';
import { RootState } from '@/store';

interface DocumentCardHistorySelectorsParams {
  selectDocumentCardHistoryState: (state: RootState) => DocumentCardHistoryState;
}

export const createDocumentCardHistorySelectors = ({ selectDocumentCardHistoryState }: DocumentCardHistorySelectorsParams) => {
  const selectTableConfig = (state: RootState) => selectDocumentCardHistoryState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

  const selectHistory = (state: RootState) => selectDocumentCardHistoryState(state).history;
  const selectIsLoadingHistory = (state: RootState) => selectDocumentCardHistoryState(state).isLoadingHistory;

  return {
    selectTableConfig,
    selectTableSorting,
    selectHistory,
    selectIsLoadingHistory
  };
};
