import { disposalCardRemainsModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { AddRemainsBody } from '@/modules/disposals/models/remains/add-remains-body';
import { DisposalsRequests } from '@/modules/disposals/requests/disposals-requests';
import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import { disposalCardRemainsSelectors } from '@/modules/disposals/store/disposal-card/remains/table/selectors';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const addRemainsToDocument = createAsyncThunkWrapper<
  void,
  Pick<AddRemainsBody, 'isForce'>
>(
  `${disposalCardRemainsModuleName}/addRemainsToDocument`,
  async ({ isForce }, { getState }) => {
    const rowsSelectionIds = disposalCardRemainsSelectors.selectRowsSelectionIds(getState());
    const docUuid = disposalCardSelectors.selectDocumentId(getState());
    const body: AddRemainsBody = {
      docUuid,
      isForce,
      remainingMedUuids: rowsSelectionIds
    };

    await DisposalsRequests.addRemainsToDocument(body);
  });
