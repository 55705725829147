import { DocumentCardBoxesState } from '@/shared/document/store/document-card/boxes/index';
import { RootState } from '@/store';

interface DocumentCardBoxesSelectorsParams {
  selectDocumentCardBoxesState: (state: RootState) => DocumentCardBoxesState;
}

export const createDocumentCardBoxesSelectors = ({ selectDocumentCardBoxesState }: DocumentCardBoxesSelectorsParams) => {
  const selectTableConfig = (state: RootState) => selectDocumentCardBoxesState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

  const selectBoxes = (state: RootState) => selectDocumentCardBoxesState(state).boxes;
  const selectIsLoadingBoxes = (state: RootState) => selectDocumentCardBoxesState(state).isLoadingBoxes;

  return {
    selectTableConfig,
    selectTableSorting,
    selectBoxes,
    selectIsLoadingBoxes
  };
};
