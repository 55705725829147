import { LoadingStatus } from '@/common/models/loading-status';
import { LoaderWrapperWithRejectContent } from '@/modules/portal/components/loader-wrappers/loader-wrapper-with-reject-content/LoaderWrapperWithRejectContent';
import { AsyncAction } from '@/store';
import React, { FC, ReactNode } from 'react';
import './loader-wrapper-page.scss';

interface LoaderWrapperPageProps {
  children: ReactNode;
  loadingStatus: LoadingStatus;
  retryAction: AsyncAction;
}
export const LoaderWrapperPage: FC<LoaderWrapperPageProps> = ({ loadingStatus, children, retryAction }) => {
  return (
    <LoaderWrapperWithRejectContent
      loadingStatus={loadingStatus}
      retryAction={retryAction}
      classNames={{
        rejectContent: { wrapper: 'loader-wrapper-page reject-content-wrapper' },
        loaderWrapper: 'loader-wrapper-page loader-wrapper'
      }}
    >
      {children}
    </LoaderWrapperWithRejectContent>
  );
};
