import { AppTableOpenButton } from '@/common/components/app-table/components/app-table-open-button/AppTableOpenButton';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { codeMaxLength, commentMaxLength, legalEntityShortNameMaxLength } from '@/common/utils/client-validation/string-lengths';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { LegalEntitiesTableColumns } from '@/modules/references/models/legal-entities/legal-entities-table-columns';
import { convertLegalEntityColumnFieldToDto } from '@/modules/references/models/legal-entities/legal-entities-table-columns-converters';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';
import { setIsDialogOpened, updateCurrentLegalEntity } from '@/modules/references/store/legal-entities-activity-places';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export function useLegalEntitiesTableSettings() {
  const dispatch = useDispatch();

  const searchPlaceholder = getSearchString([Labels.code, Labels.fullName, Labels.shortName, Labels.comment]);

  const onOpenActivityPlacesClick = useCallback((entity: LegalEntity) => {
    dispatch(updateCurrentLegalEntity(entity));
    dispatch(setIsDialogOpened(true));
  }, []);

  const columns = useMemo<AppTableColumnDefType<LegalEntity>[]>(
    () => [
      {
        id: LegalEntitiesTableColumns.Code,
        header: Labels.code,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.Code),
        enableEditing: true,
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          inputProps: {
            maxLength: codeMaxLength,
          },
        },
      },
      {
        id: LegalEntitiesTableColumns.FullName,
        header: Labels.fullName,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.FullName),
        enableEditing: false,
      },
      {
        id: LegalEntitiesTableColumns.ShortName,
        header: Labels.shortName,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.ShortName),
        enableEditing: true,
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          inputProps: {
            maxLength: legalEntityShortNameMaxLength,
          },
        },
      },
      {
        id: LegalEntitiesTableColumns.Inn,
        header: Labels.inn,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.Inn),
        enableEditing: false,
      },
      {
        id: LegalEntitiesTableColumns.Kpp,
        header: Labels.kpp,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.Kpp),
        enableEditing: false,
      },
      {
        id: LegalEntitiesTableColumns.RegNumber,
        header: Labels.regNumber,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.RegNumber),
        enableEditing: false,
      },
      {
        id: LegalEntitiesTableColumns.ActivityPlaces,
        header: Labels.activityPlaceShort,
        accessorFn: (row) => <AppTableOpenButton
          text="Открыть"
          onClick={() => onOpenActivityPlacesClick(row)}
        />,
        enableEditing: false,
        enableSorting: false,
      },
      {
        id: LegalEntitiesTableColumns.Comment,
        header: Labels.comment,
        accessorKey: convertLegalEntityColumnFieldToDto(LegalEntitiesTableColumns.Comment),
        enableEditing: true,
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          inputProps: {
            maxLength: commentMaxLength,
          },
        },
      },
    ],
    [],
  );

  return {
    columns,
    searchPlaceholder,
  };
}
