import { saleCardNotesModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardNotesApi } from '@/modules/sale/store/sale-card/notes/async-thunks';
import { createDocumentCardNotesSlice } from '@/shared/document/store/document-card/notes';

export const saleCardNotesSlice = createDocumentCardNotesSlice({
  moduleName: saleCardNotesModuleName,
  api: saleCardNotesApi,
});

export const saleCardNotesRootActions = saleCardNotesSlice.rootSlice.actions;
export const saleCardNotesPageableDataActions = saleCardNotesSlice.documentNotesPageableDataSlice.actions;

export default saleCardNotesSlice.rootSlice.reducer;
