import documentActions from '@/modules/disposals/store/disposal-card/remains/document-actions';
import table from '@/modules/disposals/store/disposal-card/remains/table';
import { combineReducers } from '@reduxjs/toolkit';

const disposalCardRemainsReducer = combineReducers({
  table,
  documentActions,
});

export default disposalCardRemainsReducer;
