import { receiptCardPositionsModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardPositionsApi } from '@/modules/receipts/store/receipt-card/positions/async-thunks';
import { createDocumentCardPositionsSlice } from '@/shared/document/store/document-card/positions';

export const receiptCardPositionsSlice = createDocumentCardPositionsSlice({
  moduleName: receiptCardPositionsModuleName,
  savedTableConfigName: 'receipt-card-positions-table',
  api: receiptCardPositionsApi,
});

export const receiptCardPositionsRootActions = receiptCardPositionsSlice.rootSlice.actions;
export const receiptCardPositionsTableConfigActions = receiptCardPositionsSlice.documentCardPositionsTableConfigSlice.actions;

export default receiptCardPositionsSlice.rootSlice.reducer;
