import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { fileOperationsModuleName } from '@/modules/file-operations/constants/file-operations-module-names';
import { defaultFileOperationsTableConfig } from '@/modules/file-operations/constants/file-operations-table-config';
import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { FileOperationsFilters } from '@/modules/file-operations/models/file-operations-filters';
import { getAllFileOperations } from '@/modules/file-operations/store/async-thunks';
import { createSlice } from '@reduxjs/toolkit';

interface FileOperationsState {
  search: SearchingState;
  filterConfig: FilterConfigState<FileOperationsFilters>;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<FileOperation>;
}

const notClearedFields: Set<keyof FileOperationsState> = new Set(['tableConfig']);

const fileOperationsTableConfigState: SavedTableConfigState = {
  tableConfigName: 'file-operations-table',
  config: defaultFileOperationsTableConfig,
};

const fileOperationsSearchingSlice = createSearchingSlice(fileOperationsModuleName);
const fileOperationsTableConfigSlice = createSavedTableConfigSlice(fileOperationsModuleName, fileOperationsTableConfigState);
const fileOperationsPageableDataSlice = createPageableDataSlice<FileOperation>(fileOperationsModuleName);
const fileOperationsFilterConfigSlice = createFilterConfigSlice<FileOperationsFilters>(fileOperationsModuleName);

const initialState: FileOperationsState = {
  search: fileOperationsSearchingSlice.getInitialState(),
  filterConfig: fileOperationsFilterConfigSlice.getInitialState(),
  tableConfig: fileOperationsTableConfigSlice.getInitialState(),
  pageableData: fileOperationsPageableDataSlice.getInitialState(),
};

const fileOperationsSlice = createSlice({
  name: fileOperationsModuleName,
  initialState,
  reducers: {
    resetState(state: FileOperationsState) {
      Object.keys(state)
        .forEach((key: keyof FileOperationsState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFileOperations.pending, (state) => {
        const { updateLoadingStatus } = fileOperationsPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllFileOperations.fulfilled, (state) => {
        const { updateLoadingStatus } = fileOperationsPageableDataSlice.caseReducers;
        const { updateStateAfterGettingData } = fileOperationsFilterConfigSlice.caseReducers;
        const { saveAppliedSearchString } = fileOperationsSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        updateStateAfterGettingData(state.filterConfig);
        saveAppliedSearchString(state.search);
      })
      .addCase(getAllFileOperations.rejected, (state) => {
        const { updateLoadingStatus } = fileOperationsPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addMatcher(
        (action) => action.type.startsWith(fileOperationsSearchingSlice.name),
        (state, action) => {
          state.search = fileOperationsSearchingSlice.reducer(state.search, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(fileOperationsTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = fileOperationsTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(fileOperationsPageableDataSlice.name),
        (state, action) => {
          state.pageableData = fileOperationsPageableDataSlice.reducer(state.pageableData, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(fileOperationsFilterConfigSlice.name),
        (state, action) => {
          state.filterConfig = fileOperationsFilterConfigSlice.reducer(state.filterConfig, action);
        }
      );
  },
});

export const {
  resetState,
} = fileOperationsSlice.actions;

export const {
  updateSearchString,
} = fileOperationsSearchingSlice.actions;

export const {
  updateFilter,
  changeFilterOpenState
} = fileOperationsFilterConfigSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = fileOperationsTableConfigSlice.actions;

export const fileOperationsPageableDataActions = fileOperationsPageableDataSlice.actions;
export const fileOperationsFilterConfigActions = fileOperationsFilterConfigSlice.actions;

export default fileOperationsSlice.reducer;
