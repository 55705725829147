import { axios } from '@/common/api/axios-config';
import { logoutUrl, passwordChangeUrl, userInfoUrl } from '@/common/api/urls';
import { PasswordChangeBody } from '@/modules/portal/models/password-change/password-change-body';
import { UserProfileInfo } from '@/modules/portal/models/user-profile-info';
import { AxiosResponse } from 'axios';

export class UserProfileRequests {

  static userInfo(): Promise<AxiosResponse<UserProfileInfo>> {
    return axios.get(userInfoUrl);
  }

  static logout(): Promise<AxiosResponse<void>> {
    return axios.get(logoutUrl);
  }

  static passwordChange(body: PasswordChangeBody): Promise<AxiosResponse<void>> {
    return axios.post(passwordChangeUrl, body);
  }
}
