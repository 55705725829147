import { HeaderWithLoader } from '@/common/components/custom/header-with-loader/HeaderWithLoader';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { AddingRemainsWarnDialogProps } from '@/modules/disposals/components/remains/adding-remains-warn-dialog/AddingRemainsWarnDialog';
import { useAddingRemainsSuccessfulNotification } from '@/modules/disposals/hooks/remains/use-adding-remains-successful-notification';
import { disposalCardRemainsDocumentActions } from '@/modules/disposals/store/disposal-card/remains/document-actions';
import { addRemainsToDocument } from '@/modules/disposals/store/disposal-card/remains/document-actions/async-thunks';
import { DisposalCardRemainsDocumentActionsSelectors } from '@/modules/disposals/store/disposal-card/remains/document-actions/selectors';
import { disposalCardRemainsRootActions } from '@/modules/disposals/store/disposal-card/remains/table';
import { useAppDispatch } from '@/store';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

type AddingRemainsWarnHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useAddingRemainsWarnDialog({ loadFirstPage }: AddingRemainsWarnHookParams): AddingRemainsWarnDialogProps {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { showSuccessfulNotification } = useAddingRemainsSuccessfulNotification();

  const isDialogOpened = useSelector(DisposalCardRemainsDocumentActionsSelectors.selectIsOpenedAddingRemainsSuccessfulDialog);
  const warnInfo = useSelector(DisposalCardRemainsDocumentActionsSelectors.selectAddingRemainsWarnInfo);

  const isAddingRemainsInProcess = useSelector(DisposalCardRemainsDocumentActionsSelectors.selectIsAddingInProgress);

  const header = <HeaderWithLoader
    title="Добавление товаров из остатков"
    showLoader={isAddingRemainsInProcess}
  />;

  const subHeader = <div className="adding-remains-blocked-info-dialog-sub-header">
    Среди товаров, которые были выбраны для добавления в документ, содержатся вторичные упаковки в составе третичной упаковки. Чтобы добавить данные товары — разагрегируйте третичные упаковки в МДЛП или нажмите «Продолжить», чтобы добавить третичные упаковки в документ.
  </div>;

  const closeDialog = useCallback(() => {
    dispatch(disposalCardRemainsDocumentActions.setIsOpenedAddingRemainsSuccessfulDialog(false));
  }, []);

  const continueAdding = useCallback(() => {
    const thenCb = () => {
      showSuccessfulNotification();
      dispatch(disposalCardRemainsRootActions.setRowSelection({}));
      loadFirstPage();
    };

    asyncDispatch(
      () => addRemainsToDocument({ isForce: true }),
      thenCb
    );
  }, [showSuccessfulNotification]);

  return {
    isDialogOpened,
    warnInfo,
    header,
    subHeader,
    closeDialog,
    continueAdding
  };
}
