import ProfileIcon from '@/assets/icons/profile.svg?react';
import { AppPopover } from '@/common/components/app-popover/AppPopover';
import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { LoaderWrapperWithRejectContent } from '@/modules/portal/components/loader-wrappers/loader-wrapper-with-reject-content/LoaderWrapperWithRejectContent';
import { ChangePasswordDialog } from '@/modules/portal/components/user-profile/components/change-password-dialog/ChangePasswordDialog';
import { UserProfileBody } from '@/modules/portal/components/user-profile/components/user-profile-body/UserProfileBody';
import { UserProfileLogoutButton } from '@/modules/portal/components/user-profile/components/user-profile-logout-button/UserProfileLogoutButton';
import { PasswordChangeBody } from '@/modules/portal/models/password-change/password-change-body';
import { resetState, setChangePasswordDialogOpen } from '@/modules/portal/store/user-profile';
import { getUserProfileInfo, passwordChange } from '@/modules/portal/store/user-profile/async-thunks';
import { UserProfileSelectors } from '@/modules/portal/store/user-profile/selectors';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './user-profile.scss';

export const UserProfile = () => {
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const { asyncDispatch } = useAsyncDispatch();

  const [open, setOpen] = useState(false);

  const profileData = useSelector(UserProfileSelectors.selectProfileData);
  const changePasswordDialogOpen = useSelector(UserProfileSelectors.selectChangePasswordDialogOpen);
  const changePasswordDialogIsLoading = useSelector(UserProfileSelectors.selectChangePasswordDialogIsLoading);
  const userProfileLoadingStatus = useSelector(UserProfileSelectors.selectUserProfileLoadingStatus);

  const onChangePasswordDialogClose = useCallback(() => {
    dispatch(setChangePasswordDialogOpen(false));
  }, []);

  const onChangePasswordDialogOpen = useCallback(() => {
    dispatch(setChangePasswordDialogOpen(true));
  }, []);

  const onPasswordChange = useCallback((body: PasswordChangeBody) => {
    const thenCb = () => {
      toast('Пароль успешно изменен');
      onChangePasswordDialogClose();
    };

    asyncDispatch(
      () => passwordChange(body),
      thenCb,
    );
  }, []);

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  return (
    <>
      <AppPopover
        open={open}
        className="user-profile"
        onChangeOpen={setOpen}
        buttonElement={
          <div className="user-profile-name">
            <ProfileIcon className="profile-icon" />
            <>{profileData?.lastName?.value} {profileData?.firstName?.value}</>
          </div>
        }
        contentElement={
          <LoaderWrapperWithRejectContent
            loadingStatus={userProfileLoadingStatus}
            classNames={{ loaderMainWrapper: 'user-profile-main-wrapper' }}
            rejectBottomContent={<UserProfileLogoutButton className="user-profile-reject-content-logout-button" />}
            retryAction={getUserProfileInfo}
          >
            <UserProfileBody onChangePasswordDialogOpen={onChangePasswordDialogOpen} />
          </LoaderWrapperWithRejectContent>}
      />
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        isLoading={changePasswordDialogIsLoading}
        onPasswordChange={onPasswordChange}
        onClose={onChangePasswordDialogClose}
      />
    </>
  );
};
