import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import React, { FC, ReactNode, memo } from 'react';
import './app-table-column-header-with-hint.scss';

interface AppTableColumnHeaderWithHintProps {
  header: string;
  hint: ReactNode;
}

const AppTableColumnHeaderWithHintInner: FC<AppTableColumnHeaderWithHintProps> = ({ header, hint }) => (
  <div className="app-table-column-header-with-hint-container">
    <span title={header}>{header}</span>
    <HintIcon
      className="app-table-column-header-hint-icon"
      text={hint}
    />
  </div>
);

export const AppTableColumnHeaderWithHint = memo(AppTableColumnHeaderWithHintInner);
