import { useMemo, useRef } from 'react';

export function useDocumentCardInfoHeight() {
  const documentCardDefaultHeight = 445;

  const documentInfoRef = useRef<HTMLDivElement>();

  const documentInfoMaxHeight = useMemo(() => documentInfoRef.current?.clientHeight ?? documentCardDefaultHeight, [documentInfoRef.current]);

  return {
    documentInfoRef,
    documentCardDefaultHeight,
    documentInfoMaxHeight,
  };
}
