import DeleteIcon from '@/assets/icons/common/delete.svg?react';
import EditIcon from '@/assets/icons/common/edit.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { DocumentCardNoteItemBase } from '@/shared/document/components/document-card-notes/components/document-card-note-item-base/DocumentCardNoteItemBase';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import { EditNoteParams } from '@/shared/document/models/document-card/notes/edit-note-params';
import React, { FC, memo, useCallback } from 'react';
import './document-card-note-item-view.scss';

export interface DocumentCardNoteItemViewProps {
  note: DocumentCardNote;
  showActions: boolean;
  isDeletingInProgress: boolean;
  notClickableActions?: boolean;
  onEditClick: (params: EditNoteParams) => void;
  onDeleteClick: (documentNoteUuid: string) => void;
}

const DocumentCardNoteItemViewInner: FC<DocumentCardNoteItemViewProps> = ({ note, showActions, isDeletingInProgress, notClickableActions, onEditClick, onDeleteClick }) => {

  const onEditClickInner = useCallback(() => {
    const { documentNoteUuid, noteText } = note;
    onEditClick({ documentNoteUuid, noteText });
  }, []);

  const onDeleteClickInner = useCallback(() => {
    onDeleteClick(note.documentNoteUuid);
  }, []);

  return (
    <DocumentCardNoteItemBase
      note={note}
      actions={
        showActions &&
        <>
          <AppIconButton
            icon={<EditIcon />}
            initialSize
            className="note-icon"
            activeColor="primary"
            tooltip="Редактировать"
            notClickable={notClickableActions}
            onClick={onEditClickInner}
          />
          <AppIconButton
            icon={<DeleteIcon className="delete-icon" />}
            initialSize
            className="note-icon"
            activeColor="primary"
            tooltip="Удалить"
            isLoading={isDeletingInProgress}
            notClickable={notClickableActions}
            onClick={onDeleteClickInner}
          />
        </>
      }
      content={note.noteText}
    />
  );
};

export const DocumentCardNoteItemView = memo(DocumentCardNoteItemViewInner);
