import { AppInput } from '@/common/components/app-input/AppInput';
import { AppNumberInput } from '@/common/components/app-number-input/AppNumberInput';
import { AppSelect } from '@/common/components/app-select/AppSelect';
import { Labels } from '@/common/constants/labels';
import { logicalSelectOptions } from '@/common/models/app-select/select-options';
import { countryMaxLength, gtinMaxLength, nonProprietaryNameMaxLength, productProducerMaxLength } from '@/common/utils/client-validation/string-lengths';
import { updateFilter } from '@/modules/references/store/mdlp-products';
import { MdlpProductsSelectors } from '@/modules/references/store/mdlp-products/selectors';
import { useAppDispatch } from '@/store';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './mdlp-products-filters.scss';

const MdlpProductsFiltersInner = () => {
  const dispatch = useAppDispatch();

  const producer = useSelector(MdlpProductsSelectors.selectFilterProducer);
  const country = useSelector(MdlpProductsSelectors.selectFilterCountry);
  const nonProprietaryName = useSelector(MdlpProductsSelectors.selectFilterNonProprietaryName);
  const vital = useSelector(MdlpProductsSelectors.selectFilterVital);
  const gtin = useSelector(MdlpProductsSelectors.selectFilterGtin);

  const updateProducer = useCallback((value: string) => dispatch(updateFilter({ producer: value })), []);
  const updateCountry = useCallback((value: string) => dispatch(updateFilter({ country: value })), []);
  const updateNonProprietaryName = useCallback((value: string) => dispatch(updateFilter({ nonProprietaryName: value })), []);
  const updateVital = useCallback((value: string) => dispatch(updateFilter({ vital: value })), []);
  const updateGtin = useCallback((value: string) => dispatch(updateFilter({ gtin: value })), []);

  return (
    <div className="mdlp-products-filters">
      <AppInput
        value={producer}
        label={Labels.producer}
        maxLength={productProducerMaxLength}
        showClearButton
        onInput={updateProducer}
      />
      <AppInput
        value={country}
        label={Labels.producerCountry}
        maxLength={countryMaxLength}
        showClearButton
        onInput={updateCountry}
      />
      <AppInput
        value={nonProprietaryName}
        label={Labels.nonProprietaryName}
        maxLength={nonProprietaryNameMaxLength}
        showClearButton
        onInput={updateNonProprietaryName}
      />
      <AppSelect
        value={vital}
        label={Labels.vital}
        options={logicalSelectOptions}
        showClearButton
        onChange={updateVital}
      />
      <AppNumberInput
        value={gtin}
        label={Labels.gtin}
        maxLength={gtinMaxLength}
        showClearButton
        onInput={updateGtin}
      />
    </div>
  );
};

export const MdlpProductsFilters = memo(MdlpProductsFiltersInner);
