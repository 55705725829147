import { disposalCardHistoryModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardHistoryApi } from '@/modules/disposals/store/disposal-card/history/async-thunks';
import { createDocumentCardHistorySlice } from '@/shared/document/store/document-card/history';

export const disposalCardHistorySlice = createDocumentCardHistorySlice({
  moduleName: disposalCardHistoryModuleName,
  savedTableConfigName: 'disposal-card-history-table',
  api: disposalCardHistoryApi,
});

export const disposalCardHistoryRootActions = disposalCardHistorySlice.rootSlice.actions;
export const disposalCardHistoryTableConfigActions = disposalCardHistorySlice.documentCardHistoryTableConfigSlice.actions;

export default disposalCardHistorySlice.rootSlice.reducer;
