import { convertDocumentFromDtoToModel } from '@/shared/document/models/document';
import { DocumentCardState } from '@/shared/document/store/document-card/card/index';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

interface DocumentCardSelectorsParams {
  selectDocumentCardState: (state: RootState) => DocumentCardState;
}
// TODO: fix type
export const createDocumentCardSelectors = ({ selectDocumentCardState }: DocumentCardSelectorsParams) => {
  const selectDocumentId = (state: RootState) => selectDocumentCardState(state).documentId;
  const selectOpenDocumentInfo = (state: RootState) => selectDocumentCardState(state).openDocumentInfo;
  const selectDocumentInfoDto = (state: RootState) => selectDocumentCardState(state).documentInfo;
  const selectDocType = (state: RootState) => selectDocumentCardState(state).docType;
  const selectDocumentInfo = createSelector([selectDocumentInfoDto], (documentInfoDto) => convertDocumentFromDtoToModel(documentInfoDto));
  const selectLoadingStatusDocumentInfo = (state: RootState) => selectDocumentCardState(state).loadingStatusDocumentInfo;
  const selectActiveTab = (state: RootState) => selectDocumentCardState(state).activeTab;
  const selectTabContentHeight = (state: RootState) => selectDocumentCardState(state).tabContentHeight;
  const selectIsUpdatingStatusInProgress = (state: RootState) => selectDocumentCardState(state).isUpdatingStatusInProgress;

  const selectProcessedDialog = (state: RootState) => selectDocumentCardState(state).processedDialog;
  const selectIsProcessedDialogOpened = (state: RootState) => selectProcessedDialog(state).isDialogOpened;
  const selectProcessedRejectReason = (state: RootState) => selectProcessedDialog(state).rejectReason;
  const selectProcessedDialogSendType = (state: RootState) => selectProcessedDialog(state).sendType;

  const selectBlockedInfoState = (state: RootState) => selectDocumentCardState(state).blockedInfo;
  const selectIsBlockedInfoDialogOpened = (state: RootState) => selectBlockedInfoState(state).isDialogOpened;
  const selectBlockedInfo = (state: RootState) => selectBlockedInfoState(state).info;
  const selectBlockedStatus = (state: RootState) => selectBlockedInfoState(state).status;

  const selectWarnInfoState = (state: RootState) => selectDocumentCardState(state).warnInfo;
  const selectIsWarnInfoDialogOpened = (state: RootState) => selectWarnInfoState(state).isDialogOpened;
  const selectNotScannedMedicineAmount = (state: RootState) => selectWarnInfoState(state).notScannedMedicineAmount;

  return {
    selectDocumentId,
    selectOpenDocumentInfo,
    selectDocumentInfo,
    selectDocType,
    selectLoadingStatusDocumentInfo,
    selectActiveTab,
    selectTabContentHeight,
    selectIsUpdatingStatusInProgress,
    selectIsBlockedInfoDialogOpened,
    selectBlockedInfo,
    selectBlockedStatus,
    selectIsProcessedDialogOpened,
    selectProcessedRejectReason,
    selectProcessedDialogSendType,
    selectIsWarnInfoDialogOpened,
    selectNotScannedMedicineAmount
  };
};
