import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { FilterConfigActions } from '@/common/models/filter-config/filter-config-actions';
import { PageableDataActions } from '@/common/models/pageable-data/pageable-data-actions';
import { SavedTableConfigActions } from '@/common/models/saved-table-config/saved-table-config-actions';
import { SearchingActions } from '@/common/models/searching/searching-actions';
import { DocumentsFilter } from '@/shared/document/components/documents-filters/DocumentsFilters';
import { DocumentsTableBottomToolbar } from '@/shared/document/components/documents-table-bottom-toolbar/DocumentsTableBottomToolbar';
import { useDocumentsFiltersValidation } from '@/shared/document/hooks/documents/use-documents-filters-validation';
import { useDocumentsTableDataSource } from '@/shared/document/hooks/documents/use-documents-table-data-source';
import { useDocumentsTableEventHandlers } from '@/shared/document/hooks/documents/use-documents-table-event-handlers';
import { useDocumentsTableFiltering } from '@/shared/document/hooks/documents/use-documents-table-filtering';
import { useDocumentsTableSettings } from '@/shared/document/hooks/documents/use-documents-table-settings';
import { DocumentsFilter as DocumentsFilterModel } from '@/shared/document/models/documents/documents-filter';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import { DocumentsTableActions } from '@/shared/document/store/documents/table-slice';
import { DocumentsApi, DocumentSelectors } from '@/shared/document/store/documents/types';
import '@/shared/document/styles/documents-table-styles.scss';
import { useFileExport } from '@/shared/files/hooks/use-file-export';
import { useFileImport } from '@/shared/files/hooks/use-file-import';
import { useSuccessfulExportNotification } from '@/shared/files/hooks/use-successful-export-notification';
import { useSuccessfulImportNotification } from '@/shared/files/hooks/use-successful-import-notification';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

const MAX_SELECTED_ROWS_COUNT = 5;

export interface DocumentsTableProps {
  documentsSelectors: DocumentSelectors;
  documentsApi: DocumentsApi;
  documentsTableActions: DocumentsTableActions;
  documentsPageableDataActions: PageableDataActions<ShortDocumentInfo>;
  documentsFilterConfigActions: FilterConfigActions<DocumentsFilterModel>;
  documentsSearchingActions: SearchingActions;
  documentsTableConfigActions: SavedTableConfigActions;
}

export const DocumentsTable: FC<DocumentsTableProps> = ({
  documentsSelectors,
  documentsApi,
  documentsTableActions,
  documentsPageableDataActions,
  documentsFilterConfigActions,
  documentsSearchingActions,
  documentsTableConfigActions,
}) => {
  const data = useSelector(documentsSelectors.selectData);
  const totalItems = useSelector(documentsSelectors.selectTotalItems);
  const isLoadingData = useSelector(documentsSelectors.selectIsLoadingData);
  const isUpdatingInProcess = useSelector(documentsSelectors.selectIsUpdatingInProcess);
  const isExportInProcess = useSelector(documentsSelectors.selectIsExportInProcess);
  const isUploadingInProcess = useSelector(documentsSelectors.selectIsUploadingInProcess);
  const updatedRowId = useSelector(documentsSelectors.selectUpdatedRowId);
  const rowsSelection = useSelector(documentsSelectors.selectRowsSelection);
  const tableConfig = useSelector(documentsSelectors.selectTableConfig);
  const filters = useSelector(documentsSelectors.selectFilters);
  const selectedFiltersCount = useSelector(documentsSelectors.selectSelectedFiltersCount);
  const isPanelOpen = useSelector(documentsSelectors.selectIsPanelOpen);
  const fileType = useSelector(documentsSelectors.selectDocType);
  const visibleColumns = useSelector(documentsSelectors.selectVisibleColumns);

  const { columns, tableClasses, getRowId, searchPlaceholder } = useDocumentsTableSettings({ documentsPageableDataActions, visibleColumns });
  const { loadFirstPage, loadNextPage } = useDocumentsTableDataSource({
    documentsSelectors,
    documentsApi,
    documentsPageableDataActions,
  });

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: documentsSelectors.selectSearchState
  });

  const {
    openImportNotificationDialog,
    successfulImportNotificationDialog
  } = useSuccessfulImportNotification({ fileType });

  const {
    openExportNotificationDialog,
    successfulExportNotificationDialog
  } = useSuccessfulExportNotification();

  const {
    importFileMaxSize,
    acceptedImportTypes,
    onImportFileUploaded,
    onImportFileMaxSizeExceed,
  } = useFileImport({ uploadAction: documentsApi.uploadDocumentFile, openImportNotificationDialog });

  const exportButtonContext = useFileExport({
    rowsSelection,
    isExportInProcess,
    exportAction: documentsApi.exportDocumentFile,
    openExportNotificationDialog,
  });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRowSelectionChange,
    onRowDoubleClick,
    onEditingRowSave,
    onRefreshClick
  } = useDocumentsTableEventHandlers({
    loadFirstPage,
    loadNextPage,
    documentsTableConfigActions,
    documentsSearchingActions,
    documentsTableActions,
    documentsPageableDataActions,
    documentsApi,
    documentsSelectors,
    visibleColumns
  });

  const {
    disableApplyFilterButton: disableApplyFilterButtonInner,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick
  } = useDocumentsTableFiltering({ loadFirstPage, documentsSelectors, documentsFilterConfigActions });

  const {
    validationResult,
    isFormValid,
  } = useDocumentsFiltersValidation(filters);

  const disableApplyFilterButton = disableApplyFilterButtonInner || !isFormValid;

  const enableRowSelection = useCallback((row: AppTableRowType<ShortDocumentInfo>) => {
    if (rowsSelection[row.original.docUuid]) {
      return true;
    }
    const selectedRowsCount = Object.keys(rowsSelection).length;
    return selectedRowsCount < MAX_SELECTED_ROWS_COUNT;
  }, [rowsSelection]);

  return (
    <>
      <AppCustomTable
        data={data}
        columns={columns}
        isLoading={isLoadingData}
        isUpdatingInProcess={isUpdatingInProcess}
        updatedRowId={updatedRowId}
        configState={tableConfig}
        classes={tableClasses}
        enableRowVirtualization
        enableEditing
        manualSorting
        enableRowSelection={enableRowSelection}
        editingMode="row"
        topToolbar={{
          filter: {
            showFilterButton: true,
            filterPanel: <DocumentsFilter
              filter={filters}
              updateFilter={documentsFilterConfigActions.updateFilter}
              validationResult={validationResult}
              visibleColumns={visibleColumns}
            />,
            isPanelOpen,
            selectedFiltersCount,
            disableApplyFilterButton,
            disableClearFilterButton,
            onFilterClick,
            onApplyFiltersClick,
            onClearFiltersClick,
          },
          search: {
            showSearchInput: true,
            searchPlaceholder,
            disableApplySearchButton: isSearchNotChanged,
            onSearchValueInput,
            onApplySearchClick,
            onClearSearchClick,
          },
          importButton: {
            showImportButton: true,
            importButtonDisabled: isUploadingInProcess,
            importFileMaxSize,
            acceptedImportTypes,
            onImportFileUploaded,
            onImportFileMaxSizeExceed,
          },
          refreshButton: {
            showRefreshButton: true,
            notClickableRefreshButton: isLoadingData,
            onRefreshClick
          },
          exportButton: {
            showExportButton: true,
            ...exportButtonContext,
          }
        }}
        bottomToolbar={{
          totalItems,
          content: <DocumentsTableBottomToolbar
            data={data}
            rowsSelection={rowsSelection}
          />
        }}
        getRowId={getRowId}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onSortingChange={onSortingChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnSizingChange={onColumnSizingChange}
        onEditingRowSave={onEditingRowSave}
        onRowDoubleClick={onRowDoubleClick}
        onRowSelectionChange={onRowSelectionChange}
        onScrollAtTheEnd={onScrollChange}
      />
      {successfulImportNotificationDialog}
      {successfulExportNotificationDialog}
    </>
  );
};
