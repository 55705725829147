import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import {
  filterProcesses,
  updateSearchString,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
  updateTableColumnVisibilityState,
  updateTableSortingState,
} from '@/modules/references/store/processes';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

export function useProcessesTableEventHandlers() {
  const dispatch = useAppDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    dispatch(filterProcesses());
  }, []);

  const onClearSearchClick = useCallback(() => {
    dispatch(filterProcesses());
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
  };
}
