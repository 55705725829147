import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { DocumentCardWarnInfoDialogProps } from '@/shared/document/components/document-card-warn-info-dialog/DocumentCardWarnInfoDialog';
import { DocumentCardActionButtonHandlersHookReturnType } from '@/shared/document/hooks/document-card/use-document-action-buttons-handlers';
import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardWarnInfoHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions'> &
  Pick<DocumentCardActionButtonHandlersHookReturnType, 'setDocumentCardStatusAsProcessed'>;

export function useDocumentCardWarnInfo({ documentCardSelectors, documentCardActions, setDocumentCardStatusAsProcessed }: DocumentCardWarnInfoHookParams): DocumentCardWarnInfoDialogProps {
  const dispatch = useAppDispatch();

  const isDialogOpened = useSelector(documentCardSelectors.selectIsWarnInfoDialogOpened);
  const notScannedMedicineAmount = useSelector(documentCardSelectors.selectNotScannedMedicineAmount);
  const isLoading = useSelector(documentCardSelectors.selectIsUpdatingStatusInProgress);

  const header = 'Вы уверены, что хотите завершить обработку?';
  const subHeader = useMemo(() => {
    let commonSubHeader = `Документ содержит ${notScannedMedicineAmount} `;
    switch (notScannedMedicineAmount) {
    case 1:
      commonSubHeader += 'неотсканированный товар';
      break;
    case 2 || 3 || 4:
      commonSubHeader += 'неотсканированных товара';
      break;
    default:
      commonSubHeader += 'неотсканированных товаров';
      break;
    }
    return commonSubHeader;
  }, [notScannedMedicineAmount]);

  const closeDialog = useCallback(() => {
    dispatch(documentCardActions.updateWarnInfo({ isDialogOpened: false }));

    setTimeout(() => {
      dispatch(documentCardActions.updateWarnInfo({ notScannedMedicineAmount: undefined }));
    }, AppDialogTransitionDuration);
  }, []);

  const continueCompletingProcessing = useCallback(() => {
    setDocumentCardStatusAsProcessed();
  }, [setDocumentCardStatusAsProcessed]);

  return {
    isDialogOpened,
    header,
    subHeader,
    isLoading,
    continueCompletingProcessing,
    closeDialog,
  };
}
