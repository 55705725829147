import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import { fullTimeFormat } from '@/common/constants/date-formats';
import { useTimer } from '@/common/hooks/use-timer';
import { getCertificates } from '@/modules/certificates/store/async-thunks';
import { ExtendSessionCertificateListDialog } from '@/modules/portal/components/extend-session/components/extend-session-certificate-list-dialog/ExtendSessionCertificateListDialog';
import { PlusIcon } from '@/modules/portal/components/extend-session/components/plus-icon/PlusIcon';
import { SessionDescription } from '@/modules/portal/components/extend-session/components/session-description/SessionDescription';
import { useExtendSession } from '@/modules/portal/hooks/use-extend-session';
import { useIntervalGettingHeaderInfo } from '@/modules/portal/hooks/use-interval-getting-header-info';
import { PortalHeaderSelectors } from '@/modules/portal/store/header/selectors';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './extend-session.scss';

export const ExtendSession = () => {

  const dispatch = useAppDispatch();

  const tokenExpiresAt = useSelector(PortalHeaderSelectors.selectTokenExpiresAtDate);

  const { isExtendSessionDialogOpened, openExtendSessionDialog, closeExtendSessionDialog, extendSession } = useExtendSession({ initTimer });

  const { timerLabel, isTimerExpired, startTimer } = useTimer({
    expireTime: tokenExpiresAt,
    dateTimeFormat: fullTimeFormat,
    onTimerExpired: openExtendSessionDialog
  });

  const { startGetHeaderInfoInterval } = useIntervalGettingHeaderInfo({ isTimerExpired });

  function initTimer() {
    startTimer();
    startGetHeaderInfoInterval();
  }

  useEffect(() => {
    dispatch(getCertificates());
  }, []);

  return (
    <div className="extend-session">
      <div className="extend-session-block hint-block">
        <div className="title">До окончания сессии</div>
        <HintIcon
          className="extend-session-hint-icon"
          text={<SessionDescription />}
        />
      </div>
      <div className="extend-session-block timer-block">
        <div className="timer">{timerLabel}</div>
        <AppIconButton
          icon={<PlusIcon />}
          className="extend-session-plus-icon"
          onClick={openExtendSessionDialog}
        />
      </div>
      <ExtendSessionCertificateListDialog
        isDialogOpened={isExtendSessionDialogOpened}
        extendSession={extendSession}
        closeDialog={closeExtendSessionDialog}
      />
    </div>
  );
};
