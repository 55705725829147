import { RootState } from '@/store';

const selectOrganizationProfileInfoState = (state: RootState) => state.organizationProfile.info;

const selectLoadingStatus = (state: RootState) => selectOrganizationProfileInfoState(state).loadingStatus;
const selectOrganizationInfo = (state: RootState) => selectOrganizationProfileInfoState(state).organizationInfo;
const selectOrganizationOwner = (state: RootState) => selectOrganizationProfileInfoState(state).organizationOwner;

export const OrganizationProfileInfoSelectors = {
  selectLoadingStatus,
  selectOrganizationInfo,
  selectOrganizationOwner,
};
