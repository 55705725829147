import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { DocumentCardBlockedInfoDialogProps } from '@/shared/document/components/document-card-blocked-info-dialog/DocumentCardBlockedInfoDialog';
import { getDocumentStatusTitle } from '@/shared/document/models/document-status';
import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardBlockedInfoHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions'>;

export function useDocumentCardBlockedInfo({ documentCardSelectors, documentCardActions }: DocumentCardBlockedInfoHookParams): DocumentCardBlockedInfoDialogProps {
  const dispatch = useAppDispatch();

  const isDialogOpened = useSelector(documentCardSelectors.selectIsBlockedInfoDialogOpened);
  const blockedStatus = useSelector(documentCardSelectors.selectBlockedStatus);
  const blockedInfo = useSelector(documentCardSelectors.selectBlockedInfo);

  const header = `Переход в статус "${getDocumentStatusTitle(blockedStatus)}" заблокирован`;
  const subHeader = <div className="document-card-blocked-info-dialog-sub-header">
    Документ не соответствует требованиям для перехода в статус &quot;{getDocumentStatusTitle(blockedStatus)}&quot;. Должны выполняться следующие условия:
  </div>;

  const closeDialog = useCallback(() => {
    dispatch(documentCardActions.updateBlockedInfo({ isDialogOpened: false }));

    setTimeout(() => {
      dispatch(documentCardActions.updateBlockedInfo({ info: undefined }));
      dispatch(documentCardActions.updateBlockedInfo({ status: undefined }));
    }, AppDialogTransitionDuration);
  }, []);

  return {
    isDialogOpened,
    blockedInfo,
    header,
    subHeader,
    closeDialog,
  };
}
