import { AppCheckbox } from '@/common/components/app-checkbox/AppCheckbox';
import { SelectOption } from '@/common/models/app-select/select-option';
import React, { FC, memo } from 'react';
import './app-select-list.scss';

interface AppSelectListProps<T = string | boolean | number> {
  items: SelectOption<T>[];
  className?: string;
  isSelected: (value: T) => boolean;
  onItemClick: (value: T) => void;
}

/**
 * List component with checkboxes for selecting items.
 * @param items - items list.
 * @param className - className for component.
 * @param isSelected - whether particular item is selected.
 * @param onItemClick - item click handler.
 */
const AppSelectListInner: FC<AppSelectListProps> = ({ items, className, isSelected, onItemClick }) => {
  return (
    <div className={`app-select-list ${className ?? ''}`}>
      {items.map((item) => (
        <div
          className="app-select-list-item"
          key={item.value?.toString()}
          onClick={() => onItemClick(item.value)}
        >
          <div className="app-select-list-item-text">
            {item.label}
          </div>
          <AppCheckbox
            checked={isSelected(item.value)}
            tabIndex={-1}
          />
        </div>
      ))}
    </div>
  );
};

export const AppSelectList = memo(AppSelectListInner);
