import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectFavoritesState = (state: RootState) => state.favorites;

const selectIsUpdatingInProcess = (state: RootState) => selectFavoritesState(state).isUpdatingInProcess;
const selectUpdatedRowId = (state: RootState) => selectFavoritesState(state).updatedRowId;

const selectTableConfig = (state: RootState) => selectFavoritesState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectSearchState = (state: RootState) => selectFavoritesState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectPageableDataState = (state: RootState) => selectFavoritesState(state).pageableData;
const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectPageNumber = createSelector([selectPageableDataState], PageableDataSelectors.selectPageNumber);
const selectTotalItems = createSelector([selectPageableDataState], PageableDataSelectors.selectTotalItems);
const selectIsLoading = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);
const selectLoadingStatus = createSelector([selectPageableDataState], PageableDataSelectors.selectLoadingStatus);

export const FavoritesSelectors = {
  selectIsUpdatingInProcess,
  selectUpdatedRowId,
  selectTableConfig,
  selectSearchState,
  selectSearchString,
  selectTableSorting,
  selectPageableDataState,
  selectData,
  selectPageNumber,
  selectTotalItems,
  selectIsLoading,
  selectLoadingStatus
};
