import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { storageKeysLogin } from '@/modules/auth/constants/storage-keys/storage-keys-login';
import { CodeStatus } from '@/modules/auth/models/code-status';
import { LoginData } from '@/modules/auth/models/login-data';
import { signInComplete } from '@/modules/auth/store/login/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoginState {
  formData: Partial<LoginData>,
  verificationCode: string;
  expireVerificationCode: string;
  codeStatus?: CodeStatus;
  signinCode?: string,
  isLoading: boolean;
}

const initialState: LoginState = {
  isLoading: false,
  formData: { login: sessionStorage.getItem(storageKeysLogin.LOGIN) ?? '' },
  verificationCode: sessionStorage.getItem(storageKeysLogin.VERIFIED_CODE) ?? '',
  expireVerificationCode: sessionStorage.getItem(storageKeysLogin.EXPIRE_VERIFICATION_CODE) ?? '',
  codeStatus: sessionStorage.getItem(storageKeysLogin.VERIFIED_CODE) ? CodeStatus.Verified : undefined,
  signinCode: sessionStorage.getItem(storageKeysLogin.SIGNIN_CODE) ?? '',
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateLoginDataValue: (state: LoginState, action: PayloadAction<Partial<LoginData>>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    saveLoginToStorage: (state: LoginState) => {
      sessionStorage.setItem(storageKeysLogin.LOGIN, state.formData.login);
    },
    updateVerificationCode: (state: LoginState, action: PayloadAction<string>) => {
      state.verificationCode = action.payload;
    },
    updateSignInCode: (state: LoginState, action: PayloadAction<string>) => {
      state.signinCode = action.payload;
      sessionStorage.setItem(storageKeysLogin.SIGNIN_CODE, action.payload);
    },
    updateExpireVerificationCode: (state: LoginState, action: PayloadAction<string>) => {
      state.expireVerificationCode = action.payload;
      sessionStorage.setItem(storageKeysLogin.EXPIRE_VERIFICATION_CODE, action.payload);
    },
    updateCodeStatus: (state: LoginState, action: PayloadAction<CodeStatus>) => {
      state.codeStatus = action.payload;
      if (state.codeStatus === CodeStatus.Verified) {
        sessionStorage.setItem(storageKeysLogin.VERIFIED_CODE, state.verificationCode);
      }
    },
    updateLoadingState: (state: LoginState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetState: (state: LoginState) => {
      state.formData = {};
      state.verificationCode = '';
      state.isLoading = false;
      state.expireVerificationCode = undefined;
      state.codeStatus = undefined;
      state.signinCode = undefined;
      Object.keys(storageKeysLogin).forEach((key: keyof typeof storageKeysLogin) => {
        sessionStorage.removeItem(storageKeysLogin[key]);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase((signInComplete.pending), (state) => {
        state.isLoading = true;
      })
      .addCase((signInComplete.fulfilled), (state) => {
        const { updateCodeStatus } = loginSlice.caseReducers;
        updateCodeStatus(state, getReducerAction(updateCodeStatus.name, CodeStatus.Verified));
        state.isLoading = false;
      })
      .addCase((signInComplete.rejected), (state) => {
        const { updateCodeStatus } = loginSlice.caseReducers;
        updateCodeStatus(state, getReducerAction(updateCodeStatus.name, CodeStatus.Denied));
        state.isLoading = false;
      });
  }
});

export const {
  updateLoginDataValue,
  updateSignInCode,
  updateVerificationCode,
  updateExpireVerificationCode,
  updateCodeStatus,
  updateLoadingState,
  saveLoginToStorage,
  resetState,
} = loginSlice.actions;

export default loginSlice.reducer;
