import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { SetNewPasswordFormProps } from '@/modules/auth/components/set-new-password-form/SetNewPasswordForm';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import { resetState, setAgreementWithRules, setIsOpenLicenceAgreement, setPasswords } from '@/modules/auth/store/invite-manager';
import { signupManagerComplete } from '@/modules/auth/store/invite-manager/async-thunks';
import { InviteManagerSelectors } from '@/modules/auth/store/invite-manager/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useInviteManagerSetNewPasswordForm = (managerUuidCipher: string): SetNewPasswordFormProps => {
  const toast = useAppToast();
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const phoneNumber = useSelector(InviteManagerSelectors.selectLogin);
  const passwords = useSelector(InviteManagerSelectors.selectPasswords);
  const agreementWithRules = useSelector(InviteManagerSelectors.selectAgreementWithRules);

  const title = 'Придумайте пароль';
  const showAgreementWithRules = true;

  const onSavePasswordClick = useCallback((password: string) => {
    const action = () => signupManagerComplete({ managerUuidCipher, password });
    const thenCb = () => {
      toast('Вы успешно зарегистрированы!');
      dispatch(resetState());
    };

    asyncDispatch(action, thenCb);
  }, [managerUuidCipher]);

  const onLicenseAgreementClick = useCallback(() => {
    dispatch(setIsOpenLicenceAgreement(true));
  }, []);

  const onChangePasswords = useCallback((passwords: PasswordsConfirmation) => {
    dispatch(setPasswords(passwords));
  }, []);

  const onChangeAgreementWithRules = useCallback((agreementWithRules: boolean) => {
    dispatch(setAgreementWithRules(agreementWithRules));
  }, []);

  return {
    title,
    passwords,
    agreementWithRules,
    phoneNumber,
    showAgreementWithRules,
    onSavePasswordClick,
    onLicenseAgreementClick,
    onChangeAgreementWithRules,
    onChangePasswords
  };
};