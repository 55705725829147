import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType, AppTableRowType } from '@/common/models/app-table/app-table-types';
import { getProductAdditionalInfoStatusLabel, ProductAdditionalInfoStatus } from '@/shared/scanning-view/models/product-additional-info-status';
import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import { ScanningTableColumns } from '@/shared/scanning-view/models/scanning-table-columns';
import { convertScanningColumnFieldToDto } from '@/shared/scanning-view/models/scanning-table-columns-converters';
import { useMemo } from 'react';

export function useScanningTableSettings() {

  const columns = useMemo<AppTableColumnDefType<ScanningInfo>[]>(
    () => [
      {
        id: ScanningTableColumns.Sgtin,
        header: Labels.ssccOrSgtin,
        accessorKey: convertScanningColumnFieldToDto(ScanningTableColumns.Sgtin),
      },
      {
        id: ScanningTableColumns.Name,
        header: Labels.nameHeader,
        accessorKey: convertScanningColumnFieldToDto(ScanningTableColumns.Name),
      },
      {
        id: ScanningTableColumns.Producer,
        header: Labels.producer,
        accessorKey: convertScanningColumnFieldToDto(ScanningTableColumns.Producer),
      },
      {
        id: ScanningTableColumns.Gtin,
        header: Labels.gtin,
        accessorKey: convertScanningColumnFieldToDto(ScanningTableColumns.Gtin),
      },
      {
        id: ScanningTableColumns.Series,
        header: Labels.series,
        accessorKey: convertScanningColumnFieldToDto(ScanningTableColumns.Series),
      },
      {
        id: ScanningTableColumns.Status,
        header: Labels.status,
        accessorFn: (row) => getProductAdditionalInfoStatusLabel(row.productAdditionalInfoStatus),
      },
    ], []);

  const getCellRowClassName = (data: { row: AppTableRowType<ScanningInfo>; }) => {
    const scanningInfo = data.row.original;
    if (scanningInfo.productAdditionalInfoStatus === ProductAdditionalInfoStatus.Error) {
      return 'scanning-in-error-status';
    }
    if (scanningInfo.productAdditionalInfoStatus === ProductAdditionalInfoStatus.Awaiting) {
      return 'scanning-in-awaiting-status';
    }
  };

  const tableClasses = useMemo(() => ({
    cell: getCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses,
  };
}
