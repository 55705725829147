import { AppTableRowSelectionType, Updater } from '@/common/models/app-table/app-table-types';
import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { getValueFromUpdater } from '@/common/utils/common/types-utils';
import { defaultRemainsTableConfig } from '@/shared/remains/constants/remains-table-config';
import { Remain } from '@/shared/remains/models/remain';
import { RemainsFilter } from '@/shared/remains/models/remains-filter';
import { RemainsApi } from '@/shared/remains/store/types';
import { CaseReducerActions, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface RemainsState {
  rowsSelection: AppTableRowSelectionType;
  search: SearchingState;
  filterConfig: FilterConfigState<RemainsFilter>;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<Remain>;
}

interface RemainsCaseReducers extends SliceCaseReducers<RemainsState> {
  setRowSelection: (state: Draft<RemainsState>, action: PayloadAction<Updater<AppTableRowSelectionType>>) => void;
  resetState: (state: Draft<RemainsState>) => void;
}

export type RemainsActions = CaseReducerActions<RemainsCaseReducers, string>;

const notClearedFields: Set<keyof RemainsState> = new Set(['tableConfig']);

interface CreateRemainsSliceParams {
  moduleName: string;
  savedTableConfigName: string;
  api: RemainsApi;
}

export const createRemainsSlice = ({ moduleName, savedTableConfigName, api }: CreateRemainsSliceParams) => {
  const remainsTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: defaultRemainsTableConfig,
  };

  const remainsSearchingSlice = createSearchingSlice(moduleName);
  const remainsTableConfigSlice = createSavedTableConfigSlice(moduleName, remainsTableConfigState);
  const remainsPageableDataSlice = createPageableDataSlice<Remain>(moduleName);
  const remainsFilterConfigSlice = createFilterConfigSlice<RemainsFilter>(moduleName);

  const initialState: RemainsState = {
    rowsSelection: {},
    search: remainsSearchingSlice.getInitialState(),
    filterConfig: remainsFilterConfigSlice.getInitialState(),
    tableConfig: remainsTableConfigSlice.getInitialState(),
    pageableData: remainsPageableDataSlice.getInitialState(),
  };

  const rootSlice = createSlice<RemainsState, RemainsCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      setRowSelection(state: RemainsState, action: PayloadAction<Updater<AppTableRowSelectionType>>) {
        state.rowsSelection = getValueFromUpdater<AppTableRowSelectionType>(action.payload, state.rowsSelection);
      },
      resetState(state: RemainsState) {
        Object.keys(state)
          .forEach((key: keyof RemainsState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getAllRemains.pending, (state) => {
          const { updateLoadingStatus } = remainsPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
        })
        .addCase(api.getAllRemains.fulfilled, (state) => {
          const { updateLoadingStatus } = remainsPageableDataSlice.caseReducers;
          const { updateStateAfterGettingData } = remainsFilterConfigSlice.caseReducers;
          const { saveAppliedSearchString } = remainsSearchingSlice.caseReducers;

          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
          updateStateAfterGettingData(state.filterConfig);
          saveAppliedSearchString(state.search);
        })
        .addCase(api.getAllRemains.rejected, (state) => {
          const { updateLoadingStatus } = remainsPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
        })
        .addMatcher(
          (action) => action.type.startsWith(remainsSearchingSlice.name),
          (state, action) => {
            state.search = remainsSearchingSlice.reducer(state.search, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(remainsTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = remainsTableConfigSlice.reducer(state.tableConfig, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(remainsPageableDataSlice.name),
          (state, action) => {
            state.pageableData = remainsPageableDataSlice.reducer(state.pageableData, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(remainsFilterConfigSlice.name),
          (state, action) => {
            state.filterConfig = remainsFilterConfigSlice.reducer(state.filterConfig, action);
          }
        );
    },
  });

  return {
    rootSlice,
    remainsSearchingSlice,
    remainsTableConfigSlice,
    remainsPageableDataSlice,
    remainsFilterConfigSlice,
  };
};
