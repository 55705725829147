import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { required } from '@/common/utils/client-validation/validation-rules';
import { DocumentCardProcessedDialogData, DocumentCardProcessedDialogDataFields } from '@/shared/document/models/document-card/document-card-processed-dialog-validation-data';

export function useDocumentCardProcessedDialogValidation(data: DocumentCardProcessedDialogData, validateRejectReason: boolean) {

  const validationRules: FormValidationRules<Partial<DocumentCardProcessedDialogData>, DocumentCardProcessedDialogDataFields> = {
    rejectReason: []
  };

  if (validateRejectReason) {
    validationRules.rejectReason.push(required());
  }

  return {
    ...useFormValidation({ validationRules, data }),
  };
}