import { receiptCardScanningModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardSelectors } from '@/modules/receipts/store/receipt-card/card/selectors';
import { receiptCardScanningSelectors } from '@/modules/receipts/store/receipt-card/scanning/selectors';
import { createDocumentCardScanningThunks } from '@/shared/document/store/document-card/scanning/async-thunks';

export const receiptCardScanningApi = createDocumentCardScanningThunks({
  moduleName: receiptCardScanningModuleName,
  documentCardSelectors: receiptCardSelectors,
  documentCardScanningSelectors: receiptCardScanningSelectors,
});
