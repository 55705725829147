import { disposalCardGeneralPositionsModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardGeneralPositionsApi } from '@/modules/disposals/store/disposal-card/general-positions/async-thunks';
import { createDocumentCardGeneralPositionsSlice } from '@/shared/document/store/document-card/general-positions';

export const disposalCardGeneralPositionsSlice = createDocumentCardGeneralPositionsSlice({
  moduleName: disposalCardGeneralPositionsModuleName,
  savedTableConfigName: 'disposal-card-general-positions-table',
  api: disposalCardGeneralPositionsApi,
});

export const disposalCardGeneralPositionsRootActions = disposalCardGeneralPositionsSlice.rootSlice.actions;
export const disposalCardGeneralPositionsTableConfigActions = disposalCardGeneralPositionsSlice.documentCardPositionsTableConfigSlice.actions;

export default disposalCardGeneralPositionsSlice.rootSlice.reducer;
