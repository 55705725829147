import { receiptCardHistoryModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardHistoryApi } from '@/modules/receipts/store/receipt-card/history/async-thunks';
import { createDocumentCardHistorySlice } from '@/shared/document/store/document-card/history';

export const receiptCardHistorySlice = createDocumentCardHistorySlice({
  moduleName: receiptCardHistoryModuleName,
  savedTableConfigName: 'receipt-card-history-table',
  api: receiptCardHistoryApi,
});

export const receiptCardHistoryRootActions = receiptCardHistorySlice.rootSlice.actions;
export const receiptCardHistoryTableConfigActions = receiptCardHistorySlice.documentCardHistoryTableConfigSlice.actions;

export default receiptCardHistorySlice.rootSlice.reducer;
