import { AppInput } from '@/common/components/app-input/AppInput';
import { numbersRegExp } from '@/common/constants/reg-exps';
import { AsyncDispatchResult, useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { ErrorModel } from '@/common/models/error-model';
import { ValidationResult as ClientValidationResult } from '@/common/utils/client-validation/validation-result';
import { ValidationResult as ServerValidationResult } from '@/common/utils/server-validation/validation-result';
import { SendCodeButton } from '@/modules/auth/components/send-code-button/SendCodeButton';
import { CodeStatus } from '@/modules/auth/models/code-status';
import { ConfirmContactErrorFields } from '@/modules/auth/models/confirm-contact/confirm-contact-error-fields';
import { ContactType } from '@/modules/auth/models/contact-type';
import { RegistrationFormCode, RegistrationFormCodesFields } from '@/modules/auth/models/registration-form-codes';
import { setCodeStatus, updateFormCodesValue } from '@/modules/auth/store/registration';
import { confirmContact } from '@/modules/auth/store/registration/async-thunks';
import { getContactTypeFromField, getFieldFromContactType } from '@/modules/auth/utils/contact-type-utils';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback, useMemo } from 'react';

interface ContactConfirmationFormProps {
  contactType: ContactType;
  code: RegistrationFormCode;
  contactValue: string;
  codesMaxLength: number;
  isConfirmContactInProgress: boolean;
  clientValidationResult: ClientValidationResult;
  expireTime: Date;
  serverValidationResult: ServerValidationResult;
  sendCode: () => AsyncDispatchResult;
}

const ContactConfirmationFormInner: FC<ContactConfirmationFormProps> = ({ contactType, code, contactValue, codesMaxLength, isConfirmContactInProgress, clientValidationResult, expireTime, serverValidationResult, sendCode }) => {
  const { fieldErrors, asyncDispatch } = useAsyncDispatch<ConfirmContactErrorFields>();
  const dispatch = useAppDispatch();

  const fieldName = useMemo(() => getFieldFromContactType(contactType), [contactType]);
  const isEmailContact = useMemo(() => contactType === ContactType.Email, [contactType]);
  const isCodeVerified = useMemo(() => code?.codeStatus === CodeStatus.Verified, [code?.codeStatus]);
  const isCodeDenied = useMemo(() => code?.codeStatus === CodeStatus.Denied, [code?.codeStatus]);

  const confirmContantInner = (fieldName: RegistrationFormCodesFields, value: string) => {
    const body = {
      contactType: getContactTypeFromField(fieldName),
      verificationCode: value
    };

    const thenCb = (res: RegistrationFormCodesFields) => {
      dispatch(setCodeStatus({ contactType: res, status: CodeStatus.Verified }));
    };

    const catchCb = (err: ErrorModel) => {
      dispatch(setCodeStatus({
        contactType: getFieldFromContactType(err.args?.contactType as ContactType ?? contactType),
        status: CodeStatus.Denied
      }));
    };

    asyncDispatch<RegistrationFormCodesFields>(
      () => confirmContact(body),
      thenCb,
      catchCb
    );
  };

  const onCodeChange = useCallback((value: string) => {
    dispatch(updateFormCodesValue({ [fieldName]: { value } }));
    if (value.length === codesMaxLength) {
      confirmContantInner(fieldName, value);
    }
  }, [fieldName, codesMaxLength]);

  const helperText = useMemo(() =>
    !isCodeVerified
      ? clientValidationResult?.messages?.[0] || fieldErrors[fieldName]?.message || serverValidationResult?.message
      : undefined
    , [isCodeVerified, clientValidationResult?.messages?.[0], fieldErrors[fieldName]?.message, serverValidationResult?.message]);

  const error = useMemo(() =>
    !isCodeVerified
      ? !isConfirmContactInProgress && (clientValidationResult?.error || fieldErrors[fieldName]?.error || serverValidationResult?.error || isCodeDenied)
      : false
    , [isCodeVerified, isConfirmContactInProgress, clientValidationResult?.error, fieldErrors[fieldName]?.error, serverValidationResult?.error, isCodeDenied]);

  return (
    <>
      <div className="label">
        {isEmailContact ? 'Email' : 'Номер телефона'} <span>{contactValue}</span>
      </div>
      <AppInput
        className="code-input"
        label={`Код подтверждения из ${isEmailContact ? 'email' : 'смс'}`}
        value={code?.value}
        maxLength={codesMaxLength}
        readOnly={isCodeVerified}
        disabled={isConfirmContactInProgress}
        success={isCodeVerified}
        error={error}
        helperText={helperText}
        regExp={numbersRegExp}
        onInput={onCodeChange}
      />
      {!isCodeVerified &&
        <SendCodeButton
          expireTime={expireTime}
          onClick={sendCode}
        />
      }
    </>
  );
};

export const ContactConfirmationForm = memo(ContactConfirmationFormInner);
