import { Certificate } from '@/common/models/certificate';
import { LoadingStatus } from '@/common/models/loading-status';
import { resetState } from '@/modules/auth/store/registration';
import { certificatesModuleName } from '@/modules/certificates/constants/certificates-module-name';
import { storageKeysCertificates } from '@/modules/certificates/constants/storage-keys';
import { getCertificates } from '@/modules/certificates/store/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CertificatesState {
  loadingStatus: LoadingStatus;
  preSelectedCertificate: Certificate;
  selectedCertificate?: Certificate;
  certificates: Certificate[];
}

const initialState: CertificatesState = {
  loadingStatus: undefined,
  preSelectedCertificate: undefined,
  selectedCertificate: undefined,
  certificates: [],
};

const certificatesSlice = createSlice({
  name: certificatesModuleName,
  initialState,
  reducers: {
    setPreSelectedCertificate(state, action: PayloadAction<Certificate>) {
      state.preSelectedCertificate = action.payload;
    },
    setSelectedCertificate(state) {
      state.selectedCertificate = state.preSelectedCertificate;
      sessionStorage.setItem(storageKeysCertificates.SELECTED_CERTIFICATE, JSON.stringify(state.selectedCertificate));
    },
    setSelectedCertificatesFromStorage(state) {
      state.preSelectedCertificate = JSON.parse(sessionStorage.getItem(storageKeysCertificates.SELECTED_CERTIFICATE));
      state.selectedCertificate = JSON.parse(sessionStorage.getItem(storageKeysCertificates.SELECTED_CERTIFICATE));
    },
    removeSelectedCertificate(state) {
      state.selectedCertificate = undefined;
      state.preSelectedCertificate = undefined;
      sessionStorage.removeItem(storageKeysCertificates.SELECTED_CERTIFICATE);
    },
    setLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (state) => {
        certificatesSlice.caseReducers.removeSelectedCertificate(state);
      })
      .addCase(getCertificates.pending, (state) => {
        state.loadingStatus = LoadingStatus.Pending;
      })
      .addCase(getCertificates.fulfilled, (state, action) => {
        state.certificates = action.payload;
        state.loadingStatus = LoadingStatus.Fulfilled;
      })
      .addCase(getCertificates.rejected, (state) => {
        state.loadingStatus = LoadingStatus.Rejected;
      });
  }
});

export const {
  setPreSelectedCertificate,
  setSelectedCertificate,
  setSelectedCertificatesFromStorage,
  removeSelectedCertificate,
  setLoadingStatus
} = certificatesSlice.actions;

export default certificatesSlice.reducer;
