import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { FileOperationsFilters } from '@/modules/file-operations/components/file-operations-filters/FileOperationsFilters';
import { useFileOperationsTableActions } from '@/modules/file-operations/hooks/use-file-operations-table-actions';
import { useFileOperationsTableDataSource } from '@/modules/file-operations/hooks/use-file-operations-table-data-source';
import { useFileOperationsTableEventHandlers } from '@/modules/file-operations/hooks/use-file-operations-table-event-handlers';
import { useFileOperationsTableFiltering } from '@/modules/file-operations/hooks/use-file-operations-table-filtering';
import { useFileOperationsTableRowActions } from '@/modules/file-operations/hooks/use-file-operations-table-row-actions';
import { useFileOperationsTableSettings } from '@/modules/file-operations/hooks/use-file-operations-table-settings';
import { FileOperationsSelectors } from '@/modules/file-operations/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import './file-operations-table.scss';

export const FileOperationsTable = () => {
  const data = useSelector(FileOperationsSelectors.selectData);
  const isLoadingData = useSelector(FileOperationsSelectors.selectIsLoadingData);
  const tableConfig = useSelector(FileOperationsSelectors.selectTableConfig);
  const selectedFiltersCount = useSelector(FileOperationsSelectors.selectSelectedFiltersCount);
  const isPanelOpen = useSelector(FileOperationsSelectors.selectIsPanelOpen);

  const { loadFirstPage, loadNextPage } = useFileOperationsTableDataSource();
  const { isDocumentLoading, isReportLoading, downloadDocument, downloadReport } = useFileOperationsTableActions();
  const { columns, tableClasses, searchPlaceholder } = useFileOperationsTableSettings();
  const { getRowActions } = useFileOperationsTableRowActions({ isDocumentLoading, isReportLoading, downloadDocument, downloadReport });
  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: FileOperationsSelectors.selectSearchState
  });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick
  } = useFileOperationsTableFiltering({ loadFirstPage });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRefreshClick
  } = useFileOperationsTableEventHandlers({ loadFirstPage, loadNextPage });

  return (
    <AppCustomTable
      data={data}
      columns={columns}
      isLoading={isLoadingData}
      configState={tableConfig}
      classes={tableClasses}
      getRowActions={getRowActions}
      enableRowVirtualization
      manualSorting
      topToolbar={{
        filter: {
          showFilterButton: true,
          filterPanel: <FileOperationsFilters />,
          isPanelOpen,
          selectedFiltersCount,
          disableApplyFilterButton,
          disableClearFilterButton,
          onFilterClick,
          onApplyFiltersClick,
          onClearFiltersClick,
        },
        search: {
          showSearchInput: true,
          searchPlaceholder,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput,
          onApplySearchClick,
          onClearSearchClick,
        },
        refreshButton: {
          showRefreshButton: true,
          notClickableRefreshButton: isLoadingData,
          onRefreshClick
        }
      }}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
      onScrollAtTheEnd={onScrollChange}
    />
  );
};
