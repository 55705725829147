import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { useAddingRemainsSuccessfulNotification } from '@/modules/disposals/hooks/remains/use-adding-remains-successful-notification';
import { AddRemainsBodyErrorFields } from '@/modules/disposals/models/remains/add-remains-body';
import { disposalCardRemainsDocumentActions } from '@/modules/disposals/store/disposal-card/remains/document-actions';
import { addRemainsToDocument } from '@/modules/disposals/store/disposal-card/remains/document-actions/async-thunks';
import { DisposalCardRemainsDocumentActionsSelectors } from '@/modules/disposals/store/disposal-card/remains/document-actions/selectors';
import { disposalCardRemainsRootActions } from '@/modules/disposals/store/disposal-card/remains/table';
import { disposalCardRemainsSelectors } from '@/modules/disposals/store/disposal-card/remains/table/selectors';
import { useAppDispatch } from '@/store';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type RemainsTableDocumentActionsHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useRemainsTableDocumentActions({ loadFirstPage }: RemainsTableDocumentActionsHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch<AddRemainsBodyErrorFields>();
  const { showSuccessfulNotification } = useAddingRemainsSuccessfulNotification();

  const rowsSelectionsIds = useSelector(disposalCardRemainsSelectors.selectRowsSelectionIds);
  const isAddingInProcess = useSelector(DisposalCardRemainsDocumentActionsSelectors.selectIsAddingInProgress);

  const selectedRowsCount = rowsSelectionsIds.length;
  const addButtonDisabled = selectedRowsCount === 0 || isAddingInProcess;

  const addButtonLabel = useMemo(() => (
    `Добавить${selectedRowsCount ? ` (${selectedRowsCount})` : ''}`
  ), [selectedRowsCount]);

  const onAddClick = useCallback(() => {
    asyncDispatch(
      () => addRemainsToDocument({ isForce: false }),
      () => {
        showSuccessfulNotification();
        dispatch(disposalCardRemainsRootActions.setRowSelection({}));
        loadFirstPage();
      },
      (err) => {
        if (err?.args) {
          dispatch(disposalCardRemainsDocumentActions.setAddingRemainsWarnInfo(err.args.ssccBoxToAdding));
          dispatch(disposalCardRemainsDocumentActions.setIsOpenedAddingRemainsSuccessfulDialog(true));
        }
      }
    );
  }, [showSuccessfulNotification]);

  return {
    addButton: {
      label: addButtonLabel,
      disabled: addButtonDisabled,
      onClick: onAddClick
    }
  };
}
