import CrossIcon from '@/assets/icons/controls/cross.svg?react';
import { DeleteButtonTooltip } from '@/common/components/app-table/components/delete-button/delete-button-tooltip/DeleteButtonTooltip';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { ClickAwayListener } from '@mui/material';
import React, { FC, memo, useCallback, useState } from 'react';
import './delete-button.scss';

interface DeleteButtonProps {
  onDelete: () => AsyncDispatchResult;
}

const DeleteButtonInner: FC<DeleteButtonProps> = ({ onDelete }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onDeleteInner = useCallback(() => {
    onDelete()
      .unwrap()
      .then(handleTooltipClose);
  }, [onDelete]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <AppTooltip
          open={open}
          text={
            <DeleteButtonTooltip
              onDeny={handleTooltipClose}
              onAccess={onDeleteInner}
            />
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div>
            <CrossIcon
              className="delete-button-in-circle"
              onClick={handleTooltipOpen}
            />
          </div>
        </AppTooltip>
      </div>
    </ClickAwayListener>
  );
};

export const DeleteButton = memo(DeleteButtonInner);