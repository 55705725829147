import CancelIcon from '@/assets/icons/common/cancel.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import { documentNoteMaxLength } from '@/common/utils/client-validation/string-lengths';
import { DocumentCardNoteItemBase } from '@/shared/document/components/document-card-notes/components/document-card-note-item-base/DocumentCardNoteItemBase';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import SaveIcon from '@mui/icons-material/Save';
import React, { FC, memo } from 'react';

export interface DocumentCardNoteItemEditProps {
  note: DocumentCardNote;
  isSavingInProgress: boolean;
  onSaveClick: () => void;
  onCancelClick: () => void;
  onNoteValueChange: (value: string) => void;
}

const DocumentCardNoteItemEditInner: FC<DocumentCardNoteItemEditProps> = ({ note, isSavingInProgress, onSaveClick, onCancelClick, onNoteValueChange }) => {
  return (
    <DocumentCardNoteItemBase
      note={note}
      actions={
        <>
          <AppIconButton
            icon={<CancelIcon />}
            initialSize
            disabled={isSavingInProgress}
            hoverColor="text"
            className="note-icon"
            tooltip="Отменить"
            onClick={onCancelClick}
          />
          <AppIconButton
            icon={<SaveIcon />}
            initialSize
            className="note-icon"
            activeColor="primary"
            tooltip="Сохранить"
            isLoading={isSavingInProgress}
            onClick={onSaveClick}
          />
        </>
      }
      content={
        <AppInput
          type={AppInputType.Textarea}
          value={note.noteText}
          rows={5}
          autoFocus
          readOnly={isSavingInProgress}
          maxLength={documentNoteMaxLength}
          onValueChange={onNoteValueChange}
        />
      }
    />
  );
};

export const DocumentCardNoteItemEdit = memo(DocumentCardNoteItemEditInner);
