import ChevronIcon from '@/assets/icons/chevron.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { MenuItem } from '@/modules/portal/components/menu-panel/components/MenuPanelItem/MenuPanelItem';
import { useMenuPanelItems } from '@/modules/portal/hooks/use-menu-panel-items';
import { useMenuPanelItemsVisibility } from '@/modules/portal/hooks/use-menu-panel-items-visibility';
import React, { MouseEvent, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './menu-panel.scss';

export const MenuPanel = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { menuPanelItems } = useMenuPanelItems();
  const { isParentItemVisible, isChildItemVisible } = useMenuPanelItemsVisibility();

  const [open, setOpen] = useState(true);

  const toggleMenuPanel = useCallback((e: MouseEvent<Element>) => {
    e.stopPropagation();
    setOpen(prev => !prev);
  }, []);

  const onClickMenuPanelItem = useCallback((link: string) => open && navigate(link), []);
  const isActiveMenuPanelItem = useCallback((link: string) => pathname.startsWith(link), [pathname]);

  return (
    <div
      className={`menu-panel ${open ? 'open' : ''}`}
      onClick={(e) => !open && toggleMenuPanel(e)}
    >
      <AppIconButton
        icon={<ChevronIcon />}
        activeColor="primary"
        initialSize
        className="toggle-open-chevron"
        onClick={toggleMenuPanel}
      />
      <div className="menu-panel-list-items">
        {menuPanelItems.map((item) => isParentItemVisible(item) && (
          <MenuItem
            key={item.label}
            item={item}
            open={open}
            isActiveMenuItem={isActiveMenuPanelItem}
            isItemVisible={isChildItemVisible}
            onClick={onClickMenuPanelItem}
          />
        ))}
      </div>
    </div>
  );
};
