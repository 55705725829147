import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardOpeningHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions'>;

export function useDocumentCardOpening({ documentCardSelectors, documentCardActions }: DocumentCardOpeningHookParams) {
  const dispatch = useAppDispatch();

  const isCardOpened = useSelector(documentCardSelectors.selectOpenDocumentInfo);

  const toggleOpenDocumentInfo = useCallback(() => dispatch(documentCardActions.toggleOpenDocumentInfo()), []);

  return {
    isCardOpened,
    toggleOpenDocumentInfo,
  };

}
