import { DocumentType } from '@/shared/document/models/document-type';

const documentsRoutes = {
  [DocumentType.Receipt]: '/receipts',
  [DocumentType.Sale]: '/sales',
  [DocumentType.OtherDisposals]: '/disposals',
};

export const getBaseDocumentPath = (docType: DocumentType) => documentsRoutes[docType];

export const getDocumentCardRoutePath = () => `:id`;
export const getDocumentCardRelativeRoute = (id: string) => id;

export const getDocumentCardRoutePathById = (baseDocumentRoutePath: string, docUuid: string) => `${baseDocumentRoutePath}/${docUuid}`;
export const getDocumentCardRoutePathByDocType = ({ docType, docUuid }: { docUuid: string; docType: DocumentType; }) => {
  const baseDocumentRoutePath = getBaseDocumentPath(docType);
  return getDocumentCardRoutePathById(baseDocumentRoutePath, docUuid);
};

export const documentCardScanningRoutePath = 'scanning';
export const documentCardRemainsRoutePath = 'remains';
