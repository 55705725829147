import SearchIcon from '@/assets/icons/controls/search.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { searchMaxLength } from '@/common/utils/client-validation/string-lengths';
import React, { FC, KeyboardEvent, memo, useCallback } from 'react';

export interface AppSearchBarProps {
  value: string;
  className?: string;
  placeholder?: string;
  searchButtonDisabled?: boolean;
  maxLength?: number;
  onInput: (value: string) => void;
  onSearchClick: () => void;
  onClearClick?: () => void;
}

const AppSearchBarInner: FC<AppSearchBarProps> = ({
  value,
  className,
  placeholder,
  searchButtonDisabled,
  maxLength = searchMaxLength,
  onInput,
  onSearchClick,
  onClearClick
}) => {

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearchClick?.();
    }
  }, []);

  return (
    <AppInput
      value={value}
      placeholder={placeholder}
      className={className}
      showClearButton
      maxLength={maxLength}
      iconStart={
        <AppIconButton
          icon={<SearchIcon />}
          initialSize
          disabled={searchButtonDisabled}
          tooltip="Выполнить поиск"
          onClick={onSearchClick}
        />
      }
      autoComplete="search"
      onKeyDown={onKeyDown}
      onInput={onInput}
      onClearClick={onClearClick}
    />
  );
};

export const AppSearchBar = memo(AppSearchBarInner);
