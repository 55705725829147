import { Labels } from '@/common/constants/labels';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { FilterConfigActions } from '@/common/models/filter-config/filter-config-actions';
import { PageableDataActions } from '@/common/models/pageable-data/pageable-data-actions';
import { SavedTableConfigActions } from '@/common/models/saved-table-config/saved-table-config-actions';
import { SearchingActions } from '@/common/models/searching/searching-actions';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { useDocumentCardScanningDeleteButton } from '@/shared/document/hooks/document-card-scanning/use-document-card-scanning-actions-toolbar';
import { useDocumentCardScanningItem } from '@/shared/document/hooks/document-card-scanning/use-document-card-scanning-item';
import { useDocumentCardScanningTableDataSource } from '@/shared/document/hooks/document-card-scanning/use-document-card-scanning-table-data-source';
import { useDocumentCardScanningTableEventHandlers } from '@/shared/document/hooks/document-card-scanning/use-document-card-scanning-table-event-handlers';
import { useDocumentCardScanningTableFiltering } from '@/shared/document/hooks/document-card-scanning/use-document-card-scanning-table-filtering';
import { DocumentCardScanningFilter } from '@/shared/document/models/document-card/scanning/document-card-scanning-filter';
import { getDocumentCardRoutePathByDocType } from '@/shared/document/routes/routes';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { DocumentCardScanningActions } from '@/shared/document/store/document-card/scanning';
import { DocumentCardScanningSelectors, DocumentsCardScanningApi } from '@/shared/document/store/document-card/scanning/types';
import { ScanningView } from '@/shared/scanning-view/ScanningView';
import { ScanningFilters } from '@/shared/scanning-view/components/scanning-filters/ScanningFilters';
import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import { useAppDispatch } from '@/store';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export interface DocumentCardScanningPageProps {
  documentCardSelectors: DocumentCardSelectors;
  documentCardScanningSelectors: DocumentCardScanningSelectors;
  documentCardScanningActions: DocumentCardScanningActions;
  documentCardScanningPageableDataActions: PageableDataActions<ScanningInfo>;
  documentCardScanningSearchingActions: SearchingActions;
  documentCardScanningTableConfigActions: SavedTableConfigActions;
  documentCardScanningFilterConfigActions: FilterConfigActions<DocumentCardScanningFilter>;
  documentCardScanningApi: DocumentsCardScanningApi;
}

export const DocumentCardScanningPage: FC<DocumentCardScanningPageProps> = ({
  documentCardSelectors,
  documentCardScanningSelectors,
  documentCardScanningActions,
  documentCardScanningPageableDataActions,
  documentCardScanningSearchingActions,
  documentCardScanningTableConfigActions,
  documentCardScanningFilterConfigActions,
  documentCardScanningApi
}) => {
  const dispatch = useAppDispatch();

  const data = useSelector(documentCardScanningSelectors.selectScannedItems);
  const isLoading = useSelector(documentCardScanningSelectors.selectIsLoadingScannedItems);
  const configState = useSelector(documentCardScanningSelectors.selectTableConfig);
  const filterModel = useSelector(documentCardScanningSelectors.selectFilters);
  const isPanelOpen = useSelector(documentCardScanningSelectors.selectIsPanelOpen);
  const selectedFiltersCount = useSelector(documentCardScanningSelectors.selectSelectedFiltersCount);
  const docType = useSelector(documentCardSelectors.selectDocType);
  const docUuid = useSelector(documentCardSelectors.selectDocumentId);

  const backNavigationRoute = useMemo(() => {
    return getDocumentCardRoutePathByDocType({ docUuid, docType });
  }, [docUuid, docType]);

  useEffect(() => {
    return () => { dispatch(documentCardScanningActions.resetState()); };
  }, []);

  const getRowId = useCallback((row: ScanningInfo) => row.scanUuid, []);
  const searchPlaceholder = getSearchString([Labels.ssccOrSgtin]);

  const { loadFirstPage, loadNextPage } = useDocumentCardScanningTableDataSource({
    documentCardSelectors,
    documentCardScanningSelectors,
    documentCardScanningPageableDataActions,
    documentCardScanningApi
  });

  const {
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onFilterChanged,
    onRefreshClick,
    ...tableEventHandlers
  } = useDocumentCardScanningTableEventHandlers({
    loadFirstPage,
    loadNextPage,
    documentCardScanningTableConfigActions,
    documentCardScanningSearchingActions,
    documentCardScanningActions,
    documentCardScanningFilterConfigActions,
  });

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: documentCardScanningSelectors.selectSearchState
  });

  const scanningDeleteButtonContext = useDocumentCardScanningDeleteButton({
    loadFirstPage,
    documentCardScanningSelectors,
    documentCardScanningApi,
  });

  const scanningItemContext = useDocumentCardScanningItem({
    documentCardScanningSelectors,
    documentCardScanningApi,
  });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick
  } = useDocumentCardScanningTableFiltering({
    loadFirstPage,
    documentCardScanningFilterConfigActions,
    documentCardScanningSelectors,
  });

  return (
    <ScanningView
      backNavigationRoute={backNavigationRoute}
      scanningItemParams={scanningItemContext}
      deleteButtonParams={scanningDeleteButtonContext}
      toolbarParams={{
        search: {
          showSearchInput: true,
          searchPlaceholder,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput: onSearchValueInput,
          onApplySearchClick: onApplySearchClick,
          onClearSearchClick: onClearSearchClick,
        },
        filter: {
          showFilterButton: true,
          filterPanel: <ScanningFilters
            filter={filterModel}
            onFilterChanged={onFilterChanged}
          />,
          isPanelOpen,
          selectedFiltersCount,
          disableApplyFilterButton,
          disableClearFilterButton,
          onFilterClick,
          onApplyFiltersClick,
          onClearFiltersClick,
        },
        refreshButton: {
          showRefreshButton: true,
          notClickableRefreshButton: isLoading,
          onRefreshClick
        }
      }}
      tableParams={{
        data,
        isLoading,
        configState,
        getRowId,
        ...tableEventHandlers
      }}
    />
  );
};
