import React from 'react';
import { receiptCardScanningSelectors } from '@/modules/receipts/store/receipt-card/scanning/selectors';
import {
  receiptCardScanningFilterConfigSlice,
  receiptCardScanningPageableDataActions,
  receiptCardScanningRootActions,
  receiptCardScanningSearchingActions,
  receiptCardScanningTableConfigActions
} from '@/modules/receipts/store/receipt-card/scanning';
import { receiptCardScanningApi } from '@/modules/receipts/store/receipt-card/scanning/async-thunks';
import { receiptCardSelectors } from '@/modules/receipts/store/receipt-card/card/selectors';
import { DocumentCardScanningPage } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';

export const ReceiptCardScanningPage = () => (
  <DocumentCardScanningPage
    documentCardSelectors={receiptCardSelectors}
    documentCardScanningSelectors={receiptCardScanningSelectors}
    documentCardScanningActions={receiptCardScanningRootActions}
    documentCardScanningPageableDataActions={receiptCardScanningPageableDataActions}
    documentCardScanningSearchingActions={receiptCardScanningSearchingActions}
    documentCardScanningTableConfigActions={receiptCardScanningTableConfigActions}
    documentCardScanningFilterConfigActions={receiptCardScanningFilterConfigSlice}
    documentCardScanningApi={receiptCardScanningApi}
  />
);
