export const AppTableActionColumnName = 'mrt-row-actions';
export const AppTableSelectColumnName = 'mrt-row-select';
export const AppTableFixedCellWidthClassName = 'fixed-cell-width';
export const AppTableDisabledCellClassName = 'disabled-cell';
export const AppTableHideRowActionsClassName = 'hide-row-actions';

export const AppTableMaxColumnSize = 800;
export const AppTableMinColumnSize = 40;
export const AppTableDefaultColumnSize = 150;
export const AppTableSelectColumnSize = 50;
export const AppTableActionColumnDefaultSize = 70;
export const AppTableActionColumnIncreasedSize = 100;

export const AppTableScrollBottomPadding = 600;

export const AppTableItemsPageSize = 25;

export const AppTableMaxHeightForTableInDialog = 350;
