import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { NomenclatureFilter } from '@/modules/references/models/nomenclature/nomenclature-filter';
import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';

function getNomenclatureRsqlFilter(filter: Partial<NomenclatureFilter>): RsqlFilterModel<NomenclatureItem>[] {
  return [
    {
      selector: 'code',
      operation: RsqlFilterOperations.Equal,
      value: filter.code,
    },
    {
      selector: 'producerName',
      operation: RsqlFilterOperations.ILike,
      value: filter.producer,
    },
    {
      selector: 'producerCountry',
      operation: RsqlFilterOperations.ILike,
      value: filter.country,
    },
    {
      selector: 'barcode',
      operation: RsqlFilterOperations.Equal,
      value: filter.barcode,
    },
    {
      selector: 'gnvlp',
      operation: RsqlFilterOperations.Equal,
      value: filter.vital
    },
    {
      selector: 'gtin',
      operation: RsqlFilterOperations.Equal,
      value: filter.gtin
    },
    {
      selector: 'isGtinFound',
      operation: RsqlFilterOperations.Equal,
      value: filter.isGtinFound
    },
  ];
}

function getNomenclatureSearchFilter(searchString: string): RsqlFilterModel<NomenclatureItem>[] {
  return [
    {
      selector: 'name',
      operation: RsqlFilterOperations.EILike,
      value: searchString,
    }
  ];
}

export function buildNomenclatureFilterExpression(filter: Partial<NomenclatureFilter>, searchString: string): string {
  const filterModel = getNomenclatureRsqlFilter(filter);
  const searchModel = getNomenclatureSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
