import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableRowSelectionType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { DocumentCardScanningFilter } from '@/shared/document/models/document-card/scanning/document-card-scanning-filter';
import { DocumentCardScanningPageProps } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type DocumentCardScanningTableEventHandlersHookParams =
  PageableLoadingActions &
  Pick<DocumentCardScanningPageProps, 'documentCardScanningTableConfigActions' | 'documentCardScanningSearchingActions' | 'documentCardScanningActions' | 'documentCardScanningFilterConfigActions'>;

export function useDocumentCardScanningTableEventHandlers({
  loadFirstPage,
  loadNextPage,
  documentCardScanningTableConfigActions,
  documentCardScanningSearchingActions,
  documentCardScanningActions,
  documentCardScanningFilterConfigActions,
}: DocumentCardScanningTableEventHandlersHookParams
) {
  const dispatch = useAppDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(documentCardScanningTableConfigActions.updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(documentCardScanningTableConfigActions.updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(documentCardScanningTableConfigActions.updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(documentCardScanningTableConfigActions.updateTableColumnSizingState(value));
  }, []);

  const onScrollAtTheEnd = useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(documentCardScanningSearchingActions.updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onRowSelectionChange = useCallback((value: Updater<AppTableRowSelectionType>) => {
    dispatch(documentCardScanningActions.setRowSelection(value));
  }, []);

  const onFilterChanged = useCallback((model: Partial<DocumentCardScanningFilter>) => {
    dispatch(documentCardScanningFilterConfigActions.updateFilter(model));
  }, []);

  const onRefreshClick = useCallback(() => {
    loadFirstPage();
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onScrollAtTheEnd,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onRowSelectionChange,
    onFilterChanged,
    onRefreshClick
  };
}
