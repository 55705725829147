import React, { ReactNode } from 'react';

export enum DocumentCardUpdatingStatusBlockedInfoErrorFields {
  MedicinesWithoutSuccess = 'medicinesWithoutSuccess',
  IncorrectMedicinesAddedInOrder = 'incorrectMedicinesAddedInOrder',
  CorrectMedicinesNotAddedInOrder = 'correctMedicinesNotAddedInOrder'
}

const documentCardUpdatingStatusBlockedInfoTitles = {
  [DocumentCardUpdatingStatusBlockedInfoErrorFields.MedicinesWithoutSuccess]: 'Ожидается дополнительная информация по следующим товарам:',
  [DocumentCardUpdatingStatusBlockedInfoErrorFields.IncorrectMedicinesAddedInOrder]: 'Присутствуют товары, информации о которых нет в загруженной накладной:',
  [DocumentCardUpdatingStatusBlockedInfoErrorFields.CorrectMedicinesNotAddedInOrder]: 'Количество ожидаемых в накладной товаров не совпадает с фактическим количеством добавленных товаров:',
};

const documentCardUpdatingStatusBlockedInfoHintTexts: Partial<Record<DocumentCardUpdatingStatusBlockedInfoErrorFields, ReactNode>> = {
  [DocumentCardUpdatingStatusBlockedInfoErrorFields.MedicinesWithoutSuccess]: <>Ожидается ответ из МДЛП с дополнительной информацией о товарах.<br /> Например, производственная серия и срок годности</>,
  [DocumentCardUpdatingStatusBlockedInfoErrorFields.IncorrectMedicinesAddedInOrder]: 'Проверка товаров происходит по gtin и производственной серии, которые содержатся в xml-накладной, загруженной пользователем',
  [DocumentCardUpdatingStatusBlockedInfoErrorFields.CorrectMedicinesNotAddedInOrder]: 'Если по некоторым товарам еще не вернулся ответ с дополнительной информацией из МДЛП - они не будут учтены в фактическом количестве',
};

export const getDocumentCardUpdatingStatusBlockedInfoTitle = (field: DocumentCardUpdatingStatusBlockedInfoErrorFields) => documentCardUpdatingStatusBlockedInfoTitles[field];
export const getDocumentCardUpdatingStatusBlockedInfoHintText = (field: DocumentCardUpdatingStatusBlockedInfoErrorFields) => documentCardUpdatingStatusBlockedInfoHintTexts[field];