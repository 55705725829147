import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { ScrollableContent, ScrollableContentComponent } from '@/common/components/custom/scrollable-content/ScrollableContent';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { PageableDataActions } from '@/common/models/pageable-data/pageable-data-actions';
import { DocumentCardNoteAdding } from '@/shared/document/components/document-card-notes/components/document-card-note-adding/DocumentCardNoteAdding';
import { DocumentCardNoteContent } from '@/shared/document/components/document-card-notes/components/document-card-note-content/DocumentCardNoteContent';
import { useDocumentCardNotesAdding } from '@/shared/document/hooks/document-card-notes/use-document-card-notes-adding';
import { useDocumentCardNotesDataSource } from '@/shared/document/hooks/document-card-notes/use-document-card-notes-data-source';
import { useDocumentCardNotesDeleting } from '@/shared/document/hooks/document-card-notes/use-document-card-notes-deleting';
import { useDocumentCardNotesEditing } from '@/shared/document/hooks/document-card-notes/use-document-card-notes-editing';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { DocumentCardNotesSelectors, DocumentsCardNotesApi } from '@/shared/document/store/document-card/notes/types';
import React, { FC, memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './document-card-notes.scss';

export interface DocumentCardNotesProps {
  documentCardSelectors: DocumentCardSelectors;
  documentCardNotesSelectors: DocumentCardNotesSelectors;
  documentCardNotesPageableDataActions: PageableDataActions<DocumentCardNote>;
  documentCardNotesApi: DocumentsCardNotesApi;
}

const DocumentCardNotesInner: FC<DocumentCardNotesProps> = ({
  documentCardSelectors,
  documentCardNotesSelectors,
  documentCardNotesPageableDataActions,
  documentCardNotesApi
}) => {
  const { abortRequestByParam } = useAsyncDispatchAborting();

  const notes = useSelector(documentCardNotesSelectors.selectData);
  const isLoadingData = useSelector(documentCardNotesSelectors.selectIsLoadingData);
  const notesCount = notes.length;

  const scrollableContentRef = useRef<ScrollableContentComponent>();
  const scrollContentToTop = () => scrollableContentRef.current?.scrollToTop();

  const { dispatchResult, loadNextPage } = useDocumentCardNotesDataSource({
    documentCardSelectors,
    documentCardNotesSelectors,
    documentCardNotesPageableDataActions,
    documentCardNotesApi
  });

  useEffect(() => {
    return () => { abortRequestByParam(dispatchResult); };
  }, [dispatchResult]);

  const {
    newNote,
    isAddingNoteInProgress,
    onAddNoteInput,
    addNewNote
  } = useDocumentCardNotesAdding({ onNoteAdded: scrollContentToTop, documentCardNotesSelectors, documentCardNotesApi });

  const {
    editedNoteUuid,
    isEditingInProgress,
    onNoteValueChange,
    onEditClick,
    onCancelClick,
    onSaveClick
  } = useDocumentCardNotesEditing({ documentCardNotesSelectors, documentCardNotesApi });

  const {
    deletedNoteUuid,
    revertedNoteUuid,
    isDeletingInProgress,
    isRevertingInProgress,
    onDeleteClick,
    onRevertClick
  } = useDocumentCardNotesDeleting({ documentCardNotesSelectors, documentCardNotesApi });

  const notClickableActions = isEditingInProgress || isDeletingInProgress || isRevertingInProgress;

  return (
    <div className="document-card-notes-container">
      <DocumentCardNoteAdding
        noteText={newNote}
        isAddingNoteDisabled={isAddingNoteInProgress}
        onNoteInput={onAddNoteInput}
        onAddNewNoteClick={addNewNote}
      />
      {notesCount === 0 && isLoadingData ?
        <AppLoader className="notes-main-loader" /> :
        <ScrollableContent
          ref={scrollableContentRef}
          scrollBottomPadding={200}
          onScrollAtTheEnd={loadNextPage}
        >
          <div className="notes-container">
            {notes.map((note: DocumentCardNote) => <DocumentCardNoteContent
              key={note.documentNoteUuid}
              note={note}
              notClickableActions={notClickableActions}
              edit={{
                editedNoteUuid,
                onNoteValueChange,
                onEditClick,
              }}
              revert={{
                revertedNoteUuid,
                isRevertingInProgress,
                onRevertClick,
              }}
              delete={{
                deletedNoteUuid,
                isDeletingInProgress,
                onDeleteClick
              }}
              save={{
                isSavingInProgress: isEditingInProgress,
                onSaveClick,
                onCancelClick
              }}
            />
            )}
            {
              notesCount > 0 && isLoadingData &&
              <AppLoader
                className="notes-small-loader"
                size="small"
              />
            }
          </div>
        </ScrollableContent>
      }
    </div>
  );
};

export const DocumentCardNotes = memo(DocumentCardNotesInner);
