import { CommonUserData } from '@/common/models/common-user-data';
import { formatPhoneOutput } from '@/common/utils/common/formatting-utils';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';

export interface UserInfoFormData extends CommonUserData {
  activityPlaces: string[];
  userUuid?: string;
}

export type UserInfoFormDataFields = keyof UserInfoFormData;

export function convertUserDtoModelToFormModel(dto: UserInfo): UserInfoFormData {
  return {
    userUuid: dto.userUuid,
    firstName: dto.firstName,
    secondName: dto.secondName,
    lastName: dto.lastName,
    email: dto.email,
    phoneNumber: formatPhoneOutput(dto.phoneNumber),
    position: dto.position,
    activityPlaces: dto.activityPlaces?.map((place) => place.activityPlaceUuid),
  };
}
