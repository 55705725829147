import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';

type ColumnsMap<Column extends string, Entity extends object> = Partial<Record<Column, AppTableColumnDefType<Entity>>>;

export interface VisibleColumns<Column extends string> {
  visibleColumns?: Column[];
}

interface GetDocumentVisibleColumnsParams<Column extends string, Entity extends object> extends VisibleColumns<Column> {
  columnsMap: ColumnsMap<Column, Entity>;
}
type GetDocumentVisibleColumnsReturnType<Entity extends object> = AppTableColumnDefType<Entity>[];

type EntryColumnMap<Column extends string, Entity extends object> = [keyof ColumnsMap<Column, Entity>, ColumnsMap<Column, Entity>[keyof ColumnsMap<Column, Entity>]];

export const getDocumentVisibleColumns = <Column extends string, Entity extends object>({ columnsMap, visibleColumns }: GetDocumentVisibleColumnsParams<Column, Entity>): GetDocumentVisibleColumnsReturnType<Entity> => {
  if (Object.keys(columnsMap).length === 0) {
    return [];
  }

  if (!visibleColumns) {
    return Object.values(columnsMap);
  }

  return Object.entries(columnsMap).reduce((columns, [columnName, columnValue]: EntryColumnMap<Column, Entity>) => {
    if (visibleColumns.includes(columnName)) {
      columns.push(columnValue);
    }
    return columns;
  }, [] as GetDocumentVisibleColumnsReturnType<Entity>);
};