import boxes from '@/modules/disposals/store/disposal-card/boxes';
import card from '@/modules/disposals/store/disposal-card/card';
import generalPositions from '@/modules/disposals/store/disposal-card/general-positions';
import history from '@/modules/disposals/store/disposal-card/history';
import notes from '@/modules/disposals/store/disposal-card/notes';
import positions from '@/modules/disposals/store/disposal-card/positions';
import remains from '@/modules/disposals/store/disposal-card/remains/disposal-card-remains-reducer';
import scanning from '@/modules/disposals/store/disposal-card/scanning';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base disposal card reducer.
 */
const disposalCardReducer = combineReducers({
  card,
  generalPositions,
  boxes,
  positions,
  history,
  notes,
  scanning,
  remains,
});

export default disposalCardReducer;
