import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { ErrorModel } from '@/common/models/error-model';
import { DocumentCardCheckForAllScannedMedicineErrorFields } from '@/shared/document/models/document-card-check-for-not-scanned-medicine-error-fields.ts';
import { DocumentCardUpdatingStatusBlockedInfo } from '@/shared/document/models/document-card-updating-status-blocked-info';
import { DocumentCardUpdatingStatusBlockedInfoErrorFields } from '@/shared/document/models/document-card-updating-status-blocked-info-error-fields';
import { DocumentCardActionButtonsType } from '@/shared/document/models/document-card/document-card-action-buttons-type';
import { DocumentCardSendType } from '@/shared/document/models/document-card/document-card-send-type';
import { DocumentProcessingWay } from '@/shared/document/models/document-processing-way';
import { DocumentStatus } from '@/shared/document/models/document-status';
import { DocumentCardPageProps } from '@/shared/document/pages/document-card-page/DocumentCardPage';
import { documentCardRemainsRoutePath, documentCardScanningRoutePath } from '@/shared/document/routes/routes';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type DocumentCardActionButtonHandlersHookParams = Pick<DocumentCardPageProps, 'documentCardSelectors' | 'documentCardActions' | 'documentCardApi'>;

export interface DocumentCardActionButtonHandlersHookReturnType {
  getActionButtonHandlerByType: (type: DocumentCardActionButtonsType) => () => void;
  setDocumentCardStatusAsProcessed: () => void;
}

export const useDocumentCardActionButtonHandlers = ({ documentCardSelectors, documentCardApi, documentCardActions }: DocumentCardActionButtonHandlersHookParams): DocumentCardActionButtonHandlersHookReturnType => {
  const toast = useAppToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch<DocumentCardUpdatingStatusBlockedInfoErrorFields | DocumentCardCheckForAllScannedMedicineErrorFields>();

  const documentInfo = useSelector(documentCardSelectors.selectDocumentInfo);

  const onStartProcessingClick = useCallback(() => {
    asyncDispatch(
      () => documentCardApi.updateDocumentCardStatus({ state: DocumentStatus.InProcess }),
      () => toast('Документ переведен в обработку')
    );
  }, []);

  const onScanClick = useCallback(() => {
    navigate(documentCardScanningRoutePath);
  }, []);

  const onAddRemainsClick = useCallback(() => {
    navigate(documentCardRemainsRoutePath);
  }, []);

  const setDocumentCardStatusAsProcessed = useCallback(() => {

    const updateStatusFinallyCb = () => {
      dispatch(documentCardActions.updateWarnInfo({
        notScannedMedicineAmount: undefined,
        isDialogOpened: false,
      }));
    };

    const updateStatusCatchCb = (err: ErrorModel<DocumentCardUpdatingStatusBlockedInfoErrorFields>) => {
      if (err?.args) {
        dispatch(documentCardActions.updateBlockedInfo({
          status: DocumentStatus.Processed,
          info: err?.args as DocumentCardUpdatingStatusBlockedInfo,
          isDialogOpened: true,
        }));
      }
    };

    asyncDispatch(
      () => documentCardApi.updateDocumentCardStatus({ state: DocumentStatus.Processed }),
      () => toast('Обработка документа завершена'),
      updateStatusCatchCb,
      updateStatusFinallyCb
    );
  }, []);

  const onCompleteProcessingClick = useCallback(() => {
    const checkNotScannedMedicineCatchCb = (err: ErrorModel<DocumentCardCheckForAllScannedMedicineErrorFields>) => {
      if (err?.args) {
        dispatch(documentCardActions.updateWarnInfo({
          notScannedMedicineAmount: err?.args.notScannedMedicineAmount,
          isDialogOpened: true,
        }));
      }
    };

    if (documentInfo.processingWay === DocumentProcessingWay.Check) {
      asyncDispatch(
        documentCardApi.checkForAllScannedMedicine,
        setDocumentCardStatusAsProcessed,
        checkNotScannedMedicineCatchCb
      );
    } else {
      setDocumentCardStatusAsProcessed();
    }

  }, [documentInfo]);

  const openProcessedDialog = (sendType: DocumentCardSendType) => {
    dispatch(documentCardActions.updateProcessedDialog({ isDialogOpened: true, sendType }));
  };

  const onRefuceClick = useCallback(() => {
    openProcessedDialog(DocumentCardSendType.Reject);
  }, []);

  const onAcceptClick = useCallback(() => {
    openProcessedDialog(DocumentCardSendType.Accept);
  }, []);

  const onRejectClick = useCallback(() => {
    openProcessedDialog(DocumentCardSendType.Reject);
  }, []);

  const onConfirmClick = useCallback(() => {
    openProcessedDialog(DocumentCardSendType.Accept);
  }, []);

  const onSendClick = useCallback(() => {
    openProcessedDialog(DocumentCardSendType.Accept);
  }, []);

  const actionButtonHandlers = {
    [DocumentCardActionButtonsType.StartProcesing]: onStartProcessingClick,
    [DocumentCardActionButtonsType.Confirm]: onConfirmClick,
    [DocumentCardActionButtonsType.Reject]: onRejectClick,
    [DocumentCardActionButtonsType.Scan]: onScanClick,
    [DocumentCardActionButtonsType.AddRemains]: onAddRemainsClick,
    [DocumentCardActionButtonsType.CompleteProcesing]: onCompleteProcessingClick,
    [DocumentCardActionButtonsType.Recall]: onRefuceClick,
    [DocumentCardActionButtonsType.Accept]: onAcceptClick,
    [DocumentCardActionButtonsType.Send]: onSendClick,
  };

  const getActionButtonHandlerByType = (type: DocumentCardActionButtonsType) => actionButtonHandlers[type];

  return {
    getActionButtonHandlerByType,
    setDocumentCardStatusAsProcessed
  };
};
