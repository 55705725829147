import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { nomenclaturePageableDataActions } from '@/modules/references/store/nomenclature';
import { getAllNomenclature } from '@/modules/references/store/nomenclature/async-thunks';
import { NomenclatureSelectors } from '@/modules/references/store/nomenclature/selectors';
import { useSelector } from 'react-redux';

export function useNomenclatureTableDataSource() {
  const sorting = useSelector(NomenclatureSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: nomenclaturePageableDataActions,
    selectPageableDataState: NomenclatureSelectors.selectPageableDataState,
    getData: getAllNomenclature,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
