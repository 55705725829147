import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import './app-checkbox.scss';

type AppCheckboxClasses = 'container' | 'checkbox';

interface AppCheckboxProps {
  checked: boolean;
  label?: React.ReactNode;
  classes?: Partial<Record<AppCheckboxClasses, string>>;
  tabIndex?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  hint?: React.ReactNode;
  onChange?: (checked: boolean) => void;
}

const AppCheckboxInner: FC<AppCheckboxProps> = ({ checked = false, label, classes, tabIndex, disabled, error, helperText, hint, onChange }) => {

  const onChangeInner = useCallback((_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange?.(checked);
  }, []);

  const labelInner = useMemo(() => <>
    {label}
    {hint && <HintIcon
      text={hint}
      className="app-checkbox-hint"
    />}
  </>, [label, hint]);

  return (
    <div className={`app-checkbox-container ${classes?.container ?? ''}`}>
      <FormControlLabel
        className={`app-checkbox ${error ? 'error' : ''} ${classes?.checkbox ?? ''}`}
        label={labelInner}
        control={
          <Checkbox
            className="app-checkbox-icon"
            tabIndex={tabIndex}
            disableRipple
            disabled={disabled}
            checked={checked}
            onChange={onChangeInner}
          />
        }
      />
      {helperText && <div className="app-checkbox-helper-text">{helperText}</div>}
    </div>
  );
};

export const AppCheckbox = memo(AppCheckboxInner);
