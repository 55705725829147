import { shortTimeFormat } from '@/common/constants/date-formats';
import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { useTimer } from '@/common/hooks/use-timer';
import React, { FC, memo, useMemo } from 'react';
import './send-code-button.scss';

interface SendCodeButtonProps {
  expireTime: Date;
  onClick: () => AsyncDispatchResult;
}

const SendCodeButtonInner: FC<SendCodeButtonProps> = ({ expireTime, onClick }) => {

  const { timerLabel, isTimerExpired, startTimer } = useTimer({
    expireTime,
    dateTimeFormat: shortTimeFormat,
  });

  const isSendButtonDisabled = useMemo(() => !isTimerExpired, [isTimerExpired]);

  const onClickInner = () => {
    if (isSendButtonDisabled) {
      return;
    }
    onClick()
      .unwrap()
      .then(() => startTimer());
  };

  return (
    <div
      className={`send-code-button ${isSendButtonDisabled ? 'disabled' : ''}`}
      onClick={onClickInner}
    >
      Прислать еще раз {isSendButtonDisabled && <span>через {timerLabel}</span>}
    </div>
  );
};

export const SendCodeButton = memo(SendCodeButtonInner);