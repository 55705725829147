import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { DocumentCardCommonEntity } from '@/shared/document/models/document-card/document-card-common-entity';
import { DocumentProcessingWay } from '@/shared/document/models/document-processing-way';

export const getDocumentCardPositionCellRowClassName = (processingWay: DocumentProcessingWay) =>
  (data: { row: AppTableRowType<DocumentCardCommonEntity>; }) => {
    const documentPosition = data.row.original;
    if (processingWay === DocumentProcessingWay.Fill && !documentPosition.isProductPresentInOrder) {
      return 'document-position-in-error-status';
    }
  };
