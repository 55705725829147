import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { getCountOfNonEmptyFields } from '@/common/utils/common/object-utils';
import { createSelector } from '@reduxjs/toolkit';

const selectIsPanelOpen = <T>(config: FilterConfigState<T>) => config.isPanelOpen;
const selectFilters = <T>(config: FilterConfigState<T>) => config.filters;
const selectAppliedFilters = <T>(config: FilterConfigState<T>) => config.appliedFilters;
const selectSelectedFiltersCount = createSelector([selectAppliedFilters], (appliedFilters) => getCountOfNonEmptyFields(appliedFilters));

export const FilterConfigSelectors = {
  selectIsPanelOpen,
  selectFilters,
  selectSelectedFiltersCount,
};

