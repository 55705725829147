import { portalUserProfileModuleName } from '@/modules/portal/constants/portal-module-names';
import { userProfileStorageKeys } from '@/modules/portal/constants/user-profile-storage-keys';
import { PasswordChangeBody } from '@/modules/portal/models/password-change/password-change-body';
import { UserProfileInfo } from '@/modules/portal/models/user-profile-info';
import { UserProfileRequests } from '@/modules/portal/requests/user-profile-requests';
import { setUserUuidToSessionStorage } from '@/modules/portal/store/user-profile';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getUserProfileInfo = createAsyncThunkWrapper<
  UserProfileInfo,
  void
>(
  `${portalUserProfileModuleName}/getUserInfo`,
  (_, { dispatch }) => UserProfileRequests.userInfo()
    .then((res) => {
      const { userUuid } = res.data;
      if (!sessionStorage.getItem(userProfileStorageKeys.USER_UUID)) {
        dispatch(setUserUuidToSessionStorage(userUuid));
      }
      return res.data;
    }),
);

export const logout = createAsyncThunkWrapper<
  void,
  void
>(
  `${portalUserProfileModuleName}/logout`,
  async () => { await UserProfileRequests.logout(); }
);

export const passwordChange = createAsyncThunkWrapper<
  void,
  PasswordChangeBody
>(
  `${portalUserProfileModuleName}/passwordChange`,
  async (body) => { await UserProfileRequests.passwordChange(body); }
);
