import { initialPasswordsState } from '@/modules/auth/constants/initial-passwords-state';
import { storageKeysInviteManager } from '@/modules/auth/constants/storage-keys/storage-keys-invite-manager';
import { CodeStatus } from '@/modules/auth/models/code-status';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import { sendVerificationCodeByCipcher, signupManagerVerifyPhoneNumber } from '@/modules/auth/store/invite-manager/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InviteManagerFormState {
  login: string;
  code: string;
  isLoading: boolean;
  codeStatus?: CodeStatus;
  expireTimePhone: string;
  passwords: Partial<PasswordsConfirmation>;
  agreementWithRules: boolean;
  showSetNewPasswordForm: boolean;
  isOpenLicenceAgreement: boolean;
}

const initialState: InviteManagerFormState = {
  login: '',
  code: sessionStorage.getItem(storageKeysInviteManager.VERIFIED_CODE) ?? '',
  isLoading: false,
  codeStatus: sessionStorage.getItem(storageKeysInviteManager.VERIFIED_CODE) ? CodeStatus.Verified : undefined,
  expireTimePhone: sessionStorage.getItem(storageKeysInviteManager.EXPIRE_TIME_PHONE) ?? '',
  passwords: initialPasswordsState,
  agreementWithRules: false,
  showSetNewPasswordForm: !!sessionStorage.getItem(storageKeysInviteManager.VERIFIED_CODE),
  isOpenLicenceAgreement: false
};

const InviteManagerFormSlice = createSlice({
  name: 'inviteManagerForm',
  initialState,
  reducers: {
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setIsOpenLicenceAgreement(state, action: PayloadAction<boolean>) {
      state.isOpenLicenceAgreement = action.payload;
    },
    setPasswords(state, action: PayloadAction<PasswordsConfirmation>) {
      state.passwords = action.payload;
    },
    setAgreementWithRules(state, action: PayloadAction<boolean>) {
      state.agreementWithRules = action.payload;
    },
    resetState: (state) => {
      state.code = '';
      state.login = '';
      state.isLoading = false;
      state.codeStatus = undefined;
      state.expireTimePhone = '';
      state.showSetNewPasswordForm = false;
      Object.keys(storageKeysInviteManager).forEach((key: keyof typeof storageKeysInviteManager) =>
        sessionStorage.removeItem(storageKeysInviteManager[key])
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendVerificationCodeByCipcher.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendVerificationCodeByCipcher.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expireTimePhone = action.payload.nextCodeResend;
        state.login = action.payload.contact;
        sessionStorage.setItem(storageKeysInviteManager.EXPIRE_TIME_PHONE, action.payload.nextCodeResend);
        sessionStorage.setItem(storageKeysInviteManager.LOGIN, action.payload.contact);
      })
      .addCase(signupManagerVerifyPhoneNumber.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signupManagerVerifyPhoneNumber.fulfilled, (state) => {
        state.isLoading = false;
        state.codeStatus = CodeStatus.Verified;
        state.showSetNewPasswordForm = true;
        sessionStorage.setItem(storageKeysInviteManager.VERIFIED_CODE, state.code);
      })
      .addCase(signupManagerVerifyPhoneNumber.rejected, (state) => {
        state.isLoading = false;
        state.codeStatus = CodeStatus.Denied;
      });
  },
});

export const {
  setCode,
  setIsOpenLicenceAgreement,
  setPasswords,
  setAgreementWithRules,
  resetState,
} = InviteManagerFormSlice.actions;

export default InviteManagerFormSlice.reducer;
