import { remainsRoutePath } from '@/modules/remains/routes/routes';
import { RemainsPage } from '@/modules/remains/views/remains-page/RemainsPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All remains routes.
 */
const routes: RouteObject[] = [
  {
    path: remainsRoutePath,
    element: <RemainsPage />,
  }
];

export default routes;
