import { FavoritesTable } from '@/modules/favorites/components/favorites-table/FavoritesTable';
import { resetState } from '@/modules/favorites/store';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const FavoritesPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <FavoritesTable />;
};
