import boxes from '@/modules/receipts/store/receipt-card/boxes';
import card from '@/modules/receipts/store/receipt-card/card';
import generalPositions from '@/modules/receipts/store/receipt-card/general-positions';
import history from '@/modules/receipts/store/receipt-card/history';
import notes from '@/modules/receipts/store/receipt-card/notes';
import positions from '@/modules/receipts/store/receipt-card/positions';
import scanning from '@/modules/receipts/store/receipt-card/scanning';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base receipt card reducer.
 */
const receiptCardReducer = combineReducers({
  card,
  generalPositions,
  boxes,
  positions,
  history,
  notes,
  scanning,
});

export default receiptCardReducer;
