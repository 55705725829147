import { ValidationRule } from '@/common/utils/client-validation/validation-rule';
import { toNumber } from '@/common/utils/common/number-utils';

export const required = <T>(message?: string): ValidationRule<T> => ({
  validationCallback: (value) => !!value,
  message: message ?? 'Поле не должно быть пустым',
});

export const minLength = <T>(length: number): ValidationRule<T> => ({
  validationCallback: (value) => String(value).length >= length,
  message: `Поле должно содержать не менее ${length} символов`,
});

export const maxLength = <T>(length: number): ValidationRule<T> => ({
  validationCallback: (value) => String(value).length <= length,
  message: `Поле должно содержать не более ${length} символов`,
});

const emailRegex = /^[\w\d_.-]+@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/;
export const email = (): ValidationRule<string> => ({
  validationCallback: (value) => value && emailRegex.test(value.trim()),
  message: 'Поле не соответствует шаблону email',
});

const phoneRegex = /^[+]?[0-9][\s(]{0,2}[0-9]{3}[\s)]{0,2}[0-9]{3}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}$/;
export const phone = (): ValidationRule<string> => ({
  validationCallback: (value) => value && phoneRegex.test(value.trim()),
  message: 'Поле не соответствует шаблону телефона',
});

export const passwordsEquals = (value1: string): ValidationRule<string> => ({
  validationCallback: (value2) => value1 === value2,
  message: 'Пароли не совпадают',
});

export const passwordsNotEquals = (value1: string): ValidationRule<string> => ({
  validationCallback: (value2) => value1 !== value2,
  message: 'Новый пароль должен отличаться от старого',
});

const lowerCaseRegex = /[a-z]+/;
export const lowerCase = (): ValidationRule<string> => ({
  validationCallback: (value) => value && lowerCaseRegex.test(value.trim()),
  message: 'Должны присутствовать строчные латинские буквы'
});

const upperCaseRegex = /[A-Z]+/;
export const upperCase = (): ValidationRule<string> => ({
  validationCallback: (value) => value && upperCaseRegex.test(value.trim()),
  message: 'Должны присутствовать заглавные латинские буквы'
});

const numberRegex = /\d+/;
export const number = (): ValidationRule<string> => ({
  validationCallback: (value) => value && numberRegex.test(value.trim()),
  message: 'Должны присутствовать цифры 0-9'
});

export const specialCharactersStr = '@#$%^&+=!';
const specialCharactersRegex = /[@#$%^&+=!]+/;
export const specialCharacters = (): ValidationRule<string> => ({
  validationCallback: (value) => value && specialCharactersRegex.test(value.trim()),
  message: `Должен присутствовать хотя бы 1 из спецсимволов ${specialCharactersStr}`
});

const forbiddenCharactersRegex = /^[^-_.,?()[\]<>'";:*{}/|\\]*$/;
export const forbiddenCharacters = (): ValidationRule<string> => ({
  validationCallback: (value) => value && forbiddenCharactersRegex.test(value.trim()),
  message: `Введен некорректный символ. Разрешенные символы: ${specialCharactersStr}`
});

const notCyrilicLettersRegex = /^[^А-Яа-я]*$/;
export const notCyrilicLetters = (): ValidationRule<string> => ({
  validationCallback: (value) => value && notCyrilicLettersRegex.test(value.trim()),
  message: 'Допустимы только символы латинского алфавита'
});

export const notEmptyArray = <T extends []>(): ValidationRule<T> => ({
  validationCallback: (value) => !!value?.length,
  message: 'Должен быть выбран хотя бы 1 элемент',
});

export const lessOrEquals = (oppositeValue: string | number, message?: string): ValidationRule<string> => ({
  validationCallback: (currentValue) => !oppositeValue || !currentValue || toNumber(currentValue) <= toNumber(oppositeValue),
  message: message ?? `Значение должно быть меньше или равно, чем ${oppositeValue}`,
});

export const greaterOrEquals = (oppositeValue: string | number, message?: string): ValidationRule<string> => ({
  validationCallback: (currentValue) => !oppositeValue || !currentValue || toNumber(currentValue) >= toNumber(oppositeValue),
  message: message ?? `Значение должно быть больше или равно, чем ${oppositeValue}`,
});
