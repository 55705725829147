import { disposalCardNotesModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardNotesApi } from '@/modules/disposals/store/disposal-card/notes/async-thunks';
import { createDocumentCardNotesSlice } from '@/shared/document/store/document-card/notes';

export const disposalCardNotesSlice = createDocumentCardNotesSlice({
  moduleName: disposalCardNotesModuleName,
  api: disposalCardNotesApi,
});

export const disposalCardNotesRootActions = disposalCardNotesSlice.rootSlice.actions;
export const disposalCardNotesPageableDataActions = disposalCardNotesSlice.documentNotesPageableDataSlice.actions;

export default disposalCardNotesSlice.rootSlice.reducer;
