import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { LegalEntitiesFilter } from '@/modules/references/models/legal-entities/legal-entities-filter';
import { changeFilterOpenState, legalEntitiesFilterConfigActions } from '@/modules/references/store/legal-entities';
import { LegalEntitiesSelectors } from '@/modules/references/store/legal-entities/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type LegalEntitiesTableFilteringHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useLegalEntitiesTableFiltering({ loadFirstPage }: LegalEntitiesTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData<LegalEntitiesFilter>({
    filterConfigActions: legalEntitiesFilterConfigActions,
    selectFilterConfig: LegalEntitiesSelectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
