import InfoIcon from '@/assets/icons/info.svg?react';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app-toast.scss';

export const AppToast = () => (
  <ToastContainer
    autoClose={4000}
    limit={3}
    icon={<InfoIcon className="info-icon" />}
  />
);
