import { dateFormat, dateTimeFormat } from '@/common/constants/date-formats';
import { formatDate } from '@/common/utils/common/date-utils';
import { formatCurrency } from '@/common/utils/common/formatting-utils';
import { Document } from '@/shared/document/models/document';
import { getDocumentContractTypeLabel } from '@/shared/document/models/document-contract-type';
import { getDocumentFinancingSourceLabel } from '@/shared/document/models/document-financing-source';
import { getDocumentOperationTypeLabel } from '@/shared/document/models/document-operation-type';
import { getDocumentOrderTitle } from '@/shared/document/models/document-order';
import { getDocumentStatusTitle } from '@/shared/document/models/document-status';

type DocumentInfoCardModel =
  Record<keyof Omit<Document, 'isFavorite' | 'processingWay'>, string> &
  { isFavorite: boolean; };

export const convertDocumentInfoToCardModel = (document: Document): DocumentInfoCardModel => {
  return {
    sender: document.sender ?? '',
    documentNumber: document.documentNumber ?? '',
    documentDate: document.documentDate ? formatDate(document?.documentDate, dateFormat) : '',
    departurePoint: document.departurePoint ?? '',
    operationType: getDocumentOperationTypeLabel(document.operationType),
    receiver: document.receiver ?? '',
    contractType: getDocumentContractTypeLabel(document.contractType),
    acceptancePlace: document.acceptancePlace ?? '',
    financingSource: getDocumentFinancingSourceLabel(document.financingSource),
    operationDate: document.operationDate ? formatDate(document.operationDate, dateTimeFormat) : '',
    status: getDocumentStatusTitle(document.status),
    amountWithVat: formatCurrency(document.amountWithVat ?? 0),
    vatAmount: formatCurrency(document.vatAmount ?? 0),
    comment: document.comment ?? '',
    order: getDocumentOrderTitle(document.order),
    activityPlace: document.activityPlace ?? '',
    exportCountry: document.exportCountry ?? '',
    isFavorite: document.isFavorite
  };
};
