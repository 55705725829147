export enum LegalEntityActivityPlaceType {
  ActivityPlace = 'ACTIVITY_PLACE',
  SafeStoragePlace = 'SAFE_STORAGE_PLACE'
}

const legalEntityActivityPlaceTypeTitles = {
  [LegalEntityActivityPlaceType.ActivityPlace]: 'Место деятельности',
  [LegalEntityActivityPlaceType.SafeStoragePlace]: 'Место ответственного хранения',
};

export const getLegalEntityActivityPlaceTypeTitle = (value: LegalEntityActivityPlaceType): string => legalEntityActivityPlaceTypeTitles[value];
