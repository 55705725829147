import { receiptCardModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardApi } from '@/modules/receipts/store/receipt-card/card/async-thunks';
import { DocumentType } from '@/shared/document/models/document-type';
import { createDocumentCardSlice } from '@/shared/document/store/document-card/card';

export const receiptCardSlice = createDocumentCardSlice({
  moduleName: receiptCardModuleName,
  api: receiptCardApi,
  docType: DocumentType.Receipt,
});

export const receiptCardActions = receiptCardSlice.actions;

export default receiptCardSlice.reducer;
