import LoginBackground from '@/assets/images/login/background.png';
import React, { FC } from 'react';
import './auth-layout.scss';

interface AuthLayoutProps {
  children: React.ReactNode;
  padding?: boolean;
  licenceAgreement?: boolean;
  classes?: Partial<Record<'form', string>>;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, padding, licenceAgreement, classes }) => (
  <div className="auth-layout-page">
    <img
      className="background-image"
      src={LoginBackground}
      alt="Login background"
    />
    <div className={`auth-layout-form ${padding ? 'padding' : ''} ${licenceAgreement ? 'licence-agreement' : ''} ${classes?.form ?? ''}`}>
      {children}
    </div>
  </div>
);
