import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { LoadingStatus } from '@/common/models/loading-status';
import { CertificateListDialog } from '@/modules/certificates/components/certificates-list-dialog/CertificatesListDialog';
import { removeSelectedCertificate, setLoadingStatus } from '@/modules/certificates/store';
import { useInteractionLogTableDataSource } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-data-source';
import { setIsOpenCertificatesListDialog } from '@/modules/interaction-log/store/interaction-log';
import { retryOperationInit, retryOperationSend } from '@/modules/interaction-log/store/interaction-log/async-thunks';
import { InteractionLogSelectors } from '@/modules/interaction-log/store/interaction-log/selectors';
import { SessionDescription } from '@/modules/portal/components/extend-session/components/session-description/SessionDescription';
import { useAppDispatch } from '@/store';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const InteractionLogRetryOperationDialog = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();
  const { loadFirstPage } = useInteractionLogTableDataSource();

  const isOpenCertificatesListDialog = useSelector(InteractionLogSelectors.selectIsOpenCertificatesListDialog);

  const retryOperation = () => {
    dispatch(setLoadingStatus(LoadingStatus.Pending));

    const catchCb = () => {
      dispatch(setLoadingStatus(LoadingStatus.Rejected));
      dispatch(removeSelectedCertificate());
    };

    const sendThenCb = () => {
      loadFirstPage();
      closeDialog();
    };

    const initThenCb = (signature: string) => {
      // second request if the first was successfull
      asyncDispatch(() => retryOperationSend(signature), sendThenCb, catchCb);
    };

    // first request
    asyncDispatch(retryOperationInit, initThenCb, catchCb);
  };

  const closeDialog = useCallback(() => {
    dispatch(setIsOpenCertificatesListDialog(false));
    dispatch(removeSelectedCertificate());
    dispatch(setLoadingStatus(undefined));
  }, []);

  return (
    <CertificateListDialog
      open={isOpenCertificatesListDialog}
      title="Повторная отправка документа"
      subtitle={<>Для повторной отправки документа вставьте УКЭП в USB-разъем и нажмите &quot;Обновить&quot;<br /> или выберите из списка установленных в системе сертификатов</>}
      selectCertificateTitleButton="Отправить повторно"
      onSelectCertificate={retryOperation}
      tooltipText={<SessionDescription />}
      closeDialog={closeDialog}
    />
  );
};