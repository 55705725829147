import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { AppTableTopToolbarProps } from '@/common/components/app-table/components/app-table-top-toolbar/AppTableTopToolbar';
import { AppTablePropsGetRowsActions } from '@/common/components/app-table/components/app-table/AppTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { FilterConfigActions } from '@/common/models/filter-config/filter-config-actions';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { SavedTableConfigActions } from '@/common/models/saved-table-config/saved-table-config-actions';
import { SearchingActions } from '@/common/models/searching/searching-actions';
import { RemainsFilters } from '@/shared/remains/components/remains-filters/RemainsFilters';
import { useRemainsTableEventHandlers } from '@/shared/remains/hooks/use-remains-table-event-handlers';
import { useRemainsTableFiltering } from '@/shared/remains/hooks/use-remains-table-filtering';
import { useRemainsTableSettings } from '@/shared/remains/hooks/use-remains-table-settings';
import { RemainsFilter } from '@/shared/remains/models/remains-filter';
import { RemainsActions } from '@/shared/remains/store';
import { RemainsSelectors } from '@/shared/remains/store/types';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

export interface RemainsTableProps extends PageableLoadingActions {
  topToolbar?: AppTableTopToolbarProps;
  selectors: RemainsSelectors;
  rootActions: RemainsActions;
  filterConfigActions: FilterConfigActions<RemainsFilter>;
  searchingActions: SearchingActions;
  tableConfigActions: SavedTableConfigActions;
  getRowsActions?: AppTablePropsGetRowsActions;
}

export const RemainsTable: FC<RemainsTableProps> = ({
  topToolbar,
  selectors,
  rootActions,
  filterConfigActions,
  searchingActions,
  tableConfigActions,
  loadFirstPage,
  loadNextPage,
  getRowsActions
}) => {
  const data = useSelector(selectors.selectData);
  const totalItems = useSelector(selectors.selectTotalItems);
  const isLoadingData = useSelector(selectors.selectIsLoadingData);
  const tableConfig = useSelector(selectors.selectTableConfig);
  const selectedFiltersCount = useSelector(selectors.selectSelectedFiltersCount);
  const isPanelOpen = useSelector(selectors.selectIsPanelOpen);

  const { columns, getRowId, searchPlaceholder } = useRemainsTableSettings();
  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: selectors.selectSearchState
  });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick
  } = useRemainsTableFiltering({ loadFirstPage, selectors, filterConfigActions });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRefreshClick,
    onRowSelectionChange
  } = useRemainsTableEventHandlers({ loadFirstPage, loadNextPage, tableConfigActions, searchingActions, rootActions });

  return (
    <AppCustomTable
      data={data}
      columns={columns}
      isLoading={isLoadingData}
      getRowId={getRowId}
      configState={tableConfig}
      enableRowVirtualization
      manualSorting
      enableRowSelection
      topToolbar={{
        filter: {
          showFilterButton: true,
          filterPanel: <RemainsFilters
            selectors={selectors}
            filterConfigActions={filterConfigActions}
          />,
          isPanelOpen,
          selectedFiltersCount,
          disableApplyFilterButton,
          disableClearFilterButton,
          onFilterClick,
          onApplyFiltersClick,
          onClearFiltersClick,
          ...topToolbar?.filter,
        },
        search: {
          showSearchInput: true,
          searchPlaceholder,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput,
          onApplySearchClick,
          onClearSearchClick,
          ...topToolbar?.search,
        },
        refreshButton: {
          showRefreshButton: true,
          notClickableRefreshButton: isLoadingData,
          onRefreshClick,
          ...topToolbar?.refreshButton,
        },
        exportButton: {
          ...topToolbar?.exportButton,
        }
      }}
      bottomToolbar={{
        totalItems,
      }}
      getRowActions={getRowsActions}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
      onRowSelectionChange={onRowSelectionChange}
      onScrollAtTheEnd={onScrollChange}
    />
  );
};
