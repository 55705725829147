import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDialog } from '@/common/components/app-dialog/AppDialog';
import { ColumnSettingsForm } from '@/common/components/app-table/components/column-settings-form/ColumnSettingsForm';
import { SelectOption } from '@/common/models/app-select/select-option';
import { AppTableColumnVisibilityType } from '@/common/models/app-table/app-table-types';
import React, { FC, memo, useCallback, useState } from 'react';

interface ColumnSettingsDialogProps {
  open: boolean;
  columns: SelectOption[];
  columnVisibility: AppTableColumnVisibilityType;
  onSave: (value: AppTableColumnVisibilityType) => void;
  onClose: () => void;
}

const ColumnSettingsDialogInner: FC<ColumnSettingsDialogProps> = ({ open, columns, columnVisibility, onSave, onClose }) => {
  const [columnVisibilityInner, setColumnVisibilityInner] = useState(columnVisibility);

  const onSaveClick = useCallback(() => {
    onSave(columnVisibilityInner);
  }, [columnVisibilityInner]);

  const setColumnVisibility = useCallback((value: AppTableColumnVisibilityType) => {
    setColumnVisibilityInner(value);
  }, []);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      header="Настройка столбцов таблицы"
      footer={
        <AppButton
          label="Сохранить"
          onClick={onSaveClick}
        />
      }
    >
      <ColumnSettingsForm
        columns={columns}
        columnVisibility={columnVisibility}
        columnVisibilityChanged={setColumnVisibility}
      />
    </AppDialog>
  );
};

export const ColumnSettingsDialog = memo(ColumnSettingsDialogInner);
