import { fileOperationsRoutePath } from '@/modules/file-operations/routes/routes';
import { FileOperationsPage } from '@/modules/file-operations/views/file-operations-page/FileOperationsPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All file operations routes.
 */
const routes: RouteObject[] = [
  {
    path: fileOperationsRoutePath,
    element: <FileOperationsPage />,
  }
];

export default routes;
