import { AppTabGroupProps } from '@/common/components/app-tab-group/AppTabGroup';
import { ExtractPropertyType } from '@/common/utils/common/types-utils';
import { DocumentCardBlockedInfoDialog } from '@/shared/document/components/document-card-blocked-info-dialog/DocumentCardBlockedInfoDialog';
import { DocumentCardFavoriteButton } from '@/shared/document/components/document-card-favorite-button/DocumentCardFavoriteButton';
import { DocumentCardInfo as DefaultDocumentCardInfo, DocumentCardInfoContentProps } from '@/shared/document/components/document-card-info/DocumentCardInfo';
import { DocumentCardProcessedDialog } from '@/shared/document/components/document-card-processed-dialog/DocumentCardProcessedDialog';
import { DocumentCardWarnInfoDialog } from '@/shared/document/components/document-card-warn-info-dialog/DocumentCardWarnInfoDialog';
import { DocumentCard } from '@/shared/document/components/document-card/DocumentCard';
import { useDocumentCardActionButtonHandlers } from '@/shared/document/hooks/document-card/use-document-action-buttons-handlers';
import { useDocumentCardActionButtonsRender } from '@/shared/document/hooks/document-card/use-document-card-action-buttons-render';
import { useDocumentCardBlockedInfo } from '@/shared/document/hooks/document-card/use-document-card-blocked-info';
import { useDocumentCardInfo } from '@/shared/document/hooks/document-card/use-document-card-info';
import { useDocumentCardInfoHeight } from '@/shared/document/hooks/document-card/use-document-card-info-height';
import { useDocumentCardOpening } from '@/shared/document/hooks/document-card/use-document-card-opening';
import { useDocumentCardProcessed } from '@/shared/document/hooks/document-card/use-document-card-processed-dialog';
import { useDocumentCardTabContentHeight } from '@/shared/document/hooks/document-card/use-document-card-tab-content-height';
import { useDocumentCardTabs } from '@/shared/document/hooks/document-card/use-document-card-tabs';
import { useDocumentCardWarnInfo } from '@/shared/document/hooks/document-card/use-document-card-warn-info';
import { getBaseDocumentPath } from '@/shared/document/routes/routes';
import { DocumentCardBoxesActions } from '@/shared/document/store/document-card/boxes';
import { createDocumentCardBoxesThunks } from '@/shared/document/store/document-card/boxes/async-thunks';
import { DocumentCardActions } from '@/shared/document/store/document-card/card';
import { DocumentCardSelectors, DocumentsCardApi } from '@/shared/document/store/document-card/card/types';
import { DocumentCardGeneralPositionsActions } from '@/shared/document/store/document-card/general-positions';
import { createDocumentCardGeneralPositionsThunks } from '@/shared/document/store/document-card/general-positions/async-thunks';
import { DocumentCardHistoryActions } from '@/shared/document/store/document-card/history';
import { createDocumentCardHistoryThunks } from '@/shared/document/store/document-card/history/async-thunks';
import { DocumentCardNotesActions } from '@/shared/document/store/document-card/notes';
import { createDocumentCardNotesThunks } from '@/shared/document/store/document-card/notes/async-thunks';
import { createDocumentCardPositionsThunks } from '@/shared/document/store/document-card/positions/async-thunks';
import React, { FC, ForwardRefExoticComponent, RefAttributes, memo, useMemo } from 'react';

export interface DocumentCardPageProps {
  tabs: ExtractPropertyType<AppTabGroupProps, 'tabs'>;
  documentCardSelectors: DocumentCardSelectors;
  documentCardApi: DocumentsCardApi;
  documentCardActions: DocumentCardActions;
  documentCardGeneralPositionsActions: DocumentCardGeneralPositionsActions;
  documentCardBoxesActions: DocumentCardBoxesActions;
  documentCardHistoryActions: DocumentCardHistoryActions;
  documentCardPositionsActions: DocumentCardHistoryActions;
  documentCardNotesActions: DocumentCardNotesActions;
  getGeneralPositions: ReturnType<typeof createDocumentCardGeneralPositionsThunks>['getGeneralPositions'];
  getAllPositions: ReturnType<typeof createDocumentCardPositionsThunks>['getAllPositions'];
  getAllHistory: ReturnType<typeof createDocumentCardHistoryThunks>['getAllHistory'];
  getAllBoxes: ReturnType<typeof createDocumentCardBoxesThunks>['getAllBoxes'];
  getAllNotes: ReturnType<typeof createDocumentCardNotesThunks>['getAllNotes'];
  CustomDocumentCardInfo?: ForwardRefExoticComponent<DocumentCardInfoContentProps & RefAttributes<HTMLDivElement>>;
}

const DocumentCardPageInner: FC<DocumentCardPageProps> = ({
  tabs,
  documentCardSelectors,
  documentCardApi,
  documentCardActions,
  documentCardGeneralPositionsActions,
  documentCardBoxesActions,
  documentCardPositionsActions,
  documentCardHistoryActions,
  documentCardNotesActions,
  getGeneralPositions,
  getAllPositions,
  getAllBoxes,
  getAllHistory,
  getAllNotes,
  CustomDocumentCardInfo,
}) => {
  const {
    id,
    dataFromTable,
    documentInfo,
    loadingStatusDocumentInfo,
    docType,
    getDocumentInfo
  } = useDocumentCardInfo({ documentCardSelectors, documentCardActions, documentCardApi });
  const { tabContentRef } = useDocumentCardTabContentHeight({ documentCardActions });

  const {
    documentInfoRef,
    documentCardDefaultHeight,
    documentInfoMaxHeight
  } = useDocumentCardInfoHeight();

  const {
    isCardOpened,
    toggleOpenDocumentInfo
  } = useDocumentCardOpening({ documentCardSelectors, documentCardActions });

  const { activeTab, changeActiveTab, onActiveTabClick } = useDocumentCardTabs({
    documentCardSelectors,
    documentCardActions,
    documentCardGeneralPositionsActions,
    documentCardBoxesActions,
    documentCardPositionsActions,
    documentCardHistoryActions,
    documentCardNotesActions,
    getGeneralPositions,
    getAllPositions,
    getAllBoxes,
    getAllNotes,
    getAllHistory
  });

  const {
    getActionButtonHandlerByType,
    setDocumentCardStatusAsProcessed
  } = useDocumentCardActionButtonHandlers({ documentCardSelectors, documentCardApi, documentCardActions });

  const documentCardBlockedInfoContext = useDocumentCardBlockedInfo({ documentCardSelectors, documentCardActions });

  const documentCardWarnInfoContext = useDocumentCardWarnInfo({ documentCardSelectors, documentCardActions, setDocumentCardStatusAsProcessed });

  const documentCardWarnProcessedContext = useDocumentCardProcessed({ documentCardSelectors, documentCardActions, documentCardApi });

  const backNavigationRoute = useMemo(() => {
    return getBaseDocumentPath(docType);
  }, [docType]);

  const partialDocumentInfo = useMemo(() => ({
    //TODO: fix type
    order: documentInfo?.order ?? dataFromTable?.docOrder,
    status: documentInfo?.status ?? dataFromTable?.docState,
    docType
  }), [documentInfo?.order, dataFromTable?.docOrder, documentInfo?.status, dataFromTable?.docState, docType]);

  const { documentCardActionButtons } = useDocumentCardActionButtonsRender({
    partialDocumentInfo,
    getActionButtonHandlerByType
  });

  const DocumentCardInfo = useMemo(() => CustomDocumentCardInfo ?? DefaultDocumentCardInfo, [CustomDocumentCardInfo]);

  return (
    <>
      <DocumentCard
        isOpenInfo={isCardOpened}
        loadingStatusInfo={loadingStatusDocumentInfo}
        maxHeightInfo={documentInfoMaxHeight}
        defaultHeightInfo={documentCardDefaultHeight}
        backNavigationRoute={backNavigationRoute}
        retryAction={getDocumentInfo}
        infoComponent={
          <DocumentCardInfo
            info={documentInfo}
            ref={documentInfoRef}
          />
        }
        activeTab={activeTab}
        tabs={tabs}
        actionButtons={documentCardActionButtons}
        tabContentRef={tabContentRef}
        favoriteButton={
          <DocumentCardFavoriteButton
            docUuid={id}
            isFavorite={documentInfo?.isFavorite ?? dataFromTable?.isFavorite}
            setIsFavorite={documentCardActions.setIsFavorite}
          />
        }
        toggleOpenDocumentCardInfo={toggleOpenDocumentInfo}
        onChangeTab={changeActiveTab}
        onActiveTabClick={onActiveTabClick}
      />
      <DocumentCardWarnInfoDialog {...documentCardWarnInfoContext} />
      <DocumentCardBlockedInfoDialog {...documentCardBlockedInfoContext} />
      <DocumentCardProcessedDialog {...documentCardWarnProcessedContext} />
    </>
  );
};

export const DocumentCardPage = memo(DocumentCardPageInner);
