import { getISOString } from '@/common/utils/common/date-utils';
import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { FileOperationsFilters } from '@/modules/file-operations/models/file-operations-filters';

const searchFields: (keyof FileOperation)[] = ['fileName'];

function getFileOperationsRsqlFilter(filter: Partial<FileOperationsFilters>): RsqlFilterModel<FileOperation>[] {
  return [
    {
      selector: 'fileType',
      operation: RsqlFilterOperations.Equal,
      value: filter.fileType,
    },
    {
      selector: 'fileState',
      operation: RsqlFilterOperations.Equal,
      value: filter.fileState,
    },
    {
      selector: 'operationType',
      operation: RsqlFilterOperations.Equal,
      value: filter.operationType,
    },
    {
      selector: 'operationDate',
      operation: RsqlFilterOperations.GreaterEq,
      value: getISOString(filter.operationDateFrom),
    },
    {
      selector: 'operationDate',
      operation: RsqlFilterOperations.LessEq,
      value: getISOString(filter.operationDateTo),
    },
  ];
}

function getFileOperationsSearchFilter(searchString: string): RsqlFilterModel<FileOperation>[] {
  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: searchString,
  }));
}

export function buildFileOperationsFilterExpression(filter: Partial<FileOperationsFilters>, searchString: string): string {
  const filterModel = getFileOperationsRsqlFilter(filter);
  const searchModel = getFileOperationsSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
