export const getDocumentCardModuleNames = (documentCardModuleName: string) => {
  return {
    generalPositionsModuleName: `${documentCardModuleName}/generalPositions`,
    boxesModuleName: `${documentCardModuleName}/boxes`,
    positionsModuleName: `${documentCardModuleName}/positions`,
    historyModuleName: `${documentCardModuleName}/history`,
    notesModuleName: `${documentCardModuleName}/notes`,
    scanningModuleName: `${documentCardModuleName}/scanning`,
    remainsModuleName: `${documentCardModuleName}/remains`,
  };
};
