/**
 * Class for saving and updating state about user login.
 */
export class UserAuthService {
  private static loggedInStorageKey = 'isLoggedIn';
  private static loggedInStorageValue = 'true';

  public static isLoggedIn(): boolean {
    return localStorage.getItem(this.loggedInStorageKey) === this.loggedInStorageValue;
  }

  public static setUserLoggedIn(): void {
    localStorage.setItem(this.loggedInStorageKey, this.loggedInStorageValue);
  }

  public static removeUserLoggedIn(): void {
    localStorage.removeItem(this.loggedInStorageKey);
  }
}
