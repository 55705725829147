import { dateFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { formatDate } from '@/common/utils/common/date-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { DocumentLink } from '@/shared/remains/components/document-link/DocumentLink';
import { Remain } from '@/shared/remains/models/remain';
import { RemainsTableColumns } from '@/shared/remains/models/remains-table-columns';
import { convertRemainColumnFieldToDto } from '@/shared/remains/models/remains-table-columns-converters';
import React, { useCallback, useMemo } from 'react';

export function useRemainsTableSettings() {
  const searchPlaceholder = getSearchString([Labels.sgtin, Labels.medicinalProductName, Labels.docNumber]);

  const getRowId = useCallback((row: Remain) => row.medUuid, []);

  const columns = useMemo<AppTableColumnDefType<Remain>[]>(
    () => [
      {
        id: RemainsTableColumns.Sgtin,
        header: Labels.sgtin,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.Sgtin),
      },
      {
        id: RemainsTableColumns.Gtin,
        header: Labels.gtin,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.Gtin),
      },
      {
        id: RemainsTableColumns.ActivityPlace,
        header: Labels.activityPlaceShort,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.ActivityPlace),
      },
      {
        id: RemainsTableColumns.Name,
        header: Labels.medicinalProductName,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.Name),
      },
      {
        id: RemainsTableColumns.Producer,
        header: Labels.producer,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.Producer),
      },
      {
        id: RemainsTableColumns.ProducerCountry,
        header: Labels.producerCountry,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.ProducerCountry),
      },
      {
        id: RemainsTableColumns.SeriesNumber,
        header: Labels.series,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.SeriesNumber),
      },
      {
        id: RemainsTableColumns.ExpirationDate,
        header: Labels.expirationDate,
        accessorFn: (row) => formatDate(row.expirationDate, dateFormat),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: RemainsTableColumns.Sscc,
        header: Labels.sscc,
        accessorKey: convertRemainColumnFieldToDto(RemainsTableColumns.Sscc),
      },
      {
        id: RemainsTableColumns.Document,
        header: Labels.relatedDocument,
        // if it will be enabled, then backend returns only those rows that has not empty related document - bug on backend side
        enableSorting: false,
        accessorFn: (row) => <DocumentLink
          docNumber={row.relatedDocNumber}
          docType={row.docType}
          docUuid={row.relatedDocUuid}
        />,
      },
    ], []);

  return {
    columns,
    getRowId,
    searchPlaceholder,
  };
}
