import PlusIcon from '@/assets/icons/controls/rect_plus.svg?react';
import { AppButton } from '@/common/components/app-button/AppButton';
import React, { FC, memo } from 'react';
import './disposal-remains-actions.scss';

interface ActionButtonProps {
  label: string;
  disabled: boolean;
  onClick: () => void;
}

interface DisposalRemainsActionsProps {
  addButton: ActionButtonProps;
}

const DisposalRemainsActionsInner: FC<DisposalRemainsActionsProps> = ({ addButton }) => (
  <AppButton
    {...addButton}
    variant="outlined"
    className="add-remains-button"
    startIcon={<PlusIcon />}
  />
);

export const DisposalRemainsActions = memo(DisposalRemainsActionsInner);
