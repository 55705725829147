import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { phone, required } from '@/common/utils/client-validation/validation-rules';
import { LoginData, LoginDataFields } from '@/modules/auth/models/login-data';

export function useLoginFormValidation(data: Partial<LoginData>) {

  const validationRules: FormValidationRules<LoginData, LoginDataFields> = {
    login: [required(), phone()],
    password: [required()],
  };

  return {
    ...useFormValidation({ validationRules, data }),
  };
}
