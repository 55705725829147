import { LegalEntitiesTable } from '@/modules/references/components/legal-entities-table/LegalEntitiesTable';
import { resetState } from '@/modules/references/store/legal-entities';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const LegalEntitiesPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <LegalEntitiesTable />;
};
