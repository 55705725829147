import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { AppTableColumnOrderType, AppTableColumnSizingType, AppTableColumnVisibilityType, AppTableSortingType, Updater } from '@/common/models/app-table/app-table-types';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { LegalEntitiesTableColumns } from '@/modules/references/models/legal-entities/legal-entities-table-columns';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';
import { convertFromColumnModelToUpdateLegalEntityBody } from '@/modules/references/models/legal-entities/update-legal-entity-body';
import { patchLegalEntity, setIsUpdatingInProgress, setUpdatedRowId, updateSearchString, updateTableColumnOrderState, updateTableColumnSizingState, updateTableColumnVisibilityState, updateTableSortingState } from '@/modules/references/store/legal-entities';
import { updateLegalEntity } from '@/modules/references/store/legal-entities/async-thunks';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type LegalEntitiesTableEventHandlersHookParams = PageableLoadingActions;

export function useLegalEntitiesTableEventHandlers({ loadFirstPage, loadNextPage }: LegalEntitiesTableEventHandlersHookParams) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(updateTableColumnSizingState(value));
  }, []);

  const onSearchValueInput = useCallback((search: string) => {
    dispatch(updateSearchString(search));
  }, []);

  const onApplySearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onClearSearchClick = useCallback(() => {
    loadFirstPage();
  }, []);

  const onEditingRowSave: AppTableProps<LegalEntity, LegalEntitiesTableColumns>['onEditingRowSave'] = ({ exitEditingMode, row, values }) => {
    dispatch(setUpdatedRowId(row.id));

    const { partnerUuid } = row.original;
    const updateEntity = convertFromColumnModelToUpdateLegalEntityBody(values);

    asyncDispatch(
      () => updateLegalEntity({ partnerUuid, body: updateEntity }),
      () => {
        dispatch(patchLegalEntity({ item: updateEntity, index: row.index }));
        exitEditingMode();
      },
      undefined,
      () => {
        // needs for delay for exitEditingMode, in order to stop loader only after edit mode will be closed.
        setTimeout(() => {
          dispatch(setUpdatedRowId(''));
          dispatch(setIsUpdatingInProgress(false));
        }, 200);
      }
    );
  };

  const onScrollChange = useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onEditingRowSave,
    onScrollChange
  };
}
