import { AppInput } from '@/common/components/app-input/AppInput';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import { documentCardRejectReasonMaxLength } from '@/common/utils/client-validation/string-lengths';
import { FormValidationResult } from '@/common/utils/client-validation/validation-result';
import { CertificateListDialog } from '@/modules/certificates/components/certificates-list-dialog/CertificatesListDialog';
import { DocumentCardProcessedDialogDataFields } from '@/shared/document/models/document-card/document-card-processed-dialog-validation-data';
import React, { FC, ReactNode, memo } from 'react';
import './document-card-processed-dialog.scss';

export interface DocumentCardProcessedDialogProps {
  isDialogOpened: boolean;
  rejectReason: string;
  validationResult: FormValidationResult<DocumentCardProcessedDialogDataFields>;
  title: string;
  subtitle: ReactNode;
  isAcceptingDocument: boolean;
  onSendDocumentToMdlp: () => void;
  onRejectReasonInput: (value: string) => void;
  closeDialog: () => void;
}

const DocumentCardProcessedDialogInner: FC<DocumentCardProcessedDialogProps> = ({
  isDialogOpened,
  rejectReason,
  validationResult,
  title,
  subtitle,
  isAcceptingDocument,
  onSendDocumentToMdlp,
  onRejectReasonInput,
  closeDialog
}) => (
  <CertificateListDialog
    open={isDialogOpened}
    title={title}
    subtitle={subtitle}
    selectCertificateTitleButton="Подписать"
    bottomContent={
      !isAcceptingDocument && <AppInput
        label="Причина отказа от ЛП"
        type={AppInputType.Textarea}
        value={rejectReason}
        maxRows={3}
        className="document-card-processed-dialog-reject-reason-input"
        maxLength={documentCardRejectReasonMaxLength}
        error={validationResult.rejectReason?.error}
        helperText={validationResult.rejectReason?.messages?.[0]}
        onInput={onRejectReasonInput}
      />
    }
    closeDialog={closeDialog}
    onSelectCertificate={onSendDocumentToMdlp}
  />
);

export const DocumentCardProcessedDialog = memo(DocumentCardProcessedDialogInner);