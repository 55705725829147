import { buildModelValueGetter } from '@/common/utils/app-table/app-table-utils';
import { LegalEntitiesTableColumns } from '@/modules/references/models/legal-entities/legal-entities-table-columns';
import { convertLegalEntityDtoFieldToColumn } from '@/modules/references/models/legal-entities/legal-entities-table-columns-converters';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';

export type UpdateLegalEntityBody = Pick<LegalEntity, 'shortOrganizationName' | 'description' | 'clientIdCRM'>;

export function convertFromColumnModelToUpdateLegalEntityBody(model: Record<LegalEntitiesTableColumns, any>): UpdateLegalEntityBody {
  const getData = buildModelValueGetter<LegalEntitiesTableColumns, LegalEntity>(convertLegalEntityDtoFieldToColumn, model);

  return {
    clientIdCRM: getData('clientIdCRM')?.trim(),
    shortOrganizationName: getData('shortOrganizationName')?.trim(),
    description: getData('description')?.trim(),
  };
}
