import { saleCardGeneralPositionsModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardGeneralPositionsApi } from '@/modules/sale/store/sale-card/general-positions/async-thunks';
import { createDocumentCardGeneralPositionsSlice } from '@/shared/document/store/document-card/general-positions';

export const saleCardGeneralPositionsSlice = createDocumentCardGeneralPositionsSlice({
  moduleName: saleCardGeneralPositionsModuleName,
  savedTableConfigName: 'sale-card-general-positions-table',
  api: saleCardGeneralPositionsApi,
});

export const saleCardGeneralPositionsRootActions = saleCardGeneralPositionsSlice.rootSlice.actions;
export const saleCardGeneralPositionsTableConfigActions = saleCardGeneralPositionsSlice.documentCardPositionsTableConfigSlice.actions;

export default saleCardGeneralPositionsSlice.rootSlice.reducer;
