import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectNomenclatureState = (state: RootState) => state.references.nomenclature;

const selectIsUploadingInProcess = (state: RootState) => selectNomenclatureState(state).isUploadingInProcess;

const selectSearchState = (state: RootState) => selectNomenclatureState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectFilterConfig = (state: RootState) => selectNomenclatureState(state).filterConfig;
const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);
const selectFilterCode = (state: RootState) => selectFilters(state).code;
const selectFilterProducer = (state: RootState) => selectFilters(state).producer;
const selectFilterCountry = (state: RootState) => selectFilters(state).country;
const selectFilterBarcode = (state: RootState) => selectFilters(state).barcode;
const selectFilterVital = (state: RootState) => selectFilters(state).vital;
const selectFilterGtin = (state: RootState) => selectFilters(state).gtin;
const selectFilterIsGtinFound = (state: RootState) => selectFilters(state).isGtinFound;

const selectTableConfig = (state: RootState) => selectNomenclatureState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectPageableDataState = (state: RootState) => selectNomenclatureState(state).pageableData;
const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectIsLoadingData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

export const NomenclatureSelectors = {
  selectIsUploadingInProcess,
  selectTableConfig,
  selectTableSorting,
  selectPageableDataState,
  selectData,
  selectIsLoadingData,
  selectSearchString,
  selectSearchState,
  selectFilterConfig,
  selectIsPanelOpen,
  selectFilters,
  selectSelectedFiltersCount,
  selectFilterCode,
  selectFilterProducer,
  selectFilterCountry,
  selectFilterBarcode,
  selectFilterVital,
  selectFilterGtin,
  selectFilterIsGtinFound,
};
