import CloseIcon from '@/assets/icons/controls/cross.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { Popover } from '@mui/material';
import React, { FC, MouseEvent, ReactNode, memo, useCallback, useRef, useState } from 'react';
import './app-popover.scss';

interface AppPopoverProps {
  buttonElement: ReactNode;
  contentElement: ReactNode;
  className?: string;
  open?: boolean;
  onChangeOpen?: (isOpen: boolean) => void;
}

const AppPopoverInner: FC<AppPopoverProps> = ({ contentElement, buttonElement, className, open, onChangeOpen }) => {

  const buttonRef = useRef<HTMLDivElement>();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const onOpen = useCallback((e: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
    onChangeOpen?.(true);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
    onChangeOpen?.(false);
  }, []);

  return (
    <>
      <div
        ref={buttonRef}
        onClick={onOpen}
      >
        {buttonElement}
      </div>
      <Popover
        open={open ?? !!anchorEl}
        anchorEl={anchorEl}
        elevation={0}
        className={`app-popover ${className ?? ''}`}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <AppIconButton
          icon={<CloseIcon />}
          className="app-popover-close-icon"
          edge="end"
          onClick={onClose}
        />
        {contentElement}
      </Popover>
    </>
  );
};

export const AppPopover = memo(AppPopoverInner);
