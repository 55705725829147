import { DocumentCardDeletePositionBody } from '@/shared/document/models/document-card/positions/document-card-delete-position-body';
import { DocumentCardPosition } from '@/shared/document/models/document-card/positions/document-card-position';
import { DocumentCardPositionType } from '@/shared/document/models/document-card/positions/document-card-position-type';
import { DocumentCardPositionsRequests } from '@/shared/document/requests/document-card-positions-requests';
import { DocumentCardPositionsSelectors } from '@/shared/document/store/document-card/positions/types';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardPositionsThunksCreationParams {
  moduleName: string;
  documentCardPositionsSelectors: DocumentCardPositionsSelectors;
}

export const createDocumentCardPositionsThunks = ({ moduleName, documentCardPositionsSelectors }: DocumentCardPositionsThunksCreationParams) => {
  const getAllPositions = createAsyncThunkWrapper<
    DocumentCardPosition[],
    string
  >(`${moduleName}/getAllPositions`,
    (documentId, { signal }) => DocumentCardPositionsRequests.getAllPositions(documentId, DocumentCardPositionType.SeparateSgtinBox, signal)
      .then((res) => res.data.positions),
    {
      condition: asyncThunkConditionOption((state, documentId) => {
        const isLoading = documentCardPositionsSelectors.selectIsLoadingPositions(state);
        return isLoading || !documentId;
      })
    }
  );

  const deletePosition = createAsyncThunkWrapper<
    void,
    DocumentCardDeletePositionBody
  >(`${moduleName}/deletePosition`,
    async (body) => {
      await DocumentCardPositionsRequests.deletePosition(body);
    },
  );

  return {
    getAllPositions,
    deletePosition,
  };
};
