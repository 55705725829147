import { AppLink } from '@/common/components/app-link/AppLink';
import { DocumentType } from '@/shared/document/models/document-type';
import { getDocumentCardRoutePathByDocType } from '@/shared/document/routes/routes';
import React, { FC, memo, useMemo } from 'react';
import './document-link.scss';

interface DocumentLinkProps {
  docNumber: string;
  docType: DocumentType;
  docUuid: string;
}

const DocumentLinkInner: FC<DocumentLinkProps> = ({ docNumber, docType, docUuid }) => {
  const navigatePath = useMemo(() => getDocumentCardRoutePathByDocType({ docType, docUuid }), [docType, docUuid]);

  return <AppLink
    classes={{ container: 'document-link-container', font: 'document-link-font' }}
    text={docNumber}
    href={navigatePath}
  />;
};

export const DocumentLink = memo(DocumentLinkInner);
