import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

const selectPortalHeaderState = (state: RootState) => state.portal.header;

const selectFirstName = (state: RootState) => selectPortalHeaderState(state).firstName;
const selectLastName = (state: RootState) => selectPortalHeaderState(state).lastName;
const selectOrganizationName = (state: RootState) => selectPortalHeaderState(state).organizationName;
const selectInn = (state: RootState) => selectPortalHeaderState(state).inn;
const selectLoadingStatus = (state: RootState) => selectPortalHeaderState(state).loadingStatus;
const selectIsTokenExpired = (state: RootState) => selectPortalHeaderState(state).isTokenExpired;
const selectTokenExpiresAt = (state: RootState) => selectPortalHeaderState(state).tokenExpiresAt;
const selectIsOpenCertificatesListDialog = (state: RootState) => selectPortalHeaderState(state).isOpenCertificatesListDialog;

const selectTokenExpiresAtDate = createSelector([selectTokenExpiresAt], (token) => token ? parseISO(token) : null);

export const PortalHeaderSelectors = {
  selectFirstName,
  selectLastName,
  selectOrganizationName,
  selectInn,
  selectLoadingStatus,
  selectIsTokenExpired,
  selectIsOpenCertificatesListDialog,
  selectTokenExpiresAtDate,
};
