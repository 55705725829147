import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { LegalEntitiesActivityPlacesDialog } from '@/modules/references/components/legal-entities-activity-places-dialog/LegalEntitiesActivityPlacesDialog';
import { LegalEntitiesFilters } from '@/modules/references/components/legal-entities-filters/LegalEntitiesFilters';
import { useLegalEntitiesTableDataSource } from '@/modules/references/hooks/legal-entities/use-legal-entities-table-data-source';
import { useLegalEntitiesTableEventHandlers } from '@/modules/references/hooks/legal-entities/use-legal-entities-table-event-handlers';
import { useLegalEntitiesTableFiltering } from '@/modules/references/hooks/legal-entities/use-legal-entities-table-filtering';
import { useLegalEntitiesTableSettings } from '@/modules/references/hooks/legal-entities/use-legal-entities-table-settings';
import { LegalEntitiesSelectors } from '@/modules/references/store/legal-entities/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const LegalEntitiesTable = () => {
  const legalEntities = useSelector(LegalEntitiesSelectors.selectLegalEntities);
  const isLoadingLegalEntities = useSelector(LegalEntitiesSelectors.selectIsLoadingLegalEntities);
  const isUpdatingInProcess = useSelector(LegalEntitiesSelectors.selectIsUpdatingInProcess);
  const updatedRowId = useSelector(LegalEntitiesSelectors.selectUpdatedRowId);
  const tableConfig = useSelector(LegalEntitiesSelectors.selectTableConfig);
  const selectedFiltersCount = useSelector(LegalEntitiesSelectors.selectSelectedFiltersCount);
  const isPanelOpen = useSelector(LegalEntitiesSelectors.selectIsPanelOpen);

  const { columns, searchPlaceholder } = useLegalEntitiesTableSettings();
  const { loadFirstPage, loadNextPage } = useLegalEntitiesTableDataSource();
  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: LegalEntitiesSelectors.selectSearchState
  });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onEditingRowSave,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
  } = useLegalEntitiesTableEventHandlers({ loadFirstPage, loadNextPage });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick,
  } = useLegalEntitiesTableFiltering({ loadFirstPage });

  return (
    <>
      <AppCustomTable
        data={legalEntities}
        columns={columns}
        isLoading={isLoadingLegalEntities}
        isUpdatingInProcess={isUpdatingInProcess}
        updatedRowId={updatedRowId}
        configState={tableConfig}
        enableRowVirtualization
        enableEditing
        manualSorting
        editingMode="row"
        topToolbar={{
          filter: {
            showFilterButton: true,
            filterPanel: <LegalEntitiesFilters />,
            isPanelOpen,
            selectedFiltersCount,
            disableApplyFilterButton,
            disableClearFilterButton,
            onFilterClick,
            onApplyFiltersClick,
            onClearFiltersClick,
          },
          search: {
            showSearchInput: true,
            searchPlaceholder,
            disableApplySearchButton: isSearchNotChanged,
            onSearchValueInput,
            onApplySearchClick,
            onClearSearchClick,
          }
        }}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onSortingChange={onSortingChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnSizingChange={onColumnSizingChange}
        onEditingRowSave={onEditingRowSave}
        onScrollAtTheEnd={onScrollChange}
      />
      <LegalEntitiesActivityPlacesDialog />
    </>
  );
};
