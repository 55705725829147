import { AppTable } from '@/common/components/app-table/components/app-table/AppTable';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useAsyncDispatchAborting } from '@/common/hooks/use-async-dispatch-aborting';
import { SavedTableConfigActions } from '@/common/models/saved-table-config/saved-table-config-actions';
import { useDocumentCardHistoryTableEventHandlers } from '@/shared/document/hooks/document-card-history/use-document-card-history-table-event-handlers';
import { useDocumentCardHistoryTableSettings } from '@/shared/document/hooks/document-card-history/use-document-card-history-table-settings';
import { DocumentCardHistorySelectors, DocumentsCardHistoryApi } from '@/shared/document/store/document-card/history/types';
import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export interface DocumentCardHistoryTableProps {
  selectors: DocumentCardHistorySelectors;
  api: DocumentsCardHistoryApi;
  tableConfigActions: SavedTableConfigActions;
  maxTableHeight: number;
}

const DocumentCardHistoryTableInner: FC<DocumentCardHistoryTableProps> = ({ selectors, api, tableConfigActions, maxTableHeight }) => {
  const { asyncDispatch } = useAsyncDispatch();
  const { id: documentId } = useParams();
  const { abortRequestByParam } = useAsyncDispatchAborting();

  const boxes = useSelector(selectors.selectHistory);
  const isLoading = useSelector(selectors.selectIsLoadingHistory);
  const tableConfig = useSelector(selectors.selectTableConfig);

  const { columns } = useDocumentCardHistoryTableSettings();

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
  } = useDocumentCardHistoryTableEventHandlers({ tableConfigActions });

  useEffect(() => {
    const dispatchResult = asyncDispatch(() => api.getAllHistory(documentId));
    return () => { abortRequestByParam(dispatchResult); };
  }, [documentId]);

  return (
    <AppTable
      data={boxes}
      columns={columns}
      isLoading={isLoading}
      configState={tableConfig}
      maxHeight={`${maxTableHeight}px`}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
    />
  );
};

export const DocumentCardHistoryTable = memo(DocumentCardHistoryTableInner);
