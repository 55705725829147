import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { ColumnSettingsButton } from '@/common/components/app-table/components/column-settings-button/ColumnSettingsButton';
import { AppTableStyles } from '@/common/components/app-table/hooks/use-app-table-styles';
import {
  AppTableActionColumnName,
  AppTableFixedCellWidthClassName,
  AppTableSelectColumnName,
  AppTableSelectColumnSize
} from '@/common/constants/app-table-constants';
import { AppTableCellType } from '@/common/models/app-table/app-table-types';
import React, { useCallback, useMemo } from 'react';

type AppTableMuiDefOptionsParams = Pick<AppTableProps, 'columns' | 'configState' | 'classes' | 'onColumnVisibilityChange'>
  & Pick<AppTableStyles, 'actionColumnSize' | 'hideRowActionsClassName' | 'disabledCellClassName'>;

export function useAppTableMuiDefOptions({
  columns, configState, classes, onColumnVisibilityChange,
  actionColumnSize, hideRowActionsClassName, disabledCellClassName
}: AppTableMuiDefOptionsParams) {
  const columnSelectOptions = useMemo(() => {
    const options = [];

    for (let columnId of configState.columnOrder) {
      const column = columns.find((current) => current.id === columnId);
      if (!column || column.showInSettings === false) {
        continue;
      }
      options.push({ value: column.id, label: column.header || column?.headerSettingsName });
    }

    return options;
  }, [columns, configState.columnOrder]);

  const muiTableBodyCellEditTextFieldProps = useCallback(({ cell }: { cell: AppTableCellType; }) => ({
    defaultValue: cell.getValue() ?? '',
    value: undefined,
    className: 'app-table-cell-input',
  }), []);

  const actionColumnDefOptions = useMemo(() => ({
    [AppTableActionColumnName]: {
      Header: () =>
        <ColumnSettingsButton
          columns={columnSelectOptions}
          columnVisibility={configState.columnVisibility}
          columnVisibilityChanged={onColumnVisibilityChange}
        />,
      size: actionColumnSize,
      muiTableHeadCellProps: {
        align: 'center',
        className: AppTableFixedCellWidthClassName,
      },
      muiTableBodyCellProps: ({ row, cell }) => ({
        align: 'center',
        className: `${classes?.cell({ row, cell }) ?? ''} ${AppTableFixedCellWidthClassName} ${hideRowActionsClassName(row)} ${disabledCellClassName(row, cell)}`
      })
    },
  }), [columnSelectOptions, configState.columnVisibility, disabledCellClassName]);

  const selectColumnDefOptions = useMemo(() => ({
    [AppTableSelectColumnName]: {
      Header: () => '',
      size: AppTableSelectColumnSize,
      muiTableHeadCellProps: {
        align: 'center',
        className: AppTableFixedCellWidthClassName,
      },
      muiTableBodyCellProps: ({ row, cell }) => ({
        align: 'center',
        className: `${classes?.cell({ row, cell }) ?? ''} ${AppTableFixedCellWidthClassName}`
      })
    }
  }), []);

  return {
    muiTableBodyCellEditTextFieldProps,
    actionColumnDefOptions,
    selectColumnDefOptions,
  };
}
