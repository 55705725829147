export enum DocumentOrder {
  Direct = 'DIRECT',
  Reverse = 'REVERSE',
}

const documentOrdersTitles = {
  [DocumentOrder.Direct]: 'Прямой',
  [DocumentOrder.Reverse]: 'Обратный',
};

export const getDocumentOrderTitle = (key: DocumentOrder): string => documentOrdersTitles[key];

export const getDocumentOrdersOptions = () => Object.entries(documentOrdersTitles).map(([key, value]) => ({
  label: value,
  value: key,
}));
