import { disposalCardRemainsModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardRemainsApi } from '@/modules/disposals/store/disposal-card/remains/table/async-thunks';
import { createRemainsSlice } from '@/shared/remains/store';

const disposalCardRemainsSlice = createRemainsSlice({
  moduleName: disposalCardRemainsModuleName,
  savedTableConfigName: 'disposal-card-remains-table',
  api: disposalCardRemainsApi,
});

export const disposalCardRemainsRootActions = disposalCardRemainsSlice.rootSlice.actions;
export const disposalCardRemainsSearchingActions = disposalCardRemainsSlice.remainsSearchingSlice.actions;
export const disposalCardRemainsTableConfigActions = disposalCardRemainsSlice.remainsTableConfigSlice.actions;
export const disposalCardRemainsFilterConfigActions = disposalCardRemainsSlice.remainsFilterConfigSlice.actions;
export const disposalCardRemainsPageableDataActions = disposalCardRemainsSlice.remainsPageableDataSlice.actions;

export default disposalCardRemainsSlice.rootSlice.reducer;
