import { receiptCardBoxesModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardBoxesSelectors } from '@/modules/receipts/store/receipt-card/boxes/selectors';
import { receiptCardSelectors } from '@/modules/receipts/store/receipt-card/card/selectors';
import { createDocumentCardBoxesThunks } from '@/shared/document/store/document-card/boxes/async-thunks';

export const receiptCardBoxesApi = createDocumentCardBoxesThunks({
  moduleName: receiptCardBoxesModuleName,
  documentCardSelectors: receiptCardSelectors,
  documentCardBoxesSelectors: receiptCardBoxesSelectors,
});
