import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { MenuItem } from '@mui/material';
import React, { FC, memo } from 'react';

export interface AppMenuItemProps {
  title: string;
  disabled?: boolean;
  showLoader?: boolean;
  action: () => void | AsyncDispatchResult;
}

const AppMenuItemInner: FC<AppMenuItemProps> = ({ title, disabled, showLoader, action }) => {

  const hasLoader = showLoader !== undefined;

  return (
    <MenuItem
      className={`app-select-menu-item ${disabled ? 'disabled' : ''} ${hasLoader ? 'has-loader' : ''}`}
      onClick={action}
    >
      {title}
      {showLoader && <AppLoader className="app-select-menu-item-loader" />}
    </MenuItem>
  );
};

export const AppMenuItem = memo(AppMenuItemInner);