import { FilterConfigActionType } from '@/common/models/filter-config/filter-config-action-type';
import { FilterConfigCaseReducers } from '@/common/models/filter-config/filter-config-case-reducers';
import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { getCountOfNonEmptyFields } from '@/common/utils/common/object-utils';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

const getInitialState = <T>(): FilterConfigState<T> => ({
  isPanelOpen: false,
  filters: {},
  appliedFilters: {},
  isFiltersChanged: false,
  isDataFiltered: false,
  actionType: undefined,
});

export const createFilterConfigSlice = <T>(name: string) => {

  const commitStates = (state: Draft<FilterConfigState<T>>) => {
    state.isFiltersChanged = false;
    state.isDataFiltered = !!getCountOfNonEmptyFields(state.appliedFilters as Partial<T>);
  };
  return createSlice<FilterConfigState<T>, FilterConfigCaseReducers<T>>({
    name: `${name}/tableFilters`,
    initialState: getInitialState<T>(),
    reducers: {
      updateFilter(state, action) {
        if (!state.isFiltersChanged) {
          state.isFiltersChanged = true;
        }
        state.filters = {
          //@ts-ignore
          ...state.filters,
          ...action.payload,
        };
        // Case if user sets filters (not applied) and clears ones, then Apply button won't be active
        if (!getCountOfNonEmptyFields(state.filters as Partial<T>) && !state.isDataFiltered) {
          state.isFiltersChanged = false;
        }
      },
      clearFilter(state) {
        state.filters = {} as Draft<Partial<T>>;
        state.appliedFilters = {} as Draft<Partial<T>>;
      },
      resetIsFiltersChanged(state) {
        state.isFiltersChanged = false;
      },
      updateStateAfterGettingData(state) {
        state.appliedFilters = {
          //@ts-ignore
          ...state.appliedFilters,
          //@ts-ignore
          ...state.filters,
        };
        if (state.actionType === FilterConfigActionType.Apply) {
          commitStates(state);
        } else {
          state.isFiltersChanged = false;
          state.isDataFiltered = false;
        }
        state.actionType = undefined;
      },
      setActionType(state, action: PayloadAction<FilterConfigActionType>) {
        state.actionType = action.payload;
      },
      changeFilterOpenState(state) {
        state.isPanelOpen = !state.isPanelOpen;
        // when user opens panel, filters are reset and values become appliedFilters, Apply button become disabled and Clear button is active if user applied any filters (getCountOfNonEmptyFields)
        if (state.isPanelOpen) {
          state.filters = state.appliedFilters;
          commitStates(state);
        }
      }
    }
  });
};
