import { useContentOverflowCheck } from '@/common/hooks/use-content-overflow-check';
import Tooltip from '@mui/material/Tooltip';
import React, { AllHTMLAttributes, FC, ReactNode, memo, useCallback, useMemo, useRef, useState, } from 'react';

interface AppTooltipProps {
  children: React.ReactNode;
  text?: string | ReactNode;
  open?: boolean | 'onlyOnOverflow';
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
}

const AppTooltipInner: FC<AppTooltipProps> = ({
  children,
  text,
  open,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener
}) => {
  const contentRef = useRef<HTMLElement>();

  const childrenWithRef = React.cloneElement(
    React.Children.only(children),
    { ref: el => contentRef.current = el } as AllHTMLAttributes<HTMLElement>
  );

  const [openInner, setOpenInner] = useState(false);

  const onOpen = useCallback(() => {
    setOpenInner(true);
  }, []);

  const onClose = useCallback(() => {
    setOpenInner(false);
  }, []);

  const { hasOverflow } = useContentOverflowCheck({ contentRef });

  const showTooltip = useMemo(() => {
    if (open !== undefined && typeof open === 'boolean') {
      return open;
    }

    if (open === 'onlyOnOverflow') {
      return hasOverflow && openInner;
    }

    return undefined;
  }, [open, hasOverflow, openInner]);

  return <Tooltip
    title={text}
    open={showTooltip}
    disableFocusListener={disableFocusListener}
    disableHoverListener={disableHoverListener}
    disableTouchListener={disableTouchListener}
    onOpen={onOpen}
    onClose={onClose}
  >
    {childrenWithRef}
  </Tooltip>;
};

export const AppTooltip = memo(AppTooltipInner);
