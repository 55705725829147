import { AppInput } from '@/common/components/app-input/AppInput';
import { AppInputType } from '@/common/models/app-input/app-input-type';
import { DocumentCardInfoContainer } from '@/shared/document/components/document-card-info-container/DocumentCardInfoContainer';
import { Document } from '@/shared/document/models/document';
import { convertDocumentInfoToCardModel } from '@/shared/document/utils/convert-document-info';
import React, { forwardRef } from 'react';
import './disposal-card-info.scss';

interface DisposalCardInfoContentProps {
  info: Document;
}

export const DisposalCardInfo = forwardRef<HTMLDivElement, DisposalCardInfoContentProps>(
  ({ info }, ref) => {

    const documentInfo = convertDocumentInfoToCardModel(info);

    return (
      <DocumentCardInfoContainer
        className="disposal-card-info"
        ref={ref}
      >
        <AppInput
          label="Тип вывода из обращения"
          value={documentInfo?.operationType}
          className="operation-type"
          readOnly
        />
        <AppInput
          label="Номер документа"
          value={documentInfo?.documentNumber}
          className="document-number"
          readOnly
        />
        <AppInput
          label="Дата документа"
          value={documentInfo?.documentDate}
          className="document-date"
          readOnly
        />
        <AppInput
          label="Место деятельности"
          value={documentInfo?.activityPlace}
          className="activity-place"
          readOnly
        />
        <AppInput
          label="Дата операции"
          value={documentInfo?.operationDate}
          className="operaton-date"
          readOnly
        />
        <AppInput
          label="Статус"
          value={documentInfo?.status}
          className="status"
          readOnly
        />
        <AppInput
          label="Комментарий"
          value={documentInfo?.comment}
          type={AppInputType.Textarea}
          className="comment"
          readOnly
        />
        <AppInput
          label="Код страны экспорта"
          value={documentInfo?.exportCountry}
          className="export-country"
          readOnly
        />
      </DocumentCardInfoContainer>
    );
  });

DisposalCardInfo.displayName = 'DisposalCardInfo';

