import React, { FC, ReactNode, memo } from 'react';
import './app-list.scss';

interface AppListProps {
  // if ReactNode is used as an element, you must set a key attribute for the ReactNode
  items: ReactNode[];
  classNames?: Partial<Record<'list' | 'item', string>>;
}

const AppListInner: FC<AppListProps> = ({ items, classNames }) => {
  return (
    <ul className={`app-list ${classNames?.list ?? ''}`}>
      {items?.map((item) =>
        <li
          key={typeof item === 'object' ? item?.key : item}
          className={`app-list-item ${classNames?.item ?? ''}`}
        >
          {item}
        </li>
      )}
    </ul>
  );
};

export const AppList = memo(AppListInner);