import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { OrganizationPlacesInfoCard } from '@/modules/organization-profile/components/ogranization-places-info-card/OrganizationPlacesInfoCard';
import { OrganizationInfoPageLayout } from '@/modules/organization-profile/components/organixation-info-page-layout/OrganizationInfoPageLayout';
import { OrganizationInfoCard } from '@/modules/organization-profile/components/organization-info-card/OrganizationInfoCard';
import { OrganizationOwnerCard } from '@/modules/organization-profile/components/organization-owner-card/OrganizationOwnerCard';
import { resetState } from '@/modules/organization-profile/store/organization-info';
import { getOrganizationInfo } from '@/modules/organization-profile/store/organization-info/async-thunks';
import { OrganizationProfileInfoSelectors } from '@/modules/organization-profile/store/organization-info/selectors';
import { LoaderWrapperPage } from '@/modules/portal/components/loader-wrappers/loader-wrapper-page/LoaderWrapperPage';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './organization-info-page.scss';

export const OrganizationInfoPage = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  useEffect(() => {
    // organizationInfo doesn't change often, so we don't need to update it every page's render. We get data on the first load page only
    if (!organizationInfo) {
      asyncDispatch(getOrganizationInfo);
    }
    return () => { dispatch(resetState()); };
  }, []);

  const loadingStatus = useSelector(OrganizationProfileInfoSelectors.selectLoadingStatus);
  const organizationInfo = useSelector(OrganizationProfileInfoSelectors.selectOrganizationInfo);
  const organizationOwner = useSelector(OrganizationProfileInfoSelectors.selectOrganizationOwner);

  return (
    <LoaderWrapperPage
      loadingStatus={loadingStatus}
      retryAction={getOrganizationInfo}
    >
      <OrganizationInfoPageLayout className="organization-info-page-container">
        {organizationInfo && <OrganizationInfoCard model={organizationInfo} />}
        {organizationOwner &&
          <>
            <div className="organization-owner-title">Руководитель</div>
            <OrganizationOwnerCard model={organizationOwner} />
          </>
        }
        {organizationInfo?.activityPlacesCount &&
          <OrganizationPlacesInfoCard placesCount={organizationInfo.activityPlacesCount} />
        }
      </OrganizationInfoPageLayout>
    </LoaderWrapperPage>
  );
};
