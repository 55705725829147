import { AppTableRowActionMenuItemModel } from '@/common/models/app-table/app-table-row-action-menu-item-model';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import { InteractionLogTableActions } from '@/modules/interaction-log/hooks/interaction-log-table/use-interaction-log-table-actions';
import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { InteractionStatus } from '@/modules/interaction-log/models/interaction-status';
import { useCallback, useMemo } from 'react';

type InteractionLogTableRowActionsHookParams = InteractionLogTableActions;

export function useInteractionLogTableRowActions(
  { isFileLoading, isTicketLoading, downloadFile, downloadTicket, retryOperation }: InteractionLogTableRowActionsHookParams
) {
  const downloadFileActionItem: AppTableRowActionMenuItemModel<InteractionItem> = useMemo(() => ({
    key: 'download-file',
    title: 'Скачать XML операции',
    showLoader: isFileLoading,
    disabled: isFileLoading,
    action: (row) => downloadFile(row.original.docFileUuid),
  }), [isFileLoading]);

  const downloadTicketActionItem: AppTableRowActionMenuItemModel<InteractionItem> = useMemo(() => ({
    key: 'download-ticket',
    title: 'Скачать квитанцию',
    showLoader: isTicketLoading,
    disabled: isTicketLoading,
    action: (row) => downloadTicket(row.original.ticketFileUuid),
  }), [isTicketLoading]);

  const retryOperationActionItem: AppTableRowActionMenuItemModel<InteractionItem> = useMemo(() => ({
    key: 'retry-operation',
    title: 'Повторить операцию',
    action: (row) => retryOperation(row.original.eventUuid),
  }), []);

  const getRowActions = useCallback((row: AppTableRowType<InteractionItem>) => {
    const items: AppTableRowActionMenuItemModel<InteractionItem>[] = [];
    const canUserRetryOperation = row.original.eventState === InteractionStatus.FailedToSendToMdlp;

    if (canUserRetryOperation) {
      items.push(retryOperationActionItem);
    }
    if (row.original.docFileUuid) {
      items.push(downloadFileActionItem);
    }
    if (row.original.ticketFileUuid) {
      items.push(downloadTicketActionItem);
    }

    return items;
  }, [downloadFileActionItem, downloadTicketActionItem]);

  return {
    getRowActions,
  };
}
