import { ErrorModel } from '@/common/models/error-model';
import { ContactType } from '@/modules/auth/models/contact-type';
import { Operation } from '@/modules/auth/models/verification-code-send/operation';
import { VerificationCodeSendBody } from '@/modules/auth/models/verification-code-send/verification-code-send-body';
import { AuthRequests } from '@/modules/auth/requests/auth-requests';
import { saveLoginToStorage, updateExpireVerificationCode, updateLoadingState, updateSignInCode } from '@/modules/auth/store/login/index';
import { LoginSelectors } from '@/modules/auth/store/login/selectors';
import { RejectWithValue } from '@/store';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';
import { AxiosError } from 'axios';

export const signInInit = createAsyncThunkWrapper<
  void | RejectWithValue,
  undefined
>(
  'login/signin/init',
  (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const login = LoginSelectors.selectPreprocessedLogin(state);
    const password = LoginSelectors.selectPassword(state);

    dispatch(updateLoadingState(true));
    return AuthRequests.signinInit({ login, password })
      .then((res) => {
        dispatch(updateExpireVerificationCode(res.data.nextCodeResend));
        dispatch(updateSignInCode(res.data.signinCode));
        dispatch(saveLoginToStorage());
        dispatch(updateLoadingState(false));
      })
      .catch((err: AxiosError<ErrorModel>) => {
        dispatch(updateLoadingState(false));
        return rejectWithValue(err.response.data);
      });
  }
);

export const sendSignInVerificationCode = createAsyncThunkWrapper<
  void | RejectWithValue,
  undefined
>(
  'login/sendVerificationCode',
  (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const contact = LoginSelectors.selectPreprocessedLogin(state);

    const body: VerificationCodeSendBody = {
      contactType: ContactType.PhoneNumber,
      operation: Operation.Login,
      contact,
    };

    dispatch(updateLoadingState(true));
    return AuthRequests.verificationCodeSend(body)
      .then((res) => {
        dispatch(updateExpireVerificationCode(res.data.nextCodeResend));
        dispatch(updateLoadingState(false));
      })
      .catch((err: AxiosError<ErrorModel>) => {
        dispatch(updateLoadingState(false));
        return rejectWithValue(err.response.data);
      });
  });

export const signInComplete = createAsyncThunkWrapper<
  void,
  undefined
>(
  'login/signin/complete',
  async (_, { getState }) => {
    const state = getState();
    const signinCode = LoginSelectors.selectSigninCode(state);
    const verificationCode = LoginSelectors.selectVerificationCode(state);

    await AuthRequests.signinComplete({ signinCode, verificationCode });
  }
);
