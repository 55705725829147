import React from 'react';

export class Labels {
  static code = 'Код';
  static nameHeader = 'Наименование';
  static title = 'Название';
  static fullName = 'Наименование полное';
  static shortName = 'Наименование краткое';
  static fileName = 'Название файла';
  static fileType = 'Тип файла';
  static inn = 'ИНН';
  static kpp = 'КПП';
  static producer = 'Производитель';
  static vital = 'ЖНВЛП';
  static sscc = 'SSCC';
  static gtin = 'GTIN';
  static sgtin = 'SGTIN';
  static ssccOrSgtin = 'SSCC/SGTIN';
  static order = 'Порядок акцептования';
  static lastNotification = 'Последнее уведомление';
  static lastNotificationHint = 'Последний статус документа в МДЛП';
  static series = 'Серия';
  static expirationDate = 'Срок годности';
  static vat = 'НДС, руб.';
  static priceWithVat = 'Цена с НДС, руб.';
  static sum = 'Сумма';
  static totalPriceWithVat = 'Сумма с НДС, руб.';
  static vatRate = 'Ставка НДС, %';
  static countOfScanned = 'Отсканировано, уп.';
  static totalTertiaryPackages = 'Всего третичных упаковок';
  static totalSecondaryPackages = 'Всего вторичных упаковок';
  static totalSecondaryPackagesHint = 'Количество неагрегированных вторичных упаковок ("россыпью")';
  static amount = 'Количество, уп.';
  static isProductInOrder = 'Наличие в накладной';
  static isProductInOrderHint = 'Присутствует ли данный товар в xml-накладной, загруженной пользователем';
  static processingStatus = 'Обработан';
  static processingStatusHint = 'Загрузка и обработка данных о коробах из МДЛП может занять несколько минут';
  static comment = 'Комментарий';
  static activityPlaceShort = 'МД';
  static activityPlaceFull = 'Место деятельности';
  static activityPlacesFull = 'Места деятельности';
  static partner = 'Контрагент';
  static relatedDocument = 'Связанный документ';
  static docType = 'Тип документа';
  static docNumber = 'Номер документа';
  static docNumberHint = <>В случае, если уведомление было загружено в систему в процессе синхронизации, то заполнение поля &quot;Номер документа&quot; может занять несколько минут.<br /> В это время будет происходить связывание уведомления и документа.</>;
  static docDate = 'Дата документа';
  static status = 'Статус';
  static user = 'Пользователь';
  static organization = 'Организация';
  static operationDate = 'Дата операции';
  static operationType = 'Тип операции';
  static operationName = 'Наименование операции';
  static regNumber = 'Регистрационный номер';
  static address = 'Адрес';
  static addressFull = 'Адрес полный';
  static type = 'Тип';
  static producerCountry = 'Страна производителя';
  static nonProprietaryName = 'МНН';
  static barCode = 'Штрихкод';
  static isGtinFound = 'GTIN найден';
  static mdlpComparison = 'Сравнение с МДЛП';
  static fio = 'ФИО';
  static position = 'Должность';
  static addedDate = 'Дата добавления';
  static createdDate = 'Дата создания';
  static createdDateMdlp = 'Дата создания в МДЛП';
  static lastSigninDate = 'Активность';
  static phoneNumber = 'Телефон';
  static email = 'Email';
  static scheme = 'Схема';
  static description = 'Описание';
  static schemaType = 'Тип схемы';
  static period = 'Период';
  static event = 'Событие';
  static source = 'Источник';
  static target = 'Получатель';
  static notificationHistory = 'История уведомления';
  static editor = 'Редактор';
  static medicinalProductName = 'Наименование ЛП';
  static searchBy = 'Поиск по';
  static dateFrom = 'с';
  static dateTo = 'по';
  static amountFrom = 'от';
  static amountTo = 'по';
}
