import { AppInput } from '@/common/components/app-input/AppInput';
import { AppNumberInput } from '@/common/components/app-number-input/AppNumberInput';
import { Labels } from '@/common/constants/labels';
import { innMaxLength, kppMaxLength, registrationNumberMaxLength } from '@/common/utils/client-validation/string-lengths';
import { updateFilter } from '@/modules/references/store/legal-entities';
import { LegalEntitiesSelectors } from '@/modules/references/store/legal-entities/selectors';
import { useAppDispatch } from '@/store';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './legal-entities-filters.scss';

const LegalEntitiesFiltersInner = () => {
  const dispatch = useAppDispatch();

  const inn = useSelector(LegalEntitiesSelectors.selectFilterInn);
  const kpp = useSelector(LegalEntitiesSelectors.selectFilterKpp);
  const regNumber = useSelector(LegalEntitiesSelectors.selectFilterRegNumber);

  const updateInn = useCallback((value: string) => dispatch(updateFilter({ inn: value })), []);
  const updateKpp = useCallback((value: string) => dispatch(updateFilter({ kpp: value })), []);
  const updateRegNumber = useCallback((value: string) => dispatch(updateFilter({ regNumber: value })), []);

  return (
    <div className="legal-entities-filters">
      <AppNumberInput
        label={Labels.inn}
        maxLength={innMaxLength}
        value={inn}
        className="legal-entities-filter"
        showClearButton
        onInput={updateInn}
      />
      <AppNumberInput
        label={Labels.kpp}
        maxLength={kppMaxLength}
        value={kpp}
        className="legal-entities-filter"
        showClearButton
        onInput={updateKpp}
      />
      <AppInput
        label={Labels.regNumber}
        maxLength={registrationNumberMaxLength}
        value={regNumber}
        className="legal-entities-filter"
        showClearButton
        onInput={updateRegNumber}
      />
    </div>
  );
};

export const LegalEntitiesFilters = memo(LegalEntitiesFiltersInner);
