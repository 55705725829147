import { AppDropAliveComponent } from '@/common/components/app-keep-alive/AppDropAliveComponent';
import { AppKeepAliveComponent } from '@/common/components/app-keep-alive/AppKeepAliveComponent';
import { disposalCardRoutePath, disposalsRoutePath } from '@/modules/disposals/routes/routes';
import { DisposalCardPage } from '@/modules/disposals/views/disposal-card-page/DisposalCardPage';
import { DisposalCardRemainsPage } from '@/modules/disposals/views/disposal-card-remains-page/DisposalCardRemainsPage';
import { DisposalCardRootPage } from '@/modules/disposals/views/disposal-card-root-page/DisposalCardRootPage';
import { DisposalCardScanningPage } from '@/modules/disposals/views/disposal-card-scanning-page/DisposalCardScanningPage';
import { DisposalsPage } from '@/modules/disposals/views/disposals-page/DisposalsPage';
import { documentCardRemainsRoutePath, documentCardScanningRoutePath } from '@/shared/document/routes/routes';
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const DisposalsPageComponentName = 'DisposalsPage';

/**
 * All disposals routes.
 */
const routes: RouteObject[] = [
  {
    path: disposalsRoutePath,
    element:
      <AppDropAliveComponent name={DisposalsPageComponentName}>
        <Outlet />
      </AppDropAliveComponent>,
    children: [
      {
        path: '',
        element:
          <AppKeepAliveComponent name={DisposalsPageComponentName}>
            <DisposalsPage />
          </AppKeepAliveComponent>,
      },
      {
        path: disposalCardRoutePath,
        element: <DisposalCardRootPage />,
        children: [
          {
            path: '',
            element: <DisposalCardPage />,
          },
          {
            path: documentCardScanningRoutePath,
            element: <DisposalCardScanningPage />,
          },
          {
            path: documentCardRemainsRoutePath,
            element: <DisposalCardRemainsPage />,
          }
        ]
      },
    ],
  },
];

export default routes;
