import { InputAdornment } from '@mui/material';
import React, { FC } from 'react';

interface AppInputStartAdornmentProps {
  iconStart: React.ReactNode;
}

export const AppInputStartAdornment: FC<AppInputStartAdornmentProps> = ({ iconStart }) => (
  <InputAdornment position="start">
    {iconStart}
  </InputAdornment>
);