import { axios } from '@/common/api/axios-config';
import { headerInfoUrl, mdlpAuthCodeUrl, mdlpTokenUrl } from '@/common/api/urls';
import { HeaderInfo } from '@/modules/portal/models/header-info';
import { MdlpAuthCodeBody } from '@/modules/portal/models/mdlp-auth-code/mdlp-auth-code-body';
import { MdlpAuthCodeResponse } from '@/modules/portal/models/mdlp-auth-code/mdlp-auth-code-response';
import { MdlpTokenBody } from '@/modules/portal/models/mdlp-token/mdlp-token-body';
import { AxiosResponse } from 'axios';

export class HeaderRequests {

  static headerInfo(): Promise<AxiosResponse<HeaderInfo>> {
    return axios.get(headerInfoUrl);
  }

  static mdlpAuthCode(body: MdlpAuthCodeBody): Promise<AxiosResponse<MdlpAuthCodeResponse>> {
    return axios.post(mdlpAuthCodeUrl, body);
  }

  static mdlpToken(body: MdlpTokenBody): Promise<AxiosResponse<void>> {
    return axios.put(mdlpTokenUrl, body);
  }
}
