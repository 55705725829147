import { MutableRefObject, useState } from 'react';

interface AutofillCheckHookParams {
  inputElement: MutableRefObject<HTMLInputElement | undefined>;
}

export function useAutofillCheck({ inputElement }: AutofillCheckHookParams) {
  const [hasAutofilledValue, setHasAutofilledValue] = useState(false);

  const getHasAutofilledValue = () => !!inputElement?.current?.matches(':-internal-autofill-selected');

  const checkAutofillInner = () => {
    const hasAutofill = getHasAutofilledValue();
    setHasAutofilledValue(hasAutofill);
    return hasAutofill;
  };

  const checkAutofillWithTimeout = (timeouts: number[], step: number, hasAutofill: boolean) => {
    if (step === timeouts.length || hasAutofill) {
      return;
    }

    setTimeout(() => {
      const hasAutofill = checkAutofillInner();
      checkAutofillWithTimeout(timeouts, step + 1, hasAutofill);
    }, timeouts[step]);
  }

  const checkAutofill = () => {
    // Extra check on autofill for different browsers (if 200 ms will not enough)
    const timeouts = [0, 200, 400, 1000];
    checkAutofillWithTimeout(timeouts, 0, false);
  }

  return {
    hasAutofilledValue,
    checkAutofill,
  };
}
