import { AppButton } from '@/common/components/app-button/AppButton';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { usePortalInitialization } from '@/modules/portal/hooks/use-portal-initialization';
import { PortalSelectors } from '@/modules/portal/store/portal/selectors';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import './user-without-roles-content.scss';

export const UserWithoutRolesContent = () => {
  const { getPortalInfo } = usePortalInitialization();

  const isLoading = useSelector(PortalSelectors.selectIsLoading);

  const onRetry = useCallback(() => getPortalInfo(), []);

  return (
    <div className="user-without-roles-content">
      Произошла ошибка сервиса, попробуйте повторить операцию позже. <br /> Приносим извинения за неудобства
      <AppButton
        label="Обновить страницу"
        disabled={isLoading}
        onClick={onRetry}
      />
      {isLoading && <AppLoader
        size="small"
        className="refresh-portal-loader"
      />}
    </div>
  );
};
