import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { phoneMask } from '@/common/constants/masks';
import { passwordMaxLength } from '@/common/utils/client-validation/string-lengths';
import { Logo } from '@/modules/auth/components/logo/Logo';
import { useLoginFormValidation } from '@/modules/auth/hooks/use-login-form-validation';
import { usePasswordInput } from '@/modules/auth/hooks/use-password-input';
import { LoginDataFields } from '@/modules/auth/models/login-data';
import { updateLoginDataValue } from '@/modules/auth/store/login';
import { LoginSelectors } from '@/modules/auth/store/login/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './login-form.scss';

interface LoginFormProps {
  onSignInClick: () => void;
  onPasswordRecoveryClick: () => void;
  onRegisterClick: () => void;
}

export const LoginForm: FC<LoginFormProps> = ({ onSignInClick, onPasswordRecoveryClick, onRegisterClick }) => {
  const dispatch = useAppDispatch();

  const formData = useSelector(LoginSelectors.selectFormData);
  const isLoading = useSelector(LoginSelectors.selectIsLoading);

  const onFiledChange = (fieldName: LoginDataFields) =>
    (value: string) => dispatch(updateLoginDataValue({ [fieldName]: value }));

  const onLoginChange = useCallback(onFiledChange('login'), []);
  const onPasswordChange = useCallback(onFiledChange('password'), []);

  const {
    validationResult,
    validateForm,
  } = useLoginFormValidation(formData);

  const onLoginClickInner = () => {
    if (validateForm()) {
      onSignInClick();
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        onLoginClickInner();
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onLoginClickInner]);

  const { passwordInputType, passwordIconComponent } = usePasswordInput();

  return (
    <div className="login-form-container">
      <Logo />
      <AppButton
        label="Зарегистрировать организацию"
        variant="outlined"
        className="register-button"
        onClick={onRegisterClick}
      />
      <AppInput
        label="Телефон"
        showClearButton
        value={formData.login}
        mask={phoneMask}
        error={validationResult.login?.error}
        helperText={validationResult.login?.messages?.[0]}
        onInput={onLoginChange}
      />
      <AppInput
        label="Пароль"
        type={passwordInputType}
        value={formData.password}
        maxLength={passwordMaxLength}
        error={validationResult.password?.error}
        helperText={validationResult.password?.messages?.[0]}
        iconEnd={passwordIconComponent}
        onInput={onPasswordChange}
      />
      <AppButton
        label="Войти"
        className={`sign-in-button ${isLoading ? 'short-margin-bottom' : ''}`}
        disabled={isLoading}
        onClick={onLoginClickInner}
      />
      {
        isLoading && <AppLoader className="sign-in-loader" size={'small'} />}
      <AppLink
        text="Забыли пароль?"
        onClick={onPasswordRecoveryClick}
      />
    </div>
  );
};
