import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { NomenclatureFilters } from '@/modules/references/components/nomenclature-filters/NomenclatureFilters';
import { NomenclatureMdlpComparisonDialog } from '@/modules/references/components/nomenclature-mdlp-comparison-dialog/NomenclatureMdlpComparisonDialog';
import { useNomenclatureTableDataSource } from '@/modules/references/hooks/nomenclature/use-nomenclature-table-data-source';
import { useNomenclatureTableEventHandlers } from '@/modules/references/hooks/nomenclature/use-nomenclature-table-event-handlers';
import { useNomenclatureTableFiltering } from '@/modules/references/hooks/nomenclature/use-nomenclature-table-filtering';
import { useNomenclatureTableSettings } from '@/modules/references/hooks/nomenclature/use-nomenclature-table-settings';
import { uploadNomenclatureFile } from '@/modules/references/store/nomenclature/async-thunks';
import { NomenclatureSelectors } from '@/modules/references/store/nomenclature/selectors';
import { useFileImport } from '@/shared/files/hooks/use-file-import';
import { useSuccessfulImportNotification } from '@/shared/files/hooks/use-successful-import-notification';
import { ExtraFileType } from '@/shared/files/models/file-type';
import React from 'react';
import { useSelector } from 'react-redux';

export const NomenclatureTable = () => {
  const data = useSelector(NomenclatureSelectors.selectData);
  const isLoadingData = useSelector(NomenclatureSelectors.selectIsLoadingData);
  const tableConfig = useSelector(NomenclatureSelectors.selectTableConfig);
  const selectedFiltersCount = useSelector(NomenclatureSelectors.selectSelectedFiltersCount);
  const isPanelOpen = useSelector(NomenclatureSelectors.selectIsPanelOpen);
  const isUploadingInProcess = useSelector(NomenclatureSelectors.selectIsUploadingInProcess);

  const { loadFirstPage, loadNextPage } = useNomenclatureTableDataSource();

  const { columns, searchPlaceholder } = useNomenclatureTableSettings();

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: NomenclatureSelectors.selectSearchState
  });

  const {
    openImportNotificationDialog,
    successfulImportNotificationDialog
  } = useSuccessfulImportNotification({ fileType: ExtraFileType.NomenclatureDict });

  const {
    importFileMaxSize,
    acceptedImportTypes,
    onImportFileUploaded,
    onImportFileMaxSizeExceed,
  } = useFileImport({ uploadAction: uploadNomenclatureFile, openImportNotificationDialog });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
    onRefreshClick
  } = useNomenclatureTableEventHandlers({ loadFirstPage, loadNextPage });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick,
  } = useNomenclatureTableFiltering({ loadFirstPage });

  return (
    <>
      <AppCustomTable
        data={data}
        columns={columns}
        isLoading={isLoadingData}
        configState={tableConfig}
        enableRowVirtualization
        manualSorting
        topToolbar={{
          filter: {
            showFilterButton: true,
            filterPanel: <NomenclatureFilters />,
            isPanelOpen,
            selectedFiltersCount,
            disableApplyFilterButton,
            disableClearFilterButton,
            onFilterClick,
            onApplyFiltersClick,
            onClearFiltersClick,
          },
          search: {
            showSearchInput: true,
            searchPlaceholder,
            disableApplySearchButton: isSearchNotChanged,
            onSearchValueInput,
            onApplySearchClick,
            onClearSearchClick,
          },
          importButton: {
            showImportButton: true,
            importButtonDisabled: isUploadingInProcess,
            importFileMaxSize,
            acceptedImportTypes,
            onImportFileUploaded,
            onImportFileMaxSizeExceed,
          },
          refreshButton: {
            showRefreshButton: true,
            notClickableRefreshButton: isLoadingData,
            onRefreshClick
          }
        }}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onSortingChange={onSortingChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnSizingChange={onColumnSizingChange}
        onScrollAtTheEnd={onScrollChange}
      />
      <NomenclatureMdlpComparisonDialog />
      {successfulImportNotificationDialog}
    </>
  );
};
