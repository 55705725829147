import StarIcon from '@/assets/icons/common/star-filled.svg?react';
import DisposalsIcon from '@/assets/icons/menu-icons/disposals.svg?react';
import FileOperationsIcon from '@/assets/icons/menu-icons/file-operations.svg?react';
import InteractionLogIcon from '@/assets/icons/menu-icons/interaction-log.svg?react';
import ManageUsersIcon from '@/assets/icons/menu-icons/manage-users.svg?react';
import OrganizationProfileIcon from '@/assets/icons/menu-icons/organization-profile.svg?react';
import ReceiptsIcon from '@/assets/icons/menu-icons/receipts.svg?react';
import ReferencesIcon from '@/assets/icons/menu-icons/references.svg?react';
import RemainsIcon from '@/assets/icons/menu-icons/remains.svg?react';
import SalesIcon from '@/assets/icons/menu-icons/sales.svg?react';
import { disposalsRoutePath } from '@/modules/disposals/routes/routes';
import { favoritesRoutePath } from '@/modules/favorites/routes/routes';
import { fileOperationsRoutePath } from '@/modules/file-operations/routes/routes';
import { interactionLogRoutePath } from '@/modules/interaction-log/routes/routes';
import { organizationInformationPath, organizationPlacesPath } from '@/modules/organization-profile/routes/routes';
import { MenuPanelItem } from '@/modules/portal/models/menu-panel-item';
import { receiptsRoutePath } from '@/modules/receipts/routes/routes';
import { legalEntitiesRoutePath, mdlpProductsRoutePath, nomenclatureRoutePath, processesRoutePath } from '@/modules/references/routes/routes';
import { remainsRoutePath } from '@/modules/remains/routes/routes';
import { salesRoutePath } from '@/modules/sale/routes/routes';
import { usersManagementRoutePath } from '@/modules/users-management/routes/routes';
import React, { useMemo } from 'react';

export const useMenuPanelItems = () => {

  const menuPanelItems: MenuPanelItem[] = useMemo(() => [
    {
      label: 'Избранное',
      link: favoritesRoutePath,
      icon: <StarIcon />,
    },
    {
      label: 'Поступление',
      link: receiptsRoutePath,
      icon: <ReceiptsIcon />,
    },
    {
      label: 'Реализация',
      link: salesRoutePath,
      icon: <SalesIcon />,
    },
    {
      label: 'Прочее выбытие',
      link: disposalsRoutePath,
      icon: <DisposalsIcon />,
    },
    {
      label: 'Остатки',
      link: remainsRoutePath,
      icon: <RemainsIcon />,
    },
    {
      label: 'Журнал взаимодействия с МДЛП',
      link: interactionLogRoutePath,
      icon: <InteractionLogIcon />,
    },
    {
      label: 'Импорт/экспорт',
      link: fileOperationsRoutePath,
      icon: <FileOperationsIcon />,
    },
    {
      label: 'Справочники',
      icon: <ReferencesIcon />,
      children: [
        {
          label: 'Контрагенты',
          link: legalEntitiesRoutePath,
        },
        {
          label: 'Товары МДЛП',
          link: mdlpProductsRoutePath,
        },
        {
          label: 'Номенклатура КУС',
          link: nomenclatureRoutePath,
        },
        {
          label: 'Процессы',
          link: processesRoutePath,
        },
      ]
    },
    {
      label: 'Профиль организации',
      icon: <OrganizationProfileIcon />,
      children: [
        {
          label: 'Общие сведения',
          link: organizationInformationPath,
        },
        {
          label: 'Места деятельности',
          link: organizationPlacesPath,
        }
      ]
    },
    {
      label: 'Управление пользователями',
      link: usersManagementRoutePath,
      icon: <ManageUsersIcon />,
    },
  ], []);

  return {
    menuPanelItems,
  };
};
