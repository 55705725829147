import { AppTableActionColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { NomenclatureTableColumns } from '@/modules/references/models/nomenclature/nomenclature-table-columns';

const columnVisibility = {
  [NomenclatureTableColumns.Code]: true,
  [NomenclatureTableColumns.Name]: true,
  [NomenclatureTableColumns.Producer]: true,
  [NomenclatureTableColumns.Country]: true,
  [NomenclatureTableColumns.Barcode]: true,
  [NomenclatureTableColumns.Vital]: true,
  [NomenclatureTableColumns.Gtin]: true,
  [NomenclatureTableColumns.IsGtinFound]: true,
  [NomenclatureTableColumns.MdlpComparison]: true,
};

const sorting = [
  {
    id: NomenclatureTableColumns.Name,
    desc: false,
  }
];

const columnOrder = [
  NomenclatureTableColumns.Code,
  NomenclatureTableColumns.Name,
  NomenclatureTableColumns.Producer,
  NomenclatureTableColumns.Country,
  NomenclatureTableColumns.Barcode,
  NomenclatureTableColumns.Vital,
  NomenclatureTableColumns.Gtin,
  NomenclatureTableColumns.IsGtinFound,
  NomenclatureTableColumns.MdlpComparison,
  AppTableActionColumnName,
];

const columnSizing = {
  [NomenclatureTableColumns.Code]: 20,
  [NomenclatureTableColumns.Name]: 80,
  [NomenclatureTableColumns.Producer]: 60,
  [NomenclatureTableColumns.Country]: 60,
  [NomenclatureTableColumns.Barcode]: 40,
  [NomenclatureTableColumns.Vital]: 20,
  [NomenclatureTableColumns.Gtin]: 40,
  [NomenclatureTableColumns.IsGtinFound]: 20,
  [NomenclatureTableColumns.MdlpComparison]: 50,
};

export const defaultNomenclatureTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
