import { removeNonDigits } from '@/common/utils/common/string-utils';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

const selectLoginState = (state: RootState) => state.auth.login;

const selectFormData = (state: RootState) => selectLoginState(state).formData;
const selectSigninCode = (state: RootState) => selectLoginState(state).signinCode;
const selectExpireVerificationCode = (state: RootState) => selectLoginState(state).expireVerificationCode;
const selectVerificationCode = (state: RootState) => selectLoginState(state).verificationCode;
const selectCodeStatus = (state: RootState) => selectLoginState(state).codeStatus;
const selectIsLoading = (state: RootState) => selectLoginState(state).isLoading;

const selectLogin = (state: RootState) => selectFormData(state).login;
const selectPassword = (state: RootState) => selectFormData(state).password;

const selectPreprocessedLogin = createSelector([selectLogin], (login) => removeNonDigits(login));
const selectExpireVerificationCodeDate = createSelector([selectExpireVerificationCode], (expireVerificationCode) => parseISO(expireVerificationCode));

export const LoginSelectors = {
  selectFormData,
  selectLogin,
  selectPreprocessedLogin,
  selectPassword,
  selectSigninCode,
  selectExpireVerificationCodeDate,
  selectVerificationCode,
  selectCodeStatus,
  selectIsLoading,
};
