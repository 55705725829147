import { AppButton } from '@/common/components/app-button/AppButton';
import { AppMenuItem, AppMenuItemProps } from '@/common/components/app-menu/components/AppMenuItem';
import { ArrowDropDown } from '@mui/icons-material';
import { Menu } from '@mui/material';
import React, { FC, memo, useCallback, useState } from 'react';
import './app-menu.scss';

interface AppMenuProps {
  items: AppMenuItemProps[];
  label: string;
}

const AppMenuInner: FC<AppMenuProps> = ({ label, items }) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const actionMenuItemInner = useCallback((action: AppMenuItemProps['action']) =>
    () => {
      action()
        ?.unwrap()
        ?.then(closeMenu)
        || closeMenu();
    }, []);

  return (
    <div className="app-menu">
      <AppButton
        label={label}
        variant="outlined"
        endIcon={<ArrowDropDown className={`app-menu-label-arrow ${isMenuOpened ? 'reverse' : ''}`} />}
        onClick={openMenu}
      />
      {isMenuOpened && <Menu
        anchorEl={anchorEl}
        open={isMenuOpened}
        onClose={closeMenu}
      >
        {items.map((item) => (
          <AppMenuItem
            {...item}
            key={item.title}
            action={actionMenuItemInner(item.action)}
          />
        ))}
      </Menu>}
    </div>
  );
};

export const AppMenu = memo(AppMenuInner);