import { ImportExportLink } from '@/shared/files/components/import-export-link/ImportExportLink';
import React, { FC, memo } from 'react';
import './successful-export-notification-form.scss';

interface SuccessfulExportNotificationFormProps {
  onImportExportClick: () => void;
}

const SuccessfulExportNotificationFormInner: FC<SuccessfulExportNotificationFormProps> = ({ onImportExportClick }) => (
  <div className="successful-export-notification-form-container">
    <div>Процесс может занять до нескольких минут.</div>
    <div>После завершения формирования файла он станет доступным для скачивания на вкладке <ImportExportLink onClick={onImportExportClick} /> главного меню.</div>
  </div>
);

export const SuccessfulExportNotificationForm = memo(SuccessfulExportNotificationFormInner);
