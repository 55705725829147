import { axios } from '@/common/api/axios-config';
import { activityPlaceSlimUrl } from '@/common/api/urls';
import { OrganizationPlacesShortResponse } from '@/common/models/common-data-sources/organization-places/organization-places-short-response';
import { AxiosResponse } from 'axios';

export class CommonDataSourcesRequests {
  static getOrganizationPlaces(): Promise<AxiosResponse<OrganizationPlacesShortResponse>> {
    return axios.get(activityPlaceSlimUrl);
  }
}
