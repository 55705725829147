import {
  salesFilterConfigActions,
  salesPageableDataActions,
  salesRootActions,
  salesSearchingActions,
  salesTableActions,
  salesTableConfigActions
} from '@/modules/sale/store/sales';
import { salesApi } from '@/modules/sale/store/sales/async-thunks';
import { salesSelectors } from '@/modules/sale/store/sales/selectors';
import { DocumentsTable } from '@/shared/document/components/documents-table/DocumentsTable';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const SalesPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(salesRootActions.resetState()); };
  }, []);

  return <DocumentsTable
    documentsSelectors={salesSelectors}
    documentsApi={salesApi}
    documentsTableActions={salesTableActions}
    documentsPageableDataActions={salesPageableDataActions}
    documentsFilterConfigActions={salesFilterConfigActions}
    documentsSearchingActions={salesSearchingActions}
    documentsTableConfigActions={salesTableConfigActions}
  />;
};
