import { AppInput } from '@/common/components/app-input/AppInput';
import { AppNumberInput } from '@/common/components/app-number-input/AppNumberInput';
import { AppSelect } from '@/common/components/app-select/AppSelect';
import { Labels } from '@/common/constants/labels';
import { logicalSelectOptions } from '@/common/models/app-select/select-options';
import { barcodeMaxLength, codeMaxLength, countryMaxLength, gtinMaxLength, productProducerMaxLength } from '@/common/utils/client-validation/string-lengths';
import { updateFilter } from '@/modules/references/store/nomenclature';
import { NomenclatureSelectors } from '@/modules/references/store/nomenclature/selectors';
import { useAppDispatch } from '@/store';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './nomenclature-filters.scss';

const NomenclatureFiltersInner = () => {
  const dispatch = useAppDispatch();

  const code = useSelector(NomenclatureSelectors.selectFilterCode);
  const producer = useSelector(NomenclatureSelectors.selectFilterProducer);
  const country = useSelector(NomenclatureSelectors.selectFilterCountry);
  const barcode = useSelector(NomenclatureSelectors.selectFilterBarcode);
  const vital = useSelector(NomenclatureSelectors.selectFilterVital);
  const gtin = useSelector(NomenclatureSelectors.selectFilterGtin);
  const isGtinFound = useSelector(NomenclatureSelectors.selectFilterIsGtinFound);

  const updateCode = useCallback((value: string) => dispatch(updateFilter({ code: value })), []);
  const updateProducer = useCallback((value: string) => dispatch(updateFilter({ producer: value })), []);
  const updateCountry = useCallback((value: string) => dispatch(updateFilter({ country: value })), []);
  const updateBarcode = useCallback((value: string) => dispatch(updateFilter({ barcode: value })), []);
  const updateVital = useCallback((value: string) => dispatch(updateFilter({ vital: value })), []);
  const updateGtin = useCallback((value: string) => dispatch(updateFilter({ gtin: value })), []);
  const updateIsGtinFound = useCallback((value: string) => dispatch(updateFilter({ isGtinFound: value })), []);

  return (
    <div className="nomenclature-filters">
      <AppInput
        value={code}
        label={Labels.code}
        maxLength={codeMaxLength}
        showClearButton
        onInput={updateCode}
      />
      <AppInput
        value={producer}
        label={Labels.producer}
        maxLength={productProducerMaxLength}
        showClearButton
        onInput={updateProducer}
      />
      <AppInput
        value={country}
        label={Labels.producerCountry}
        maxLength={countryMaxLength}
        showClearButton
        onInput={updateCountry}
      />
      <AppNumberInput
        value={barcode}
        label={Labels.barCode}
        maxLength={barcodeMaxLength}
        showClearButton
        onInput={updateBarcode}
      />
      <AppSelect
        value={vital}
        label={Labels.vital}
        options={logicalSelectOptions}
        showClearButton
        onChange={updateVital}
      />
      <AppNumberInput
        value={gtin}
        label={Labels.gtin}
        maxLength={gtinMaxLength}
        showClearButton
        onInput={updateGtin}
      />
      <AppSelect
        value={isGtinFound}
        label={Labels.isGtinFound}
        options={logicalSelectOptions}
        showClearButton
        onChange={updateIsGtinFound}
      />
    </div>
  );
};

export const NomenclatureFilters = memo(NomenclatureFiltersInner);
