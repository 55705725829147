import { LoadingStatus } from '@/common/models/loading-status';
import { organizationInfoModuleName } from '@/modules/organization-profile/constants/organization-profile-module-names';
import { convertOrganizationInfoFromDtoToModel, OrganizationInfo } from '@/modules/organization-profile/models/organization-info';
import { convertOrganizationInfoFromDtoToOwnerModel, OrganizationOwner } from '@/modules/organization-profile/models/organization-owner';
import { getOrganizationInfo } from '@/modules/organization-profile/store/organization-info/async-thunks';
import { createSlice } from '@reduxjs/toolkit';

interface OrganizationInfoState {
  organizationInfo?: OrganizationInfo,
  organizationOwner?: OrganizationOwner,
  loadingStatus: LoadingStatus;
}

const initialState: OrganizationInfoState = {
  organizationInfo: undefined,
  organizationOwner: undefined,
  loadingStatus: undefined,
};

const organizationInfoSlice = createSlice({
  name: organizationInfoModuleName,
  initialState,
  reducers: {
    resetState(state) {
      state.loadingStatus = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationInfo.pending, (state) => { state.loadingStatus = LoadingStatus.Pending; })
      .addCase(getOrganizationInfo.rejected, (state) => { state.loadingStatus = LoadingStatus.Rejected; })
      .addCase(getOrganizationInfo.fulfilled, (state, action) => {
        state.loadingStatus = LoadingStatus.Fulfilled;
        state.organizationInfo = convertOrganizationInfoFromDtoToModel(action.payload);
        state.organizationOwner = convertOrganizationInfoFromDtoToOwnerModel(action.payload);
      });
  },
});

export const {
  resetState,
} = organizationInfoSlice.actions;

export default organizationInfoSlice.reducer;
