import { disposalCardSelectors } from '@/modules/disposals/store/disposal-card/card/selectors';
import {
  disposalCardScanningFilterConfigSlice,
  disposalCardScanningPageableDataActions,
  disposalCardScanningRootActions,
  disposalCardScanningSearchingActions,
  disposalCardScanningTableConfigActions
} from '@/modules/disposals/store/disposal-card/scanning';
import { disposalCardScanningApi } from '@/modules/disposals/store/disposal-card/scanning/async-thunks';
import { disposalCardScanningSelectors } from '@/modules/disposals/store/disposal-card/scanning/selectors';
import { DocumentCardScanningPage } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import React from 'react';

export const DisposalCardScanningPage = () => (
  <DocumentCardScanningPage
    documentCardSelectors={disposalCardSelectors}
    documentCardScanningSelectors={disposalCardScanningSelectors}
    documentCardScanningActions={disposalCardScanningRootActions}
    documentCardScanningPageableDataActions={disposalCardScanningPageableDataActions}
    documentCardScanningSearchingActions={disposalCardScanningSearchingActions}
    documentCardScanningTableConfigActions={disposalCardScanningTableConfigActions}
    documentCardScanningFilterConfigActions={disposalCardScanningFilterConfigSlice}
    documentCardScanningApi={disposalCardScanningApi}
  />
);
