import { portalSyncModuleName } from '@/modules/portal/constants/portal-module-names';
import { SyncData, SyncDataIsAbleToLoading } from '@/modules/portal/models/sync/sync-data';
import { SyncRequests } from '@/modules/portal/requests/sync-requests';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getSyncInfo = createAsyncThunkWrapper<
  SyncData,
  boolean | void
>(
  `${portalSyncModuleName}/getSyncInfo`,
  () => SyncRequests.getSyncInfo()
    .then((res) => res.data),
);

export const startSync = createAsyncThunkWrapper<
  SyncData,
  SyncDataIsAbleToLoading
>(
  `${portalSyncModuleName}/startSync`,
  (type) => SyncRequests.startSync(type)
    .then((res) => res.data)
);
