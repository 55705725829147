import { DocumentCardPosition } from '@/shared/document/models/document-card/positions/document-card-position';
import { DocumentCardPositionsTableColumns } from '@/shared/document/models/document-card/positions/document-card-positions-table-columns';

const documentCardPositionsTableColumnsToDtoMap: Record<DocumentCardPositionsTableColumns, keyof DocumentCardPosition> = {
  [DocumentCardPositionsTableColumns.Code]: 'nomenclatureCode',
  [DocumentCardPositionsTableColumns.Name]: 'medicineName',
  [DocumentCardPositionsTableColumns.Producer]: 'producer',
  [DocumentCardPositionsTableColumns.Vital]: 'gnvlp',
  [DocumentCardPositionsTableColumns.Gtin]: 'gtin',
  [DocumentCardPositionsTableColumns.Series]: 'seriesNumber',
  [DocumentCardPositionsTableColumns.ExpirationDate]: 'expirationDate',
  [DocumentCardPositionsTableColumns.PriceWithVat]: 'priceWithVat',
  [DocumentCardPositionsTableColumns.Vat]: 'vat',
  [DocumentCardPositionsTableColumns.TotalPriceWithVat]: 'totalPriceWithVat',
  [DocumentCardPositionsTableColumns.VatRate]: 'vatRate',
  [DocumentCardPositionsTableColumns.IsProductInOrder]: 'isProductPresentInOrder',
  [DocumentCardPositionsTableColumns.CountOfScanned]: 'countOfScanned',
  [DocumentCardPositionsTableColumns.Amount]: 'amount',
};

export const convertDocumentCardPositionColumnFieldToDto = (columnField: DocumentCardPositionsTableColumns) => documentCardPositionsTableColumnsToDtoMap[columnField];
