import { MutableRefObject } from 'react';
import { Mask } from 'react-text-mask';

interface MaskedInputCursorHookParams {
  mask: Mask;
  maskPlaceholderChar: string;
  input: MutableRefObject<HTMLInputElement | undefined>;
}

export function useMaskedInputCursor({ mask, maskPlaceholderChar, input }: MaskedInputCursorHookParams) {
  const getCursorPosition = (maskedValue: string) => {
    if (!Array.isArray(mask)) {
      return -1;
    }

    if (!maskedValue) {
      return mask.findIndex((value: string | RegExp) => typeof value === 'object');
    }

    const placeholderPosition = maskedValue.indexOf(maskPlaceholderChar);
    if (placeholderPosition === -1) {
      return mask.length;
    }

    return placeholderPosition;
  };

  const changeCursorPositionAfterInputCharacters = (maskedValue: string) => {
    const currentCursorPosition = input?.current?.selectionStart;
    const prevValue = maskedValue?.[currentCursorPosition - 1];
    if (!maskedValue || !prevValue || prevValue === maskPlaceholderChar) {
      const newCursorPosition = getCursorPosition(maskedValue);
      input?.current?.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  };

  return {
    changeCursorPositionAfterInputCharacters
  };
}
