import StarFilledIcon from '@/assets/icons/common/star-filled.svg?react';
import StarOutlinedIcon from '@/assets/icons/common/star-outlined.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import React, { FC, memo } from 'react';
import './favorite-button-common.scss';

interface FavoriteButtonCommonProps {
  isFavorite: boolean;
  notClickable?: boolean;
  onClick: () => void;
}

const FavoriteButtonCommonInner: FC<FavoriteButtonCommonProps> = ({ isFavorite, notClickable, onClick }) => {
  const icon = isFavorite ?
    <StarFilledIcon className="star-button-icon" /> :
    <StarOutlinedIcon className="star-button-icon" />;

  const tooltip = isFavorite ?
    'Удалить из избранного' :
    'Добавить в избранное';

  return (
    <AppIconButton
      icon={icon}
      initialSize
      activeColor="primary"
      notClickable={notClickable}
      tooltip={tooltip}
      onClick={onClick}
    />
  );
};

export const FavoriteButtonCommon = memo(FavoriteButtonCommonInner);
