import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { DocumentsTableProps } from '@/shared/document/components/documents-table/DocumentsTable';
import { useSelector } from 'react-redux';

type DocumentsTableDataSourceHookParams = Pick<DocumentsTableProps, 'documentsSelectors' | 'documentsApi' | 'documentsPageableDataActions'>;

export function useDocumentsTableDataSource({ documentsSelectors, documentsApi, documentsPageableDataActions }: DocumentsTableDataSourceHookParams) {
  const sorting = useSelector(documentsSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: documentsPageableDataActions,
    selectPageableDataState: documentsSelectors.selectPageableDataState,
    getData: documentsApi.getAllDocuments,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
