import React, { FC, memo } from 'react';
import './form-info-item.scss';

interface FormInfoItemProps {
  title: string;
  value?: string;
  hideElement?: boolean;
  hideValue?: boolean;
}

const FormInfoItemInner: FC<FormInfoItemProps> = ({ title, value, hideElement = false, hideValue = false }) => (
  hideElement ?
    <br/> :
    <div className="form-info-item-container">
      <div className="title">{title}</div>
      <div className="value">{hideValue ? '' : value || '-'}</div>
    </div>
);

export const FormInfoItem = memo(FormInfoItemInner);
