import { saleModuleName } from '@/modules/sale/constants/sale-module-names';
import { salesApi } from '@/modules/sale/store/sales/async-thunks';
import { DocumentType } from '@/shared/document/models/document-type';
import { createDocumentsSlice } from '@/shared/document/store/documents';

const salesSlice = createDocumentsSlice({
  moduleName: saleModuleName,
  savedTableConfigName: 'sales-table',
  docType: DocumentType.Sale,
  api: salesApi,
});

export const salesRootActions = salesSlice.rootSlice.actions;
export const salesTableActions = salesSlice.documentsTableSlice.actions;
export const salesSearchingActions = salesSlice.documentsSearchingSlice.actions;
export const salesTableConfigActions = salesSlice.documentsTableConfigSlice.actions;
export const salesFilterConfigActions = salesSlice.documentsFilterConfigSlice.actions;
export const salesPageableDataActions = salesSlice.documentsPageableDataSlice.actions;

export default salesSlice.rootSlice.reducer;
