import { AppCheckbox } from '@/common/components/app-checkbox/AppCheckbox';
import { AppNumberInput } from '@/common/components/app-number-input/AppNumberInput';
import { ssccMaxLength } from '@/common/utils/client-validation/string-lengths';
import { FormValidationResult } from '@/common/utils/client-validation/validation-result';
import React, { FC, memo } from 'react';
import './unbox-remain-bottom-content.scss';

export type UnboxRemainValidateFields = 'sscc';

export interface UnboxRemainBottomContentProps {
  sscc: string;
  validationResult?: FormValidationResult<UnboxRemainValidateFields>;
  isRecursive: boolean;
  readonlySscc: boolean;
  onChangeIsRecursive: (value: boolean) => void;
  onChangeSscc: (value: string) => void;
}

const UnboxRemainBottomContentInner: FC<UnboxRemainBottomContentProps> = ({ sscc, readonlySscc, validationResult, isRecursive, onChangeIsRecursive, onChangeSscc }) => (
  <div className="unbox-remain-bottom-content">
    <AppNumberInput
      label="SSCC короба"
      value={sscc}
      maxLength={ssccMaxLength}
      readOnly={readonlySscc}
      error={validationResult?.sscc?.error}
      helperText={validationResult?.sscc?.messages?.[0]}
      onValueChange={onChangeSscc}
    />
    <AppCheckbox
      checked={isRecursive}
      label="Разагрегировать ресурсивно"
      hint={<div className="unbox-remain-bottom-content-hint">Будут разагрегированы все вложенные третичные упаковки, которые находятся внутри текущей третичной упаковки</div>}
      onChange={onChangeIsRecursive}
    />
  </div>
);

export const UnboxRemainBottomContent = memo(UnboxRemainBottomContentInner);