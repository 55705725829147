import { isValueExist } from '@/common/utils/common/types-utils';

/**
 * Returns formatted string for phone number.
 * @param value - phone input string.
 */
export const formatPhoneOutput = (value: string) => {
  if (!value || value.length < 11) {
    return value;
  }
  return `+${value[0]} (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 9)}-${value.slice(9, 11)}`;
};

const formatInner = (value?: number | string, options?: Intl.NumberFormatOptions) => {
  if (!isValueExist(value)) {
    return '';
  }

  let num = value;
  if (typeof value === 'string') {
    num = Number(value);
  }

  if (isNaN(num)) {
    return '';
  }

  return num.toLocaleString('ru-RU', options);
};

/**
 * Returns formatted string for currency.
 * @param value - currency input.
 */
export const formatCurrency = (value?: number | string) => {
  return formatInner(value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

/**
 * Returns formatted number.
 * @param value - number input.
 */
export const formatNumber = (value?: number | string) => {
  return formatInner(value);
};
