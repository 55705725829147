import ChevronIcon from '@/assets/icons/chevron.svg?react';
import { AppDropDownContainer } from '@/common/components/app-drop-down-container/AppDropDownContainer';
import { AppTabGroup, AppTabGroupProps } from '@/common/components/app-tab-group/AppTabGroup';
import { LoadingStatus } from '@/common/models/loading-status';
import { ExtractPropertyType } from '@/common/utils/common/types-utils';
import { BackButton } from '@/modules/portal/components/back-button/BackButton';
import { LoaderWrapperWithRejectContent } from '@/modules/portal/components/loader-wrappers/loader-wrapper-with-reject-content/LoaderWrapperWithRejectContent';
import { DocumentCardInfoButton } from '@/shared/document/components/document-card/document-card-info-button/EntityDocumentCardInfoButton';
import { AsyncAction } from '@/store';
import React, { FC, MutableRefObject, ReactNode, memo, useMemo } from 'react';
import './document-card.scss';

interface DocumentCardProps {
  isOpenInfo: boolean;
  loadingStatusInfo: LoadingStatus;
  maxHeightInfo: number;
  defaultHeightInfo: number;
  backNavigationRoute: string;
  retryAction: AsyncAction;
  infoComponent: ReactNode;
  activeTab: ExtractPropertyType<AppTabGroupProps, 'activeTabId'>;
  tabs: ExtractPropertyType<AppTabGroupProps, 'tabs'>;
  actionButtons: ReactNode[];
  favoriteButton: ReactNode;
  tabContentRef: MutableRefObject<HTMLDivElement>;
  toggleOpenDocumentCardInfo: () => void;
  onChangeTab: ExtractPropertyType<AppTabGroupProps, 'onChangeTab'>;
  onActiveTabClick: ExtractPropertyType<AppTabGroupProps, 'onActiveTabClick'>;
}

const DocumentCardInner: FC<DocumentCardProps> = ({ isOpenInfo, loadingStatusInfo, maxHeightInfo, defaultHeightInfo, backNavigationRoute, retryAction, infoComponent, actionButtons, activeTab, tabs, favoriteButton, tabContentRef, toggleOpenDocumentCardInfo, onChangeTab, onActiveTabClick }) => {

  const documentCardButtonLabel = useMemo(() => `${isOpenInfo ? 'Скрыть' : 'Посмотреть'} карточку документа`, [isOpenInfo]);

  return (
    <div className="document-card">
      <div className="card-header">
        <BackButton
          defaultRoute={backNavigationRoute}
          className="card-back-button"
        />
        {favoriteButton}
      </div>
      <div className="card-info">
        <DocumentCardInfoButton
          label={documentCardButtonLabel}
          icon={<ChevronIcon className={`card-button-icon ${isOpenInfo ? 'open' : ''}`} />}
          onClick={toggleOpenDocumentCardInfo}
        />
        <AppDropDownContainer
          isOpen={isOpenInfo}
          maxHeight={maxHeightInfo}
          className="card-drop-down-container"
        >
          <LoaderWrapperWithRejectContent
            loadingStatus={loadingStatusInfo}
            retryAction={retryAction}
            mainWrapperHeight={defaultHeightInfo}
          >
            {infoComponent}
          </LoaderWrapperWithRejectContent>
        </AppDropDownContainer>
      </div>
      {!!actionButtons.length && (
        <div className="card-action-buttons">
          {actionButtons}
        </div>
      )}
      <AppTabGroup
        activeTabId={activeTab}
        tabs={tabs}
        tabContentRef={tabContentRef}
        onChangeTab={onChangeTab}
        onActiveTabClick={onActiveTabClick}
      />
    </div>
  );
};

export const DocumentCard = memo(DocumentCardInner);
