import React, { ReactNode, forwardRef, memo } from 'react';
import './document-card-info-container.scss';

interface DocumentCardInfoContainerProps {
  children: ReactNode;
  className?: string;
}

const DocumentCardInfoContainerInner = forwardRef<HTMLDivElement, DocumentCardInfoContainerProps>(({ children, className }, ref) => (
  <div
    className={`document-card-info-container ${className ?? ''}`}
    ref={ref}
  >
    {children}
  </div>
));
DocumentCardInfoContainerInner.displayName = 'DocumentCardInfoContainer';

export const DocumentCardInfoContainer = memo(DocumentCardInfoContainerInner);