import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { formatPhoneOutput } from '@/common/utils/common/formatting-utils';
import { PhoneCodeFormProps } from '@/modules/auth/components/phone-code-form/PhoneCodeForm';
import { setCode } from '@/modules/auth/store/invite-manager';
import { sendVerificationCodeByCipcher, signupManagerVerifyPhoneNumber } from '@/modules/auth/store/invite-manager/async-thunks';
import { InviteManagerSelectors } from '@/modules/auth/store/invite-manager/selectors';
import { useAppDispatch } from '@/store';
import React, { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';

export function useInviteManagerPhoneCodeForm(managerUuidCipher: string): PhoneCodeFormProps {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const code = useSelector(InviteManagerSelectors.selectCode);
  const codeStatus = useSelector(InviteManagerSelectors.selectCodeStatus);
  const phone = useSelector(InviteManagerSelectors.selectLogin);
  const isLoading = useSelector(InviteManagerSelectors.selectIsLoading);
  const expireVerificationCode = useSelector(InviteManagerSelectors.selectExpireTimePhoneDate);

  const onInput = useCallback((value: string) => {
    dispatch(setCode(value));
  }, []);

  const onCompleteInput = useCallback(() => {
    asyncDispatch(() => signupManagerVerifyPhoneNumber(managerUuidCipher));
  }, []);

  const onSendPhoneNumberCodeClick = useCallback(() => asyncDispatch(() => sendVerificationCodeByCipcher(managerUuidCipher)), []);

  const phoneNumberMessage: ReactNode = <>На указанный администратором при регистрации номер телефона <span className="phone-number"> {formatPhoneOutput(phone)} </span> отправлен код подтверждения</>;

  return {
    code,
    codeStatus,
    phone,
    isLoading,
    expireVerificationCode,
    phoneNumberMessage,
    onInput,
    onCompleteInput,
    onSendPhoneNumberCodeClick,
  };
}
