import { AppCheckbox } from '@/common/components/app-checkbox/AppCheckbox';
import { AppLink } from '@/common/components/app-link/AppLink';
import { ValidationResult } from '@/common/utils/client-validation/validation-result';
import React, { FC, memo } from 'react';
import './agreement-with-rules.scss';

interface AgreementWithRulesProps {
  checked: boolean | undefined;
  validationResult: ValidationResult;
  className?: string;
  onChange: (checked: boolean) => void;
  onLinkClick: () => void;
}

const AgreementWithRulesInner: FC<AgreementWithRulesProps> = ({ checked, validationResult, className, onChange, onLinkClick }) => (
  <AppCheckbox
    classes={{
      container: `agreement-with-rules-container ${className ?? ''}`,
      checkbox: 'agreement-with-rules-checkbox'
    }}
    checked={checked}
    label={
      <span>
        Подтверждаю согласие с <AppLink
          text="Правилами использования системы"
          classes={{ container: 'rule-link-container', font: 'rule-link-font' }}
          onClick={onLinkClick}
        />
      </span>
    }
    error={validationResult?.error}
    helperText={validationResult?.messages?.[0]}
    onChange={onChange}
  />
);

export const AgreementWithRules = memo(AgreementWithRulesInner);
