import { AppErrorBoundary } from '@/common/components/app-error-boundary/AppErrorBoundary';
import { ProtectedRoute } from '@/common/components/protected-route/ProtectedRoute';
import { loginRoutePath } from '@/modules/auth/routes/routes';
import disposalsRoutes from '@/modules/disposals/routes';
import favoritesRoutes from '@/modules/favorites/routes';
import fileOperationsRoutes from '@/modules/file-operations/routes';
import interactionLogRoutes from '@/modules/interaction-log/routes';
import organizationProfileRoutes from '@/modules/organization-profile/routes';
import { PortalLoaderWrapper } from '@/modules/portal/components/loader-wrappers/portal-loader-wrapper/PortalLoaderWrapper';
import { MainLayout } from '@/modules/portal/components/main-layout/MainLayout';
import { NavigationAfterSignIn } from '@/modules/portal/components/navigation-after-sign-in/NavigationAfterSignIn';
import { portalNotFoundRoutePath } from '@/modules/portal/routes/routes';
import { NotFoundPage } from '@/modules/portal/views/not-found-page/NotFoundPage';
import receiptsRoutes from '@/modules/receipts/routes';
import referencesRoutes from '@/modules/references/routes';
import remainsRoutes from '@/modules/remains/routes';
import salesRoutes from '@/modules/sale/routes';
import usersManagementRoutes from '@/modules/users-management/routes';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All portal routes.
 */
const routes: RouteObject[] = [
  {
    path: '/',
    element:
      <PortalLoaderWrapper>
        <ProtectedRoute
          accessBy="authenticated"
          redirectPath={loginRoutePath}
        >
          <MainLayout />
        </ProtectedRoute>
      </PortalLoaderWrapper>,
    errorElement: <AppErrorBoundary />,
    children: [
      {
        path: '/',
        element: <NavigationAfterSignIn />
      },
      ...favoritesRoutes,
      ...receiptsRoutes,
      ...salesRoutes,
      ...disposalsRoutes,
      ...remainsRoutes,
      ...interactionLogRoutes,
      ...fileOperationsRoutes,
      ...referencesRoutes,
      ...organizationProfileRoutes,
      ...usersManagementRoutes,
      {
        path: portalNotFoundRoutePath,
        element: <NotFoundPage />
      }
    ],
  },
];

export default routes;
