import { ImportExportLink } from '@/shared/files/components/import-export-link/ImportExportLink';
import React, { FC, memo } from 'react';
import './successful-import-notification-form.scss';

interface SuccessfulImportNotificationFormProps {
  mainMessage: string;
  onImportExportClick: () => void;
}

const SuccessfulImportNotificationFormInner: FC<SuccessfulImportNotificationFormProps> = ({ mainMessage, onImportExportClick }) => (
  <div className="successful-import-notification-form-container">
    <div>Процесс может занять до нескольких минут.</div>
    <div>{mainMessage}</div>
    <div>Для отслеживания прогресса и ознакомления с ошибками (в случае их возникновения) – откройте вкладку <ImportExportLink onClick={onImportExportClick}/> главного меню.
    </div>
  </div>
);

export const SuccessfulImportNotificationForm = memo(SuccessfulImportNotificationFormInner);
