import CalendarIcon from '@/assets/icons/controls/calendar.svg?react';
import { AppEndAdornment } from '@/common/components/app-end-adornment/AppEndAdornment';
import { dateFormat } from '@/common/constants/date-formats';
import { useAppThemePalette } from '@/common/hooks/use-app-theme-palette';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ru } from 'date-fns/locale';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './app-date-picker.scss';

interface AppDatePickerProps {
  value: Date | undefined;
  label: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  format?: string;
  onChange: (newValue: Date | null) => void;
}

const AppDatePickerInner: FC<AppDatePickerProps> = ({
  value = null,
  label,
  format = dateFormat,
  minDate,
  maxDate,
  onChange,
}) => {
  const datePickerContainerRef = useRef<HTMLDivElement | undefined>();
  const datePickerInputRef = useRef<HTMLInputElement | undefined>();

  const [innerValue, setInnerValue] = useState(value);

  const ruLocale = useMemo(() => ruRU.components.MuiLocalizationProvider.defaultProps.localeText, []);

  useEffect(() => { setInnerValue(value); }, [value]);
  useEffect(
    () => { datePickerInputRef.current = datePickerContainerRef.current?.getElementsByTagName('input')?.item(0); },
    [datePickerContainerRef.current]
  );

  const { themePalette } = useAppThemePalette();

  const onChangeInner = useCallback((value: Date) => {
    setInnerValue(value);
    onChange(value);
  }, []);

  const onClear = useCallback(() => {
    setInnerValue(null);
    onChange(undefined);

    const { selectionStart, selectionEnd } = datePickerInputRef?.current;
    if (selectionStart !== selectionEnd) {  // if input is focused, then save it
      datePickerInputRef?.current?.focus();
      datePickerInputRef?.current?.setSelectionRange(0, 0);
    }
  }, []);

  return (
    <div className="app-date-picker">
      <ThemeProvider theme={themePalette}>
        <LocalizationProvider
          localeText={ruLocale}
          adapterLocale={ru}
          dateAdapter={AdapterDateFns}
        >
          <DatePicker
            ref={datePickerContainerRef}
            value={innerValue}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
            format={format}
            slots={{
              openPickerIcon: CalendarIcon,
              clearButton: AppEndAdornment
            }}
            slotProps={{
              openPickerIcon: {
                className: 'app-date-picker-open-picker-icon'
              },
              field: {
                clearable: true,
              },
              clearButton: {
                showClearButton: true,
                onClearButtonClick: onClear,
              }
            }}
            onChange={onChangeInner}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};

export const AppDatePicker = memo(AppDatePickerInner);
