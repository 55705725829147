import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { DisposalRemainsFilter } from '@/modules/disposals/models/remains/disposal-remains-filter';
import { DocUuidFilter } from '@/modules/disposals/models/remains/doc-uuid-filter';
import { Remain } from '@/shared/remains/models/remain';
import { RemainsActivityPlaceFilter } from '@/shared/remains/models/remains-activity-place-filter';
import { getRemainsRsqlFilter, getRemainsSearchFilter } from '@/shared/remains/utils/filters/remains-filter-utils';

function getDisposalRemainsRsqlFilter(filter: Partial<DisposalRemainsFilter>): RsqlFilterModel<Remain & RemainsActivityPlaceFilter & DocUuidFilter>[] {
  return [
    {
      selector: 'docUuid',
      operation: RsqlFilterOperations.Equal,
      value: filter.docUuid,
    },
    ...getRemainsRsqlFilter(filter),
  ];
}

export function buildDisposalCardRemainsFilterExpression(filter: Partial<DisposalRemainsFilter>, searchString: string): string {
  const filterModel = getDisposalRemainsRsqlFilter(filter);
  const searchModel = getRemainsSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
