import { isPhone } from '@/common/utils/common/phone-utils';
import { removeNonDigits } from '@/common/utils/common/string-utils';
import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { Roles } from '@/modules/portal/models/roles';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { hasRolesAccessByPermission } from '@/modules/portal/utils/has-roles-access-by-permission';
import { UserInfo, UserInfoFields } from '@/modules/users-management/models/user-info/user-info';
import { UserState } from '@/modules/users-management/models/user-state';
import { UsersManagemenetFilterSelectors, UsersManagementFilter } from '@/modules/users-management/models/users-management-filter';

export const getUsersManagementSearchFields = (userRoles: Roles[]) => {
  const searchFields: UserInfoFields[] = ['firstName', 'secondName', 'lastName', 'phoneNumber', 'email'];

  if (hasRolesAccessByPermission(userRoles, UserPermission.ViewOrganizationInUsersManagement)) {
    searchFields.push('organizationName');
  }

  return searchFields;
};

function getUsersManagementRsqlFilter(filter: Partial<UsersManagementFilter>): RsqlFilterModel<UsersManagemenetFilterSelectors>[] {
  return [
    {
      selector: 'state',
      operation: RsqlFilterOperations.NotEqual,
      value: !filter.showBlockedUsers && UserState.Blocked,
    },
  ];
}

function getUsersManagementSearchFilter(searchString: string, searchFields: UserInfoFields[]): RsqlFilterModel<UserInfo>[] {
  const search = isPhone(searchString) ? removeNonDigits(searchString) : searchString;

  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: search,
  }));
}

export function buildUsersManagementFilterExpression(filter: Partial<UsersManagementFilter>, searchString: string, searchFields: UserInfoFields[]): string {
  const searchModel = getUsersManagementSearchFilter(searchString, searchFields);
  const filterModel = getUsersManagementRsqlFilter(filter);
  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
