import { Stepper } from '@/common/components/stepper/Stepper';
import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { RegistrationStep1Form } from '@/modules/auth/components/registration-forms/RegistrationStep1Form';
import { RegistrationStep2Form } from '@/modules/auth/components/registration-forms/RegistrationStep2Form';
import { RegistrationStep3Form } from '@/modules/auth/components/registration-forms/RegistrationStep3Form';
import { SignupAuthCodeBody } from '@/modules/auth/models/signup-auth-code/signup-auth-code-body';
import { SignupValidateContactsErrorFields } from '@/modules/auth/models/signup-validate-contacts/signup-validate-contacts-body';
import { loginRoutePath } from '@/modules/auth/routes/routes';
import { resetFormCodesValue, resetState, setStep } from '@/modules/auth/store/registration';
import { sendEmailCode as sendEmailCodeAsync, sendPhoneCode as sendPhoneCodeAsync, signUpInit, signupAuthCode, signupComplete, signupValidateContacts } from '@/modules/auth/store/registration/async-thunks';
import { RegistrationSelectors } from '@/modules/auth/store/registration/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './registration-forms.scss';

const RegistrationFormInner: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useAppToast();

  const { fieldErrors, asyncDispatch, resetFieldErrors } = useAsyncDispatch<SignupValidateContactsErrorFields>();
  const activeStep = useSelector(RegistrationSelectors.selectActiveStep);

  const onNextStepClick = () => {
    asyncDispatch(signupValidateContacts, () => dispatch(setStep(1)));
  };

  const onPrevStepClick = () => {
    resetFieldErrors();
    dispatch(setStep(0));
    dispatch(resetFormCodesValue());
  };

  const onCheckDataClick = () => {
    const thenCb = (res: SignupAuthCodeBody) => {
      asyncDispatch(() => signupAuthCode(res));
    };
    asyncDispatch(signUpInit, thenCb);
  };

  const onRegisterClick = useCallback(() => {
    asyncDispatch(signupComplete, () => toast('Вы успешно зарегистрированы!'));
  }, []);

  const onCancelClick = useCallback(() => {
    navigate(loginRoutePath);
    dispatch(resetState());
  }, []);

  const sendPhoneCode = useCallback(() => asyncDispatch(sendPhoneCodeAsync), []);
  const sendEmailCode = useCallback(() => asyncDispatch(sendEmailCodeAsync), []);

  return (
    <div className="registration-form">
      <Stepper activeStep={activeStep}>
        <RegistrationStep1Form
          fieldErrors={fieldErrors}
          onNextClick={onNextStepClick}
          onCancelClick={onCancelClick}
        />
        <RegistrationStep2Form
          onPrevClick={onPrevStepClick}
          onCheckDataClick={onCheckDataClick}
        />
        <RegistrationStep3Form
          fieldErrors={fieldErrors}
          onRegisterClick={onRegisterClick}
          onChangeDataClick={onPrevStepClick}
          sendPhoneCode={sendPhoneCode}
          sendEmailCode={sendEmailCode}
        />
      </Stepper>
    </div>
  );
};

export const RegistrationForm = memo(RegistrationFormInner);
