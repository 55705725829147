import { dateTimeFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType, AppTableRowType } from '@/common/models/app-table/app-table-types';
import { formatDate } from '@/common/utils/common/date-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { FileOperation } from '@/modules/file-operations/models/file-operation';
import { getFileOperationTypeTitle } from '@/modules/file-operations/models/file-operation-type';
import { FileOperationsTableColumns } from '@/modules/file-operations/models/file-operations-table-columns';
import { convertFileOperationColumnFieldToDto } from '@/modules/file-operations/models/file-operations-table-columns-converters';
import { getFileStateTitle, isErrorFileState, isWarnFileState } from '@/modules/file-operations/models/file-state';
import { getFileTypeTitle } from '@/shared/files/models/file-type';
import { useMemo } from 'react';

export function useFileOperationsTableSettings() {
  const searchPlaceholder = getSearchString([Labels.fileName]);

  const columns = useMemo<AppTableColumnDefType<FileOperation>[]>(
    () => [
      {
        id: FileOperationsTableColumns.OperationDate,
        header: Labels.operationDate,
        accessorFn: (row) => formatDate(row.operationDate, dateTimeFormat),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: FileOperationsTableColumns.FileName,
        header: Labels.fileName,
        accessorKey: convertFileOperationColumnFieldToDto(FileOperationsTableColumns.FileName),
      },
      {
        id: FileOperationsTableColumns.FileType,
        header: Labels.fileType,
        accessorFn: (row) => getFileTypeTitle(row.fileType),
      },
      {
        id: FileOperationsTableColumns.OperationType,
        header: Labels.operationType,
        accessorFn: (row) => getFileOperationTypeTitle(row.operationType),
      },
      {
        id: FileOperationsTableColumns.FileState,
        header: Labels.status,
        accessorFn: (row) => getFileStateTitle(row.fileState),
      },
    ],
    [],
  );

  const getCellRowClassName = (data: { row: AppTableRowType<FileOperation>; }) => {
    const { fileState } = data.row.original;

    if (isWarnFileState(fileState)) {
      return 'file-operation-warn-state';
    }
    if (isErrorFileState(fileState)) {
      return 'file-operation-error-state';
    }
  };

  const tableClasses = useMemo(() => ({
    cell: getCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses,
    searchPlaceholder,
  };
}
