import { SortDirection } from '@/common/utils/sorting/sort-direction';
import { parseISO } from 'date-fns';

/**
 * Default function for comparing two generic values.
 * @param a the first of compared element
 * @param b the second compared element
 * @returns number result of values comparing.
 */
export function comparator<T>(a: T, b: T): number {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  throw new Error('Unsupported object type for comparing');
}

/**
 * Sorting template function (callback) that leaves empty values at the end of the array.
 * @param a one of compared element
 * @param b another compared element
 * @param direction sorting direction
 * @param compare function for comparing elements of template type.
 * @returns number for sort function in array.
 */
export function sortWithOrder<T>(
  a: T | undefined,
  b: T | undefined,
  direction: SortDirection = SortDirection.Asc,
  compare: (v1: T, v2: T) => number = comparator,
): number {
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const order = direction === SortDirection.Asc ? 1 : -1;
  return compare(a, b) * order;
}

/**
 * Sorting 2 dates function.
 * @param date1 - the first date in Date or string ISO format.
 * @param date2 - the second date in Date or string ISO format.
 * @returns number result of values comparing.
 */
export function sortDates(date1: Date | string | undefined, date2: Date | string | undefined): number {
  const convertedDate1 = typeof date1 === 'string' ? parseISO(date1) : date1;
  const convertedDate2 = typeof date2 === 'string' ? parseISO(date2) : date2;
  return sortWithOrder(convertedDate1?.getTime(), convertedDate2?.getTime());
}
