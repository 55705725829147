import { differenceInSeconds, endOfDay, format, isBefore, isDate as isDateInstance, parseISO } from 'date-fns';

const minDate = new Date(0, 0, 0);

/**
 * Format date by pattern.
 * @param date - date string in ISO format or Date.
 * @param formatType - format for output.
 */
export const formatDate = (date: string | Date | undefined, formatType: string) => {
  if (!date) {
    return '';
  }
  const convertedDate = typeof date === 'string' ? parseISO(date) : date;
  return format(convertedDate, formatType);
};

export const formatCurrentDate = (formatType: string) => {
  return formatDate(new Date(), formatType);
};

export const differenceInSecondsWithCurrentTime = (date: string | Date | undefined) => {
  if (!date) {
    return undefined;
  }
  const convertedDate = typeof date === 'string' ? parseISO(date) : date;
  return differenceInSeconds(convertedDate, new Date());
};

export const isDate = (date: unknown) => {
  return isDateInstance(date);
};

export const isDateValid = (date: Date) => {
  // @ts-ignore
  return date instanceof Date && !isNaN(date) && isBefore(minDate, date);
};

export const getISOString = (date: Date | null | undefined) => date?.toISOString();

export const getEndOfDay = (date: Date | null | undefined) => date ? endOfDay(date) : null;
