import { SearchingState } from '@/common/models/searching/searching-state';
import { RootState } from '@/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface SearchingDataHookParams {
  selectSearchingState: (state: RootState) => SearchingState,
}

export function useSearchingData({ selectSearchingState }: SearchingDataHookParams) {
  const currentSearchString = useSelector((state: RootState) => selectSearchingState(state).currentSearchString);
  const lastAppliedSearchString = useSelector((state: RootState) => selectSearchingState(state).lastAppliedSearchString);
  const isSearchNotChanged = useMemo(() => currentSearchString === lastAppliedSearchString, [currentSearchString, lastAppliedSearchString]);

  return {
    isSearchNotChanged,
  };
}
