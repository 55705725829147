import { usersPermissionsByRoles } from '@/modules/portal/constants/users-permissions-by-roles';
import { Roles } from '@/modules/portal/models/roles';
import { UserPermission } from '@/modules/portal/models/user-permission';

export const hasRolesAccessByPermission = (roles: Roles[], accessPermission: UserPermission) => {
  if (!roles.length) {
    return false;
  }
  const allowedRoles = usersPermissionsByRoles[accessPermission];
  return roles.some(role => allowedRoles?.includes(role));
};
