import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { getOrganizationPlacesDataSource } from '@/common/store/common-data-sources/async-thunks';
import { CommonDataSourcesSelectors } from '@/common/store/common-data-sources/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useOrganizationPlacesDataSource = () => {
  const { asyncDispatch } = useAsyncDispatch();

  const organizationPlacesData = useSelector(CommonDataSourcesSelectors.selectOrganizationPlacesData);

  const organizationPlacesOptions = useMemo(() => organizationPlacesData.map(({ name, activityPlaceUuid }) => ({
    label: name,
    value: activityPlaceUuid,
  })), [organizationPlacesData]);

  const loadOrganizationPlacesOptions = () => {
    asyncDispatch(getOrganizationPlacesDataSource);
  };

  return {
    organizationPlacesOptions,
    loadOrganizationPlacesOptions,
  };
};
