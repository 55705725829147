export enum FileOperationType {
  Import = 'IMPORT',
  Export = 'EXPORT',
}

const fileOperationTypesTitles = {
  [FileOperationType.Import]: 'Импорт',
  [FileOperationType.Export]: 'Экспорт',
};

export const getFileOperationTypeTitle = (key: FileOperationType): string => fileOperationTypesTitles[key];

export const getFileOperationTypesOptions = () => Object.entries(fileOperationTypesTitles).map(([key, value]) => ({
  label: value,
  value: key,
}));
