import { DocumentType, documentTypeLabels } from '@/shared/document/models/document-type';

export enum ExtraFileType {
  NomenclatureDict = 'NOMENCLATURE_DICT',
  RemainingMed = 'REMAINING_MED'
}

export type FileType = DocumentType | ExtraFileType;

const extraFileTypesTitles = {
  [ExtraFileType.NomenclatureDict]: 'Справочник',
  [ExtraFileType.RemainingMed]: 'Остатки'
};

export const getFileTypeTitle = (key: FileType): string => extraFileTypesTitles[key] ?? documentTypeLabels[key];

export const getFileTypesOptions = () => Object.entries({ ...extraFileTypesTitles, ...documentTypeLabels }).map(([key, value]) => ({
  label: value,
  value: key,
}));
