import { CertificatesSelectors } from "@/modules/certificates/store/selectors";
import { getSignatureByCertificateOwner } from '@/modules/certificates/utils/get-signature-by-certificate-owner';
import { remainsPageModuleName } from "@/modules/remains/constants/remains-module-names";
import { SendDocumentRemainSignatureParams } from "@/modules/remains/models/unbox-remain-init/send-unbox-remain-signature-params";
import { UnboxRemainInitResponse } from "@/modules/remains/models/unbox-remain-init/unbox-remain-init-response";
import { unboxRemainInitSendType } from "@/modules/remains/models/unbox-remain-init/unbox-remain-init-send-type";
import { RemainsPageRequests } from "@/modules/remains/requests/remains-page-requests";
import { RemainsPageSelectors } from "@/modules/remains/store/page/selectors";
import { remainsTableRootActions } from "@/modules/remains/store/table";
import { remainsTableSelectors } from "@/modules/remains/store/table/selectors";
import { DocumentRequests } from "@/shared/document/requests/document-requests";
import { AllFilesExportBody, FileExportBody } from "@/shared/files/models/file-export-body";
import { ExtraFileType } from "@/shared/files/models/file-type";
import { FileRequests } from "@/shared/files/requests/file-requests";
import { buildRemainsFilterExpression } from "@/shared/remains/utils/filters/remains-filter-utils";
import { createAsyncThunkWrapper } from "@/store/create-async-thunk-wrapper";

export const exportSelectedRemainsFiles = createAsyncThunkWrapper<
  void,
  undefined
>(
  `${remainsPageModuleName}/exportSelectedRemainsFiles`,
  (_, { getState, dispatch }) => {

    const rowsSelectionIds = remainsTableSelectors.selectRowsSelectionIds(getState());

    const body: FileExportBody = {
      exportType: ExtraFileType.RemainingMed,
      exportUuids: rowsSelectionIds,
    };

    return FileRequests.exportFile(body)
      .then(() => dispatch(remainsTableRootActions.setRowSelection({})));
  },
);

export const exportAllRemainsFiles = createAsyncThunkWrapper<
  void,
  undefined
>(
  `${remainsPageModuleName}/exportAllRemainsFiles`,
  async (_, { getState }) => {

    const filters = remainsTableSelectors.selectFilters(getState());
    const searchString = remainsTableSelectors.selectSearchString(getState());

    const filterParams = buildRemainsFilterExpression(filters, searchString);

    const body: AllFilesExportBody = {
      exportType: ExtraFileType.RemainingMed,
      filter: filterParams,
    };

    await FileRequests.exportFile(body);
  },
);

export const unboxRemainInit = createAsyncThunkWrapper<
  UnboxRemainInitResponse,
  undefined
>(
  `${remainsPageModuleName}/unboxRemainInit`,
  (_, { getState }) => {
    const sscc = RemainsPageSelectors.selectSscc(getState());
    const isRecursive = RemainsPageSelectors.selectIsRecursive(getState());

    const body = {
      sendType: unboxRemainInitSendType,
      sscc,
      isRecursive
    };

    return RemainsPageRequests.unboxRemainInit(body)
      .then((res) => res.data);
  });

export const getUnboxRemainSignature = createAsyncThunkWrapper<
  string,
  string
>(
  `${remainsPageModuleName}/getUnboxRemainSignature`,
  (content, { getState }) => {
    const selectedSertificate = CertificatesSelectors.selectSelectedCertificate(getState());
    return getSignatureByCertificateOwner(selectedSertificate.owner, atob(content));
  }
);

export const sendUnboxRemainSignature = createAsyncThunkWrapper<
  void,
  SendDocumentRemainSignatureParams
>(
  `${remainsPageModuleName}/sendUnboxRemainSignature`,
  async ({ documentUuid, signature }) => {
    await DocumentRequests.sendDocument(documentUuid, signature);
  }
);