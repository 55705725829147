import { ContactType } from '@/modules/auth/models/contact-type';
import { PasswordRecoveryCompleteBody } from '@/modules/auth/models/password-recovery-complete/password-recovery-complete-body';
import { PasswordRecoveryInitResponse } from '@/modules/auth/models/password-recovery-init/password-recovery-init-response';
import { Operation } from '@/modules/auth/models/verification-code-send/operation';
import { VerificationCodeSendBody } from '@/modules/auth/models/verification-code-send/verification-code-send-body';
import { VerificationCodeSendResponse } from '@/modules/auth/models/verification-code-send/verification-code-send-response';
import { AuthRequests } from '@/modules/auth/requests/auth-requests';
import { PasswordRecoverySelectors } from '@/modules/auth/store/password-recovery/selectors';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const sendVerificationCode = createAsyncThunkWrapper<
  VerificationCodeSendResponse,
  undefined
>(
  'passwordRecovery/sendVerificationCode',
  (_, { getState }) => {
    const state = getState();
    const contact = PasswordRecoverySelectors.selectPreprocessedLogin(state);

    const body: VerificationCodeSendBody = {
      contactType: ContactType.PhoneNumber,
      operation: Operation.PasswordRecovery,
      contact,
    };
    return AuthRequests.verificationCodeSend(body)
      .then(res => res.data);
  });

export const passwordRecoveryInit = createAsyncThunkWrapper<
  PasswordRecoveryInitResponse,
  undefined
>(
  'passwordRecovery/init',
  (_, { getState }) => {
    const state = getState();
    const phoneNumber = PasswordRecoverySelectors.selectPreprocessedLogin(state);
    const verificationCode = PasswordRecoverySelectors.selectCode(state);

    return AuthRequests.passwordRecoveryInit({ phoneNumber, verificationCode })
      .then((res) => res.data);
  }
);

export const passwordRecoveryComplete = createAsyncThunkWrapper<
  void,
  PasswordRecoveryCompleteBody
>(
  'passwordRecovery/complete',
  async (body) => { await AuthRequests.passwordRecoveryComplete(body); },
);
