import { remainsPageModuleName } from "@/modules/remains/constants/remains-module-names";
import { exportAllRemainsFiles, exportSelectedRemainsFiles } from "@/modules/remains/store/page/async-thunks";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InitialUnboxState {
  sscc: string;
  isRecursive: boolean;
  isDialogOpenedByRowActions: boolean;
}

interface InitialState {
  isExportSelectedInProcess: boolean;
  isExportAllInProcess: boolean;
  unboxRemain: InitialUnboxState;
}

const initialState: InitialState = {
  isExportSelectedInProcess: false,
  isExportAllInProcess: false,
  unboxRemain: {
    sscc: undefined,
    isRecursive: false,
    isDialogOpenedByRowActions: false,
  }
};

export const remainsPageSlice = createSlice({
  name: remainsPageModuleName,
  initialState,
  reducers: {
    setIsExportSelectedInProcess(state, action: PayloadAction<boolean>) {
      state.isExportSelectedInProcess = action.payload;
    },
    setIsExportAllInProcess(state, action: PayloadAction<boolean>) {
      state.isExportAllInProcess = action.payload;
    },
    setSscc(state, action: PayloadAction<string>) {
      state.unboxRemain.sscc = action.payload;
    },
    setIsRecursive(state, action: PayloadAction<boolean>) {
      state.unboxRemain.isRecursive = action.payload;
    },
    setIsDialogOpenedByRowActions(state, action: PayloadAction<boolean>) {
      state.unboxRemain.isDialogOpenedByRowActions = action.payload;
    },
    resetUnboxState(state) {
      state.unboxRemain = initialState.unboxRemain;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof InitialState) => {
          // @ts-ignore
          state[key] = initialState[key];
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportSelectedRemainsFiles.pending, (state) => {
        state.isExportSelectedInProcess = true;
      })
      .addCase(exportSelectedRemainsFiles.fulfilled, (state) => {
        state.isExportSelectedInProcess = false;
      })
      .addCase(exportSelectedRemainsFiles.rejected, (state) => {
        state.isExportSelectedInProcess = false;
      })
      .addCase(exportAllRemainsFiles.pending, (state) => {
        state.isExportAllInProcess = true;
      })
      .addCase(exportAllRemainsFiles.fulfilled, (state) => {
        state.isExportAllInProcess = false;
      })
      .addCase(exportAllRemainsFiles.rejected, (state) => {
        state.isExportAllInProcess = false;
      });
  }
});

export const {
  setIsExportAllInProcess,
  setIsExportSelectedInProcess,
  setIsRecursive,
  setSscc,
  setIsDialogOpenedByRowActions,
  resetUnboxState,
  resetState
} = remainsPageSlice.actions;

export default remainsPageSlice.reducer;