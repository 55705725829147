import { AppButton } from '@/common/components/app-button/AppButton';
import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLink } from '@/common/components/app-link/AppLink';
import { phoneMask } from '@/common/constants/masks';
import { AgreementWithRules } from '@/modules/auth/components/agreement-with-rules/AgreementWithRules';
import { Logo } from '@/modules/auth/components/logo/Logo';
import { PasswordConfirmation } from '@/modules/auth/components/password-confirmation/PasswordConfirmation';
import { useConfirmPasswordValidation } from '@/modules/auth/hooks/use-confirm-password-validation';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import React, { FC, memo, useCallback, useMemo } from 'react';
import './set-new-password-form.scss';

export interface SetNewPasswordFormProps {
  title: string;
  passwords: PasswordsConfirmation;
  phoneNumber?: string;
  showAgreementWithRules?: boolean;
  agreementWithRules?: boolean;
  onChangePasswords: (passwords: PasswordsConfirmation) => void;
  onSavePasswordClick: (password: string) => void;
  onChangeAgreementWithRules?: (agreementWithRules: boolean) => void;
  onGoBackClick?: () => void;
  onLicenseAgreementClick?: () => void;
}

const SetNewPasswordFormInner: FC<SetNewPasswordFormProps> = (
  { title, phoneNumber, showAgreementWithRules, passwords, agreementWithRules, onChangeAgreementWithRules, onChangePasswords, onGoBackClick, onSavePasswordClick, onLicenseAgreementClick }
) => {

  const data = useMemo(() => ({
    ...passwords,
    agreementWithRules: showAgreementWithRules && agreementWithRules
  }), [passwords, showAgreementWithRules, agreementWithRules]);

  const {
    passwordMaxLength,
    validationResult,
    validateForm,
  } = useConfirmPasswordValidation(data);

  const onSavePasswordClickInner = useCallback(() => {
    if (validateForm()) {
      onSavePasswordClick(passwords.password);
    }
  }, [passwords, agreementWithRules]);

  return (
    <div className="set-new-password-form">
      <div className="form-title">
        <Logo />
        <h2>{title}</h2>
      </div>
      {phoneNumber &&
        <AppInput
          className="hidden-phone"
          autoComplete="username"
          label="Номер телефона"
          value={phoneNumber}
          mask={phoneMask}
          readOnly
        />
      }
      <PasswordConfirmation
        passwords={passwords}
        passwordMaxLength={passwordMaxLength}
        validationResult={validationResult}
        onChangePasswords={onChangePasswords}
      />
      {showAgreementWithRules &&
        <AgreementWithRules
          checked={agreementWithRules}
          validationResult={validationResult?.agreementWithRules}
          className="form-agreement-with-rules"
          onChange={onChangeAgreementWithRules}
          onLinkClick={onLicenseAgreementClick}
        />
      }
      <div className="form-actions">
        <AppButton
          label="Сохранить и войти"
          className="sign-in-button"
          onClick={onSavePasswordClickInner}
        />
        {onGoBackClick && (
          <AppLink
            text="Отмена"
            onClick={onGoBackClick}
          />
        )}
      </div>
    </div>
  );
};

export const SetNewPasswordForm = memo(SetNewPasswordFormInner);
