import { Certificate } from '@/common/models/certificate';
import { CertificateListDialog } from '@/modules/certificates/components/certificates-list-dialog/CertificatesListDialog';
import { SessionDescription } from '@/modules/portal/components/extend-session/components/session-description/SessionDescription';
import React, { FC, memo } from 'react';

interface ExtendSessionCertificateListDialogProps {
  isDialogOpened: boolean;
  closeDialog: () => void;
  extendSession: (certificate: Certificate) => void;
}

const ExtendSessionCertificateListDialogInner: FC<ExtendSessionCertificateListDialogProps> = ({ isDialogOpened, closeDialog, extendSession }) => (
  <CertificateListDialog
    open={isDialogOpened}
    title="Продление сессии с МДЛП"
    subtitle={<>Для продолжения работы с Системой вставьте УКЭП в USB-разъем и нажмите &quot;Обновить&quot; <br /> или выберите из списка установленных в системе сертификатов</>}
    selectCertificateTitleButton="Продлить сессию"
    tooltipText={<SessionDescription />}
    closeDialog={closeDialog}
    onSelectCertificate={extendSession}
  />
);

export const ExtendSessionCertificateListDialog = memo(ExtendSessionCertificateListDialogInner);