import { removeNonDigits } from '@/common/utils/common/string-utils';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

const selectPasswordRecoveryState = (state: RootState) => state.auth.passwordRecovery;

const selectCode = (state: RootState) => selectPasswordRecoveryState(state).code;
const selectLogin = (state: RootState) => selectPasswordRecoveryState(state).login;
const selectIsLoading = (state: RootState) => selectPasswordRecoveryState(state).isLoading;
const selectShowPasswordRecoveryForm = (state: RootState) => selectPasswordRecoveryState(state).showPasswordRecoveryForm;
const selectCodeStatus = (state: RootState) => selectPasswordRecoveryState(state).codeStatus;
const selectEmail = (state: RootState) => selectPasswordRecoveryState(state).email;
const selectExpireTimePhone = (state: RootState) => selectPasswordRecoveryState(state).expireTimePhone;
const selectPasswords = (state: RootState) => selectPasswordRecoveryState(state).passwords;

const selectPreprocessedLogin = createSelector([selectLogin], (login) => removeNonDigits(login));
const selectExpireTimePhoneDate = createSelector([selectExpireTimePhone], (expireTimePhone) => parseISO(expireTimePhone));

export const PasswordRecoverySelectors = {
  selectCode,
  selectLogin,
  selectPreprocessedLogin,
  selectIsLoading,
  selectShowPasswordRecoveryForm,
  selectCodeStatus,
  selectEmail,
  selectPasswords,
  selectExpireTimePhoneDate,
};
