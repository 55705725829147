import { processesModuleName } from '@/modules/references/constants/references-module-names';
import { Process } from '@/modules/references/models/processes/process';
import { ReferencesRequests } from '@/modules/references/requests/references-requests';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllProcesses = createAsyncThunkWrapper<
  Process[],
  undefined
>(
  `${processesModuleName}/getAllProcesses`,
  () => ReferencesRequests.getAllProcesses()
    .then((res) => res.data.processes),
);
