import { AppDialogTransitionDuration } from '@/common/constants/app-dialog-constants';
import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useDialog } from '@/common/hooks/use-dialog';
import { LoadingStatus } from '@/common/models/loading-status';
import { CertificateListDialog } from '@/modules/certificates/components/certificates-list-dialog/CertificatesListDialog';
import { removeSelectedCertificate, setLoadingStatus } from '@/modules/certificates/store';
import { UnboxRemainBottomContent } from '@/modules/remains/components/unbox-remain-bottom-content/UnboxRemainBottomContent';
import { useUnboxRemainBottomContent } from '@/modules/remains/hooks/use-unbox-remain-bottom-content';
import { UnboxRemainInitResponse } from '@/modules/remains/models/unbox-remain-init/unbox-remain-init-response';
import { resetUnboxState } from '@/modules/remains/store/page';
import { getUnboxRemainSignature, sendUnboxRemainSignature, unboxRemainInit } from '@/modules/remains/store/page/async-thunks';
import { useAppDispatch } from '@/store';
import React, { useCallback } from 'react';

export const useUnboxRemainDialog = () => {
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const { asyncDispatch } = useAsyncDispatch();

  const { isDialogOpened, openDialog, closeDialog } = useDialog();

  const { validateForm, resetValidationResult, ...unboxRemainBottomContext } = useUnboxRemainBottomContent();

  const onSendDocumentToMdlp = useCallback(() => {
    let documentUuid: string;

    if (validateForm()) {
      dispatch(setLoadingStatus(LoadingStatus.Pending));

      const catchCb = () => {
        dispatch(setLoadingStatus(LoadingStatus.Rejected));
      };

      const sendUnboxRemainSignatureThenCb = () => {
        dispatch(setLoadingStatus(LoadingStatus.Fulfilled));
        closeDialogInner();
        toast('Отправлен запрос на разагрегирование короба');
      };

      const getUnboxRemainSignatureThenCb = (signature: string) => {
        // third request, send signature & documentUuid to mdlp
        asyncDispatch(
          () => sendUnboxRemainSignature({ documentUuid, signature }),
          sendUnboxRemainSignatureThenCb,
          catchCb
        );
      };

      const unboxRemainInitThenCb = (res: UnboxRemainInitResponse) => {
        documentUuid = res.documentUuid;

        // second request, get signature from decrypted xml-document
        asyncDispatch(
          () => getUnboxRemainSignature(res.content),
          getUnboxRemainSignatureThenCb,
          catchCb
        );
      };

      //first request, get encrypted xml-document & documentUuid
      asyncDispatch(
        unboxRemainInit,
        unboxRemainInitThenCb,
        catchCb
      );

    }
  }, [validateForm]);

  const closeDialogInner = useCallback(() => {
    closeDialog();
    setTimeout(() => {
      dispatch(removeSelectedCertificate());
      dispatch(resetUnboxState());
      resetValidationResult();
    }, AppDialogTransitionDuration);
  }, [closeDialog, resetValidationResult]);

  const unboxRemainDialog = <CertificateListDialog
    open={isDialogOpened}
    title="Разагрегирование третичной упаковки"
    subtitle={'Для разагрегирования третичной упаковки вставьте УКЭП в USB-разъем и нажмите Обновить" или выберите из списка установленных в системе сертификатов'}
    selectCertificateTitleButton="Разагрегировать"
    bottomContent={<UnboxRemainBottomContent {...unboxRemainBottomContext} />}
    closeDialog={closeDialogInner}
    onSelectCertificate={onSendDocumentToMdlp}
  />;

  return {
    unboxRemainDialog,
    openUnboxRemainDialog: openDialog
  };
};