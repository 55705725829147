import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { MdlpProductsFilter } from '@/modules/references/models/mdlp-products/mdlp-products-filter';

function getMdlpProductsRsqlFilter(filter: Partial<MdlpProductsFilter>): RsqlFilterModel<MdlpProduct>[] {
  return [
    {
      selector: 'producerName',
      operation: RsqlFilterOperations.ILike,
      value: filter.producer,
    },
    {
      selector: 'producerCountry',
      operation: RsqlFilterOperations.ILike,
      value: filter.country,
    },
    {
      selector: 'mnn',
      operation: RsqlFilterOperations.ILike,
      value: filter.nonProprietaryName
    },
    {
      selector: 'gnvlp',
      operation: RsqlFilterOperations.Equal,
      value: filter.vital
    },
    {
      selector: 'gtin',
      operation: RsqlFilterOperations.Equal,
      value: filter.gtin
    }
  ];
}

function getMdlpProductsSearchFilter(searchString: string): RsqlFilterModel<MdlpProduct>[] {
  return [
    {
      selector: 'name',
      operation: RsqlFilterOperations.EILike,
      value: searchString
    }
  ];
}

export function buildMdlpProductsFilterExpression(filter: Partial<MdlpProductsFilter>, searchString: string): string {
  const filterModel = getMdlpProductsRsqlFilter(filter);
  const searchModel = getMdlpProductsSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
