import { ErrorModel } from '@/common/models/error-model';
import commonDataSourcesReducer from '@/common/store/common-data-sources';
import authReducer from '@/modules/auth/store/auth-reducer';
import certificatesReducer from '@/modules/certificates/store';
import disposalsReducer from '@/modules/disposals/store/disposals-reducer';
import favoritesReducer from '@/modules/favorites/store';
import fileOperationsReducer from '@/modules/file-operations/store';
import interactionLogReducer from '@/modules/interaction-log/store/interaction-log-reducer';
import organizationProfileReducer from '@/modules/organization-profile/store/organization-profile-reducer';
import portalReducer from '@/modules/portal/store/portal-reducer';
import receiptsReducer from '@/modules/receipts/store/receipts-reducer';
import referencesReducer from '@/modules/references/store/references-reducer';
import remainsReducer from '@/modules/remains/store/remains-reducer';
import salesReducer from '@/modules/sale/store/sales-reducer';
import usersManagementReducer from '@/modules/users-management/store';
import sharedReducer from '@/shared/shared-reducer';
import { configureStore } from '@reduxjs/toolkit';
import { AsyncThunkAction, BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    commonDataSources: commonDataSourcesReducer,
    shared: sharedReducer,
    auth: authReducer,
    portal: portalReducer,
    favorites: favoritesReducer,
    interactionLog: interactionLogReducer,
    fileOperations: fileOperationsReducer,
    organizationProfile: organizationProfileReducer,
    references: referencesReducer,
    receipts: receiptsReducer,
    sales: salesReducer,
    disposals: disposalsReducer,
    remains: remainsReducer,
    usersManagement: usersManagementReducer,
    certificates: certificatesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  // @ts-ignore
  devTools: import.meta.env.VITE_DISABLE_DEV_TOOLS !== 'true',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type ThunkApi = BaseThunkAPI<RootState, unknown, AppDispatch, ErrorModel>;

export type RejectWithValue = ReturnType<ThunkApi['rejectWithValue']>;

export interface AsyncThunkConfig {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: ErrorModel;
}

export type AsyncAction = () => AsyncThunkAction<unknown, unknown, AsyncThunkConfig>;
