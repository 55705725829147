import { AppButton } from '@/common/components/app-button/AppButton';
import { BackButton } from '@/modules/portal/components/back-button/BackButton';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './not-found-page.scss';

interface NotFoundPageProps {
  showBackButton?: boolean;
  mainPageRoutePath?: string;
}

export const NotFoundPage: FC<NotFoundPageProps> = ({ showBackButton = true, mainPageRoutePath = '/' }) => {
  const navigate = useNavigate();

  const goToMainPage = useCallback(() => navigate(mainPageRoutePath), []);

  return (
    <div className="not-found-page-container">
      {showBackButton && <BackButton
        defaultRoute={mainPageRoutePath}
        className="back-button"
      />}
      <h1>Ошибка 404</h1>
      <h2>Извините, страница не существует.<br />Проверьте правильность введенного адреса</h2>
      <AppButton
        label="Вернуться на главную"
        onClick={goToMainPage}
      />
    </div>
  );
};
