import ChevronIcon from '@/assets/icons/chevron.svg?react';
import { AppButton } from '@/common/components/app-button/AppButton';
import { AppDropDownContainer } from '@/common/components/app-drop-down-container/AppDropDownContainer';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import React, { FC, ReactNode, memo, useMemo, useRef } from 'react';
import './app-filter-panel.scss';

export interface AppFilterPanelClasses {
  dropdown?: string;
  actions?: string;
}

interface AppFilterPanelProps {
  isOpen: boolean;
  disableApplyButton: boolean;
  disableClearButton: boolean;
  classes?: AppFilterPanelClasses;
  filterPanel?: ReactNode;
  onClearClick: () => void;
  onExpandClick: () => void;
  onApplyClick: () => void;
}

const AppFilterPanelInner: FC<AppFilterPanelProps> = ({
  isOpen, disableApplyButton, disableClearButton, classes, filterPanel, onClearClick, onExpandClick, onApplyClick
}) => {
  const filterPanelRef = useRef<HTMLDivElement>();
  const filterBottomToolbarRef = useRef<HTMLDivElement>();

  const filterContainerPadding = 16;

  const filterPanelMaxHeight = useMemo(() =>
    filterPanelRef.current?.clientHeight
    + filterContainerPadding * 2
    + filterBottomToolbarRef?.current?.clientHeight
    , [filterPanelRef?.current?.clientHeight, filterBottomToolbarRef?.current?.clientHeight]);

  return (
    <AppDropDownContainer
      isOpen={isOpen}
      maxHeight={filterPanelMaxHeight}
      className={`drop-down-container-filter-panel ${classes?.dropdown ?? ''}`}
    >
      <div
        className="filters-container"
        style={{
          padding: filterContainerPadding
        }}
      >
        <div
          className="component-filters"
          ref={filterPanelRef}
        >
          {filterPanel}
        </div>
        <div className={`filters-actions ${classes?.actions ?? ''}`}>
          <AppButton
            label="Очистить"
            disabled={disableClearButton}
            variant="outlined"
            onClick={onClearClick}
          />
          <AppButton
            label="Применить"
            disabled={disableApplyButton}
            variant="contained"
            onClick={onApplyClick}
          />
        </div>
      </div>
      <div
        className="bottom-toolbar"
        ref={filterBottomToolbarRef}
        onClick={onExpandClick}
      >
        <div className="app-filter-actions">
          <AppIconButton
            icon={<ChevronIcon className="chevron-icon-collapse" />}
            activeColor="primary"
            initialSize
          />
        </div>
      </div>
    </AppDropDownContainer>
  );
};

export const AppFilterPanel = memo(AppFilterPanelInner);
