import { UserPermission } from '@/modules/portal/models/user-permission';
import { UserProfileSelectors } from '@/modules/portal/store/user-profile/selectors';
import { hasRolesAccessByPermission } from '@/modules/portal/utils/has-roles-access-by-permission';
import { useSelector } from 'react-redux';

export const useUserPermissionsAccess = () => {
  const profileInfo = useSelector(UserProfileSelectors.selectProfileInfo);

  const isUserHasNoRoles = !profileInfo?.roles || profileInfo?.roles?.length === 0;

  const hasAccessByPermission = (accessPermission: UserPermission) => {
    const roles = profileInfo?.roles ?? [];
    return hasRolesAccessByPermission(roles, accessPermission);
  };

  return {
    isUserHasNoRoles,
    hasAccessByPermission,
  };
};
