import { AppConfirmDialog } from '@/common/components/app-confirm-dialog/AppConfirmDialog';
import { useDialog } from '@/common/hooks/use-dialog';
import { SuccessfulExportNotificationForm } from '@/shared/files/components/successful-export-notification-form/SuccessfulExportNotificationForm';
import '@/shared/files/styles/successful-export-notification-dialog.scss';
import React, { useCallback } from 'react';

export function useSuccessfulExportNotification() {
  const { isDialogOpened, openDialog, closeDialog } = useDialog();

  const onImportExportClick = useCallback(() => { closeDialog(); }, []);

  const successfulExportNotificationDialog =
    <AppConfirmDialog
      open={isDialogOpened}
      classes={{ dialog: 'successful-export-notification-dialog' }}
      title="Подготавливается документ для скачивания"
      message={<SuccessfulExportNotificationForm onImportExportClick={onImportExportClick} />}
      hideDeclineButton
      acceptButtonText="ОК"
      onClose={closeDialog}
    />;

  return {
    openExportNotificationDialog: openDialog,
    successfulExportNotificationDialog,
  };
}
