import { AppTableActionColumnName, AppTableSelectColumnName } from '@/common/constants/app-table-constants';
import { SavedTableConfig } from '@/common/models/saved-table-config/saved-table-config-state';
import { RemainsTableColumns } from '@/shared/remains/models/remains-table-columns';

const columnVisibility = {
  [RemainsTableColumns.Sgtin]: true,
  [RemainsTableColumns.Gtin]: true,
  [RemainsTableColumns.ActivityPlace]: true,
  [RemainsTableColumns.Name]: true,
  [RemainsTableColumns.Producer]: true,
  [RemainsTableColumns.ProducerCountry]: true,
  [RemainsTableColumns.SeriesNumber]: true,
  [RemainsTableColumns.ExpirationDate]: true,
  [RemainsTableColumns.Sscc]: true,
  [RemainsTableColumns.Document]: true,
};

const sorting = [
  {
    id: RemainsTableColumns.Sgtin,
    desc: true,
  }
];

const columnOrder = [
  AppTableSelectColumnName,
  RemainsTableColumns.Sgtin,
  RemainsTableColumns.Gtin,
  RemainsTableColumns.ActivityPlace,
  RemainsTableColumns.Name,
  RemainsTableColumns.Producer,
  RemainsTableColumns.ProducerCountry,
  RemainsTableColumns.SeriesNumber,
  RemainsTableColumns.ExpirationDate,
  RemainsTableColumns.Sscc,
  RemainsTableColumns.Document,
  AppTableActionColumnName,
];

const columnSizing = {
  [RemainsTableColumns.Sgtin]: 140,
  [RemainsTableColumns.Gtin]: 80,
  [RemainsTableColumns.ActivityPlace]: 80,
  [RemainsTableColumns.Name]: 100,
  [RemainsTableColumns.Producer]: 100,
  [RemainsTableColumns.ProducerCountry]: 80,
  [RemainsTableColumns.SeriesNumber]: 60,
  [RemainsTableColumns.ExpirationDate]: 60,
  [RemainsTableColumns.Sscc]: 90,
  [RemainsTableColumns.Document]: 100,
};

export const defaultRemainsTableConfig: SavedTableConfig = {
  columnVisibility,
  sorting,
  columnOrder,
  columnSizing,
};
