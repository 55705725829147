import legalEntitiesActivityPlacesReducer from '@/modules/references/store/legal-entities-activity-places/index';
import legalEntitiesReducer from '@/modules/references/store/legal-entities/index';
import mdlpProductsReducer from '@/modules/references/store/mdlp-products/index';
import nomenclatureMdlpComparisonReducer from '@/modules/references/store/nomenclature-mdlp-comparison/index';
import nomenclatureReducer from '@/modules/references/store/nomenclature/index';
import processesReducer from '@/modules/references/store/processes/index';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base references reducer.
 */
const referencesReducer = combineReducers({
  processes: processesReducer,
  legalEntities: legalEntitiesReducer,
  legalEntitiesActivityPlaces: legalEntitiesActivityPlacesReducer,
  mdlpProducts: mdlpProductsReducer,
  nomenclature: nomenclatureReducer,
  nomenclatureMdlpComparison: nomenclatureMdlpComparisonReducer
});

export default referencesReducer;
