import React, { useEffect } from 'react';
import { useAppDispatch } from '@/store';
import { DocumentsTable } from '@/shared/document/components/documents-table/DocumentsTable';
import { receiptsSelectors } from '@/modules/receipts/store/receipts/selectors';
import { receiptsApi } from '@/modules/receipts/store/receipts/async-thunks';
import {
  receiptsFilterConfigActions,
  receiptsPageableDataActions,
  receiptsRootActions,
  receiptsSearchingActions,
  receiptsTableActions,
  receiptsTableConfigActions,
} from '@/modules/receipts/store/receipts';

export const ReceiptsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(receiptsRootActions.resetState()); };
  }, []);

  return <DocumentsTable
    documentsSelectors={receiptsSelectors}
    documentsApi={receiptsApi}
    documentsTableActions={receiptsTableActions}
    documentsPageableDataActions={receiptsPageableDataActions}
    documentsFilterConfigActions={receiptsFilterConfigActions}
    documentsSearchingActions={receiptsSearchingActions}
    documentsTableConfigActions={receiptsTableConfigActions}
  />;
};
