import { getISOString } from '@/common/utils/common/date-utils';
import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { Remain } from '@/shared/remains/models/remain';
import { RemainsActivityPlaceFilter } from '@/shared/remains/models/remains-activity-place-filter';
import { RemainsFilter } from '@/shared/remains/models/remains-filter';

export function getRemainsRsqlFilter(filter: Partial<RemainsFilter>): RsqlFilterModel<Remain & RemainsActivityPlaceFilter>[] {
  return [
    {
      selector: 'placeUuid',
      operation: RsqlFilterOperations.In,
      value: filter.activityPlaces,
    },
    {
      selector: 'producer',
      operation: RsqlFilterOperations.ILike,
      value: filter.producer,
    },
    {
      selector: 'expirationDate',
      operation: RsqlFilterOperations.GreaterEq,
      value: getISOString(filter.expirationDateFrom),
    },
    {
      selector: 'expirationDate',
      operation: RsqlFilterOperations.LessEq,
      value: getISOString(filter.expirationDateTo),
    },
    {
      selector: 'gtin',
      operation: RsqlFilterOperations.Equal,
      value: filter.gtin,
    },
    {
      selector: 'sscc',
      operation: RsqlFilterOperations.Equal,
      value: filter.sscc,
    },
    {
      selector: 'seriesNumber',
      operation: RsqlFilterOperations.Equal,
      value: filter.seriesNumber,
    },
  ];
}

export function getRemainsSearchFilter(searchString: string): RsqlFilterModel<Remain>[] {
  return [
    {
      selector: 'sgtin',
      operation: RsqlFilterOperations.EILike,
      value: searchString,
    },
    {
      selector: 'name',
      operation: RsqlFilterOperations.EILike,
      value: searchString,
    },
    {
      selector: 'relatedDocNumber',
      operation: RsqlFilterOperations.EILike,
      value: searchString,
    },
  ];
}

export function buildRemainsFilterExpression(filter: Partial<RemainsFilter>, searchString: string): string {
  const filterModel = getRemainsRsqlFilter(filter);
  const searchModel = getRemainsSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
