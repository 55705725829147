import { AppInput } from '@/common/components/app-input/AppInput';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { AppMultiSelect } from '@/common/components/app-multi-select/AppMultiSelect';
import { phoneMask } from '@/common/constants/masks';
import { fioRegExp } from '@/common/constants/reg-exps';
import { SelectOption } from '@/common/models/app-select/select-option';
import { emailMaxLength, fioMaxLength, positionMaxLength } from '@/common/utils/client-validation/string-lengths';
import { FormValidationResult } from '@/common/utils/client-validation/validation-result';
import { FieldErrors } from '@/common/utils/server-validation/field-errors';
import { SaveNewUserErrorFields } from '@/modules/users-management/models/save-new-user/save-new-user-error-fields';
import { UserInfoFormData, UserInfoFormDataFields } from '@/modules/users-management/models/user-info/user-info-form-data';
import React, { FC, memo, useCallback } from 'react';
import './edit-user-form.scss';

interface EditUserFormProps {
  readOnly: boolean;
  userData: Partial<UserInfoFormData>;
  activityPlacesOptions?: SelectOption[];
  validationResult: FormValidationResult<UserInfoFormDataFields>;
  fieldErrors: FieldErrors<SaveNewUserErrorFields>;
  showActivityPlaces: boolean;
  onFieldChange: (field: UserInfoFormDataFields) => (value: UserInfoFormData[UserInfoFormDataFields]) => void;
}

const EditUserFormInner: FC<EditUserFormProps> = ({
  readOnly, userData, validationResult, activityPlacesOptions, fieldErrors, showActivityPlaces, onFieldChange
}) => {
  const onSecondNameChange = useCallback(onFieldChange('secondName'), []);
  const onFirstNameChange = useCallback(onFieldChange('firstName'), []);
  const onLastNameChange = useCallback(onFieldChange('lastName'), []);
  const onPhoneNumberChange = useCallback(onFieldChange('phoneNumber'), []);
  const onEmailChange = useCallback(onFieldChange('email'), []);
  const onPositionChange = useCallback(onFieldChange('position'), []);
  const onActivityPlacesChange = useCallback(onFieldChange('activityPlaces'), []);

  return userData ? (
    <div className="edit-user-content">
      <AppInput
        label="Фамилия"
        value={userData.lastName}
        maxLength={fioMaxLength}
        readOnly={readOnly}
        error={validationResult.lastName?.error}
        helperText={validationResult.lastName?.messages?.[0]}
        regExp={fioRegExp}
        onValueChange={onLastNameChange}
      />
      <AppInput
        label="Имя"
        value={userData.firstName}
        maxLength={fioMaxLength}
        readOnly={readOnly}
        error={validationResult.firstName?.error}
        helperText={validationResult.firstName?.messages?.[0]}
        regExp={fioRegExp}
        onValueChange={onFirstNameChange}
      />
      <AppInput
        label="Отчество"
        value={userData.secondName}
        maxLength={fioMaxLength}
        readOnly={readOnly}
        error={validationResult.secondName?.error}
        helperText={validationResult.secondName?.messages?.[0]}
        regExp={fioRegExp}
        onValueChange={onSecondNameChange}
      />
      {showActivityPlaces && <AppMultiSelect
        options={activityPlacesOptions}
        value={userData?.activityPlaces}
        readOnly={readOnly}
        maxWidth={600}
        className="activity-places"
        label="Места деятельности"
        error={validationResult.activityPlaces?.error}
        helperText={validationResult.activityPlaces?.messages?.[0]}
        onChange={onActivityPlacesChange}
      />}
      <AppInput
        label="Должность"
        value={userData.position}
        maxLength={positionMaxLength}
        readOnly={readOnly}
        error={validationResult.position?.error}
        helperText={validationResult.position?.messages?.[0]}
        onValueChange={onPositionChange}
      />
      <AppInput
        label="Номер телефона"
        value={userData.phoneNumber}
        mask={phoneMask}
        readOnly={readOnly}
        error={validationResult.phoneNumber?.error || fieldErrors?.phoneNumber?.error}
        helperText={validationResult.phoneNumber?.messages?.[0] || fieldErrors?.phoneNumber?.message}
        onValueChange={onPhoneNumberChange}
      />
      <AppInput
        label="Email"
        value={userData.email}
        maxLength={emailMaxLength}
        readOnly={readOnly}
        error={validationResult.email?.error || fieldErrors?.email?.error}
        helperText={validationResult.email?.messages?.[0] || fieldErrors?.email?.message}
        onValueChange={onEmailChange}
      />
    </div>
  ) : <AppLoader className="edit-user-form-loader" />;
};

export const EditUserForm = memo(EditUserFormInner);
