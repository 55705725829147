import { LoadingStatus } from '@/common/models/loading-status';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { portalUserProfileModuleName } from '@/modules/portal/constants/portal-module-names';
import { userProfileStorageKeys } from '@/modules/portal/constants/user-profile-storage-keys';
import { UserProfileInfo } from '@/modules/portal/models/user-profile-info';
import { getUserProfileInfo, passwordChange } from '@/modules/portal/store/user-profile/async-thunks';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

interface UserProfileState {
  profileInfo?: UserProfileInfo;
  profileInfoLoadingStatus: LoadingStatus;
  changePasswordDialogOpen: boolean;
  changePasswordDialogIsLoading: boolean;
}

const initialState: UserProfileState = {
  profileInfo: undefined,
  profileInfoLoadingStatus: undefined,
  changePasswordDialogOpen: false,
  changePasswordDialogIsLoading: false,
};

export const userProfileSlice = createSlice({
  name: portalUserProfileModuleName,
  initialState,
  reducers: {
    setChangePasswordDialogOpen(state, action: PayloadAction<boolean>) {
      state.changePasswordDialogOpen = action.payload;
    },
    setUserUuidToSessionStorageInner(state, action: PayloadAction<string>) {
      sessionStorage.setItem(userProfileStorageKeys.USER_UUID, action.payload);
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof UserProfileState) => {
          // @ts-ignore
          state[key] = initialState[key];
        });
      Object.keys(userProfileStorageKeys).forEach((key: keyof typeof userProfileStorageKeys) =>
        sessionStorage.removeItem(userProfileStorageKeys[key])
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileInfo.pending, (state) => {
        state.profileInfoLoadingStatus = LoadingStatus.Pending;
      })
      .addCase(getUserProfileInfo.fulfilled, (state, action: PayloadAction<UserProfileInfo>) => {
        state.profileInfo = action.payload;
        state.profileInfoLoadingStatus = LoadingStatus.Fulfilled;
      })
      .addCase(getUserProfileInfo.rejected, (state) => {
        state.profileInfoLoadingStatus = LoadingStatus.Rejected;
      })
      .addCase(passwordChange.pending, (state) => {
        state.changePasswordDialogIsLoading = true;
      })
      .addCase(passwordChange.fulfilled, (state) => {
        state.changePasswordDialogIsLoading = false;
      })
      .addCase(passwordChange.rejected, (state) => {
        state.changePasswordDialogIsLoading = false;
      })
      .addCase(setUserUuidToSessionStorage, (state, action: PayloadAction<string>) => {
        const { setUserUuidToSessionStorageInner } = userProfileSlice.caseReducers;
        setUserUuidToSessionStorageInner(state, getReducerAction(setUserUuidToSessionStorageInner.name, action.payload));
      });
  }
});

/**
 * Use the action when table in module has optional columns by user roles. It has to update tableConfig of module (column visibility, size and sorting are personal settings for users)
 */
export const setUserUuidToSessionStorage = createAction<string>('setUserUuidToSessionStorage');

export const {
  setChangePasswordDialogOpen,
  resetState
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
