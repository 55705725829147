import CancelIcon from '@/assets/icons/common/cancel.svg?react';
import DeleteIcon from '@/assets/icons/common/delete.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { DocumentCardNoteItemBase } from '@/shared/document/components/document-card-notes/components/document-card-note-item-base/DocumentCardNoteItemBase';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import React, { FC, memo, useCallback } from 'react';
import './document-card-note-item-deleted.scss';

export interface DocumentCardNoteItemDeletedProps {
  note: DocumentCardNote;
  showActions: boolean;
  isRevertingInProgress: boolean;
  notClickableActions?: boolean;
  onRevertClick: (documentNoteUuid: string) => void;
}

const DocumentCardNoteItemDeletedInner: FC<DocumentCardNoteItemDeletedProps> = ({ note, showActions, isRevertingInProgress, notClickableActions, onRevertClick }) => {

  const onRevertClickInner = useCallback(() => {
    onRevertClick(note.documentNoteUuid);
  }, []);

  return (
    <DocumentCardNoteItemBase
      note={note}
      className="deleted-note"
      actions={
        showActions &&
        <>
          <AppIconButton
            icon={<CancelIcon />}
            initialSize
            className="note-icon"
            activeColor="primary"
            tooltip="Восстановить"
            isLoading={isRevertingInProgress}
            notClickable={notClickableActions}
            onClick={onRevertClickInner}
          />
          <AppIconButton
            icon={<DeleteIcon className="delete-icon" />}
            initialSize
            className="note-icon"
            activeColor="primary"
            notClickable={notClickableActions}
          />
        </>
      }
      content={note.noteText}
    />
  );
};

export const DocumentCardNoteItemDeleted = memo(DocumentCardNoteItemDeletedInner);
