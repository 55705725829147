import { ScanningViewInfoItem } from '@/shared/scanning-view/components/scanning-view-info/scanning-view-info-item/ScanningViewInfoItem';
import { ScanningViewInfoStatusIcon } from '@/shared/scanning-view/components/scanning-view-info/scanning-view-info-status-icon/ScanningViewInfoStatusIcon';
import { getScanningStatusLabel, isErrorScanningStatus, ScanningStatus } from '@/shared/scanning-view/models/scanning-status';
import { ScanningItemProps } from '@/shared/scanning-view/ScanningView';
import React, { FC, memo, useMemo } from 'react';
import './scanning-view-info.scss';

type ScanningViewInfoProps = Pick<ScanningItemProps, 'lastScannedItem'>;

const ScanningViewInfoInner: FC<ScanningViewInfoProps> = ({ lastScannedItem }) => {

  const scanningViewInfoStatusClassName = useMemo(() =>
    `scanning-view-info-status ${isErrorScanningStatus(lastScannedItem?.state) ? 'error' : ''}`
    , [lastScannedItem?.state]);

  const showSeriesHint = lastScannedItem?.state === ScanningStatus.MedicineScannedBeAcceptedLater;
  const seriesHint = 'Серия появится позже, когда данные о товаре будут загружены из МДЛП';

  return (
    <div className="scanning-view-info">
      <h2 className="scanning-view-info-label">Информация о последнем отсканированном товаре</h2>
      {lastScannedItem?.state && (
        <span className={scanningViewInfoStatusClassName}>
          <ScanningViewInfoStatusIcon status={lastScannedItem?.state} />
          {getScanningStatusLabel(lastScannedItem?.state)}
        </span>
      )}
      <ScanningViewInfoItem
        label="GTIN"
        value={lastScannedItem?.gtin}
        className="scanning-view-info-item-block"
      />
      <ScanningViewInfoItem
        label="Серия"
        value={lastScannedItem?.seriesNumber}
        className="scanning-view-info-item-block"
        hint={showSeriesHint && seriesHint}
      />
      <ScanningViewInfoItem
        label="Наименование"
        value={lastScannedItem?.medicineName}
        className="scanning-view-info-item-block"
      />
      <ScanningViewInfoItem
        label="Производитель"
        value={lastScannedItem?.producer}
        className="scanning-view-info-item-block"
      />
    </div>
  );
};

export const ScanningViewInfo = memo(ScanningViewInfoInner);
