import { sharedFavoritesModuleName } from '@/shared/favorites/constants/shared-favorites-module-name';
import { addToFavorite, deleteFromFavorite } from '@/shared/favorites/store/async-thunks';
import { createSlice } from '@reduxjs/toolkit';

interface SharedFavoritesState {
  favoriteDocumentsInProgress: Record<string, true>;
}

const initialState: SharedFavoritesState = {
  favoriteDocumentsInProgress: {}
};

const sharedFavoritesSlice = createSlice({
  name: sharedFavoritesModuleName,
  initialState,
  reducers: {
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof SharedFavoritesState) => {
          // @ts-ignore
          state[key] = initialState[key];
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToFavorite.pending, (state, action) => {
        state.favoriteDocumentsInProgress[action.meta.arg] = true;
      })
      .addCase(addToFavorite.fulfilled, (state, action) => {
        delete state.favoriteDocumentsInProgress[action.meta.arg];
      })
      .addCase(addToFavorite.rejected, (state, action) => {
        delete state.favoriteDocumentsInProgress[action.meta.arg];
      })
      .addCase(deleteFromFavorite.pending, (state, action) => {
        state.favoriteDocumentsInProgress[action.meta.arg] = true;
      })
      .addCase(deleteFromFavorite.fulfilled, (state, action) => {
        delete state.favoriteDocumentsInProgress[action.meta.arg];
      })
      .addCase(deleteFromFavorite.rejected, (state, action) => {
        delete state.favoriteDocumentsInProgress[action.meta.arg];
      });
  }
});

export const {
  resetState,
} = sharedFavoritesSlice.actions;

export default sharedFavoritesSlice.reducer;
