import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { DocumentsState } from '@/shared/document/store/documents/index';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

interface DocumentSelectorsParams {
  selectDocumentsState: (state: RootState) => DocumentsState;
}

export const createDocumentSelectors = ({ selectDocumentsState }: DocumentSelectorsParams) => {
  const selectIsExportInProcess = (state: RootState) => selectDocumentsState(state).table.isExportInProcess;
  const selectIsUploadingInProcess = (state: RootState) => selectDocumentsState(state).table.isUploadingInProcess;
  const selectIsUpdatingInProcess = (state: RootState) => selectDocumentsState(state).table.isUpdatingInProcess;
  const selectUpdatedRowId = (state: RootState) => selectDocumentsState(state).table.updatedRowId;
  const selectRowsSelection = (state: RootState) => selectDocumentsState(state).table.rowsSelection;
  const selectDocType = (state: RootState) => selectDocumentsState(state).table.docType;
  const selectVisibleColumns = (state: RootState) => selectDocumentsState(state).table.visibleColumns;

  const selectSearchState = (state: RootState) => selectDocumentsState(state).search;
  const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

  const selectFilterConfig = (state: RootState) => selectDocumentsState(state).filterConfig;
  const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
  const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
  const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);

  const selectSavedTableConfig = (state: RootState) => selectDocumentsState(state).tableConfig.config;
  const selectTableSorting = (state: RootState) => selectSavedTableConfig(state).sorting;

  const selectPageableDataState = (state: RootState) => selectDocumentsState(state).pageableData;
  const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
  const selectTotalItems = createSelector([selectPageableDataState], PageableDataSelectors.selectTotalItems);
  const selectIsLoadingData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

  const selectTableConfig = createSelector(
    [selectRowsSelection, selectSavedTableConfig],
    (rowSelection, savedTableConfig) => ({ rowSelection, ...savedTableConfig })
  );

  return {
    selectDocumentsState,
    selectIsExportInProcess,
    selectIsUploadingInProcess,
    selectIsUpdatingInProcess,
    selectUpdatedRowId,
    selectRowsSelection,
    selectDocType,
    selectVisibleColumns,
    selectSearchState,
    selectSearchString,
    selectFilterConfig,
    selectIsPanelOpen,
    selectFilters,
    selectSelectedFiltersCount,
    selectTableConfig,
    selectTableSorting,
    selectPageableDataState,
    selectData,
    selectTotalItems,
    selectIsLoadingData,
  };
};
