import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { usersManagementPageableDataActions } from '@/modules/users-management/store';
import { getAllUsers } from '@/modules/users-management/store/async-thunks';
import { UsersManagementSelectors } from '@/modules/users-management/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useUsersManagementTableDataSource() {
  const sorting = useSelector(UsersManagementSelectors.selectTableSorting);
  const showBlockedUsers = useSelector(UsersManagementSelectors.selectShowBlockedUsers);

  const resetParams = useMemo(() => ({ sorting, showBlockedUsers }), [sorting, showBlockedUsers]);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: usersManagementPageableDataActions,
    selectPageableDataState: UsersManagementSelectors.selectPageableDataState,
    getData: getAllUsers,
    resetCondition: resetParams,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
