import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { changeFilterOpenState, fileOperationsFilterConfigActions } from '@/modules/file-operations/store';
import { FileOperationsSelectors } from '@/modules/file-operations/store/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type FileOperationsTableFilteringHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useFileOperationsTableFiltering({ loadFirstPage }: FileOperationsTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData({
    filterConfigActions: fileOperationsFilterConfigActions,
    selectFilterConfig: FileOperationsSelectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
