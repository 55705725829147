import { FormValidationResult } from '@/common/utils/client-validation/validation-result';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Validation hook params,
 * T - form data model,
 * V - keys of form data model.
 */
interface FormValidationHookParams<T, V extends keyof T & string> {
  validationRules: FormValidationRules<T, V>;
  data: Partial<T>;
  fields?: Set<V>;
}

/**
 * Validation form hook's return value.
 */
export interface FormValidationReturnValue<T, V extends keyof T & string> {
  validationResult: FormValidationResult<V>;
  validateField: (fieldName: V) => void;
  validateForm: () => boolean;
  isFormValid: boolean;
  resetValidationResult: () => void;
}

/**
 * Validation forms hook.
 */
export function useFormValidation<T, V extends keyof T & string>(params: FormValidationHookParams<T, V>): FormValidationReturnValue<T, V> {
  const { validationRules, data, fields } = params;
  const formFields = fields ?? new Set(Object.keys(validationRules));

  const [validationResult, setValidationResult] = useState<FormValidationResult<V>>({});

  const validForm = (validationMap: FormValidationResult<V>) => (
    Object.keys(validationMap).every((key: V) => (!validationMap[key]?.error))
  );

  const isFormValid = useMemo(() => validForm(validationResult), [validationResult]);

  const getFieldValidationResult = (fieldName: V) => {
    const value = data[fieldName];
    const errors: string[] = [];
    validationRules[fieldName].forEach((validator) => {
      if (!validator.validationCallback(value)) {
        errors.push(validator.message);
      }
    });
    return {
      error: errors.length > 0,
      messages: errors,
    };
  };

  const [validatedField, setValidatedField] = useState<V>();
  const validateField = (fieldName: V) => {
    setValidatedField(fieldName);
  };

  useEffect(() => {
    if (validatedField) {
      setValidationResult({
        ...validationResult,
        [validatedField]: getFieldValidationResult(validatedField)
      });
      setValidatedField(undefined);
    }
  }, [validatedField]);

  const validateForm = () => {
    const result: FormValidationResult<V> = {};
    formFields.forEach((key: V) => {
      result[key] = getFieldValidationResult(key);
    });
    setValidationResult(result);
    return validForm(result);
  };

  const resetValidationResult = () => {
    setValidationResult({});
    firstRenderCounter.current = formFields.size;
  };

  const firstRenderCounter = useRef(formFields.size);

  const startWatchingForFields = () => {
    formFields.forEach((key: V) => {
      useEffect(() => {
        if (firstRenderCounter.current > 0) {
          firstRenderCounter.current -= 1;
          return;
        }
        setValidationResult({
          ...validationResult,
          [key]: getFieldValidationResult(key)
        });
      }, [data[key]]);
    });
  };

  startWatchingForFields();

  return {
    validationResult,
    validateField,
    validateForm,
    isFormValid,
    resetValidationResult
  };
}
