import Plus from '@/assets/icons/controls/rect_plus.svg?react';
import { AppMultiSelectChip } from '@/common/components/app-multi-select/components/app-multi-select-chip/AppMultiSelectChip';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { SelectOption } from '@/common/models/app-select/select-option';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import './app-multi-select.scss';

interface AppMultiSelectProps {
  options: SelectOption[];
  className?: string;
  value: string[];
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  maxWidth?: number;
  error?: boolean;
  helperText?: string;
  onChange: (value: string[]) => void;
}

type MapOptions = Map<SelectOption['value'], SelectOption['label']>;

const AppMultiSelectInner: FC<AppMultiSelectProps> = ({
  options,
  className,
  value = [],
  label,
  disabled = false,
  readOnly = false,
  maxWidth,
  error = false,
  helperText,
  onChange,
}) => {

  const [open, setOpen] = useState(false);

  const mapOptions: MapOptions = useMemo(() =>
    options.reduce((acc, option: SelectOption) => {
      return acc.set(option.value, option.label);
    }, new Map())
    , [options]);

  const onChangeInner = useCallback((event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    onChange(newValue);
  }, []);

  const onDelete = useCallback((deletedOptionValue: string) => {
    const newValue = value.filter((item) => item !== deletedOptionValue);
    onChange(newValue);
  }, [value]);

  const toggleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <FormControl className={`app-multi-select-form-control  ${className ?? ''}`}>
      <InputLabel
        id="app-multi-select-label"
        className="app-select-label app-multi-select-label"
        disabled={disabled}
        error={error}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        open={open}
        readOnly={readOnly}
        onClick={(e) => e.stopPropagation()}
        onChange={onChangeInner}
        multiple
        className="app-select app-multi-select"
        label={label}
        labelId="app-multi-select-label"
        disabled={disabled}
        error={error}
        startAdornment={
          <InputAdornment
            position="start"
            className="app-multi-select-input-adornment"
            disablePointerEvents={readOnly}
            onClick={toggleOpen}
          >
            <Plus className="app-multi-select-plus-icon" />
          </InputAdornment>
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
        renderValue={(selected) => (
          <div className="app-multi-select-selected-items">
            {selected.map((value) => (
              <AppMultiSelectChip
                key={value}
                value={value}
                readOnly={readOnly}
                label={mapOptions.get(value)}
                onDelete={onDelete}
              />
            ))}
          </div>
        )}
        onOpen={toggleOpen}
        onClose={toggleOpen}
      >
        {options.map((option) => (
          <MenuItem
            className="app-select-menu-item"
            key={option.value?.toString()}
            value={option.value as any}
            style={{
              maxWidth
            }}
          >
            <AppTooltip
              text={option.label}
              open="onlyOnOverflow"
            >
              <span className="ellipsis-text">{option.label}</span>
            </AppTooltip>
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText
          className="app-select-form-helper-text"
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const AppMultiSelect = memo(AppMultiSelectInner);
