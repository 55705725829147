import { RemainsExportMenu, RemainsExportMenuProps } from '@/modules/remains/components/remains-export-menu/RemainsExportMenu';
import React, { FC, memo } from 'react';
import './remains-filter-actions.scss';

type RemainsFilterActions = RemainsExportMenuProps & { onUnboxClick: () => void; };

const RemainsFilterActionsInner: FC<RemainsFilterActions> = ({ openDialog, onUnboxClick }) => {
  return (
    <div className="remains-filter-actions">
      <RemainsExportMenu openDialog={openDialog} />
    </div>
  );
};

export const RemainsFilterActions = memo(RemainsFilterActionsInner);
