import { ErrorModel } from '@/common/models/error-model';
import { ContactType } from '@/modules/auth/models/contact-type';
import { RegistrationFormCodesFields } from '@/modules/auth/models/registration-form-codes';
import { SignupAuthCodeBody } from '@/modules/auth/models/signup-auth-code/signup-auth-code-body';
import { SignupAuthCodeReturnValue } from '@/modules/auth/models/signup-auth-code/signup-auth-code-return-value';
import { SignupConfirmContactBody } from '@/modules/auth/models/signup-confirm-contact/signup-confirm-contact-body';
import { SignupInitBody } from '@/modules/auth/models/signup-init/signup-init-body';
import { SignupInitResponse } from '@/modules/auth/models/signup-init/signup-init-response';
import { Operation } from '@/modules/auth/models/verification-code-send/operation';
import { VerificationCodeSendBody } from '@/modules/auth/models/verification-code-send/verification-code-send-body';
import { AuthRequests } from '@/modules/auth/requests/auth-requests';
import { setIsEmailConfirmContactInProgress, setIsPhoneNumberConfirmContactInProgress } from '@/modules/auth/store/registration';
import { RegistrationSelectors } from '@/modules/auth/store/registration/selectors';
import { getFieldFromContactType } from '@/modules/auth/utils/contact-type-utils';
import { getSignatureByCertificateOwner } from '@/modules/certificates/utils/get-signature-by-certificate-owner';
import { RejectWithValue } from '@/store';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';
import { AxiosError } from 'axios';

export const signupValidateContacts = createAsyncThunkWrapper<
  SignupInitResponse,
  undefined
>(
  'registration/signup/validateContacts',
  (_, { getState }) => {
    const state = getState();
    const { email } = RegistrationSelectors.selectFormData(state);
    const phoneNumber = RegistrationSelectors.selectPreprocessedPhone(state);

    return AuthRequests.signupValidateContacts({ email, phoneNumber })
      .then((response) => response.data);
  }
);

export const signUpInit = createAsyncThunkWrapper<
  SignupAuthCodeBody,
  undefined
>(
  'registration/signup/init',
  (_, { getState }) => {
    const state = getState();
    const { firstName, secondName, lastName, email, position, clientId, clientSecret, selectedCertificate } = RegistrationSelectors.selectFormData(state);
    const phoneNumber = RegistrationSelectors.selectPreprocessedPhone(state);

    const body: SignupInitBody = {
      firstName,
      secondName,
      lastName,
      email,
      phoneNumber,
      position,
      clientId,
      clientSecret,
      thumbprint: selectedCertificate.thumbprint
    };

    return AuthRequests.signupInit(body)
      .then((res) =>
        getSignatureByCertificateOwner(selectedCertificate.owner, res.data.authMdlpCode)
          .then((signature: string) => {
            const bodyForSignupAuthCode: SignupAuthCodeBody = {
              authMdlpCode: res.data.authMdlpCode,
              userUuid: res.data.userUuid,
              signature
            };
            return bodyForSignupAuthCode;
          })
      );
  }
);

export const signupAuthCode = createAsyncThunkWrapper<
  SignupAuthCodeReturnValue,
  SignupAuthCodeBody
>(
  'registration/signup/authCode',
  (body) => {
    return AuthRequests.signupAuthCode(body)
      .then((res) => ({
        expireTimePhone: res.data.nextSmsCodeResend,
        expireTimeEmail: res.data.nextEmailCodeResend,
      }));
  }
);

export const confirmContact = createAsyncThunkWrapper<
  RegistrationFormCodesFields | RejectWithValue,
  SignupConfirmContactBody
>(
  'registration/confirm-contact',
  ({ verificationCode, contactType }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const userUuid = RegistrationSelectors.selectUserUuid(state);
    const contact = RegistrationSelectors.selectContactByType(state, contactType);

    const setIsContactTypeInProgress =
      contactType === ContactType.Email
        ? setIsEmailConfirmContactInProgress
        : setIsPhoneNumberConfirmContactInProgress;

    dispatch(setIsContactTypeInProgress(true));

    const body = { verificationCode, contactType, userUuid, contact };
    return AuthRequests.signupConfirmContact(body)
      .then((res) => {
        dispatch(setIsContactTypeInProgress(false));
        return getFieldFromContactType(res.data.contactType);
      })
      .catch((err: AxiosError<ErrorModel>) => {
        dispatch(setIsContactTypeInProgress(false));
        return rejectWithValue(err.response.data);
      });
  });

export const signupComplete = createAsyncThunkWrapper<
  void | RejectWithValue,
  void
>(
  'registration/signup/complete',
  async (_, { getState }) => {
    const state = getState();
    const password = RegistrationSelectors.selectPassword(state);
    const userUuid = RegistrationSelectors.selectUserUuid(state);
    await AuthRequests.signupComplete({ userUuid, password });
  });

export const sendPhoneCode = createAsyncThunkWrapper<
  string,
  void
>(
  'registration/sendPhoneCode',
  (_, { getState }) => {
    const state = getState();
    const contact = RegistrationSelectors.selectPreprocessedPhone(state);

    const body: VerificationCodeSendBody = {
      contactType: ContactType.PhoneNumber,
      operation: Operation.Registration,
      contact,
    };

    return AuthRequests.verificationCodeSend(body)
      .then((res) => res.data.nextCodeResend);
  });

export const sendEmailCode = createAsyncThunkWrapper<
  string,
  void
>(
  'registration/sendEmailCode',
  (_, { getState }) => {
    const state = getState();
    const { email } = RegistrationSelectors.selectFormData(state);

    const body: VerificationCodeSendBody = {
      contactType: ContactType.Email,
      operation: Operation.Registration,
      contact: email
    };

    return AuthRequests.verificationCodeSend(body)
      .then((res) => res.data.nextCodeResend);
  });
