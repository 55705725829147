import { AppButtonVariant } from '@/common/components/app-button/AppButton';

export enum DocumentCardActionButtonsType {
  StartProcesing = 'start-processing',
  Scan = 'scan',
  AddRemains = 'add-remains',
  CompleteProcesing = 'complete-processing',
  Recall = 'recall',
  Reject = 'reject',
  Accept = 'accept',
  Confirm = 'confirm',
  Send = 'send',
}

const documentCardActionButtonsVariants: Record<DocumentCardActionButtonsType, AppButtonVariant> = {
  [DocumentCardActionButtonsType.StartProcesing]: 'contained',
  [DocumentCardActionButtonsType.Confirm]: 'contained',
  [DocumentCardActionButtonsType.Reject]: 'outlined',
  [DocumentCardActionButtonsType.Scan]: 'outlined',
  [DocumentCardActionButtonsType.AddRemains]: 'outlined',
  [DocumentCardActionButtonsType.CompleteProcesing]: 'contained',
  [DocumentCardActionButtonsType.Recall]: 'outlined',
  [DocumentCardActionButtonsType.Accept]: 'contained',
  [DocumentCardActionButtonsType.Send]: 'contained',
};

const documentCardActionButtonsLabels: Record<DocumentCardActionButtonsType, string> = {
  [DocumentCardActionButtonsType.StartProcesing]: 'Начать обработку',
  [DocumentCardActionButtonsType.Scan]: 'Сканировать',
  [DocumentCardActionButtonsType.AddRemains]: 'Добавить остатки',
  [DocumentCardActionButtonsType.CompleteProcesing]: 'Завершить обработку',
  [DocumentCardActionButtonsType.Recall]: 'Отозвать ЛП',
  [DocumentCardActionButtonsType.Reject]: 'Отклонить',
  [DocumentCardActionButtonsType.Accept]: 'Принять',
  [DocumentCardActionButtonsType.Confirm]: 'Подтвердить',
  [DocumentCardActionButtonsType.Send]: 'Отправить',
};

export const getDocumentCardActionButtonsVariant = (type: DocumentCardActionButtonsType) => documentCardActionButtonsVariants[type];

export const getDocumentCardActionButtonsLabel = (type: DocumentCardActionButtonsType) => documentCardActionButtonsLabels[type];
