import { receiptCardScanningModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardScanningApi } from '@/modules/receipts/store/receipt-card/scanning/async-thunks';
import { createDocumentCardScanningSlice } from '@/shared/document/store/document-card/scanning';

export const receiptCardScanningSlice = createDocumentCardScanningSlice({
  moduleName: receiptCardScanningModuleName,
  savedTableConfigName: 'receipt-card-scanning-table',
  api: receiptCardScanningApi,
});

export const receiptCardScanningRootActions = receiptCardScanningSlice.rootSlice.actions;
export const receiptCardScanningPageableDataActions = receiptCardScanningSlice.documentCardScanningPageableDataSlice.actions;
export const receiptCardScanningSearchingActions = receiptCardScanningSlice.documentCardScanningSearchingSlice.actions;
export const receiptCardScanningTableConfigActions = receiptCardScanningSlice.documentCardScanningTableConfigSlice.actions;
export const receiptCardScanningFilterConfigSlice = receiptCardScanningSlice.documentCardScanningFilterConfigSlice.actions;

export default receiptCardScanningSlice.rootSlice.reducer;
