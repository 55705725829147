import { AppTableRowSelectionType } from '@/common/models/app-table/app-table-types';
import { formatCurrency } from '@/common/utils/common/formatting-utils';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';
import React, { FC, memo, useMemo } from 'react';
import './documents-table-bottom-toolbar.scss';

interface DocumentsTableBottomToolbarProps {
  rowsSelection: AppTableRowSelectionType;
  data: ShortDocumentInfo[];
}

const DocumentsTableBottomToolbarInner: FC<DocumentsTableBottomToolbarProps> = ({ rowsSelection, data }) => {
  const totalCount = Object.keys(rowsSelection).length;

  const totalSum = useMemo(() => {
    const selectedIds = Object.keys(rowsSelection);
    const selectedEntities = data.filter((value: ShortDocumentInfo) => selectedIds?.includes(value.docUuid));
    return selectedEntities.reduce((sum, cur) => sum + cur.totalPriceWithVat, 0);
  }, [rowsSelection]);

  const formattedTotalSum = useMemo(() => totalSum > 0 ? formatCurrency(totalSum) : '0', [totalSum]);

  return (
    <div className="documents-table-bottom-toolbar-content">
      <span className="total-selected">Выбрано: {totalCount}</span>
      <span className="total-sum">Сумма: {formattedTotalSum}</span>
    </div>
  );
};

export const DocumentsTableBottomToolbar = memo(DocumentsTableBottomToolbarInner);
