export enum ScanningStatus {
  MedicineFound = 'MEDICINE_FOUND',
  MedicineNotFound = 'MEDICINE_NOT_FOUND',
  MedicineScannedBeAcceptedLater = 'MEDICINE_SCANNED_BE_ACCEPTED_LATER',
  MedicineAlreadyScanned = 'MEDICINE_ALREADY_SCANNED',
  ScanningError = 'SCAN_ERROR',
}

export const scanningStatusLabels = {
  [ScanningStatus.MedicineFound]: 'Товар найден',
  [ScanningStatus.MedicineNotFound]: 'Товар не найден',
  [ScanningStatus.MedicineScannedBeAcceptedLater]: 'Товар отсканирован, детальная информация появится в системе позже',
  [ScanningStatus.MedicineAlreadyScanned]: 'Товар уже отсканирован',
  [ScanningStatus.ScanningError]: 'Ошибка сканирования'
};

export const getScanningStatusLabel = (status: ScanningStatus) => scanningStatusLabels[status];

const errorScanningStatuses = new Set([ScanningStatus.MedicineNotFound, ScanningStatus.ScanningError]);
export const isErrorScanningStatus = (status: ScanningStatus) => errorScanningStatuses.has(status);

const warningScanningStatuses = new Set([ScanningStatus.MedicineAlreadyScanned]);
export const isWarningScanningStatus = (status: ScanningStatus) => warningScanningStatuses.has(status);

const successfulScanningStatuses = new Set([ScanningStatus.MedicineFound, ScanningStatus.MedicineScannedBeAcceptedLater]);
export const isSuccessfulScanningStatus = (status: ScanningStatus) => successfulScanningStatuses.has(status);
