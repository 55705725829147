import { getISOString } from '@/common/utils/common/date-utils';
import { RsqlFilterModel } from '@/common/utils/rsql-filter/rsql-filter-model';
import { RsqlFilterOperations } from '@/common/utils/rsql-filter/rsql-filter-operations';
import { convertToFilterExpressions, joinFilterExpressions } from '@/common/utils/rsql-filter/rsql-filter-utils';
import { RsqlLogicalOperators } from '@/common/utils/rsql-filter/rsql-logical-operators';
import { DocumentsActivityPlaceFilter } from '@/shared/document/models/documents/documents-activity-place-filter';
import { DocumentsFilter } from '@/shared/document/models/documents/documents-filter';
import { DocumentsTypeFilter } from '@/shared/document/models/documents/documents-type-filter';
import { ShortDocumentInfo } from '@/shared/document/models/short-document-info';

const searchFields: (keyof ShortDocumentInfo)[] = ['docNumber', 'comment'];

function getDocumentsRsqlFilter(filter: Partial<DocumentsFilter & DocumentsTypeFilter>): RsqlFilterModel<ShortDocumentInfo & DocumentsTypeFilter & DocumentsActivityPlaceFilter>[] {
  return [
    {
      selector: 'docType',
      operation: RsqlFilterOperations.Equal,
      value: filter.docType,
    },
    {
      selector: 'ownPlaceUuid',
      operation: RsqlFilterOperations.In,
      value: filter.activityPlaces,
    },
    {
      selector: 'partnerOrganizationName',
      operation: RsqlFilterOperations.ILike,
      value: filter.legalEntity,
    },
    {
      selector: 'docDate',
      operation: RsqlFilterOperations.GreaterEq,
      value: getISOString(filter.creationDateFrom),
    },
    {
      selector: 'docDate',
      operation: RsqlFilterOperations.LessEq,
      value: getISOString(filter.creationDateTo),
    },
    {
      selector: 'totalPriceWithVat',
      operation: RsqlFilterOperations.GreaterEq,
      value: filter.sumFrom,
    },
    {
      selector: 'totalPriceWithVat',
      operation: RsqlFilterOperations.LessEq,
      value: filter.sumTo,
    },
    {
      selector: 'docOrder',
      operation: RsqlFilterOperations.Equal,
      value: filter.order,
    },
    {
      selector: 'docState',
      operation: RsqlFilterOperations.In,
      value: filter.statuses,
    },
  ];
}

function getDocumentsSearchFilter(searchString: string): RsqlFilterModel<ShortDocumentInfo>[] {
  return searchFields.map((field) => ({
    selector: field,
    operation: RsqlFilterOperations.ILike,
    value: searchString,
  }));
}

export function buildDocumentsFilterExpression(filter: Partial<DocumentsFilter & DocumentsTypeFilter>, searchString: string): string {
  const filterModel = getDocumentsRsqlFilter(filter);
  const searchModel = getDocumentsSearchFilter(searchString);

  const filterExpressions = convertToFilterExpressions(filterModel);
  const searchExpressions = convertToFilterExpressions(searchModel);

  return joinFilterExpressions([
    joinFilterExpressions(filterExpressions, RsqlLogicalOperators.And),
    joinFilterExpressions(searchExpressions, RsqlLogicalOperators.Or),
  ], RsqlLogicalOperators.And);
}
