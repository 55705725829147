import { RemainsFilterActions } from '@/modules/remains/components/remains-filter-actions/RemainsFilterActions';
import { useRemainsTableActions } from '@/modules/remains/hooks/use-remains-table-actions';
import { useRemainsTableRowActions } from '@/modules/remains/hooks/use-remains-table-row-actions';
import { useUnboxRemainDialog } from '@/modules/remains/hooks/use-unbox-remain-dialog';
import { resetState as resetRemainsPageState } from '@/modules/remains/store/page';
import {
  remainsFilterConfigActions,
  remainsPageableDataActions,
  remainsSearchingActions,
  remainsTableConfigActions,
  remainsTableRootActions
} from '@/modules/remains/store/table';
import { remainsTableApi } from '@/modules/remains/store/table/async-thunks';
import { remainsTableSelectors } from '@/modules/remains/store/table/selectors';
import { useSuccessfulExportNotification } from '@/shared/files/hooks/use-successful-export-notification';
import { RemainsTable } from '@/shared/remains/components/remains-table/RemainsTable';
import { useRemainsTableDataSource } from '@/shared/remains/hooks/use-remains-table-data-source';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const RemainsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetRemainsPageState());
      dispatch(remainsTableRootActions.resetState());
    };
  }, []);

  const { loadFirstPage, loadNextPage } = useRemainsTableDataSource({
    selectors: remainsTableSelectors,
    api: remainsTableApi,
    pageableDataActions: remainsPageableDataActions
  });

  const {
    openExportNotificationDialog,
    successfulExportNotificationDialog
  } = useSuccessfulExportNotification();

  const {
    openUnboxRemainDialog,
    unboxRemainDialog
  } = useUnboxRemainDialog();

  const { unboxRemain } = useRemainsTableActions(openUnboxRemainDialog);
  const { getRowActions } = useRemainsTableRowActions({ unboxRemain });

  return (
    <>
      <RemainsTable
        topToolbar={{
          filter: {
            customFilterActions: <RemainsFilterActions
              openDialog={openExportNotificationDialog}
              onUnboxClick={openUnboxRemainDialog}
            />
          }
        }}
        selectors={remainsTableSelectors}
        rootActions={remainsTableRootActions}
        filterConfigActions={remainsFilterConfigActions}
        searchingActions={remainsSearchingActions}
        tableConfigActions={remainsTableConfigActions}
        loadFirstPage={loadFirstPage}
        loadNextPage={loadNextPage}
      // REM-837: hide unbox remains
      // getRowsActions={getRowActions}
      />
      {successfulExportNotificationDialog}
      {unboxRemainDialog}
    </>
  );
};
