import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { defaultMdlpProductsTableConfig } from '@/modules/references/constants/mdlp-products-table-config';
import { mdlpProductsModuleName } from '@/modules/references/constants/references-module-names';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { MdlpProductsFilter } from '@/modules/references/models/mdlp-products/mdlp-products-filter';
import { getAllMdlpProducts } from '@/modules/references/store/mdlp-products/async-thunks';
import { createSlice } from '@reduxjs/toolkit';

interface MdlpProductsState {
  search: SearchingState;
  filterConfig: FilterConfigState<MdlpProductsFilter>;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<MdlpProduct>;
}

const notClearedFields: Set<keyof MdlpProductsState> = new Set(['tableConfig']);

const mdlpProductsTableConfigState: SavedTableConfigState = {
  tableConfigName: 'mdlp-products-table',
  config: defaultMdlpProductsTableConfig,
};

const mdlpProductsSearchingSlice = createSearchingSlice(mdlpProductsModuleName);
const mdlpProductsTableConfigSlice = createSavedTableConfigSlice(mdlpProductsModuleName, mdlpProductsTableConfigState);
const mdlpProductsPageableDataSlice = createPageableDataSlice<MdlpProduct>(mdlpProductsModuleName);
const mdlpProductsFilterConfigSlice = createFilterConfigSlice(mdlpProductsModuleName);

const initialState: MdlpProductsState = {
  search: mdlpProductsSearchingSlice.getInitialState(),
  filterConfig: mdlpProductsFilterConfigSlice.getInitialState(),
  tableConfig: mdlpProductsTableConfigSlice.getInitialState(),
  pageableData: mdlpProductsPageableDataSlice.getInitialState(),
};

const mdlpProductsSlice = createSlice({
  name: mdlpProductsModuleName,
  initialState,
  reducers: {
    resetState(state: MdlpProductsState) {
      Object.keys(state)
        .forEach((key: keyof MdlpProductsState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMdlpProducts.pending, (state) => {
        const { updateLoadingStatus } = mdlpProductsPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllMdlpProducts.fulfilled, (state) => {
        const { updateLoadingStatus } = mdlpProductsPageableDataSlice.caseReducers;
        const { updateStateAfterGettingData } = mdlpProductsFilterConfigSlice.caseReducers;
        const { saveAppliedSearchString } = mdlpProductsSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        updateStateAfterGettingData(state.filterConfig);
        saveAppliedSearchString(state.search);
      })
      .addCase(getAllMdlpProducts.rejected, (state) => {
        const { updateLoadingStatus } = mdlpProductsPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addMatcher(
        (action) => action.type.startsWith(mdlpProductsSearchingSlice.name),
        (state, action) => {
          state.search = mdlpProductsSearchingSlice.reducer(state.search, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(mdlpProductsTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = mdlpProductsTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(mdlpProductsPageableDataSlice.name),
        (state, action) => {
          state.pageableData = mdlpProductsPageableDataSlice.reducer(state.pageableData, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(mdlpProductsFilterConfigSlice.name),
        (state, action) => {
          state.filterConfig = mdlpProductsFilterConfigSlice.reducer(state.filterConfig, action);
        }
      );
  },
});

export const {
  resetState,
} = mdlpProductsSlice.actions;

export const {
  updateSearchString,
} = mdlpProductsSearchingSlice.actions;

export const {
  updateFilter,
  changeFilterOpenState
} = mdlpProductsFilterConfigSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = mdlpProductsTableConfigSlice.actions;

export const mdlpProductsPageableDataActions = mdlpProductsPageableDataSlice.actions;
export const mdlpProductsFilterConfigActions = mdlpProductsFilterConfigSlice.actions;

export default mdlpProductsSlice.reducer;
