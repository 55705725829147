import { AppTableColumnHeaderWithHint } from '@/common/components/app-table/components/app-table-column-header-with-hint/AppTableColumnHeaderWithHint';
import { AppTableOpenButton } from '@/common/components/app-table/components/app-table-open-button/AppTableOpenButton';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { dateTimeFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType, AppTableRowType } from '@/common/models/app-table/app-table-types';
import { getShortFioFromFullName } from '@/common/utils/common/common-user-data-utils';
import { formatDate } from '@/common/utils/common/date-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { getInteractionEventLabel } from '@/modules/interaction-log/models/interaction-event';
import { InteractionItem } from '@/modules/interaction-log/models/interaction-item';
import { convertInteractionItemColumnFieldToDto } from '@/modules/interaction-log/models/interaction-log-table/interaction-log-table-column-converter';
import { InteractionLogTableColumns } from '@/modules/interaction-log/models/interaction-log-table/interaction-log-table-columns';
import { getInteractionOriginLabel } from '@/modules/interaction-log/models/interaction-origin';
import { getInteractionStatusLabel, isErrorInteractionStatus, isWarningInteractionStatus } from '@/modules/interaction-log/models/interaction-status';
import { setCurrentEventUuid, setIsOpenNotificationHistoryDialog } from '@/modules/interaction-log/store/interaction-log-notification-history';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { DocumentLink } from '@/shared/remains/components/document-link/DocumentLink';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export function useInteractionLogTableSettings() {
  const dispatch = useDispatch();
  const { hasAccessByPermission } = useUserPermissionsAccess();

  const searchPlaceholder = getSearchString([
    hasAccessByPermission(UserPermission.ViewOrganizationInInteractionLog) ? Labels.organization : '',
    Labels.user,
    Labels.docNumber
  ]);

  const onOpenNotificationHistory = useCallback((eventUuid: string) => {
    dispatch(setCurrentEventUuid(eventUuid));
    dispatch(setIsOpenNotificationHistoryDialog(true));
  }, []);

  const columns = useMemo(
    () => {
      const userColumns: AppTableColumnDefType<InteractionItem>[] = [
        {
          id: InteractionLogTableColumns.CreatedDate,
          header: Labels.createdDate,
          accessorFn: (row) => formatDate(row.createdDate, dateTimeFormat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: InteractionLogTableColumns.DocNumber,
          header: '',
          headerSettingsName: Labels.docNumber,
          Header: () =>
            <AppTableColumnHeaderWithHint
              header={Labels.docNumber}
              hint={Labels.docNumberHint}
            />,
          accessorFn: ({ docNumber, docType, docUuid }) => {
            if (docType && docUuid) {
              return <DocumentLink
                docNumber={docNumber}
                docType={docType}
                docUuid={docUuid}
              />;
            }
            return docNumber;
          },
        },
        {
          id: InteractionLogTableColumns.Event,
          header: Labels.event,
          accessorFn: (row) => getInteractionEventLabel(row.docSchema),
        },
        {
          id: InteractionLogTableColumns.Source,
          header: Labels.source,
          accessorFn: (row) => getInteractionOriginLabel(row.source),
        },
        {
          id: InteractionLogTableColumns.Target,
          header: Labels.target,
          accessorFn: (row) => getInteractionOriginLabel(row.target),
        },
        {
          id: InteractionLogTableColumns.User,
          header: Labels.user,
          accessorFn: (row) => (
            <AppTooltip text={row.userFullName}>
              <span>{getShortFioFromFullName(row.userFullName)}</span>
            </AppTooltip>
          )
        },
        {
          id: InteractionLogTableColumns.Status,
          header: Labels.status,
          accessorFn: (row) => getInteractionStatusLabel(row.eventState)
        },
        {
          id: InteractionLogTableColumns.CreatedDateMdlp,
          header: Labels.createdDateMdlp,
          accessorFn: (row) => formatDate(row.docCreationDateInMdlp, dateTimeFormat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: InteractionLogTableColumns.NotificationHistory,
          header: Labels.notificationHistory,
          accessorFn: (row) =>
            <AppTableOpenButton
              text="Открыть"
              onClick={() => onOpenNotificationHistory(row.eventUuid)}
            />,
          enableEditing: false,
          enableSorting: false,
        },
      ];

      const isOrganizationColumnView = hasAccessByPermission(UserPermission.ViewOrganizationInInteractionLog);
      if (isOrganizationColumnView) {
        return [
          {
            id: InteractionLogTableColumns.Organization,
            header: Labels.organization,
            accessorKey: convertInteractionItemColumnFieldToDto(InteractionLogTableColumns.Organization),
          },
          ...userColumns
        ];
      }
      return userColumns;
    }, []);

  const getCellRowClassName = (data: { row: AppTableRowType<InteractionItem>; }) => {
    const { eventState } = data.row.original;
    if (isWarningInteractionStatus(eventState)) {
      return 'interaction-log-warning-status';
    }
    if (isErrorInteractionStatus(eventState)) {
      return 'interaction-log-error-status';
    }
  };

  const tableClasses = useMemo(() => ({
    cell: getCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses,
    searchPlaceholder
  };
}
