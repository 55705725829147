import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getOrganizationStateTitle } from '@/modules/organization-profile/models/organization-state';
import { LegalEntitiesActivityPlacesTableColumns } from '@/modules/references/models/legal-entities-activity-places/legal-entities-activity-places-table-columns';
import { LegalEntityActivityPlace } from '@/modules/references/models/legal-entities-activity-places/legal-entity-activity-place';
import { getLegalEntityActivityPlaceTypeTitle } from '@/modules/references/models/legal-entities-activity-places/legal-entity-activity-place-type';
import { useMemo } from 'react';

export function useLegalEntitiesActivityPlacesTableSettings() {
  const columns = useMemo<AppTableColumnDefType<LegalEntityActivityPlace>[]>(
    () => [
      {
        id: LegalEntitiesActivityPlacesTableColumns.RegNumber,
        header: Labels.regNumber,
        accessorKey: 'mdlpPlaceId',
      },
      {
        id: LegalEntitiesActivityPlacesTableColumns.Address,
        header: Labels.address,
        accessorKey: 'address',
      },
      {
        id: LegalEntitiesActivityPlacesTableColumns.State,
        header: Labels.status,
        accessorFn: (row) => getOrganizationStateTitle(row.state),
      },
      {
        id: LegalEntitiesActivityPlacesTableColumns.Type,
        header: Labels.type,
        accessorFn: (row) => getLegalEntityActivityPlaceTypeTitle(row.type),
      }
    ], []);

  return {
    columns,
  };
}
