import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { defaultLegalEntitiesTableConfig } from '@/modules/references/constants/legal-entities-table-config';
import { legalEntitiesModuleName } from '@/modules/references/constants/references-module-names';
import { LegalEntitiesFilter } from '@/modules/references/models/legal-entities/legal-entities-filter';
import { LegalEntity } from '@/modules/references/models/legal-entities/legal-entity';
import { getAllLegalEntities, updateLegalEntity } from '@/modules/references/store/legal-entities/async-thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LegalEntitiesState {
  updatedRowId: string;
  isUpdatingInProcess: boolean;
  search: SearchingState;
  filterConfig: FilterConfigState<LegalEntitiesFilter>;
  tableConfig: SavedTableConfigState;
  pageableData: PageableDataState<LegalEntity>;
}

const notClearedFields: Set<keyof LegalEntitiesState> = new Set(['tableConfig']);

const legalEntitiesTableConfigState: SavedTableConfigState = {
  tableConfigName: 'legal-entities-table',
  config: defaultLegalEntitiesTableConfig,
};

const legalEntitiesSearchingSlice = createSearchingSlice(legalEntitiesModuleName);
const legalEntitiesTableConfigSlice = createSavedTableConfigSlice(legalEntitiesModuleName, legalEntitiesTableConfigState);
const legalEntitiesPageableDataSlice = createPageableDataSlice<LegalEntity>(legalEntitiesModuleName);
const legalEntitiesFilterConfigSlice = createFilterConfigSlice(legalEntitiesModuleName);

const initialState: LegalEntitiesState = {
  updatedRowId: '',
  isUpdatingInProcess: false,
  filterConfig: legalEntitiesFilterConfigSlice.getInitialState(),
  search: legalEntitiesSearchingSlice.getInitialState(),
  tableConfig: legalEntitiesTableConfigSlice.getInitialState(),
  pageableData: legalEntitiesPageableDataSlice.getInitialState(),
};

const legalEntitiesSlice = createSlice({
  name: legalEntitiesModuleName,
  initialState,
  reducers: {
    setUpdatedRowId(state: LegalEntitiesState, action: PayloadAction<string>) {
      state.updatedRowId = action.payload;
    },
    setIsUpdatingInProgress(state: LegalEntitiesState, action: PayloadAction<boolean>) {
      state.isUpdatingInProcess = action.payload;
    },
    resetState(state: LegalEntitiesState) {
      Object.keys(state)
        .forEach((key: keyof LegalEntitiesState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLegalEntities.pending, (state) => {
        const { updateLoadingStatus } = legalEntitiesPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
      })
      .addCase(getAllLegalEntities.fulfilled, (state) => {
        const { updateLoadingStatus } = legalEntitiesPageableDataSlice.caseReducers;
        const { updateStateAfterGettingData } = legalEntitiesFilterConfigSlice.caseReducers;
        const { saveAppliedSearchString } = legalEntitiesSearchingSlice.caseReducers;

        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
        updateStateAfterGettingData(state.filterConfig);
        saveAppliedSearchString(state.search);
      })
      .addCase(getAllLegalEntities.rejected, (state: LegalEntitiesState) => {
        const { updateLoadingStatus } = legalEntitiesPageableDataSlice.caseReducers;
        updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
      })
      .addCase(updateLegalEntity.pending, (state: LegalEntitiesState) => {
        state.isUpdatingInProcess = true;
      })
      .addMatcher(
        (action) => action.type.startsWith(legalEntitiesSearchingSlice.name),
        (state, action) => {
          state.search = legalEntitiesSearchingSlice.reducer(state.search, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(legalEntitiesTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = legalEntitiesTableConfigSlice.reducer(state.tableConfig, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(legalEntitiesPageableDataSlice.name),
        (state, action) => {
          state.pageableData = legalEntitiesPageableDataSlice.reducer(state.pageableData, action);
        }
      )
      .addMatcher(
        (action) => action.type.startsWith(legalEntitiesFilterConfigSlice.name),
        (state, action) => {
          state.filterConfig = legalEntitiesFilterConfigSlice.reducer(state.filterConfig, action);
        }
      );
  },
});

export const {
  updateFilter,
  changeFilterOpenState
} = legalEntitiesFilterConfigSlice.actions;

export const {
  setUpdatedRowId,
  setIsUpdatingInProgress,
  resetState,
} = legalEntitiesSlice.actions;

export const {
  updateSearchString,
} = legalEntitiesSearchingSlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = legalEntitiesTableConfigSlice.actions;

export const {
  patchItemByIndex: patchLegalEntity,
} = legalEntitiesPageableDataSlice.actions;

export const legalEntitiesPageableDataActions = legalEntitiesPageableDataSlice.actions;
export const legalEntitiesFilterConfigActions = legalEntitiesFilterConfigSlice.actions;

export default legalEntitiesSlice.reducer;
