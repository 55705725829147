import Error from '@/assets/icons/controls/cross.svg?react';
import React, { FC, memo } from 'react';
import './error-icon.scss';

interface ErrorIconProps {
  width?: number;
  height?: number;
}

const ErrorIconInner: FC<ErrorIconProps> = ({ width, height }) => (
  <div
    className="error-icon"
    style={{
      width: width ?? 16,
      height: height ?? 16
    }}
  >
    <Error className="error" />
  </div>
);

export const ErrorIcon = memo(ErrorIconInner);
