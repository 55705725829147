import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { MdlpProductsFilters } from '@/modules/references/components/mdlp-products-filters/MdlpProductsFilters';
import { useMdlpProductsTableDataSource } from '@/modules/references/hooks/mdlp-products/use-mdlp-products-table-data-source';
import { useMdlpProductsTableEventHandlers } from '@/modules/references/hooks/mdlp-products/use-mdlp-products-table-event-handlers';
import { useMdlpProductsTableFiltering } from '@/modules/references/hooks/mdlp-products/use-mdlp-products-table-filtering';
import { useMdlpProductsTableSettings } from '@/modules/references/hooks/mdlp-products/use-mdlp-products-table-settings';
import { MdlpProductsSelectors } from '@/modules/references/store/mdlp-products/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const MdlpProductsTable = () => {
  const data = useSelector(MdlpProductsSelectors.selectData);
  const isLoadingData = useSelector(MdlpProductsSelectors.selectIsLoadingData);
  const tableConfig = useSelector(MdlpProductsSelectors.selectTableConfig);
  const selectedFiltersCount = useSelector(MdlpProductsSelectors.selectSelectedFiltersCount);
  const isPanelOpen = useSelector(MdlpProductsSelectors.selectIsPanelOpen);

  const { loadFirstPage, loadNextPage } = useMdlpProductsTableDataSource();

  const { columns, searchPlaceholder } = useMdlpProductsTableSettings();

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: MdlpProductsSelectors.selectSearchState
  });

  const {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onScrollChange,
  } = useMdlpProductsTableEventHandlers({ loadFirstPage, loadNextPage });

  const {
    disableApplyFilterButton,
    disableClearFilterButton,
    onFilterClick,
    onApplyFiltersClick,
    onClearFiltersClick,
  } = useMdlpProductsTableFiltering({ loadFirstPage });

  return (
    <AppCustomTable
      data={data}
      columns={columns}
      isLoading={isLoadingData}
      configState={tableConfig}
      enableRowVirtualization
      manualSorting
      topToolbar={{
        filter: {
          showFilterButton: true,
          filterPanel: <MdlpProductsFilters />,
          isPanelOpen,
          selectedFiltersCount,
          disableApplyFilterButton,
          disableClearFilterButton,
          onFilterClick,
          onApplyFiltersClick,
          onClearFiltersClick,
        },
        search: {
          showSearchInput: true,
          searchPlaceholder,
          disableApplySearchButton: isSearchNotChanged,
          onSearchValueInput,
          onApplySearchClick,
          onClearSearchClick,
        }
      }}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onSortingChange={onSortingChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
      onScrollAtTheEnd={onScrollChange}
    />
  );
};
