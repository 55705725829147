import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { PageableDataActions } from '@/common/models/pageable-data/pageable-data-actions';
import { Remain } from '@/shared/remains/models/remain';
import { RemainsApi, RemainsSelectors } from '@/shared/remains/store/types';
import { useSelector } from 'react-redux';

interface RemainsTableDataSourceHookParams {
  selectors: RemainsSelectors;
  api: RemainsApi;
  pageableDataActions: PageableDataActions<Remain>;
}

export function useRemainsTableDataSource({ selectors, api, pageableDataActions }: RemainsTableDataSourceHookParams) {
  const sorting = useSelector(selectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: pageableDataActions,
    selectPageableDataState: selectors.selectPageableDataState,
    getData: api.getAllRemains,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage,
  };
}
