import { HintIcon } from '@/common/components/icons/hint-icon/HintIcon';
import React, { FC, memo } from 'react';
import './scanning-view-info-item.scss';

interface ScanningViewInfoItemProps {
  label: string;
  value?: string;
  className?: string;
  hint?: string;
}

const ScanningViewInfoItemInner: FC<ScanningViewInfoItemProps> = ({ label, value, className, hint }) => (
  <div className={`scanning-view-info-item ${className ?? ''}`}>
    <div className="scanning-view-info-item-label">
      <span>{label}</span>
      {hint && <HintIcon text={hint} />}
    </div>
    <div className="scanning-view-info-item-value">{value?.length ? value : '-'}</div>
  </div>
);

export const ScanningViewInfoItem = memo(ScanningViewInfoItemInner);
