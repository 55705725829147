import { receiptCardGeneralPositionsModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardGeneralPositionsApi } from '@/modules/receipts/store/receipt-card/general-positions/async-thunks';
import { createDocumentCardGeneralPositionsSlice } from '@/shared/document/store/document-card/general-positions';

export const receiptCardGeneralPositionsSlice = createDocumentCardGeneralPositionsSlice({
  moduleName: receiptCardGeneralPositionsModuleName,
  savedTableConfigName: 'receipt-card-general-positions-table',
  api: receiptCardGeneralPositionsApi,
});

export const receiptCardGeneralPositionsRootActions = receiptCardGeneralPositionsSlice.rootSlice.actions;
export const receiptCardGeneralPositionsTableConfigActions = receiptCardGeneralPositionsSlice.documentCardPositionsTableConfigSlice.actions;

export default receiptCardGeneralPositionsSlice.rootSlice.reducer;
