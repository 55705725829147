export enum SyncType {
  All = 'ALL',
  Partners = 'PARTNERS',
  Products = 'PRODUCTS',
  OtherSyncs = 'OTHER_SYNCS',
  ReceiptMed = 'RECEIPT_MED',
  SaleMed = 'SALE_MED',
  DisposalMed = 'DISPOSAL_MED',
  RemainMed = 'REMAIN_MED',
  Interactions = 'INTERACTIONS'
}

const syncTypeLabel = {
  [SyncType.Partners]: 'Справочник "Контрагенты"',
  [SyncType.Products]: 'Справочник "Товары МДЛП"',
  [SyncType.ReceiptMed]: 'Поступление',
  [SyncType.SaleMed]: 'Реализация',
  [SyncType.DisposalMed]: 'Выбытие',
  [SyncType.Interactions]: 'Уведомления',
  [SyncType.RemainMed]: 'Остатки',
  [SyncType.OtherSyncs]: 'Прочие синхронизации',
  [SyncType.All]: 'Запуск всех доступных синхронизаций'
};

export const getSyncTypeLabel = (type: SyncType) => syncTypeLabel[type];