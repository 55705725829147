import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { DocumentCardScanningPageProps } from '@/shared/document/pages/document-card-scanning-page/DocumentCardScanningPage';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type DocumentCardScanningTableFilteringHookParams = Pick<PageableLoadingActions, 'loadFirstPage'> &
  Pick<DocumentCardScanningPageProps, 'documentCardScanningFilterConfigActions' | 'documentCardScanningSelectors'>;

export function useDocumentCardScanningTableFiltering({
  loadFirstPage,
  documentCardScanningFilterConfigActions,
  documentCardScanningSelectors
}: DocumentCardScanningTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(documentCardScanningFilterConfigActions.changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData({
    filterConfigActions: documentCardScanningFilterConfigActions,
    selectFilterConfig: documentCardScanningSelectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
