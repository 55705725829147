import { UserProfileData } from '@/modules/portal/models/user-profile-info';
import React, { FC, memo } from 'react';
import './user-profile-content.scss';

interface UserProfileContentProps {
  userProfileInfo?: UserProfileData;
}

const UserProfileContentInner: FC<UserProfileContentProps> = ({ userProfileInfo }) => (
  <div className="user-profile-content">
    {Object.values(userProfileInfo).map(({ label, value }) => (
      <div
        key={label}
        className="user-profile-info"
      >
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
    ))}
  </div>
);

export const UserProfileContent = memo(UserProfileContentInner);