import { saleCardModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardApi } from '@/modules/sale/store/sale-card/card/async-thunks';
import { DocumentType } from '@/shared/document/models/document-type';
import { createDocumentCardSlice } from '@/shared/document/store/document-card/card';

export const saleCardSlice = createDocumentCardSlice({
  moduleName: saleCardModuleName,
  api: saleCardApi,
  docType: DocumentType.Sale,
});

export const saleCardActions = saleCardSlice.actions;

export default saleCardSlice.reducer;
