import { usePageableLoadingData } from '@/common/hooks/use-pageable-loading-data';
import { favoritesPageableDataActions } from '@/modules/favorites/store';
import { getAllFavoriteDocuments } from '@/modules/favorites/store/async-thunks';
import { FavoritesSelectors } from '@/modules/favorites/store/selectors';
import { useSelector } from 'react-redux';

export function useFavoritesTableDataSource() {
  const sorting = useSelector(FavoritesSelectors.selectTableSorting);

  const { loadNextPage, loadFirstPage } = usePageableLoadingData({
    dataActions: favoritesPageableDataActions,
    selectPageableDataState: FavoritesSelectors.selectPageableDataState,
    getData: getAllFavoriteDocuments,
    resetCondition: sorting,
  });

  return {
    loadNextPage,
    loadFirstPage
  };
}
