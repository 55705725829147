import { axios } from '@/common/api/axios-config';
import { allRemainsUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { Remain } from '@/shared/remains/models/remain';
import { AxiosResponse } from 'axios';

export class RemainsRequests {
  static getAllRemains(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<Remain>>> {
    return axios.get(allRemainsUrl, { params });
  }
}
