import { MdlpProductsTable } from '@/modules/references/components/mdlp-products-table/MdlpProductsTable';
import { resetState } from '@/modules/references/store/mdlp-products';
import { useAppDispatch } from '@/store';
import React, { useEffect } from 'react';

export const MdlpProductsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  return <MdlpProductsTable />;
};
