import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { AddNoteBody } from '@/shared/document/models/document-card/notes/add-note-body';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import { EditNoteBody } from '@/shared/document/models/document-card/notes/edit-note-body';
import { EditNoteParams } from '@/shared/document/models/document-card/notes/edit-note-params';
import { DocumentCardNotesRequests } from '@/shared/document/requests/document-card-notes-requests';
import { DocumentCardSelectors } from '@/shared/document/store/document-card/card/types';
import { DocumentCardNotesSelectors } from '@/shared/document/store/document-card/notes/types';
import { buildDocumentCardFilterExpression } from '@/shared/document/utils/filters/document-card-filter-utils';
import { RootState } from '@/store';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

interface DocumentCardNotesThunksCreationParams {
  moduleName: string;
  documentCardSelectors: DocumentCardSelectors;
  documentCardNotesSelectors: DocumentCardNotesSelectors;
}

export const createDocumentCardNotesThunks = ({ moduleName, documentCardSelectors, documentCardNotesSelectors }: DocumentCardNotesThunksCreationParams) => {
  const getAllNotes = createAsyncThunkWrapper<
    PageableDataResponse<DocumentCardNote>,
    PageableDataBody
  >(
    `${moduleName}/getAllNotes`,
    ({ pageNumber }, { getState, signal }) => {
      const sorting = documentCardNotesSelectors.selectDataSorting(getState());
      const documentId = documentCardSelectors.selectDocumentId(getState());

      const body: PageableDataQueryParams = {
        page: pageNumber,
        size: AppTableItemsPageSize,
      };

      const sortParams = getRequestSorting(sorting);
      if (sortParams) {
        body.sort = sortParams;
      }

      const filterParams = buildDocumentCardFilterExpression(documentId);
      if (filterParams) {
        body.filter = filterParams;
      }

      return DocumentCardNotesRequests.getAllNotes(body, signal)
        .then((res) => res.data);
    },
    {
      condition: asyncThunkConditionOption((state: RootState) => {
        const isLoading = documentCardNotesSelectors.selectIsLoadingData(state);
        const documentId = documentCardSelectors.selectDocumentId(state);
        return isLoading || !documentId;
      })
    },
  );

  const addNote = createAsyncThunkWrapper<
    DocumentCardNote,
    string
  >(
    `${moduleName}/addNote`,
    (noteText, { getState }) => {
      const docUuid = documentCardSelectors.selectDocumentId(getState());

      const body: AddNoteBody = {
        noteText,
        docUuid,
      };

      return DocumentCardNotesRequests.addNote(body)
        .then((res) => res.data);
    },
    {
      condition: asyncThunkConditionOption((state: RootState) => {
        return !documentCardSelectors.selectDocumentId(state);
      })
    },
  );

  const editNote = createAsyncThunkWrapper<
    DocumentCardNote,
    EditNoteParams
  >(
    `${moduleName}/editNote`,
    ({ documentNoteUuid, noteText }) => {
      const body: EditNoteBody = {
        noteText,
      };

      return DocumentCardNotesRequests.editNote(documentNoteUuid, body)
        .then((res) => res.data);
    });

  const deleteNote = createAsyncThunkWrapper<
    void,
    string
  >(
    `${moduleName}/deleteNote`,
    async (documentNoteUuid) => {
      await DocumentCardNotesRequests.deleteNote(documentNoteUuid);
    }
  );

  const revertNote = createAsyncThunkWrapper<
    void,
    string
  >(
    `${moduleName}/revertNote`,
    async (documentNoteUuid) => {
      await DocumentCardNotesRequests.revertNote(documentNoteUuid);
    }
  );

  return {
    getAllNotes,
    addNote,
    editNote,
    deleteNote,
    revertNote,
  };
};
