export enum ErrorCode {
  MDLP_TOKEN_EXPIRED = 'MDLP_TOKEN_EXPIRED',
  INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
  VERIFICATION_CODE_ATTEMPTS_EXCEEDED = 'VERIFICATION_CODE_ATTEMPTS_EXCEEDED',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  TOO_EARLY_FOR_MDLP_SYNC = 'TOO_EARLY_FOR_MDLP_SYNC',
  USER_PHONE_NUMBER_NOT_FOUND = 'USER_PHONE_NUMBER_NOT_FOUND',
  UNVERIFIED_CONTACTS = 'UNVERIFIED_CONTACTS',
  CONTACT_ALREADY_USED = 'CONTACT_ALREADY_USED',
  CONTACT_NOT_FOUND = 'CONTACT_NOT_FOUND',
  DOCUMENT_IS_NOT_READY_TO_PROMOTE = 'DOCUMENT_IS_NOT_READY_TO_PROMOTE',
  DOCUMENT_CONTAINS_NOT_SCANNED_MEDICINES = 'DOCUMENT_CONTAINS_NOT_SCANNED_MEDICINES',
  REMAINING_MED_IS_PART_OF_SSCC_BOX = 'REMAINING_MED_IS_PART_OF_SSCC_BOX',
  NOT_ALL_MEDICINES_ARE_READY = 'NOT_ALL_MEDICINES_ARE_READY'
}

const errorCodesForValidation = new Set([ErrorCode.VALIDATION_ERROR, ErrorCode.CONTACT_ALREADY_USED, ErrorCode.INVALID_VERIFICATION_CODE, ErrorCode.USER_PHONE_NUMBER_NOT_FOUND]);
export const isErrorCodeForValidation = (code: ErrorCode) => errorCodesForValidation.has(code);

const errorCodesWithoutNotification = new Set([ErrorCode.DOCUMENT_IS_NOT_READY_TO_PROMOTE, ErrorCode.DOCUMENT_CONTAINS_NOT_SCANNED_MEDICINES, ErrorCode.REMAINING_MED_IS_PART_OF_SSCC_BOX, ErrorCode.NOT_ALL_MEDICINES_ARE_READY]);
export const isErrorCodeWithoutNotification = (code: ErrorCode) => errorCodesWithoutNotification.has(code);