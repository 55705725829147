import { RootState } from '@/store';

const selectDisposalCardRemainsDocumentActionsState = (state: RootState) => state.disposals.disposalCard.remains.documentActions;

const selectIsOpenedAddingRemainsSuccessfulDialog = (state: RootState) => selectDisposalCardRemainsDocumentActionsState(state).isAddingWarnDialogOpened;
const selectIsAddingInProgress = (state: RootState) => selectDisposalCardRemainsDocumentActionsState(state).isAddingInProgress;
const selectAddingRemainsWarnInfo = (state: RootState) => selectDisposalCardRemainsDocumentActionsState(state).addingRemainsWarnInfo;

export const DisposalCardRemainsDocumentActionsSelectors = {
  selectIsOpenedAddingRemainsSuccessfulDialog,
  selectIsAddingInProgress,
  selectAddingRemainsWarnInfo
};
