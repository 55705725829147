import {
  AppTableColumnOrderType,
  AppTableColumnSizingType,
  AppTableColumnVisibilityType,
  AppTableSortingType,
  Updater
} from '@/common/models/app-table/app-table-types';
import {
  updateTableColumnOrderState,
  updateTableColumnSizingState,
  updateTableColumnVisibilityState,
  updateTableSortingState
} from '@/modules/interaction-log/store/interaction-log-notification-history';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

export function useInteractionLogNotificationHistoryTableEventHandlers() {
  const dispatch = useAppDispatch();

  const onColumnVisibilityChange = useCallback((value: AppTableColumnVisibilityType) => {
    dispatch(updateTableColumnVisibilityState(value));
  }, []);

  const onSortingChange = useCallback((value: Updater<AppTableSortingType>) => {
    dispatch(updateTableSortingState(value));
  }, []);

  const onColumnOrderChange = useCallback((value: Updater<AppTableColumnOrderType>) => {
    dispatch(updateTableColumnOrderState(value));
  }, []);

  const onColumnSizingChange = useCallback((value: Updater<AppTableColumnSizingType>) => {
    dispatch(updateTableColumnSizingState(value));
  }, []);

  return {
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
  };
}
