import { AppTableSortingType } from '@/common/models/app-table/app-table-types';
import { SortDirection } from '@/common/utils/sorting/sort-direction';

const sortingDelimiter = ',';

export const getRequestSorting = (tableSort: AppTableSortingType | undefined, tableConverter?: (columnName: string) => string) => {
  if (!tableSort?.length) {
    return '';
  }

  const { id, desc } = tableSort[0];
  const sortingField = tableConverter ? tableConverter(id) : id;
  const sortingDirection = desc ? SortDirection.Desc : SortDirection.Asc;
  return `${sortingField}${sortingDelimiter}${sortingDirection}`;
};
