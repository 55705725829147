import { UserPermission } from '@/modules/portal/models/user-permission';
import { Roles } from '@/modules/portal/models/roles';

export const usersPermissionsByRoles: Record<UserPermission, Roles[]> = {
  // pages permissions
  [UserPermission.ViewPortalNotFound]: [Roles.OrganizationAdmin, Roles.Manager, Roles.SystemAdmin],
  [UserPermission.ViewFavorites]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewReceipts]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewSales]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewDisposals]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewRemains]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewInteractionLog]: [Roles.OrganizationAdmin, Roles.Manager, Roles.SystemAdmin],
  [UserPermission.ViewFileOperations]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewLegalEntities]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewMdlpProducts]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewNomenclature]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewProcesses]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewOrganizationProfile]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewUsersManagement]: [Roles.OrganizationAdmin, Roles.SystemAdmin],
  // others permissions
  [UserPermission.ViewOrganizationInInteractionLog]: [Roles.SystemAdmin],
  [UserPermission.RetryOperationInInteractionLog]: [Roles.SystemAdmin],
  [UserPermission.ViewOrganizationInUsersManagement]: [Roles.SystemAdmin],
  [UserPermission.SendInviteLetter]: [Roles.OrganizationAdmin],
  [UserPermission.EditOrganizationProfile]: [Roles.OrganizationAdmin],
  [UserPermission.AddNewUser]: [Roles.OrganizationAdmin],
  [UserPermission.ExtendUserSession]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.StartAllSyncs]: [Roles.OrganizationAdmin, Roles.Manager],
  [UserPermission.ViewActivityPlaces]: [Roles.OrganizationAdmin, Roles.Manager],
};
