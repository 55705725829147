import { receiptCardNotesModuleName } from '@/modules/receipts/constants/receipts-module-names';
import { receiptCardNotesApi } from '@/modules/receipts/store/receipt-card/notes/async-thunks';
import { createDocumentCardNotesSlice } from '@/shared/document/store/document-card/notes';

export const receiptCardNotesSlice = createDocumentCardNotesSlice({
  moduleName: receiptCardNotesModuleName,
  api: receiptCardNotesApi,
});

export const receiptCardNotesRootActions = receiptCardNotesSlice.rootSlice.actions;
export const receiptCardNotesPageableDataActions = receiptCardNotesSlice.documentNotesPageableDataSlice.actions;

export default receiptCardNotesSlice.rootSlice.reducer;
