import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { MdlpProductsFilter } from '@/modules/references/models/mdlp-products/mdlp-products-filter';
import { changeFilterOpenState, mdlpProductsFilterConfigActions } from '@/modules/references/store/mdlp-products';
import { MdlpProductsSelectors } from '@/modules/references/store/mdlp-products/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type MdlpProductsTableFilteringHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useMdlpProductsTableFiltering({ loadFirstPage }: MdlpProductsTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData<MdlpProductsFilter>({
    filterConfigActions: mdlpProductsFilterConfigActions,
    selectFilterConfig: MdlpProductsSelectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
