export enum DocumentCardBoxesTableColumns {
  Code = 'code',
  Name = 'name',
  Producer = 'producer',
  Vital = 'vital',
  Sscc = 'sscc',
  Gtin = 'gtin',
  Series = 'series',
  ExpirationDate = 'expirationDate',
  PriceWithVat = 'priceWithVat',
  Vat = 'vat',
  TotalPriceWithVat = 'totalPriceWithVat',
  VatRate = 'vatRate',
  Amount = 'amount',
  IsProductInOrder = 'isProductInOrder',
  ProcessingStatus = 'processingStatus'
}
