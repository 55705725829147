import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { LoaderWrapperCommonProps } from '@/modules/portal/models/loader-wrapper/loader-wrapper-common-props';
import React, { FC, memo } from 'react';
import './loader-wrapper.scss';

interface LoaderWrapperProps extends LoaderWrapperCommonProps {
  isLoading: boolean;
}

const LoaderWrapperInner: FC<LoaderWrapperProps> = ({ children, isLoading, classNames }) => {

  if (isLoading) {
    return (
      <div className={`loader-wrapper ${classNames?.loaderWrapper ?? ''}`}>
        <AppLoader className={`loader-wrapper-loader-component ${classNames?.loader ?? ''}`} />
      </div>
    );
  }

  return <>{children}</>;
};

export const LoaderWrapper = memo(LoaderWrapperInner);