import { LicenceAgreement, LicenceAgreementProps } from '@/modules/auth/components/licence-agreement/LicenceAgreement';
import { PhoneCodeForm, PhoneCodeFormProps } from '@/modules/auth/components/phone-code-form/PhoneCodeForm';
import { SetNewPasswordForm, SetNewPasswordFormProps } from '@/modules/auth/components/set-new-password-form/SetNewPasswordForm';
import React, { FC, memo } from 'react';

interface InviteManagerContentProps {
  showSetNewPasswordForm: boolean;
  isOpenLicenceAgreement: boolean;
  setNewPasswordFormContext: SetNewPasswordFormProps;
  licenceAgreementContext: LicenceAgreementProps;
  phoneCodeFormContext: PhoneCodeFormProps;
}

const InviteManagerContentInner: FC<InviteManagerContentProps> = ({ showSetNewPasswordForm, isOpenLicenceAgreement, setNewPasswordFormContext, licenceAgreementContext, phoneCodeFormContext }) => {
  if (isOpenLicenceAgreement) {
    return <LicenceAgreement {...licenceAgreementContext} />;
  }
  if (showSetNewPasswordForm) {
    return <SetNewPasswordForm {...setNewPasswordFormContext} />;
  } else {
    return <PhoneCodeForm {...phoneCodeFormContext} />;
  }
};
export const InviteManagerContent = memo(InviteManagerContentInner);
