export enum UserPermission {
  // pages permissions
  ViewPortalNotFound,
  ViewFavorites,
  ViewReceipts,
  ViewSales,
  ViewDisposals,
  ViewRemains,
  ViewInteractionLog,
  ViewFileOperations,
  ViewLegalEntities,
  ViewMdlpProducts,
  ViewNomenclature,
  ViewProcesses,
  ViewOrganizationProfile,
  ViewUsersManagement,

  // others permissions
  ViewOrganizationInInteractionLog,
  RetryOperationInInteractionLog,
  ViewOrganizationInUsersManagement,
  SendInviteLetter,
  EditOrganizationProfile,
  AddNewUser,
  ExtendUserSession,
  StartAllSyncs,
  ViewActivityPlaces
}
