export enum RemainsTableColumns {
  Sgtin = 'sgtin',
  Gtin = 'gtin',
  ActivityPlace = 'activityPlace',
  Name = 'name',
  Producer = 'producer',
  ProducerCountry = 'producerCountry',
  SeriesNumber = 'seriesNumber',
  ExpirationDate = 'expirationDate',
  Sscc = 'sscc',
  Document = 'document',
}
