export enum DocumentContractType {
  BuySell = 'BUY_SELL',
  Fee = 'FEE',
  AgencyContract = 'AGENCY_CONTRACT',
  Donation = 'DONATION',
  ReturnToManufacturer = 'RETURN_TO_MANUFACTURER',
  GovSupply = 'GOV_SUPPLY',
  ConsignmentContract = 'CONSIGNMENT_CONTRACT',
  OwnFunds = 'OWN_FUNDS',
}

const documentContractTypeLabels = {
  [DocumentContractType.BuySell]: 'Купля-продажа',
  [DocumentContractType.Fee]: 'Комиссия',
  [DocumentContractType.AgencyContract]: 'Агентский договор',
  [DocumentContractType.Donation]: 'Передача на безвозмездной основе',
  [DocumentContractType.ReturnToManufacturer]: 'Возврат контрактному производителю',
  [DocumentContractType.GovSupply]: 'Государственное лекарственное обеспечение',
  [DocumentContractType.ConsignmentContract]: 'Договор консигнации',
  [DocumentContractType.OwnFunds]: 'Собственные средства',
};

export const getDocumentContractTypeLabel = (contractType: DocumentContractType) => documentContractTypeLabels[contractType];
