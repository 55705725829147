import { RootState } from '@/store';

const selectNomenclatureMdlpComparisonState = (state: RootState) => state.references.nomenclatureMdlpComparison;

const selectIsDialogOpened = (state: RootState) => selectNomenclatureMdlpComparisonState(state).isDialogOpened;
const selectIsLoading = (state: RootState) => selectNomenclatureMdlpComparisonState(state).isLoadingData;
const selectNomenclature = (state: RootState) => selectNomenclatureMdlpComparisonState(state).currentNomenclature;
const selectNomenclatureGtin = (state: RootState) => selectNomenclatureMdlpComparisonState(state).currentNomenclature?.gtin;
const selectNomenclatureIsGtinFound = (state: RootState) => selectNomenclatureMdlpComparisonState(state).currentNomenclature?.isGtinFound;
const selectMdlpProduct = (state: RootState) => selectNomenclatureMdlpComparisonState(state).mdlpProduct;

export const NomenclatureMdlpComparisonSelectors = {
  selectIsDialogOpened,
  selectIsLoading,
  selectNomenclature,
  selectNomenclatureGtin,
  selectNomenclatureIsGtinFound,
  selectMdlpProduct,
};
