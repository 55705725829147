import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { nomenclatureModuleName } from '@/modules/references/constants/references-module-names';
import { NomenclatureItem } from '@/modules/references/models/nomenclature/nomenclature-item';
import { convertNomenclatureColumnFieldToDto } from '@/modules/references/models/nomenclature/nomenclature-table-columns-converters';
import { ReferencesRequests } from '@/modules/references/requests/references-requests';
import { NomenclatureSelectors } from '@/modules/references/store/nomenclature/selectors';
import { buildNomenclatureFilterExpression } from '@/modules/references/utils/filters/nomenclature-filter-utils';
import { ExtraFileType } from '@/shared/files/models/file-type';
import { FileRequests } from '@/shared/files/requests/file-requests';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllNomenclature = createAsyncThunkWrapper<
  PageableDataResponse<NomenclatureItem>,
  PageableDataBody
>(
  `${nomenclatureModuleName}/getAllNomenclature`,
  ({ pageNumber }, { getState }) => {
    const sorting = NomenclatureSelectors.selectTableSorting(getState());
    const filters = NomenclatureSelectors.selectFilters(getState());
    const searchString = NomenclatureSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: AppTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertNomenclatureColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const filterParams = buildNomenclatureFilterExpression(filters, searchString);
    if (filterParams) {
      body.filter = filterParams;
    }

    return ReferencesRequests.getAllNomenclature(body)
      .then(res => res.data);
  },
  { condition: asyncThunkConditionOption(NomenclatureSelectors.selectIsLoadingData) },
);

export const uploadNomenclatureFile = createAsyncThunkWrapper<
  void,
  File
>(
  `${nomenclatureModuleName}/uploadNomenclatureFile`,
  async (file) => { await FileRequests.uploadFile({ fileType: ExtraFileType.NomenclatureDict, file }); },
);
