import { useAppToast } from '@/common/hooks/use-app-toast';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { defaultFileMaxSize } from '@/shared/files/constants/file-constants';
import { getFileSizeText } from '@/shared/files/utils/file-size-utils';
import { AsyncThunkConfig } from '@/store';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useCallback } from 'react';

interface FileImportHookParams {
  uploadAction: AsyncThunk<void, File, AsyncThunkConfig>;
  openImportNotificationDialog: () => void;
}

export function useFileImport({ uploadAction, openImportNotificationDialog }: FileImportHookParams) {
  const { asyncDispatch } = useAsyncDispatch();
  const toast = useAppToast();

  const importFileMaxSize = defaultFileMaxSize;

  const acceptedImportTypes = '.xml';

  const onImportFileUploaded = useCallback((file: File) => {
    asyncDispatch(
      () => uploadAction(file),
      openImportNotificationDialog,
    );
  }, []);

  const onImportFileMaxSizeExceed = useCallback(() => {
    toast(`Ошибка импорта. Файл превышает максимально возможный размер: ${getFileSizeText(importFileMaxSize)}`, 'error');
  }, []);

  return {
    importFileMaxSize,
    acceptedImportTypes,
    onImportFileUploaded,
    onImportFileMaxSizeExceed,
  };
}
