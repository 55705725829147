import ArrowDownwardIcon from '@/assets/icons/app-table/arrow_downward.svg?react';
import DragHandleIcon from '@/assets/icons/app-table/drag_handle.svg?react';
import CancelIcon from '@/assets/icons/common/cancel.svg?react';
import EditIcon from '@/assets/icons/common/edit.svg?react';
import { AppLoader } from '@/common/components/app-loader/AppLoader';
import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { ActionsIcon } from '@/common/components/icons/actions-icon/ActionsIcon';
import { AppTableIcons } from '@/common/models/app-table/app-table-types';
import SaveIcon from '@mui/icons-material/Save';
import React, { useMemo } from 'react';

type AppTableCustomIconsHookParams = Pick<AppTableProps, 'isUpdatingInProcess'>;

export function useAppTableCustomIcons({ isUpdatingInProcess }: AppTableCustomIconsHookParams) {
  const customIcons = useMemo<Partial<AppTableIcons>>(() => ({
    MoreHorizIcon: () => <ActionsIcon />,
    DragHandleIcon: () => <DragHandleIcon className="app-table-drag-icon" />,
    ArrowDownwardIcon: (props: any) => <ArrowDownwardIcon {...props} />,
    EditIcon: () => <EditIcon className="app-table-edit-icon" />,
    CancelIcon: () => <CancelIcon className={`app-table-cancel-icon ${isUpdatingInProcess ? 'app-table-disabled-icon' : ''}`} />,
    SaveIcon: () => isUpdatingInProcess ? <AppLoader className="app-table-loader-icon" /> : <SaveIcon className="app-table-save-icon" />,
  }), [isUpdatingInProcess]);

  return {
    customIcons,
  };
}
