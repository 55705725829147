import { FilterConfigSelectors } from '@/common/store/filter-config/selectors';
import { PageableDataSelectors } from '@/common/store/pageable-data/selectors';
import { SearchingSelectors } from '@/common/store/searching/selectors';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';

const selectFileOperationsState = (state: RootState) => state.fileOperations;

const selectSearchState = (state: RootState) => selectFileOperationsState(state).search;
const selectSearchString = createSelector([selectSearchState], (searchState) => SearchingSelectors.selectSearchString(searchState));

const selectFilterConfig = (state: RootState) => selectFileOperationsState(state).filterConfig;
const selectIsPanelOpen = createSelector([selectFilterConfig], FilterConfigSelectors.selectIsPanelOpen);
const selectFilters = createSelector([selectFilterConfig], FilterConfigSelectors.selectFilters);
const selectSelectedFiltersCount = createSelector([selectFilterConfig], FilterConfigSelectors.selectSelectedFiltersCount);
const selectFilterFileType = (state: RootState) => selectFilters(state).fileType;
const selectFilterFileState = (state: RootState) => selectFilters(state).fileState;
const selectFilterOperationType = (state: RootState) => selectFilters(state).operationType;
const selectFilterOperationDateFrom = (state: RootState) => selectFilters(state).operationDateFrom;
const selectFilterOperationDateTo = (state: RootState) => selectFilters(state).operationDateTo;

const selectTableConfig = (state: RootState) => selectFileOperationsState(state).tableConfig.config;
const selectTableSorting = (state: RootState) => selectTableConfig(state).sorting;

const selectPageableDataState = (state: RootState) => selectFileOperationsState(state).pageableData;
const selectData = createSelector([selectPageableDataState], PageableDataSelectors.selectData);
const selectIsLoadingData = createSelector([selectPageableDataState], PageableDataSelectors.selectIsLoading);

export const FileOperationsSelectors = {
  selectSearchState,
  selectSearchString,
  selectFilterConfig,
  selectIsPanelOpen,
  selectFilters,
  selectSelectedFiltersCount,
  selectFilterFileType,
  selectFilterFileState,
  selectFilterOperationType,
  selectFilterOperationDateFrom,
  selectFilterOperationDateTo,
  selectTableConfig,
  selectTableSorting,
  selectPageableDataState,
  selectData,
  selectIsLoadingData,
};
