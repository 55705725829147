import { fullDateTimeFormat } from '@/common/constants/date-formats';
import { formatCurrentDate } from '@/common/utils/common/date-utils';
import { DownloadFileModel } from '@/shared/files/models/download-file-model';
import { AxiosResponse } from 'axios';

interface DownloadFileParams {
  url: string;
  fileName: string;
}

export const downloadFile = ({ url, fileName }: DownloadFileParams) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.style.visibility = 'hidden';
  anchor.click();
  window.URL.revokeObjectURL(url);
};

const getDefaultFileName = () => {
  const currentDate = formatCurrentDate(fullDateTimeFormat);
  return `Файл данных Ремедиум (${currentDate}).xml`;
};

const convertResponseToFileModel = (res: AxiosResponse<string>): DownloadFileModel => {
  const contentDisposition = res.headers['content-disposition'];
  const regex = /filename\*=UTF-8''(.*)/;
  const matches = regex.exec(contentDisposition);
  let fileName = matches?.[1];
  fileName = fileName ? decodeURIComponent(fileName) : getDefaultFileName();
  const data = new Blob([res.data]);
  return { data, fileName };
};

export const downloadFileFromResponse = (res: AxiosResponse<string>) => {
  const { fileName, data } = convertResponseToFileModel(res);
  const url = window.URL.createObjectURL(data);

  downloadFile({ fileName, url });
};
