import { useFilteringData } from '@/common/hooks/use-filtering-data';
import { PageableLoadingActions } from '@/common/models/pageable-data/pageable-loading-actions';
import { changeFilterOpenState, interactionLogFilterConfigActions } from '@/modules/interaction-log/store/interaction-log';
import { InteractionLogSelectors } from '@/modules/interaction-log/store/interaction-log/selectors';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

type InteractionLogTableFilteringHookParams = Pick<PageableLoadingActions, 'loadFirstPage'>;

export function useInteractionLogTableFiltering({ loadFirstPage }: InteractionLogTableFilteringHookParams) {
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(() => {
    dispatch(changeFilterOpenState());
  }, []);

  const { disableApplyButton, disableClearButton, clearFilter, applyFilter } = useFilteringData({
    filterConfigActions: interactionLogFilterConfigActions,
    selectFilterConfig: InteractionLogSelectors.selectFilterConfig,
    getData: loadFirstPage,
    closePanel: onFilterClick
  });

  return {
    disableApplyFilterButton: disableApplyButton,
    disableClearFilterButton: disableClearButton,
    onFilterClick,
    onApplyFiltersClick: applyFilter,
    onClearFiltersClick: clearFilter,
  };
}
