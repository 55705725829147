import { AppTableColumnHeaderWithHint } from '@/common/components/app-table/components/app-table-column-header-with-hint/AppTableColumnHeaderWithHint';
import { dateFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType } from '@/common/models/app-table/app-table-types';
import { getDocumentVisibleColumns, VisibleColumns } from '@/common/utils/app-table/get-document-visible-columns';
import { formatDate } from '@/common/utils/common/date-utils';
import { formatCurrency } from '@/common/utils/common/formatting-utils';
import { getLogicalValueLabel } from '@/common/utils/common/types-utils';
import { sortDates } from '@/common/utils/sorting/sorting-functions';
import { DocumentCardPosition } from '@/shared/document/models/document-card/positions/document-card-position';
import { DocumentCardPositionsTableColumns } from '@/shared/document/models/document-card/positions/document-card-positions-table-columns';
import { convertDocumentCardPositionColumnFieldToDto } from '@/shared/document/models/document-card/positions/document-card-positions-table-columns-converters';
import { DocumentProcessingWay } from '@/shared/document/models/document-processing-way';
import { getDocumentCardPositionCellRowClassName } from '@/shared/document/utils/get-document-card-position-cell-row-class-name';
import React, { useMemo } from 'react';

interface DocumentCardPositionsTableSettingsHookParams extends VisibleColumns<DocumentCardPositionsTableColumns> {
  processingWay: DocumentProcessingWay;
}

export function useDocumentCardPositionsTableSettings({ processingWay, visibleColumns }: DocumentCardPositionsTableSettingsHookParams) {

  const getCodeColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Code,
    header: Labels.code,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Code),
  });

  const getNameColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Name,
    header: Labels.nameHeader,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Name),
  });

  const getProducerColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Producer,
    header: Labels.producer,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Producer),
  });

  const getVitalColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Vital,
    header: Labels.vital,
    accessorFn: (row) => {
      const modelField = convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Vital);
      const isVital = row[modelField] as boolean;
      return getLogicalValueLabel(isVital);
    }
  });

  const getGtinColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Gtin,
    header: Labels.gtin,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Gtin),
  });

  const getSeriesColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Series,
    header: Labels.series,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Series),
  });

  const getExpirationDateColumn = (): AppTableColumnDefType<DocumentCardPosition> => (
    {
      id: DocumentCardPositionsTableColumns.ExpirationDate,
      header: Labels.expirationDate,
      accessorFn: (row) => `${formatDate(row.expirationDate, dateFormat)}`,
      sortingFn: (row1, row2) => sortDates(row1.original.expirationDate, row2.original.expirationDate),
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    });

  const getPriceWithVatColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.PriceWithVat,
    header: Labels.priceWithVat,
    accessorFn: (row) => formatCurrency(row.priceWithVat),
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  });

  const getVatColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Vat,
    header: Labels.vat,
    accessorFn: (row) => formatCurrency(row.vat),
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  });

  const getTotalPriceWithVatColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.TotalPriceWithVat,
    header: Labels.totalPriceWithVat,
    accessorFn: (row) => formatCurrency(row.totalPriceWithVat),
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  });

  const getVatRateColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.VatRate,
    header: Labels.vatRate,
    accessorFn: (row) => formatCurrency(row.vatRate)
  });

  const getCountOfScannedColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.CountOfScanned,
    header: Labels.countOfScanned,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.CountOfScanned),
  });

  const getIsProductInOrderColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.IsProductInOrder,
    header: '',
    headerSettingsName: Labels.isProductInOrder,
    Header: () =>
      <AppTableColumnHeaderWithHint
        header={Labels.isProductInOrder}
        hint={Labels.isProductInOrderHint}
      />,
    accessorFn: (row) => {
      const modelField = convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.IsProductInOrder);
      const isProductInOrder = row[modelField] as boolean;
      return getLogicalValueLabel(isProductInOrder);
    },
  });

  const getAmountColumn = (): AppTableColumnDefType<DocumentCardPosition> => ({
    id: DocumentCardPositionsTableColumns.Amount,
    header: Labels.amount,
    accessorKey: convertDocumentCardPositionColumnFieldToDto(DocumentCardPositionsTableColumns.Amount),
  });

  const columnsMap = useMemo<Record<DocumentCardPositionsTableColumns, AppTableColumnDefType<DocumentCardPosition>>>(
    () => ({
      [DocumentCardPositionsTableColumns.Code]: getCodeColumn(),
      [DocumentCardPositionsTableColumns.Name]: getNameColumn(),
      [DocumentCardPositionsTableColumns.Producer]: getProducerColumn(),
      [DocumentCardPositionsTableColumns.Vital]: getVitalColumn(),
      [DocumentCardPositionsTableColumns.Gtin]: getGtinColumn(),
      [DocumentCardPositionsTableColumns.Series]: getSeriesColumn(),
      [DocumentCardPositionsTableColumns.ExpirationDate]: getExpirationDateColumn(),
      [DocumentCardPositionsTableColumns.PriceWithVat]: getPriceWithVatColumn(),
      [DocumentCardPositionsTableColumns.Vat]: getVatColumn(),
      [DocumentCardPositionsTableColumns.TotalPriceWithVat]: getTotalPriceWithVatColumn(),
      [DocumentCardPositionsTableColumns.VatRate]: getVatRateColumn(),
      [DocumentCardPositionsTableColumns.CountOfScanned]: getCountOfScannedColumn(),
      [DocumentCardPositionsTableColumns.IsProductInOrder]: getIsProductInOrderColumn(),
      [DocumentCardPositionsTableColumns.Amount]: getAmountColumn()
    }), []);

  const columns = useMemo<AppTableColumnDefType<DocumentCardPosition>[]>(
    () => {
      const updatedVisibleColumns = visibleColumns?.filter((column) => {
        if (column === DocumentCardPositionsTableColumns.IsProductInOrder && processingWay === DocumentProcessingWay.Check) {
          return false;
        }
        if (column === DocumentCardPositionsTableColumns.Amount && processingWay === DocumentProcessingWay.Fill) {
          return false;
        }
        return true;

      });

      return getDocumentVisibleColumns<DocumentCardPositionsTableColumns, DocumentCardPosition>({ columnsMap, visibleColumns: updatedVisibleColumns });
    },
    [visibleColumns, processingWay, columnsMap]
  );

  const tableClasses = useMemo(() => ({
    cell: getDocumentCardPositionCellRowClassName(processingWay),
  }), [processingWay]);

  return {
    columns,
    tableClasses
  };
}
