import { DocumentCardNoteItemDeleted, DocumentCardNoteItemDeletedProps } from '@/shared/document/components/document-card-notes/components/document-card-note-item-deleted/DocumentCardNoteItemDeleted';
import { DocumentCardNoteItemEdit, DocumentCardNoteItemEditProps } from '@/shared/document/components/document-card-notes/components/document-card-note-item-edit/DocumentCardNoteItemEdit';
import { DocumentCardNoteItemView, DocumentCardNoteItemViewProps } from '@/shared/document/components/document-card-notes/components/document-card-note-item-view/DocumentCardNoteItemView';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import { NoteState } from '@/shared/document/models/document-card/notes/note-state';
import React, { FC, memo } from 'react';

interface DocumentCardNoteContentProps {
  edit:
  { editedNoteUuid: string; }
  & Pick<DocumentCardNoteItemEditProps, 'onNoteValueChange'>
  & Pick<DocumentCardNoteItemViewProps, 'onEditClick'>;
  revert:
  { revertedNoteUuid: string; }
  & Pick<DocumentCardNoteItemDeletedProps, 'isRevertingInProgress' | 'onRevertClick'>;
  delete:
  { deletedNoteUuid: string; }
  & Pick<DocumentCardNoteItemViewProps, 'isDeletingInProgress' | 'onDeleteClick'>;
  save:
  Pick<DocumentCardNoteItemEditProps, 'isSavingInProgress' | 'onSaveClick' | 'onCancelClick'>;
  note: DocumentCardNote;
  notClickableActions: boolean;
}

const DocumentCardNoteContentInner: FC<DocumentCardNoteContentProps> = ({
  edit: {
    editedNoteUuid,
    onNoteValueChange,
    onEditClick,
  },
  revert: {
    revertedNoteUuid,
    isRevertingInProgress,
    onRevertClick,
  },
  delete: {
    deletedNoteUuid,
    isDeletingInProgress,
    onDeleteClick,
  },
  save: {
    isSavingInProgress,
    onSaveClick,
    onCancelClick,
  },
  note,
  notClickableActions,
}) => {
  if (editedNoteUuid === note.documentNoteUuid) {
    return <DocumentCardNoteItemEdit
      note={note}
      isSavingInProgress={isSavingInProgress}
      onSaveClick={onSaveClick}
      onCancelClick={onCancelClick}
      onNoteValueChange={onNoteValueChange}
    />;
  }

  if (note.noteState === NoteState.Deleted) {
    return <DocumentCardNoteItemDeleted
      note={note}
      isRevertingInProgress={revertedNoteUuid === note.documentNoteUuid && isRevertingInProgress}
      notClickableActions={notClickableActions}
      showActions={note.isNoteOfCurrentUser}
      onRevertClick={onRevertClick}
    />;
  }

  return <DocumentCardNoteItemView
    note={note}
    isDeletingInProgress={deletedNoteUuid === note.documentNoteUuid && isDeletingInProgress}
    notClickableActions={notClickableActions}
    showActions={note.isNoteOfCurrentUser}
    onEditClick={onEditClick}
    onDeleteClick={onDeleteClick}
  />;
};

export const DocumentCardNoteContent = memo(DocumentCardNoteContentInner);
