import DeleteIcon from '@/assets/icons/common/delete.svg?react';
import { AppButton } from '@/common/components/app-button/AppButton';
import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { AppTableTopToolbarProps } from '@/common/components/app-table/components/app-table-top-toolbar/AppTableTopToolbar';
import { AppTableProps } from '@/common/components/app-table/components/app-table/AppTable';
import { DeleteButtonParams } from '@/shared/scanning-view/ScanningView';
import { useScanningTableSettings } from '@/shared/scanning-view/hooks/use-scanning-table-settings';
import React, { FC, memo } from 'react';
import './scanning-view-table.scss';

export interface ScanningViewTableProps {
  tableParams: AppTableProps;
  toolbarParams: AppTableTopToolbarProps;
  deleteButtonParams: DeleteButtonParams;
}

const ScanningViewTableInner: FC<ScanningViewTableProps> = ({ tableParams, toolbarParams, deleteButtonParams }) => {

  const { columns, tableClasses } = useScanningTableSettings();

  return (
    <AppCustomTable
      {...tableParams}
      columns={columns}
      classes={tableClasses}
      enableSorting={false}
      enableEditing={false}
      enableRowVirtualization
      manualSorting
      enableRowSelection
      topToolbar={{
        ...toolbarParams,
        filter: {
          ...toolbarParams.filter,
          customFilterActions: (
            <AppButton
              label={deleteButtonParams.deleteButtonLabel}
              variant="outlined"
              className="delete-button-filter"
              startIcon={<DeleteIcon />}
              disabled={deleteButtonParams.deleteButtonDisabled}
              onClick={deleteButtonParams.onDeleteClick}
            />),
          filterClasses: { actions: 'scanning-view-filter-actions', dropdown: 'scanning-view-filter-dropdown' }
        },
        search: {
          ...toolbarParams.search,
          searchClassName: 'scanning-view-search-input',
        }
      }}
    />
  );
};

export const ScanningViewTable = memo(ScanningViewTableInner);
