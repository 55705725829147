import { axios } from '@/common/api/axios-config';
import { allFavoriteDocumentsUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { FavoriteDocument } from '@/modules/favorites/models/favorite-document';
import { AxiosResponse } from 'axios';

export class FavoritesRequests {

  static getAllFavoriteDocuments(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<FavoriteDocument>>> {
    return axios.get(allFavoriteDocumentsUrl, { params });
  }

}