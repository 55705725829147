import { favoritesRoutePath } from '@/modules/favorites/routes/routes';
import { FavoritesPage } from '@/modules/favorites/views/favorites-page/FavoritesPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All favorites routes.
 */
const routes: RouteObject[] = [
  {
    path: favoritesRoutePath,
    element: <FavoritesPage />,
  }
];

export default routes;
