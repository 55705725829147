import { AppSearchBar } from '@/common/components/app-search-bar/AppSearchBar';
import { Labels } from '@/common/constants/labels';
import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { OrganizationInfoPageLayout } from '@/modules/organization-profile/components/organixation-info-page-layout/OrganizationInfoPageLayout';
import { OrganizationPlaceCard } from '@/modules/organization-profile/components/organization-place-card/OrganizationPlaceCard';
import { filterPlaces, resetState, updateSearchString } from '@/modules/organization-profile/store/organization-places';
import { getOrganizationPlaces } from '@/modules/organization-profile/store/organization-places/async-thunks';
import { OrganizationProfilePlacesSelectors } from '@/modules/organization-profile/store/organization-places/selectors';
import { LoaderWrapperPage } from '@/modules/portal/components/loader-wrappers/loader-wrapper-page/LoaderWrapperPage';
import { useAppDispatch } from '@/store';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './organization-places-page.scss';

export const OrganizationPlacesPage = () => {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  useEffect(() => {
    // organizationPlaces doesn't change often, so we don't need to update it every page's render. We get data on the first load page only
    if (!organizationPlaces.length) {
      asyncDispatch(getOrganizationPlaces);
    }
    return () => { dispatch(resetState()); };
  }, []);

  const organizationPlaces = useSelector(OrganizationProfilePlacesSelectors.selectOrganizationPlaces);
  const loadingStatus = useSelector(OrganizationProfilePlacesSelectors.selectLoadingStatus);
  const searchString = useSelector(OrganizationProfilePlacesSelectors.selectSearchString);

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: OrganizationProfilePlacesSelectors.selectSearchState
  });

  const searchPlaceholder = getSearchString([Labels.title, Labels.addressFull]);

  const onSearchValueInput = useCallback((value: string) => {
    dispatch(updateSearchString(value));
  }, []);

  const onApplySearchClick = useCallback(() => {
    dispatch(filterPlaces());
  }, []);

  const onClearSearchClick = useCallback(() => {
    dispatch(filterPlaces());
  }, []);

  return (
    <LoaderWrapperPage
      loadingStatus={loadingStatus}
      retryAction={getOrganizationPlaces}
    >
      <OrganizationInfoPageLayout className="organization-places-page-container">
        <AppSearchBar
          value={searchString}
          placeholder={searchPlaceholder}
          searchButtonDisabled={isSearchNotChanged}
          onInput={onSearchValueInput}
          onSearchClick={onApplySearchClick}
          onClearClick={onClearSearchClick}
        />
        {organizationPlaces.map((place) => (
          <OrganizationPlaceCard
            key={place.mdlpId}
            model={place}
          />
        ))}
      </OrganizationInfoPageLayout>
    </LoaderWrapperPage>
  );
};
