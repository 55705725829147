import { AppMenuItem } from '@/common/components/app-menu/components/AppMenuItem';
import { AppTableRowActionMenuItemModel } from '@/common/models/app-table/app-table-row-action-menu-item-model';
import { AppTableRowType } from '@/common/models/app-table/app-table-types';
import React, { FC, memo, useCallback } from 'react';

interface AppTableRowActionsMenuProps {
  item: AppTableRowActionMenuItemModel;
  row: AppTableRowType;
  closeMenu: () => void;
}

const AppTableRowActionsMenuInner: FC<AppTableRowActionsMenuProps> = ({ item, row, closeMenu }) => {

  const onClick = useCallback(() => {
    item.action(row)
      ?.unwrap()
      ?.then(closeMenu)
      || closeMenu();
  }, [row, item.action, closeMenu]);

  return (
    <AppMenuItem
      {...item}
      action={onClick}
    />
  );
};

export const AppTableRowActionMenuItem = memo(AppTableRowActionsMenuInner);
