export enum RsqlFilterOperations {
  Equal = '==',
  NotEqual = '!=',
  Greater = '>',
  GreaterEq = '>=',
  Less = '<',
  LessEq = '<=',
  In = '=in=',
  Out = '=out=',
  Like = '=like=',
  ILike = '=ilike=',
  EILike = '=eilike=',
}
