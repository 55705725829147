import { getPermissionByRoute } from '@/modules/portal/constants/users-permissions-by-routes';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { MenuPanelItem as MenuItemType, MenuPanelCommonItem } from '@/modules/portal/models/menu-panel-item';

export function useMenuPanelItemsVisibility() {
  const { hasAccessByPermission } = useUserPermissionsAccess();

  const isCommonItemVisible = (item: MenuPanelCommonItem) => {
    if (!item.link) {
      return false;
    }
    const permission = getPermissionByRoute(item.link);
    return hasAccessByPermission(permission);
  };

  const isParentItemVisible = (item: MenuItemType) => {
    if (item.link) {
      return isCommonItemVisible(item);
    }
    return item.children.some((child) => isCommonItemVisible(child));
  };

  return {
    isParentItemVisible,
    isChildItemVisible: isCommonItemVisible,
  };
}
