import { AsyncDispatchResult } from '@/common/hooks/use-async-dispatch';
import { useRef } from 'react';

export const useAsyncDispatchAborting = () => {
  const dispatchResultRef = useRef<AsyncDispatchResult>();

  const setDispatchResult = (dispatchResult: AsyncDispatchResult) => {
    dispatchResultRef.current = dispatchResult;
  };

  const abortRequest = () => {
    dispatchResultRef?.current?.abort();
  };

  const abortRequestByParam = (dispatchResult: AsyncDispatchResult) => {
    dispatchResult?.abort();
  };

  return {
    setDispatchResult,
    abortRequest,
    abortRequestByParam
  };
};
