import { SearchingCaseReducers } from '@/common/models/searching/searching-case-reducers';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SearchingState = {
  currentSearchString: '',
  lastAppliedSearchString: '',
};

export const createSearchingSlice = (name: string) => {
  return createSlice<SearchingState, SearchingCaseReducers>({
    name: `${name}/searching`,
    initialState,
    reducers: {
      updateSearchString(state: SearchingState, action: PayloadAction<string>) {
        state.currentSearchString = action.payload;
      },
      saveAppliedSearchString(state: SearchingState) {
        state.lastAppliedSearchString = state.currentSearchString;
      },
    }
  });
};
