import { AppTableItemsPageSize } from '@/common/constants/app-table-constants';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { getRequestSorting } from '@/common/utils/sorting/sorting-utils';
import { mdlpProductsModuleName } from '@/modules/references/constants/references-module-names';
import { MdlpProduct } from '@/modules/references/models/mdlp-products/mdlp-product';
import { convertMdlpProductColumnFieldToDto } from '@/modules/references/models/mdlp-products/mdlp-products-table-columns-converters';
import { ReferencesRequests } from '@/modules/references/requests/references-requests';
import { MdlpProductsSelectors } from '@/modules/references/store/mdlp-products/selectors';
import { buildMdlpProductsFilterExpression } from '@/modules/references/utils/filters/mdlp-products-filter-utils';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getAllMdlpProducts = createAsyncThunkWrapper<
  PageableDataResponse<MdlpProduct>,
  PageableDataBody
>(
  `${mdlpProductsModuleName}/getAllMdlpProducts`,
  ({ pageNumber }, { getState }) => {
    const sorting = MdlpProductsSelectors.selectTableSorting(getState());
    const filters = MdlpProductsSelectors.selectFilters(getState());
    const searchString = MdlpProductsSelectors.selectSearchString(getState());

    const body: PageableDataQueryParams = {
      page: pageNumber,
      size: AppTableItemsPageSize,
    };

    const sortParams = getRequestSorting(sorting, convertMdlpProductColumnFieldToDto);
    if (sortParams) {
      body.sort = sortParams;
    }

    const filterParams = buildMdlpProductsFilterExpression(filters, searchString);
    if (filterParams) {
      body.filter = filterParams;
    }

    return ReferencesRequests.getAllMdlpProducts(body)
      .then((res) => res.data);
  },
  { condition: asyncThunkConditionOption(MdlpProductsSelectors.selectIsLoadingData) }
);
