import { DoneIcon } from '@/common/components/icons/done-icon/DoneIcon';
import { ErrorIcon } from '@/common/components/icons/error-icon/ErrorIcon';
import { LoadingIcon } from '@/common/components/icons/loading-icon/LoadingIcon';
import { SyncStatus } from '@/modules/portal/models/sync/sync-status';
import React, { FC, memo } from 'react';

interface SyncItemStatusIconProps {
  status: SyncStatus;
}

const SyncItemStatusIconInner: FC<SyncItemStatusIconProps> = ({ status }) => {
  let StatusIcon;
  if (status === SyncStatus.CompleteSuccesslully) {
    StatusIcon = DoneIcon;
  } else if (status === SyncStatus.CompleteExceptionally) {
    StatusIcon = ErrorIcon;
  } else {
    StatusIcon = LoadingIcon;
  }

  return (
    <StatusIcon
      width={24}
      height={24}
    />
  );
};
export const SyncItemStatusIcon = memo(SyncItemStatusIconInner);