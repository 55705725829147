import { CertificatesSelectors } from '@/modules/certificates/store/selectors';
import { getSignatureByCertificateOwner } from '@/modules/certificates/utils/get-signature-by-certificate-owner';
import { portalHeaderModuleName } from '@/modules/portal/constants/portal-module-names';
import { HeaderInfo } from '@/modules/portal/models/header-info';
import { MdlpAuthCodeBody } from '@/modules/portal/models/mdlp-auth-code/mdlp-auth-code-body';
import { MdlpTokenBody } from '@/modules/portal/models/mdlp-token/mdlp-token-body';
import { HeaderRequests } from '@/modules/portal/requests/header-requests';
import { setIsOpenCertificatesListDialog } from '@/modules/portal/store/header';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getHeaderInfo = createAsyncThunkWrapper<
  HeaderInfo,
  void
>(
  `${portalHeaderModuleName}/getHeaderInfo`,
  (_, { dispatch }) => HeaderRequests.headerInfo()
    .then((res) => {
      res.data.isTokenExpired && dispatch(setIsOpenCertificatesListDialog(true));
      return res.data;
    })
);

export const getAuthCode = createAsyncThunkWrapper<
  MdlpTokenBody,
  MdlpAuthCodeBody
>(
  `${portalHeaderModuleName}/getAuthCode`,
  (body, { getState }) =>
    HeaderRequests.mdlpAuthCode(body)
      .then((res) => {
        const { authCode } = res.data;
        const selectedSertificate = CertificatesSelectors.selectSelectedCertificate(getState());
        return getSignatureByCertificateOwner(selectedSertificate.owner, authCode)
          .then((signature) => {
            const bodyForSignupAuthCode: MdlpTokenBody = {
              code: authCode,
              signature
            };
            return bodyForSignupAuthCode;
          });
      })
);

export const setToken = createAsyncThunkWrapper<
  void,
  MdlpTokenBody
>(
  `${portalHeaderModuleName}/setToken`,
  async (body) => {
    await HeaderRequests.mdlpToken(body);
  }
);