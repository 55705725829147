import { axios } from '@/common/api/axios-config';
import { allDocumentNotesUrl, documentNoteByUuidUrl, documentNoteUrl, revertDocumentNoteUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { AddNoteBody } from '@/shared/document/models/document-card/notes/add-note-body';
import { DocumentCardNote } from '@/shared/document/models/document-card/notes/document-card-note';
import { EditNoteBody } from '@/shared/document/models/document-card/notes/edit-note-body';
import { AxiosResponse } from 'axios';

export class DocumentCardNotesRequests {

  static getAllNotes(params: PageableDataQueryParams, signal?: AbortSignal): Promise<AxiosResponse<PageableDataResponse<DocumentCardNote>>> {
    return axios.get(allDocumentNotesUrl, { params, signal });
  }

  static addNote(body: AddNoteBody): Promise<AxiosResponse<DocumentCardNote>> {
    return axios.post(documentNoteUrl, body);
  }

  static editNote(documentNoteUuid: string, body: EditNoteBody): Promise<AxiosResponse<DocumentCardNote>> {
    return axios.patch(documentNoteByUuidUrl(documentNoteUuid), body);
  }

  static deleteNote(documentNoteUuid: string): Promise<AxiosResponse<void>> {
    return axios.delete(documentNoteByUuidUrl(documentNoteUuid));
  }

  static revertNote(documentNoteUuid: string): Promise<AxiosResponse<void>> {
    return axios.patch(revertDocumentNoteUrl(documentNoteUuid));
  }
}
