import { AppTableRowSelectionType, Updater } from '@/common/models/app-table/app-table-types';
import { FilterConfigState } from '@/common/models/filter-config/filter-config-state';
import { LoadingStatus } from '@/common/models/loading-status';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { SearchingState } from '@/common/models/searching/searching-state';
import { createFilterConfigSlice } from '@/common/store/filter-config';
import { createPageableDataSlice } from '@/common/store/pageable-data';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { createSearchingSlice } from '@/common/store/searching';
import { getReducerAction } from '@/common/utils/common/get-reducer-action';
import { getValueFromUpdater } from '@/common/utils/common/types-utils';
import { DocumentCardScanningFilter } from '@/shared/document/models/document-card/scanning/document-card-scanning-filter';
import { DocumentsCardScanningApi } from '@/shared/document/store/document-card/scanning/types';
import { defaultScanningTableConfig } from '@/shared/scanning-view/constants/scanning-table-config';
import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import { isSuccessfulScanningStatus } from '@/shared/scanning-view/models/scanning-status';
import { CaseReducerActions, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface DocumentCardScanningState {
  tableConfig: SavedTableConfigState;
  rowsSelection: AppTableRowSelectionType;
  search: SearchingState;
  filterConfig: FilterConfigState<DocumentCardScanningFilter>;
  pageableData: PageableDataState<ScanningInfo>;
  lastScannedItem: ScanningInfo;
  isGettingLastScannedItemInProcess: boolean;
  isDeletingInProcess: boolean;
}

interface DocumentCardScanningCaseReducers extends SliceCaseReducers<DocumentCardScanningState> {
  setRowSelection: (state: Draft<DocumentCardScanningState>, action: PayloadAction<Updater<AppTableRowSelectionType>>) => void;
  resetState: (state: Draft<DocumentCardScanningState>) => void;
}

export type DocumentCardScanningActions = CaseReducerActions<DocumentCardScanningCaseReducers, string>;

const notClearedFields: Set<keyof DocumentCardScanningState> = new Set(['tableConfig']);

interface CreateDocumentCardScanningSliceParams {
  moduleName: string;
  savedTableConfigName: string;
  api: DocumentsCardScanningApi;
}

export const createDocumentCardScanningSlice = ({ moduleName, savedTableConfigName, api }: CreateDocumentCardScanningSliceParams) => {
  const scanningTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: defaultScanningTableConfig,
  };

  const documentCardScanningSearchingSlice = createSearchingSlice(moduleName);
  const documentCardScanningTableConfigSlice = createSavedTableConfigSlice(moduleName, scanningTableConfigState);
  const documentCardScanningPageableDataSlice = createPageableDataSlice<ScanningInfo>(moduleName);
  const documentCardScanningFilterConfigSlice = createFilterConfigSlice<DocumentCardScanningFilter>(moduleName);

  const initialState: DocumentCardScanningState = {
    search: documentCardScanningSearchingSlice.getInitialState(),
    tableConfig: documentCardScanningTableConfigSlice.getInitialState(),
    pageableData: documentCardScanningPageableDataSlice.getInitialState(),
    filterConfig: documentCardScanningFilterConfigSlice.getInitialState(),
    rowsSelection: {},
    lastScannedItem: undefined,
    isGettingLastScannedItemInProcess: false,
    isDeletingInProcess: false,
  };

  const rootSlice = createSlice<DocumentCardScanningState, DocumentCardScanningCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      setRowSelection(state: DocumentCardScanningState, action: PayloadAction<Updater<AppTableRowSelectionType>>) {
        state.rowsSelection = getValueFromUpdater<AppTableRowSelectionType>(action.payload, state.rowsSelection);
      },
      resetState: (state) => {
        Object.keys(state)
          .forEach((key: keyof DocumentCardScanningState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.addScannedItemInfo.pending, (state) => {
          state.isGettingLastScannedItemInProcess = true;
        })
        .addCase(api.addScannedItemInfo.fulfilled, (state, action) => {
          state.lastScannedItem = action.payload;
          state.isGettingLastScannedItemInProcess = false;
          if (isSuccessfulScanningStatus(action.payload.state)) {
            const { addItemToStart } = documentCardScanningPageableDataSlice.caseReducers;
            addItemToStart(state.pageableData, getReducerAction(addItemToStart.name, action.payload));
          }
        })
        .addCase(api.addScannedItemInfo.rejected, (state) => {
          state.isGettingLastScannedItemInProcess = false;
        })
        .addCase(api.getAllScannedItems.pending, (state) => {
          const { updateLoadingStatus } = documentCardScanningPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Pending));
        })
        .addCase(api.getAllScannedItems.rejected, (state) => {
          const { updateLoadingStatus } = documentCardScanningPageableDataSlice.caseReducers;
          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Rejected));
        })
        .addCase(api.getAllScannedItems.fulfilled, (state) => {
          const { updateLoadingStatus } = documentCardScanningPageableDataSlice.caseReducers;
          const { updateStateAfterGettingData } = documentCardScanningFilterConfigSlice.caseReducers;
          const { saveAppliedSearchString } = documentCardScanningSearchingSlice.caseReducers;

          updateLoadingStatus(state.pageableData, getReducerAction(updateLoadingStatus.name, LoadingStatus.Fulfilled));
          updateStateAfterGettingData(state.filterConfig);
          saveAppliedSearchString(state.search);
        })
        .addCase(api.deleteScannedItems.pending, (state) => {
          state.isDeletingInProcess = true;
        })
        .addCase(api.deleteScannedItems.fulfilled, (state) => {
          state.isDeletingInProcess = false;
          state.rowsSelection = {};
        })
        .addCase(api.deleteScannedItems.rejected, (state) => {
          state.isDeletingInProcess = false;
        })
        .addMatcher(
          (action) => action.type.startsWith(documentCardScanningTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = documentCardScanningTableConfigSlice.reducer(state.tableConfig, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentCardScanningPageableDataSlice.name),
          (state, action) => {
            state.pageableData = documentCardScanningPageableDataSlice.reducer(state.pageableData, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentCardScanningSearchingSlice.name),
          (state, action) => {
            state.search = documentCardScanningSearchingSlice.reducer(state.search, action);
          }
        )
        .addMatcher(
          (action) => action.type.startsWith(documentCardScanningFilterConfigSlice.name),
          (state, action) => {
            state.filterConfig = documentCardScanningFilterConfigSlice.reducer(state.filterConfig, action);
          }
        );
    }
  });

  return {
    rootSlice,
    documentCardScanningSearchingSlice,
    documentCardScanningTableConfigSlice,
    documentCardScanningPageableDataSlice,
    documentCardScanningFilterConfigSlice,
  };
};
