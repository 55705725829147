import { useAppToast } from '@/common/hooks/use-app-toast';
import { disposalCardRemainsSelectors } from '@/modules/disposals/store/disposal-card/remains/table/selectors';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useAddingRemainsSuccessfulNotification = () => {
  const toast = useAppToast();

  const rowsSelectionsIds = useSelector(disposalCardRemainsSelectors.selectRowsSelectionIds);
  const selectedRowsCount = rowsSelectionsIds.length;

  const message = useMemo(() => {
    let commonMessage = 'В документ будет ';

    switch (selectedRowsCount) {
    case 1:
      commonMessage += `добавлен ${selectedRowsCount} товар`;
      break;
    case 2 || 3 || 4:
      commonMessage += `добавлено ${selectedRowsCount} товара`;
      break;
    default:
      commonMessage += `добавлено ${selectedRowsCount} товаров`;
      break;
    }
    return commonMessage;
  }, [selectedRowsCount]);

  const showSuccessfulNotification = useCallback(() => {
    toast(message);
  }, [message]);

  return {
    showSuccessfulNotification,
  };
};