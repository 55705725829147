export enum OrganizationPlaceType {
  Storage = 'STORAGE',
  Pharmacy = 'PHARMACY',
}

const organizationPlacesTitles = {
  [OrganizationPlaceType.Storage]: 'Склад',
  [OrganizationPlaceType.Pharmacy]: 'Аптека',
};

export const getOrganizationPlaceTitle = (value: OrganizationPlaceType): string => organizationPlacesTitles[value];

export const getOrganizationPlacesTypesOptions = () => Object.entries(organizationPlacesTitles).map(([key, value]) => ({
  label: value,
  value: key,
}));
