import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { interactionLogNotificationHistoryModuleName } from '@/modules/interaction-log/constants/interaction-log-module-names';
import { defaultInteractionLogNotificationHistoryTableConfig } from '@/modules/interaction-log/constants/interaction-log-notification-history-table-config';
import { NotificationHistory } from '@/modules/interaction-log/models/interaction-log-notification-history/notification-history';
import { getNotificationHistory } from '@/modules/interaction-log/store/interaction-log-notification-history/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InteractionLogNotificationHistoryState {
  tableConfig: SavedTableConfigState;
  isOpenNotificationHistoryDialog: boolean;
  notificationHistory: NotificationHistory[];
  isLoadingNotificationHistory: boolean;
  currentEventUuid: string;
}

const notClearedFields: Set<keyof InteractionLogNotificationHistoryState> = new Set(['tableConfig']);

const interactionLogNotificationHistoryTableConfigState: SavedTableConfigState = {
  tableConfigName: 'interaction-log-notification-history-table',
  config: defaultInteractionLogNotificationHistoryTableConfig,
};

const interactionLogNotificationHistoryTableConfigSlice = createSavedTableConfigSlice(interactionLogNotificationHistoryModuleName, interactionLogNotificationHistoryTableConfigState);

const initialState: InteractionLogNotificationHistoryState = {
  tableConfig: interactionLogNotificationHistoryTableConfigSlice.getInitialState(),
  isOpenNotificationHistoryDialog: false,
  notificationHistory: [],
  isLoadingNotificationHistory: false,
  currentEventUuid: undefined,
};

export const interactionLoNotificationHistorySlice = createSlice({
  name: interactionLogNotificationHistoryModuleName,
  initialState,
  reducers: {
    setCurrentEventUuid(state, action: PayloadAction<string>) {
      state.currentEventUuid = action.payload;
    },
    setIsOpenNotificationHistoryDialog(state, action: PayloadAction<boolean>) {
      state.isOpenNotificationHistoryDialog = action.payload;
    },
    resetState(state) {
      Object.keys(state)
        .forEach((key: keyof InteractionLogNotificationHistoryState) => {
          if (!notClearedFields.has(key)) {
            // @ts-ignore
            state[key] = initialState[key];
          }
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationHistory.pending, (state) => {
        state.isLoadingNotificationHistory = true;
      })
      .addCase(getNotificationHistory.fulfilled, (state, action: PayloadAction<NotificationHistory[]>) => {
        state.notificationHistory = action.payload;
        state.isLoadingNotificationHistory = false;
      })
      .addCase(getNotificationHistory.rejected, (state) => {
        state.isLoadingNotificationHistory = false;
      })
      .addMatcher(
        (action) => action.type.startsWith(interactionLogNotificationHistoryTableConfigSlice.name),
        (state, action) => {
          state.tableConfig = interactionLogNotificationHistoryTableConfigSlice.reducer(state.tableConfig, action);
        }
      );
  }
});

export const {
  setCurrentEventUuid,
  setIsOpenNotificationHistoryDialog,
  resetState,
} = interactionLoNotificationHistorySlice.actions;

export const {
  updateTableColumnVisibilityState,
  updateTableSortingState,
  updateTableColumnOrderState,
  updateTableColumnSizingState,
} = interactionLogNotificationHistoryTableConfigSlice.actions;

export default interactionLoNotificationHistorySlice.reducer;
