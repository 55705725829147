import { axios } from '@/common/api/axios-config';
import {
  passwordRecoveryCompleteUrl,
  passwordRecoveryInitUrl, signinCompleteUrl,
  signinInitUrl,
  signupAuthCodeUrl,
  signupCompleteUrl,
  signupConfirmContactUrl,
  signupInitUrl,
  signupManagerCompleteUrl,
  signupManagerVerifyPhoneNumberUrl,
  signupValidateContactsUrl,
  verificationCodeSendByCipcherUrl,
  verificationCodeSendUrl
} from '@/common/api/urls';
import { LoginData } from '@/modules/auth/models/login-data';
import { PasswordRecoveryCompleteBody } from '@/modules/auth/models/password-recovery-complete/password-recovery-complete-body';
import { PasswordRecoveryInitBody } from '@/modules/auth/models/password-recovery-init/password-recovery-init-body';
import { PasswordRecoveryInitResponse } from '@/modules/auth/models/password-recovery-init/password-recovery-init-response';
import { SignInCompleteBody } from '@/modules/auth/models/sign-in-complete/sign-in-complete-body';
import { SignInInitResponse } from '@/modules/auth/models/sign-in-init/sign-in-init-response';
import { SignupAuthCodeBody } from '@/modules/auth/models/signup-auth-code/signup-auth-code-body';
import { SignupAuthCodeResponse } from '@/modules/auth/models/signup-auth-code/signup-auth-code-response';
import { SignupCompleteBody } from '@/modules/auth/models/signup-complete/signup-complete-body';
import { SignupConfirmContactRequestBody } from '@/modules/auth/models/signup-confirm-contact/signup-confirm-contact-body';
import { SignupConfirmContactResponse } from '@/modules/auth/models/signup-confirm-contact/signup-confirm-contact-response';
import { SignupInitBody } from '@/modules/auth/models/signup-init/signup-init-body';
import { SignupInitResponse } from '@/modules/auth/models/signup-init/signup-init-response';
import { SignupManagerCompleteBody } from '@/modules/auth/models/signup-manager-complete/signup-manager-complete-body';
import { SignupManagerVerifyPhoneNumberBody } from '@/modules/auth/models/signup-manager-verify-phone-number/signup-manager-verify-phone-number-body';
import { SignupValidateContactsBody } from '@/modules/auth/models/signup-validate-contacts/signup-validate-contacts-body';
import { VerificationCodeSendBody } from '@/modules/auth/models/verification-code-send/verification-code-send-body';
import { VerificationCodeSendByCipcherBody } from '@/modules/auth/models/verification-code-send/verification-code-send-by-cipcher-body';
import { VerificationCodeSendResponse } from '@/modules/auth/models/verification-code-send/verification-code-send-response';
import { AxiosResponse } from 'axios';

export class AuthRequests {

  static signinInit(body: LoginData): Promise<AxiosResponse<SignInInitResponse>> {
    return axios.post(signinInitUrl, body);
  }

  static signinComplete(body: SignInCompleteBody): Promise<AxiosResponse<void>> {
    return axios.post(signinCompleteUrl, body);
  }

  static signupValidateContacts(body: SignupValidateContactsBody): Promise<AxiosResponse<SignupInitResponse>> {
    return axios.post(signupValidateContactsUrl, body);
  }

  static signupInit(body: SignupInitBody): Promise<AxiosResponse<SignupInitResponse>> {
    return axios.post(signupInitUrl, body);
  }

  static signupAuthCode(body: SignupAuthCodeBody): Promise<AxiosResponse<SignupAuthCodeResponse>> {
    return axios.post(signupAuthCodeUrl, body);
  }

  static signupConfirmContact(body: SignupConfirmContactRequestBody): Promise<AxiosResponse<SignupConfirmContactResponse>> {
    return axios.post(signupConfirmContactUrl, body);
  }

  static signupComplete(body: SignupCompleteBody): Promise<AxiosResponse<void>> {
    return axios.post(signupCompleteUrl, body);
  }

  static signupManagerVerifyPhoneNumber(body: SignupManagerVerifyPhoneNumberBody): Promise<AxiosResponse<void>> {
    return axios.post(signupManagerVerifyPhoneNumberUrl, body);
  }

  static signupManagerComplete(body: SignupManagerCompleteBody): Promise<AxiosResponse<void>> {
    return axios.post(signupManagerCompleteUrl, body);
  }

  static verificationCodeSend(body: VerificationCodeSendBody): Promise<AxiosResponse<VerificationCodeSendResponse>> {
    return axios.post(verificationCodeSendUrl, body);
  }

  static verificationCodeSendByCipher(body: VerificationCodeSendByCipcherBody): Promise<AxiosResponse<VerificationCodeSendResponse>> {
    return axios.post(verificationCodeSendByCipcherUrl, body);
  }

  static passwordRecoveryInit(body: PasswordRecoveryInitBody): Promise<AxiosResponse<PasswordRecoveryInitResponse>> {
    return axios.post(passwordRecoveryInitUrl, body);
  }

  static passwordRecoveryComplete(body: PasswordRecoveryCompleteBody): Promise<AxiosResponse<void>> {
    return axios.post(passwordRecoveryCompleteUrl, body);
  }
}
