import { errorResponseHandler, successResponseHandler } from '@/common/api/axios-handlers';
import axiosLib from 'axios';
import queryString from 'query-string';

export const axios = axiosLib.create({
  // @ts-ignore
  baseURL: import.meta.env.VITE_REMEDIUM_APP_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
  withCredentials: true,
  paramsSerializer: (params) => queryString.stringify(params, { encode: false }),
});

axios.interceptors.response.use(
  successResponseHandler,
  errorResponseHandler
);
