import { AsyncDispatchResult, useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { PageableDataActions } from '@/common/models/pageable-data/pageable-data-actions';
import { PageableDataBody } from '@/common/models/pageable-data/pageable-data-body';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { PageableDataState } from '@/common/models/pageable-data/pageable-data-state';
import { AsyncThunkConfig, RootState, useAppDispatch } from '@/store';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

interface PageableRequestDataHookParams<Source> {
  dataActions: PageableDataActions<Source>;
  selectPageableDataState: (state: RootState) => PageableDataState<Source>,
  getData: AsyncThunk<PageableDataResponse<Source>, PageableDataBody, AsyncThunkConfig>;
  resetCondition?: unknown;
}

export function usePageableLoadingData<Source>({ dataActions, selectPageableDataState, getData, resetCondition }: PageableRequestDataHookParams<Source>) {
  const dispatch = useAppDispatch();
  const { asyncDispatch } = useAsyncDispatch();

  const data = useSelector((state: RootState) => selectPageableDataState(state).data);
  const pageNumber = useSelector((state: RootState) => selectPageableDataState(state).pageNumber);
  const totalItems = useSelector((state: RootState) => selectPageableDataState(state).totalItems);
  const dispatchResultRef = useRef<AsyncDispatchResult>();

  const canGetNextPage = useMemo(() => data.length < totalItems, [data, totalItems]);

  const loadNextPage = useCallback(() => {
    if (canGetNextPage) {
      dispatchResultRef.current = asyncDispatch(
        () => getData({ pageNumber: pageNumber + 1 }),
        (data: PageableDataResponse<Source>) => {
          dispatch(dataActions.addData(data));
          dispatch(dataActions.moveToNextPage());
        },
      );
    }
  }, [canGetNextPage, pageNumber]);

  const loadFirstPage = useCallback(() => {
    dispatchResultRef.current = asyncDispatch(
      () => getData({ pageNumber: 0 }),
      (data: PageableDataResponse<Source>) => {
        dispatch(dataActions.rewriteData(data));
        dispatch(dataActions.resetPagination());
      }
    );
  }, []);

  useEffect(() => {
    loadFirstPage();
  }, [resetCondition]);

  return {
    dispatchResult: dispatchResultRef.current,
    loadNextPage,
    loadFirstPage
  };
}
