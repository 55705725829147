import { formatNumber } from '@/common/utils/common/formatting-utils';
import React, { forwardRef, memo, ReactNode } from 'react';
import './app-table-bottom-toolbar.scss';

export interface AppTableBottomToolbarProps {
  content?: ReactNode;
  totalItems: number;
}

const AppTableBottomToolbarInner = forwardRef<HTMLDivElement, AppTableBottomToolbarProps>((
  { content, totalItems }, ref) => {
  return (
    <div
      className="app-table-bottom-toolbar-container"
      ref={ref}
    >
      {content}
      <span className="total-items">Всего: {formatNumber(totalItems)}</span>
    </div>
  );
});

AppTableBottomToolbarInner.displayName = 'AppTableBottomToolbar';

export const AppTableBottomToolbar = memo(AppTableBottomToolbarInner);
