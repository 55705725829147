import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { AuthLayout } from '@/modules/auth/components/base-auth-layout/AuthLayout';
import { PasswordRecoveryForm } from '@/modules/auth/components/password-recovery-form/PasswordRecoveryForm';
import { PhoneCodeForm } from '@/modules/auth/components/phone-code-form/PhoneCodeForm';
import { usePhoneCodeFormForPasswordRecovery } from '@/modules/auth/hooks/use-phone-code-form-for-password-recovery';
import { VerificationCodeSendErrorFields } from '@/modules/auth/models/verification-code-send/verification-code-send-error-fields';
import { loginRoutePath } from '@/modules/auth/routes/routes';
import { resetState } from '@/modules/auth/store/password-recovery';
import { sendVerificationCode } from '@/modules/auth/store/password-recovery/async-thunks';
import { PasswordRecoverySelectors } from '@/modules/auth/store/password-recovery/selectors';
import { useAppDispatch } from '@/store';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PasswordRecoveryPageInner: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fieldErrors, resetFieldErrors, asyncDispatch } = useAsyncDispatch<VerificationCodeSendErrorFields>();

  const showPasswordRecoveryForm = useSelector(PasswordRecoverySelectors.selectShowPasswordRecoveryForm);

  useEffect(() => {
    return () => { dispatch(resetState()); };
  }, []);

  const navigateToLogin = useCallback(() => {
    dispatch(resetState());
    resetFieldErrors();
    navigate(loginRoutePath);
  }, []);

  const goToSendPhoneCode = useCallback(() => {
    asyncDispatch(sendVerificationCode);
  }, []);

  const phoneCodeFormContext = usePhoneCodeFormForPasswordRecovery();

  return (
    <AuthLayout padding>
      {showPasswordRecoveryForm ?
        <PasswordRecoveryForm
          fieldErrors={fieldErrors}
          onGoBackClick={navigateToLogin}
          onNextClick={goToSendPhoneCode}
        /> :
        <PhoneCodeForm
          phone={phoneCodeFormContext.login}
          code={phoneCodeFormContext.code}
          codeStatus={phoneCodeFormContext.codeStatus}
          isLoading={phoneCodeFormContext.isLoading}
          expireVerificationCode={phoneCodeFormContext.expireTimePhone}
          successMessage={phoneCodeFormContext.successMessage}
          onInput={phoneCodeFormContext.onCodeInput}
          onCompleteInput={phoneCodeFormContext.onCodeValueChange}
          onSendPhoneNumberCodeClick={phoneCodeFormContext.onSendPhoneNumberCodeClick}
          onGoBackClick={navigateToLogin}
        />
      }
    </AuthLayout>
  );
};

export const PasswordRecoveryPage = memo(PasswordRecoveryPageInner);
