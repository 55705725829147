import AdminIcon from '@/assets/icons/users/admin.svg?react';
import BlockedUserIcon from '@/assets/icons/users/blocked_user.svg?react';
import InvitedUserIcon from '@/assets/icons/users/invited_user.svg?react';
import User from '@/assets/icons/users/user.svg?react';
import { AppTooltip } from '@/common/components/app-tooltip/AppTooltip';
import { Roles } from '@/modules/portal/models/roles';
import { UserState } from '@/modules/users-management/models/user-state';
import React, { FC, memo, useMemo } from 'react';
import './user-icon.scss';

interface UserIconProps {
  userState: UserState;
  userRoles: Roles[];
}

const UserIconInner: FC<UserIconProps> = ({ userState, userRoles }) => {

  const isOrganizationAdmin = useMemo(() => userRoles.includes(Roles.OrganizationAdmin), [userRoles]);

  const userIcon = useMemo(() => {
    if (isOrganizationAdmin) {
      return <AdminIcon />;
    }
    if (userState === UserState.Blocked) {
      return <BlockedUserIcon />;
    }
    if (userState === UserState.Invited) {
      return <InvitedUserIcon />;
    }
    return <User />;
  }, [userState]);

  const tooltipText = useMemo(() => {
    if (isOrganizationAdmin) {
      return 'Администратор организации';
    }
    if (userState === UserState.Blocked) {
      return 'Пользователь заблокирован';
    }
    if (userState === UserState.Invited) {
      return 'Приглашение отправлено';
    }
    return 'Пользователь активен';
  }, [userState]);

  const className = useMemo(() => {
    if (userState === UserState.Blocked) {
      return 'blocked-user-icon';
    }
    return 'regular-user-icon';
  }, [userState]);

  return (
    <AppTooltip text={tooltipText}>
      <div className={`user-icon-container ${className}`}>
        {userIcon}
      </div>
    </AppTooltip>
  );
};

export const UserIcon = memo(UserIconInner);
