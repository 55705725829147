import { interactionLogNotificationHistoryModuleName } from '@/modules/interaction-log/constants/interaction-log-module-names';
import { NotificationHistory } from '@/modules/interaction-log/models/interaction-log-notification-history/notification-history';
import { InteractionLogRequests } from '@/modules/interaction-log/requests/interaction-log-requests';
import { InteractionLogNotificationHistorySelectors } from '@/modules/interaction-log/store/interaction-log-notification-history/selectors';
import { asyncThunkConditionOption } from '@/store/async-thunk-condition-option';
import { createAsyncThunkWrapper } from '@/store/create-async-thunk-wrapper';

export const getNotificationHistory = createAsyncThunkWrapper<
  NotificationHistory[],
  string
>(`${interactionLogNotificationHistoryModuleName}/getNotificationHistory`,
  (eventUuid, { signal }) => {
    return InteractionLogRequests.getInteractionLogNotificationHistory(eventUuid, signal)
      .then(res => res.data.notificationHistory);
  },
  {
    condition: asyncThunkConditionOption((state) => {
      const isLoading = InteractionLogNotificationHistorySelectors.selectIsLoadingNotificationHistory(state);
      const eventUuid = InteractionLogNotificationHistorySelectors.selectCurrentEventUuid(state);
      return isLoading || !eventUuid;
    })
  }
);
