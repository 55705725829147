import { dateFormat, dateTimeFormat } from '@/common/constants/date-formats';
import { Labels } from '@/common/constants/labels';
import { AppTableColumnDefType, AppTableRowType } from '@/common/models/app-table/app-table-types';
import { getFio } from '@/common/utils/common/common-user-data-utils';
import { formatDate } from '@/common/utils/common/date-utils';
import { formatPhoneOutput } from '@/common/utils/common/formatting-utils';
import { getSearchString } from '@/common/utils/searching/searching-utils';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { ActivityPlacesCell } from '@/modules/users-management/components/users-management-table/components/activity-places-cell/ActivityPlacesCell';
import { UserIcon } from '@/modules/users-management/components/users-management-table/components/user-icon/UserIcon';
import { UserInfo } from '@/modules/users-management/models/user-info/user-info';
import { UserState } from '@/modules/users-management/models/user-state';
import { UsersManagementTableColumns } from '@/modules/users-management/models/users-management-table/users-management-table-columns';
import React, { useMemo } from 'react';

export function useUsersManagementTableSettings() {
  const { hasAccessByPermission } = useUserPermissionsAccess();

  const searchPlaceholder = getSearchString([
    Labels.fio,
    hasAccessByPermission(UserPermission.ViewOrganizationInUsersManagement) ? Labels.organization : '',
    Labels.email,
    Labels.phoneNumber,
  ]);

  const columns = useMemo<AppTableColumnDefType<UserInfo>[]>(
    () => {
      const commonColumns: AppTableColumnDefType<UserInfo>[] = [
        {
          id: UsersManagementTableColumns.Role,
          header: '',
          accessorFn: (row) => (
            <UserIcon
              userRoles={row.roles ?? []}
              userState={row.userState}
            />
          ),
          enableColumnDragging: false,
          enableSorting: false,
          enableResizing: false,
          showInSettings: false,
          fixedWidth: true,
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: UsersManagementTableColumns.Fio,
          header: Labels.fio,
          accessorFn: (row) => getFio(row),
        },
        {
          id: UsersManagementTableColumns.Position,
          header: Labels.position,
          accessorKey: 'position',
        },
        {
          id: UsersManagementTableColumns.CreatedDate,
          header: Labels.addedDate,
          accessorFn: (row) => formatDate(row.createdDate, dateFormat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: UsersManagementTableColumns.LastSigninDate,
          header: Labels.lastSigninDate,
          accessorFn: (row) => formatDate(row.lastSigninDate, dateTimeFormat),
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          id: UsersManagementTableColumns.PhoneNumber,
          header: Labels.phoneNumber,
          accessorFn: (row) => `${formatPhoneOutput(row.phoneNumber)}`,
        },
        {
          id: UsersManagementTableColumns.Email,
          header: Labels.email,
          accessorKey: 'email',
        },
      ];

      const isOrganizationColumnView = hasAccessByPermission(UserPermission.ViewOrganizationInUsersManagement);
      if (isOrganizationColumnView) {
        return [
          ...commonColumns,
          {
            id: UsersManagementTableColumns.Organization,
            header: Labels.organization,
            accessorKey: 'organizationName',
          },
        ];
      }

      return [
        ...commonColumns,
        {
          id: UsersManagementTableColumns.ActivityPlaces,
          header: Labels.activityPlaceFull,
          accessorFn: (row) => <ActivityPlacesCell places={row.activityPlaces?.map((place) => place.name)} />,
        },
      ];
    }, []);

  const getCellRowClassName = (data: { row: AppTableRowType<UserInfo>; }) => {
    const { userState } = data.row.original;
    if (userState === UserState.Blocked) {
      return 'blocked-user';
    } else if (userState === UserState.Invited) {
      return 'invited-user';
    }
    return 'active-user';
  };

  const tableClasses = useMemo(() => ({
    cell: getCellRowClassName,
  }), []);

  return {
    columns,
    tableClasses,
    searchPlaceholder,
  };
}
