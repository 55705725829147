import { initialPasswordsState } from '@/modules/auth/constants/initial-passwords-state';
import { storageKeysPasswordRecovery } from '@/modules/auth/constants/storage-keys/storage-keys-password-recovery';
import { CodeStatus } from '@/modules/auth/models/code-status';
import { PasswordsConfirmation } from '@/modules/auth/models/passwords-confirmation';
import { passwordRecoveryInit, sendVerificationCode } from '@/modules/auth/store/password-recovery/async-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PasswordRecoveryFormState {
  login: string;
  code: string;
  email: string;
  isLoading: boolean;
  codeStatus?: CodeStatus;
  expireTimePhone: string;
  passwords: PasswordsConfirmation;
  showPasswordRecoveryForm: boolean;
}

const initialState: PasswordRecoveryFormState = {
  login: sessionStorage.getItem(storageKeysPasswordRecovery.LOGIN) ?? '',
  code: sessionStorage.getItem(storageKeysPasswordRecovery.VERIFIED_CODE) ?? '',
  email: '',
  isLoading: false,
  codeStatus: sessionStorage.getItem(storageKeysPasswordRecovery.VERIFIED_CODE) ? CodeStatus.Verified : undefined,
  expireTimePhone: sessionStorage.getItem(storageKeysPasswordRecovery.EXPIRE_TIME_PHONE) ?? '',
  passwords: initialPasswordsState,
  showPasswordRecoveryForm: true,
};

const PasswordRecoveryFormSlice = createSlice({
  name: 'passwordRecoveryForm',
  initialState,
  reducers: {
    setLogin(state, action: PayloadAction<string>) {
      state.login = action.payload;
    },
    setCode(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
    resetState(state) {
      state.login = '';
      state.code = '';
      state.email = '';
      state.isLoading = false;
      state.codeStatus = undefined;
      state.expireTimePhone = '';
      state.passwords = initialPasswordsState;
      state.showPasswordRecoveryForm = true;
      Object.keys(storageKeysPasswordRecovery).forEach((key: keyof typeof storageKeysPasswordRecovery) =>
        sessionStorage.removeItem(storageKeysPasswordRecovery[key])
      );
    },
    resetCode(state) {
      state.code = '';
      state.codeStatus = undefined;
    },
    setPasswords(state, action: PayloadAction<PasswordsConfirmation>) {
      state.passwords = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendVerificationCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendVerificationCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expireTimePhone = action.payload.nextCodeResend;
        state.showPasswordRecoveryForm = false;
        sessionStorage.setItem(storageKeysPasswordRecovery.LOGIN, state.login);
        sessionStorage.setItem(storageKeysPasswordRecovery.EXPIRE_TIME_PHONE, action.payload.nextCodeResend);
      })
      .addCase(passwordRecoveryInit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(passwordRecoveryInit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.codeStatus = CodeStatus.Verified;
        state.email = action.payload.email;
        sessionStorage.setItem(storageKeysPasswordRecovery.VERIFIED_CODE, state.code);
      })
      .addCase(passwordRecoveryInit.rejected, (state) => {
        state.isLoading = false;
        state.codeStatus = CodeStatus.Denied;
      });
  },
});

export const {
  setLogin,
  setCode,
  setPasswords,
  resetState,
  resetCode
} = PasswordRecoveryFormSlice.actions;

export default PasswordRecoveryFormSlice.reducer;
