import { useFormValidation } from '@/common/utils/client-validation/use-form-validation';
import { FormValidationRules } from '@/common/utils/client-validation/validation-rule';
import { phone } from '@/common/utils/client-validation/validation-rules';
import { PasswordRecoveryData, PasswordRecoveryDataFields } from '@/modules/auth/models/password-recovery-data';

export function usePasswordRecoveryFormValidation(data: PasswordRecoveryData) {

  const validationRules: FormValidationRules<PasswordRecoveryData, PasswordRecoveryDataFields> = {
    login: [phone()],
  };

  return {
    ...useFormValidation({ validationRules, data }),
  };
}
