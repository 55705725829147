export enum DocumentFinancingSource {
  Own = 'OWN',
  Federal = 'FEDERAL',
  Regional = 'REGIONAL',
  ExtraBudgetary = 'EXTRA_BUDGETARY',
  Blended = 'BLENDED',
}

const documentFinancingSourceLabels = {
  [DocumentFinancingSource.Own]: 'Собственные средства',
  [DocumentFinancingSource.Federal]: 'Средства федерального бюджета',
  [DocumentFinancingSource.Regional]: 'Средства регионального бюджета',
  [DocumentFinancingSource.ExtraBudgetary]: 'Средства бюджетов внебюджетных фондов',
  [DocumentFinancingSource.Blended]: 'Смешанные средства бюджетов',
};

export const getDocumentFinancingSourceLabel = (financingSource: DocumentFinancingSource) => documentFinancingSourceLabels[financingSource];