import { removeNonDigits } from '@/common/utils/common/string-utils';
import { ContactType } from '@/modules/auth/models/contact-type';
import { getFieldFromContactType } from '@/modules/auth/utils/contact-type-utils';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

const selectRegistrationState = (state: RootState) => state.auth.registration;

const selectFormData = (state: RootState) => selectRegistrationState(state).formData;
const selectUserUuid = (state: RootState) => selectRegistrationState(state).userUuid;
const selectCodes = (state: RootState) => selectRegistrationState(state).codes;
const selectActiveStep = (state: RootState) => selectRegistrationState(state).activeStep;
const selectIsLoadingStep2 = (state: RootState) => selectRegistrationState(state).isLoadingStep2;
const selectIsLoadingStep3 = (state: RootState) => selectRegistrationState(state).isLoadingStep3;
const selectExpireTimeEmail = (state: RootState) => selectRegistrationState(state).expireTimeEmail;
const selectExpireTimePhone = (state: RootState) => selectRegistrationState(state).expireTimePhone;
const selectIsEmailConfirmContactInProgress = (state: RootState) => selectRegistrationState(state).isEmailConfirmContactInProgress;
const selectIsPhoneNumberConfirmContactInProgress = (state: RootState) => selectRegistrationState(state).isPhoneNumberConfirmContactInProgress;
const selectIsOpenCertificatesListDialog = (state: RootState) => selectRegistrationState(state).isOpenCertificatesListDialog;
const selectPassword = (state: RootState) => selectRegistrationState(state).password;
const selectContactType = (state: RootState, type: ContactType) => type;
const selectIsOpenLicenceAgreement = (state: RootState) => selectRegistrationState(state).isOpenLicenceAgreement;

const selectPreprocessedPhone = createSelector([selectFormData], (formData) => removeNonDigits(formData.phoneNumber));
const selectExpireTimeEmailDate = createSelector([selectExpireTimeEmail], (expireTimeEmail) => parseISO(expireTimeEmail));
const selectExpireTimePhoneDate = createSelector([selectExpireTimePhone], (expireTimePhone) => parseISO(expireTimePhone));
const selectContactByType = createSelector(
  [selectFormData, selectContactType, selectPreprocessedPhone],
  (formData, type, preprocessedPhone) => {
    if (type === ContactType.PhoneNumber) {
      return preprocessedPhone;
    }
    const contactTypeField = getFieldFromContactType(type);
    return formData[contactTypeField];
  }
);

export const RegistrationSelectors = {
  selectFormData,
  selectUserUuid,
  selectCodes,
  selectActiveStep,
  selectIsLoadingStep2,
  selectIsLoadingStep3,
  selectExpireTimeEmailDate,
  selectExpireTimePhoneDate,
  selectPassword,
  selectIsEmailConfirmContactInProgress,
  selectIsPhoneNumberConfirmContactInProgress,
  selectIsOpenCertificatesListDialog,
  selectPreprocessedPhone,
  selectContactByType,
  selectIsOpenLicenceAgreement
};
