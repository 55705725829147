import { MutableRefObject, useEffect, useMemo, useState } from 'react';

interface TableHeightParams {
  topToolbarRef: MutableRefObject<HTMLDivElement>,
  bottomToolbarRef: MutableRefObject<HTMLDivElement>,
}

export function useTableHeight(args: TableHeightParams) {
  const [topToolbarVerticalOffset, setTopToolbarVerticalOffset] = useState(0);
  const [topToolbarHeight, setTopToolbarHeight] = useState(0);
  const [bottomToolbarHeight, setBottomToolbarHeight] = useState(0);

  const updateToolbarsHeights = () => {
    const topToolbarRect = args.topToolbarRef.current?.getBoundingClientRect();
    setTopToolbarHeight(topToolbarRect?.height ?? 0);
    setTopToolbarVerticalOffset(topToolbarRect?.y ?? 0);

    const bottomToolbarRect = args.bottomToolbarRef.current?.getBoundingClientRect();
    setBottomToolbarHeight(bottomToolbarRect?.height ?? 0);
  };

  useEffect(() => {
    setTimeout(updateToolbarsHeights, 0);
  }, []);

  const tableMaxHeight = useMemo(
    () => `calc(100vh - ${topToolbarVerticalOffset}px - ${topToolbarHeight}px - ${bottomToolbarHeight}px)`
    , [topToolbarHeight]);

  return {
    tableMaxHeight,
  };
}
