import { FormValidationReturnValue } from "@/common/utils/client-validation/use-form-validation";
import { UnboxRemainBottomContentProps, UnboxRemainValidateFields } from "@/modules/remains/components/unbox-remain-bottom-content/UnboxRemainBottomContent";
import { useUnboxRemainDialogValidation } from "@/modules/remains/hooks/use-unbox-remain-dialog-validation";
import { setIsRecursive, setSscc } from "@/modules/remains/store/page";
import { RemainsPageSelectors } from "@/modules/remains/store/page/selectors";
import { useAppDispatch } from "@/store";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type UnboxRemainValidationReturnValue = Pick<FormValidationReturnValue<Record<UnboxRemainValidateFields, string>, UnboxRemainValidateFields>, 'resetValidationResult' | 'validateForm'>;

export const useUnboxRemainBottomContent = (): UnboxRemainBottomContentProps & UnboxRemainValidationReturnValue => {

  const dispatch = useAppDispatch();

  const sscc = useSelector(RemainsPageSelectors.selectSscc);
  const isRecursive = useSelector(RemainsPageSelectors.selectIsRecursive);
  const isDialogOpenedByRowActions = useSelector(RemainsPageSelectors.selectIsDialogOpenedByRowActions);

  const {
    validationResult,
    validateForm,
    resetValidationResult
  } = useUnboxRemainDialogValidation({ sscc });

  const onChangeIsRecursive = useCallback((value: boolean) => {
    dispatch(setIsRecursive(value));
  }, []);

  const onChangeSscc = useCallback((value: string) => {
    dispatch(setSscc(value));
  }, []);

  return {
    sscc,
    readonlySscc: isDialogOpenedByRowActions,
    isRecursive,
    validationResult,
    onChangeIsRecursive,
    onChangeSscc,
    validateForm,
    resetValidationResult,
  };
};