import { axios } from '@/common/api/axios-config';
import { favoriteDocumentUrl } from '@/common/api/urls';
import { AxiosResponse } from 'axios';

export class SharedFavoritesRequests {

  static addToFavorite(docUuid: string): Promise<AxiosResponse<void>> {
    return axios.post(favoriteDocumentUrl(docUuid));
  }

  static deleteFromFavorite(docUuid: string): Promise<AxiosResponse<void>> {
    return axios.delete(favoriteDocumentUrl(docUuid));
  }

}