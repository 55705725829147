import { saleCardPositionsModuleName } from '@/modules/sale/constants/sale-module-names';
import { saleCardPositionsApi } from '@/modules/sale/store/sale-card/positions/async-thunks';
import { createDocumentCardPositionsSlice } from '@/shared/document/store/document-card/positions';

export const saleCardPositionsSlice = createDocumentCardPositionsSlice({
  moduleName: saleCardPositionsModuleName,
  savedTableConfigName: 'sale-card-positions-table',
  api: saleCardPositionsApi,
});

export const saleCardPositionsRootActions = saleCardPositionsSlice.rootSlice.actions;
export const saleCardPositionsTableConfigActions = saleCardPositionsSlice.documentCardPositionsTableConfigSlice.actions;

export default saleCardPositionsSlice.rootSlice.reducer;
