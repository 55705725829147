import { AppCheckbox } from '@/common/components/app-checkbox/AppCheckbox';
import { AppCustomTable } from '@/common/components/app-table/components/app-custom-table/AppCustomTable';
import { useSearchingData } from '@/common/hooks/use-searching-data';
import { useUserPermissionsAccess } from '@/modules/portal/hooks/use-user-permissions-access';
import { UserPermission } from '@/modules/portal/models/user-permission';
import { EditUserDialog } from '@/modules/users-management/components/edit-user-dialog/EditUserDialog';
import { useUsersManagementTableActions } from '@/modules/users-management/hooks/use-users-management-table-actions';
import { useUsersManagementTableDataSource } from '@/modules/users-management/hooks/use-users-management-table-data-source';
import { useUsersManagementTableEventHandlers } from '@/modules/users-management/hooks/use-users-management-table-event-handlers';
import { useUsersManagementTableRowActions } from '@/modules/users-management/hooks/use-users-management-table-row-actions';
import { useUsersManagementTableSettings } from '@/modules/users-management/hooks/use-users-management-table-settings';
import { UsersManagementSelectors } from '@/modules/users-management/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export const UsersManagementTable = () => {
  const users = useSelector(UsersManagementSelectors.selectUsers);
  const isLoadingDataSource = useSelector(UsersManagementSelectors.selectIsLoadingUsers);
  const tableConfig = useSelector(UsersManagementSelectors.selectTableConfig);
  const showBlockedUsers = useSelector(UsersManagementSelectors.selectShowBlockedUsers);

  const { hasAccessByPermission } = useUserPermissionsAccess();

  const { loadFirstPage, loadNextPage } = useUsersManagementTableDataSource();
  const {
    isActivateUserLoading,
    isBlockUserLoading,
    isInviteUserLoading,
    addUser,
    editUser,
    blockUser,
    activateUser,
    inviteUser
  } = useUsersManagementTableActions();

  const { columns, tableClasses, searchPlaceholder } = useUsersManagementTableSettings();

  const { isSearchNotChanged } = useSearchingData({
    selectSearchingState: UsersManagementSelectors.selectSearchState
  });

  const {
    onAddClick,
    onDialogClosed,
    onColumnVisibilityChange,
    onSortingChange,
    onColumnOrderChange,
    onColumnSizingChange,
    onSearchValueInput,
    onApplySearchClick,
    onClearSearchClick,
    onShowBlockedUsersChange,
    onScrollChange,
  } = useUsersManagementTableEventHandlers({ loadFirstPage, loadNextPage, addUser });

  const { getRowActions } = useUsersManagementTableRowActions({
    isActivateUserLoading, isInviteUserLoading, isBlockUserLoading,
    editUser, blockUser, activateUser, inviteUser
  });

  const showAddButton = hasAccessByPermission(UserPermission.AddNewUser);

  return (
    <>
      <AppCustomTable
        data={users}
        columns={columns}
        classes={tableClasses}
        isLoading={isLoadingDataSource}
        configState={tableConfig}
        enableRowVirtualization
        manualSorting
        topToolbar={{
          filter: {
            customFilterActions:
              <AppCheckbox
                label="Показывать заблокированных пользователей"
                checked={showBlockedUsers}
                onChange={onShowBlockedUsersChange}
              />,
          },
          search: {
            showSearchInput: true,
            searchPlaceholder,
            disableApplySearchButton: isSearchNotChanged,
            onSearchValueInput,
            onApplySearchClick,
            onClearSearchClick,
          },
          addButton: {
            showAddButton,
            onAddClick,
          }
        }}
        getRowActions={getRowActions}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onSortingChange={onSortingChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnSizingChange={onColumnSizingChange}
        onScrollAtTheEnd={onScrollChange}
      />
      <EditUserDialog dialogClosed={onDialogClosed} />
    </>
  );
};
