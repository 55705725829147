import interactionLogPageReducer from '@/modules/interaction-log/store/interaction-log';
import interactionLogNotificationHistoryReducer from '@/modules/interaction-log/store/interaction-log-notification-history';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Base references reducer.
 */
const interactionLogReducer = combineReducers({
  interactionLog: interactionLogPageReducer,
  interactionLogNotificationHistory: interactionLogNotificationHistoryReducer,
});

export default interactionLogReducer;
