import { axios } from '@/common/api/axios-config';
import { allDocumentScanningUrl, createDocumentScanningUrl, resetDocumentScanningUrl } from '@/common/api/urls';
import { PageableDataQueryParams } from '@/common/models/pageable-data/pageable-data-query-params';
import { PageableDataResponse } from '@/common/models/pageable-data/pageable-data-response';
import { CreateDocumentScanningBody } from '@/shared/document/models/document-card/scanning/create-document-scanning-body';
import { DeleteDocumentScanningBody } from '@/shared/document/models/document-card/scanning/delete-document-scanning-body';
import { ScanningInfo } from '@/shared/scanning-view/models/scanning-info';
import { AxiosResponse } from 'axios';

export class DocumentScanningRequests {

  static addScannedItemInfo(docUuid: string, body: CreateDocumentScanningBody): Promise<AxiosResponse<ScanningInfo>> {
    return axios.post(createDocumentScanningUrl(docUuid), body);
  }

  static getScannedItems(params: PageableDataQueryParams): Promise<AxiosResponse<PageableDataResponse<ScanningInfo>>> {
    return axios.get(allDocumentScanningUrl, { params });
  }

  static deleteScannedItems(body: DeleteDocumentScanningBody): Promise<AxiosResponse<void>> {
    return axios.delete(resetDocumentScanningUrl, { data: body });
  }
}
