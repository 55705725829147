import { useAsyncDispatch } from '@/common/hooks/use-async-dispatch';
import { DocumentCardNotesProps } from '@/shared/document/components/document-card-notes/DocumentCardNotes';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

type DocumentCardNotesDeletingHookParams = Pick<DocumentCardNotesProps, 'documentCardNotesSelectors' | 'documentCardNotesApi'>;

export function useDocumentCardNotesDeleting({ documentCardNotesSelectors, documentCardNotesApi }: DocumentCardNotesDeletingHookParams) {
  const { asyncDispatch } = useAsyncDispatch();

  const isDeletingInProgress = useSelector(documentCardNotesSelectors.selectIsDeletingNoteInProgress);
  const isRevertingInProgress = useSelector(documentCardNotesSelectors.selectIsRevertingNoteInProgress);

  const [deletedNoteUuid, setDeletedNoteUuid] = useState<string>();
  const [revertedNoteUuid, setRevertedNoteUuid] = useState<string>();

  const onDeleteClick = useCallback((documentNoteUuid: string) => {
    setDeletedNoteUuid(documentNoteUuid);
    asyncDispatch(() => documentCardNotesApi.deleteNote(documentNoteUuid));
  }, []);

  const onRevertClick = useCallback((documentNoteUuid: string) => {
    setRevertedNoteUuid(documentNoteUuid);
    asyncDispatch(() => documentCardNotesApi.revertNote(documentNoteUuid));
  }, []);

  return {
    deletedNoteUuid,
    revertedNoteUuid,
    isDeletingInProgress,
    isRevertingInProgress,
    onDeleteClick,
    onRevertClick,
  };
}
