import { SavedTableConfigState } from '@/common/models/saved-table-config/saved-table-config-state';
import { createSavedTableConfigSlice } from '@/common/store/saved-table-config';
import { defaultDocumentCardBoxesTableConfig } from '@/shared/document/constants/document-card-boxes-table-config';
import { DocumentCardBox } from '@/shared/document/models/document-card/boxes/document-card-box';
import { DocumentsCardBoxesApi } from '@/shared/document/store/document-card/boxes/types';
import { CaseReducerActions, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface DocumentCardBoxesState {
  tableConfig: SavedTableConfigState;
  boxes: DocumentCardBox[];
  isLoadingBoxes: boolean;
}

interface DocumentCardBoxesCaseReducers extends SliceCaseReducers<DocumentCardBoxesState> {
  deleteBox: (state: Draft<DocumentCardBoxesState>, action: PayloadAction<number>) => void;
  resetState: (state: Draft<DocumentCardBoxesState>) => void;
}

export type DocumentCardBoxesActions = CaseReducerActions<DocumentCardBoxesCaseReducers, string>;

const notClearedFields: Set<keyof DocumentCardBoxesState> = new Set(['tableConfig']);

interface CreateDocumentCardBoxesSliceParams {
  moduleName: string;
  savedTableConfigName: string;
  api: DocumentsCardBoxesApi;
}

export const createDocumentCardBoxesSlice = ({ moduleName, savedTableConfigName, api }: CreateDocumentCardBoxesSliceParams) => {
  const documentCardBoxesTableConfigState: SavedTableConfigState = {
    tableConfigName: savedTableConfigName,
    config: defaultDocumentCardBoxesTableConfig,
  };

  const documentCardBoxesTableConfigSlice = createSavedTableConfigSlice(moduleName, documentCardBoxesTableConfigState);

  const initialState: DocumentCardBoxesState = {
    tableConfig: documentCardBoxesTableConfigSlice.getInitialState(),
    boxes: [],
    isLoadingBoxes: false
  };

  const rootSlice = createSlice<DocumentCardBoxesState, DocumentCardBoxesCaseReducers>({
    name: moduleName,
    initialState,
    reducers: {
      deleteBox: (state, action: PayloadAction<number>) => {
        state.boxes.splice(action.payload, 1);
      },
      resetState: (state) => {
        Object.keys(state)
          .forEach((key: keyof DocumentCardBoxesState) => {
            if (!notClearedFields.has(key)) {
              // @ts-ignore
              state[key] = initialState[key];
            }
          });
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(api.getAllBoxes.pending, (state) => {
          state.isLoadingBoxes = true;
        })
        .addCase(api.getAllBoxes.fulfilled, (state, action) => {
          state.isLoadingBoxes = false;
          state.boxes = action.payload;
        })
        .addCase(api.getAllBoxes.rejected, (state) => {
          state.isLoadingBoxes = false;
        })
        .addMatcher(
          (action) => action.type.startsWith(documentCardBoxesTableConfigSlice.name),
          (state, action) => {
            state.tableConfig = documentCardBoxesTableConfigSlice.reducer(state.tableConfig, action);
          }
        );
    }
  });

  return {
    rootSlice,
    documentCardBoxesTableConfigSlice,
  };
};
