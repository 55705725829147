import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

const selectInviteManagerState = (state: RootState) => state.auth.inviteManager;

const selectCode = (state: RootState) => selectInviteManagerState(state).code;
const selectLogin = (state: RootState) => selectInviteManagerState(state).login;
const selectIsLoading = (state: RootState) => selectInviteManagerState(state).isLoading;
const selectCodeStatus = (state: RootState) => selectInviteManagerState(state).codeStatus;
const selectExpireTimePhone = (state: RootState) => selectInviteManagerState(state).expireTimePhone;
const selectPasswords = (state: RootState) => selectInviteManagerState(state).passwords;
const selectAgreementWithRules = (state: RootState) => selectInviteManagerState(state).agreementWithRules;
const selectShowSetNewPasswordForm = (state: RootState) => selectInviteManagerState(state).showSetNewPasswordForm;
const selectIsOpenLicenceAgreement = (state: RootState) => selectInviteManagerState(state).isOpenLicenceAgreement;

const selectExpireTimePhoneDate = createSelector([selectExpireTimePhone], (expireTimePhone) => parseISO(expireTimePhone));

export const InviteManagerSelectors = {
  selectCode,
  selectLogin,
  selectIsLoading,
  selectCodeStatus,
  selectExpireTimePhoneDate,
  selectPasswords,
  selectAgreementWithRules,
  selectShowSetNewPasswordForm,
  selectIsOpenLicenceAgreement
};
