import { usersManagementRoutePath } from '@/modules/users-management/routes/routes';
import { UsersManagementPage } from '@/modules/users-management/views/users-management-page/UsersManagementPage';
import React from 'react';
import { RouteObject } from 'react-router-dom';

/**
 * All users management routes.
 */
const routes: RouteObject[] = [
  {
    path: usersManagementRoutePath,
    element: <UsersManagementPage />,
  }
];

export default routes;
