import DeleteIcon from '@/assets/icons/controls/cross.svg?react';
import { AppIconButton } from '@/common/components/app-icon-button/AppIconButton';
import { SelectOption } from '@/common/models/app-select/select-option';
import { Chip } from '@mui/material';
import React, { FC, memo } from 'react';
import './app-multi-select-chip.scss';

interface AppMultiSelectChipProps {
  value: SelectOption['value'];
  label: SelectOption['label'];
  readOnly?: boolean;
  onDelete: (value: SelectOption['value']) => void;
}

const AppMultiSelectChipInner: FC<AppMultiSelectChipProps> = ({ label, value, readOnly = false, onDelete }) => {

  const onDeleteInner = () => onDelete(value);

  return (
    <Chip
      label={label}
      className="selected-item"
      variant="outlined"
      onDelete={onDeleteInner}
      deleteIcon={
        <AppIconButton
          icon={<DeleteIcon onMouseDown={(event: MouseEvent) => event.stopPropagation()} />}
          className="app-multi-select-delete-icon"
          notClickable={readOnly}
          edge="end"
        />
      }
    />
  );
};

export const AppMultiSelectChip = memo(AppMultiSelectChipInner);
