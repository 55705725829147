import { disposalCardPositionsModuleName } from '@/modules/disposals/constants/disposals-module-names';
import { disposalCardPositionsApi } from '@/modules/disposals/store/disposal-card/positions/async-thunks';
import { createDocumentCardPositionsSlice } from '@/shared/document/store/document-card/positions';

export const disposalCardPositionsSlice = createDocumentCardPositionsSlice({
  moduleName: disposalCardPositionsModuleName,
  savedTableConfigName: 'disposal-card-positions-table',
  api: disposalCardPositionsApi,
});

export const disposalCardPositionsRootActions = disposalCardPositionsSlice.rootSlice.actions;
export const disposalCardPositionsTableConfigActions = disposalCardPositionsSlice.documentCardPositionsTableConfigSlice.actions;

export default disposalCardPositionsSlice.rootSlice.reducer;
